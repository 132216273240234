
import React, { useState, useMemo, useEffect } from 'react'
import PageTitle from '../../../components/PageTitle'
import { useSelector } from 'react-redux';
import Select from "react-select";
import { adminStyles, adminStylesLight } from '../../../utils/selectStyles';
import { generateSelectData } from '../../../utils/serialiseResponse';
import { fileToBase64, orgTypes } from '../../../utils/helper';
import { updateCompanyProfile, getCompanyProfile, getCompanyWorkingAddress } from '../../../services/hrms';
import '../../Employee/create/create.css'
import customToast from '../../../components/CustomToast';
import Table from '../../../components/Table';
import { useNavigate } from 'react-router-dom';
import { IoEye } from 'react-icons/io5';
import Dropdown from "../../../components/DropDown/dropdown";
import { SlOptionsVertical } from 'react-icons/sl';

const WorkingAddress = () => {
  const [data , setData] = useState("");
  const theme = useSelector((state) => state.theme.theme);
  const navigate = useNavigate();
    
  useEffect(() => {
    getCompanyWorkingAddress({}).then(({data}) => setData(data))
    .catch((err) => {customToast.error("Server Error")})
  }, [])

  let columns = [
    {
        Header: "Office Name",
        disableFilters: false,
        disableSortBy: true,
        Cell:(props) => {
          return (
            <span
              className="cursor-pointer active:text-tertiary active:font-semibold">
                {props?.row?.original?.officeName}
            </span>
          );
        }
    },
    {
      Header: "State",
      disableFilters: false,
      disableSortBy: true,
      Cell:(props) => {
        return (
          <span
            className="cursor-pointer active:text-tertiary active:font-semibold">
              {props?.row?.original?.state?.name}
          </span>
        );
      }
    },
    {
      Header: "City",
      // accessor: "EmployeeName",
      disableFilters: false,
      disableSortBy: true,
      Cell:(props) => {
        return (
          <span
            className="cursor-pointer active:text-tertiary active:font-semibold">
              {props?.row?.original?.city?.name}
          </span>
        );
      }
    },
    {
      Header: "Address Line 1",
      // accessor: "EmployeeName",
      disableFilters: false,
      disableSortBy: true,
      Cell: (props) => {
        return (
          <span className="flex items-center justify-center cursor-pointer">
             {props?.row?.original?.addressLine1}
          </span>
        );
      },
    },
    {
        Header: "Address Line 2",
        // accessor: "EmployeeName",
        disableFilters: false,
        disableSortBy: true,
        Cell: (props) => {
          return (
            <span className="flex items-center justify-center cursor-pointer">
               {props?.row?.original?.addressLine2}
            </span>
          );
        },
      },
      {
        Header: "Zip Code",
        // accessor: "EmployeeName",
        disableFilters: false,
        disableSortBy: true,
        Cell: (props) => {
          let details = props?.row?.original;
          return (
            <span className="flex items-center justify-center cursor-pointer">
             { props?.row?.original?.zipCode }
            </span>
          );
        },
      },
      {
        Header: "Action",
        // accessor: "EmployeeName",
        disableFilters: false,
        disableSortBy: true,
        Cell: (props) => {
            return (
                <>
                  <Dropdown
                    icon={
                      <SlOptionsVertical className="icon-color-tertiary" />
                    }
                    options={[
                      {
                        text: 'Edit',
                        onClick: () => {
                          navigate("../working-address/update",{state:props?.row?.original})
                        }
                      },
                      {
                        text: props?.row?.original?.status ? "In-Active" : "Active",
                        onClick: () => handleStatusChange(props?.row?.original?._id , props?.row?.original?.status)
                      },
                    ]}
                  />
                </>
              );
        },
      },
    // [SearchColumnFilter, table]
  ]
  
  const tableData = data ? data : [];

    return (
        <main className="w-full">
            <div
                className={
                    "creation-content " + `${theme == "light" ? "light_bg" : ""}`
                }
            >
                <PageTitle title="Company Working Address" route='/dashboard' />
            </div>
                <section className="admin-content">
                <button
                  onClick={() => navigate('/add/working-address/add')}
                  className="rounded-full font-medium text-2xl px-10 py-5 dark:bg-tertiary cursor-pointer"
                 >
                 ADD NEW ADDRESS
                </button>   
                <div className={"filter-table "}>
                <Table columns={columns} data={tableData} />
                </div>
                </section>
        </main>
    )
}

export default WorkingAddress;