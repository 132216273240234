import { customAxios } from "../utils/axiosinstance"

export const getAllAdvances = async () => {
    const { data } = await customAxios.get(`/hrms/salary-advance/get-all-salary-advances`)
    return data;
}

export const assignSalaryAdvance = async (payload) => {
    const { data } = await customAxios.post(`/hrms/salary-advance/assign-salary-advance`, payload)
    return data;
}

export const getAdvSalaryInstallments = async (payload) => {
    const { data } = await customAxios.post(`/hrms/salary-advance/get-advance-salary-installments`, payload)
    return data;
}

export const payAdvSalaryInstallment = async (payload) => {
    const { data } = await customAxios.post(`/hrms/salary-advance/edit-advance-salary-installment-amount`, payload)
    return data;
}

export const pauseAdvSalaryInstallments = async (payload) => {
    const { data } = await customAxios.post(`/hrms/salary-advance/pause-advance-salary-installments`, payload)
    return data;
}

export const deleteAdvSalary = async (payload) => {
    const { data } = await customAxios.post(`/hrms/salary-advance/delete-advance-salary`, payload)
    return data;
}

export const getEmployeeAdvSalaryDetailsByMonthAndYear = async(payload) => {
    console.log(payload , "payload")
    const {data} = await customAxios.get(`/hrms/salary-advance/get-advsalary-details-by-month-year` , {
        params: payload,
    })
    
    return data;
}