import React, { useEffect, useState } from "react";
import PageTitle from "../../../../components/PageTitle";
import { dayOptions } from "../../../../utils/helper";
import { MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
import { FaCalendar } from "react-icons/fa";
import Select from "react-select";
import { IoCheckbox } from "react-icons/io5";
import { IoCalendarClear } from "react-icons/io5";
import customToast from "../../../../components/CustomToast";
import { deepObjCompare } from "../../../../utils/helper";
import {
  CreatePaymentSchedule,
  GetPaymentSchedule,
} from "../../../../services/paymentschedule";
import {
  adminStyles,
  dcrStyles,
  multiSelectStyles,
  selectUserStyles,
  tpStyles,
} from "../../../../utils/selectStyles";
import { fetchPayrunRuns } from "../../../../reducers/payrunReducer";
import { useSelector, useDispatch } from "react-redux";
import divisionReducer from "../../../../reducers/divisionReducer";
import { ConfigurePayRunApproveStatus } from "../../../../services/payRun";

export const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    padding: "0.2rem 1rem",
    backgroundColor: "#37AAF8",
    color: "#ffff",
    border: "5px",
    boxShadow: "none",
    minHeight: "3.6rem",
    width: "100%",
    marginTop: "1rem",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    color: "#9E9AD1",

    "&:hover": {
      backgroundColor: "#393b5f",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#9e9ad0",
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    width: "80%",
  }),
  input: (provided, state) => ({
    ...provided,
    color: "#fff",
    border: "none",
    width: "100%",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#fff",

    "&:hover": {
      color: "#9E9AD1",
    },
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#fff",
  }),
  indicatorSeparator: () => null,
};

export const adminStylesLight = {
  control: (provided, state) => ({
    ...provided,
    padding: "0rem 1rem",
    backgroundColor: "#e6edf3",
    color: "#C7C4E9",
    border: "none",
    borderRadius: "0",
    boxShadow: "none",
    minHeight: "3rem",
    width: "90%",
    marginTop: "1rem",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#e6edf3",
    color: "#25253d",

    "&:hover": {
      backgroundColor: "#cde8ff",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#9e9ad0",
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    width: "80%",
  }),
  input: (provided, state) => ({
    ...provided,
    color: "#fff",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#C7C4E9",

    "&:hover": {
      color: "#25253d",
    },
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#25253d",
  }),
  indicatorSeparator: () => null,
};

export const filterStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    color: "#C7C4E9",

    border: "1px solid #26243c",
    borderRadius: "5px",
    boxShadow: "none",
    height: "3.5rem",
    cursor: "pointer",
    fontSize: "1.4rem",

    "&:hover": {
      border: "1px solid #353152",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    color: "#9E9AD1",

    "&:hover": {
      backgroundColor: "#393b5f",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#9e9ad0",
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
  }),
  input: (provided, state) => ({
    ...provided,
    color: "#fff",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#C7C4E9",

    "&:hover": {
      color: "#9E9AD1",
    },
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#9E9AD1",
  }),
  indicatorSeparator: () => null,
};

const SchedulePayments = () => {
  const dispatch = useDispatch();
  const [selectedDay, setSelectedDay] = useState([]);
  const theme = useSelector((state) => state.theme.theme);
  const [actualDays, setActualDays] = useState(false);
  const [companyDays, setCompanyDays] = useState(false);
  const [lastDay, setLastDay] = useState(false);
  const [dayOfmonth, setDayOfMonth] = useState(false);
  const [salaryCalculationBasis, setSalaryCalculationBasis] = useState({
    type: "",
    value: null,
  });
  const [makePaymentOn, setMakePaymentOn] = useState({
    type: "",
    value: null,
  });

  const [selectedOption, setSelectedOption] = useState({
    value: null,
    label: "",
  });
  const [selectedOption2, setSelectedOption2] = useState({
    value: null,
    label: "",
  });

  const [paymentDate, setPaymentDate] = useState("");
  const [firstPayMonth, setFirstPayMonth] = useState({});
  const [paymentMonth, setPaymentMonth] = useState({});

  const companyWorkingDaysOptions = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
    { value: 7, label: "7" },
    { value: 8, label: "8" },
    { value: 9, label: "9" },
    { value: 10, label: "10" },
    { value: 11, label: "11" },
    { value: 12, label: "12" },
    { value: 13, label: "13" },
    { value: 14, label: "14" },
    { value: 15, label: "15" },
    { value: 16, label: "16" },
    { value: 17, label: "17" },
    { value: 18, label: "18" },
    { value: 19, label: "19" },
    { value: 20, label: "20" },
    { value: 21, label: "21" },
    { value: 22, label: "22" },
    { value: 23, label: "23" },
    { value: 24, label: "24" },
    { value: 25, label: "25" },
    { value: 26, label: "26" },
    { value: 27, label: "27" },
    { value: 28, label: "28" },
    { value: 29, label: "29" },
    { value: 30, label: "30" },
    { value: 31, label: "31" },
  ];
  const makePaymentOnOptions = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
    { value: 7, label: "7" },
    { value: 8, label: "8" },
    { value: 9, label: "9" },
    { value: 10, label: "10" },
    { value: 11, label: "11" },
    { value: 12, label: "12" },
    { value: 13, label: "13" },
    { value: 14, label: "14" },
    { value: 15, label: "15" },
    { value: 16, label: "16" },
    { value: 17, label: "17" },
    { value: 18, label: "18" },
    { value: 19, label: "19" },
    { value: 20, label: "20" },
    { value: 21, label: "21" },
    { value: 22, label: "22" },
    { value: 23, label: "23" },
    { value: 24, label: "24" },
    { value: 25, label: "25" },
    { value: 26, label: "26" },
    { value: 27, label: "27" },
    { value: 28, label: "28" },
    { value: 29, label: "29" },
    { value: 30, label: "30" },
    { value: 31, label: "31" },
  ];

  const getMonthName = (monthIndex) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return months[monthIndex];
  };

  useEffect(() => {
    dispatch(fetchPayrunRuns());
  }, [dispatch]);

  useEffect(() => {
    GetPaymentSchedule()
      .then((data) => {
        setSelectedDay(
          data?.selectedDay,
          // dayOptions.filter((obj) => data?.selectedDay.includes(obj.label)),
        );
        setSalaryCalculationBasis(data?.salaryCalculationBasis);
        setSelectedOption({
          value: data?.salaryCalculationBasis.value,
          label: data?.salaryCalculationBasis.value,
        });
        setSelectedOption2({
          value: data?.makePaymentOn.value,
          label: data?.makePaymentOn.value,
        });
        setMakePaymentOn(data?.makePaymentOn);

        switch (data?.salaryCalculationBasis.type) {
          case "company":
            setCompanyDays(true);
            break;
          case "actual":
            setActualDays(true);
            break;

          default:
            break;
        }

        switch (data?.makePaymentOn.type) {
          case "lastWorkingDay":
            setLastDay(true);
            break;
          case "dayOfFollowingMonth":
            setDayOfMonth(true);
            break;

          default:
            break;
        }
      })
      .catch((error) => console.log("No Such Data Exist"));
  }, []);
  const payrunData = useSelector((state) => state.payrun.data);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (selectedDay?.length === 0) {
        customToast.error("Please select work days");
        return;
      }
      if (salaryCalculationBasis.type === "") {
        customToast.error("Please select salary calculation basis");
        return;
      }
      if (makePaymentOn.type === "") {
        customToast.error("Please select make payment on");
        return;
      }
      if (
        salaryCalculationBasis.type === "company" &&
        !salaryCalculationBasis.value
      ) {
        customToast.error("Please select company working days");
        return;
      }
      if (
        makePaymentOn.type === "dayOfFollowingMonth" &&
        !makePaymentOn.value
      ) {
        customToast.error("Please select day of following month");
        return;
      }
      if (Object.keys(paymentMonth).length == 0 && payrunData.length == 0) {
        customToast.error("Select Date for first month payment");
        return;
      }
      const paymentScheduleDetails = { selectedDay };

      if (firstPayMonth) {
        if (firstPayMonth.month == "0") {
          paymentScheduleDetails.firstPayMonth = firstPayMonth;
        } else {
          (firstPayMonth.month = parseInt(firstPayMonth.month) - 1),
            (paymentScheduleDetails.firstPayMonth = firstPayMonth);
        }
      }
      if (paymentMonth) {
        if (makePaymentOn.type === "lastWorkingDay") {
          paymentMonth.month = parseInt(firstPayMonth.month);
          paymentScheduleDetails.paymentMonth = paymentMonth;
        } else if (makePaymentOn.type === "dayOfFollowingMonth") {
          const nextMonth =
            firstPayMonth.month === 11 ? 0 : firstPayMonth.month + 1;
          const nextYear =
            firstPayMonth.month === 11
              ? parseInt(firstPayMonth.year) + 1
              : firstPayMonth.year;
          paymentScheduleDetails.paymentMonth = {
            month: nextMonth,
            year: nextYear,
          };
        } else if (firstPayMonth.month == "11") {
          paymentMonth.month = 0;
          paymentScheduleDetails.paymentMonth = paymentMonth;
        } else {
          paymentMonth.month = firstPayMonth.month;
          paymentScheduleDetails.paymentMonth = paymentMonth;
        }
      }
      if (salaryCalculationBasis) {
        if (salaryCalculationBasis.type == "company") {
          paymentScheduleDetails.salaryCalculationBasis =
            salaryCalculationBasis;
        } else {
          let month = firstPayMonth.month;
          if (month == "1") {
            const year = parseInt(firstPayMonth.year);
            const isLeapYear =
              (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
            salaryCalculationBasis.value = isLeapYear ? 29 : 28;
            paymentScheduleDetails.salaryCalculationBasis =
              salaryCalculationBasis;
          } else if (month == "0" || month % 2 == 0) {
            salaryCalculationBasis.value = 31;
            paymentScheduleDetails.salaryCalculationBasis =
              salaryCalculationBasis;
          } else {
            salaryCalculationBasis.value = 30;
            paymentScheduleDetails.salaryCalculationBasis =
              salaryCalculationBasis;
          }
        }
      }
      if (makePaymentOn) {
        if (makePaymentOn.type == "dayOfFollowingMonth") {
          paymentScheduleDetails.makePaymentOn = makePaymentOn;
        } else {
          let date = new Date(
            parseInt(firstPayMonth.year),
            parseInt(firstPayMonth.month),
            parseInt(salaryCalculationBasis.value),
          );
          let difference = selectedDay?.length - date.getDay();
          if (difference <= 0) {
            makePaymentOn.value = salaryCalculationBasis.value;
            paymentScheduleDetails.makePaymentOn = makePaymentOn;
          } else {
            makePaymentOn.value = salaryCalculationBasis.value - difference;
            paymentScheduleDetails.makePaymentOn = makePaymentOn;
          }
        }
      }

      const setUpStatusData = {
        docName: "payrunApproveStatus",
        month: 0,
        approveStatus: 0,
        year: 2024,
      };

      const res = await ConfigurePayRunApproveStatus(setUpStatusData);
      if (res) {
        const response = await CreatePaymentSchedule(paymentScheduleDetails);
        if (response) {
          customToast.success("Payment Schedule Added Successfully");
          setSelectedDay([]);
          setActualDays("");
          setCompanyDays("");
          setFirstPayMonth("");
          setMakePaymentOn("");
          setSalaryCalculationBasis("");
          setPaymentMonth("");
        } else {
          customToast.error("Error in Adding Payment Schedule");
        }
      } else {
        customToast.error("Error in Adding Payment Schedule");
      }
    } catch (error) {
      customToast.error(error);
    }
  };

  return (
    <main className="main-content bg-primaryLight2 dark:bg-primaryDark1">
      <div
        className={
          "area-creation-content" + `${theme == "light" ? "light_bg" : ""}`
        }
      >
        <PageTitle title="Schedule Payment" route={".."} />

        <section className="admin-content pb-10">
          <form>
            <div className="row">
              <div>
                <label>
                  Select Work Days <span className="asterisk-imp">*</span>
                </label>
                <div className="flex gap-4 mt-4">
                  {dayOptions?.map((el, idx) => (
                    <span
                      key={idx}
                      className={`px-3 py-3 w-28 text-md text-center rounded-xl cursor-pointer ${
                        selectedDay?.some((elm) => deepObjCompare(el, elm))
                          ? "dark:bg-green-500 text-white"
                          : "dark:bg-primaryDark"
                      }`}
                      onClick={() => {
                        if (
                          selectedDay?.some((elm) => deepObjCompare(el, elm))
                        ) {
                          setSelectedDay(
                            selectedDay.filter((item) => item !== el),
                          );
                        } else {
                          setSelectedDay([...selectedDay, el]);
                        }
                      }}
                    >
                      {el.label}
                    </span>
                  ))}
                </div>
              </div>
            </div>

            <div className="flex flex-col mx-8">
              <label>
                Calculate Salary Based On{" "}
                <span className="asterisk-imp">*</span>
              </label>
              <div className="flex flex-col">
                <div className="text-3xl font-medium">
                  {actualDays ? (
                    <IoCheckbox
                      className="text-success inline-block text-4xl me-4 cursor-pointer"
                      onClick={() => {
                        setActualDays(false);
                        setCompanyDays(false);
                      }}
                    />
                  ) : (
                    <MdOutlineCheckBoxOutlineBlank
                      className="text-success inline-block text-4xl me-4"
                      onClick={() => {
                        setActualDays(true);
                        setCompanyDays(false);
                        setSalaryCalculationBasis({
                          type: "actual",
                          value: null,
                        });
                      }}
                    />
                  )}
                  <label>Actual days in a month</label>
                </div>

                <label className="text-3xl flex font-medium items-center">
                  {companyDays ? (
                    <IoCheckbox
                      className="text-success inline-block text-4xl me-4 cursor-pointer"
                      onClick={() => {
                        setCompanyDays(false);
                        setActualDays(false);
                      }}
                    />
                  ) : (
                    <MdOutlineCheckBoxOutlineBlank
                      className="text-success inline-block text-4xl me-4"
                      onClick={() => {
                        setCompanyDays(true);
                        setActualDays(false);
                        setSalaryCalculationBasis({
                          type: "company",
                          value: null,
                        });
                      }}
                    />
                  )}
                  Company working days
                  <Select
                    options={companyWorkingDaysOptions}
                    // defaultValue={selectedOption.value}
                    styles={adminStyles}
                    value={selectedOption}
                    placeholder=""
                    className="w-max mx-4"
                    onChange={(e) => {
                      setSelectedOption(e);
                      setSalaryCalculationBasis({
                        type: "company",
                        value: e.value,
                      });
                    }}
                  />{" "}
                  days per month
                </label>
              </div>
            </div>

            <div className="flex flex-col mx-8">
              <label>
                Make Payment On <span className="asterisk-imp">*</span>
              </label>
              <div className="flex flex-col">
                <div className="text-3xl font-medium">
                  {lastDay ? (
                    <IoCheckbox
                      className="text-success inline-block text-4xl me-4 cursor-pointer"
                      onClick={() => {
                        setLastDay(false);
                        setDayOfMonth(false);
                      }}
                    />
                  ) : (
                    <MdOutlineCheckBoxOutlineBlank
                      className="text-success inline-block text-4xl me-4"
                      onClick={() => {
                        setLastDay(true);
                        setDayOfMonth(false);
                        setMakePaymentOn({
                          type: "lastWorkingDay",
                          value: null,
                        });
                      }}
                    />
                  )}
                  <label>Last working day of every month</label>
                </div>

                <label className="text-3xl font-medium flex items-center">
                  {dayOfmonth ? (
                    <IoCheckbox
                      className="text-success inline-block text-4xl me-4 cursor-pointer"
                      onClick={() => {
                        setDayOfMonth(false);
                        setLastDay(false);
                      }}
                    />
                  ) : (
                    <MdOutlineCheckBoxOutlineBlank
                      className="text-success inline-block text-4xl me-4"
                      onClick={() => {
                        setDayOfMonth(true);
                        setLastDay(false);
                        setMakePaymentOn({
                          type: "dayOfFollowingMonth",
                          value: selectedOption2.value,
                        });
                      }}
                    />
                  )}
                  Day{" "}
                  <Select
                    options={makePaymentOnOptions}
                    defaultValue={selectedOption2.value}
                    styles={adminStyles}
                    value={selectedOption2}
                    placeholder=""
                    className="w-max mx-4"
                    onChange={(e) => {
                      setSelectedOption2(e);
                      setMakePaymentOn({
                        type: "dayOfFollowingMonth",
                        value: e.value,
                      });
                    }}
                  />{" "}
                  of following month
                </label>
              </div>
            </div>

            {payrunData?.length == 0 && (
              <div className="my-10 mx-8 flex flex-col gap-5 bg-[#d87355] bg-opacity-25 p-8 rounded-lg border-2 border-[#d87355] ">
                <div className="text-3xl font-medium  text-[#fc7248] gap-10">
                  Start Making Payment From{" "}
                  <span className="asterisk-imp">*</span>
                </div>
                <div className="flex gap-10">
                  <div className="flex items-center">
                    <input
                      type="month"
                      className="dark:bg-tertiary dark:text-white outline-none border-none px-3 py-3 rounded-lg mt-2 placeholder-white"
                      onChange={(e) => {
                        const [year, month] = e.target.value.split("-");
                        const monthName = getMonthName(parseInt(month) - 1);
                        const m = month + 1;
                        setFirstPayMonth({ month, year });
                        setPaymentMonth({ month, year });
                        setPaymentDate(`${monthName} ${year}`);
                      }}
                    />
                    {/* <input
                      value={paymentDate}
                      type="month"
                      placeholder="Select Month"
                      className="dark:bg-tertiary dark:text-white outline-none border-none px-3 py-3 rounded-lg mt-2 placeholder-white"
                      onChange={(e) => {
                        const [year, month] = e.target.value.split("-");
                        const monthName = getMonthName(parseInt(month) - 1);
                        console.log(year, month);
                        setFirstPayMonth({ month, year });
                        setPaymentMonth({ month, year });
                        setPaymentDate(`${monthName} ${year}`);
                      }}
                    /> */}
                  </div>
                  {/* <div className="flex items-center relative">
                    <IoCalendarClear
                      size={25}
                      className="cursor-pointer text-[#fc7248]"
                      onClick={() => monthInputRef.current.focus()}
                    />
                    <input
                      type="month"
                      className=""
                      ref={monthInputRef}
                      onChange={(e) => {
                        const [year, month] = e.target.value.split("-");
                        const monthName = getMonthName(parseInt(month) - 1);
                        const m = month + 1;
                        setFirstPayMonth({ month, year });
                        setPaymentMonth({ month, year });
                        setPaymentDate(`${monthName} ${year}`);
                      }}
                    />
                  </div> */}
                </div>
                <div className="flex flex-col gap-2 mt-3">
                  {/* {firstPayMonth && makePaymentOn ? <p className="text-base text-tertiary">Salary for the month Feb 2024 will be paid on {makePaymentOn?.value}/{parseInt(paymentMonth?.month) + 1}/{paymentMonth?.year}</p> : null} */}
                  <p className="text-xl text-[#fc7248]">
                    Note: Select the date for your first payroll when you start
                    using Medorn HRMS
                  </p>
                </div>
              </div>
            )}

            <button
              className="button-blue-gradient mx-8"
              onClick={handleSubmit}
            >
              Save
            </button>
          </form>
        </section>
      </div>
    </main>
  );
};

export default SchedulePayments;
