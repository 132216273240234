import React, { useState } from "react";
import DropZone from "../../../components/DropZone/DropZone";
import { useSelector } from "react-redux";
import PageTitle from "../../../components/PageTitle";
import { useNavigate } from "react-router-dom";

const ImportEarningAndDeduction = () => {
  const theme = useSelector((state) => state.theme.theme);
  const navigate = useNavigate();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  return (
    <main
      className={
        "main-content " + `${theme == "light" ? " light_bg_main" : ""}`
      }
      style={{ width: "100%" }}
    >
      <div className="creation-content ">
        <PageTitle title="One Time Earning And Deduction" />
      </div>

      <div className="mr-10 mb-10 py-10">
        <p className="font-semibold text-3xl">
          Download a sample <span className="text-tertiary">.csv format</span>{" "}
          or <span className="text-tertiary">.xls format</span> file and compare
          it with your import file to ensure that the file is ready to import.
        </p>
      </div>

      <DropZone
        uploadedFiles={uploadedFiles}
        setUploadedFiles={setUploadedFiles}
      />

      <div className="mr-10 flex items-center gap-20">
        <button
          className="button-green-gradient"
          onClick={() => {
            if (uploadedFiles.length === 0) return;
            navigate("map-fields", {
              state: {
                uploadedFiles: uploadedFiles,
              },
            });
          }}
        >
          Next
        </button>
        <button className="button-red-gradient">Cancel</button>
      </div>
    </main>
  );
};

export default ImportEarningAndDeduction;
