import { useDispatch, useSelector } from 'react-redux';
import PageTitle from '../../../components/PageTitle';
import Tabs from '../../../components/Tabs'
import React, { useEffect, useMemo, useState } from 'react'
import Select from "react-select";
import Table from '../../../components/Table';
import { adminStyles, adminStylesLight } from "../../../utils/selectStyles";
import { fetchEmployees } from '../../../reducers/employeesReducer'
import makeAnimated from 'react-select/animated';
import { generateSelectData, generateSelectDataForOnboarding } from '../../../utils/serialiseResponse';
import { fetchAllOnboardProcesses, fetchAllOnboardingEmployeeTasks } from '../../../reducers/onboardingDivisionsReducer';
import { AddEmployeesToProcess, updateStatusOfEmpTasks, updateStatusOfEmpSingleTask } from '../../../services/onboarding';
import customToast from '../../../components/CustomToast/index';
import Dropdown from '../../../components/DropDown/dropdown';
import { BsThreeDotsVertical } from 'react-icons/bs';
import Modal from '../../../components/Modal';

const initiation = () => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.theme);
  const processes = useSelector((state) => state.onboardDivisions.processesData);
  const employeeTasks = useSelector((state) => state.onboardDivisions.employeeTasks);
  const employees = useSelector((state) => state.employees);
  const animatedComponents = makeAnimated();
  const [formData, setformData] = useState({ processId: null, employeesIds: [] });
  const [taskListModal, settaskListModal] = useState(false);
  const [userProcessDets, setuserProcessDets] = useState();
  const [selectedEmployees, setSelectedEmployees] = useState([]);

  const columns = useMemo(
    () => [
      {
        Header: "EMPLOYEE UID",
        accessor: "uid",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "EMPLOYEE NAME",
        accessor: (row) => row.firstName + " " + row.lastName,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "JOINING DATE",
        accessor: (row) => new Date(row.joiningDate).toLocaleDateString('en-IN'),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "DEPARTMENT",
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <>
              {row.original.department?.name ? row.original.department?.name : "--"}
            </>
          );
        },
      },
    ],
    [employees.data]
  );

  const columnsOfInitiated = useMemo(
    () => [
      {
        Header: "EMPLOYEE UID",
        accessor: "uid",
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row }) => {
          const doc = row.original;
          return (
            <>
              {doc.employee?.uid ?
                <div>
                  <span
                    className='cursor-pointer hover:underline'
                    onClick={() => {
                      setuserProcessDets(doc);
                      settaskListModal(true);
                    }}
                  >
                    {doc.employee.uid}
                  </span>
                </div>
                :
                "--"
              }
            </>
          );
        },
      },
      {
        Header: "EMPLOYEE NAME",
        accessor: (row) => row?.employee?.firstName + " " + row?.employee?.lastName,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "DATE OF JOINING",
        accessor: (row) => new Date(row.employee.joiningDate).toLocaleDateString('en-IN'),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "STATUS",
        accessor: "status",
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <>
              {row.original?.status === 'Initiated' ? (
                <span className='text-[#f7b500]'>{row.original.status}</span>
              ) : row.original.status === 'Pending' ? (
                <span className='text-[#e62626]'>{row.original.status}</span>
              ) : (
                <span className='text-[#00b74a]'>{row.original.status}</span>
              )
              }
            </>
          );
        },
      },
      {
        Header: "ACTION",
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <>
              <Dropdown
                icon={
                  <BsThreeDotsVertical className='text-white cursor-pointer text-3xl' />
                }
                options={[
                  {
                    text: 'Initiated',
                    onClick: async () => {
                      try {
                        const res = await updateStatusOfEmpTasks({ id: row.original._id, status: 'Initiated' });
                        if (res) {
                          dispatch(fetchAllOnboardingEmployeeTasks());
                          return customToast.success("Status updated successfully");
                        }
                      }
                      catch (err) {
                        if(err.response.data.message === "Bad Request!"){
                          return customToast.error(err.response.data.data)
                        }
                        return customToast.error("Something went wrong");
                      }
                    }
                  },
                  {
                    text: 'Pending',
                    onClick: async () => {
                      try {
                        const res = await updateStatusOfEmpTasks({ id: row.original._id, status: 'Pending' });
                        if (res) {
                          dispatch(fetchAllOnboardingEmployeeTasks());
                          return customToast.success("Status updated successfully");
                        }
                      }
                      catch (err) {
                        if(err.response.data.message === "Bad Request!"){
                          return customToast.error(err.response.data.data)
                        }
                        return customToast.error("Something went wrong");
                      }
                    }
                  },
                  {
                    text: 'Completed',
                    onClick: async () => {
                      try {
                        const res = await updateStatusOfEmpTasks({ id: row.original._id, status: 'Completed' });
                        if (res) {
                          dispatch(fetchAllOnboardingEmployeeTasks());
                          return customToast.success("Status updated successfully");
                        }
                      }
                      catch (err) {
                        if(err.response.data.message === "Bad Request!"){
                          return customToast.error(err.response.data.data)
                        }
                        return customToast.error("Something went wrong");
                      }
                    }
                  },
                ]}
              />
            </>
          );
        },
      },
    ],
    []
  );

  const emplSelect = useMemo(
    () => generateSelectData(employees.data, "fullName"),
    [employees.data]
  );

  let processesSelect = useMemo(
    () => generateSelectDataForOnboarding(processes),
    [processes]
  );

  const submitHandler = async (e) => {
    e.preventDefault();
    if (formData.employeesIds.length === 0 || !formData.processId) {
      return customToast.error("Please fill all required fields");
    }

    try {
      const res = await AddEmployeesToProcess(formData);
      if (res) {
        dispatch(fetchAllOnboardProcesses());
        dispatch(fetchAllOnboardingEmployeeTasks());
        return customToast.success("Employees added to process successfully");
      }
    }
    catch (err) {
      return customToast.error("Something went wrong!");
    }
  }

  useEffect(() => {
    dispatch(fetchEmployees());
    dispatch(fetchAllOnboardProcesses());
    dispatch(fetchAllOnboardingEmployeeTasks());
  }, [dispatch]);

  return (
    <main className={"main-content " + `${theme == "light" ? "light_bg_main" : ""} w-full`} >
      <div className={"area-creation-content " + `${theme == "light" ? "light_bg" : ""}`}>
        <PageTitle title="Onboarding Initiation" route={'../..'} />

        <section className='admin-content px-8 mt-6' >
          <Tabs
            width={"w-60"}
            height={"h-16"}
            textSize={"text-xl"}
            titleBgColor={`bg-primaryDark1`}
            onTabChange={() => {
              setSelectedEmployees([]);
            }}
            tabs={[
              {
                name: "Initiated",
                content:
                  <div>
                    {employeeTasks?.length > 0 ? (
                      <div>
                        <section>
                          <h2 className='show-entries'>
                            SHOWING ({employeeTasks?.length}) ENTRIES
                          </h2>

                          <div className="filter-table">
                            <Table columns={columnsOfInitiated} data={employeeTasks} />
                          </div>
                        </section>
                      </div>
                    ) : (
                      <div className='mt-4'>
                        <span className='text-[#e62626] text-2xl font-semibold'>No Employees Onboarding Pending</span>
                      </div>
                    )}

                  </div>
              },
              {
                name: "Excluded",
                content:
                  <form onSubmit={submitHandler}>
                    <div className='flex flex-col gap-4'>
                      <div>
                        <label htmlFor="emp_name">
                          Select Employee <span className="asterisk-imp">*</span>
                        </label>
                        <br />
                        <Select
                          id="emp_name"
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          options={emplSelect}
                          isMulti
                          styles={theme == "light" ? adminStylesLight : adminStyles}
                          placeholder="Select Employee"
                          className='w-max'
                          onChange={(e) => {
                            const selectedIds = e.map(item => item._id);
                            setformData({ ...formData, employeesIds: selectedIds });
                            setSelectedEmployees(e);
                          }}
                        />
                      </div>

                      <div className="filter-table">
                        <Table columns={columns} data={employees.data.filter(emp => !selectedEmployees.some(sel => sel._id === emp._id))} />
                      </div>

                      <div>
                        <label htmlFor="emp_name">
                          Select Onboarding Process <span className="asterisk-imp">*</span>
                        </label>
                        <br />
                        <Select
                          id="emp_name"
                          options={processesSelect}
                          styles={theme == "light" ? adminStylesLight : adminStyles}
                          placeholder="Select Process"
                          className="w-max"
                          onChange={(e) => setformData({ ...formData, processId: e.value })}
                        />
                      </div>

                      {/* <span>
                        <span>
                          Initiate Onboarding Process
                        </span>
                        <input
                          type="checkbox"
                          className="cursor-pointer"
                        />
                      </span> */}

                      <div className='flex mt-4 gap-6'>
                        <button type='submit' className='button-green-gradient'>Submit</button>
                      </div>
                    </div>
                  </form>
              }
            ]} />
        </section>

        <Modal
          showModal={taskListModal}
          closeModal={() => settaskListModal(false)}
          title={userProcessDets?.employee.firstName + " " + userProcessDets?.employee.lastName}
          width="w-max"
          content={
            <div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  settaskListModal(false);
                }}
              >
                <table className="mt-8 w-full">
                  <thead className="uppercase dark:text-secondary dark:bg-primaryDark2 w-full text-center">
                    <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">
                      Task List
                    </th>
                    <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">
                      Attachments
                    </th>
                    <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">
                      Due Date
                    </th>
                    <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">
                      Status
                    </th>
                  </thead>

                  <tbody>
                    {userProcessDets?.tasks.map((item, idx) => (
                      <tr key={idx} className="dark:bg-primary text-center">
                        <td className="border-2 border-collapse border-primaryDark text-wheat px-6 py-6">
                          {item.taskDetails.taskName}
                        </td>

                        <td className="border-2 border-collapse border-primaryDark text-wheat px-6 py-6">
                          {item.taskDetails.file ? (
                            <a
                              href={item.taskDetails.file}
                              className="text-blue-500 hover:underline"
                            >
                              Download File
                            </a>
                          ) : (
                            <span className="text-red-500">No Attachment</span>
                          )}
                        </td>

                        <td className="border-2 border-collapse border-primaryDark text-wheat px-6 py-6">
                          <span>
                            {item.taskDetails.dueDays > 1 ? item.taskDetails.dueDays + " Day(s) " : item.taskDetails.dueDays + " Day "} after joining date
                          </span>
                        </td>

                        <td className="border-2 border-collapse border-primaryDark text-wheat px-6 py-6">
                          {item.status === "Pending" ? (
                            <button
                              className='button-blue-gradient'
                              onClick={async () => {
                                const res = await updateStatusOfEmpSingleTask({ id: userProcessDets._id, taskId: item.taskDetails._id, status: "Completed" })
                                if (res.message === "OK") {
                                  dispatch(fetchAllOnboardingEmployeeTasks())
                                  return customToast.success("Status of task successfully updated")
                                }
                              }}
                            >
                              Complete
                            </button>
                          ) : (
                            <button
                              className='button-green-gradient'
                              onClick={async () => {
                                const res = await updateStatusOfEmpSingleTask({ id: userProcessDets._id, taskId: item.taskDetails._id, status: "Pending" })
                                if (res.message === "OK") {
                                  dispatch(fetchAllOnboardingEmployeeTasks())
                                }
                              }}
                            >Completed</button>
                          )
                          }
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </form>
            </div>
          }
        />
      </div>
    </main >
  )
}

export default initiation;
