import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../../reducers/userReducer";
import { useNavigate } from "react-router-dom";
import "./index.css";
import customToast from "../../../components/CustomToast";
import { ToastContainer } from "react-toastify";
import innerDots from "../../../assets/img/Vector.png";
import login_bg from "../../../assets/img/login_bg.png";
import { Spinner } from "react-bootstrap";
import { FiEye, FiEyeOff } from "react-icons/fi";

const index = ({ loggedUser }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loggedIn = useSelector(({ user }) => user);

  useEffect(() => {
    if (loggedUser) navigate("/dashboard");
  }, [loggedUser, navigate]);

  const [companyId, setCompanyId] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (companyId && username && password) {
      let error = null;
      try {
        setLoading(true);
        await dispatch(loginUser({ companyId: companyId.toLowerCase().trim(), username, password }), remember);
      } catch (e) {
        error = e;
        customToast.error(error?.response?.data?.data);
      } finally {
        setLoading(false);
      }
      if (!error) {
        customToast.success("Successfully Logged In");
      }
    }else {
      customToast.error("Please Enter all fields");
    }
  };

  return (
    <div className="login_page ">
      <div className="login_box">
        <div className="login_box_left">
          <h1 style={{ color: "#37AAF8" }}>MEDORN</h1>
          <h1 style={{ color: "#fff" }}>LOGIN PAGE</h1>
          <p>HUMAN RESOURCE MANAGEMENT SYSTEM</p>
        </div>

        <div className="login_box_right">
          <h1>MEDORN</h1>
          <p>This is a Login Page For HR Management System</p>
          <form onSubmit={(e) => handleFormSubmit(e)} className="login_form">
            <div className="div_input">
              <label htmlFor="comp_id">Company ID</label>
              <br />
              <input
                className="input_box"
                type="text"
                placeholder="Company ID"
                id="comp_id"
                value={companyId}
                onChange={(e) => setCompanyId(e.target.value)}
              />
            </div>

            <div className="div_input">
              <label htmlFor="username_add">username Address</label>
              <br />
              <input
                type="text"
                className="input_box"
                id="username_add"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="username Address"
              />
            </div>

            <div className="div_input">
              <label htmlFor="password">Password</label>
              <br />
              <div className="relative flex">
                <input
                  className="input_box"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {showPassword ? (
                  <FiEye
                    className="password_icon absolute top-[35%] left-[90%]"
                    onClick={handleTogglePasswordVisibility}
                  />
                ) : (
                  <FiEyeOff
                    className="password_icon absolute top-[35%] left-[90%]"
                    onClick={handleTogglePasswordVisibility}
                  />
                )}
              </div>
            </div>

            <div className="options">
              <span>
                <input
                  className="checkbox_input"
                  type="checkbox"
                  value={remember}
                  onChange={(e) => setRemember(e.target.checked)}
                />
                Remember Me
              </span>
              <span className="forgot_pass">Forgot Password ?</span>
            </div>

            {loading ? (
              <Spinner
                animation="border"
                variant="primary"
                style={{ width: "3rem", height: "3rem" }}
              />
            ) : (
              <button className="submit_btn" type="submit">
                Sign In
              </button>
            )}
          </form>
        </div>

        <div className="login_bg">
          <img src={login_bg} alt="" />
        </div>

        <div className="dotContainer">
          <img src={innerDots} alt="innerDots" />
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default index;
