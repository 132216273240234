import React from 'react'
import SideBar from '../../components/SideBar/SideBar'
import { Outlet } from 'react-router-dom'
import { title,links } from './navdata'
const CreateCompany = () => {
  return (
    <>
        <SideBar title={title} links={links}></SideBar>
        <Outlet/>
    </>
  )
}

export default CreateCompany