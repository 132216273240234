import React, { useMemo, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import PageTitle from "../../../../components/PageTitle/index";
import Table from "../../../../components/Table/index";
import customToast from "../../../../components/CustomToast/index";
import {
  generateWeekDays,
  attendanceStatusOptions,
} from "../../../../utils/helper";
import { getPayrunWithMonthAndYear } from "../../../../services/payRun";
import {
  updateAttendance,
  getEmployeeMonthAttendance,
} from "../../../../services/attendance";
import Select from "react-select";
import { adminStyles, adminStylesLight } from "../../../../utils/selectStyles";
import moment from "moment-timezone";
import { useSelector } from "react-redux";

// import "./index.css";

const ViewAttendance = () => {
  const location = useLocation();
  const [isLocked, setIsLocked] = useState(false);
  const [edit, setEdit] = useState({});
  const [data, setData] = useState([]);

  useEffect(() => {
    const { month, year } = location?.state?.period;
    const { uid } = location?.state?.data;
    getEmployeeMonthAttendance({ month, year, uid })
      .then(({ data }) => setData(data[0]))
      .catch(() => customToast.error("Unable to fetch attendance"));
  }, []);
  // console.log(location.state.data);

  // UNCOMMENT THIS IF YOU WANT TO LOCK EDIT ATTENDANCE ON THE BASIS OF PAYRUN STATUS
  // useEffect(() => {
  //   const { month, year } = location?.state?.period;
  //   getPayrunWithMonthAndYear({ month: month?.value, year: year?.label })
  //     .then(({ data }) => {
  //       if (data?.length) {
  //         setIsLocked(data[0].paidStatus);
  //       }
  //     })
  //     .catch(() => console.error("Somthing went wrong"));
  // }, []);

  const columns = useMemo(() => {
    let initial = [
      {
        Header: "S No.",
        accessor: "sno",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          // console.log(props.row.original);
          return <span>{props?.row?.index + 1 || "_"}</span>;
        },
      },
      {
        Header: "Date",
        accessor: "date",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return <span>{`${props?.row?.original?.date}` || "_"}</span>;
        },
      },
      {
        Header: "Day",
        accessor: "day",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          const date = moment
            .tz(props?.row?.original?.date, "Asia/Kolkata")
            .format();
          const day = new Date(date).getDay();

          return <span>{generateWeekDays(day) || "_"}</span>;
        },
      },
      {
        Header: "Current Status",
        accessor: "currentStatus",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          const color = {
            present: "text-[#2ECC71]",
            absent: "text-[#E74C3C]",
            leave: "text-[#E67E22]",
            holiday: "text-[#F1C40F]",
          };
          const { status } = props?.row?.original;

          return (
            <ToggleStatus
              isEditMode={edit}
              color={color}
              status={status}
              setEdit={setEdit}
              edit={edit}
              row={props?.row?.index}
            />
          );
        },
      },
      {
        Header: "Previous Status",
        accessor: "previous",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return <span>{props?.row?.original?.initialStatus || "_"}</span>;
        },
      },

      {
        Header: "Actions",
        accessor: "actions",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return (
            <span>
              {isLocked ? (
                <span className="text-[#21c38b] flex justify-center h-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M6 22q-.825 0-1.412-.587T4 20V10q0-.825.588-1.412T6 8h1V6q0-2.075 1.463-3.537T12 1t3.538 1.463T17 6v2h1q.825 0 1.413.588T20 10v10q0 .825-.587 1.413T18 22zm6-5q.825 0 1.413-.587T14 15t-.587-1.412T12 13t-1.412.588T10 15t.588 1.413T12 17M9 8h6V6q0-1.25-.875-2.125T12 3t-2.125.875T9 6z"
                    />
                  </svg>
                </span>
              ) : (
                <EditToggleBox
                  setData={setData}
                  uid={location?.state?.data?.uid}
                  setEdit={setEdit}
                  row={props?.row?.index}
                  edit={edit}
                  date={props?.row?.original?.date}
                />
              )}
            </span>
          );
        },
      },
    ];

    return initial;
  }, [location, isLocked, edit]);

  return (
    <main className="main-content">
      <div className="area-creation-content">
        <PageTitle
          title="Attendance"
          route={"/attendance/sync-attendance/regular-attendance"}
        />
      </div>
      <section>
        <div className="flex gap-8">
          <div className="flex gap-2 items-center">
            <div className="w-6 h-6 bg-[#2ECC71] rounded-full"></div>
            <h4 className="font-medium text-3xl">
              Present: <span className="text-[#2ECC71]">{data?.present}</span>
            </h4>
          </div>
          <div className="flex gap-2 items-center">
            <div className="w-6 h-6 bg-[#E74C3C] rounded-full"></div>
            <h4 className="font-medium text-3xl">
              Absent: <span className="text-[#E74C3C]">{data?.absent}</span>
            </h4>
          </div>
          <div className="flex gap-2 items-center">
            <div className="w-6 h-6 bg-[#E67E22] rounded-full"></div>
            <h4 className="font-medium text-3xl">
              Leave: <span className="text-[#E67E22]">{data?.leaves}</span>
            </h4>
          </div>
          <div className="flex gap-2 items-center">
            <div className="w-6 h-6 bg-[#F1C40F] rounded-full"></div>
            <h4 className="font-medium text-3xl">
              Holiday: <span className="text-[#F1C40F]">{data?.holidays}</span>
            </h4>
          </div>
        </div>

        <div className="filter-table">
          <Table
            columns={columns}
            data={data?.attendances ?? []}
            defaultPageSize={35}
          />
        </div>
      </section>
    </main>
  );
};

export default ViewAttendance;

const EditToggleBox = ({ setEdit, row, edit, date, uid, setData }) => {
  const location = useLocation();
  const handleUpdate = async () => {
    if (edit[row]) {
      const res = await updateAttendance({ date, uid, status: edit[row] });
      const { year, month } = location?.state?.period;
      console.log({ month, year, uid });
      const { data } = await getEmployeeMonthAttendance({ month, year, uid });
      setData(data[0]);

      customToast.success("Attendance updated successfully");
    }

    setEdit((pre) => {
      if (pre[row]) return { ...pre, [row]: false };
      if (!pre[row]) return { ...pre, [row]: true };
    });
  };
  return (
    <span
      onClick={handleUpdate}
      className="text-[#21c38b] flex justify-center h-full cursor-pointer"
    >
      {!edit[row] ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="22"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M3 21v-4.25L16.2 3.575q.3-.275.663-.425t.762-.15t.775.15t.65.45L20.425 5q.3.275.438.65T21 6.4q0 .4-.137.763t-.438.662L7.25 21zM17.6 7.8L19 6.4L17.6 5l-1.4 1.4z"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="22"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="m9.55 18l-5.7-5.7l1.425-1.425L9.55 15.15l9.175-9.175L20.15 7.4z"
          />
        </svg>
      )}
    </span>
  );
};

const ToggleStatus = ({ color, status, isEditMode, setEdit, row, edit }) => {
  const theme = useSelector((state) => state.theme.theme);
  const [value, setValue] = useState({ value: "", label: status });
  const handleChange = (e) => {
    setEdit((pre) => ({ ...pre, [row]: e.value }));
  };
  // const [isEditMode, setEditMode] = useState(false);
  return (
    <span>
      {isEditMode[row] ? (
        <Select
          placeholder={status}
          styles={theme == "light" ? adminStylesLight : adminStyles}
          options={attendanceStatusOptions}
          value={edit[row] == true ? value : { value: "", label: edit[row] }}
          onChange={handleChange}
          // onChange={(e) => handleChange(e)}
        />
      ) : (
        // <Select
        //   options={attendanceStatusOptions}
        //   // onChange={({ value, label }) => setValue({ value, label })}
        //   placeholder={status}
        // />
        <span className={`capitalize ${color[status]}`}>{status || "_"}</span>
      )}
    </span>
  );
};
