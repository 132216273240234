import { useEffect, useState, useMemo, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import customToast from "../../../components/CustomToast";
import PageTitle from "../../../components/PageTitle";
import { adminStylesLight, adminStyles } from "../../../utils/selectStyles";
import { generateSelectData } from "../../../utils/serialiseResponse";

import { setDivisions } from "../../../reducers/divisionReducer";
import { setDesignations } from "../../../reducers/designationReducer";

import {
  GetAutoFillData,
  createEmployee,
  getCompanyWorkingAddress,
} from "../../../services/hrms";
import "./create.css";
import { setDepartments } from "../../../reducers/departmentReducer";
import { fetchStates } from "../../../reducers/stateReducer";
import { fetchAllCities, fetchCities } from "../../../reducers/cityReducer";
import Accordion from "../../../components/Accordion";
import { fetchEmployees } from "../../../reducers/employeesReducer";
import { setHeadquarters } from "../../../reducers/locationReducer";
import { fetchPtDetails } from "../../../services/salaryTempelates";

const genderOptions = [
  { value: 0, label: "Male" },
  { value: 1, label: "Female" },
  { value: 2, label: "Other" },
];

const maritalOptions = [
  { value: 0, label: "Married" },
  { value: 1, label: "UnMarried" },
  { value: 2, label: "Not Disclosed" },
];

const initialState = {
  firstName: "",
  middleName: "",
  lastName: "",
  phone: "",
  email: "",
  gender: "",
  maritalStatus: "",
  marriageDate: "",
  birthDate: "",
  panNumber: "",
  aadharNumber: "",
  fatherName: "",
  personalEmail: "",
  paymentDetails: {},
  employeeId: "",
  joiningDate: "",
  headquarter: "",
  division: "",
  des: "",
  reportingManager: "",
  dateOfConfirmation: "",
  category: "",
  employeeType: "",
  epfNumber: "",
  esiNumber: "",
  uanNumber: "",
  workState: "",
  replaceWhom: "",
  department: "",
  jobType: "",
  subDivision: "",
  officeNumber: "",
  healthInsuranceNumber: "",
  linNumber: "",
  street1: "",
  street2: "",
  city: "",
  state: "",
  residentialStreet1: "",
  residentialStreet2: "",
  residentialCity: "",
  residentialState: "",
  officeAddress: "",
  paymentType: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_FIELD":
      return {
        ...state,
        [action.field]: action.value,
      };
    default:
      return state;
  }
};

export const selectStyle = {
  control: (provided, state) => ({
    ...provided,
    padding: "0rem 1rem",
    backgroundColor: "#2B2C47",
    color: "#C7C4E9",
    border: "5px",
    borderRadius: "5px",
    boxShadow: "none",
    minHeight: "3.6rem",
    width: "100%",

    // marginTop: "1rem",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    color: "#9E9AD1",

    "&:hover": {
      backgroundColor: "#393b5f",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#9e9ad0",
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    width: "100%",
  }),
  input: (provided, state) => ({
    ...provided,
    color: "#fff",
    border: "none",
    width: "100%",
  }),
  multiValue: (provided, state) => ({
    ...provided,
    color: "#9e9ad0",
    backgroundColor: "#393b5f",
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: "#9e9ad0",
    backgroundColor: "#393b5f",
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    color: "#9e9ad0",
    backgroundColor: "#393b5f",
    ":hover": {
      backgroundColor: "#9e9ad0",
      color: "white",
    },
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#C7C4E9",

    "&:hover": {
      color: "#9E9AD1",
    },
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#9E9AD1",
    marginLeft: "0.5rem",
  }),
  indicatorSeparator: () => null,
};
export const ptStyle = {
  control: (provided, state) => ({
    ...provided,
    padding: "0rem 1rem",
    backgroundColor: "#353456",
    color: "#C7C4E9",
    border: "5px",
    borderRadius: "5px",
    boxShadow: "none",
    minHeight: "3.6rem",
    width: "100%",

    // marginTop: "1rem",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#353456",
    color: "#9E9AD1",

    "&:hover": {
      backgroundColor: "#393b5f",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#9e9ad0",
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#353456",
    width: "100%",
  }),
  input: (provided, state) => ({
    ...provided,
    color: "#fff",
    border: "none",
    width: "100%",
  }),
  multiValue: (provided, state) => ({
    ...provided,
    color: "#9e9ad0",
    backgroundColor: "#393b5f",
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: "#9e9ad0",
    backgroundColor: "#393b5f",
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    color: "#9e9ad0",
    backgroundColor: "#393b5f",
    ":hover": {
      backgroundColor: "#9e9ad0",
      color: "white",
    },
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#C7C4E9",

    "&:hover": {
      color: "#9E9AD1",
    },
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#9E9AD1",
    marginLeft: "0.5rem",
  }),
  indicatorSeparator: () => null,
};

const lightStyle = {
  control: (provided, state) => ({
    ...provided,
    padding: "0rem 1rem",
    backgroundColor: "#E6EDF3",
    color: "#C7C4E9",
    border: "5px",
    borderRadius: "5px",
    boxShadow: "none",
    minHeight: "3.5rem",
    maxHeight: "4.5rem",
    width: "100%",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#e6edf3",
    color: "#25253d",

    "&:hover": {
      backgroundColor: "#cde8ff",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#9e9ad0",
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    width: "100%",
  }),
  input: (provided, state) => ({
    ...provided,
    color: "#fff",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#C7C4E9",

    "&:hover": {
      color: "#25253d",
    },
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#25253d",
  }),
  indicatorSeparator: () => null,
};

const CreateEmployee = () => {
  const initialStatuatoryInfoShow = {
    epf: false,
    uan: false,
    esi: false,
    pt: false,
  };
  const dispatch = useDispatch();
  // const loggedIn = useSelector(({ user }) => user);
  // const divisions = useSelector(({ divisions }) => divisions);
  // const state = useSelector((state) => state.st
  const [formData, dispatcher] = useReducer(reducer, initialState);
  const location = useSelector(({ location }) => location);
  const designations = useSelector(({ designations }) => designations);
  const department = useSelector(({ department }) => department);

  // const [accountName, setAccountName] = useState("");
  // const [accountLastName, setAccountLastName] = useState("");
  // const [bankName, setBankName] = useState("");
  // const [bankAccountNumber, setBankAccountNumber] = useState("");
  // const [reEnterAccNo, setReEnterAccNo] = useState("");
  // const [ifsc, setIfsc] = useState("");
  // const [accountType, setAccountType] = useState("");
  // const [chevronRotationLoc, setChevronRotationLoc] = useState(0);
  // const [chevronRotationPer, setChevronRotationPer] = useState(0);
  // const [chevronRotationStatu, setChevronRotationStatu] = useState(0);
  // const [chevronRotationPay, setChevronRotationPay] = useState(0);
  // const [chevronRotation, setChevronRotation] = useState(0);
  // const [openAccInfo, setopenAccInfo] = useState(false);
  // const [locDragUp, setLocDrapUp] = useState(false);
  // const [perDragUp, setPerDrapUp] = useState(false);
  // const [statuDragUp, setStatuDrapUp] = useState(false);
  // const [payDragUp, setPayDrapUp] = useState(false);
  const [paymentType, setPaymentType] = useState("");
  const [uid, setUid] = useState("");
  const [addressData, setAddressData] = useState(null);
  const [isSubmited, setIsSubmited] = useState(false);
  const [statuatoryInfoShowState, setStatuatoryInfoShowState] = useState(
    initialStatuatoryInfoShow,
  );
  const [ptData, setPtData] = useState([]);
  const [medornUid, setMedornUid] = useState(null);

  const handleChange = (field, value) => {
    dispatcher({
      type: "UPDATE_FIELD",
      field,
      value,
    });
  };

  const handleCheckboxChange = (option) => {
    if (paymentType === option) {
      setPaymentType("");
    } else {
      setPaymentType(option);
    }
  };
  const headquarterSelect = useMemo(
    () => generateSelectData(location.hq, "name"),
    [location.hq],
  );
  const departmentSelect = useMemo(
    () => generateSelectData(department.data, "name"),
    [department.data],
  );

  const designationSelect = useMemo(
    () => generateSelectData(designations.data, "name"),
    [designations.data],
  );
  const { data: divisions } = useSelector(({ divisions }) => divisions);

  useEffect(() => {
    dispatch(setDivisions());
    dispatch(setDesignations());
    dispatch(setDepartments());
    dispatch(fetchStates("state"));
    dispatch(fetchAllCities());
    dispatch(setHeadquarters());
    dispatch(setDivisions());
  }, [dispatch]);

  useEffect(() => {
    getCompanyWorkingAddress({})
      .then(({ data }) => setAddressData(data))
      .catch((err) => {
        customToast.error("Server Error");
      });

    fetchPtDetails()
      .then((res) => {
        setPtData(res.data[0]?.stateWiseInfo);
      })
      .catch(() => {
        return customToast.error("Error fetching pt data !");
      });
  }, []);

  const selectOfficeAddress = useMemo(() => {
    if (addressData?.length > 0) {
      return generateSelectData(addressData, "officeName");
    }
    return [];
  }, [addressData]);

  const selectDivision = useMemo(() => {
    if (divisions?.length > 0) {
      return generateSelectData(divisions, "name");
    }
    return [];
  }, [divisions]);

  const ptDetailsOption = useMemo(() => {
    const options = [];
    ptData?.forEach((pt) => {
      options.push({ label: pt?.state?.name, value: pt?.state?._id, ...pt });
    });
    // console.log(options);

    return options;
  }, [ptData]);

  const handleAutoFill = async (e) => {
    e.preventDefault();
    if (!uid) return customToast.error("Please Enter Uid To AutoFill !!");
    try {
      const data = await GetAutoFillData(uid);
      Object.keys(data).forEach((key) => {
        if (key == "gender") {
          genderOptions.forEach((obj) => {
            if (obj.label == data[key]) {
              handleChange(key, obj);
            }
          });
        } else if (key == "des") {
          designationSelect.forEach((obj) => {
            if (obj.label == data[key]) {
              handleChange(key, obj);
            }
          });
        } else if (key == "headquarter") {
          headquarterSelect.forEach((obj) => {
            if (obj.label == data[key]) {
              handleChange(key, obj);
            }
          });
        } else if (
          key == "birthDate" ||
          key == "joiningDate" ||
          key == "dateOfConfirmation"
        ) {
          const formatedDate = new Date(data[key]).toISOString().slice(0, 10);
          handleChange(key, formatedDate);
        } else if (key == "uid") {
          setMedornUid(data[key]);
        } else {
          handleChange(key, data[key]);
        }
      });

      setUid("");
    } catch (error) {
      customToast.error("Something went wrong !!");
    }
  };

  const resetForm = () => {
    dispatcher({ type: "UPDATE_FIELD", field: "firstName", value: "" });
    dispatcher({ type: "UPDATE_FIELD", field: "middleName", value: "" });
    dispatcher({ type: "UPDATE_FIELD", field: "lastName", value: "" });
    dispatcher({ type: "UPDATE_FIELD", field: "phone", value: "" });
    dispatcher({ type: "UPDATE_FIELD", field: "email", value: "" });
    dispatcher({ type: "UPDATE_FIELD", field: "gender", value: "" });
    dispatcher({ type: "UPDATE_FIELD", field: "maritalStatus", value: "" });
    dispatcher({ type: "UPDATE_FIELD", field: "marriageDate", value: "" });
    dispatcher({ type: "UPDATE_FIELD", field: "birthDate", value: "" });
    dispatcher({ type: "UPDATE_FIELD", field: "panNumber", value: "" });
    dispatcher({ type: "UPDATE_FIELD", field: "aadharNumber", value: "" });
    dispatcher({ type: "UPDATE_FIELD", field: "fatherName", value: "" });
    dispatcher({ type: "UPDATE_FIELD", field: "personalEmail", value: "" });
    dispatcher({ type: "UPDATE_FIELD", field: "paymentDetails", value: {} });
    dispatcher({ type: "UPDATE_FIELD", field: "employeeId", value: "" });
    dispatcher({ type: "UPDATE_FIELD", field: "joiningDate", value: "" });
    dispatcher({ type: "UPDATE_FIELD", field: "headquarter", value: "" });
    dispatcher({ type: "UPDATE_FIELD", field: "division", value: "" });
    dispatcher({ type: "UPDATE_FIELD", field: "des", value: "" });
    dispatcher({ type: "UPDATE_FIELD", field: "reportingManager", value: "" });
    dispatcher({
      type: "UPDATE_FIELD",
      field: "dateOfConfirmation",
      value: "",
    });
    dispatcher({ type: "UPDATE_FIELD", field: "category", value: "" });
    dispatcher({ type: "UPDATE_FIELD", field: "employeeType", value: "" });
    dispatcher({ type: "UPDATE_FIELD", field: "epfNumber", value: "" });
    dispatcher({ type: "UPDATE_FIELD", field: "esiNumber", value: "" });
    dispatcher({ type: "UPDATE_FIELD", field: "uanNumber", value: "" });
    dispatcher({ type: "UPDATE_FIELD", field: "workState", value: "" });
    dispatcher({ type: "UPDATE_FIELD", field: "replaceWhom", value: "" });
    dispatcher({ type: "UPDATE_FIELD", field: "department", value: "" });
    dispatcher({ type: "UPDATE_FIELD", field: "jobType", value: "" });
    dispatcher({ type: "UPDATE_FIELD", field: "subDivision", value: "" });
    dispatcher({ type: "UPDATE_FIELD", field: "officeNumber", value: "" });
    dispatcher({
      type: "UPDATE_FIELD",
      field: "healthInsuranceNumber",
      value: "",
    });
    dispatcher({ type: "UPDATE_FIELD", field: "linNumber", value: "" });
    dispatcher({ type: "UPDATE_FIELD", field: "street1", value: "" });
    dispatcher({ type: "UPDATE_FIELD", field: "street2", value: "" });
    dispatcher({ type: "UPDATE_FIELD", field: "city", value: "" });
    dispatcher({ type: "UPDATE_FIELD", field: "state", value: "" });

    dispatcher({ type: "UPDATE_FIELD", field: "paymentType", value: "" });
    dispatcher({ type: "UPDATE_FIELD", field: "accountType", value: "" });
    dispatcher({ type: "UPDATE_FIELD", field: "accountName", value: "" });
    dispatcher({ type: "UPDATE_FIELD", field: "accountLastName", value: "" });
    dispatcher({ type: "UPDATE_FIELD", field: "bankName", value: "" });
    dispatcher({ type: "UPDATE_FIELD", field: "ifsc", value: "" });
    dispatcher({ type: "UPDATE_FIELD", field: "bankAccountNumber", value: "" });
    dispatcher({
      type: "UPDATE_FIELD",
      field: "residentialStreet1",
      value: "",
    });
    dispatcher({
      type: "UPDATE_FIELD",
      field: "residentialStreet2",
      value: "",
    });
    dispatcher({
      type: "UPDATE_FIELD",
      field: "residentialCity",
      value: "",
    });
    dispatcher({
      type: "UPDATE_FIELD",
      field: "residentialState",
      value: "",
    });
    dispatcher({
      type: "UPDATE_FIELD",
      field: "phoneNumber",
      value: "",
    });
    dispatcher({
      type: "UPDATE_FIELD",
      field: "officeAddress",
      value: { label: "", value: "" },
    });
  };

  const handleOfficeAddressChange = (e) => {
    handleChange("street1", e?.addressLine1);
    handleChange("street2", e?.addressLine2);
    handleChange("state", e?.state);
    handleChange("city", e?.city);
  };

  // console.log(paymentType, "formData");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      formData.employeeId === "" ||
      formData.firstName === "" ||
      formData.lastName === "" ||
      formData.joiningDate === "" ||
      formData.dateOfConfirmation === "" ||
      formData.des === "" ||
      formData.gender === "" ||
      formData.email === "" ||
      formData.phoneNumber === "" ||
      formData.headquarter === "" ||
      formData.department === "" ||
      formData.street1 === "" ||
      formData.city === "" ||
      formData.state === "" ||
      formData.birthDate === "" ||
      formData.maritalStatus === "" ||
      formData.fatherName === "" ||
      formData.personalEmail === "" ||
      formData.division === ""
    ) {
      setIsSubmited(true);
      return customToast.error("Please fill all the required fields !!");
    }

    if (formData?.panNumber?.length > 10 || formData?.panNumber?.length < 10) {
      customToast.error("PAN number must be 10 character alphanumeric");
      return;
    }
    if (
      formData?.aadharNumber?.length > 12 ||
      formData?.aadharNumber?.length < 12
    ) {
      customToast.error("Aadhaar number must be 12 character alphanumeric");
      return;
    }
    if (
      formData?.epfNumber?.length > 0 &&
      (formData?.epfNumber?.length > 22 || formData?.epfNumber?.length < 22)
    ) {
      customToast.error("EPF number must be 22 character alphanumeric");
      return;
    }
    if (
      formData?.esiNumber?.length > 0 &&
      (formData?.esiNumber?.length > 17 || formData?.esiNumber?.length < 17)
    ) {
      customToast.error("ESI number must be 17 character alphanumeric");
      return;
    }
    if (
      formData?.uanNumber?.length > 0 &&
      (formData?.uanNumber?.length > 12 || formData?.uanNumber?.length < 12)
    ) {
      customToast.error("UAN number must be 12 character alphanumeric");
      return;
    }
    try {
      if (paymentType === "Bank Transfer") {
        if (
          formData.paymentDetails.bankAccountNumber ===
          formData.paymentDetails.reEnterAccNo
        ) {
          handleChange("paymentDetails", {
            ...formData.paymentDetails,
            paymentType,
          });
        } else {
          customToast.error(
            "Account Number and confirm Account number not matched",
          );
          return;
        }
      } else if (paymentType === "Cheque") {
        handleChange("paymentDetails", {
          paymentType,
        });
      }
      if (medornUid) {
        formData.uid = medornUid;
      } else {
        formData.uid = null;
      }
      await createEmployee(formData);
      dispatch(fetchEmployees());
      customToast.success("Employee Created Successfully !!");
      setStatuatoryInfoShowState(initialStatuatoryInfoShow);
      setPaymentType("");
      setIsSubmited(false);
      setMedornUid(null);
      resetForm();
    } catch (error) {
      if (error.response.data.data === "Employee already registered") {
        customToast.error("Employee already registered !!");
      } else {
        customToast.error("Something went wrong !!");
      }
    }
  };
  const theme = useSelector((state) => state.theme.theme);

  return (
    <main className="main-content">
      <div className="area-creation-content">
        <PageTitle title="Create Employee" route={"../.."} />

        <section className="admin-content">
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className={`subheading1`}>
              <span className="text-[15px]">Basic Details</span>
              <div className=" autofill_container">
                <input
                  type="text"
                  placeholder="Enter UID"
                  value={uid}
                  onChange={(e) => setUid(e.target.value)}
                />
                <button
                  className="button-blue-gradient"
                  onClick={(e) => handleAutoFill(e)}
                >
                  AutoFill
                </button>
              </div>
            </div>

            <div className="flex flex-col gap-2 ">
              <div className="row1">
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <label>
                    Employee id <span className="asterisk-imp">*</span>
                  </label>
                  <br />
                  <input
                    type="text"
                    className="me-5 mt-4"
                    style={
                      isSubmited && !formData.employeeId
                        ? { border: "solid #FF6969 1px" }
                        : {}
                    }
                    placeholder="Enter Employee Id"
                    value={formData.employeeId}
                    onChange={({ target }) =>
                      handleChange("employeeId", target?.value)
                    }
                  />
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <label>
                    First Name <span className="asterisk-imp">*</span>
                  </label>
                  <br />
                  <input
                    type="text"
                    className="me-5 mt-4"
                    style={
                      isSubmited && !formData.firstName
                        ? { border: "solid #FF6969 1px" }
                        : {}
                    }
                    placeholder="Enter First Name"
                    value={formData.firstName}
                    onChange={({ target }) =>
                      handleChange("firstName", target?.value)
                    }
                  />
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <label htmlFor="mName">Middle Name</label>
                  <br />
                  <input
                    type="text"
                    className="me-5 mt-4"
                    id="mName"
                    placeholder="Enter Middle Name"
                    value={formData.middleName}
                    onChange={({ target }) =>
                      handleChange("middleName", target?.value)
                    }
                  />
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <label htmlFor="lName">
                    Last Name <span className="asterisk-imp">*</span>
                  </label>
                  <br />
                  <input
                    type="text"
                    className="me-5 mt-4"
                    style={
                      isSubmited && !formData.lastName
                        ? { border: "solid #FF6969 1px" }
                        : {}
                    }
                    id="lName"
                    placeholder="Enter last Name"
                    value={formData.lastName}
                    onChange={({ target }) =>
                      handleChange("lastName", target?.value)
                    }
                  />
                </div>
              </div>

              <div className="row1">
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <label>
                    Date of joining <span className="asterisk-imp">*</span>
                  </label>

                  <input
                    type="date"
                    placeholder="Select Date"
                    style={
                      isSubmited && !formData.joiningDate
                        ? {
                            border: "solid #FF6969 1px",
                            boxShadow: "none",
                            borderRadius: "0%",
                          }
                        : {
                            boxShadow: "none",
                            borderRadius: "0%",
                          }
                    }
                    value={formData.joiningDate}
                    onChange={({ target }) =>
                      handleChange("joiningDate", target?.value)
                    }
                  />
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <label>
                    Date of Confirmation <span className="asterisk-imp">*</span>
                  </label>
                  <br />
                  <input
                    type="date"
                    style={
                      isSubmited && !formData.dateOfConfirmation
                        ? {
                            border: "solid #FF6969 1px",
                            boxShadow: "none",
                            borderRadius: "0%",
                          }
                        : {
                            boxShadow: "none",
                            borderRadius: "0%",
                          }
                    }
                    placeholder="Select Date"
                    value={formData.dateOfConfirmation}
                    onChange={({ target }) =>
                      handleChange("dateOfConfirmation", target?.value)
                    }
                  />
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <label>
                    Designation<span className="asterisk-imp">*</span>
                  </label>
                  <br />
                  <div
                    className="mt-4"
                    style={
                      isSubmited && !formData.des
                        ? { border: "solid #FF6969 1px" }
                        : {}
                    }
                  >
                    <Select
                      className="me-5  w-full"
                      isSearchable={false}
                      options={designationSelect}
                      styles={theme == "light" ? lightStyle : selectStyle}
                      value={formData.des}
                      onChange={(e) => handleChange("des", { ...e })}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <label>
                    Gender<span className="asterisk-imp">*</span>
                  </label>
                  <br />
                  <div
                    className="mt-4"
                    style={
                      isSubmited && !formData.gender
                        ? { border: "solid #FF6969 1px" }
                        : {}
                    }
                  >
                    <Select
                      isSearchable={false}
                      options={genderOptions}
                      styles={theme == "light" ? adminStylesLight : selectStyle}
                      value={formData.gender}
                      className="me-5 w-full"
                      onChange={(e) => handleChange("gender", { ...e })}
                    />
                  </div>
                </div>
              </div>

              <div className="row1">
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <label>
                    Email<span className="asterisk-imp">*</span>
                  </label>
                  <br />
                  <input
                    type="text"
                    className="me-5 mt-4"
                    style={
                      isSubmited && !formData.email
                        ? { border: "solid #FF6969 1px" }
                        : {}
                    }
                    placeholder="Enter Email"
                    value={formData.email}
                    onChange={({ target }) =>
                      handleChange("email", target?.value)
                    }
                  />
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <label>
                    Phone Number<span className="asterisk-imp">*</span>
                  </label>
                  <br />
                  <input
                    type="text"
                    pattern="[6-9]{1}[0-9]{9}"
                    maxLength={10}
                    className="me-5 mt-4"
                    style={
                      isSubmited && !formData.phoneNumber
                        ? { border: "solid #FF6969 1px" }
                        : {}
                    }
                    placeholder="Enter Number"
                    value={formData.phoneNumber}
                    onChange={({ target }) =>
                      handleChange("phoneNumber", target?.value)
                    }
                  />
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <label>
                    Headquarter<span className="asterisk-imp">*</span>
                  </label>
                  <br />
                  <div
                    className="mt-4"
                    style={
                      isSubmited && !formData.headquarter
                        ? { border: "solid #FF6969 1px" }
                        : {}
                    }
                  >
                    <Select
                      isSearchable={true}
                      options={headquarterSelect}
                      styles={theme == "light" ? adminStylesLight : selectStyle}
                      value={formData.headquarter}
                      className="me-5 w-full"
                      onChange={(e) => handleChange("headquarter", { ...e })}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <label>
                    Department<span className="asterisk-imp">*</span>
                  </label>
                  <br />
                  <div
                    className="mt-4"
                    style={
                      isSubmited && !formData.department
                        ? { border: "solid #FF6969 1px" }
                        : {}
                    }
                  >
                    <Select
                      isSearchable={false}
                      options={departmentSelect}
                      styles={theme == "light" ? adminStylesLight : selectStyle}
                      value={formData.department}
                      className=" w-full"
                      onChange={(e) => handleChange("department", { ...e })}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col">
              <Accordion
                defaultState={true}
                title="Work Location"
                content={
                  <div className="area-creation-content_form py-0 my-0">
                    <div className="row1">
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        <label>
                          Select Division{" "}
                          <span className="asterisk-imp">*</span>
                        </label>
                        <br />
                        <div
                          className="mt-4"
                          style={
                            isSubmited && !formData.division
                              ? { border: "solid #FF6969 1px" }
                              : {}
                          }
                        >
                          <Select
                            isSearchable={true}
                            options={selectDivision}
                            styles={
                              theme == "light" ? adminStylesLight : selectStyle
                            }
                            value={formData.division}
                            // onChange={(e) => handleChange("workState", { ...e })}
                            onChange={(e) => {
                              handleChange("division", { ...e });
                            }}
                            className="w-full"
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        <label>
                          Select Office Name{" "}
                          <span className="asterisk-imp">*</span>
                        </label>
                        <br />
                        <div
                          className="mt-4"
                          style={
                            isSubmited && !formData.officeAddress
                              ? { border: "solid #FF6969 1px" }
                              : {}
                          }
                        >
                          <Select
                            isSearchable={true}
                            options={selectOfficeAddress}
                            styles={
                              theme == "light" ? adminStylesLight : selectStyle
                            }
                            value={formData.officeAddress}
                            // onChange={(e) => handleChange("workState", { ...e })}
                            onChange={(e) => {
                              handleOfficeAddressChange({ ...e });
                              handleChange("officeAddress", { ...e });
                            }}
                            className="w-full"
                          />
                        </div>
                      </div>

                      {/* <div className="col-sm-12 col-md-4 col-lg-4 flex items-center justify-end capitalize font-semibold">
                        {`${formData?.street1 ? formData?.street1 : ""} ${formData?.street2 ? formData?.street2 : ""
                          }`}
                      </div> */}

                      {/* <div className="col-sm-12 col-md-4 col-lg-4 flex items-center justify-start capitalize font-semibold">
                        {` ${
                          formData?.state?.name ? formData?.state?.name : ""
                        } ${formData?.city?.name ? formData?.city?.name : ""}`}
                      </div> */}
                    </div>
                  </div>
                }
              />

              <Accordion
                defaultState={true}
                title="Residential Address"
                content={
                  <div className="area-creation-content_form py-0 my-0">
                    <div className="row1">
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        <label>street Address 1</label>
                        <br />
                        <input
                          type="text"
                          className="me-5 mt-4"
                          placeholder="Enter Street Address "
                          value={formData.residentialStreet1}
                          onChange={({ target }) =>
                            handleChange("residentialStreet1", target?.value)
                          }
                        />
                      </div>
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        <label>street Address 2</label>
                        <br />
                        <input
                          type="text"
                          className="me-5 mt-4"
                          placeholder="Enter Street Address "
                          value={formData.residentialStreet2}
                          onChange={({ target }) =>
                            handleChange("residentialStreet2", target?.value)
                          }
                        />
                      </div>
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        <label>City</label>
                        <br />
                        <input
                          type="text"
                          className="me-5 mt-4"
                          placeholder="Enter City "
                          value={formData.residentialCity}
                          onChange={({ target }) =>
                            handleChange("residentialCity", target?.value)
                          }
                        />
                      </div>
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        <label>state</label>
                        <br />
                        <input
                          type="text"
                          className="me-5 mt-4"
                          placeholder="Enter State "
                          value={formData.residentialState}
                          onChange={({ target }) =>
                            handleChange("residentialState", target?.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                }
              />

              <Accordion
                defaultState={true}
                title="Personal Info"
                content={
                  <div className="area-creation-content_form">
                    <div className="row1 py-2">
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        <label>
                          Date of birth<span className="asterisk-imp">*</span>
                        </label>
                        <br />
                        <input
                          type="date"
                          className="me-5 mt-4"
                          style={
                            isSubmited && !formData.birthDate
                              ? { border: "solid #FF6969 1px" }
                              : {}
                          }
                          placeholder="Select Date"
                          value={formData.birthDate}
                          onChange={({ target }) =>
                            handleChange("birthDate", target?.value)
                          }
                        />
                      </div>
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        <label>Pan Number</label>
                        <br />
                        <input
                          type="text"
                          className="me-5 mt-4"
                          maxLength={10}
                          placeholder="Enter PAN Number"
                          value={formData.panNumber}
                          onChange={({ target }) => {
                            handleChange("panNumber", target?.value);
                          }}
                        />
                      </div>
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        <label>Aadhar Number</label>
                        <br />
                        <input
                          type="text"
                          maxLength={12}
                          className="me-5 mt-4"
                          placeholder="Enter Aadhar Number"
                          value={formData.aadharNumber}
                          onChange={({ target }) =>
                            handleChange("aadharNumber", target?.value)
                          }
                        />
                      </div>
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        <label>
                          Marital Status<span className="asterisk-imp">*</span>
                        </label>
                        <br />
                        <div
                          className="mt-4"
                          style={
                            isSubmited && !formData.maritalStatus
                              ? { border: "solid #FF6969 1px" }
                              : {}
                          }
                        >
                          <Select
                            options={maritalOptions}
                            styles={
                              theme == "light" ? adminStylesLight : selectStyle
                            }
                            value={formData.maritalStatus}
                            className=""
                            onChange={(e) =>
                              handleChange("maritalStatus", { ...e })
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        <label>
                          Father's Name <span className="asterisk-imp">*</span>
                        </label>
                        <br />
                        <input
                          type="text"
                          className="me-5 mt-4"
                          placeholder="Enter Father Name"
                          style={
                            isSubmited && !formData.fatherName
                              ? { border: "solid #FF6969 1px" }
                              : {}
                          }
                          value={formData.fatherName}
                          onChange={({ target }) =>
                            handleChange("fatherName", target?.value)
                          }
                        />
                      </div>
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        <label>
                          Personal Email Address{" "}
                          <span className="asterisk-imp">*</span>
                        </label>
                        <br />
                        <input
                          type="text"
                          className="me-5 mt-4"
                          style={
                            isSubmited && !formData.personalEmail
                              ? { border: "solid #FF6969 1px" }
                              : {}
                          }
                          placeholder="Enter Personal Email"
                          value={formData.personalEmail}
                          onChange={({ target }) =>
                            handleChange("personalEmail", target?.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                }
              />

              <Accordion
                defaultState={true}
                title="Statuatory Info"
                content={
                  <div className="area-creation-content_form">
                    <div className="flex flex-wrap justify-between my-4 mx-8 py-2 gap-y-4">
                      <div className="w-[48%] bg-primary px-4 rounded-lg h-fit">
                        <div className="flex items-center justify-between w-full ">
                          <label
                            className="mt-6 cursor-pointer"
                            htmlFor="statu_opt-1"
                          >
                            Does this employee have EPF Number
                          </label>
                          <input
                            type="checkbox"
                            name=""
                            id="statu_opt-1"
                            checked={statuatoryInfoShowState.epf}
                            className="check-box green_b"
                            onChange={(e) =>
                              setStatuatoryInfoShowState((pre) => ({
                                ...pre,
                                epf: e.target.checked,
                              }))
                            }
                          />
                        </div>
                        {statuatoryInfoShowState.epf && (
                          <div
                            className={`${
                              statuatoryInfoShowState.epf ? "initial" : "hidden"
                            } slider`}
                          >
                            <label>EPF Number</label>
                            <br />
                            <input
                              type="text"
                              maxLength={22}
                              className="me-5 mt-4"
                              placeholder="Enter EPF Number"
                              value={formData.epfNumber}
                              onChange={({ target }) => {
                                target?.value?.length > 22
                                  ? customToast.error(
                                      "EPF number must be 22 character alphanumeric",
                                    )
                                  : handleChange("epfNumber", target?.value);
                              }}
                            />
                          </div>
                        )}
                      </div>
                      <div className="w-[48%] bg-primary px-4 rounded-lg h-fit">
                        <div
                          className="flex items-center justify-between w-full cursor-pointer"
                          onClick={(e) =>
                            setStatuatoryInfoShowState((pre) => ({
                              ...pre,
                              uan: e.target.checked,
                            }))
                          }
                        >
                          <label className="mt-6" htmlFor="statu_opt-2">
                            Does this employee have UAN Number
                          </label>
                          <input
                            type="checkbox"
                            name=""
                            id="statu_opt-2"
                            checked={statuatoryInfoShowState.uan}
                            className="check-box green_b"
                          />
                        </div>
                        {statuatoryInfoShowState.uan && (
                          <div
                            className={`${
                              statuatoryInfoShowState.uan ? "initial" : "hidden"
                            } slider`}
                          >
                            <label>Uan number</label>
                            <br />
                            <input
                              type="text"
                              className="me-5 mt-4"
                              maxLength={12}
                              placeholder="Enter UAN Number"
                              value={formData.uanNumber}
                              onChange={({ target }) => {
                                target?.value?.length > 12
                                  ? customToast.error(
                                      "UAN number must be 12 character alphanumeric",
                                    )
                                  : handleChange("uanNumber", target?.value);
                              }}
                            />
                          </div>
                        )}
                      </div>
                      <div className="w-[48%] bg-primary px-4 rounded-lg h-fit">
                        <div
                          className="flex items-center justify-between w-full"
                          onClick={(e) =>
                            setStatuatoryInfoShowState((pre) => ({
                              ...pre,
                              esi: e.target.checked,
                            }))
                          }
                        >
                          <label
                            className="mt-6 cursor-pointer"
                            htmlFor="statu_opt-3"
                          >
                            Does this employee have ESI Number
                          </label>
                          <input
                            type="checkbox"
                            name=""
                            id="statu_opt-3"
                            checked={statuatoryInfoShowState.esi}
                            className="check-box green_b"
                          />
                        </div>
                        {statuatoryInfoShowState.esi && (
                          <div className="slider">
                            <label>ESI Number</label>
                            <br />
                            <input
                              type="text"
                              maxLength={17}
                              className="me-5 mt-4"
                              placeholder="Enter ESI Number"
                              value={formData.esiNumber}
                              onChange={({ target }) => {
                                target?.value?.length > 17
                                  ? customToast.error(
                                      "UAN number must be 12 character alphanumeric",
                                    )
                                  : handleChange("esiNumber", target?.value);
                              }}
                            />
                          </div>
                        )}
                      </div>
                      <div className="w-[48%] bg-primary px-4 rounded-lg h-fit">
                        <div
                          className="flex items-center justify-between w-full "
                          onClick={(e) =>
                            setStatuatoryInfoShowState((pre) => ({
                              ...pre,
                              pt: e.target.checked,
                            }))
                          }
                        >
                          <label
                            className="mt-6 cursor-pointer"
                            htmlFor="statu_opt-4"
                          >
                            Does this employee have Provisional tax
                          </label>
                          <input
                            type="checkbox"
                            name=""
                            id="statu_opt-4"
                            checked={statuatoryInfoShowState.pt}
                            className="check-box green_b"
                          />
                        </div>
                        {statuatoryInfoShowState.pt && (
                          <div className="mb-4">
                            <label>State</label>
                            <br />
                            <Select
                              className="me-5  w-full"
                              isSearchable={false}
                              options={ptDetailsOption}
                              styles={ptStyle}
                              value={formData.ptDetails}
                              onChange={(e) =>
                                handleChange("ptDetails", { ...e })
                              }
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                }
              />

              <Accordion
                defaultState={true}
                title="Payment Details"
                content={
                  <div>
                    <label htmlFor="bnk-trnsfr">
                      <div className={`subheading1 cursor-pointer`}>
                        <span className="text-2xl">
                          Bank Transfer
                          <span className="ml-4 text-lg text-green-600">
                            (Manual Payment Process)
                          </span>
                        </span>
                        <div className="flex gap-4 items-center">
                          <input
                            type="checkbox"
                            className={
                              isSubmited && !paymentType
                                ? "check-box red_b"
                                : "check-box green_b"
                            }
                            checked={paymentType === "Bank Transfer"}
                            id="bnk-trnsfr"
                            // onChange={() => handleCheckboxChange("Bank Transfer")}
                            onChange={({ target }) => {
                              handleChange("paymentDetails", {
                                ...formData.paymentDetails,
                                paymentType: "Bank Transfer",
                              });
                              handleCheckboxChange("Bank Transfer");
                            }}
                          />
                        </div>
                      </div>
                    </label>
                    {paymentType === "Bank Transfer" && (
                      <div className="flex flex-col smallbdr">
                        <div className="row">
                          <div className="col-sm-12 col-md-4 col-lg-4">
                            <div className="flex w-fit gap-4 !h-4 items-center mb-2">
                              <input
                                type="checkbox"
                                checked={
                                  formData.paymentDetails.accountType ===
                                  "savings"
                                }
                                className="check-box-active blue_b padding-checkbox"
                                onClick={() =>
                                  handleChange("paymentDetails", {
                                    ...formData.paymentDetails,
                                    accountType: "savings",
                                  })
                                }
                              />
                              <label className="mt-10">Saving Account</label>
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4 col-lg-4">
                            <div className="flex w-fit gap-4 !h-4 items-center mb-2">
                              <input
                                type="checkbox"
                                checked={
                                  formData.paymentDetails.accountType ===
                                  "current"
                                }
                                className="check-box-active blue_b padding-checkbox"
                                onClick={() =>
                                  handleChange("paymentDetails", {
                                    ...formData.paymentDetails,
                                    accountType: "current",
                                  })
                                }
                              />
                              <label className="mt-10">Current Account</label>
                            </div>
                          </div>
                        </div>

                        <div>
                          <div className="row">
                            <div className="col-sm-12 col-md-4 col-lg-4">
                              <label>
                                Account Name{" "}
                                <span className="asterisk-imp">*</span>
                              </label>
                              <br />
                              <input
                                type="text"
                                className="me-5 mt-4"
                                placeholder="Enter Account Name"
                                value={
                                  formData.paymentDetails.accountName || ""
                                }
                                onChange={({ target }) =>
                                  handleChange("paymentDetails", {
                                    ...formData.paymentDetails,
                                    accountName: target.value,
                                  })
                                }
                              />
                            </div>

                            <div className="col-sm-12 col-md-4 col-lg-4">
                              <label>
                                Account Last Name{" "}
                                <span className="asterisk-imp">*</span>
                              </label>
                              <br />
                              <input
                                type="text"
                                className="me-5 mt-4"
                                placeholder="Enter Account Last Name"
                                value={
                                  formData.paymentDetails.accountLastName || ""
                                }
                                onChange={({ target }) =>
                                  handleChange("paymentDetails", {
                                    ...formData.paymentDetails,
                                    accountLastName: target.value,
                                  })
                                }
                              />
                            </div>

                            <div className="col-sm-12 col-md-4 col-lg-4">
                              <label>
                                Bank Name{" "}
                                <span className="asterisk-imp">*</span>
                              </label>
                              <br />
                              <input
                                type="text"
                                className="me-5 mt-4"
                                placeholder="Enter Bank Name"
                                value={formData.paymentDetails.bankName || ""}
                                onChange={({ target }) =>
                                  handleChange("paymentDetails", {
                                    ...formData.paymentDetails,
                                    bankName: target.value,
                                  })
                                }
                              />
                            </div>

                            <div className="col-sm-12 col-md-4 col-lg-4">
                              <label>
                                Ifsc code{" "}
                                <span className="asterisk-imp">*</span>
                              </label>
                              <br />
                              <input
                                type="text"
                                className="me-5 mt-4"
                                placeholder="Enter IFSC"
                                value={formData.paymentDetails.ifsc || ""}
                                onChange={({ target }) =>
                                  handleChange("paymentDetails", {
                                    ...formData.paymentDetails,
                                    ifsc: target.value,
                                  })
                                }
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-12 col-md-4 col-lg-4">
                              <label>
                                Bank Account Number{" "}
                                <span className="asterisk-imp">*</span>
                              </label>
                              <br />
                              <input
                                type="text"
                                className="me-5 mt-4"
                                placeholder="Enter Account Number"
                                value={
                                  formData.paymentDetails.bankAccountNumber ||
                                  ""
                                }
                                onChange={({ target }) =>
                                  handleChange("paymentDetails", {
                                    ...formData.paymentDetails,
                                    bankAccountNumber: target.value,
                                  })
                                }
                              />
                            </div>

                            <div className="col-sm-12 col-md-4 col-lg-4">
                              <label>
                                Re-Enter Account Number{" "}
                                <span className="asterisk-imp">*</span>
                              </label>
                              <br />
                              <input
                                type="text"
                                className="me-5 mt-4 "
                                placeholder="Enter Account Number"
                                value={
                                  formData.paymentDetails.reEnterAccNo || ""
                                }
                                onChange={({ target }) =>
                                  handleChange("paymentDetails", {
                                    ...formData.paymentDetails,
                                    reEnterAccNo: target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <label htmlFor="chq">
                      <div className={`subheading1 cursor-pointer`}>
                        <span className="text-2xl">Cheque</span>
                        <input
                          type="checkbox"
                          className={
                            isSubmited && !paymentType
                              ? "check-box red_b"
                              : "check-box green_b"
                          }
                          id="chq"
                          checked={paymentType === "Cheque"}
                          // onChange={() => handleCheckboxChange("Cheque")}
                          onChange={({ target }) => {
                            handleChange("paymentDetails", {
                              ...formData.paymentDetails,
                              paymentType: "Cheque",
                            });
                            handleCheckboxChange("Cheque");
                          }}
                        />
                      </div>
                    </label>
                  </div>
                }
              />
            </div>

            <div className="bg-[#25253D] py-6 col-sm-2 col-md-4 col-lg-2">
              <button type="submit" className="button-blue-gradient">
                Submit
              </button>
            </div>
          </form>
        </section>
      </div>
    </main>
  );
};

export default CreateEmployee;
