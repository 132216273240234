import React, { useState } from "react";
import Select from "react-select";
import { setUpSelectStyles } from "../../../utils/selectStyles";
import ToggleButton from "../../../components/Toggle/ToggleButton/ToggleButton";
import customToast from "../../../components/CustomToast";

const deductionTypes = [
  { value: "monthly", label: "Monthly" },
  { value: "annual", label: "Annual" },
];

const employerContributionRate = [{ value: 12, label: "12% of Basic" }];

const employeeContributionRate = [{ value: 12, label: "12% of Basic" }];

const Epf = ({ epf, setEpf }) => {
  const [toggle, setToggle] = useState(false);

  return (
    <div className="comp ">
      <div
        className="heading_container cursor-pointer"
        onClick={() => setToggle(!toggle)}
      >
        <h1>
          Employee’s Provident Fund (EPF)
          <ToggleButton
            value={epf?.enableEpf}
            onChange={() => setToggle(!toggle)}
          />
        </h1>

        <p>
          Any Company with more than 20 Employees Any Company with more than 20
          Employees Any Company with more than 20 Employees
        </p>
      </div>
      <form className={"set-up " + `${toggle ? "show" : "hide"}`}>
        <div className="two_in_one">
          <div>
            <label htmlFor="">EPF Number</label>
            <br />
            <input
              className="text_input"
              type="text"
              placeholder="AA/AAA/0000000/000"
              value={epf.epfNumber}
              onChange={(e) =>
                setEpf({
                  ...epf,
                  epfNumber: e.target.value,
                })
              }
            />
          </div>
          <div>
            <label htmlFor="">Deduction Type</label>
            <br />
            <Select
              isSearchable={false}
              styles={setUpSelectStyles}
              options={deductionTypes}
              value={epf.deductionType}
              onChange={(e) => setEpf({ ...epf, deductionType: e })}
            />
          </div>
        </div>
        <div>
          <label htmlFor="">Employer Contribution Rate</label>
          <br />
          <Select
            isSearchable={false}
            styles={setUpSelectStyles}
            options={employerContributionRate}
            value={epf.employerContributionRate}
            onChange={(e) => setEpf({ ...epf, employerContributionRate: e })}
          />
        </div>
        <div>
          <label htmlFor="">Employee Contribution Rate</label>
          <br />
          <Select
            isSearchable={false}
            styles={setUpSelectStyles}
            options={employeeContributionRate}
            value={epf.employeeContributionRate}
            onChange={(e) => setEpf({ ...epf, employeeContributionRate: e })}
          />
        </div>
        <div className="terms">
          <span className="text-green-500 my-2">View Sample Calculation</span>
          <div>
            <input
              className="checkbox_input"
              type="checkbox"
              id="epf-op-1"
              onClick={(e) => {
                setEpf({
                  ...epf,
                  calculatOnProDataBasis: e.target.checked,
                });
              }}
            />
            <label
              className="!m-0 !font-normal !text-lg cursor-pointer"
              htmlFor="epf-op-1"
            >
              Calculate on Pro-rata Basis
            </label>
          </div>
          <div>
            <input
              className="checkbox_input"
              type="checkbox"
              id="epf-op-2"
              onClick={(e) => {
                setEpf({
                  ...epf,
                  allSalaryComponentsConsideration: e.target.checked,
                });
              }}
            />
            <label
              className="!m-0 !font-normal !text-lg cursor-pointer"
              htmlFor="epf-op-2"
            >
              Consider all salary components applicable if the pay is less than
              15000 after LOP calculation
            </label>
          </div>
          <div>
            <input
              className="checkbox_input"
              type="checkbox"
              id="epf-op-3"
              onClick={(e) => {
                if (e.target.checked) {
                  setEpf({
                    ...epf,
                    includeEmployersContribution: e.target.checked,
                  });
                } else {
                  setEpf({
                    ...epf,
                    includeEmployersContribution: e.target.checked,
                    addPfAdminChargesInCtc: false,
                    addEmployersEdliContributionInCtc: false,
                  });
                }
              }}
            />
            <label
              className="!m-0 !font-normal !text-lg cursor-pointer"
              htmlFor="epf-op-3"
            >
              Include Employers Contribution in the CTC
            </label>
          </div>

          {/* we have to toggle between them */}
          {epf.includeEmployersContribution && (
            <div className="flex flex-col  !items-start pl-12">
              <div>
                <input
                  className="checkbox_input"
                  type="checkbox"
                  id="epf-op-4"
                  checked={epf.addPfAdminChargesInCtc}
                  onClick={(e) => {
                    if (e.target.checked) {
                      setEpf({
                        ...epf,
                        addPfAdminChargesInCtc: e.target.checked,
                        // addEmployersEdliContributionInCtc: false,
                      });
                    } else {
                      setEpf({
                        ...epf,
                        addPfAdminChargesInCtc: e.target.checked,
                      });
                    }
                  }}
                />
                <label
                  className="!m-0 !font-normal !text-lg cursor-pointer"
                  htmlFor="epf-op-4"
                >
                  Add PF Admin charges in CTC
                </label>
              </div>
              <div>
                <input
                  className="checkbox_input"
                  type="checkbox"
                  id="epf-op-5"
                  checked={epf.addEmployersEdliContributionInCtc}
                  onClick={(e) => {
                    if (e.target.checked) {
                      setEpf({
                        ...epf,
                        addEmployersEdliContributionInCtc: e.target.checked,
                        // addPfAdminChargesInCtc: false,
                      });
                    } else {
                      setEpf({
                        ...epf,
                        addEmployersEdliContributionInCtc: e.target.checked,
                      });
                    }
                  }}
                />
                <label
                  className="!m-0 !font-normal !text-lg cursor-pointer"
                  htmlFor="epf-op-5"
                >
                  Add Employer’s EDLI Contribution in CTC
                </label>
              </div>
            </div>
          )}

          <div>
            <input
              className="checkbox_input"
              type="checkbox"
              id="epf-op-6"
              onClick={(e) => {
                setEpf({
                  ...epf,
                  componentOnPayslip: e.target.checked,
                });
              }}
            />
            <label
              className="!m-0 !font-normal !text-lg cursor-pointer"
              htmlFor="epf-op-6"
            >
              Show this Component on Payslip
            </label>
          </div>
        </div>
        <div className="mt-4">
          <button
            className="submit_button"
            onClick={(e) => {
              e.preventDefault();
              if (
                epf.epfNumber &&
                epf.deductionType &&
                epf.employerContributionRate &&
                epf.employeeContributionRate
              ) {
                setEpf({
                  ...epf,
                  enableEpf: !epf.enableEpf,
                });
                setToggle(!toggle);
              } else customToast.error("Please fill all the fields");
            }}
          >
            {epf.enableEpf === true ? "Enabled" : "Enable"}
          </button>
          <span
            className="skip"
            onClick={(e) => {
              e.preventDefault();
              setEpf({
                ...epf,
                enableEpf: false,
              });
              setToggle(false);
            }}
          >
            cancel
          </span>
        </div>
      </form>
    </div>
  );
};

export default Epf;
