import { useEffect, useState, useMemo, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../../../components/PageTitle";
import customToast from "../../../../components/CustomToast";
import { GetAllEmployees, UploadDocument } from "../../../../services/hrms";
import { fileToBase64 } from "../../../../utils/helper";
import Select from "react-select";
import {
  fetchEmployees,
  setEmployees,
} from "../../../../reducers/employeesReducer";
import { generateSelectData } from "../../../../utils/serialiseResponse";
import { adminStyles, adminStylesLight } from "../../../../utils/selectStyles";
import { IoIosAddCircleOutline, IoIosRemoveCircleOutline } from "react-icons/io";


const selectStyle = {
  control: (provided, state) => ({
    ...provided,
    padding: "0.1rem 1rem",
    backgroundColor: "#2B2C47",
    color: "#C7C4E9",
    border: "5px",
    borderRadius: "5px",
    boxShadow: "none",
    minHeight: "3.5rem",
    maxHeight: "3.5rem",
    width: "100%",
    marginTop: "1rem",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    color: "#9E9AD1",

    "&:hover": {
      backgroundColor: "#393b5f",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#9e9ad0",
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    width: "80%",
  }),
  input: (provided, state) => ({
    ...provided,
    color: "#fff",
    border: "none",
    width: "100%",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#C7C4E9",

    "&:hover": {
      color: "#9E9AD1",
    },
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#9E9AD1",
  }),
  indicatorSeparator: () => null,
};

const VoterId = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchEmployees({}));
  }, [dispatch]);

  const employeeData = useSelector((state) => state.employees.data);

  const employeeSelectData = useMemo(
    () => generateSelectData(employeeData, "fullName"),
    [employeeData],
  );

  const [selectedEmployee, setSelectedEmployee] = useState();
  const [voterIdNumber, setVoterIdNumber] = useState("");
  const [uploadData, setUploadData] = useState([]);
  const [docs, setdocs] = useState([]);
  const [extraInput, setextraInput] = useState(false)

  let changeHandler = (e) => {
    if (e.target.files[0].size > 5242880) {
      e.target.value = '';
      customToast.error("Maximum file size should be 5 MB");
      return;
    }
    else {
      const validFiles = Array.from(e.target.files);
      const updatedDocs = [...docs, ...validFiles];
      setdocs(updatedDocs);
      const promises = updatedDocs.map(fileToBase64);
      setUploadData([]);
      Promise.all(promises)
        .then((base64Images) => {
          setUploadData(base64Images);
        })
        .catch((error) => {
          customToast.error("Error adding images");
        });
    }
  };

  const resetStates = () => {
    setSelectedEmployee("");
    setVoterIdNumber("");
    setUploadData([]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedEmployee) {
      customToast.error("Please Select Employee !!");
      return;
    }
    if (!voterIdNumber && voterIdNumber.length < 12) {
      customToast.error("Please Enter VoterId Number !!");
      return;
    }
    try {
      const payload = {
        employeeId: selectedEmployee?.value,
        docType: "voterIdCard",
        number: voterIdNumber,
        uploadData,
      };
      const response = await UploadDocument(payload);
      if (response.ok) {
        dispatch(fetchEmployees());
        customToast.success("VoterId Card Uploaded Successfully");
        resetStates();
      }
    } catch (error) {
      customToast.error("Error Uploading VoterID Card Details");
    }
  };

  const theme = useSelector((state) => state.theme.theme);
  const [inputCount, setInputCount] = useState(0);


  return (
    <main
      className="main-content bg-primaryLight2 dark:bg-primaryDark1"
    >
      <div
        className={
          "area-creation-content " + `${theme == "light" ? "light_bg" : ""}`
        }
      >
        <PageTitle title="Voter Id CARD DETAILS" route={'/employee/manage/create'} />
        <form>
          <section className="admin-content">
            <div className="row">
              <div>
                <label>Select Employee</label>
                <br />
                <Select
                  name="emp_select"
                  value={selectedEmployee}
                  styles={theme == "light" ? adminStylesLight : adminStyles}
                  options={employeeSelectData}
                  onChange={(e) => setSelectedEmployee({ ...e })}
                  placeholder="Select Employee"
                />
              </div>
              <div>
                <label>
                  Voter ID Number
                  <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                  placeholder="Enter Epic Number"
                  value={voterIdNumber}
                  onChange={({ target }) => setVoterIdNumber(target?.value)}
                />
              </div>
            </div>

            <div className="mx-[2rem] w-max">
              <label>Upload Document</label><span className="asterisk-imp">*</span>
              <br />
              <div className="flex flex-col">
                <div className="flex h-full gap-4">
                  <input type="file" onChange={changeHandler} className="dark:bg-primaryDark2 px-5 py-2 " />
                  {
                    extraInput &&
                    <input type="file" onChange={changeHandler} className="dark:bg-primaryDark2 px-5 py-2 " />
                  }
                  <div className="flex justify-center items-center mt-3">
                    {extraInput ?
                      <IoIosRemoveCircleOutline
                        className="text-4xl cursor-pointer text-[#36abf9]"
                        onClick={() => setextraInput(false)}
                      />
                      :
                      <IoIosAddCircleOutline
                        className="text-4xl cursor-pointer text-[#36abf9]"
                        onClick={() => setextraInput(true)}
                      />
                    }
                    <br />
                  </div>
                </div>
                <span className="text-lg text-gray-500 mt-2">*Please upload only JPG, JPEG or PDF format, no larger than 5 MB.</span>
              </div>
            </div>

            <div className="row pb-10">
              <button type="submit" className="button-blue-gradient" onClick={(e) => handleSubmit(e)}>
                Upload
              </button>
            </div>
          </section>
        </form>
      </div>
    </main>
  );
};

export default VoterId;
