import { createSlice } from "@reduxjs/toolkit";
import { getEpfDetails } from "../services/hrms";

const epfDetailsSlice = createSlice({
  name: "epfDetails",
  initialState: {
    data: [],
  },
  reducers: {
    setEpfDetails(state, action) {
      state.data = action.payload;
    },
  },
});

export const { setEpfDetails } = epfDetailsSlice.actions;

export const fetchEpfDetails = () => async (dispatch) => {
  try {
    const response = await getEpfDetails();
    dispatch(setEpfDetails(response));
  } catch (error) {
  }
};

export default epfDetailsSlice.reducer;
