import React, { useEffect, useMemo, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import customToast from "../../../components/CustomToast";
import { CreateEarning, GetEarningTypes } from "../../../services/earning";
import { useDispatch } from "react-redux";
import { generateSelectData } from "../../../utils/serialiseResponse";
import Select from "react-select";
import { setUpSelectStyles } from "../../../utils/selectStyles";

const Earnings = ({ onDeduction, sidePanelOpen, setSidePanelOpen }) => {
  const colums = [
    {
      name: "Earning Name",
      key: "eName",
    },
    {
      name: "Earning Type",
      key: "earningType",
    },
    {
      name: "Calculation Type",
      key: "calcType",
    },
  ];

  const [data, setData] = useState([
    {
      eName: "Basic",
      earningType: "Basic",
      calcType: {
        type: "percentage",
        value: "ctc",
      },
      paytype: "fixed",
      amount: 50,
      nPayslip: "Basic",
      active: false,
      isEpf: true,
      isEsi: true,
      proRata: true,
      onPaySlip: true,
      addToSalary: true,
      taxable: true,
    },
    {
      eName: "HRA",
      earningType: "HRA",
      calcType: {
        type: "percentage",
        value: "basic",
      },
      paytype: "fixed",
      amount: 50,
      nPayslip: "House Rent Allowance",
      active: false,
      isEpf: false,
      isEsi: true,
      proRata: false,
      onPaySlip: false,
      addToSalary: true,
      taxable: true,
    },
    {
      eName: "Conveyance Allowance",
      earningType: "Conveyance Allowance",
      calcType: {
        type: "fixed",
        value: "fixed",
      },
      paytype: "fixed",
      amount: 1600,
      nPayslip: "Conveyance Allowance",
      active: false,
      isEpf: false,
      isEsi: true,
      proRata: false,
      onPaySlip: false,
      addToSalary: false,
      taxable: false,
    },
    {
      eName: "Transport Allowance",
      earningType: "Transport Allowance",
      calcType: {
        type: "fixed",
        value: "fixed",
      },
      paytype: "fixed",
      amount: 1600,
      nPayslip: "Transport Allowance",
      active: false,
      isEpf: false,
      isEsi: true,
      proRata: false,
      onPaySlip: false,
      addToSalary: false,
      taxable: false,
    },
    {
      eName: "Travelling Allowance",
      earningType: "Travelling Allowance",
      calcType: {
        type: "fixed",
        value: "fixed",
      },
      paytype: "fixed",
      amount: 0,
      nPayslip: "Travelling Allowance",
      active: false,
      isEpf: false,
      isEsi: true,
      proRata: false,
      onPaySlip: false,
      addToSalary: false,
      taxable: false,
    },
    {
      eName: "Bonus",
      earningType: "Bonus",
      calcType: {
        type: "fixed",
        value: "fixed",
      },
      paytype: "variable",
      amount: 0,
      nPayslip: "Bonus",
      active: false,
      isEpf: false,
      isEsi: true,
      proRata: false,
      onPaySlip: false,
      addToSalary: false,
      taxable: false,
    },
    {
      eName: "Commission",
      earningType: "Commission",
      calcType: {
        type: "fixed",
        value: "fixed",
      },
      paytype: "variable",
      amount: 0,
      nPayslip: "Commission",
      active: false,
      isEpf: false,
      isEsi: true,
      proRata: false,
      onPaySlip: false,
      addToSalary: false,
      taxable: false,
    },
    {
      eName: "Fixed Allowance",
      earningType: "Fixed Allowance",
      calcType: {
        type: "fixed",
        value: "fixed",
      },
      paytype: "fixed",
      amount: 0,
      nPayslip: "Fixed Allowance",
      active: true,
      isEpf: false,
      isEsi: true,
      proRata: true,
      onPaySlip: true,
      addToSalary: true,
      taxable: true,
    },
    {
      eName: "Gratuity",
      earningType: "Gratuity",
      calcType: {
        type: "percentage",
        value: "basic",
      },
      paytype: "variable",
      amount: 4.81,
      nPayslip: "Gratuity",
      active: false,
      isEpf: false,
      isEsi: true,
      proRata: false,
      onPaySlip: false,
      addToSalary: false,
      taxable: false,
    },
    {
      eName: "Notice Pay",
      earningType: "Notice Pay",
      calcType: {
        type: "fixed",
        value: "fixed",
      },
      paytype: "variable",
      amount: 0,
      nPayslip: "Notice Pay",
      active: false,
      isEpf: false,
      isEsi: true,
      proRata: false,
      onPaySlip: false,
      addToSalary: false,
      taxable: false,
    },
    {
      eName: "Leave Encashment",
      earningType: "Leave Encashment",
      calcType: {
        type: "fixed",
        value: "fixed",
      },
      paytype: "variable",
      amount: 0,
      nPayslip: "Leave Encashment",
      active: false,
      isEpf: false,
      isEsi: true,
      proRata: false,
      onPaySlip: false,
      addToSalary: false,
      taxable: false,
    },
  ]);

  const [earnTypes, setEarnTypes] = useState([]);
  const [selected, setSelected] = useState([]);
  const [editEarning, setEditEarning] = useState(false);
  const [calculationType, setCalculationType] = useState({
    type: "percentage",
    value: "basic",
  });
  const [earningType, setEarningType] = useState(null);
  const [earningName, setEarningName] = useState(null);
  const [paySlipName, setPaySlipName] = useState(null);
  const [payType, setPayType] = useState("fixed");
  const [amount, setAmount] = useState(null);
  const [percentage, setPercentage] = useState(null);
  const [active, setActive] = useState(false);
  const [taxable, setTaxable] = useState(false);
  const [isEpf, setIsEpf] = useState(false);
  const [isEsi, setIsEsi] = useState(false);
  const [proRata, setProRata] = useState(false);
  const [onPaySlip, setOnPaySlip] = useState(false);
  const [addToSalary, setAddToSalary] = useState(false);

  useEffect(() => {
    GetEarningTypes()
      .then((res) => setEarnTypes(res?.data))
      .catch((err) => {
        console.log(err);
        customToast.error("Error Occurred !");
      });
  }, []);

  const selectEarnTypes = useMemo(
    () => generateSelectData(earnTypes, "name"),
    [earnTypes],
  );

  const handleAddSubmit = async (e) => {
    e.preventDefault();
    if (!earningName || !earningType || !paySlipName || !payType) {
      customToast.error("Please Fill All Fields !!");
    }
    try {
      const payload = {
        earningType: earningType,
        eName: earningName,
        nPayslip: paySlipName,
        active: active,
        paytype: payType,
        amount: Number(
          calculationType?.type === "percentage" ? percentage : amount,
        ),
        isEpf: isEpf,
        isEsi: isEsi,
        proRata: proRata,
        onPaySlip: onPaySlip,
        addToSalary: addToSalary,
        taxable: taxable,
        calcType: calculationType,
      };

      setSelected([...selected, payload]);
      setData((prevData) => [...prevData, payload]);
      clearStates();
      setSidePanelOpen(false);
    } catch (error) {
      customToast.error("Something went Wrong");
    }
  };

  const resetStates = () => {
    setSelected("");
  };

  const clearStates = () => {
    setEarningType(null);
    setEarningName(null);
    setPaySlipName(null);
    setActive(false);
    setAmount(null);
    setPercentage(null);
    setTaxable(false);
    setIsEpf(false);
    setIsEsi(false);
    setProRata(false);
    setOnPaySlip(false);
    setAddToSalary(false);
    setCalculationType(null);
    setPayType("fixed");
  };

  const handleUpdateChanges = async (e) => {
    e.preventDefault();
    setEditEarning(false);
    try {
      const payload = {
        earningType,
        earningName,
        paySlipName,
        active,
        amount,
        percentage,
        taxable,
        isEpf,
        isEsi,
        proRata,
        onPaySlip,
        addToSalary,
        calculationType,
        payType,
      };

      data.map((el) => {
        if (
          el?.earningType === payload?.earningType &&
          el?.nPayslip === payload?.paySlipName
        ) {
          el.eName = payload.earningName;
          (el.nPayslip = payload?.paySlipName),
            (el.active = payload?.active),
            (el.paytype = payload?.payType),
            (el.amount = Number(
              payload?.calculationType?.type === "percentage"
                ? payload?.percentage
                : payload?.amount,
            )),
            (el.isEpf = payload?.isEpf),
            (el.isEsi = payload?.isEsi),
            (el.proRata = payload?.proRata),
            (el.onPaySlip = payload?.onPaySlip),
            (el.addToSalary = payload?.addToSalary),
            (el.taxable = payload?.taxable),
            (el.calcType = payload?.calculationType);
        }
      });
      clearStates();
      setEditEarning(false);
      setSidePanelOpen(false);
      customToast.success("Updated Successfully");
    } catch (error) {
      customToast.error("Error in Updating");
    }
  };

  const handleFormSubmit = async () => {
    try {
      const response = await CreateEarning(data);
      if (response.message == "OK") {
        customToast.success("Earnings Details Uploaded Successfully");
        clearStates();
        resetStates();
        onDeduction();
      }
    } catch (error) {
      if (error.response.status && error.response.status == 403) {
        customToast.error(`${error.response.data.data} Already Exist`);
      } else {
        customToast.error("Error Uploading Deduction Details");
      }
    }
  };

  const isSelected = (row) => selected.some((item) => item.eName === row.eName);

  return (
    <div className="reimbursements">
      <div className="new_table mt-5">
        <div className="new_thead ">
          {colums.map((col) => {
            return <span>{col.name}</span>;
          })}
        </div>

        {data.map((row, index) => (
          <div
            key={index}
            className={`new_table_row ${
              selected.includes(row) ? "bg-blue-200" : ""
            }`}
          >
            {colums.map((col) => (
              <span key={col.key}>
                {col.name === "Earning Name" && (
                  <span className="flex items-center gap-2">
                    <input
                      id={`opt-${row[col.key]}`}
                      type="checkbox"
                      checked={isSelected(row)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSidePanelOpen(true);
                          setEditEarning(true);
                          setPaySlipName(row?.nPayslip);
                          setEarningName(row?.eName);
                          setEarningType(row?.earningType);
                          setPayType(row?.paytype);
                          setCalculationType(row?.calcType);

                          row?.calcType?.type === "percentage"
                            ? setPercentage(row?.amount)
                            : setAmount(row?.amount);

                          setAddToSalary(row?.addToSalary);
                          setTaxable(row?.taxable);
                          setProRata(row?.proRata);
                          setIsEpf(row?.isEpf);
                          setIsEsi(row?.isEsi);
                          setOnPaySlip(row?.onPaySlip);
                          setActive(true);

                          row.active = true;
                          setSelected([...selected, row]);
                          const updatedData = data.map((d) =>
                            d.eName === row.eName ? { ...d, active: true } : d,
                          );
                          setData(updatedData);
                        } else {
                          row.active = false;
                          setSelected(
                            selected.filter((item) => item.eName !== row.eName),
                          );
                          const updatedData = data.map((d) =>
                            d.eName === row.eName ? { ...d, active: false } : d,
                          );
                          setData(updatedData);
                        }
                      }}
                      className="checkbox_input"
                      name="select earning"
                    />
                    <label
                      className="!m-0 !font-normal !text-2xl cursor-pointer"
                      htmlFor={`opt-${row[col.key]}`}
                    >
                      {row[col.key]}
                    </label>
                  </span>
                )}
                {col.name === "Earning Type" && <span>{row[col.key]}</span>}
                {col.name === "Calculation Type" && (
                  <span>
                    {row?.calcType?.type === "fixed" ? "Fixed" : "Variable"},
                    {row?.calcType?.type === "fixed" &&
                    row?.calcType?.value === "fixed"
                      ? " Flat Amount"
                      : row?.calcType?.value === "ctc"
                      ? ` 50% of CTC`
                      : ` 50% of Basic`}
                  </span>
                )}
              </span>
            ))}
          </div>
        ))}

        <div className="flex">
          <button className="submit_button" onClick={handleFormSubmit}>
            Save & Continue
          </button>
          <span className="skip" onClick={onDeduction}>
            Skip for now
          </span>
        </div>
      </div>

      <div
        className=" side_panel "
        style={{
          width: sidePanelOpen ? "100%" : "0",
        }}
      >
        <div
          className="side_panel_form"
          style={{
            display: sidePanelOpen ? "block" : "none",
          }}
        >
          {editEarning ? (
            <form onSubmit={(e) => handleUpdateChanges(e)}>
              <div className="side_panel_form_head">
                <p>Edit Earning </p>
                <RxCross1
                  size={20}
                  color="black"
                  style={{ marginTop: "-2rem", cursor: "pointer" }}
                  onClick={() => {
                    clearStates();
                    setSidePanelOpen(false);
                    setEditEarning(false);
                  }}
                />
              </div>

              <div>
                <label htmlFor="">Earning Type</label>
                <span className="imp_star">*</span>
                <br />
                <input
                  disabled={true}
                  type="text"
                  placeholder="Select Earning Type"
                  onChange={(e) => {
                    setEarningType(e.target.value);
                  }}
                  className="text_input"
                  value={earningType}
                />
              </div>

              <div>
                <label htmlFor="">Earing Name</label>
                <span className="imp_star">*</span>
                <br />
                <input
                  type="text"
                  placeholder="Earning Name"
                  onChange={(e) => setEarningName(e.target.value)}
                  className="text_input"
                  value={earningName}
                />
              </div>

              <div>
                <label htmlFor="">Name on the Payslip *</label>
                <span className="imp_star">*</span>
                <br />
                <input
                  type="text"
                  placeholder="Name on the Payslip"
                  onChange={(e) => setPaySlipName(e.target.value)}
                  className="text_input"
                  value={paySlipName}
                />
              </div>

              {earningType != "Fixed Allowance" ? (
                earningType != "HRA" ? (
                  <>
                    <div className="terms">
                      <label>Pay Type</label>
                      <div>
                        <input
                          type="checkbox"
                          name=""
                          id="ern-optn-1"
                          checked={payType === "fixed"}
                          onChange={(e) => setPayType("fixed")}
                          className="checkbox_input"
                        />
                        <label
                          className="!m-0 !font-normal !text-xl cursor-pointer"
                          htmlFor={`ern-optn-1`}
                        >
                          Fixed Pay
                        </label>
                      </div>
                      <span className="terms_note">
                        (Fixed amount paid at the end of every month)
                      </span>

                      <div>
                        <input
                          type="checkbox"
                          name=""
                          id="ern-optn-2"
                          checked={payType === "variable"}
                          onChange={(e) => setPayType("variable")}
                          className="checkbox_input"
                        />
                        <label
                          className="!m-0 !font-normal !text-xl cursor-pointer"
                          htmlFor={`ern-optn-2`}
                        >
                          Variable Pay
                        </label>
                      </div>
                      <span className="terms_note">
                        (Variable amount paid during any payroll)
                      </span>
                    </div>
                    <div>
                      <div className="terms">
                        <label>Calculation Type</label>
                        <div className="two_in_one">
                          <div>
                            <input
                              type="checkbox"
                              checked={
                                calculationType?.type === "fixed" ? true : false
                              }
                              onClick={(e) => {
                                setCalculationType({
                                  type: "fixed",
                                  value: "fixed",
                                });
                              }}
                              name=""
                              id="ern-optn-3"
                              className="checkbox_input"
                            />
                            <label
                              className="!m-0 !font-normal !text-xl cursor-pointer"
                              htmlFor={`ern-optn-3`}
                            >
                              Flat Amount
                            </label>
                          </div>
                          {earningType == "Basic" ? (
                            <div>
                              <input
                                type="checkbox"
                                name=""
                                id="ern-optn-4"
                                checked={
                                  calculationType.type == "percentage"
                                    ? true
                                    : false
                                }
                                onClick={(e) => {
                                  setCalculationType({
                                    type: "percentage",
                                    value: "basic",
                                  });
                                }}
                                className="checkbox_input"
                              />
                              <label
                                className="!m-0 !font-normal !text-xl cursor-pointer"
                                htmlFor={`ern-optn-4`}
                              >
                                Percentage Of Basic
                              </label>
                            </div>
                          ) : (
                            <div>
                              <input
                                type="checkbox"
                                name=""
                                id="erp-optn-5"
                                checked={
                                  calculationType.type == "percentage"
                                    ? true
                                    : false
                                }
                                onClick={(e) => {
                                  setCalculationType({
                                    type: "percentage",
                                    value: "ctc",
                                  });
                                }}
                                className="checkbox_input"
                              />
                              <label
                                className="!m-0 !font-normal !text-xl cursor-pointer"
                                htmlFor={`ern-optn-5`}
                              >
                                Percentage Of CTC
                              </label>
                            </div>
                          )}
                        </div>
                      </div>
                      {calculationType.type === "fixed" ? (
                        <div>
                          <label htmlFor="">Enter Amount </label>
                          <br />
                          <input
                            type="text"
                            value={amount}
                            placeholder="Enter Amount"
                            onChange={(e) => setAmount(e.target.value)}
                            className="text_input"
                          />
                        </div>
                      ) : (
                        <div>
                          <label htmlFor="">Enter Percentage % </label>
                          <br />
                          <input
                            type="text"
                            value={percentage}
                            placeholder="Enter Percentage %"
                            onChange={(e) => setPercentage(e.target.value)}
                            className="text_input"
                          />
                        </div>
                      )}
                    </div>{" "}
                  </>
                ) : null
              ) : null}

              {earningType != "Fixed Allowance" ? (
                <div className="terms">
                  {/* <div>
                  <input type="checkbox" className="checkbox_input" />
                  <span>Include this component in Employee CTC</span>
                </div> */}
                  <div>
                    <input
                      type="checkbox"
                      id="ern-opt-5"
                      checked={addToSalary}
                      onChange={() => setAddToSalary(!addToSalary)}
                      className="checkbox_input"
                    />
                    <label
                      className="!m-0 !font-normal !text-xl cursor-pointer"
                      htmlFor={`ern-opt-5`}
                    >
                      Add to Employee’s Salary Structure
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="ern-opt-6"
                      checked={taxable}
                      onChange={() => setTaxable(!taxable)}
                      className="checkbox_input"
                    />
                    <label
                      className="!m-0 !font-normal !text-xl cursor-pointer"
                      htmlFor={`ern-opt-6`}
                    >
                      Taxable Earring
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="ern-opt-7"
                      checked={proRata}
                      onChange={() => setProRata(!proRata)}
                      className="checkbox_input"
                    />
                    <label
                      className="!m-0 !font-normal !text-xl cursor-pointer"
                      htmlFor={`ern-opt-7`}
                    >
                      Calculate on PRO-RATA Basis
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="ern-opt-8"
                      checked={isEpf}
                      onChange={() => setIsEpf(!isEpf)}
                      className="checkbox_input"
                    />
                    <label
                      className="!m-0 !font-normal !text-xl cursor-pointer"
                      htmlFor={`ern-opt-8`}
                    >
                      Consider for EPF Calculation
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="ern-opt-9"
                      checked={isEsi}
                      onChange={() => setIsEsi(!isEsi)}
                      className="checkbox_input"
                    />
                    <label
                      className="!m-0 !font-normal !text-xl cursor-pointer"
                      htmlFor={`ern-opt-9`}
                    >
                      Consider for ESI Calculation
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="ern-opt-10"
                      checked={onPaySlip}
                      onChange={() => setOnPaySlip(!onPaySlip)}
                      className="checkbox_input"
                    />
                    <label
                      className="!m-0 !font-normal !text-xl cursor-pointer"
                      htmlFor={`ern-opt-10`}
                    >
                      Show this Component on Payslip
                    </label>
                  </div>

                  <div>
                    <input
                      type="checkbox"
                      id="ern-opt-11"
                      checked={active}
                      onChange={() => setActive(!active)}
                      className="checkbox_input"
                    />
                    <label
                      className="!m-0 !font-normal !text-xl cursor-pointer"
                      htmlFor={`ern-opt-11`}
                    >
                      Mark this as Active
                    </label>
                  </div>
                </div>
              ) : (
                <div>
                  <input
                    type="checkbox"
                    id="ern-opt-11"
                    checked={active}
                    onChange={() => setActive(!active)}
                    className="checkbox_input"
                  />
                  <label
                    className="!m-0 !font-normal !text-xl cursor-pointer"
                    htmlFor={`ern-opt-11`}
                  >
                    Mark this as Active
                  </label>
                </div>
              )}

              <div className="note">
                Note: You will not be able to edit this salary component once
                you associate it with an Employee
              </div>

              <div>
                <button className="submit_button">Save </button>
                <span
                  className="skip"
                  onClick={() => {
                    clearStates();
                    setEditEarning(false);
                    setSidePanelOpen(false);
                  }}
                >
                  Cancel
                </span>
              </div>
            </form>
          ) : (
            <div>
              <form onSubmit={(e) => handleAddSubmit(e)}>
                <div className="side_panel_form_head">
                  <p>New Earning </p>
                  <RxCross1
                    color="black"
                    size={20}
                    style={{ marginTop: "-2rem", cursor: "pointer" }}
                    onClick={() => {
                      clearStates();
                      setSidePanelOpen(false);
                    }}
                  />
                </div>

                <div>
                  <label htmlFor="">Earning Type</label>
                  <span className="imp_star">*</span>
                  <br />
                  <Select
                    options={selectEarnTypes}
                    styles={setUpSelectStyles}
                    onChange={(e) => setEarningType(e?.name)}
                  />
                </div>

                <div>
                  <label htmlFor="">Earing Name</label>
                  <span className="imp_star">*</span>
                  <br />
                  <input
                    type="text"
                    placeholder="Earning Name"
                    onChange={(e) => setEarningName(e.target.value)}
                    className="text_input"
                  />
                </div>

                <div>
                  <label htmlFor="">Name on the Payslip *</label>
                  <span className="imp_star">*</span>
                  <br />
                  <input
                    type="text"
                    placeholder="Name on the Payslip"
                    onChange={(e) => setPaySlipName(e.target.value)}
                    className="text_input"
                  />
                </div>

                <div className="terms">
                  <label>Pay Type</label>
                  <div>
                    <input
                      type="checkbox"
                      name=""
                      id="edt-ern-opt-1"
                      checked={payType === "fixed"}
                      onChange={(e) => setPayType("fixed")}
                      className="checkbox_input"
                    />
                    <label
                      className="!m-0 !font-normal !text-xl cursor-pointer"
                      htmlFor={`edt-ern-opt-1`}
                    >
                      Fixed Pay
                    </label>
                  </div>
                  <span className="terms_note">
                    (Fixed amount paid at the end of every month)
                  </span>

                  <div>
                    <input
                      type="checkbox"
                      name=""
                      id="edt-ern-opt-2"
                      checked={payType === "variable"}
                      onChange={(e) => setPayType("variable")}
                      className="checkbox_input"
                    />
                    <label
                      className="!m-0 !font-normal !text-xl cursor-pointer"
                      htmlFor={`edt-ern-opt-2`}
                    >
                      Variable Pay
                    </label>
                  </div>
                  <span className="terms_note">
                    (Variable amount paid during any payroll)
                  </span>
                  {/* 
                <div>
                  <input
                    type="checkbox"
                    name=""
                    id=""
                    checked={payType == "pro-rata"}
                    onChange={(e) => setPayType("pro-rata")}
                    className="checkbox_input"
                  />
                  <span>Calculate based on Pro-rata basis</span>
                </div>
                <span className="terms_note">
                  (Pay will be adjusted based on Employee working days)
                </span> */}
                </div>

                <div>
                  <div className="terms">
                    <label>Calculation Type</label>
                    <div className="two_in_one">
                      <div>
                        <input
                          type="checkbox"
                          checked={
                            calculationType?.type === "fixed" ? true : false
                          }
                          onClick={(e) => {
                            setCalculationType({
                              type: "fixed",
                              value: "fixed",
                            });
                          }}
                          name=""
                          id="edt-ern-opt-3"
                          className="checkbox_input"
                        />
                        <label
                          className="!m-0 !font-normal !text-xl cursor-pointer"
                          htmlFor={`edt-ern-opt-3`}
                        >
                          Flat Amount
                        </label>
                      </div>
                      {earningType == "Basic" ? (
                        <div>
                          <input
                            type="checkbox"
                            name=""
                            id="edt-ern-opt-4"
                            checked={
                              calculationType.type == "percentage"
                                ? true
                                : false
                            }
                            onClick={(e) => {
                              setCalculationType({
                                type: "percentage",
                                value: "basic",
                              });
                            }}
                            className="checkbox_input"
                          />
                          <label
                            className="!m-0 !font-normal !text-xl cursor-pointer"
                            htmlFor={`edt-ern-opt-5`}
                          >
                            Percentage Of Basic
                          </label>
                        </div>
                      ) : (
                        <div>
                          <input
                            type="checkbox"
                            name=""
                            id="edt-ern-opt-6"
                            checked={
                              calculationType?.type == "percentage"
                                ? true
                                : false
                            }
                            onClick={(e) => {
                              setCalculationType({
                                type: "percentage",
                                value: "ctc",
                              });
                            }}
                            className="checkbox_input"
                          />
                          <label
                            className="!m-0 !font-normal !text-xl cursor-pointer"
                            htmlFor={`edt-ern-opt-6`}
                          >
                            Percentage Of CTC
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                  {calculationType?.type === "fixed" ? (
                    <div>
                      <label htmlFor="">Enter Amount </label>
                      <br />
                      <input
                        type="text"
                        value={amount}
                        placeholder="Enter Amount"
                        onChange={(e) => setAmount(e.target.value)}
                        className="text_input"
                      />
                    </div>
                  ) : (
                    <div>
                      <label htmlFor="">Enter Percentage % </label>
                      <br />
                      <input
                        type="text"
                        value={percentage}
                        placeholder="Enter Percentage %"
                        onChange={(e) => setPercentage(e.target.value)}
                        className="text_input"
                      />
                    </div>
                  )}
                </div>

                <div className="terms">
                  <div>
                    <input
                      type="checkbox"
                      className="checkbox_input"
                      id="edt-ern-opt-0"
                    />
                    <label
                      className="!m-0 !font-normal !text-xl cursor-pointer"
                      htmlFor={`edt-ern-opt-0`}
                    >
                      Include this component in Employee CTC
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      checked={addToSalary}
                      id="edt-ern-opt-7"
                      onChange={() => setAddToSalary(!addToSalary)}
                      className="checkbox_input"
                    />
                    <label
                      className="!m-0 !font-normal !text-xl cursor-pointer"
                      htmlFor={`edt-ern-opt-7`}
                    >
                      Add to Employee’s Salary Structure
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      checked={taxable}
                      onChange={() => setTaxable(!taxable)}
                      className="checkbox_input"
                      id="edt-ern-opt-8"
                    />
                    <label
                      className="!m-0 !font-normal !text-xl cursor-pointer"
                      htmlFor={`edt-ern-opt-8`}
                    >
                      Taxable Earring
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      checked={proRata}
                      onChange={() => setProRata(!proRata)}
                      className="checkbox_input"
                      id="edt-ern-opt-9"
                    />
                    <label
                      className="!m-0 !font-normal !text-xl cursor-pointer"
                      htmlFor={`edt-ern-opt-9`}
                    >
                      Calculate on PRO-RATA Basis
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      checked={isEpf}
                      onChange={() => setIsEpf(!isEpf)}
                      className="checkbox_input"
                      id="edt-ern-opt-10"
                    />
                    <label
                      className="!m-0 !font-normal !text-xl cursor-pointer"
                      htmlFor={`edt-ern-opt-10`}
                    >
                      Consider for EPF Calculation
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="edt-ern-opt-11"
                      checked={isEsi}
                      onChange={() => setIsEsi(!isEsi)}
                      className="checkbox_input"
                    />
                    <label
                      className="!m-0 !font-normal !text-xl cursor-pointer"
                      htmlFor={`edt-ern-opt-11`}
                    >
                      Consider for ESI Calculation
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      checked={onPaySlip}
                      onChange={() => setOnPaySlip(!onPaySlip)}
                      className="checkbox_input"
                      id="edt-ern-opt-12"
                    />
                    <label
                      className="!m-0 !font-normal !text-xl cursor-pointer"
                      htmlFor={`edt-ern-opt-12`}
                    >
                      Show this Component on Payslip
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      checked={active}
                      onChange={() => setActive(!active)}
                      className="checkbox_input"
                      id="edt-ern-opt-13"
                    />
                    <label
                      className="!m-0 !font-normal !text-xl cursor-pointer"
                      htmlFor={`edt-ern-opt-13`}
                    >
                      Mark this as Active
                    </label>
                  </div>
                </div>

                <div className="note">
                  Note: You will not be able to edit this salary component once
                  you associate it with an Employee
                </div>

                <div>
                  <button className="submit_button">Save </button>
                  <span
                    className="skip"
                    onClick={() => {
                      clearStates();
                      setSidePanelOpen(false);
                    }}
                  >
                    Cancel
                  </span>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Earnings;
