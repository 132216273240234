import React from "react";

const Dashboard_stat_card = ({ title, value, className }) => {
  return (
    <div className={"ele " + `${className}`}>
      <div>
        <h1>{value}</h1>
        <span>{title}</span>
      </div>
    </div>
  );
};

export default Dashboard_stat_card;
