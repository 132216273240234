import React from 'react'
import { useSelector } from 'react-redux'
import PageTitle from '../../../../components/PageTitle';

const Attendance = () => {

  const theme = useSelector(({ theme }) => theme);

  return (
    <main className="w-full">
      <div className={'creation-content m-8' + `${theme == "light" ? "light_bg" : ""}`}>
        <PageTitle title="Attendance" />
      </div>

      <section className='user-detail-container user-detail-container-yellowDark-border'>
        <div className={"user-detail-box " + `${theme == "light" ? "light_bg_logo" : ""}`}>
        </div>
      </section>
    </main>
  )
}

export default Attendance