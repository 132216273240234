import React, { useState } from "react";
import { useSelector } from "react-redux";

const Tabs = ({ tabs, height, width, textSize, titleBgColor, onTabChange }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const theme = useSelector((state) => state.theme.theme);
  const handleTabClick = (index) => {
    setActiveTabIndex(index);
    onTabChange && onTabChange();
  };

  return (
    <>
      <div className="tab-wrapper">
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`tab mb-1 uppercase cursor-pointer border-t-2 border-solid rounded-t-xl ${
              height ? height : "h-20"
            } ${
              width ? width : "w-72"
            } flex items-center justify-center transition-all ${
              textSize ? textSize : ""
            } duration-300  ${
              activeTabIndex === index
                ? "border-[#21c38b] text-white bg-[#21c38b] "
                : `border-transparent ${
                    titleBgColor ? titleBgColor : ""
                  } text-white`
            }`}
            onClick={() => handleTabClick(index)}
          >
            <span> {tab.name}</span>
          </div>
        ))}
      </div>

      <section
        className={
          "py-8 flex flex-col gap-20 h-full bg-[#353456] " +
          `${theme == "light" ? "light_bg" : ""}`
        }
      >
        {tabs[activeTabIndex] && tabs[activeTabIndex].content}
      </section>
    </>
  );
};

export default Tabs;

// import React, { useState } from 'react'

// const Tabs = () => {
//     const [isActive, setIsActive] = useState(null);
//     const tabs = ["tab1", "tab2", "tab3", "tab4"];

//     return (
//         <>
//             <div className='tab-wrapper'>
//                 {tabs.map((item, index) => (
//                     <div className={`tab ${isActive === index ? "bg-green-700" : ''}`} onClick={() => setIsActive(index)}>{item}</div>
//                 ))
//                 }

//             </div>
//             <div className='h-[40rem] w-full '>
//                 {isActive !== null && <h1>{tabs[isActive]}</h1>}
//             </div>
//         </>
//     )
// }

// export default Tabs
