import { customAxios } from "../utils/axiosinstance"

export const createloan = async(payload) => {
    const {data} = await customAxios.post(`/hrms/loans/create-loan` , payload)
    return data;
}

export const getallloans = async() => {
    const {data} = await customAxios.get(`/hrms/loans/get-all-loans`)
    return data;
}

export const assignloan = async(payload) => {
    const {data} = await customAxios.post(`/hrms/loans/assign-loan` , payload)
    return data;
}

export const deleteloan = async(payload) => {
    const {data} = await customAxios.post(`/hrms/loans/delete-loan` , payload)
    return data;
}

export const getallEmployeesLoans = async() => {
    const {data} = await customAxios.get(`/hrms/loans/get-all-employees-loans`)
    return data;
}

export const getLoanInstallments = async(payload) => {
    const {data} = await customAxios.post(`/hrms/loans/get-loan-installments`, payload)
    return data;
}

export const editInstallmentAmount = async(payload) => {
    const {data} = await customAxios.post(`/hrms/loans/edit-installment-amount`, payload)
    return data;
}

export const pauseLoanOfEmployee = async(payload) => {
    const {data} = await customAxios.post(`/hrms/loans/pause-employee-loan`, payload)
    return data;
}

export const closeLoanOfEmployee = async(payload) => {
    const {data} = await customAxios.post(`/hrms/loans/close-employee-loan`, payload)
    return data;
}

export const deleteEmployeeloan = async(payload) => {
    const {data} = await customAxios.post(`/hrms/loans/delete-employee-loan` , payload)
    return data;
}

export const getEmployeeLoanDetailsByMonthAndYear = async(payload) => {
    console.log(payload , "payload")
    const {data} = await customAxios.get(`/hrms/loans/get-loan-details-by-month-year` , {
        params: payload,
    })
    
    return data;
}