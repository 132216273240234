import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import PageTitle from "../../../../components/PageTitle";
import SearchDropDown from "../../../../components/DropDown/SearchDropDown";
import Table from "../../../../components/Table";
import {
  GetIncomeTaxAllComponents,
  GetFiniancialYearEmployee,
} from "../../../../services/incomeTax";
import { GetActiveEarnings } from "../../../../services/earning";
import { GetAllEmployees } from "../../../../services/employee";
import { GetPaymentSchedule } from "../../../../services/paymentschedule";
import { getProcessedPayrunsMonths } from "../../../../services/payRun";

const IncomeTaxHome = () => {
  const theme = useSelector((state) => state.theme.theme);
  const [options, setOptions] = useState([]);
  const [optionsEmp, setOptionsEmp] = useState([]);
  const [incomeTaxDet, setIncomeTaxDet] = useState({
    earnings: [],
    deductions: [],
  });
  const [totalErnings, setTotalEarnings] = useState({});
  const [activeEarnings, setActiveEarnings] = useState([]);
  const [filter, setFilter] = useState("?");
  const [payrunMonths, setPayrunMonths] = useState([]);

  const columns = useMemo(() => {
    let initial = [
      {
        Header: "Components",
        accessor: "components",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          // console.log(props?.row.original);
          console.log();
          const key = Object?.keys(props?.row?.original)[0];
          return (
            // <>g</>
            <span
              className={
                props?.row?.index == props?.data?.length - 1
                  ? "font-semibold"
                  : ""
              }
            >
              {key || "_"}
            </span>
          );
        },
      },
      {
        Header: "Total",
        accessor: "total",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          const key = Object?.keys(props?.row?.original)[0]
            ?.split(" ")
            ?.join("");
          const totalKey = "total" + key;
          return (
            <span className="font-semibold">
              {(key && `${Math.round(props?.row?.original[totalKey])}`) || "_"}
            </span>
          );
        },
      },
      {
        Header: (
          <header className={payrunMonths?.includes(3) ? "text-[#30A463]" : ""}>
            {" "}
            Apr
          </header>
        ),
        accessor: "apr",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          const key = Object?.keys(props?.row?.original)[0];
          return (
            <span
              className={
                props?.row?.index == props?.data?.length - 1
                  ? "font-semibold"
                  : ""
              }
            >
              {key && props?.row?.original[key][3] != undefined
                ? `${Math.round(props?.row?.original[key][3])}`
                : "_"}
            </span>
          );
        },
      },
      {
        Header: (
          <header className={payrunMonths?.includes(4) ? "text-[#30A463]" : ""}>
            {" "}
            May
          </header>
        ),
        accessor: "may",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          const key = Object?.keys(props?.row?.original)[0];
          return (
            <span
              className={
                props?.row?.index == props?.data?.length - 1
                  ? "font-semibold"
                  : ""
              }
            >
              {key && props?.row?.original[key][4] != undefined
                ? `${Math.round(props?.row?.original[key][4])}`
                : "_"}
            </span>
          );
        },
      },
      {
        Header: (
          <header className={payrunMonths?.includes(5) ? "text-[#30A463]" : ""}>
            {" "}
            Jun
          </header>
        ),
        accessor: "jun",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          const key = Object?.keys(props?.row?.original)[0];
          return (
            <span
              className={
                props?.row?.index == props?.data?.length - 1
                  ? "font-semibold"
                  : ""
              }
            >
              {key && props?.row?.original[key][5] != undefined
                ? `${Math.round(props?.row?.original[key][5])}`
                : "_"}
            </span>
          );
        },
      },
      {
        Header: (
          <header className={payrunMonths?.includes(6) ? "text-[#30A463]" : ""}>
            {" "}
            Jul
          </header>
        ),
        accessor: "jul",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          const key = Object?.keys(props?.row?.original)[0];
          return (
            <span
              className={
                props?.row?.index == props?.data?.length - 1
                  ? "font-semibold"
                  : ""
              }
            >
              {key && props?.row?.original[key][6] != undefined
                ? `${Math.round(props?.row?.original[key][6])}`
                : "_"}
            </span>
          );
        },
      },
      {
        Header: (
          <header className={payrunMonths?.includes(7) ? "text-[#30A463]" : ""}>
            {" "}
            Aug
          </header>
        ),
        accessor: "aug",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          const key = Object?.keys(props?.row?.original)[0];
          return (
            <span
              className={
                props?.row?.index == props?.data?.length - 1
                  ? "font-semibold"
                  : ""
              }
            >
              {key && props?.row?.original[key][7] != undefined
                ? `${Math.round(props?.row?.original[key][7])}`
                : "_"}
            </span>
          );
        },
      },
      {
        Header: (
          <header className={payrunMonths?.includes(8) ? "text-[#30A463]" : ""}>
            {" "}
            Sep
          </header>
        ),
        accessor: "sep",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          const key = Object?.keys(props?.row?.original)[0];
          return (
            <span
              className={
                props?.row?.index == props?.data?.length - 1
                  ? "font-semibold"
                  : ""
              }
            >
              {key && props?.row?.original[key][8] != undefined
                ? `${Math.round(props?.row?.original[key][8])}`
                : "_"}
            </span>
          );
        },
      },
      {
        Header: (
          <header className={payrunMonths?.includes(9) ? "text-[#30A463]" : ""}>
            {" "}
            Oct
          </header>
        ),
        accessor: "oct",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          const key = Object?.keys(props?.row?.original)[0];
          return (
            <span
              className={
                props?.row?.index == props?.data?.length - 1
                  ? "font-semibold"
                  : ""
              }
            >
              {key && props?.row?.original[key][9] != undefined
                ? `${Math.round(props?.row?.original[key][9])}`
                : "_"}
            </span>
          );
        },
      },
      {
        Header: (
          <header
            className={payrunMonths?.includes(10) ? "text-[#30A463]" : ""}
          >
            {" "}
            Nov
          </header>
        ),
        accessor: "nov",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          const key = Object?.keys(props?.row?.original)[0];
          return (
            <span
              className={
                props?.row?.index == props?.data?.length - 1
                  ? "font-semibold"
                  : ""
              }
            >
              {key && props?.row?.original[key][10] != undefined
                ? `${Math.round(props?.row?.original[key][10])}`
                : "_"}
            </span>
          );
        },
      },
      {
        Header: (
          <header
            className={payrunMonths?.includes(11) ? "text-[#30A463]" : ""}
          >
            {" "}
            Dec
          </header>
        ),
        accessor: "dec",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          const key = Object?.keys(props?.row?.original)[0];
          return (
            <span
              className={
                props?.row?.index == props?.data?.length - 1
                  ? "font-semibold"
                  : ""
              }
            >
              {key && props?.row?.original[key][11] != undefined
                ? `${Math.round(props?.row?.original[key][11])}`
                : "_"}
            </span>
          );
        },
      },
      {
        Header: (
          <header className={payrunMonths?.includes(0) ? "text-[#30A463]" : ""}>
            {" "}
            Jan
          </header>
        ),
        accessor: "jan",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          const key = Object?.keys(props?.row?.original)[0];
          return (
            <span
              className={
                props?.row?.index == props?.data?.length - 1
                  ? "font-semibold"
                  : ""
              }
            >
              {key && props?.row?.original[key][0] != undefined
                ? `${Math.round(props?.row?.original[key][0])}`
                : "_"}
            </span>
          );
        },
      },
      {
        Header: (
          <header className={payrunMonths?.includes(1) ? "text-[#30A463]" : ""}>
            {" "}
            Feb
          </header>
        ),
        accessor: "feb",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          const key = Object?.keys(props?.row?.original)[0];
          return (
            <span
              className={
                props?.row?.index == props?.data?.length - 1
                  ? "font-semibold"
                  : ""
              }
            >
              {key && props?.row?.original[key][1] != undefined
                ? `${Math.round(props?.row?.original[key][1])}`
                : "_"}
            </span>
          );
        },
      },
      {
        Header: (
          <header className={payrunMonths?.includes(2) ? "text-[#30A463]" : ""}>
            {" "}
            Mar
          </header>
        ),
        accessor: "mar",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          const key = Object?.keys(props?.row?.original)[0];
          return (
            <span
              className={
                props?.row?.index == props?.data?.length - 1
                  ? "font-semibold"
                  : ""
              }
            >
              {key && props?.row?.original[key][2] != undefined
                ? `${Math.round(props?.row?.original[key][2])}`
                : "_"}
            </span>
          );
        },
      },
    ];
    return initial;
  }, [activeEarnings, payrunMonths]);

  // useEffect(() => {
  //     GetAllEmployees().then(data=>{
  //       const employees = []
  //       data.forEach(element => {
  //         employees.push({value:element._id,label:`${element.firstName} ${element.lastName}`})
  //       });
  //       setOptions(employees)
  //     })
  // },[])

  useEffect(() => {
    GetPaymentSchedule().then((data) => {
      const { year } = data.paymentStartsFrom;
      const currentYear = new Date().getFullYear();
      const years = [];
      for (let i = currentYear; i >= Number(year); i--) {
        years.push({ value: `${i}-${i + 1}`, label: `${i}-${i + 1}` });
      }
      setOptions(years);
    });
  }, []);

  const handleChangeEmploy = async (value) => {
    const { data } = await GetFiniancialYearEmployee(value);

    const employees = [];
    data?.forEach((element) => {
      employees.push({
        value: element?.employee?._id,
        label: `${element?.employee?.firstName} ${element?.employee?.lastName}`,
      });
    });
    setFilter((pre) => pre + `financialYear=${value}`);
    setOptionsEmp(employees);
  };

  const handleChange = async (value) => {
    const { data } = await GetIncomeTaxAllComponents(
      `${filter}&employeeId=${value}`,
    );

    const res = await getProcessedPayrunsMonths({ year: filter.split("=")[1] });
    const months = res?.data?.map((elm) => elm.month);

    // console.log(months);

    setPayrunMonths(months);

    const total = {
      TotalEarnings: {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
      },
      totalTotalEarnings: data?.financialYearGross,
    };
    data?.earnings?.forEach((elm) => {
      const key = Object?.keys(elm)[0];
      for (let i = 0; i < 12; i++) {
        total.TotalEarnings[i] += Number(elm[key][i]);
      }
    });
    setTotalEarnings(total);
    setIncomeTaxDet(data);
  };

  return (
    <main className="main-content bg-primaryLight2 dark:bg-primaryDark1">
      <div
        className={
          "area-creation-content" + `${theme == "light" ? "light_bg" : ""}`
        }
      >
        <PageTitle title="Income Tax" route={".."} />
        <section className="admin-content">
          <div className="row ">
            <div className="col-sm-12 col-md-4 col-lg-4">
              <label htmlFor="emp_name">Select Financial Year</label>
              <br />
              <div className="flex gap-6 items-center w-full">
                <SearchDropDown
                  options={options}
                  placeholder={"Select"}
                  onChange={handleChangeEmploy}
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4">
              <label htmlFor="emp_name">Select Employee</label>
              <br />
              <div className="flex gap-6 items-center w-full">
                <SearchDropDown
                  options={optionsEmp}
                  placeholder={"Select"}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          <div>
            <section className={"cardlink-row pb-6"}>
              <label className="show-entries">Show Entries (0)</label>

              <div className="filter-table">
                <Table
                  columns={columns}
                  data={[...incomeTaxDet?.earnings, totalErnings] ?? []}
                />
              </div>
            </section>
          </div>
        </section>
      </div>
    </main>
  );
};

export default IncomeTaxHome;
