import React from "react";
import PageTitle from "../../../../components/PageTitle";
import Table from "../../../../components/Table";
import { FaPen } from "react-icons/fa";
import { adminStyles, adminStylesLight } from "../../../../utils/selectStyles";
import { useMemo } from "react";
import { useEffect, useState } from "react";
import Select from "react-select";
import ComingSoon from "../../../../components/ComingSoon";

import { useDispatch, useSelector } from "react-redux";
import Tabs from "../../../../components/Tabs";
import ReportTDS from "../ReportsTDS";
import ReportSalaryDetails from "../ReportSalaryDetails";
import ReportSalary from "../ReportSalaryRevised";
import PaySlip from "../PaySlip";
import Activity from "../Activity";
import ReportHRDocuments from "../ReportHRDocuments";
import {
  clearEmployees,
  fetchEmployees,
} from "../../../../reducers/employeesReducer";
import { generateSelectData } from "../../../../utils/serialiseResponse";

const EmployeeInfoReports = () => {
  const theme = useSelector((state) => state.theme.theme);
  const [isActive, setIsActive] = useState(0);
  const [selectData, setSelectData] = useState(null);
  // const tabs = [
  //   "PaySlip",
  //   "TDS",
  //   "Salary Details",
  //   "Revised Salary",
  //   "HR Documents",
  //   "Activity",
  // ];
  // let tabComponents = [
  //   <PaySlip />,
  //   <ReportTDS />,
  //   <ReportSalaryDetails emp={selectData} />,
  //   <ReportSalary />,
  //   <ReportHRDocuments />,
  //   <Activity />,
  // ];

  const employees = useSelector(({ employees }) => employees);
  const [selectEmpl, setSelectEmpl] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchEmployees({}));

    return () => dispatch(clearEmployees());
  }, [dispatch]);

  // const handleSelectTab = (index) => {
  //   setIsActive(index);
  // };
  useEffect(() => {
    setSelectData(selectEmpl);
  }, [selectEmpl]);

  const emplSelect = useMemo(
    () => generateSelectData(employees.data, "fullName"),
    [employees.data],
  );

  return (
    <div className="main-content relative bg-primaryLight2 dark:bg-primaryDark1">
      {/* <ComingSoon/> */}
      <div className="area-creation-content">
        <PageTitle title="Employee Reports" route={"/employee/manage/create"} />
        <div className="bg-primaryLight2 dark:bg-primaryDark1 dark:text-secondaryDark">
          <div className="flex flex-row gap-x-40">
            <div className="row px-0">
              <div className="col-sm-12 col-md-4 col-lg-4 ">
                <label htmlFor="state">SELECT EMPLOYEE</label>
                <br />
                <Select
                  className="w-full"
                  styles={theme == "light" ? adminStylesLight : adminStyles}
                  options={emplSelect}
                  onChange={(el) => setSelectEmpl(el)}
                  placeholder="Select Employee"
                />
              </div>
            </div>
          </div>

          <div className={"w-full"}>
            <Tabs
              width={"w-60"}
              height={"h-16"}
              textSize={"text-xl"}
              tabs={[
                {
                  name: "PaySlip",
                  content: <PaySlip emp={selectData} />,
                },
                {
                  name: "TDS",
                  content: <ReportTDS />,
                },
                {
                  name: "Salary Details",
                  content: <ReportSalaryDetails emp={selectData} />,
                },
                {
                  name: "Revised Salary",
                  content: <ReportSalary emp={selectData} />,
                },
                {
                  name: "HR Documents",
                  content: <ReportHRDocuments />,
                },
                {
                  name: "Activity",
                  content: <Activity emp={selectData} />,
                },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeInfoReports;
