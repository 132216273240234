import React from 'react'
import { useSelector } from 'react-redux';
import PageTitle from '../../../components/PageTitle';

const history = () => {
  const theme = useSelector((state) => state.theme.theme);


  return (
    <main className="w-full">
    <div
      className={
        "creation-content " + `${theme == "light" ? "light_bg" : ""}`
      }
    >
      <PageTitle title="History" route={'../..'} />
      <section className="creation-content_form">
       
      </section>
    </div>
  </main>
  )
}

export default history