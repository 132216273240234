import { Document, Page, Text, View, Image, Font, PDFViewer } from '@react-pdf/renderer';
import icon from '../../Reports/Payroll/Payroll-Summary/RupeeIcon.png';
import Logo from '../../../assets/img/logo.png';
import { returnMonthFromIndex } from '../../../utils/helper'

Font.register
  ({
    family: 'Montserrat',
    src: `https://fonts.gstatic.com/s/montserrat/v10/zhcz-_WihjSQC0oHJ9TCYC3USBnSvpkopQaUR-2r7iU.ttf`,
  });

const PDFDocument = ({ paySlip , companyBasicInfo}) => (
  <Document>
    <Page size="A3">
      <View style={{ backgroundColor: 'white', width: '100%', padding: '20pt', fontWeight: 'thin', fontFamily: 'Montserrat' }}>

        <View style={{ color: '#3B82F6', display: 'flex', flexDirection: 'column', columnGap: '60rem' }}>
          <View style={{ display: 'flex', flexDirection: 'row', columnGap: '350px' }}>
            <Text style={{ fontWeight: 'semibold', fontSize: '25px' , textTransform: 'capitalize'}}>{companyBasicInfo?.companyName}</Text>
            <Text style={{ marginBottom: '5px', marginLeft: '150px', marginTop: '0' }}>
              <PDFViewer>
              <Image src={companyBasicInfo?.logo || Logo} style={{ width: '80px', height: '60px' }} />
              </PDFViewer>
            </Text>
          </View>

          <View style={{ display: 'flex', flexDirection: 'row', columnGap: '300px', fontSize: '16px' }}>
            <Text style={{ width: '40%' }}>{companyBasicInfo?.addressLine1} {companyBasicInfo?.addressLine2}, {companyBasicInfo?.city}</Text>
            <Text style={{ width: '60%' }}>Payslip for the month of {returnMonthFromIndex(paySlip?.month)} {paySlip?.year}</Text>
          </View>
          <View style={{ border: '0.6px solid black', marginVertical: '10px' }}></View>
        </View>

        <View style={{ width: "100%", display: 'flex', flexDirection: 'row', marginTop: "10px", marginBottom: "15px" }}>
          <Text style={{ width: "50%", textTransform: "uppercase" }}>Employee info</Text>
        </View>

        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <View style={{ width: '50%', display: 'flex', flexDirection: 'column', gap: "15px" }}>

            <View style={{ width: "100%", display: 'flex', flexDirection: 'row' }}>
              <Text style={{ width: "50%", textTransform: "uppercase" }}>Employee Name</Text>
              <Text style={{ width: "50%" }}>{`: ${paySlip?.employeeDetails?._id?.firstName || "--"} ${paySlip?.employeeDetails?._id?.lastName || ""}`}</Text>
              {/* <Text style={{ width: "50%" }}>{` : Jatin Wakodikar`}</Text> */}
            </View>

            <View style={{ width: "100%", display: 'flex', flexDirection: 'row' }}>
              <Text style={{ width: "50%", textTransform: "uppercase" }}>Employee ID</Text>
              <Text style={{ width: "50%" }}>{`: ${paySlip?.employeeDetails?._id?.uid || "--"}`}</Text>
              {/* <Text style={{ width: "50%" }}>{` : EMP02`}</Text> */}
            </View>

            <View style={{ width: "100%", display: 'flex', flexDirection: 'row' }}>
              <Text style={{ width: "50%", textTransform: "uppercase" }}>Designation</Text>
              <Text style={{ width: "50%" }}>{`: ${paySlip?.employeeDetails?._id?.designation?.name || "--"}`}</Text>
              {/* <Text style={{ width: "50%" }}>{` : MR`}</Text> */}
            </View>

            <View style={{ width: "100%", display: 'flex', flexDirection: 'row' }}>
              <Text style={{ width: "50%", textTransform: "uppercase" }}>Date of Joining</Text>
              <Text style={{ width: "50%" }}>{`: ${paySlip?.employeeDetails?._id?.joiningDate?.slice(0, 10) || "--"}`}</Text>
              {/* <Text style={{ width: "50%" }}>{` : 04/10/2023`}</Text> */}
            </View>

            <View style={{ width: "100%", display: 'flex', flexDirection: 'row' }}>
              <Text style={{ width: "50%", textTransform: "uppercase" }}>Department</Text>
              <Text style={{ width: "50%" }}>{`: ${paySlip?.employeeDetails?._id?.department?.name || "--"}`}</Text>
              {/* <Text style={{ width: "50%" }}>{` : Sales`}</Text> */}
            </View>

            <View style={{ width: "100%", display: 'flex', flexDirection: 'row' }}>
              <Text style={{ width: "50%", textTransform: "uppercase" }}>City</Text>
              <Text style={{ width: "50%" }}>{`: ${paySlip?.employeeDetails?._id?.address?.city?.name || "--"}`}</Text>
              {/* <Text style={{ width: "50%" }}>{` : Bhopal`}</Text> */}
            </View>
            
            <View style={{ width: "100%", display: 'flex', flexDirection: 'row' }}>
              <Text style={{ width: "50%", textTransform: "uppercase" }}>Payment Mode</Text>
              <Text style={{ width: "50%" }}>{`: ${paySlip?.employeeDetails?._id?.paymentDetails?.paymentType || "--"}`}</Text>
              {/* <Text style={{ width: "50%" }}>{` : Bhopal`}</Text> */}
            </View>

            <View style={{ width: "100%", display: 'flex', flexDirection: 'row' }}>
              <Text style={{ width: "50%", textTransform: "uppercase" }}>Pay Date</Text>
              <Text style={{ width: "50%" }}>{`: ${paySlip?.paymentDate || "--"}`}</Text>
              {/* <Text style={{ width: "50%" }}>{` : 02/05/2024`}</Text> */}
            </View>

          </View>

          <View style={{ width: '50%', display: 'flex', flexDirection: 'column', gap: "15px" }}>
            <View style={{ width: "100%", display: 'flex', flexDirection: 'row' }}>
              <Text style={{ width: "50%", textTransform: "uppercase" }}>Pan Number</Text>
              <Text style={{ width: "50%" }}>{`: ${paySlip?.employeeDetails?._id?.panDetails?.
                panNumber || "--"}`}</Text>
            </View>

            <View style={{ width: "100%", display: 'flex', flexDirection: 'row' }}>
              <Text style={{ width: "50%", textTransform: "uppercase" }}>PF Number</Text>
              <Text style={{ width: "50%", fontSize: "13px", marginTop: "7px" }}>{`: ${paySlip?.employeeDetails?._id?.epfNumber || "--"}`}</Text>
            </View>

            <View style={{ width: "100%", display: 'flex', flexDirection: 'row' }}>
              <Text style={{ width: "50%", textTransform: "uppercase" }}>UAN Number</Text>
              <Text style={{ width: "50%" }}>{`: ${paySlip?.employeeDetails?._id?.uanNumber || "--"}`}</Text>
            </View>
           
            {paySlip?.employeeDetails?._id?.paymentDetails?.paymentType == "bankTransfer" ? 
            <View style={{ width: "100%", display: 'flex', flexDirection: 'row' }}>
              <Text style={{ width: "50%", textTransform: "uppercase" }}>Bank Name</Text>
              {/* <Text style={{ width: "50%" }}>{` : Axis Bank`}</Text> */}
              <Text style={{ width: "50%" }}>{`: ${paySlip?.employeeDetails?._id?.paymentDetails?.bankName || "--"}`}</Text>
            </View> :  null}
            
            {paySlip?.employeeDetails?._id?.paymentDetails?.paymentType == "bankTransfer" ? 
            <View style={{ width: "100%", display: 'flex', flexDirection: 'row' }}>
              <Text style={{ width: "50%", textTransform: "uppercase" }}>Bank Account No</Text>
              {/* <Text style={{ width: "50%" }}>{` : EMP02`}</Text> */}
              <Text style={{ width: "50%" }}>{`: ${paySlip?.employeeDetails?._id?.paymentDetails?.bankAccountNumber || "--"}`}</Text>
            </View> :  null}

            <View style={{ height: '80px', width: '350px', backgroundColor: '#f3f6f8', color: 'black', marginTop: '25px' }}>
              <View style={{ display: 'flex', flexDirection: 'row', paddingVertical: "10px", fontSize: '13px', justifyContent: "center", alignItems: "center" }}>
                <Text style={{ marginHorizontal: "10px" }}>{`Pay Days : ${paySlip?.employeeDetails?.payableDays || "--"}`}</Text>
                <Text style={{ marginHorizontal: "10px" }}>{`LOP Days : ${paySlip?.employeeDetails?.lopDays
                   || "--"}`}</Text>
              </View>

              <Text style={{ border: '0.7px dotted black' }}></Text>

              <View style={{ width: "100%", height: "100%", justifyContent: "center", alignItems: "center", display: 'flex', flexDirection: 'row' }}>
                <View style={{ display: 'flex', gap: "10px", width: "100%", flexDirection: 'row', justifyContent: 'center', alignItems: "center" }}>
                  <Text style={{ fontSize: "12px", textTransform: "uppercase" }} >Employee Net Pay</Text>
                  <View style={{ display: 'flex', gap: "4px", flexDirection: 'row', justifyContent: 'center', alignItems: "center" }}>
                    <Image src={icon} style={{ height: "20px", color: '#3399FF' }} />
                    <Text style={{ fontSize: "25px", color: '#3399FF' }}>{`${paySlip?.employeeDetails?.salaryTempelate?.netPayMonthly || "--"}/-`}</Text>
                  </View>
                </View>
              </View>

            </View>
          </View>
        </View>

        <View style={{ marginTop: '30px', display: 'flex', flexDirection: 'row', gap: "3px" }}>
          <View style={{ width: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <View style={{ width: '100%' }}>
              <View style={{ backgroundColor: '#73CAF8', color: 'white', height: '40pt', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Text style={{ width: '60%', marginLeft: "20px" }}>Earnings</Text>
                <Text>Amount</Text>
              </View>

              {paySlip?.employeeDetails?.salaryTempelate?.earnings?.map((el) => (
                <View style={{ flexDirection: 'row', alignItems: 'center', marginVertical: '10px' }}>
                  <Text style={{ width: '60%', marginLeft: "20px" }}>{el?.eName}</Text>
                  <View style={{ display: 'flex', flexDirection: 'row', alignItems: "center", justifyContent: "center" }}>
                    <Image src={icon} style={{ height: "15px" }} />
                    <Text>{el?.monthly}</Text>
                  </View>
                </View>
              ))}
             
             
             {paySlip?.employeeDetails?.salaryTempelate?.deductions?.map((el) => {
                if (
                  el.subtitle === "Employer Contribution" && el.componentOnPayslip === true 
                ) {
                  return (
                    <View style={{ flexDirection: 'row', alignItems: 'center', marginVertical: '10px' }}>
                    <Text style={{ width: '60%', marginLeft: "20px" }}>{el?.name}</Text>
                    <View style={{ display: 'flex', flexDirection: 'row', alignItems: "center", justifyContent: "center" }}>
                      <Image src={icon} style={{ height: "15px" }} />
                      <Text>{el?.monthly}</Text>
                    </View>
                    </View>
                  )
                }else{
                  return null;
                }
            })}   

            </View>
          </View>

          <View style={{ width: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <View style={{ width: '100%' }}>
              <View style={{ backgroundColor: '#73CAF8', color: 'white', height: '40pt', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Text style={{ width: '60%', marginLeft: "20px" }}>Deductions</Text>
                <Text >Amount</Text>
              </View>

              {paySlip?.employeeDetails?.salaryTempelate?.deductions?.map((el) => {
                if (
                  (el.subtitle === "Employer Contribution" && el.componentOnPayslip === true)
                  ||
                  el.subtitle === "Employee Contribution"
                ) {
                  return (
                    <View style={{ flexDirection: 'row', alignItems: 'center', marginVertical: 10 }} key={el.name}>
                      {/* {console.log(el)} */}
                      <Text style={{ width: '60%', marginLeft: 20 }}>{el?.name}</Text>
                      <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <Image src={icon} style={{ height: 15 }} />
                        <Text>{el?.monthly}</Text>
                      </View> 
                    </View>
                  );
                }
              else if(el.docName !== "esiInfo" || el.docName != "epfInfo"){
                return (
                  <View style={{ flexDirection: 'row', alignItems: 'center', marginVertical: 10 }} key={el.name}>
                    <Text style={{ width: '60%', marginLeft: 20 }}>{el?.name}</Text>
                    <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                      <Image src={icon} style={{ height: 15 }} />
                      <Text>{el?.monthly}</Text>
                    </View>
                  </View>
                );
              }
                return null;
              })}

            {paySlip?.employeeDetails?.loanDetails?.map((el) => {
                return (
                    <View style={{ flexDirection: 'row', alignItems: 'center', marginVertical: 10 }} key={el.name}>
                      {/* {console.log(el)} */}
                      <Text style={{ width: '60%', marginLeft: 20 }}>{el?.nameOnPayslip}</Text>
                      <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <Image src={icon} style={{ height: 15 }} />
                        <Text>{el.loanInstallment[0]?.installmentAmount}</Text>
                      </View> 
                    </View>
                );
            })}
             

            </View>
          </View>
        </View>

        <View>
          <Text style={{ borderBottomWidth: '2pt', borderBottomColor: 'black', marginVertical: '7px' }}>
          </Text>
        </View>

        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <View style={{ width: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <View style={{ width: '100%' }}>
              <View style={{ height: '40pt', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Text style={{ width: '60%', marginLeft: "20px" }}>Gross Earnings</Text>
                <View style={{ display: 'flex', flexDirection: 'row', alignItems: "center", justifyContent: "center" }}>
                  <Image src={icon} style={{ height: "15px" }} />
                  <Text>{`${paySlip?.employeeDetails?.salaryTempelate?.earningTotalMonthly || "--"}`}</Text>
                </View>
              </View>
            </View>
          </View>

          <View style={{ width: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <View style={{ width: '100%' }}>
              <View style={{ height: '40pt', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Text style={{ width: '60%', marginLeft: "20px" }}>Total Deductions</Text>
                <View style={{ display: 'flex', flexDirection: 'row', alignItems: "center", justifyContent: "center" }}>
                  <Image src={icon} style={{ height: "15px" }} />
                  <Text>{`${paySlip?.employeeDetails?.salaryTempelate?.allTotalDeduction || "--"}`}</Text>
                </View>
              </View>
            </View>
          </View>
        </View>


        {paySlip?.employeeDetails?.salaryTempelate?.reimbursements?.length > 0 ?
          <View style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginVertical: '20px' }}>
            <View style={{ width: '100%' }}>
              <View style={{ backgroundColor: '#73CAF8', color: 'white', height: '40pt', flexDirection: 'row', alignItems: 'center' }}>
                <Text style={{ width: '80%', marginLeft: "20px" }}>Reimbursement</Text>
                <Text>Amount</Text>
              </View>

              {paySlip?.employeeDetails?.salaryTempelate?.reimbursements?.map((el) => (
                <View  style={{ height: '40pt', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Text style={{ width: '80%', marginLeft: "20px" }}>{el?.nameOnPaySlip}</Text>
                  {/* <Text style={{ width: '80%', marginLeft: "20px" }}>Fuel Reimburesment</Text> */}
                  <View style={{ display: 'flex', flexDirection: 'row', alignItems: "center", justifyContent: "center" }}>
                    <Image src={icon} style={{ height: "15px" }} />
                    <Text>{el?.monthly}</Text>
                    {/* <Text>10000</Text> */}
                  </View>
                </View>
              ))}
            </View>
               
            <View >
             <Text style={{ borderBottomWidth: '2pt', borderBottomColor: 'black', marginVertical: '7px' }}>
             </Text>
            </View>

            <View style={{ width: '100%' }}>
              <View style={{ height: '40pt', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Text style={{ width: '80%', marginLeft: "20px" }}>Total Reimbursement</Text>
                <View style={{ display: 'flex', flexDirection: 'row', alignItems: "center", justifyContent: "center" }}>
                  <Image src={icon} style={{ height: "15px" }} />
                  <Text>{`${paySlip?.employeeDetails?.salaryTempelate?.reimbTotalMonthly || "--"}`}</Text>
                  {/* <Text>10000</Text> */}
                </View>
              </View>
            </View>
          </View>
          :
          null
        }
      </View>
    </Page>
  </Document>
);

export default PDFDocument;