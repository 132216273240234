export const title = "Manage User"

export const links = [
    {
        name:"Profile",
        route:'profile'
    },
    {
        name:"Salary Details",
        route:'salary-details'
    },
    {
        name:"Attendance",
        route:'attendance'
    },
    {
        name:"Finance",
        route:'finance'
    },
    {
        name:"Log out",
        route:'log-out'
    },
]