import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import PageTitle from '../../../../components/PageTitle';
import Table from '../../../../components/Table'

const SalaryDetails = () => {

  const theme = useSelector(({ theme }) => theme);

  const dummy1 = [
    {
      employeeName: "Siddharth Singh",
      employeeId: "MED001",
      totalMonthly: "200000",
      totalAnually: "1200000",
    }
  ]

  const dummy2 = [
    {
      employeeName: "Siddharth Singh",
      employeeId: "MED001",
      totalMonthly: "200000",
      totalAnually: "1200000",
    }
  ]

  const columns1 = useMemo(
    () => [
      {
        Header: "Employee Name",
        accessor: "employeeName",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "Employee ID",
        accessor: "employeeId",
        disableFilters: true,
        disableSortBy: true
        // Cell: (props) => {
        //   return (
        //     <div className='section-form_content'>
        //       <form >
        //         <input
        //           type="date"

        //           className='outline-none border-none '
        //           value={selectDate || today} // Set default value to today's date
        //           onChange={(e) => setselectDate(e.target.value)}
        //         />
        //       </form>
        //     </div>
        //   );
        // }
      },
      {
        Header: "Total Monthly",
        accessor: "totalMonthly",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "Total Annually",
        accessor: "totalAnually",
        disableFilters: true,
        disableSortBy: true
      },
    ],
    [dummy1],
  );

  const columns2 = useMemo(
    () => [
      {
        Header: "Employee Name",
        accessor: "employeeName",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "Employee ID",
        accessor: "employeeId",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "Total Monthly",
        accessor: "totalMonthly",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "Total Annually",
        accessor: "totalAnually",
        disableFilters: true,
        disableSortBy: true
      },
    ],
    [dummy1],
  );

  return (
    <main className="w-full">
      <div className={'creation-content m-8' + `${theme == "light" ? "light_bg" : ""}`}>
        <PageTitle title="Salary Details" />
      </div>

      <section className='user-detail-container user-detail-container-yellowDark-border'>
        <div className={"user-detail-box " + `${theme == "light" ? "light_bg_logo" : ""}`}>
          <div className='w-full'>
            <Table columns={columns1} data={dummy1} />
          </div>

          <div className='w-full'>
            <Table columns={columns2} data={dummy2} />
          </div>
        </div>
      </section>
    </main>
  )
}

export default SalaryDetails