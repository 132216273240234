export const title = "Manage Templates"

export const links = [
  {
    name:"Salary Templates",
    route:'salary'
  },
  {
    name:"Payslip Templates",
    route:'payslip'
  },
  // {
  //   name:"Email Templates",
  //   route:'email'
  // }
]