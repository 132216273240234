import { createSlice } from "@reduxjs/toolkit";
import { viewLocations ,newLocation, deleteLocation, editLocation,} from "../services/location";

const initialState = {
  data: [],
};

const statesSlice = createSlice({
  name: "states",
  initialState,
  reducers: {
    setStates(state, action) {
      state.data = action.payload;
    },
    addState(state, action) {
      state.data.push(action.payload.data);
    },
    editState(state, action) {
      const updatedIndex = state.data.findIndex(
        (location) => location.id === action.payload.data.id
      );
      if (updatedIndex !== -1) {
        state.data[updatedIndex] = action.payload.data;
      }
    },
    deleteState(state, action) { 
      state.data = state.data.filter(
        (location) => location.id !== action.payload.id
      );
    },
    clearStates(state) {
      state.data = [];
    },
  },
});

export const { setStates, clearStates , addState, editState, deleteState, } = statesSlice.actions;

export const fetchStates = (location) => async (dispatch) => {
  try {
    const states = await viewLocations(location);
    dispatch(setStates(states.data));
  } catch (error) {
  }
};


export const addStateAsync = (data) => async (dispatch) => {
  try {
    const addedState = await newLocation("state", data);
    dispatch(addState(addedState));
    dispatch(fetchStates('state'));
  } catch (error) {
  }
};

export const editStateAsync = (id, updatedLoc) => async (dispatch) => {
  try {
    const updatedState = await editLocation("state", id, updatedLoc);
    dispatch(editState(updatedState));
    dispatch(fetchStates("state")); 
  } catch (error) {
  }
};

export const deleteStateAsync = (id) => async (dispatch) => {
  try {
    await deleteLocation("state", id);
    dispatch(deleteState({ id }));
    dispatch(fetchStates("state"));
  } catch (error) {
  }
};

export default statesSlice.reducer;
