export 
const others = {
    titles: [
        {
          id: 'Day 1 Of Current Month - Day 30 Of Current Month',
          label: 'Day 1 Of Current Month - Day 30 Of Current Month',
          warning: "(Example-If Payslip is processed for the month of July, 1st July to 31st July will be considered)"
        },
        {
            id: 'Day Of Previous Month - Day of Current Month',
            label: 'Day Of Previous Month - Day of Current Month',
            warning:"(Example-If Payslip processed for the month of August, July to August will be considered)"
        },
        {
            id: 'Day 1 of Previous Month to Last day of Previous Month',
            label: 'Day 1 of Previous Month to Last day of Previous Month',
        },
      ],
}
