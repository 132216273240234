import {
  useCallback,
  useDebugValue,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import { RiEdit2Fill } from "react-icons/ri";
import Table from "../../../components/Table";
import serialise, {
  generateSelectData,
} from "../../../utils/serialiseResponse";
import customToast from "../../../components/CustomToast";
import PageTitle from "../../../components/PageTitle";
import { fetchTemp } from "../../../reducers/salaryTempelate";
import {
  AddSalaryTempelate,
  GetEmployeeTemp,
  calculateTemplateData,
  updateEmployeeSalaryConfigs,
} from "../../../services/hrms";
import { fetchPt } from "../../../services/salaryTempelates";
import { GetEarnings } from "../../../services/earning";
import { GetAllDeduction } from "../../../services/deduction";
import { getReimbursements } from "../../../services/reimbursement";
import { fetchEpfDetails } from "../../../reducers/epfDetailsReducer";
import { fetchEsiDetails } from "../../../reducers/esiDetailsReducer";
import { fetchProfessionalTaxDeails } from "../../../reducers/professionalTaxDeailsReducer";
import { CiCircleMinus } from "react-icons/ci";
import { getPercentageSalary } from "../../../utils/helper";
import { calculatePayrunData } from "../../../services/payRun";
import Modal from "../../../components/Modal";
import { MdCheckBox } from "react-icons/md";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { Alert, OverlayTrigger, Tooltip } from "react-bootstrap";
import { IoIosInformationCircle } from "react-icons/io";
import { RiCheckboxCircleFill } from "react-icons/ri";
import { RiCheckboxBlankCircleLine } from "react-icons/ri";
import { GiArrowWings } from "react-icons/gi";
import PageTitleForEmployeeInfo from "../../../components/PageTitleForEmployeeInfo";
import WarningPopup from "../../../components/WarningPopup";
import { message } from "antd";
import NavigationModal from "../../../components/NavigationModal";
import { initInputToken } from "antd/es/input/style";

const seleStyles = {
  control: (provided, state) => ({
    ...provided,
    padding: "0.18rem 1rem",
    backgroundColor: "#2B2C47",
    color: "#C7C4E9",
    border: "5px",
    boxShadow: "none",
    minHeight: "3.6rem",
    width: "100%",
    borderRadius: "0rem",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    color: "#9E9AD1",

    "&:hover": {
      backgroundColor: "#393b5f",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#9e9ad0",
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    width: "80%",
  }),
  input: (provided, state) => ({
    ...provided,
    color: "#fff",
    border: "none",
    width: "100%",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#C7C4E9",

    "&:hover": {
      color: "#9E9AD1",
    },
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#9E9AD1",
  }),
  indicatorSeparator: () => null,
};

const CheckContainer = ({
  text,
  initialStatus,
  includeToolTip,
  toolTipText,
  onStatusChange,
}) => {
  const [status, setStatus] = useState(initialStatus);
  console.log(status, "status");
  const handleClick = () => {
    const newStatus = !status;
    setStatus(newStatus);
    onStatusChange(newStatus);
  };

  return (
    <div>
      <label className="text-3xl font-medium flex">
        {status ? (
          <MdCheckBox
            onClick={handleClick}
            className="inline-block text-4xl me-4 cursor-pointer text-[#21c38b]"
          />
        ) : (
          <MdCheckBoxOutlineBlank
            onClick={handleClick}
            className="inline-block text-4xl me-4 cursor-pointer text-[#21c38b]"
          />
        )}

        {!includeToolTip ? (
          text
        ) : (
          <OverlayTrigger
            className="cursor-pointer"
            placement="right"
            overlay={
              <Tooltip
                id={`tooltip-top`}
                className="text-[1.2rem] w-60 pb-8 cursor-pointer text-yellow"
              >
                {toolTipText}
              </Tooltip>
            }
          >
            <div className="flex gap-4">
              <span>{text}</span>
              <IoIosInformationCircle className="text-yellow" />
            </div>
          </OverlayTrigger>
        )}
      </label>
    </div>
  );
};

const SalaryInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [temp, setTemp] = useState(null);
  const [userTemp, setUserTemp] = useState(null);
  const [earnData, setEarnData] = useState([]);
  const [deductData, setDeductData] = useState([]);
  const [reimbData, setReimbData] = useState([]);
  const [deductionData, setDeductionData] = useState([]);
  const [total, setTotal] = useState(0);
  const [monthlyTotal, setMonthlyTotal] = useState(0);
  const [earningTotal, setEarningTotal] = useState(0);
  const [earningTotalMonthly, setEarningTotalMonthly] = useState(0);
  const [deductionTotal, setDeductionTotal] = useState(0);
  const [deductionTotalMonthly, setDeductionTotalMonthly] = useState(0);
  const [reimbTotal, setReimbTotal] = useState(0);
  const [reimbTotalMonthly, setReimbTotalMonthly] = useState(0);
  const [showUpdateBtn, setShowUpdateBtn] = useState(false);
  const [ctcAmountExceed, setCtcAmountExceed] = useState(false);
  const [ctc, setCtc] = useState(0);
  const [eTotal, setETotal] = useState(0);
  const [totalE, setTotalE] = useState(0);
  const [professionalTax, setProfessionalTax] = useState(null);
  const [grossSalary, setGrossSalary] = useState(0);
  const [netPay, setNetPay] = useState(0);
  const [netPayMonthly, setNetPayMonthly] = useState(0);
  const [fixedAllowance, setFixedAllowance] = useState(0);
  const [paidDays, setPaidDays] = useState(31);
  const [payableDays, setPayableDays] = useState(31);
  const [showEarnModal, setshowEarnModal] = useState(false);
  const [selectedearn, setselectedearn] = useState();
  const [showDedModal, setshowDedModal] = useState(false);
  const [selectedDed, setselectedDed] = useState(false);
  const [updatedEarningsConf, setupdatedEarningsConf] = useState();
  const [updatedDeductionsConf, setupdatedDeductionsConf] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [perDayGross, setPerDayGross] = useState(0);
  const [isTemplateSuitable, setIsTemplateSuitable] = useState({
    value: false,
    message: "",
  });
  const [includeEmployersContribution, setIncludeEmployersContribution] =
    useState(false);
  const [componentOnPayslip, setComponentOnPayslip] = useState(false);
  const [calculatOnProDataBasis, setCalculatOnProDataBasis] = useState(false);

  const tempData = useSelector((state) => state.salaryTemp.data);
  const tempSelect = useMemo(
    () => generateSelectData(tempData, "tempName"),
    [tempData],
  );
  const [basicAmount, setBasicAmount] = useState(0);
  const ptdetails = useSelector(
    (state) => state.professionalTaxDeails.data.data,
  );
  const [earning, setEarning] = useState(null);
  const [deduction, setDeduction] = useState(null);
  const [reimbursement, setReimbursement] = useState(null);
  const [triggerBack, setTriggerBack] = useState(false);

  useEffect(() => {
    setIncludeEmployersContribution(selectedDed?.includeEmployersContribution);
    setComponentOnPayslip(selectedDed?.componentOnPayslip);
    setCalculatOnProDataBasis(selectedDed?.calculatOnProDataBasis);
  }, [selectedDed]);

  useEffect(() => {
    GetEmployeeTemp({ id: location?.state?._id })
      .then(({ data }) => {
        setUserTemp(data?.salaryTempelate);
      })
      .catch((err) =>
        console.log("Tempelate is not Alloted for this Employee!"),
      );
  }, []);

  useEffect(() => {
    if (userTemp) {
      setEarning(
        Array.isArray(userTemp?.earnings) ? [...userTemp.earnings] : [],
      );
      setDeduction(
        Array.isArray(userTemp?.deductions) ? [...userTemp.deductions] : [],
      );
      setReimbursement(
        Array.isArray(userTemp?.reimbursements)
          ? [...userTemp.reimbursements]
          : [],
      );
      setCtc(userTemp?.ctc || 0);
      setGrossSalary(userTemp?.grossSalary || 0);
      setMonthlyTotal(((userTemp?.ctc || 0) / 12).toFixed(2));
      setTotal(userTemp?.ctc || 0);
    }
  }, [userTemp]);

  useEffect(() => {
    if (earning && deduction && monthlyTotal) {
      for (let deduct of deduction) {
        if (deduct.label == "epfInfo") {
          if (location?.state?.hasEpfNumber) {
            continue;
          } else {
            setIsTemplateSuitable({
              value: true,
              message: "Employee Do Not Have EPF Number",
            });
            return;
          }
        } else if (deduct.label == "esiInfo") {
          if (location?.state?.hasEsiNumber) {
            continue;
          } else {
            setIsTemplateSuitable({
              value: true,
              message: "Employee Do Not Have Esi Number",
            });
            return;
          }
        } else if (deduct.label == "Professional Tax") {
          if (location?.state?.hasPtDetails) {
            continue;
          } else {
            setIsTemplateSuitable({
              value: true,
              message: "Employee Do Not Have PT Number",
            });
            return;
          }
        }
      }
      calculateTemplateData({
        earning,
        deduction,
        reimbursement,
        monthlyTotal,
        professionalTax,
        paidDays,
      }).then(({ data }) => {
        setEarnData(data?.earning);
        setDeductData(data?.deduction);
        setReimbData(data?.reimbursement);
        setEarningTotal(data?.earningTotal);
        setNetPay(data?.netPayMonthly);
        setDeductionTotal(data?.totalDeduction);
        setReimbTotal(data?.TotalReimb);
        setPerDayGross(data?.perDayGross);
      });
    }
  }, [earning, deduction, reimbursement, monthlyTotal]);

  useEffect(() => {
    dispatch(fetchTemp());
    //  dispatch(fetchEpfDetails());
    // dispatch(fetchEsiDetails());
  }, [dispatch]);

  const handleSubmit = async (tempId) => {
    try {
      let hasError = false;

      earnData?.forEach((el) => {
        if (el.earningType.name === "Fixed Allowance" && el.monthly <= 0) {
          customToast.error("Fixed Allowance cannot be zero or negative");
          hasError = true;
        }
      });

      if (hasError) {
        return;
      }

      const payload = {
        joiningDate: location.state.joiningDate,
        tempData: {
          ctc,
          tempName: userTemp.tempName,
          description: userTemp.description,
          earnings: earnData,
          deductions: deductData,
          reimbursements: reimbData,
          earningTotal,
          earningTotalMonthly: earningTotal,
          deductionTotal,
          deductionTotalMonthly: deductionTotal,
          reimbTotal,
          reimbTotalMonthly: reimbTotal,
          status: true,
          monthlyTotal,
          total,
          netPay,
          netPayMonthly: netPay,
          perDayGross,
        },
      };
      const id = location.state._id;
      const response = await AddSalaryTempelate(id, payload);
      if (response.ok || response == "Inserted") {
        setShowUpdateBtn(false);
        setTimeout(() => {
          navigate("/employee/manage/employees-info/profile");
          customToast.success(
            "Salary Template Added/Updated to Employee Successfully",
          );
        }, 500);
      } else {
        customToast.error("Something went wrong", error);
      }
    } catch (error) {
      customToast.error("Something went wrong", error);
    }
  };

  const handleEarningAmountChange = (id, amount) => {
    const updatedEarnings = earning?.map((el) => {
      if (el._id === id) {
        let monthly, yearly;

        if (el.calcType.type === "percentage") {
          if (el.earningType.name == "HRA") {
            yearly = getPercentageSalary(basicAmount, amount);
            monthly = (yearly / 12).toFixed(2);
          } else {
            yearly = getPercentageSalary(ctc, amount);
            monthly = (yearly / 12).toFixed(2);
          }
        } else {
          amount = amount;
          yearly = amount;
          monthly = (yearly / 12).toFixed(2);
        }
        return {
          ...el,
          amount: amount,
          monthly: monthly,
          yearly: yearly,
        };
      }
      return el;
    });

    setEarning(updatedEarnings);
    setShowUpdateBtn(true);
  };

  const handleEarningAmount = (id, amount) => {
    const updatedEarnings = earning?.map((el) => {
      if (el._id === id) {
        if (amount <= el.amount) {
          const monthly = amount;
          const yearly = (12 * monthly).toFixed(2);
          return { ...el, monthly, yearly };
        } else {
          customToast.error("Max Amount Exceed");
        }
      }
      return el;
    });
    setEarning(updatedEarnings);
    setShowUpdateBtn(true);
  };

  const updateFieldAnswer = (setState, field, answer) => {
    setState((prevState) => ({
      ...prevState,
      [field]: answer,
    }));
  };

  useEffect(() => {
    setComponentOnPayslip(includeEmployersContribution);
    updateFieldAnswer(
      setupdatedDeductionsConf,
      "componentOnPayslip",
      includeEmployersContribution,
    );
  }, [includeEmployersContribution]);

  const handleDeductionChange = (id, value, type) => {
    const updatedDeductions = deduction.map((el) => {
      if (el._id === id) {
        if (type === "monthly") {
          const monthly = value;
          const yearly = value * 12;
          return { ...el, monthly, yearly };
        } else if (type === "yearly") {
          const yearly = value;
          const monthly = value / 12;
          return { ...el, monthly, yearly };
        }
      }
      return el;
    });
    setDeduction(updatedDeductions);
    setShowUpdateBtn(true);
  };

  const handleUpdateConfigs = async (e) => {
    e.preventDefault();
    const updatedSelectedEarn = { ...selectedearn };

    for (const key in updatedEarningsConf) {
      updatedSelectedEarn[key] = updatedEarningsConf[key];
    }

    const updatedEarning = earning.map((el) => {
      if (el?._id === selectedearn?._id) {
        return { ...el, ...updatedSelectedEarn };
      }
      return el;
    });
    setEarning(updatedEarning);
    setshowEarnModal(false);
    setShowUpdateBtn(true);
  };

  const handleUpdateDedConfigs = async (e) => {
    e.preventDefault();

    const updatedDeduction = deduction.map((el) => {
      if (el._id === selectedDed._id) {
        const updatedEl = { ...el };
        for (const key in updatedDeductionsConf) {
          updatedEl[key] = updatedDeductionsConf[key];
        }
        return updatedEl;
      }
      return el;
    });
    setDeduction(updatedDeduction);
    setshowDedModal(false);
    setShowUpdateBtn(true);
  };

  const onClose = () => {
    setIsTemplateSuitable({
      value: false,
      message: "",
    });
  };

  const onConfirm = () => {
    navigate("/employee/manage/employees-info/edit-employee", {
      state: location.state,
    });
    setIsTemplateSuitable({
      value: false,
      message: "",
    });
  };
  // console.log(componentOnPayslip, "componentonPayslip");
  return (
    <div className="mt-8 mr-8 w-full bg-whiteLight dark:bg-primaryDark pb-28">
      <WarningPopup showUpdateBtn={showUpdateBtn} />
      <NavigationModal
        isOpen={isTemplateSuitable?.value}
        message={isTemplateSuitable?.message}
        onClose={onClose}
        onConfirm={onConfirm}
      />
      <div className="dark:bg-primaryDark2">
        <PageTitleForEmployeeInfo
          title="Salary"
          showUpdateButton={showUpdateBtn}
          route={"/employee/manage/employees-info"}
        />
      </div>
      <div className="flex justify-between mt-16 items-center px-1">
        <div className="dark:text-tertiary font-semibold uppercase">
          {" "}
          {`${location?.state?.firstName}${" "}${location?.state?.lastName}`}
        </div>
        {userTemp ? (
          <button
            className="button-green-gradient"
            onClick={() =>
              navigate("/employee/manage/employees-info/revise-salary", {
                state: {
                  state: location?.state._id,
                  firstName: location?.state.firstName,
                  lastName: location.state.lastName,
                },
              })
            }
          >
            Revise Salary
          </button>
        ) : null}
      </div>
      <div className="row flex w-full justify-between !p-0">
        <div className="col-sm-12 col-md-4 col-lg-4">
          <div className="text-3xl font-medium dark:text-wheat">
            Select Salary Template <span className="text-error">*</span>
          </div>
          <div>
            <Select
              name="temp"
              id="temp"
              onChange={(e) => {
                setUserTemp(e);
                setCtc(e.ctc);
                setShowUpdateBtn(true);
              }}
              styles={seleStyles}
              placeholder="Select Tempelate"
              className="mt-3 !rounded-s-none min-w-90 max-w-90"
              options={tempSelect}
              value={{label:userTemp?.tempName,value:userTemp?.tempName}}
            />
          </div>
        </div>
      </div>
      <div className="dark:text-white mt-10">
        <span className="text-2xl mr-4">Annual CTC*</span>
        <input
          className="w-15 py-4 px-4 text-center rounded-sm dark:bg-primary"
          value={ctc}
          onChange={(e) => handleCtcChange(e)}
          disabled
        />
        <span className="text-2xl ml-5">Per Year</span>
      </div>
      <>
        {userTemp && !temp ? (
          <>
            <div className="h-auto mt-20  ">
              <table className="w-full">
                <thead className="uppercase dark:text-secondary dark:bg-primaryDark2 w-full text-center">
                  <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">
                    Salary Components
                  </th>
                  <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">
                    Calculation Type
                  </th>
                  <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">
                    Total Monthly
                  </th>
                  <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">
                    Total Annually
                  </th>
                  <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4"></th>
                </thead>
                <tbody>
                  <tr className="uppercase text-2x2">
                    <td className="px-3 py-8">Earnings</td>
                  </tr>
                  {earnData?.map((el, idx) => {
                    return (
                      <tr key={idx} className="dark:bg-primary text-center">
                        <td
                          className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6 w-1/5 hover:underline hover:cursor-pointer"
                          onClick={() => {
                            setshowEarnModal(true);
                            setselectedearn(el);
                          }}
                        >
                          {el?.eName}
                        </td>
                        <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat pl-6 flex w-full items-center gap-4 justify-end !h-full">
                          {el.calcType.type === "percentage" ? (
                            <>
                              <span className="dark:bg-primaryDark1 w-1/2">
                                <input
                                  type="number"
                                  placeholder=""
                                  className="dark:bg-primaryDark1 dark:text-secondary outline-none border-none rounded-lg font-semibold w-[45%] p-4 "
                                  value={el?.amount}
                                  onChange={(e) =>
                                    handleEarningAmountChange(
                                      el._id,
                                      e.target.value,
                                    )
                                  }
                                />
                              </span>{" "}
                              <span className="py-8 px-2 dark:bg-tertiary font-semibold w-[32%]">
                                % of {el.calcType.value}
                              </span>
                            </>
                          ) : (
                            <span className="p-8 w-full mt-2">{`${el?.calcType?.type}`}</span>
                          )}
                        </td>
                        <td className="border-2 border-collapse  border-primaryLight3  dark:border-primaryDark dark:text-wheat text-black px-6 py-6 w-1/5">
                          {el?.earningType?.name == "Basic" ||
                          el?.earningType?.name == "HRA" ? (
                            <span>{el.monthly}</span>
                          ) : (
                            <input
                              type="number"
                              placeholder=""
                              className="dark:bg-primaryDark1 dark:text-secondary outline-none border-none rounded-lg font-semibold w-16 w-full flex h-16 text-center"
                              value={el.monthly}
                              max={el.monthly}
                              onChange={(e) =>
                                handleEarningAmount(el._id, e.target.value)
                              }
                            />
                          )}
                        </td>
                        <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6 w-1/5">
                          {el?.yearly}
                        </td>
                        <td className="border-2 border-collapse  border-primaryLight3  dark:border-primaryDark dark:text-wheat text-black px-6 py-6 w-1/12 "></td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="text-2x2 uppercase ml-1 my-8">Deductions</div>
            <div className="h-auto mt-8">
              <table className="w-full">
                <tbody>
                  {deductData?.map((el, idx) => (
                    <tr key={idx} className="dark:bg-primary text-center">
                      {el?.subtitle !== "Employee Contribution" &&
                      el.includeEmployersContribution ? (
                        <>
                          <td
                            className="border-2 border-collapse border-primaryDark text-wheat px-6 py-6 w-1/5 hover:underline hover:cursor-pointer"
                            onClick={() => {
                              setshowDedModal(true);
                              setselectedDed(el);
                            }}
                          >
                            <span>{el?.name}</span>
                            <br />
                            {el?.subtitle && (
                              <span className="text-base text-tertiary font-bold">
                                ({el?.subtitle})
                              </span>
                            )}
                          </td>
                          <td className="border-2 border-collapse border-primaryLight3 dark:border-primaryDark dark:text-wheat text-black px-6 py-6 w-1/5">
                            <span>
                              {el.employerContributionRate?.label
                                ? `${el.employerContributionRate?.label}`
                                : `${el.deductionFrequency}`}
                            </span>
                          </td>
                          {el.employeeContributionRate?.label ===
                          "User Define" ? (
                            <>
                              <td className="border-2 border-collapse border-primaryLight3 dark:border-primaryDark dark:text-wheat text-black px-16 py-6 w-1/5">
                                <span>{"--"}</span>
                              </td>
                              <td className="border-2 border-collapse border-primaryLight3 dark:border-primaryDark dark:text-wheat text-black px-16 py-6 w-1/5">
                                <span>{"--"}</span>
                              </td>
                            </>
                          ) : (
                            <>
                              <td className="border-2 border-collapse border-primaryDark text-wheat px-16 py-6 w-1/5">
                                {el?.docName &&
                                el?.subtitle !== "Employee Contribution" ? (
                                  <span>{el?.monthly}</span>
                                ) : (
                                  <input
                                    type="number"
                                    placeholder=""
                                    className="dark:bg-primaryDark1 dark:text-secondary outline-none border-none rounded-lg font-semibold w-full h-16 text-center"
                                    value={el.monthly}
                                    onChange={(e) =>
                                      handleDeductionChange(
                                        el._id,
                                        e.target.value,
                                        "monthly",
                                      )
                                    }
                                  />
                                )}
                              </td>
                              <td className="border-2 border-collapse border-primaryDark text-wheat px-16 py-6 w-1/5">
                                {el?.docName ? (
                                  <span>{el?.yearly}</span>
                                ) : (
                                  <input
                                    type="number"
                                    placeholder=""
                                    className="dark:bg-primary dark:text-secondary outline-none border-none rounded-lg font-semibold w-full h-16 text-center"
                                    value={el.yearly}
                                  />
                                )}
                              </td>
                            </>
                          )}
                          <td className="border-2 border-collapse border-primaryLight3 dark:border-primaryDark dark:text-wheat text-black px-6 py-6 w-1/12"></td>
                        </>
                      ) : (
                        <>
                          {el.docName !== "esiInfo" &&
                            el?.docName !== "epfInfo" && (
                              <>
                                <td className="border-2 border-collapse border-primaryDark text-wheat px-6 py-6 w-1/5">
                                  <span>{el?.name}</span>
                                  {el?.subtitle && (
                                    <span className="text-base text-tertiary font-bold">
                                      ({el?.subtitle})
                                    </span>
                                  )}
                                </td>
                                <td className="border-2 border-collapse border-primaryLight3 dark:border-primaryDark dark:text-wheat text-black px-6 py-6 w-1/5">
                                  <span>
                                    {el.employeeContributionRate?.label
                                      ? `${el.employeeContributionRate?.label}`
                                      : `${el.deductionFrequency}`}
                                  </span>
                                </td>
                                {el.employeeContributionRate?.label ===
                                "User Define" ? (
                                  <>
                                    <td className="border-2 border-collapse border-primaryLight3 dark:border-primaryDark dark:text-wheat text-black px-16 py-6 w-1/5">
                                      <span>{"--"}</span>
                                    </td>
                                    <td className="border-2 border-collapse border-primaryLight3 dark:border-primaryDark dark:text-wheat text-black px-16 py-6 w-1/5">
                                      <span>{"--"}</span>
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td className="border-2 border-collapse border-primaryDark text-wheat px-16 py-6 w-1/5">
                                      {el?.docName &&
                                      el?.subtitle !==
                                        "Employee Contribution" ? (
                                        <span>{el?.monthly}</span>
                                      ) : (
                                        <input
                                          type="number"
                                          placeholder=""
                                          className="dark:bg-primaryDark1 dark:text-secondary outline-none border-none rounded-lg font-semibold w-full h-16 text-center"
                                          value={el.monthly}
                                          onChange={(e) =>
                                            handleDeductionChange(
                                              el._id,
                                              e.target.value,
                                              "monthly",
                                            )
                                          }
                                        />
                                      )}
                                    </td>
                                    <td className="border-2 border-collapse border-primaryDark text-wheat px-16 py-6 w-1/5">
                                      {el?.docName ? (
                                        <span>{el?.yearly}</span>
                                      ) : (
                                        <input
                                          type="number"
                                          placeholder=""
                                          className="dark:bg-primary dark:text-secondary outline-none border-none rounded-lg font-semibold w-full h-16 text-center"
                                          value={el.yearly}
                                        />
                                      )}
                                    </td>
                                  </>
                                )}
                                <td className="border-2 border-collapse border-primaryLight3 dark:border-primaryDark dark:text-wheat text-black px-6 py-6 w-1/12"></td>
                              </>
                            )}
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="text-3xl uppercase ml-1 my-8">reimbursement</div>
            <div className="h-auto mt-8 ">
              <table className="w-full">
                <tbody>
                  {reimbursement?.map((el, idx) => {
                    return (
                      <tr key={idx} className="dark:bg-primary text-center">
                        <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6 w-1/5">
                          {el?.nameOnPaySlip}
                        </td>
                        <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6 w-1/5">
                          <span>{"Fixed Amount"}</span>
                        </td>
                        <td className="border-2 border-collapse  border-primaryLight3  dark:border-primaryDark dark:text-wheat text-black px-6 py-6 w-1/5">
                          <input
                            type="number"
                            placeholder=""
                            className="dark:bg-primaryDark1 dark:text-secondary outline-none border-none rounded-lg font-semibold w-16 w-full flex h-16 text-center"
                            value={el.monthly}
                            max={el.monthly}
                            onChange={(e) =>
                              handleReimbursementAmount(el._id, e.target.value)
                            }
                          />
                        </td>
                        <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6 w-1/5">
                          {el?.yearly}
                        </td>
                        <td className="border-2 border-collapse  border-primaryLight3 dark:border-primaryDark dark:text-wheat text-black px-6 py-6 w-1/12"></td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="max-h-[400px] mt-8 overflow-y-scroll ">
              <table className="w-full">
                <tbody>
                  <tr className="dark:bg-primary text-center">
                    <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6 w-1/5"></td>
                    <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6 w-1/5">
                      <span className="text-tertiary">
                        {"Total Gross Salary"}
                      </span>
                    </td>
                    <td
                      className={`${
                        ctcAmountExceed ? "text-tertiary" : "text-tertiary"
                      } border-2 border-collapse border-primaryLight3 dark:border-primaryDark px-6 py-6 w-1/5`}
                    >
                      {monthlyTotal}
                    </td>
                    <td
                      className={`${
                        ctcAmountExceed ? "text-tertiary" : "text-tertiary"
                      } border-2 border-collapse border-primaryLight3 dark:border-primaryDark px-6 py-6 w-1/5`}
                    >
                      {total}
                    </td>
                    <td className="border-2 border-collapse border-primarrDark border-primaryDark px-6 py-6 w-1/12"></td>
                  </tr>
                </tbody>
              </table>
            </div>
            {showUpdateBtn ? (
              <button
                className="button_blue_gradient mt-20"
                onClick={() => handleSubmit(userTemp._id)}
              >
                Update
              </button>
            ) : null}
          </>
        ) : null}
      </>

      <Modal
        showModal={showEarnModal}
        closeModal={() => setshowEarnModal(false)}
        title="Other Configurations"
        width="w-max"
        content={
          <div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setshowEarnModal(false);
              }}
            >
              <div className="admin-content">
                <div className="flex flex-col gap-1 mt-4">
                  <CheckContainer
                    text="Add to Employee's Salary Structure"
                    initialStatus={selectedearn?.addToSalary}
                    onStatusChange={(e) => {
                      updateFieldAnswer(
                        setupdatedEarningsConf,
                        "addToSalary",
                        e,
                      );
                    }}
                  />

                  <CheckContainer
                    text="Taxable Earnings"
                    initialStatus={selectedearn?.taxable}
                    includeToolTip={true}
                    toolTipText="The Income Tax amount will be divided equally and deducted every month across Financial year"
                    onStatusChange={(e) => {
                      updateFieldAnswer(setupdatedEarningsConf, "taxable", e);
                    }}
                  />

                  <CheckContainer
                    text="Calculate on Pro-Rata Basis"
                    initialStatus={selectedearn?.proRata}
                    includeToolTip={true}
                    toolTipText="Pay will be adjusted based on employees working days"
                    onStatusChange={(e) => {
                      updateFieldAnswer(setupdatedEarningsConf, "proRata", e);
                    }}
                  />

                  <CheckContainer
                    text="Add as a Flexible Benefit Plan Component"
                    initialStatus={selectedearn?.flexible}
                    onStatusChange={(e) => {
                      updateFieldAnswer(setupdatedEarningsConf, "flexible", e);
                    }}
                  />

                  <CheckContainer
                    text="Consider for EPF Calculation"
                    initialStatus={selectedearn?.isEpf}
                    onStatusChange={(e) => {
                      updateFieldAnswer(setupdatedEarningsConf, "isEpf", e);
                    }}
                  />

                  <CheckContainer
                    text="Consider for ESI Calculation"
                    initialStatus={selectedearn?.isEsi}
                    onStatusChange={(e) => {
                      updateFieldAnswer(setupdatedEarningsConf, "isEsi", e);
                    }}
                  />

                  <CheckContainer
                    text="Show this Component on Payslip"
                    initialStatus={selectedearn?.onPaySlip}
                    onStatusChange={(e) => {
                      updateFieldAnswer(setupdatedEarningsConf, "onPaySlip", e);
                    }}
                  />
                </div>

                <div className="flex items-center gap-10">
                  <button
                    className="px-8 py-2 text-white bg-tertiary rounded-xl cursor-pointer"
                    value="Update"
                    onClick={(e) => handleUpdateConfigs(e)}
                  >
                    Submit
                  </button>
                  {/* <span
                          className="text-cyan cursor-pointer"
                          onClick={() => {
                            setshowEarnModal(false);
                          }}
                        >
                          Cancel
                        </span> */}
                </div>
              </div>
            </form>
          </div>
        }
      />

      <Modal
        showModal={showDedModal}
        closeModal={() => setshowDedModal(false)}
        title="Other Configurations"
        width="w-max"
        content={
          <div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setshowDedModal(false);
              }}
            >
              <div className="admin-content">
                <div className="flex flex-col gap-1 mt-4">
                  <CheckContainer
                    text="Include Employer's Contribution in CTC"
                    initialStatus={selectedDed?.includeEmployersContribution}
                    onStatusChange={(newValue) => {
                      setIncludeEmployersContribution(newValue);
                      updateFieldAnswer(
                        setupdatedDeductionsConf,
                        "includeEmployersContribution",
                        newValue,
                      );
                    }}
                  />

                  {includeEmployersContribution ? (
                    <CheckContainer
                      text="Show this Component on Payslip"
                      initialStatus={selectedDed?.componentOnPayslip}
                      onStatusChange={(newValue) => {
                        setComponentOnPayslip(newValue);
                        updateFieldAnswer(
                          setupdatedDeductionsConf,
                          "componentOnPayslip",
                          newValue,
                        );
                      }}
                    />
                  ) : null}

                  <CheckContainer
                    text="Calculate on Pro-Rata Basis"
                    initialStatus={selectedDed?.calculatOnProDataBasis}
                    includeToolTip={true}
                    toolTipText="Pay will be adjusted based on employees working days"
                    onStatusChange={(newValue) => {
                      setCalculatOnProDataBasis(newValue);
                      updateFieldAnswer(
                        setupdatedDeductionsConf,
                        "calculatOnProDataBasis",
                        newValue,
                      );
                    }}
                  />
                </div>

                <div className="flex items-center gap-10">
                  <button
                    type="submit"
                    className="px-8 py-2 text-white bg-tertiary rounded-xl cursor-pointer"
                    value="Update"
                    onClick={(e) => handleUpdateDedConfigs(e)}
                  >
                    Submit
                  </button>
                  <span
                    className="text-cyan cursor-pointer"
                    onClick={() => {
                      setshowEarnModal(false);
                    }}
                  >
                    Cancel
                  </span>
                </div>
              </div>
            </form>
          </div>
        }
      />
    </div>
  );
};

export default SalaryInfo;
