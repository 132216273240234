import React, { useReducer, useState } from 'react'
import { useSelector } from 'react-redux';
import PageTitle from '../../../../components/PageTitle';
import Select from 'react-select';
import { adminStyles, adminStylesLight } from '../../../../utils/selectStyles';
import './addDivision.css'
import OtherConfig from '../../../Settings/salary/other-config';
import { others } from './daysdata';
import ListForm from './listForm';


const AddDivision = () => {
    const theme = useSelector((state) => state.theme.theme);
    const [config , setConfig] = useState([])
    const [divisionName , setDivisionName] = useState(null);
    const [divisionCode , setDivisionCode] = useState(null);
    const [salaryType , setSalaryType] = useState(null);
    const [salaryAmountType , setSalaryAmountType] = useState(null);
    const [addressLine1 , setAddressLine1] = useState(null);
    const [addressLine2 , setAddressLine2] = useState(null);
    const [email , setEmail] = useState(null);
    const [phoneNumber , setPhoneNumber] = useState(null);
    const [bankBranch , setBankBranch] = useState(null);
    const [bankAccountNumber , setBankAccountNumber] = useState(null);
    const [ifsc , setIfsc] = useState(null);
    const [bankName , setBankName] = useState(null);
    const [state , setState] = useState(null);
    const [guid , setGuid] = useState(null);
    const [wageType , setWageType] = useState(null);
    const [managerName , setManagerName] = useState(null);
    const [weeklyOff , setWeeklyOff] = useState(null);
    const [registrationNo , setRegistrationNo] = useState(null);

    const handleCheckboxChange = (titleId) => {
      setConfig((prevConfig) => {
        if (prevConfig.includes(titleId)) {
          return prevConfig.filter((id) => id !== titleId);
        } else {
          return [...prevConfig, titleId];
        }
      });
    };

  return (
    <main className={"main-content " + `${theme == "light" ? "light_bg_main" : ""}`} >
       <div className={"creation-content" + `${theme=="light"?"light_bg":""}`} >
        <PageTitle title="Add Division" />
      </div>

      <section className={"creation-content_form" + `${theme == "light" ? "section-form_content" : ""}`}>
          <form >
          <div className="row">
            <div className="row">
            <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                <label >
                  DIVISION NAME<span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                  className={"me-5 mt-4 "  + (theme === 'light' ? 'input-padding' : '')}
                  id="mName"
                  placeholder="Enter Division Name"
                  value={divisionName} 
                  onChange={(e) => setDivisionName(e.target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                <label htmlFor="mName">
                  DIVISION CODE <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                   className={"me-5 mt-4 "  + (theme === 'light' ? 'input-padding' : '')}
                  id="mName"
                  placeholder="Enter Division Code"
                  value={divisionCode}
                  onChange={(e) => setDivisionCode(e.target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                <label htmlFor="mName">
                  SALARY TYPE <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  styles={theme == "light" ? adminStylesLight : adminStyles}
                  placeholder="Enter Salary Type" 
                  className='mt-4'
                  onChange={({ value, label }) =>
                    setSalaryType({ value, label })
                  }
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                <label htmlFor="mName">
                  SALARY AMOUNT TYPE
                </label>
                <br />
                <Select
                  styles={theme == "light" ? adminStylesLight : adminStyles}
                  placeholder="SELECT" 
                  className='mt-4'
                  onChange={({ value, label }) =>
                    setSalaryAmountType({ value, label })
                  }
                />
              </div>
            </div>

            <div className="row">
            <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                <label >
                  ADDRESS LINE 1<span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                   className={"me-5 mt-4 "  + (theme === 'light' ? 'input-padding' : '')}
                  id="mName"
                  placeholder="Enter Address Line 1"
                  value={addressLine1}
                  onChange={(e) => setAddressLine1(e.target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                <label htmlFor="mName">
                   ADDRESS LINE 2<span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                   className={"me-5 mt-4 "  + (theme === 'light' ? 'input-padding' : '')}
                  id="mName"
                  placeholder="Enter Address line 2"
                  value={addressLine2}
                  onChange={(e) => setAddressLine2(e.target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                <label htmlFor="mName">
                  EMAIL ADDRESS 
                </label>
                <br />
                <input
                  type="text"
                   className={"me-5 mt-4 "  + (theme === 'light' ? 'input-padding' : '')}
                  id="mName"
                  placeholder="Enter Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                <label htmlFor="mName">
                  PHONE NUMBER 
                </label>
                <br />
                <input
                  type="text"
                   className={"me-5 mt-4 "  + (theme === 'light' ? 'input-padding' : '')}
                  id="mName"
                  placeholder="Enter Phone Number"
                  value={phoneNumber} 
                  onChange={(e) => setPhoneNumber(e.target.value)} 
                />
              </div>
            </div>

            <div className="row">
            <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                <label >
                  BANK BRANCH
                </label>
                <br />
                <input
                  type="text"
                   className={"me-5 mt-4 "  + (theme === 'light' ? 'input-padding' : '')}
                  id="mName"
                  placeholder="Enter Bank Branch"
                  value={bankBranch} 
                  onChange={(e) => setBankBranch(e.target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                <label htmlFor="mName">
                  BANK ACCOUNT NUMBER 
                </label>
                <br />
                <input
                  type="text"
                   className={"me-5 mt-4 "  + (theme === 'light' ? 'input-padding' : '')}
                  id="mName"
                  placeholder="Enter Bank Account No" 
                  value={bankAccountNumber}
                  onChange={(e) => setBankAccountNumber(e.target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                <label htmlFor="mName">
                  IFSC CODE 
                </label>
                <br />
                <input
                  type="text"
                   className={"me-5 mt-4 "  + (theme === 'light' ? 'input-padding' : '')}
                  id="mName"
                  placeholder="Enter IFSC Code"
                  value={ifsc} 
                  onChange={(e) => setIfsc(e.target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                <label htmlFor="mName">
                  BANK NAME
                </label>
                <br />
                <input
                  type="text"
                   className={"me-5 mt-4 "  + (theme === 'light' ? 'input-padding' : '')}
                  id="mName"
                  placeholder="Enter Bank Name"
                  value={bankName}
                  onChange={(e) => setBankName(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
            <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                <label >
                  STATE
                </label>
                <br />
                <Select
                  styles={theme == "light" ? adminStylesLight : adminStyles}
                  placeholder="Select State" 
                  className='mt-4'
                  onChange={({ value, label }) =>
                    setState({ value, label })
                  }
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                <label htmlFor="mName">
                  GUID 
                </label>
                <br />
                <input
                  type="text"
                   className={"me-5 mt-4 "  + (theme === 'light' ? 'input-padding' : '')}
                  id="mName"
                  placeholder="Enter GUID"
                  value={guid}
                  onChange={(e) => setGuid(e.target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                <label htmlFor="mName">
                  WAGE TYPE
                </label>
                <br />
                <input
                  type="text"
                   className={"me-5 mt-4 "  + (theme === 'light' ? 'input-padding' : '')}
                  id="mName"
                  placeholder="Enter Wage Type"
                  value={wageType}
                  onChange={(e) => setWageType(e.target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                <label htmlFor="mName">
                  MANAGER NAME
                </label>
                <br />
                <input
                  type="text"
                   className={"me-5 mt-4 "  + (theme === 'light' ? 'input-padding' : '')}
                  id="mName"
                  placeholder="Enter Manager Name" 
                  value={managerName}
                  onChange={(e) => setManagerName(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
            <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                <label >
                  WEEKLY OFF
                </label>
                <br />
                <Select
                  styles={theme == "light" ? adminStylesLight : adminStyles}
                  placeholder="Select Off Day" 
                  className='mt-4'
                  onChange={({ value, label }) =>
                    setweek({ value, label })
                  }
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                <label htmlFor="mName">
                  REGISTRATION CERTIFICATION
                </label>
                <br />
                <input
                  type="text"
                  className={"me-5 mt-4 "  + (theme === 'light' ? 'input-padding' : '')}
                  id="mName"
                  placeholder="Enter Number"
                  value={registrationNo}
                  onChange={(e) => setRegistrationNo(e.target.value)}
                />
              </div>
             </div>
           </div>
         
         <div className='creation-content_container'>
          <div  className={
             "creation-content m-0 mt-3 " + `${theme == "light" ? "light_bg" : ""}`
           }>
             <div className='blank-container'></div>
          </div>
          <div  className={
             "creation-content m-0 second-box " + `${theme == "light" ? "light_bg" : ""}`
           }>
            <div className="other-days" >
             <label className='label'>Day <span>1</span> Of Current Month</label>
             <OtherConfig others={others} handleCheckboxChange={handleCheckboxChange}/>
            </div>
          </div>
        </div>
        
         <ListForm />
           
        {/* <div className='list'>
          <div>
             <h3>Payslip Entries with zero values should be removed</h3>
             <div className='d-flex gap-64 ml-10'>
             <div className='d-flex gap-4'>
              <input type='checkbox'/>
              <label>Remove Zero Value Entries</label>
             </div>
             </div>
          </div>

          <div className='d-flex flex-col'>
             <h3>Deducting Professional Tax from Employee Income as per “PT Slab”</h3>
             <div className='d-flex gap-64 ml-10'>
             <div className='d-flex gap-4'>
              <input type='checkbox'/>
              <label>Professional Tax Standard Deduction</label>
             </div>
             </div>
          </div>
        </div> */}

            <div className="col-sm-2 col-md-4 col-lg-2 mt-5">
              <button type="submit" className="button-blue-gradient">
                Save
              </button>
            </div> 
          </form>   
        </section>
    </main>
  )
}

export default AddDivision;