export const title = "Manage Employee";

export const links = [
  {
    name: "Create Employee",
    route: "create",
    accessor: "create",
  },
  {
    name: "Upload Documents",
    route: "upload-documents/pan-card",
    accessor: "upload documents",
  },
  {
    name: "Employees Info",
    route: "employees-info/profile",
    accessor: "employees info",
  },
  {
    name: "Onboarding",
    route: "onboarding",
    accessor: "onboarding",
  },
  // {
  //   name: "Account Status",
  //   route: "account-status",
  //   accessor: "account status",
  // },
];


