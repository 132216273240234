import "./loan.css";
import PageTitle from "../../../../components/PageTitle/index.jsx";
import React, { useEffect, useMemo, useRef, useState } from "react";
import "./loan.css";
import Table from "../../../../components/Table";
import { useSelector, useDispatch } from "react-redux";
import { adminStyles, adminStylesLight } from "../../../../utils/selectStyles";
import { createloan, deleteloan } from "../../../../services/loans.js";
import { useForm } from "react-hook-form";
import customToast from "../../../../components/CustomToast/index.js";
import { HiPencil } from "react-icons/hi";
import { fetchAllLoans } from "../../../../reducers/loansReducer.js";
import { fetchAllEmployeesLoans } from "../../../../reducers/employeesloansReducer.js";
import Select from "react-select";
import { IoAddOutline } from "react-icons/io5";
import { components } from "react-select";
import Modal from "../../../../components/Modal";
import Dropdown from "../../../../components/DropDown/dropdown.jsx";

let sbiInterestRateForEducationLoan = 8.15;
let sbiInterestRateForHomeLoan = 8.5;
let sbiInterestRateForAutoLoan = 8.85;
let sbiInterestRateForPersonalLoan = 11.15;
let sbiInterestRate = 10;

const CreateLoan = () => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.theme);
  const loanData = useSelector((state) => state.loans.data);
  const [addOtherModal, setaddOtherModal] = useState();
  const [selected, setselected] = useState({ loanName: null, loanType: null });

  useEffect(() => {
    dispatch(fetchAllEmployeesLoans());
    dispatch(fetchAllLoans());
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "LOAN NAME",
        accessor: "loanName",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "INTEREST RATE",
        accessor: "interestRate",
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row }) => {
          const rate = row.original.interestRate;
          return (
            <div className="flex items-center justify-center w-full h-full">
              {rate} %
            </div>
          );
        },
      },
      {
        Header: "ACTION",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <>
              <Dropdown
                icon={
                  <HiPencil className="text-[#3893d7] cursor-pointer text-4xl" />
                }
                options={[
                  {
                    text: "Delete",
                    onClick: () => {
                      deleteloan({ loanId: row.original._id })
                        .then((res) => {
                          dispatch(fetchAllLoans());
                          return customToast.success(
                            "Loan deleted successfully",
                          );
                        })
                        .catch((err) => {
                          if (err.response.data.message === "Bad Request!") {
                            return customToast.error(err.response.data.data);
                          }
                          return customToast.error("Something went wrong !");
                        });
                    },
                  },
                ]}
              />
            </>
          );
        },
      },
    ],
    [loanData],
  );

  let loanNamesOptions = [
    { label: "Education Loan", value: "Education Loan" },
    { label: "Personal Loan", value: "Personal Loan" },
    { label: "Medical Loan", value: "Medical Loan" },
    { label: "Home Loan", value: "Home Loan" },
    { label: "Auto Loan", value: "Auto Loan" },
    { label: "Add Other", value: "Add Other", icon: <IoAddOutline /> },
  ];

  const loanTypeOptions = [
    { label: "Interest-free Loan", value: "Interest-free Loan" },
    { label: "Concessional Loan", value: "Concessional Loan" },
    { label: "Loan at Market Rate", value: "Loan at Market Rate" },
  ];

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!selected.loanName || !selected.loanType) {
      return customToast.error("One of the required field is missing !");
    }

    try {
      const res = await createloan({
        loanName: selected.loanName.value,
        loanType: selected.loanType.value,
        interestRate: selected.interestRate,
      });
      if (res) {
        setselected({ loanName: null, loanType: null, interestRate: "" });
        dispatch(fetchAllLoans());
        return customToast.success("Loan created successsfully");
      }
    } catch (err) {
      return customToast.error("Something went wrong !");
    }
  };

  return (
    <main
      className={
        "main-content " + `${theme == "light" ? "light_bg_main" : ""} w-full`
      }
    >
      <div
        className={
          "area-creation-content  " + `${theme == "light" ? "light_bg" : ""}`
        }
      >
        <PageTitle title="Create Loan" route={"../.."} />

        <form onSubmit={submitHandler} className="admin-content">
          <div className="row ">
            <div>
              <label htmlFor="loan_name">
                LOAN NAME
                <span className="asterisk-imp">*</span>
              </label>
              <Select
                id="loan_name"
                options={loanNamesOptions}
                value={selected.loanName}
                onChange={(e) => {
                  if (e.value === "Add Other") {
                    setaddOtherModal(true);
                  } else if (e.value === "Medical Loan") {
                    setselected({
                      loanName: e,
                      loanType: {
                        label: "Interest-free Loan",
                        value: "Interest-free Loan",
                      },
                      interestRate: 0,
                    });
                  } else {
                    setselected({
                      loanName: e,
                      loanType: null,
                      interestRate: null,
                    });
                  }
                }}
                styles={theme == "light" ? adminStylesLight : adminStyles}
                placeholder="Select Name"
                components={{
                  Option: ({ data, ...props }) => (
                    <components.Option {...props}>
                      <div className="flex gap-2 items-center">
                        <span>{data.label}</span>
                        <div>{data.icon}</div>
                      </div>
                    </components.Option>
                  ),
                }}
              />
            </div>

            <div>
              <label htmlFor="loan_type">
                LOAN TYPE <span className="asterisk-imp">*</span>
              </label>
              <Select
                id="loan_type"
                options={loanTypeOptions}
                value={selected.loanType}
                onChange={(e) => {
                  if (e.value === "Interest-free Loan") {
                    setselected({ ...selected, loanType: e, interestRate: 0 });
                  } else if (e.value === "Loan at Market Rate") {
                    if (selected?.loanName?.value === "Education Loan") {
                      setselected({
                        ...selected,
                        loanType: e,
                        interestRate: sbiInterestRateForEducationLoan,
                      });
                    } else if (selected?.loanName?.value === "Home Loan") {
                      setselected({
                        ...selected,
                        loanType: e,
                        interestRate: sbiInterestRateForHomeLoan,
                      });
                    } else if (selected?.loanName?.value === "Personal Loan") {
                      setselected({
                        ...selected,
                        loanType: e,
                        interestRate: sbiInterestRateForPersonalLoan,
                      });
                    } else if (selected?.loanName?.value === "Auto Loan") {
                      setselected({
                        ...selected,
                        loanType: e,
                        interestRate: sbiInterestRateForAutoLoan,
                      });
                    } else {
                      setselected({
                        ...selected,
                        loanType: e,
                        interestRate: sbiInterestRate,
                      });
                    }
                  } else {
                    setselected({ ...selected, loanType: e });
                  }
                }}
                styles={theme == "light" ? adminStylesLight : adminStyles}
                placeholder="Select Type"
                className={`w-full ${
                  selected?.loanName?.value === "Medical Loan" &&
                  "pointer-events-none"
                }`}
              />
            </div>

            <div>
              <label htmlFor="interest_rate">INTEREST RATE</label>
              <input
                type="text"
                value={selected.interestRate}
                onChange={(e) =>
                  setselected({ ...selected, interestRate: e.target.value })
                }
                placeholder="Enter Interest Rate"
                className={`bg-[#353456] ${
                  selected?.loanType?.value === "Loan at Market Rate"
                    ? "pointer-events-none disabled"
                    : ""
                }${
                  selected?.loanType?.value === "Interest-free Loan"
                    ? "pointer-events-none disabled"
                    : ""
                } px-6 py-[9px] outline-none`}
              />
            </div>

            <div className="area-creation-content__form-submit">
              <button
                type="submit"
                className="button-blue-gradient py-[0.8rem] px-[4rem]"
              >
                Add Loan
              </button>
            </div>
          </div>
        </form>

        {
          // loanData?.length > 0 &&
          <div>
            <section className={"cardlink-row pb-4"}>
              <label className="show-entries">
                Show Entries ({loanData.length})
              </label>

              <div className="filter-table  mr-8 ">
                <Table columns={columns} data={loanData} />
              </div>
            </section>
          </div>
        }
      </div>

      <Modal
        showModal={addOtherModal}
        closeModal={() => setaddOtherModal(false)}
        title="Add Loan Name"
        width="w-[300px]"
        content={
          <div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setselected({
                  ...selected,
                  loanName: {
                    label: e.target.loan_name.value,
                    value: e.target.loan_name.value,
                  },
                });
                setaddOtherModal(false);
              }}
            >
              <div className="flex flex-col gap-10">
                <div className="flex flex-col gap-2">
                  <label htmlFor="loan_name">Enter Loan Name</label>
                  <input
                    id="loan_name"
                    type="text"
                    placeholder="Enter Name"
                    className="bg-[#2B2C47] px-6 py-4 outline-none"
                  />
                </div>

                <div className="flex items-center gap-10">
                  <button
                    type="submit"
                    className="px-8 py-2 w-max text-white bg-tertiary rounded-xl cursor-pointer"
                    value="Save"
                  >
                    Submit
                  </button>
                  <span className="text-cyan cursor-pointer">Cancel</span>
                </div>
              </div>
            </form>
          </div>
        }
      />
    </main>
  );
};

export default CreateLoan;
