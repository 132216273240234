import { createSlice } from "@reduxjs/toolkit";
import { GetEmployeesWithAniversary } from "../../services/hrms";

const initialState = {
  data: [],
};

const anniversaryReducer = createSlice({
  name: "anniversaryReducer",
  initialState,
  reducers: {
    setEmployeesWithAniversary: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setEmployeesWithAniversary } = anniversaryReducer.actions;

export const fetchEmployeesWithAnniversary = () => async (dispatch) => {
  try {
    const data = await GetEmployeesWithAniversary();
    dispatch(setEmployeesWithAniversary(data));
  } catch (error) {
  }
};

export default anniversaryReducer.reducer;
