import { createSlice } from "@reduxjs/toolkit";

const getInitialTheme = () => {
  const storedTheme = localStorage.getItem("theme");
  return storedTheme ? storedTheme : "dark"; 
};

let initialState = {
  theme: getInitialTheme(),
};

const themeReducer = createSlice({
  name: "theme",
  initialState,
  reducers: {
    changeTheme(state, action) {
      switch (action.payload) {
        case "dark":
          state.theme = "dark";
          break;
        case "light":
          state.theme = "light";
          break;
        default:
          state.theme = "dark";
      }
    },
  },
});

export const { changeTheme } = themeReducer.actions;
export default themeReducer.reducer;