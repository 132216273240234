import { customAxios } from "../utils/axiosinstance";

export const CreatePaymentSchedule = async (payload) => {
  const { data } = await customAxios.post(`/hrms/payment/`, payload);
  return data;
};

export const GetPaymentSchedule = async (payload) => {
  const { data } = await customAxios.get(`/hrms/payment/`);
  return data.data;
};
