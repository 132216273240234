import React, { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { setUpSelectStyles } from "../../../utils/selectStyles";
import ToggleButton from "../../../components/Toggle/ToggleButton/ToggleButton";
import customToast from "../../../components/CustomToast";
import { useDispatch, useSelector } from "react-redux";
import { generateSelectData } from "../../../utils/serialiseResponse";
import {
  addStateAsync,
  clearStates,
  fetchStates,
} from "../../../reducers/stateReducer";
import { IoAddOutline } from "react-icons/io5";
import Modal from "../../../components/Modal";

const deductionTypes = [
  { value: "monthly", label: "Monthly" },
  { value: "annual", label: "Annual" },
];

const AllStatesData = [
  { value: "Andhra Pradesh", label: "Andhra Pradesh" },
  { value: "Arunachal Pradesh", label: "Arunachal Pradesh" },
  { value: "Assam", label: "Assam" },
  { value: "Bihar", label: "Bihar" },
  { value: "Chhattisgarh", label: "Chhattisgarh" },
  { value: "Goa", label: "Goa" },
  { value: "Gujarat", label: "Gujarat" },
  { value: "Haryana", label: "Haryana" },
  { value: "Himachal Pradesh", label: "Himachal Pradesh" },
  { value: "Jharkhand", label: "Jharkhand" },
  { value: "Karnataka", label: "Karnataka" },
  { value: "Kerala", label: "Kerala" },
  { value: "Madhya Pradesh", label: "Madhya Pradesh" },
  { value: "Maharashtra", label: "Maharashtra" },
  { value: "Manipur", label: "Manipur" },
  { value: "Meghalaya", label: "Meghalaya" },
  { value: "Mizoram", label: "Mizoram" },
  { value: "Nagaland", label: "Nagaland" },
  { value: "Odisha", label: "Odisha" },
  { value: "Punjab", label: "Punjab" },
  { value: "Rajasthan", label: "Rajasthan" },
  { value: "Sikkim", label: "Sikkim" },
  { value: "Tamil Nadu", label: "Tamil Nadu" },
  { value: "Telangana", label: "Telangana" },
  { value: "Tripura", label: "Tripura" },
  { value: "Uttar Pradesh", label: "Uttar Pradesh" },
  { value: "Uttarakhand", label: "Uttarakhand" },
  { value: "West Bengal", label: "West Bengal" },
  {
    value: "Andaman and Nicobar Islands",
    label: "Andaman and Nicobar Islands",
  },
  { value: "Chandigarh", label: "Chandigarh" },
  {
    value: "Dadra and Nagar Haveli and Daman and Diu",
    label: "Dadra and Nagar Haveli and Daman and Diu",
  },
  { value: "Lakshadweep", label: "Lakshadweep" },
  { value: "Delhi", label: "Delhi" },
  { value: "Puducherry", label: "Puducherry" },
];

const ProfessionalTax = ({ professionalTax, setProfessionalTax }) => {
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [addOtherModal, setaddOtherModal] = useState();
  const [stateName, setStateName] = useState(null);
  const [state, setState] = useState("");
  const statesData = useSelector((state) => state.states.data);
  const states = useMemo(
    () => generateSelectData(statesData, "name"),
    [statesData],
  );

  console.log(professionalTax, "pttt");

  useEffect(() => {
    dispatch(fetchStates("state"));
    return () => {
      dispatch(clearStates());
    };
  }, [dispatch]);

  let stateNameOptions = [
    { label: "Add Other", value: "Add Other", icon: <IoAddOutline /> },
  ];

  const stateOptions = [...states, ...stateNameOptions];
  console.log(stateOptions, "stateOptions");

  const handleStateSubmit = async (e) => {
    e.preventDefault();
    if (!stateName) return customToast.error("Please Enter State Name");
    var index = states.findIndex(
      (p) => p.name.toLowerCase() === stateName.toLowerCase(),
    );
    if (index === -1) {
      try {
        await dispatch(addStateAsync({ name: stateName }));
        customToast.success("State Successfully Created");
        setStateName("");
        setaddOtherModal(false);
      } catch (e) {
        customToast.error("We Encountered an Error");
      }
    } else {
      customToast.error("State Already Exists!");
    }
  };
  console.log(toggle, "toggle")
  return (
    <div className="comp cursor-pointer" >
      <div className="heading_container" onClick={() => setToggle(!toggle)}>
        <h1>
          Professional Tax (PT)
          <ToggleButton
            value={professionalTax?.enableProfessionalTax}
            onChange={() => setToggle(!toggle)}
          />
        </h1>

        <p>
          Any Company with more than 20 Employees Any Company with more than 20
          Employees Any Company with more than 20 Employees
        </p>
      </div>
      <form className={"set-up " + `${toggle ? "show" : "hide"}`}>
        <div className="two_in_one">
          <div className="w-full">
            <label htmlFor="">PT Number</label>
            <br />
            <input
              className="text_input"
              type="text"
              placeholder="Enter PT Number Here"
              value={professionalTax.PtNumber}
              onChange={(e) => {
                setProfessionalTax({
                  ...professionalTax,
                  PtNumber: e.target.value,
                });
              }}
            />
          </div>
          <div className="w-full">
            <label htmlFor="deductionSelect">Select State</label>
            <br />
            <Select
              id="state_name"
              placeholder="State"
              options={stateOptions}
              onChange={(e) => {
                if (e.value === "Add Other") {
                  setaddOtherModal(true);
                } else {
                  setProfessionalTax({ ...professionalTax, state: e?._id });
                }
              }}
              styles={setUpSelectStyles}
              className="w-full"
            />

            <Modal
              showModal={addOtherModal}
              closeModal={() => setaddOtherModal(false)}
              title="Add State"
              width="w-[300px]"
              content={
                <div>
                  <form onSubmit={(e) => handleStateSubmit(e)}>
                    <div className="flex flex-col gap-10">
                      <div className="flex flex-col gap-2">
                        <label htmlFor="loan_name">Enter State Name</label>
                        <input
                          id="state_name"
                          type="text"
                          placeholder="Enter Name"
                          className="bg-[#2B2C47] px-6 py-4 outline-none"
                          onChange={(e) => setStateName(e.target.value)}
                        />
                      </div>

                      <div className="flex items-center gap-10">
                        <button
                          className="px-8 py-2 w-max text-white bg-tertiary rounded-xl cursor-pointer"
                          value="Save"
                          onClick={(e) => handleStateSubmit(e)}
                        >
                          Submit
                        </button>
                        <span className="text-cyan cursor-pointer">Cancel</span>
                      </div>
                    </div>
                  </form>
                </div>
              }
            />
          </div>
        </div>
        <div>
          <label htmlFor="">Deduction Type</label>
          <br />
          <Select
            id="deductionSelect"
            isSearchable={false}
            styles={setUpSelectStyles}
            options={deductionTypes}
            value={professionalTax.deductionType}
            onChange={(e) =>
              setProfessionalTax({
                ...professionalTax,
                deductionType: e,
              })
            }
          />
        </div>

        {/* <div className="terms">
          <span className="text-green-500 my-2">View Sample Calculation</span>
          <div>
            <input
            className="checkbox_input"
              type="checkbox"
              onClick={(e) => {
                setProfessionalTax({
                  ...professionalTax,
                  includeEmployersContribution: e.target.checked,
                });
              }}
            />
            <span>Include Employers Contribution in the CTC</span>
          </div>
        </div> */}
        <div className="mt-4">
          <button
            className="submit_button"
            onClick={(e) => {
              e.preventDefault();
              if (
                professionalTax.PtNumber &&
                professionalTax.deductionType &&
                professionalTax.state
              ) {
                setProfessionalTax({
                  ...professionalTax,
                  enableProfessionalTax: true,
                });
                setToggle(!toggle);
              } else customToast.error("Please fill all the fields");
            }}
          >
            {professionalTax.enableProfessionalTax === true
              ? "Enabled"
              : "Enable"}
          </button>
          <span
            className="skip"
            onClick={(e) => {
              e.preventDefault();
              setProfessionalTax({
                ...professionalTax,
                enableProfessionalTax: false,
              });
              setToggle(!toggle);
            }}
          >
            cancel
          </span>
        </div>
      </form>
    </div>
  );
};

export default ProfessionalTax;
