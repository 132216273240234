import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { GetEarnings } from "../../../../../services/earning";
import customToast from "../../../../../components/CustomToast";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdDelete, MdEdit } from "react-icons/md";
import { CiCircleMinus } from "react-icons/ci";
import PageTitle from "../../../../../components/PageTitle";
import Select from "react-select";
import {
  generateSelectData,
  generateSelectDataForEarnings_SalaryTemp,
  generateSelectDataForSalaryTemp,
} from "../../../../../utils/serialiseResponse";
import {
  convertNumberToWords,
  getPercentageSalary,
} from "../../../../../utils/helper";
import { GetAllDeduction } from "../../../../../services/deduction";
import { getReimbursements } from "../../../../../services/reimbursement";
import { fetchEpfDetails } from "../../../../../reducers/epfDetailsReducer";
import { fetchEsiDetails } from "../../../../../reducers/esiDetailsReducer";
import { fetchProfessionalTaxDeails } from "../../../../../reducers/professionalTaxDeailsReducer";
import { customAxios } from "../../../../../utils/axiosinstance";
import { CreateTempelate } from "../../../../../services/salaryTempelates";

const adminStyles = {
  control: (provided, state) => ({
    ...provided,
    padding: "0.2rem 1rem",
    backgroundColor: "#37AAF8",
    color: "#FFFFF",
    cursor: "pointer",
    border: "5px",
    borderRadius: "50px",
    boxShadow: "none",
    minHeight: "3.6rem",
    width: "100%",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    color: "#9E9AD1",

    "&:hover": {
      backgroundColor: "#393b5f",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#9e9ad0",
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    width: "80%",
  }),
  input: (provided, state) => ({
    ...provided,
    color: "#fff",
    border: "none",
    width: "100%",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#C7C4E9",
    display: "none",
    "&:hover": {
      color: "#9E9AD1",
    },
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#FFFFF",
  }),
  indicatorSeparator: () => null,
};
const AddSalaryTemplates = () => {
  const navigate = useNavigate();
  const [earnData, setEarnData] = useState([]);
  const [deductData, setDeductData] = useState([]);
  const [reimbData, setReimbData] = useState([]);
  const [editing, setEditing] = useState(false);
  const [ctc, setCtc] = useState(0);
  const [tempName, setTempName] = useState("");
  const [description, setDescription] = useState("");
  const [earnings, setEarnings] = useState([]);
  const [deductions, setDeductions] = useState([]);
  const [reimbursements, setReimbursements] = useState([]);
  const [editId, setEditId] = useState("");
  const [basicCount, setBasicCount] = useState(0);
  const [hraCount, setHraCount] = useState(0);
  const [basicAmount, setBasicAmount] = useState(0);
  const [earningTotal, setEarningTotal] = useState(0);
  const [earningTotalMonthly, setEarningTotalMonthly] = useState(0);
  const [deductionTotal, setDeductionTotal] = useState(0);
  const [deductionTotalMonthly, setDeductionTotalMonthly] = useState(0);
  const [status, setStatus] = useState(true);
  const dispatch = useDispatch();
  const epfData = useSelector((state) => [state.epfInfo.data]);
  const esiData = useSelector((state) => [state.esiInfo.data]);
  const [reimbTotal, setReimbTotal] = useState(0);
  const [reimbTotalMonthly, setReimbTotalMonthly] = useState(0);
  const [maxAmount, setMaxAmount] = useState(0);
  const [ctcStatus, setCtcStatus] = useState(false);
  const [ctcAmountExceed, setCtcAmountExceed] = useState(false);
  const [reimbursement, setReimbursement] = useState([]);
  const theme = useSelector((state) => state.theme.theme);
  const [ctcInWords, setCtcInwords] = useState("");

  const ctcInputRef = useRef();

  const professionalTax = [
    {
      name: "Professional Tax",
      monthly: null,
      yearly: null,
      status: true,
      calcType: { type: "User Define" },
      employeeContributionRate: { label: "User Define" },
      paySlipName: "Professional Tax",
    },
  ];

  const handleSave = async () => {
    try {
      console.log(deduction);
      const isESIApplied = deduction?.filter((elm) => elm.label == "esiInfo");
      console.log(isESIApplied, monthlyTotal);

      if (monthlyTotal > 21000 && isESIApplied.length > 0) {
        return customToast.error(
          "Unable to create Template due to ESI.\nESI is only applicable on the monthaly salary upto 21,000",
        );
      }
      const requiredEarnings = ["Basic", "HRA", "Fixed Allowance"];
      const hasAllRequiredEarnings = requiredEarnings.every((earningName) =>
        earnings.some((earning) => earning?.earningType?.name === earningName),
      );

      if (!hasAllRequiredEarnings) {
        customToast.error(
          "Please ensure Basic, HRA, and Fixed Allowance earnings are present",
        );
        return;
      }
      if (!tempName && !description && !ctc) {
        customToast.error("PLease fill all fields");
        return;
      } else {
        const payload = {
          tempName,
          description,
          earnings,
          deductions,
          reimbursements,
          monthlyGrossSalary: monthlyTotal,
          grossSalary: total,
          status,
          ctc,
          earningTotal,
          earningTotalMonthly,
          deductionTotal,
          deductionTotalMonthly,
          reimbTotal,
          reimbTotalMonthly,
        };
        const response = await CreateTempelate(payload);
        if (response) {
          navigate(-1);
          return customToast.success("Tempelate Created Successfully");
        } else {
          return customToast.error("Something went Wrong");
        }
      }
    } catch (error) {
      return customToast.error("Something went Wrong");
    }
  };

  useEffect(() => {
    GetEarnings()
      .then(({ data }) => setEarnData(data))
      .catch((err) => customToast.error("Sorry Something Went Wrong!"));
    GetAllDeduction()
      .then(({ data }) => setDeductData(data))
      .catch((err) => customToast.error("Sorry Something Went Wrong!"));
    getReimbursements()
      .then((data) => setReimbData(data))
      .catch((err) => customToast.error("An Error Occured"));
  }, []);

  useEffect(() => {
    dispatch(fetchEpfDetails());
    dispatch(fetchEsiDetails());
    dispatch(fetchProfessionalTaxDeails());
  }, [dispatch]);

  useEffect(() => {
    let words = convertNumberToWords(ctc);
    setCtcInwords(words);
  }, [ctc]);

  const earnSelect = useMemo(
    () => generateSelectDataForEarnings_SalaryTemp(earnData, "eName"),
    [earnData],
  );
  const deductSelect = useMemo(
    () => generateSelectDataForSalaryTemp(deductData, "nameOnPaySlip"),
    [deductData],
  );
  const epfSelect = useMemo(
    () => generateSelectDataForSalaryTemp(epfData, "docName"),
    [epfData],
  );
  const esiSelect = useMemo(
    () => generateSelectDataForSalaryTemp(esiData, "docName"),
    [esiData],
  );
  const reimburseSelect = useMemo(
    () => generateSelectDataForSalaryTemp(reimbData, "nameOnPaySlip"),
    [reimbData],
  );
  const ptSelect = useMemo(
    () => generateSelectData(professionalTax, "name"),
    [professionalTax],
  );
  const deductionSelect = [
    ...deductSelect,
    ...epfSelect,
    ...esiSelect,
    ptSelect[0],
  ];
  const [deductionData, setDeductionData] = useState([]);
  const [total, setTotal] = useState(0);
  const [monthlyTotal, setMonthlyTotal] = useState(0);
  const [earning, setEarning] = useState([]);
  const [deduction, setDeduction] = useState([]);

  useEffect(() => {
    if (epfData.length > 0 && esiData.length > 0) {
      const updatedDeduction = [...epfData, ...esiData];
      if (JSON.stringify(updatedDeduction) !== JSON.stringify(deductionData)) {
        setDeductionData(updatedDeduction);
      }
    }
  }, [epfData, esiData, deductionData]);

  useEffect(() => {
    if (ctcStatus) {
      const updatedEarnings = earning.map((el) => {
        if (
          el?.earningType.name === "Basic" ||
          el?.earningType.name === "HRA"
        ) {
          let monthly, yearly;
          if (el.earningType.name === "Basic") {
            if (el.calcType.type === "percentage") {
              let p = getPercentageSalary(ctc, el.amount);
              yearly = Number(p);
              setBasicAmount(yearly);
            } else {
              yearly = el.amount * 12;
              setBasicAmount(yearly);
            }
            monthly = (yearly / 12).toFixed(2);
            return { ...el, monthly, yearly };
          } else if (el.earningType.name === "HRA") {
            if (el.calcType.type === "percentage") {
              let p = getPercentageSalary(basicAmount, el.amount);
              yearly = Number(p);
            } else {
              yearly = el.amount * 12;
            }
            monthly = Number((yearly / 12).toFixed(2));
            return { ...el, monthly, yearly };
          }
        } else if (el.calcType.type === "percentage") {
          let monthly, yearly;
          if (el.calcType.value === "basic") {
            let p = getPercentageSalary(basicAmount, el.amount);
            yearly = Number(p);
          } else {
            let p = getPercentageSalary(ctc, el.amount);
            yearly = Number(p);
          }
          monthly = Number((yearly / 12).toFixed(2));
          return { ...el, monthly, yearly };
        } else if (el?.earningType.name === "Fixed Allowance") {
          return { ...el, yearly: 0 };
        } else if (el.calcType.type === "percentage") {
          let monthly, yearly;
          if (el.calcType.basis === "ctc") {
            let p = getPercentageSalary(ctc, el.amount);
            yearly = Number(p);
          } else if (el.calcType.basis === "basic") {
            let p = getPercentageSalary(basicAmount, el.amount);
            yearly = Number(p);
          }
          monthly = Number((yearly / 12).toFixed(2));
          return { ...el, monthly, yearly };
        } else {
          let monthly, yearly;
          yearly = Number((el.amount * 12).toFixed(2));
          monthly = Number((yearly / 12).toFixed(2));
          return { ...el, monthly, yearly };
        }
      });
      setEarnings(updatedEarnings);
    }
  }, [ctc, basicAmount, ctcStatus, earning]);

  useEffect(() => {
    if (ctcStatus) {
      let totalyearly = 0;
      let totalmonthly = 0;
      earnings.forEach((el) => {
        totalyearly += Number(el.yearly);
        totalmonthly += Number(el.monthly);
      });
      setEarningTotal(totalyearly);
      setEarningTotalMonthly(totalmonthly);
    }
  }, [earnings, ctcStatus]);

  const handleAddEarning = useCallback(
    (e) => {
      if (earnings.filter((el, idx) => el?._id === e?._id).length > 0)
        return customToast.error("Earning Already exists");
      if (!ctcStatus) {
        return customToast.error("!Please Enter CTC First Add");
      }
      if (
        e?.earningType.name == "Basic" ||
        e?.earningType.name == "HRA" ||
        e?.earningType.name == "Fixed Allowance"
      ) {
        const earn = earning?.filter(
          (el) => el?.earningType?.name == e?.earningType?.name,
        );
        if (earn.length > 0) {
          return customToast.error(
            `${earn[0]?.earningType?.name} is already exist remove it first`,
          );
        }
      }
      if (e?.earningType.name === "Fixed Allowance") {
        e.monthly = 0;
        e.yearly = Number(Number(12 * e.monthly)).toFixed(2);
      } else {
        e.monthly = 0;
        e.yearly = Number(Number(12 * e.monthly)).toFixed(2);
      }
      setEarning([...earning, { ...e }]);
    },
    [earning, ctc, ctcStatus],
  );

  const handleRemoveEarning = (id) => {
    const updatedEarnings = earning.filter((el) => el._id !== id);
    setEarning(updatedEarnings);
  };

  useEffect(() => {
    if (ctcStatus) {
      if (deduction) {
        const updatedDeduction = deduction.map((el) => {
          if (
            el.docName === "epfInfo" &&
            el.subtitle === "Employer Contribution"
          ) {
            el.yearly = getPercentageSalary(
              basicAmount,
              el.employerContributionRate?.value,
            ).toFixed(2);
            el.monthly = Number(Number(Number(el.yearly) / 12).toFixed(2));
          } else if (
            el.docName === "epfInfo" &&
            el.subtitle === "Employee Contribution"
          ) {
            el.yearly = getPercentageSalary(
              basicAmount,
              el.employeeContributionRate?.value,
            ).toFixed(2);
            el.monthly = Number(Number(Number(el.yearly) / 12).toFixed(2));
          } else if (
            el.docName === "esiInfo" &&
            el.subtitle === "Employer Contribution"
          ) {
            let esiTotal = earningTotal + reimbTotal;
            let rate = el.employerContributionRate?.value;
            el.yearly = Number(getPercentageSalary(esiTotal, rate).toFixed(2));
            el.monthly = Number(Number(Number(el.yearly) / 12).toFixed(2));
          } else if (
            el.docName === "esiInfo" &&
            el.subtitle === "Employee Contribution"
          ) {
            let esiTotal = earningTotal + reimbTotal;
            let rate = el.employeeContributionRate?.value;
            el.yearly = Number(getPercentageSalary(esiTotal, rate).toFixed(2));
            el.monthly = Number(Number(Number(el.yearly) / 12).toFixed(2));
          }
          return el;
        });
        setDeductions(updatedDeduction);
      }
    }
  }, [ctc, basicAmount, deduction, ctcStatus, earningTotal, reimbTotal]);

  const handleAddDeduction = useCallback(
    (e) => {
      if (ctcStatus) {
        if (deduction.some((el) => el?._id === e?._id)) {
          return customToast.error("Deduction Already exists");
        } else {
          if (e.employeeContributionRate && e.employerContributionRate) {
            if (e.docName == "epfInfo") {
              const employeeContribution = {
                ...e,
                name: `${(e.name = "EPF Contribution")}`,
                subtitle: "Employee Contribution",
                yearly: 0,
                monthly: 0,
              };
              const employerContribution = {
                ...e,
                name: `${(e.name = "EPF Contribution")}`,
                subtitle: "Employer Contribution",
                yearly: 0,
                monthly: 0,
              };
              setDeduction([
                ...deduction,
                employeeContribution,
                employerContribution,
              ]);
            } else if ((e.docName = "esiInfo")) {
              const employeeContribution = {
                ...e,
                name: `${(e.name = "ESI Contribution")}`,
                subtitle: "Employee Contribution",
                yearly: 0,
                monthly: 0,
              };
              const employerContribution = {
                ...e,
                name: `${(e.name = "ESI Contribution")}`,
                subtitle: "Employer Contribution",
                yearly: 0,
                monthly: 0,
              };
              setDeduction([
                ...deduction,
                employeeContribution,
                employerContribution,
              ]);
            }
          } else {
            let updatedDeduction;
            if (e.employeeContributionRate?.label !== "User Define") {
              let { docName, name } = e;
              let monthly = 0;
              let yearly = 0;
              if (docName === "epfInfo" && e.employerContributionRate) {
                name = "EPF Contribution";
                e.subtitle = "employer contribution";
              } else if (docName === "epfInfo" && e.employeeContributionRate) {
                name = "EPF Contribution";
                e.subtitle = "employee contribution";
              } else if (docName === "esiInfo" && e.employerContributionRate) {
                name = "ESI Contribution";
                e.subtitle = "employer contribution";
              } else if (docName === "esiInfo" && e.employeeContributionRate) {
                name = "ESI Contribution";
                e.subtitle = "employee contribution";
              }
              updatedDeduction = { ...e, yearly, monthly, name };
              setDeduction([...deduction, updatedDeduction]);
            } else {
              updatedDeduction = { ...e, yearly: 0, monthly: 0 };
              setDeduction([...deduction, updatedDeduction]);
            }
          }
        }
      }
    },
    [deduction, ctcStatus],
  );

  const handleRemoveDeduction = (id) => {
    const updatedDeduction = deduction.filter((el) => el._id !== id);
    setDeduction(updatedDeduction);
  };

  const handleDeductionChange = (id, value, type) => {
    const updatedDeductions = deductions.map((el) => {
      if (el._id === id) {
        if (type === "monthly") {
          const monthly = value;
          const yearly = value * 12;
          return { ...el, monthly, yearly };
        } else if (type === "yearly") {
          const yearly = value;
          const monthly = value / 12;
          return { ...el, monthly, yearly };
        }
      }
      return el;
    });
    setDeductions(updatedDeductions);
  };

  const calculateTotalDeduction = useCallback(() => {
    let totalyearly = 0;
    let totalmonthly = 0;
    deductions.forEach((el) => {
      totalyearly = totalyearly + Number(el.yearly);
      totalmonthly = totalmonthly + Number(el.monthly);
    });
    setDeductionTotal(totalyearly);
    setDeductionTotalMonthly(totalmonthly);
  }, [deductions, ctc]);

  useEffect(() => {
    calculateTotalDeduction();
  }, [deductions, ctc, calculateTotalDeduction]);

  useEffect(() => {
    const updatedReimbursment = reimbursement.map((el) => {
      let monthly, yearly;
      monthly = el.limitPerMonth;
      yearly = 12 * monthly;
      return { ...el, monthly, yearly };
    });
    setReimbursements(updatedReimbursment);
  }, [reimbData, ctc, reimbursement]);

  const handleAddReimburse = (e) => {
    if (!ctcStatus) {
      return customToast.error("!Please first enter CTC");
    }
    if (reimbursement.some((el) => el?._id === e?._id)) {
      customToast.error("Reimbursement Already exists");
    } else {
      const updatedReimbursment = [
        ...reimbursement,
        { ...e, monthly: 0, yearly: 0 },
      ];
      setReimbursement(updatedReimbursment);
    }
  };

  const handleRemoveReimbursement = (id) => {
    const updatedReimbursment = reimbursements.filter((el) => el._id !== id);
    setReimbursement(updatedReimbursment);
  };

  const calculateTotalReimbursement = useCallback(() => {
    let totalyearly = 0;
    let totalmonthly = 0;
    reimbursements.forEach((el) => {
      totalyearly = totalyearly + Number(el.yearly);
      totalmonthly = totalmonthly + Number(el.monthly);
    });
    setReimbTotal(totalyearly);
    setReimbTotalMonthly(totalmonthly);
  }, [reimbursements, ctc]);

  useEffect(() => {
    calculateTotalReimbursement();
  }, [reimbursements, ctc, calculateTotalReimbursement]);

  useEffect(() => {
    let t = (earningTotal + deductionTotal + reimbTotal).toFixed(2);
    setTotal(t);
    let m = (t / 12).toFixed(2);
    setMonthlyTotal(m);
  }, [reimbTotal, earningTotal, deductionTotal]);

  const handleCtcStatus = (e) => {
    if (e.key === "Enter") {
      ctcInputRef.current.blur();
      setCtcStatus(true);
      console.log(ctcStatus);
    } else {
      setCtcStatus(false);
    }
  };
  console.log(reimbData, reimbursement, reimbursements, "allreimb");
  return (
    <main className="w-full mt-8 pr-8 dark:text-wheat pb-24">
      <div className="dark:bg-primaryDark2 bg-primaryLight2">
        <PageTitle title="Add Salary Template" route={"../salary"} />
      </div>
      {/* <section className="bg-black p-12 py-8 mt-4"> */}
      <div className="grid grid-rows-1 grid-flow-col">
        <div className="mt-6  w-96">
          <label className="text-2x2 uppercase ml-1 font-semibold">
            Salary Template Name<span className="text-error">*</span>
          </label>
          <br />
          <input
            id="eName"
            type="text"
            placeholder="Enter Name"
            className="dark:bg-primary dark:text-secondary outline-none border-none px-6 py-3 rounded-lg font-normal mt-3"
            value={tempName}
            onChange={({ target }) => setTempName(target?.value)}
          />
        </div>
        <div className="mt-6  w-96">
          <label className="text-2x2 uppercase ml-1 font-semibold">
            Description<span className="text-error">*</span>
          </label>
          <br />
          <input
            id="eName"
            type="text"
            placeholder="Enter Description"
            className="dark:bg-primary dark:text-secondary outline-none border-none px-6 py-3 rounded-lg font-semibold mt-3"
            onChange={({ target }) => setDescription(target?.value)}
          />
        </div>
      </div>
      <div className="mt-12 text-2x2">
        <span className="font-semibold">
          Annual CTC <span className="text-error">*</span>
        </span>
        <input
          ref={ctcInputRef}
          id="eName"
          type="number"
          step="1"
          placeholder="Enter CTC"
          className={`dark:bg-primary dark:text-secondary outline-none border-2 px-6 py-3 rounded-lg font-semibold mt-3 mx-5 ${
            ctcStatus ? "border-green-500" : "border-red-500"
          }`}
          value={ctc}
          onChange={({ target }) => setCtc(target?.value)}
          onKeyDown={(e) => handleCtcStatus(e)}
        />
        <span className="font-semibold">per year</span>
      </div>
      <br />
      <p className="font-semibold"> {ctc ? ctcInWords : null} </p>
      <br />

      {/* </section> */}
      <div className="max-h-auto">
        <p className="text-xl font-medium text-gray-500">
          Please Input The Value And Press{" "}
          <span className="font-bold text-red-600">Enter</span> to Proceed
          Further
        </p>
        <table className="w-full">
          <thead className="uppercase dark:text-secondary dark:bg-primaryDark2 w-full text-center bg-white">
            <th className="border-2 border-collapse  border-primaryLight3 dark:border-primaryDark dark:text-secondary font-semibold px-6 py-4">
              Salary Components
            </th>
            <th className="border-2 border-collapse  border-primaryLight3 dark:border-primaryDark dark:text-secondary font-semibold px-6 py-4">
              Calculation Type
            </th>
            <th className="border-2 border-collapse  border-primaryLight3 dark:border-primaryDark dark:text-secondary font-semibold px-6 py-4">
              Total Monthly
            </th>
            <th className="border-2 border-collapse  border-primaryLight3 dark:border-primaryDark dark:text-secondary font-semibold px-6 py-4">
              Total Annually
            </th>
            <th className="border-2 border-collapse  border-primaryLight3 dark:border-primaryDark dark:text-secondary font-semibold px-6 py-4"></th>
          </thead>
          <tbody>
            <tr className="uppercase text-2x2 text-white">
              <td className="px-3 py-8 font-semibold">Earnings</td>
            </tr>
            {earnings?.map((el, idx) => {
              return el?.earningType.name !== "Fixed Allowance" ? (
                <tr
                  key={idx}
                  className="dark:bg-primary bg-white text-center font-semibold"
                >
                  <td className="border-2 border-collapse  border-primaryLight3 dark:border-primaryDark dark:text-wheat text-black px-6 py-6 w-1/5">
                    {el?.earningType?.name == "Basic" ||
                    el?.earningType?.name == "HRA" ||
                    el?.earningType.name == "Fixed Allowance" ? (
                      <span>{el?.eName}</span>
                    ) : (
                      <span>
                        <span>{el?.eName}</span>
                      </span>
                    )}
                  </td>
                  <td className="border-2 border-collapse  border-primaryLight3 dark:border-primaryDark dark:text-wheat text-black px-6 py-6 w-100 flex justify-center">
                    {el.calcType.type === "percentage" ? (
                      <>
                        <span className="dark:bg-primaryDark1 p-4 px-14">
                          <input
                            type="number"
                            placeholder=""
                            className="dark:bg-primaryDark1 dark:text-secondary outline-none border-none rounded-lg font-semibold w-16 p-4 "
                            value={el?.amount}
                            disabled
                            // onChange={(e) => handleEarningAmountChange(el._id, e.target.value)}
                          />
                        </span>{" "}
                        <span className="p-4 dark:bg-tertiary flex items-center">
                          % of {el?.calcType?.value}
                        </span>
                      </>
                    ) : (
                      <span className="p-4 flex items-center">{`${el?.calcType.type}`}</span>
                    )}
                  </td>
                  <td className="border-2 border-collapse  border-primaryLight3  dark:border-primaryDark dark:text-wheat text-black px-6 py-6 w-1/5">
                    {(el?.earningType?.name === "Basic" ||
                      el?.earningType?.name === "HRA") && (
                      <span>{el.monthly}</span>
                    )}
                    {el?.earningType?.name !== "Basic" &&
                      el?.earningType?.name !== "HRA" &&
                      el?.earningType?.name === "Fixed Allowance" && (
                        <>{"System Generated"}</>
                      )}
                    {el?.earningType?.name !== "Basic" &&
                      el?.earningType?.name !== "HRA" &&
                      el?.earningType?.name !== "Fixed Allowance" && (
                        <input
                          type="number"
                          placeholder=""
                          className="dark:bg-primaryDark1 dark:text-secondary outline-none border-none rounded-lg font-semibold w-16 w-full flex h-16 text-center"
                          value={el.monthly}
                          max={el.monthly}
                          //  onChange={(e) => handleEarningAmount(el._id, e.target.value)}
                          disabled
                        />
                      )}
                  </td>
                  <td className="border-2 border-collapse  border-primaryLight3  dark:border-primaryDark dark:text-wheat text-black px-6 py-6 w-1/5">
                    {(el?.earningType?.name === "Basic" ||
                      el?.earningType?.name === "HRA") && (
                      <span>{el.yearly}</span>
                    )}
                    {el?.earningType?.name !== "Basic" &&
                      el?.earningType?.name !== "HRA" &&
                      el?.earningType?.name === "Fixed Allowance" && (
                        <>{"System Generated"}</>
                      )}
                    {el?.earningType?.name !== "Basic" &&
                      el?.earningType?.name !== "HRA" &&
                      el?.earningType?.name !== "Fixed Allowance" && (
                        <span>{el.yearly}</span>
                      )}
                  </td>
                  <td className="border-2 border-collapse  border-primaryLight3  dark:border-primaryDark dark:text-wheat text-black px-6 py-6 w-1/12 ">
                    <CiCircleMinus
                      className="text-tertiary text-5xl m-auto cursor-pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        handleRemoveEarning(el._id);
                        setEditId(el._id);
                      }}
                    />
                  </td>
                </tr>
              ) : null;
            })}
            {earnings?.map((el, idx) => {
              return el?.earningType.name == "Fixed Allowance" ? (
                <tr key={idx} className="dark:bg-primary bg-white text-center">
                  <td className="border-2 border-collapse  border-primaryLight3 dark:border-primaryDark dark:text-wheat text-black px-6 py-6 w-1/5">
                    {el?.earningType.name == "Fixed Allowance" ? (
                      <span>{el?.eName}</span>
                    ) : (
                      <span>
                        <span>{el?.eName}</span>
                      </span>
                    )}
                  </td>
                  <td className="border-2 border-collapse  border-primaryLight3 dark:border-primaryDark dark:text-wheat text-black px-6 py-6 w-1/5 ">
                    <span>{`Fixed`}</span>
                  </td>
                  <td className="border-2 border-collapse  border-primaryLight3  dark:border-primaryDark dark:text-wheat text-black px-6 py-6 w-1/5">
                    {el?.earningType?.name !== "Basic" &&
                      el?.earningType?.name !== "HRA" &&
                      el?.earningType?.name === "Fixed Allowance" && (
                        <>{"System Generated"}</>
                      )}
                  </td>
                  <td className="border-2 border-collapse  border-primaryLight3  dark:border-primaryDark dark:text-wheat text-black px-6 py-6 w-1/5">
                    {el?.earningType?.name !== "Basic" &&
                      el?.earningType?.name !== "HRA" &&
                      el?.earningType?.name === "Fixed Allowance" && (
                        <>{"System Generated"}</>
                      )}
                  </td>
                  <td className="border-2 border-collapse  border-primaryLight3  dark:border-primaryDark dark:text-wheat text-black px-6 py-6 w-1/12 ">
                    <CiCircleMinus
                      className="text-tertiary text-5xl m-auto cursor-pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        handleRemoveEarning(el._id);
                        setEditId(el._id);
                      }}
                    />
                  </td>
                </tr>
              ) : null;
            })}
          </tbody>
        </table>
      </div>
      <div className="w-96">
        <br />
        <Select
          options={earnSelect}
          styles={adminStyles}
          value={"soem"}
          onChange={handleAddEarning}
          className="mt-2"
          placeholder="ADD NEW EARNING"
        />
      </div>

      <div className="text-2x2 uppercase ml-1 my-8 font-semibold">
        Deductions
      </div>
      <div className="max-h-auto mt-8 ">
        <table className="w-full">
          <tbody>
            {deductions?.map((el, idx) => {
              return (
                <tr
                  key={idx}
                  className="dark:bg-primary bg-white text-center font-semibold"
                >
                  <td className="border-2 border-collapse  border-primaryLight3  dark:border-primaryDark dark:text-wheat text-black px-6 py-6 w-1/5">
                    <span>{el?.name}</span>
                    <br />
                    {el?.subtitle ? (
                      <span className="text-base text-tertiary font-bold">
                        ({el?.subtitle})
                      </span>
                    ) : (
                      <span></span>
                    )}
                  </td>
                  {el?.subtitle == "Employee Contribution" ? (
                    <td className="border-2 border-collapse  border-primaryLight3  dark:border-primaryDark dark:text-wheat text-black px-6 py-6 w-1/5">
                      <span>
                        {el.employeeContributionRate?.label
                          ? `${el.employeeContributionRate?.label}`
                          : `${el.deductionFrequency}`}
                      </span>
                    </td>
                  ) : (
                    <td className="border-2 border-collapse  border-primaryLight3  dark:border-primaryDark dark:text-wheat text-black px-6 py-6 w-1/5">
                      <span>
                        {el.employerContributionRate?.label
                          ? `${el.employerContributionRate?.label}`
                          : `${el.deductionFrequency}`}
                      </span>
                    </td>
                  )}
                  {el.employeeContributionRate?.label == "User Define" ? (
                    <>
                      <td className="border-2 border-collapse border-primaryLight3 dark:border-primaryDark dark:text-wheat text-black px-16 py-6 w-1/5">
                        <span>{"--"}</span>
                      </td>
                      <td className="border-2 border-collapse border-primaryLight3 dark:border-primaryDark dark:text-wheat text-black px-16 py-6 w-1/5">
                        <span>{"--"}</span>
                      </td>
                    </>
                  ) : (
                    <>
                      <td className="border-2 border-collapse border-primaryLight3 dark:border-primaryDark dark:text-wheat text-black px-16 py-6 w-1/5">
                        {el.docName ? (
                          <span>{el?.monthly}</span>
                        ) : (
                          <input
                            type="number"
                            placeholder=""
                            className="dark:bg-primaryDark1 dark:text-secondary outline-none border-none rounded-lg font-semibold w-16 w-full flex h-16 text-center"
                            value={el.monthly}
                            disabled
                          />
                        )}
                      </td>
                      <td className="border-2 border-collapse border-primaryLight3 dark:border-primaryDark dark:text-wheat text-black px-16 py-6 w-1/5">
                        {el.docName ? (
                          <span>{el?.yearly}</span>
                        ) : (
                          <input
                            type="number"
                            placeholder=""
                            className="dark:bg-primaryDark1 dark:text-secondary outline-none border-none rounded-lg font-semibold w-16 w-16 w-full flex h-16 text-center"
                            value={el.yearly}
                            disabled
                          />
                        )}
                      </td>{" "}
                    </>
                  )}
                  <td className="border-2 border-collapse  border-primaryLight3 dark:border-primaryDark dark:text-wheat text-black px-6 py-6 w-1/12">
                    <CiCircleMinus
                      className="text-tertiary text-5xl m-auto cursor-pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        handleRemoveDeduction(el._id);
                        setEditId(el._id);
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="w-96">
        <br />
        <Select
          options={deductionSelect}
          styles={adminStyles}
          value={"soem"}
          onChange={handleAddDeduction}
          className="mt-2"
          placeholder="ADD NEW DEDUCTION"
        />
      </div>

      <div className="text-2x2 uppercase ml-1 my-8 font-semibold">
        reimbursement
      </div>
      <div className="max-h-auto mt-8 overflow-y-scroll ">
        <table className="w-full">
          <tbody>
            {reimbursements?.map((el, idx) => {
              return (
                <tr
                  key={idx}
                  className="dark:bg-primary bg-white text-center font-semibold"
                >
                  <td className="border-2 border-collapse border-primaryLight3 dark:border-primaryDark border-primaryDark dark:text-wheat text-black px-6 py-6 w-1/5">
                    <span>
                      <span>{el?.nameOnPaySlip}</span>
                    </span>
                  </td>
                  <td className="border-2 border-collapse border-primaryLight3 dark:border-primaryDark border-primaryDark dark:text-wheat text-black px-6 py-6 w-1/5">
                    <span>{"Fixed Amount"}</span>
                  </td>
                  <td className="border-2 border-collapse  border-primaryLight3  dark:border-primaryDark dark:text-wheat text-black px-6 py-6 w-1/5">
                    <input
                      type="number"
                      placeholder=""
                      className="dark:bg-primaryDark1 dark:text-secondary outline-none border-none rounded-lg font-semibold w-16 w-full flex h-16 text-center"
                      value={el.monthly}
                      max={el.monthly}
                      disabled
                    />
                  </td>
                  <td className="border-2 border-collapse  border-primaryLight3 dark:border-primaryDark dark:text-wheat text-black px-6 py-6 w-1/5">
                    {el?.yearly}
                  </td>
                  <td className="border-2 border-collapse  border-primaryLight3 dark:border-primaryDark dark:text-wheat text-black px-6 py-6 w-1/12">
                    <CiCircleMinus
                      className="text-tertiary text-5xl m-auto cursor-pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        setEditing(true);
                        handleRemoveReimbursement(el._id);
                        setEditId(el._id);
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="w-fit">
        <br />
        <Select
          options={reimburseSelect}
          styles={adminStyles}
          value={"soem"}
          onChange={handleAddReimburse}
          className="mt-2"
          placeholder="ADD NEW REIMBURSEMENT"
        />
      </div>

      <div className="max-h-[400px] mt-8 overflow-y-scroll ">
        <table className="w-full">
          <tbody>
            <tr className="dark:bg-primary bg-white text-center font-semibold">
              <td className="border-2 border-collapse  border-primaryLight3 dark:border-primaryDark text-wheat px-6 py-6 w-1/5"></td>
              <td className="border-2 border-collapse  border-primaryLight3 dark:border-primaryDark text-wheat px-6 py-6 w-1/5">
                <span className="text-white">{"Cost To Company"}</span>
              </td>
              <td className="border-2 border-collapse  border-primaryLight3 dark:border-primaryDark  text-white px-6 py-6 w-1/5">{`${(
                ctc / 12
              ).toFixed(2)}`}</td>
              <td className="border-2 border-collapse border-primaryLight3  dark:border-primaryDark  text-white px-6 py-6 w-1/5">{`${ctc}.00`}</td>
              <td className="border-2 border-collapse  border-primaryLight3 dark:border-primaryDark  text-tertiary px-6 py-6 w-1/12"></td>
            </tr>
          </tbody>
        </table>
      </div>
      <button
        className="button_blue_gradient mt-20"
        onClick={() => handleSave()}
      >
        Save
      </button>
    </main>
  );
};

export default AddSalaryTemplates;
