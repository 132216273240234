import { createSlice } from "@reduxjs/toolkit";
import {
  getAllDivisions,
  getAllProcesses,
  getAllOnboardingEmployeeTasks
} from "../services/onboarding";
import customToast from '../components/CustomToast'

const onboardDivisionsSlice = createSlice({
  name: "onboardDivisions",
  initialState: {
    data: [],
    processesData: [],
    employeeTasks: []
  },
  reducers: {
    setonboardDivisionsDetails(state, action) {
      state.data = action.payload;
    },
    setonboardProcessesDetails(state, action) {
      state.processesData = action.payload;
    },
    setonboardEmployeeTasksDetails(state, action) {
      state.employeeTasks = action.payload;
    },
  },
});

export const { setonboardDivisionsDetails, setonboardProcessesDetails, setonboardEmployeeTasksDetails } = onboardDivisionsSlice.actions;

export const fetchAllOnboardDivisions = () => async (dispatch) => {
  try {
    const response = await getAllDivisions();
    let res = JSON.parse(response.data);
    dispatch(setonboardDivisionsDetails(res));
  } catch (error) {
    customToast.error("Something went wrong !")
  }
};

export const fetchAllOnboardProcesses = () => async (dispatch) => {
  try {
    const response = await getAllProcesses();
    dispatch(setonboardProcessesDetails(response.data));
  } catch (error) {
    customToast.error("Something went wrong !")
  }
};

export const fetchAllOnboardingEmployeeTasks = () => async (dispatch) => {
  try {
    const response = await getAllOnboardingEmployeeTasks();
    dispatch(setonboardEmployeeTasksDetails(response.data));
  } catch (error) {
    customToast.error("Something went wrong !")
  }
};

export default onboardDivisionsSlice.reducer;
