import React from "react";
import CardLink from "../../../components/CardLink";
import { useSelector } from "react-redux";

const PayRunHome = () => {
  const theme = useSelector((state) => state.theme.theme);
  return (
    <div className="main-content bg-primaryLight2 dark:bg-primaryDark1">
      <div className="admin-panel_content">
        <div className="cardlink-row">
          <div className="admin-panel__box-container flex flex-wrap gap-8 flex-row">
            <CardLink
              className={"admin-panel__cards"}
              title="Manage Payrun"
              type="ManagePayrun"
              link="manage-payrun/payrun-info"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayRunHome;
