import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { GetEarnings } from "../../../services/earning";
import customToast from "../../../components/CustomToast";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdDelete, MdEdit } from "react-icons/md";
import { CiCircleMinus } from "react-icons/ci";
import PageTitle from "../../../components/PageTitle";
import Select from "react-select";
import { generateSelectData } from "../../../utils/serialiseResponse";
import { getPercentageSalary } from "../../../utils/helper";
import { GetAllDeduction } from "../../../services/deduction";
import { getReimbursements } from "../../../services/reimbursement";
import { fetchEpfDetails } from "../../../reducers/epfDetailsReducer";
import { fetchEsiDetails } from "../../../reducers/esiDetailsReducer";
import { fetchProfessionalTaxDeails } from "../../../reducers/professionalTaxDeailsReducer";
import { customAxios } from "../../../utils/axiosinstance";
import { CreateTempelate } from "../../../services/salaryTempelates";

const adminStyles = {
  control: (provided, state) => ({
    ...provided,
    padding: "0.2rem 1rem",
    backgroundColor: "#37AAF8",
    color: "#FFFFF",
    cursor:"pointer",
    border: "5px",
    borderRadius: "50px",
    boxShadow: "none",
    minHeight: "3.6rem",
    width: "100%",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    color: "#9E9AD1",

    "&:hover": {
      backgroundColor: "#393b5f",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#9e9ad0",
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    width: "80%",
  }),
  input: (provided, state) => ({
    ...provided,
    color: "#fff",
    border:"none",
    width:"100%"
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#C7C4E9",
    display:"none",
    "&:hover": {
      color: "#9E9AD1",
    },
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#FFFFF",
  }),
  indicatorSeparator: () => null,
};

const SalaryRevisionDetails = () => {

  const location = useLocation();
  const [earnData, setEarnData] = useState([]);
  const [deductData, setDeductData] = useState([]);
  const [reimbData , setReimbData] = useState([]);
  const [editing, setEditing] = useState(false);
  const [ctc , setCtc] = useState(0);
  const [tempName, setTempName] = useState("");
  const [description , setDescription] = useState("");
  const [earnings, setEarnings] = useState([]);
  const [deductions, setDeductions] = useState([]);
  const [reimbursements, setReimbursements] = useState([]);
  const [editId , setEditId] = useState("");
  const [basicCount , setBasicCount] = useState(0);
  const [hraCount , setHraCount] = useState(0);
  const [basicAmount , setBasicAmount] = useState(0);
  const [earningTotal , setEarningTotal] = useState(0);
  const [earningTotalMonthly , setEarningTotalMonthly] = useState(0);
  const [deductionTotal , setDeductionTotal] = useState(0);
  const [deductionTotalMonthly , setDeductionTotalMonthly] = useState(0);
  const [status , setStatus] = useState(true);
  const dispatch = useDispatch();
  const epfData = useSelector((state) => [state.epfInfo.data]);
  const esiData = useSelector((state) => [state.esiInfo.data]);
  const ptData = useSelector((state) => state.professionalTaxDeails.data);
  const [ reimbTotal ,setReimbTotal] = useState(0);
  const [ reimbTotalMonthly , setReimbTotalMonthly] = useState(0);
  

  const handleSave = async() => {
    try {
      if(!tempName && !description && !ctc){
        customToast.error("PLease fill all fields")
      }
      else{
        const payload = {
         tempName,
         description,
         earnings,
         deductions,
         reimbursements,
         grossSalary:total,
         status,
     }
     
     const response = await CreateTempelate(payload);
     if(response){
       customToast.success("Tempelate Created Successfully")
     }}
    } catch (error) {
      customToast.error("Something went Wrong")  
  }}

  useEffect(()=>{
    GetEarnings().then(({data})=>setEarnData(data)).
    catch((err)=> customToast.error("Sorry Something Went Wrong!"))
    GetAllDeduction().then(({data})=>setDeductData(data)).
    catch((err)=> customToast.error("Sorry Something Went Wrong!"))
    getReimbursements().then((data)=> setReimbData(data)).
    catch((err)=> customToast.error("An Error Occured"));
  },[])

  useEffect(() => {
    dispatch(fetchEpfDetails());
    dispatch(fetchEsiDetails());
    dispatch(fetchProfessionalTaxDeails());
  },[dispatch])
  
  const earnSelect = useMemo(()=> generateSelectData(earnData, "eName"), [earnData]);
  const deductSelect = useMemo(()=> generateSelectData(deductData, "paySlipName"), [deductData]);
  const epfSelect = useMemo(() => generateSelectData(epfData , "docName"), [epfData]);
  const esiSelect = useMemo(() => generateSelectData(esiData , "docName"), [esiData]);
  const reimburseSelect = useMemo(()=> generateSelectData(reimbData, "nameOnPaySlip"), [reimbursements]);
  const ptSelect = useMemo(() => generateSelectData(ptData , "docName") , [ptData]);
  const deductionSelect = [...deductSelect , ...epfSelect , ...esiSelect , ptSelect[0]];
  const [deductionData  , setDeductionData ] = useState([]);
  const [total , setTotal] = useState(0);
  

  useEffect(() => {
    if (epfData.length > 0 && esiData.length > 0) {
      const updatedDeduction = [...epfData, ...esiData];
      if (JSON.stringify(updatedDeduction) !== JSON.stringify(deductionData)) {
        setDeductionData(updatedDeduction);
      }
    }
  }, [epfData, esiData, deductionData]); 
  
  useEffect(() => {
    const updatedEarnings = earnData.map(el => {
        if(el.earningType.name == "Basic"){
            setBasicCount((prevCount) => prevCount + 1);
        }
        if(el.earningType.name == "HRA"){
            setBasicCount((prevCount) => prevCount + 1);
        }
        let monthly, yearly;
        if (el.calcType === 'percentage') {
            const p = getPercentageSalary(ctc, el.amount);
            monthly = p;
        } else {
            monthly = el.amount;
        }
        yearly = 12 * monthly;
        return { ...el, monthly, yearly };
    });
    setEarnings(updatedEarnings);
}, [earnData]);

  useEffect(() => {
    //  if(basicCount > 2){
    //     customToast.error("You Can Add only One Basic Remove Remaining")
    //  }
    //  else if(hraCount > 1){
    //     customToast.error("You Can Add only One HRA Remove Remaining")
    //  }
    //  else{
     const updatedEarnings = earnings.map(el => {     
      if(el.earningType.name == "Basic" || el.earningType.name == "HRA"){
        if(el.earningType.name == "Basic"){
        let monthly, yearly;
        if (el.calcType === 'percentage') {
            let p = getPercentageSalary(ctc, el.amount);
            yearly = p
            setBasicAmount(yearly);
        } else {
             yearly = el.amount * 12;
             setBasicAmount(yearly);
        }
        monthly = (yearly/12).toFixed(2);
        return { ...el, monthly, yearly };}}
        if(el.earningType.name == "HRA"){
            let monthly, yearly;
            if (el.calcType === 'percentage') {
                let p = getPercentageSalary(basicAmount, el.amount);
                yearly = p
            } else {
                 yearly = el.amount * 12;
            }
            monthly = (Number(yearly/12)).toFixed(2);
            return { ...el, monthly, yearly };  
        }
        else{
            let monthly, yearly;
            if (el.calcType === 'percentage') {
                let p = getPercentageSalary(ctc, el.amount);
                yearly = p
            } else {
                 yearly = el.amount * 12;
            }
            monthly = (Number(yearly/12)).toFixed(2);
            return { ...el, monthly, yearly };
        }
    });
    setEarnings(updatedEarnings);
}, [ctc , basicAmount]);


useEffect(() => {
    if (deductions) {
        const updatedDeduction = deductions.map(el => {
            if (el.docName === "epfInfo") {
                    el.yearly = getPercentageSalary(basicAmount, el.employeeContributionRate?.value).toFixed(2);
                    el.monthly = (Number(el.yearly) / 12).toFixed(2);
            } else if (el.docName === "esiInfo") {
                el.yearly = getPercentageSalary(earningTotal, el?.employeeContributionRate?.value).toFixed(2);
                el.monthly = (Number(earningTotal) / 12).toFixed(2);
            }
            return el;
        });
        setDeductions(updatedDeduction);
    }
}, [ctc , basicAmount , deductionData]);
  
const calculateTotalEarning = useCallback(() => {
    let totalyearly = 0;
    let totalmonthly = 0;
    earnings.forEach((el) => {
       totalyearly = totalyearly + el.yearly;
       totalmonthly = totalmonthly + el.monthly;
   })   
     setEarningTotal(totalyearly);
     setEarningTotalMonthly(totalmonthly);
}, [earnings, ctc, basicAmount]); 


useEffect(() => {
    calculateTotalEarning();
}, [earnings, ctc, calculateTotalEarning]);

  const handleAddEarning = useCallback((e)=>{
    if(earnings.filter((el,idx)=> el?._id === e?._id).length > 0)
      return customToast.error("Earning Already exists");
    if(e.calcType === 'percentage'){
      if(ctc){
      const p = getPercentageSalary(ctc, e.amount);
      e.monthly = p ;
      }
    }else {
      e.monthly = e.amount ;
    }
    e.yearly = (12*e.monthly).toFixed(2) ;
    setEarnings([...earnings, {...e}]);
  },[earnings,ctc]);

  const handleRemoveEarning = (id) => {
    const updatedEarnings = earnings.filter(el => el._id !== id);
    setEarnings(updatedEarnings);
 }

 const handleEarningAmountChange = (id, amount) => {
  const updatedEarnings = earnings.map((el) => {
    if (el._id === id) {
      el.amount = amount;
      // Recalculate monthly and yearly earnings based on the new amount
      let monthly, yearly;
      if (el.calcType === "percentage") {
        const p = getPercentageSalary(ctc, amount);
        monthly = p;
      } else {
        monthly = amount;
      }
      yearly = (12 * monthly).toFixed(2);
      el.monthly = monthly;
      el.yearly = yearly;
    }
    return el;
  });
  setEarnings(updatedEarnings);
};
  
useEffect(() => {
    const updatedDeduction = deductionData.map(el => {
        let yearly , monthly , name
        monthly = 0;
        yearly = 0;
        if(el.docName == "epfInfo"){
          name = "EPF Contribution"
        }
        else if(el.docName == "esiInfo"){
          name = "ESI Contribution"
        }
        else{
          name = el.name
        }
        return { ...el , yearly , monthly , name};
    });
    setDeductions(updatedDeduction);
}, [deductionData]);


  const handleAddDeduction = useCallback((e)=>{ 
    if(deductions.filter((el,idx)=> el?._id === e?._id).length > 0)
      return customToast.error("Deduction Already exists");
      setDeductions([...deductions, {...e}]);
  },[deductions,ctc]);

  
  const handleRemoveDeduction = (id) => {
    const updatedDeduction = deductions.filter(el => el._id !== id);
    setDeductions(updatedDeduction);
 }

 const handleDeductionChange = (id, value , type) => {
    const updatedDeductions = deductions.map((el) => {
        if (el._id === id) {
          if(type == "monthly"){
            const yearly = value * 12;
            return { ...el, yearly };
          }
          else if(type == "yearly"){
            const monthly = value / 12;
            return {...el , monthly}
          }
        }
        return el;
    });
    setDeductions(updatedDeductions);
};

  
const calculateTotalDeduction = useCallback(() => {
    let totalyearly = 0;
    let totalmonthly = 0;
    deductions.forEach((el) => {
       totalyearly = totalyearly + Number(el.yearly);
       totalmonthly = totalmonthly + Number(el.monthly);
   })   
     setDeductionTotal(totalyearly);
     setDeductionTotalMonthly(totalmonthly);
}, [deductions, ctc]); 

useEffect(() => {
    calculateTotalDeduction();
}, [deductions, ctc, calculateTotalDeduction]);

 useEffect(() => {
    const updatedReimbursment = reimbData.map(el => {     
       let monthly, yearly;
       monthly = el.limitPerMonth;
       yearly = 12 * monthly;
       return { ...el, monthly, yearly };
     }) 
     setReimbursements(updatedReimbursment)
}, [reimbData]);

  const handleAddReimburse = useCallback((e)=>{
    if(reimbursements.filter((el,idx)=> el?._id === e?._id).length > 0)
      return customToast.error("Reimbursement Already exists");
      setReimbData([...reimbursements , {...e}]) 
      setReimbursements([...reimbursements, {...e}]);
  },[reimbursements,ctc]);

  const handleRemoveReimbursement = (id) => {
    const updatedReimbursment = reimbursements.filter(el => el._id !== id);
    setReimbursements(updatedReimbursment);
 }

 const calculateTotalReimbursement = useCallback(() => {
    let totalyearly = 0;
    let totalmonthly = 0;
    reimbursements.forEach((el) => {
       totalyearly = totalyearly + Number(el.yearly);
       totalmonthly = totalmonthly + Number(el.monthly);
   })   
     setReimbTotal(totalyearly);
     setReimbTotalMonthly(totalmonthly);
}, [reimbursements, ctc]); 

useEffect(() => {
    calculateTotalReimbursement();
}, [reimbursements, ctc, calculateTotalReimbursement]);

useEffect(() => {
    let t = earningTotal + deductionTotal + reimbTotal
   setTotal(t)
}, [reimbTotal , earningTotal , deductionTotal]);


  return (
    <main className="w-full mt-8 pr-8 dark:text-wheat pb-24">
      <div className="dark:bg-primaryDark2 "  >
        <PageTitle title="Salary revision" />
      </div>

     <div className="m-4 text-[#3893D7] font-medium">
        <h3>
        {`Salary Revision Approval for ${location?.state?.firstName} ${location?.state?.lastName}`}
        </h3>
     </div>

     <div className="flex flex-row gap-x-8 m-4">
        <label>
            Current CTC
        </label>
        <label className="text-[#3893D7]">
            1,50,000
        </label>
        <label>
            New CTC
        </label>
        <label className="text-[#3893D7]">
            1,50,000
        </label>
        <label>
            Payment Month
        </label>
     </div>

      <div className="max-h-[400px] mt-20 overflow-y-auto ">
        <table className="w-full">
          <thead className="uppercase dark:text-secondary dark:bg-primaryDark2 w-full text-center">
            <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">Salary Components</th>
            <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">Calculation Type</th>
            <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">Total Monthly</th>
            <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">Total Annually</th>
            {/* <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4"></th> */}
          </thead>
          <tbody>
            <tr className="uppercase text-2x2">
              <td className="px-3 py-8">Earnings</td>

            </tr>
            {earnings?.map((el,idx)=> {
              return (
                <tr key={idx} className="dark:bg-primary text-center">
                  <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6 w-1/5">{el?.eName}</td>
                  <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6 w-1/5 ">
                    {el.calcType === "percentage" ?
                    <>
                    <span className="dark:bg-primaryDark1 p-8 px-14">
                      <input
                        type="number"
                        placeholder= ""
                        className="dark:bg-primaryDark1 dark:text-secondary outline-none border-none rounded-lg font-semibold w-16 p-4 "
                        value={el?.amount}
                        onChange={(e) => handleEarningAmountChange(el._id, e.target.value)}
                      /></span> <span className="p-8 dark:bg-tertiary">%</span></>
                    : <span >{`${el?.calcType}`}</span>}
                    
                  </td>
                  <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6 w-1/5">{el?.monthly}</td>
                  <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6 w-1/5">{el?.yearly}</td>
                  {/* <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6 w-1/12 ">
                    <CiCircleMinus className="text-tertiary text-5xl m-auto cursor-pointer" 
                      onClick={(e)=>{
                        e.preventDefault();
                        handleRemoveEarning(el._id)
                        setEditId(el._id);
                      }}
                    />
                  </td> */}
                </tr>
              )}
            )}
          </tbody>
        </table>
      </div>
      
      <div  className="text-2x2 uppercase ml-1 my-8">Deductions</div>
      <div className="max-h-[400px] mt-8 overflow-y-scroll ">
        <table className="w-full">
          <tbody>
            {deductions?.map((el,idx)=> {
              return (
                <tr key={idx} className="dark:bg-primary text-center">
                  <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6 w-1/5">{el?.name}</td>
                  <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6 w-1/5">
                    <span >{el.employeeContributionRate?.label?`${el.employeeContributionRate?.label}`:`${el.deductionFrequency}` }</span>
                    {/* {
                      <input
                        type="number"
                        placeholder= ""
                        className="dark:bg-primary dark:text-secondary outline-none border-none rounded-lg font-semibold w-16 "
                        
                        onChange={({target})=>{inputRef.current = (target?.value)}}
                      />
                    } */}
                  </td>
                  <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-16 py-6 w-1/5">
                    {el.docName ? <span>{el?.monthly}</span>
                     : 
                      <input
                        type="number"
                        placeholder= ""
                        className="dark:bg-primary dark:text-secondary outline-none border-none rounded-lg font-semibold w-16 "
                        value={el.monthly}
                        onChange={(e) => handleDeductionChange(el._id, e.target.value , "monthly")}
                      />
                    }
                  </td>
                  <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-16 py-6 w-1/5">
                    {el.docName? <span>{el?.yearly}</span>
                     :  <input
                        type="number"
                        placeholder= ""
                        className="dark:bg-primary dark:text-secondary outline-none border-none rounded-lg font-semibold w-16 "
                        value={el.yearly}
                    />
                    } 
                  </td>
                  {/* <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6 w-1/12">
                    <CiCircleMinus className="text-tertiary text-5xl m-auto cursor-pointer" 
                      onClick={(e)=>{
                        e.preventDefault();
                        handleRemoveDeduction(el._id)
                        setEditId(el._id);
                      }}
                    />
                  </td> */}
                </tr>
              )}
            )}
          </tbody>
        </table>
      </div>
      


      <div  className="text-3xl uppercase ml-1 my-8">reminbursement</div>
      <div className="max-h-[400px] mt-8 overflow-y-scroll ">
        <table className="w-full">
          <tbody>
            {reimbursements?.map((el,idx)=> {
              return (
                <tr key={idx} className="dark:bg-primary text-center">
                  <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6 w-1/5">{el?.nameOnPaySlip}</td>
                  <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6 w-1/5">
                    <span >{"Fixed Amount"}</span>
                    {/* {
                      <input
                        type="number"
                        placeholder= ""
                        className="dark:bg-primary dark:text-secondary outline-none border-none rounded-lg font-semibold w-16 "
                        
                        onChange={({target})=>{inputRef.current = (target?.value)}}
                      />
                    } */}
                  </td>
                  <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6 w-1/5">{el?.monthly}</td>
                  <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6 w-1/5">{el?.yearly}</td>
                  {/* <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6 w-1/12">
                    <CiCircleMinus className="text-tertiary text-5xl m-auto cursor-pointer" 
                      onClick={(e)=>{
                        e.preventDefault();
                        setEditing(true);
                        handleRemoveReimbursement(el._id)
                        setEditId(el._id);
                      }}
                    />
                  </td> */}
                </tr>
              )}
            )}
          </tbody>
        </table>
      </div>

      <div className="max-h-[400px] mt-8 overflow-y-scroll ">
        <table className="w-full">
          <tbody>
                <tr className="dark:bg-primary text-center">
                  <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6 w-1/5"></td>
                  <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6 w-1/5">
                    <span >{"Total Gross Salary"}</span>
                    {/* {
                      <input
                        type="number"
                        placeholder= ""
                        className="dark:bg-primary dark:text-secondary outline-none border-none rounded-lg font-semibold w-16 "
                        
                        onChange={({target})=>{inputRef.current = (target?.value)}}
                      />
                    } */}
                  </td>
                  <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6 w-1/5">{((total)/12).toFixed(2)}</td>
                  <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6 w-1/5">{total}</td>
                  {/* <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6 w-1/12">
                  </td> */}
                </tr>
          </tbody>
        </table>
      </div>
      <div className="flex flex-row gap-x-8">
      <button className="button-green-gradient mt-20" onClick={() => handleSave()}>Approve</button>
      <button className="button-red-gradient mt-20" onClick={() => handleSave()}>Reject</button>
      </div>
    </main>
  );
};

export default SalaryRevisionDetails;
