import { customAxios } from "../utils/axiosinstance";

export const createDepartment = async (payload) => {
  const { data } = await customAxios.post(
    `/hrms/meta-data/department/`,
    payload,
  );
  return data;
};

export const getDepartments = async (filter) => {
  const { data } = await customAxios.get("/hrms/meta-data/department/", {
    params: filter,
  });
  return data;
};

export const editDepartment = async (id, payload) => {
  const { data } = await customAxios.put(
    `/hrms/meta-data/department/${id}/`,
    payload,
  );
  return data;
};

export const deleteDepartment = async (id, payload) => {
  const { data } = await customAxios.delete(
    `/hrms/meta-data/department/${id}/`,
    payload,
  );
  return data;
};

// Division
export const createDivision = async (payload) => {
  const { data } = await customAxios.post(`/hrms/meta-data/division`, payload);
  return data;
};

export const getDivision = async () => {
  const { data } = await customAxios.get("/hrms/meta-data/division");
  return data;
};

export const editDivision = async (id, payload) => {
  const { data } = await customAxios.put(
    `/hrms/meta-data/division/${id}/`,
    payload,
  );
  return data;
};

export const deleteDivision = async (id, payload) => {
  const { data } = await customAxios.delete(
    `/hrms/meta-data/division/${id}/`,
    payload,
  );
  return data;
};
