import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentStep } from "../../../reducers/setUpCurrentStepReducer";
import { useNavigate } from "react-router-dom";
import customToast from "../../../components/CustomToast";
import {
  addCompanyWorkingAddress,
  registerCompany,
} from "../../../services/hrms";
import {
  addStateAsync,
  clearStates,
  fetchStates,
} from "../../../reducers/stateReducer";
import { fetchCities } from "../../../reducers/cityReducer";
import Select from "react-select";
import { setUpSelectStyles } from "../../../utils/selectStyles";
import { IoAddOutline } from "react-icons/io5";
import Modal from "../../../components/Modal";
import { generateSelectData } from "../../../utils/serialiseResponse";

const SetUpCompany = () => {
  const CURRENT_STEP = 1;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [companyName, setCompanyName] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [runPayrollEarlier, setRunPayrollEarlier] = useState(false);
  const [addOtherModal, setaddOtherModal] = useState();
  const [stateName, setStateName] = useState(null);
  const [zipCode, setZipCode] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (
        !companyName ||
        !addressLine1 ||
        !city ||
        !state ||
        runPayrollEarlier === null
      ) {
        customToast.error("Please fill all the required fields");
        return;
      }
      const companyDetails = {
        companyName,
        addressLine1,
        addressLine2,
        city,
        state,
        zipCode,
        runPayrollEarlier,
      };
      await registerCompany(companyDetails);
      const payload = {
        officeName: "Headquarter",
        state,
        city,
        addressLine1,
        addressLine2,
        zipCode,
      };

      const response = await addCompanyWorkingAddress(payload);
      customToast.success("Company details saved successfully");
      navigate("/setup/tax-info");
    } catch (error) {
      customToast.error("Something went wrong, please try again later");
    }
  };

  const loggedIn = useSelector(({ user }) => user);
  const statesData = useSelector((state) => state.states.data);
  const cityData = useSelector(({ city }) => city.data);
  const hrmsSubStatus = useSelector((state) => state.hrmsSubscription.status);

  const states = useMemo(
    () => generateSelectData(statesData, "name"),
    [statesData],
  );
  const cities = useMemo(
    () => generateSelectData(cityData, "name"),
    [cityData],
  );

  const handleSkip = (e) => {
    navigate("/setup/tax-info");
  };
  useEffect(() => {
    if (hrmsSubStatus && hrmsSubStatus != "-1") navigate("/dashboard");
  }, []);
  useEffect(() => {
    dispatch(setCurrentStep(CURRENT_STEP));
    dispatch(fetchStates("state"));
    dispatch(fetchCities("", "city"));
    return () => {
      dispatch(clearStates());
    };
    // setCompanyName(loggedIn.user?.subdomain);
    // setAddressLine1(loggedIn.user?.address?.street1);
    // setAddressLine2(loggedIn.user?.address?.street2);
    // setCity(loggedIn.user?.address?.city);
    // setState(loggedIn.user?.address?.state);
  }, [dispatch]);

  let stateNameOptions = [
    { label: "Add Other", value: "Add Other", icon: <IoAddOutline /> },
  ];

  const stateOptions = [...states, ...stateNameOptions];
  // const cityOption = [...cities]
  const handleStateSubmit = async (e) => {
    e.preventDefault();
    if (!stateName) return customToast.error("Please Enter State Name");
    var index = states.findIndex(
      (p) => p.name.toLowerCase() === stateName.toLowerCase(),
    );
    if (index === -1) {
      try {
        await dispatch(addStateAsync({ name: stateName }));
        customToast.success("State Successfully Created");
        setStateName("");
        setaddOtherModal(false);
      } catch (e) {
        customToast.error("We Encountered an Error");
      }
    } else {
      customToast.error("State Already Exists!");
    }
  };

  return (
    <>
      <p>Let's Get Started</p>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="cmp_name">Company Name</label>
          <span className="imp_star">*</span>
          <br />
          <input
            type="text"
            id="cmp_name"
            placeholder="Company Name"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            className="text_input"
          />
        </div>

        <div>
          <label htmlFor="cmp_name">Address</label>
          <span className="imp_star">*</span>
          <br />
          <input
            type="text"
            placeholder="Address Line 1"
            value={addressLine1}
            onChange={(e) => setAddressLine1(e.target.value)}
            className="text_input"
          />
          <input
            type="text"
            placeholder="Address Line 2"
            value={addressLine2}
            onChange={(e) => setAddressLine2(e.target.value)}
            className="text_input"
          />
          <div className="two_in_one">
            <Select
              id="state_name"
              placeholder="State"
              options={stateOptions}
              onChange={(e) => {
                if (e.value === "Add Other") {
                  setaddOtherModal(true);
                } else {
                  setState(e);
                }
              }}
              styles={setUpSelectStyles}
              className="w-full"
            />
          </div>
        </div>

        <Modal
          showModal={addOtherModal}
          closeModal={() => setaddOtherModal(false)}
          title="Add State"
          width="w-[300px]"
          content={
            <div>
              <form onSubmit={(e) => handleStateSubmit(e)}>
                <div className="flex flex-col gap-10">
                  <div className="flex flex-col gap-2">
                    <label htmlFor="loan_name">Enter State Name</label>
                    <input
                      id="state_name"
                      type="text"
                      placeholder="Enter Name"
                      className="bg-[#2B2C47] px-6 py-4 outline-none"
                      onChange={(e) => setStateName(e.target.value)}
                    />
                  </div>

                  <div className="flex items-center gap-10">
                    <button
                      className="px-8 py-2 w-max text-white bg-tertiary rounded-xl cursor-pointer"
                      value="Save"
                      onClick={(e) => handleStateSubmit(e)}
                    >
                      Submit
                    </button>
                    <span className="text-cyan cursor-pointer">Cancel</span>
                  </div>
                </div>
              </form>
            </div>
          }
        />

        <div className="two_in_one">
          {/*<input
            type="text"
            placeholder="City"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            className="text_input"
          />*/}
          <Select
            id="city_name"
            placeholder="City"
            options={cities}
            onChange={(e) => setCity(e)}
            styles={setUpSelectStyles}
            className="w-full"
          />
          <input
            type="text"
            placeholder="Zip Code"
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
            className="text_input"
          />
        </div>

        <div className="terms">
          <p>Have you run payroll earlier this year? </p>
          <div>
            <input
              id="op-1"
              type="checkbox"
              value={runPayrollEarlier}
              checked={runPayrollEarlier == true ? true : false}
              onChange={() => setRunPayrollEarlier(true)}
              className="checkbox_input"
            />
            <label
              className="!m-0 !font-normal !text-lg cursor-pointer"
              htmlFor="op-1"
            >
              Yes, we have already run payroll for this year
            </label>
          </div>
          <div>
            <input
              type="checkbox"
              id="op-2"
              value={runPayrollEarlier}
              checked={runPayrollEarlier == false ? true : false}
              onChange={() => setRunPayrollEarlier(false)}
              className="checkbox_input"
            />
            <label
              className="!m-0 !font-normal !text-lg cursor-pointer"
              htmlFor="op-2"
            >
              No, this is our first payroll
            </label>
          </div>
        </div>

        <button className="submit_button mr-4" type="Submit">
          Save & Continue
        </button>
        <span className="skip" onClick={handleSkip}>
          Skip For Now
        </span>
      </form>
    </>
  );
};

export default SetUpCompany;
