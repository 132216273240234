
import React, { useState, useMemo, useEffect } from 'react'
import PageTitle from '../../../components/PageTitle'
import { useSelector } from 'react-redux';
import Select from "react-select";
import { adminStyles, adminStylesLight } from '../../../utils/selectStyles';
import { generateSelectData } from '../../../utils/serialiseResponse';
import { fileToBase64, orgTypes } from '../../../utils/helper';
import { updateCompanyProfile, getCompanyProfile } from '../../../services/hrms';
import '../../Employee/create/create.css'
import customToast from '../../../components/CustomToast';
const Create = () => {
    const theme = useSelector((state) => state.theme.theme);
    const [res, setres] = useState()
    const [data, setdata] = useState(
        { orgname: "", contact: "", orgType: null, email: "", website: "", addLine1: "", addlIne2: "", state: null }
    );
    const [uploadData, setUploadData] = useState([]);
    const indianStates = useMemo(() => [
        { _id: 1, label: "Andhra Pradesh" },
        { _id: 2, label: "Arunachal Pradesh" },
        { _id: 3, label: "Assam" },
        { _id: 4, label: "Bihar" },
        { _id: 5, label: "Chhattisgarh" },
        { _id: 6, label: "Goa" },
        { _id: 7, label: "Gujarat" },
        { _id: 8, label: "Haryana" },
        { _id: 9, label: "Himachal Pradesh" },
        { _id: 10, label: "Jharkhand" },
        { _id: 11, label: "Karnataka" },
        { _id: 12, label: "Kerala" },
        { _id: 13, label: "Madhya Pradesh" },
        { _id: 14, label: "Maharashtra" },
        { _id: 15, label: "Manipur" },
        { _id: 16, label: "Meghalaya" },
        { _id: 17, label: "Mizoram" },
        { _id: 18, label: "Nagaland" },
        { _id: 19, label: "Odisha" },
        { _id: 20, label: "Punjab" },
        { _id: 21, label: "Rajasthan" },
        { _id: 22, label: "Sikkim" },
        { _id: 23, label: "Tamil Nadu" },
        { _id: 24, label: "Telangana" },
        { _id: 25, label: "Tripura" },
        { _id: 26, label: "Uttar Pradesh" },
        { _id: 27, label: "Uttarakhand" },
        { _id: 28, label: "West Bengal" }
    ], []);
    const indianStatesSelect = useMemo(
        () => generateSelectData(indianStates, "name"),
        [indianStates],
    );
    const orgTypeSelect = useMemo(
        () => generateSelectData(orgTypes, "name"),
        [orgTypes],
    );

    useEffect(() => {
        getCompanyProfile().then((res) => setdata(
            {
                ...data,
                orgname: res.data[0]?.companyName,
                contact: res.data[0]?.contact,
                orgType: res.data[0]?.orgType,
                email: res.data[0]?.email,
                website: res.data[0]?.website,
                addLine1: res.data[0]?.addressLine1,
                addlIne2: res.data[0]?.addressLine2,
                state: res.data[0]?.state
            }
        ))
    }, [])


    let changeHandler = (e) => {
     let ext = e?.target?.files[0]?.name.split('.');
     if(ext[1] == 'svg'){
        setUploadData("")
        return customToast.error("Svg's are not allowed !")
      } 
        const validFiles = Array.from(e.target.files);
        // setUploadData([]);
        Promise.all(validFiles.map(fileToBase64))
            .then((base64Images) => {
                setUploadData(base64Images);
            })
            .catch((error) => {
                customToast.error('Error adding images');
            });
 };
    const resetForm = () => {
        setUploadData([]);
        setdata({ orgname: "", contact: "", orgType: null, email: "", website: "", addLine1: "", addlIne2: "", state: null })
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        const details = {
            companyName: data?.orgname,
            company_type: data?.orgType,
            addressLine1: data?.addLine1,
            addressLine2: data?.addlIne2,
            state: data?.state,
            contact: data?.contact,
            email: data?.email,
            website: data?.website
        }
        const payload = {
            logo: uploadData,
            details
        }
        console.log(payload)
        updateCompanyProfile(payload).then((res) => {
            customToast.success('Updated successefully'); setUploadData([])
        })
            .catch(() => customToast.error("Error occurred"));


    }
    return (
        <main className="w-full">
            <div
                className={
                    "creation-content " + `${theme == "light" ? "light_bg" : ""}`
                }
            >
                <PageTitle title="Create Company Profile" route='/dashboard' />
                <section className="admin-content">
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <div className={`flex flex-row justify-between pb-4 pr-6 items-center  border-solid border-primaryLight3 dark:border-primary border-b-[1px] border-primaryLight3 dark:border-primary `}>
                            <label className='cardlink-row'>Organisation Logo</label>
                            <div className=" autofill_container">
                                <input
                                    type="file"
                                    placeholder="Enter UID"
                                    onChange={(e) => changeHandler(e)}
                                />
                                <button
                                    className="button-blue-gradient mt-3"

                                >
                                    Upload
                                </button>
                            </div>
                        </div>
                        <div className="">
                            <div className='row'>
                                <div >
                                    <label>
                                        ORGANISATION NAME <span className="asterisk-imp">*</span>
                                    </label>
                                    <br />
                                    <input
                                        type="text"
                                        required
                                        placeholder="Enter Organisation Name"
                                        value={data?.orgname}
                                        onChange={(e) => setdata({ ...data, orgname: e.target.value })}
                                    />
                                </div>

                                <div>
                                    <label>
                                        ORGANISATION TYPE <span className="asterisk-imp">*</span>
                                    </label>
                                    <Select
                                        placeholder="Select Type"
                                        required
                                        options={orgTypeSelect}
                                        value={data?.orgType}
                                        styles={theme == "light" ? adminStylesLight : adminStyles}
                                        onChange={(e) => setdata({ ...data, orgType: e })}
                                    />
                                </div>

                                <div >
                                    <label htmlFor="mName">ADDRESS LINE 1</label>
                                    <input
                                        type="text"
                                        id="mName"
                                        required
                                        placeholder="Enter Address"
                                        value={data.addLine1}
                                        onChange={(e) => setdata({ ...data, addLine1: e.target.value })}
                                    />
                                </div>

                                <div>
                                    <label htmlFor="lName">
                                        ADDRESS LINE 2
                                    </label>
                                    <input
                                        type="text"
                                        id="lName"
                                        placeholder="Enter Address"
                                        value={data?.addlIne2}
                                        onChange={(e) => setdata({ ...data, addlIne2: e.target.value })}
                                    />
                                </div>
                            </div>

                            <div className='row'>
                                <div>
                                    <label>
                                        SELECT STATE<span className="asterisk-imp">*</span>
                                    </label>
                                    <Select
                                        isSearchable={true}
                                        options={indianStatesSelect}
                                        required
                                        styles={theme == "light" ? adminStylesLight : adminStyles}
                                        value={data?.state}
                                        placeholder="Select State"
                                        onChange={(e) => setdata({ ...data, state: e })}
                                    />
                                </div>

                                <div>
                                    <label>
                                        CONTACT NUMBER <span className="asterisk-imp">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        pattern="[6-9]{1}[0-9]{9}"
                                        maxLength={10}
                                        placeholder="Enter Contact Number"
                                        required
                                        value={data?.contact}
                                        onChange={(e) => setdata({ ...data, contact: e.target.value })}
                                    />
                                </div>

                                <div >
                                    <label>
                                        Email ID <span className="asterisk-imp">*</span>
                                    </label>
                                    <input
                                        type="email"
                                        placeholder="Enter Email Id"
                                        required
                                        value={data?.email}
                                        onChange={(e) => setdata({ ...data, email: e.target.value })}
                                    />
                                </div>

                                <div>
                                    <label>WEBSITE</label>
                                    <input
                                        type="text"
                                        placeholder="Enter Website Link"
                                        value={data?.website}
                                        onChange={(e) => setdata({ ...data, website: e.target.value })}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='cardlink-row pb-8'>
                            <button type="submit" className="button-blue-gradient">
                                Save
                            </button>
                        </div>
                    </form>
                </section>
            </div>
        </main>
    )
}

export default Create