import React from 'react'
import SideBar from '../../../components/SideBar/SideBar.jsx';
import { title , links } from './navdata.js';
import { Outlet } from 'react-router-dom';

const PaymentSchedule = () => {
  
  return (
    <div className="flex w-full ">
      <SideBar title={title} links={links}/>
      <Outlet />
    </div>
  )
}

export default PaymentSchedule;