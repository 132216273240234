import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sidenavVisible: true,
};

const sidenavSlice = createSlice({
  name: 'sidenav',
  initialState,
  reducers: {
    showSidenav: (state) => {
      state.sidenavVisible = true;
    },
    hideSidenav: (state) => {
      state.sidenavVisible = false;
    },
  },
});

export const { showSidenav, hideSidenav } = sidenavSlice.actions;

export default sidenavSlice.reducer;