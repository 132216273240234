import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentStep } from "../../../reducers/setUpCurrentStepReducer";
import {
  createDepartments,
  setDepartments,
} from "../../../reducers/departmentReducer";
import { changeHrmsSetUpStatus } from "../../../reducers/hrmsSetUpStatusReducer";
import serialise from "../../../utils/serialiseResponse";
import customToast from "../../../components/CustomToast";
import { useNavigate } from "react-router-dom";

const SetUpDepartment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const departments = useSelector(({ department }) => department);
  const [deptName, setDeptName] = useState("");

  const CURRENT_STEP = 6;

  useEffect(() => {
    dispatch(setCurrentStep(CURRENT_STEP));
    dispatch(setDepartments());
  }, []);

  let tableData = useMemo(() => serialise(departments?.data), [departments]);

  const handleSubmit = (e) => {
    e.preventDefault();

    try {
      if (!deptName) return customToast.error("add dept name !");
      dispatch(createDepartments({ deptName }));
      setDeptName("");
      return customToast.success("Department added successfully !");
    } catch (error) {
      console.log(error);
      customToast.error("Error occurred !");
    }
  };

  return (
    <div>
      <div className="flex justify-between mt-2 text-black">
        <p>Setup Department</p>
      </div>
      <div>
        <input
          value={deptName}
          onChange={(e) => setDeptName(e?.target?.value)}
          type="text"
          className="text_input w-fit mr-4"
          placeholder="Enter department name"
        />

        <button
          className="submit_button submit_button_border-radius ml-4"
          onClick={(e) => handleSubmit(e)}
        >
          Add Department
        </button>
      </div>
      <div>
        <table className="w-full mt-8">
          <thead className="w-full ">
            <tr className="bg-gray-200 text-black">
              <th className="px-4 py-2">Sr</th>
              <th className="px-4 py-2">Department</th>
              <th className="px-4 py-2">Department Code</th>
            </tr>
          </thead>
          <tbody className="space-y-4">
            {tableData?.map((el, idx) => (
              <tr key={idx} className="text-center text-black">
                <td className="px-4 py-2">{el?.sr}</td>
                <td className="px-4 py-2">{el?.name}</td>
                <td className="px-4 py-2">{el?.uid}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div>
        <button
          className="m-12 submit_button"
          onClick={() => {
            dispatch(changeHrmsSetUpStatus({ status: "0" }));
            navigate("../division");
          }}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default SetUpDepartment;
