import React, { useEffect, useMemo, useState } from 'react';
import Table from '../../../components/Table';
import { useDispatch, useSelector } from 'react-redux';
import PageTitle from '../../../components/PageTitle';
import { fetchStates } from '../../../reducers/stateReducer';
import { clearStates, deleteStateAsync, editStateAsync, addStateAsync, setStates } from '../../../reducers/stateReducer';
import { fetchDependents } from '../../../services/location';
import EditPopup from '../../../components/EditPopup';
import { RiEdit2Fill } from 'react-icons/ri';
import customToast from '../../../components/CustomToast';
import serialise from '../../../utils/serialiseResponse';

const State = () => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.theme);
  const states = useSelector((state) => state.states.data);
  
  const [showEdit, setShowEdit] = useState(false);
  const [stateName, setStateName] = useState("");
  const [stateData, setStateData] = useState();
  const [showDelete, setShowDelete] = useState(false);
  const [dependents, setDependents] = useState([]);

  const handleEdit = async (e) => {
    e.preventDefault();
    await dispatch(editStateAsync(stateData?._id, { ...stateData, name: stateName }));
    setShowEdit(false);
  };

  const handleDelete = () => {
    dispatch(deleteStateAsync(stateData?._id));
    setShowEdit(false);
  };

  const setEditForm = (data) => {
    setShowEdit(true);
    setStateName(data?.name);
    setStateData(data);
  };

  useEffect(() => {
    dispatch(fetchStates("state"));
    return () => {
      dispatch(clearStates());
    };
  }, [dispatch]);

  const fetchDependencies = async (data) => {
    try {
      const response = await fetchDependents(data._id, 'state');
      setDependents(response);
    } catch (error) {
      customToast.error("We Encountered an Error");}
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stateName) return customToast.error("Please Enter State Name");
    var index = states.findIndex(p => p.name.toLowerCase() === stateName.toLowerCase());
    if (index === -1) {
      try {
        await dispatch(addStateAsync({ name: stateName }));
        customToast.success("State Successfully Created");
        setStateName("");
      } catch (e) {
        customToast.error("We Encountered an Error");
      }
    } else {
      customToast.error("State Already Exists!");
    }
  };

  const columns = useMemo(() => [
    {
      Header: "Sr no.",
      accessor: "sr",
      disableFilters: true,
      disableSortBy: true
    },
    {
      Header: "State",
      accessor: "name",
      disableFilters: true,
        disableSortBy: true
    },
    {
      Header: "Action",
      accessor: "",
      disableFilters: true,
      disableSortBy: true,
      Cell: (props) => {
        return (
          <span className="flex items-center justify-center">
            <span
              className="react-table-view-link"
              onClick={() => {
                setEditForm(props.row.original);
              }}
            >
              <RiEdit2Fill className="icon-color-green" />
            </span>
          </span>
        );
      },
      disableFilters: true,
      disableSortBy: true,
    },
  ], []);

  const tableData = useMemo(() => serialise(states) , [states]) ;

  return (

    <main className="main-content bg-primaryLight2 dark:bg-primaryDark1">
      <div className={"area-creation-content" + `${theme == "light" ? "light_bg" : ""}`}>
        <PageTitle title="State" route={'../..'} />
        <form onSubmit={(e) => handleSubmit(e)}>
          <section className="admin-content">
            <div className="row">
              <div>
                <label>Enter State Name <span className='text-error'>*</span></label>
                <input
                  placeholder='Enter State'
                  onChange={(e) => setStateName(e.target.value)}
                  value={stateName}
                />
              </div>

              <div className="items-end flex">
                <button type='submit' className='py-[0.8rem] px-[2rem] button-blue-gradient'>Add State</button>
              </div>
            </div>

            <div className="mx-8 pb-10">
              {tableData?.length > 0 && (
                <h2 className="show-entries">
                  Showing ({tableData?.length}) Entries
                </h2>
              )}

              {states.length > 0 ? (
                <div className="filter-table">
                  <Table columns={columns} data={tableData} />
                </div>
              ) : (
                <div className='text-error font-medium text-3xl mt-5'>No State Exists!</div>
              )}

              {showEdit && (
                <EditPopup
                  handleDelete={handleDelete}
                  handleEdit={handleEdit}
                  setShowEdit={setShowEdit}
                  label="State"
                  value={stateName}
                  setValue={setStateName}
                  placeholder="Enter State"
                />
              )}
            </div>
          </section>
        </form>
      </div>

    </main >

    // <main className='mt-8 mr-8 w-full bg-whiteLight dark:bg-primaryDark pb-28'>
    //   <div className="dark:bg-primaryDark2">
    //     <PageTitle title="State" route={'../..'}/>
    //   </div>
    //   <form onSubmit={(e) => handleSubmit(e)}>
    //     <div>
    // <div className='uppercase text-2xl font-medium dark:text-secondary mt-5'>State Name <span className='text-error'>*</span></div>
    // <div className='mt-4 flex gap-8 justify-start items-center'>
    //   <input
    //     className='px-5 py-3 inline-block dark:bg-primary dark:text-secondary outline-none'
    //     placeholder='Enter State Name'
    //     onChange={(e) => setStateName(e.target.value)}
    //     value={stateName}
    //   />
    // <button type='submit' className='inline-block button_blue_gradient dark:text-tertiary'>Add State</button>
    //       </div>
    //     </div>
    //   </form>
    // {states.length > 0 ? (
    //   <div className="filter-table">
    //     <Table columns={columns} data={tableData} />
    //   </div>
    // ) : (
    //   <div className='text-error font-medium text-3xl mt-5'>No State Exists!</div>
    // )}
    // {showEdit && (
    //   <EditPopup
    //     handleDelete={handleDelete}
    //     handleEdit={handleEdit}
    //     setShowEdit={setShowEdit}
    //     label="State"
    //     value={stateName}
    //     setValue={setStateName}
    //     placeholder="Enter State"
    //   />
    // )}
    // </main>
  );
};

export default State;
