import { customAxios } from "../utils/axiosinstance"

export const CreateEarning = async(payload) => {
    const {data} = await customAxios.post(`/hrms/earnings/` , payload )
    return data;
}
// This route should be replaced with PostEarning
export const GetEarnings = async(payload) => {
    const {data} = await customAxios.get(`/hrms/earnings/` , payload )
    return data;
}

export const GetActiveEarnings = async(payload) => {
    const {data} = await customAxios.get(`/hrms/earnings/active-earning` , payload )
    return data;
}

export const UpdateEarning = async(id , payload) => {
   const {data} = await customAxios.post(`/hrms/earnings/update/${id}` , payload);
   return {data};
}

export const PostEarningTypes = async(payload) => {
    const {data} = await customAxios.post(`/hrms/earnings/types` , payload )
    return data;
}

export const GetEarningTypes = async(payload) => {
    const {data} = await customAxios.get(`/hrms/earnings/types` , payload )
    return data;
}
export const PostEarning = async(payload) =>{
    const {data} = await customAxios.post('/hrms/earnings/new', payload);
    return data ;
}

export const deleteEarning = async (payload) => {
    const res = customAxios.post(`/hrms/earnings/delete`, payload);
    const {data} = await customAxios.get(`/hrms/earnings/` , payload );
    return data;
} 