import React from "react";
import { Outlet } from "react-router-dom";
import { title, links } from "../Investment";
import SideBar from "../../../components/SideBar/SideBar";

const ManageInvestment = () => {
  return (
    <>
      <SideBar title={title} links={links} />
      <Outlet />
    </>
  );
};

export default ManageInvestment;
