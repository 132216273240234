import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../../components/PageTitle";
import { useNavigate } from "react-router-dom";
import { fetchPayrunApproveStatus } from "../../../reducers/payrunAprroveStatus";
import { returnMonthFromIndex } from "../../../utils/helper";
import { fetchEmployeesWithTemp } from "../../../reducers/employeesReducer";
import {
  CreatePaymentSchedule,
  GetPaymentSchedule,
} from "../../../services/paymentschedule";
import customToast from "../../../components/CustomToast";

const PayRunInfo = () => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.theme);
  const month = useSelector((state) => state.payrunApprove.month);
  const year = useSelector((state) => state.payrunApprove.year);
  const navigate = useNavigate();
  const [payingMonth, setPayingMonth] = useState({});
  const [payingYear, setPayingYear] = useState();
  const [payDateMonth, setPayDateMonth] = useState({});
  const [paymentScheduleDetails, setPaymentScheduleDetails] = useState("");
  const [paymentDetails, setPaymentDetails] = useState("");
  const [paymentYear, setPaymentYear] = useState("");

  useEffect(() => {
    dispatch(fetchPayrunApproveStatus());
    dispatch(fetchEmployeesWithTemp());
  }, [dispatch]);

  useEffect(() => {
    GetPaymentSchedule({})
      .then((data) => {
        setPaymentScheduleDetails(data);
      })
      .catch((err) => customToast.error("Sorry Something Went Wrong!"));
  }, []);

  useEffect(() => {
    if (paymentDetails) {
      const setData = async () => {
        const response = await CreatePaymentSchedule(paymentDetails);
      };
      setData();
    }
  }, [paymentDetails]);

  useEffect(() => {
    const name = returnMonthFromIndex(month);
    setPayingMonth({
      value: month,
      month: name,
    });
    // if(month == 11 && paymentDetails?.makePaymentOn?.type === "dayOfFollowingMonth"){
    //   let nextYear = year + 1;
    //   setPaymentYear(nextYear)
    // }
    // else{
    setPaymentYear(paymentScheduleDetails?.paymentMonth?.year);
    setPayingYear(year);
  }, [month, year, paymentScheduleDetails]);

  const employeeData = useSelector((state) => state.employees.data);

  return (
    <main className="main-content pl-10 bg-primaryLight2 dark:bg-primaryDark1">
      <div className="creation-content">
        <PageTitle title="PAYRUN INFO" route={"../.."} />
      </div>
      <div className="payrun-info-title">
        <h3 className="!text-primaryDark dark:!text-primaryLight2">
          Process Payrun For {payingMonth.month} {payingYear}
          <span>Ready</span>
        </h3>
      </div>
      <section
        className={
          "user-detail-container user-detail-container-blueDark-border " +
          `${theme == "light" ? "light_bg" : ""}`
        }
      >
        <div className="user-detail-box">
          <h4 className="!text-primaryDark dark:!text-primaryLight2 ">
            Employees Net Pay
          </h4>
          <p className="mt-5 user-para">YET TO PROCESS</p>
        </div>

        <div
          className={
            "user-detail-box " + `${theme == "light" ? "light_bg_logo" : ""}`
          }
        >
          <h4 className="!text-primaryDark dark:!text-primaryLight2">
            Payment Day
          </h4>
          <p className="mt-5 user-para-white">
            {paymentScheduleDetails?.makePaymentOn?.value}/
            {paymentScheduleDetails?.paymentMonth?.month + 1}/{paymentYear}
          </p>
        </div>

        <div
          className={
            "user-detail-box " + `${theme == "light" ? "light_bg_logo" : ""}`
          }
        >
          <h4 className="!text-primaryDark dark:!text-primaryLight2">
            No of Employees
          </h4>
          <p className="mt-5 user-para-white">{employeeData.length}</p>
        </div>

        <div
          className={
            "user-detail-box " + `${theme == "light" ? "light_bg_logo" : ""}`
          }
        >
          <h4></h4>
          <div className="col-sm-2 col-md-4 col-lg-2 mt-5 w-auto">
            <button
              type="submit"
              className="button-green-gradient"
              onClick={() =>
                navigate("/payrun/manage-payrun/payrun-info/create", {
                  state: {
                    payingMonth,
                    payingYear,
                    paymentYear,
                    paymentDetails,
                    payDateMonth,
                  },
                })
              }
            >
              Create Pay Run
            </button>
          </div>
        </div>
        <h4 className="mt-5 user-para-red">Please process and approve this </h4>
        <h4 className="mt-5 user-para-red ml-[-4.5rem]">
          pay run before {paymentScheduleDetails?.makePaymentOn?.value}/
          {paymentScheduleDetails?.paymentMonth?.month + 1}/{paymentYear}
        </h4>
      </section>
    </main>
  );
};

export default PayRunInfo;
