import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { RiCloseCircleLine, RiEdit2Fill } from "react-icons/ri";
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md";

import customToast from "../../../components/CustomToast";
import PageTitle from "../../../components/PageTitle";
import Table from "../../../components/Table";
import { adminStyles, adminStylesLight } from "../../../utils/selectStyles";
import serialise from "../../../utils/serialiseResponse";

import {
  addDesignation,
  editDesignation,
  deleteDesignation,
  setDesignations,
  clearDesignations,
} from "../../../reducers/designationReducer";
import EditPopup from "../../../components/EditPopup";

const priorityOptions = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
  { value: 7, label: "7" },
  { value: 8, label: "8" },
  { value: 9, label: "9" },
  { value: 10, label: "10" },
  { value: 11, label: "11" },
  { value: 12, label: "12" },
  { value: 13, label: "13" },
  { value: 14, label: "14" },
  { value: 15, label: "15" },
  { value: 16, label: "16" },
  { value: 17, label: "17" },
  { value: 18, label: "18" },
  { value: 19, label: "19" },
  { value: 20, label: "20" },
];

const Designation = () => {
  const dispatch = useDispatch();
  const designationData = useSelector((state) => state.designations.data);
  const theme = useSelector((state) => state.theme.theme);

  const [newDesignation, setNewDesignation] = useState("");

  const [designation, setDesignation] = useState("");
  const [priority, setPriority] = useState("");
  const [showEdit, setShowEdit] = useState(false);

  const [desigData, setDesigData] = useState();

  const setEditForm = (data) => {
    setShowEdit(false);
    setDesignation(data?.name);
    setDesigData(data);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Sr. No",
        accessor: "sr",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "Designation",
        accessor: "name",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "Priority",
        accessor: "priority",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Actions",
        accessor: "",
        Cell: (props) => {
          return (
            <span
              className="react-table-view-link"
              onClick={() => {
                setEditForm(props.row.original);
                setShowEdit(true);
              }}
            >
              <RiEdit2Fill className="icon-color-green" />
            </span>
          );
        },
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [],
  );

  useEffect(() => {
    dispatch(setDesignations());
    return () => {
      dispatch(clearDesignations());
    };
  }, [dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!newDesignation || !priority) {
      return customToast.error("Please fill all the fields");
    }
    try {
      dispatch(
        addDesignation({ name: newDesignation, priority: priority?.value }),
      );
      setNewDesignation("");
      setPriority("");
      customToast.success("Designation added successfully");
    } catch (error) {
      customToast.error("Unable to add designation");
    }
  };

  const tableData = useMemo(
    () => serialise(designationData),
    [designationData],
  );

  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      await dispatch(deleteDesignation(desigData._id));
      setShowEdit(false);
      customToast.success("Designation Deleted Successfully");
    } catch (error) {
      customToast.error("Unable To Delete Designation");
    }
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    try {
      await dispatch(
        editDesignation(desigData._id, {
          name: designation,
          priority: priority?.value,
        }),
      );
      setShowEdit(false);
      customToast.success("Designation Edited Successfully");
    } catch (error) {
      customToast.error("Unable To Edit Designation");
    }
  };

  return (

    <main className="main-content bg-primaryLight2 dark:bg-primaryDark1">
      <div className={"area-creation-content" + `${theme == "light" ? "light_bg" : ""}`}>
        <PageTitle title="Create Designation" route={'../..'} />

        <form onSubmit={handleSubmit}>
          <section className="admin-content">
            <div className="row">
              <div>
                <label htmlFor="designation">
                  Enter Designation <span className="asterisk-imp">*</span>
                </label>
                <input
                  type="text"
                  id="designation"
                  placeholder="Enter Designation"
                  value={newDesignation}
                  onChange={({ target }) => setNewDesignation(target.value)}
                />
              </div>

              <div>
                <label htmlFor="designation">
                  Select Priority <span className="asterisk-imp">*</span>
                </label>
                <Select
                  options={priorityOptions}
                  value={priority}
                  onChange={(e) => setPriority({ ...e })}
                  styles={theme == "light" ? adminStylesLight : adminStyles}
                  placeholder="Select Priority"
                />
              </div>

              <div className="items-end flex">
                <button type="submit" className="py-[0.8rem] px-[2rem] button-blue-gradient">
                  Add Designation
                </button>
              </div>
            </div>


            <div className="mx-8 pb-10">
              {tableData?.length > 0 && (
                <h2 className="show-entries">
                  Showing ({tableData?.length}) Entries
                </h2>
              )}
              <div className="filter-table">
                <Table columns={columns} data={tableData} />
              </div>
              {showEdit ? (
                <EditPopup
                  handleDelete={handleDelete}
                  handleEdit={handleEdit}
                  setShowEdit={setShowEdit}
                  label="Designation"
                  value={designation}
                  setValue={setDesignation}
                  placeholder="Enter Designation"
                />
              ) : null}
            </div>
          </section>
        </form>
      </div>

    </main>

    // <div className="main-content admin-content">
    //   <div className="area-creation-content">
    // <PageTitle title="Create Designation" route={'../..'}/>

    //     <section className="area-creation-content__form">
    //       <form onSubmit={handleSubmit}>
    //         <div className="flex flex-row gap-x-8">
    // <div className="w-fit">
    //   <label htmlFor="designation">
    //     Enter Designation <span className="asterisk-imp">*</span>
    //   </label>
    //   <br />
    //   <input
    //     type="text"
    //     id="designation"
    //     className="me-5 mt-5"
    //     placeholder="Enter Designation"
    //     value={newDesignation}
    //     onChange={({ target }) => setNewDesignation(target.value)}
    //   />
    // </div>

    //           <div>
    // <label htmlFor="designation">
    //   Select Priority <span className="asterisk-imp">*</span>
    // </label>
    // <br />
    // <Select
    //   options={priorityOptions}
    //   value={priority}
    //   onChange={(e) => setPriority({ ...e })}
    //   styles={adminStyles}
    //   placeholder="Select Priority"
    //   isSearchable={false}
    //   className='w-[70%]'
    // />
    //           </div>
    //         </div>

    // <button type="submit" className="button-blue-gradient mt-8">
    //   Add Designation
    // </button>
    //       </form>
    //     </section>

    //     <section className="area-creation-content__info">
    //       {tableData?.length > 0 && (
    //         <h2 className="web-app__heading mb-4 ms-2">
    //           Showing ({tableData?.length}) Entries
    //         </h2>
    //       )}
    //       <div className="filter-table">
    //         <Table columns={columns} data={tableData} />
    //       </div>
    //       {showEdit ? (
    //         <EditPopup
    //           handleDelete={handleDelete}
    //           handleEdit={handleEdit}
    //           setShowEdit={setShowEdit}
    //           label="Designation"
    //           value={designation}
    //           setValue={setDesignation}
    //           placeholder="Enter Designation"
    //         />
    //       ) : null}
    //     </section>
    //   </div>
    // </div>
  );
};

export default Designation;
