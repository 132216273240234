import { customAxios } from "../utils/axiosinstance";

export const GetAllEmployees = async (id) => {
  const { data } = await customAxios.get(`hrms/employee/all`);
  return data.data;
};
export const ChangeRegimeType = async (payload) => {
  const { data } = await customAxios.patch(
    `hrms/employee/toggle-tax-regime-type/${payload.id}`,
    payload,
  );
  return data.data;
};

export const GetEmployeePayslipsOnTheBasisOfYear = async (payload) => {
  const { data } = await customAxios.get(
    `hrms/employee/finance/payslips?emplId=${payload.emplId}&year=${payload.year}`,
  );
  return data.data;
};
