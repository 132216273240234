import { createSlice } from "@reduxjs/toolkit";
import { createDepartment, editDepartment, getDepartments, deleteDepartment  } from "../services/metaData";
import { getTempelate } from "../services/salaryTempelates";

const salaryTempelateSlice = createSlice({
  name: "SalaryTempelate",
  initialState: {
    data: [],
    temp: [],
    user: [],
  },
  reducers: {
    setTemp: (state, action) => {
      state.data = action.payload.data;
    },
    setTempelate: (state, action) => {
      state.temp = action.payload.data;
    },
    setUser: (state, action) => {
      state.user = action.payload.data;
    },
  },
});

export const { setTemp , setUser , setTempelate } = salaryTempelateSlice.actions;

export const fetchTemp = () => async (dispatch) => {
  try {
    const { data } = await getTempelate();
    dispatch(setTemp({ data }));
  } catch (err) {
    console.log(err)
  }
};

export const setTempData = (data) => async (dispatch) => {
  try {
    dispatch(setTempelate({ data }));
  } catch (err) {
    console.log(err)
  }
};

export const setUsers = (data) => async (dispatch) => {
  try {
    dispatch(setUser({ data }));
  } catch (err) {
    console.log(err)
  }
};



export default salaryTempelateSlice.reducer;
