import { HiRefresh } from "react-icons/hi";
import { PiUploadFill } from "react-icons/pi";
import { FcLeave } from "react-icons/fc";
import { IoPersonCircleOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaUser } from "react-icons/fa6";
import { TbMoneybag } from "react-icons/tb";
import { BiRevision } from "react-icons/bi";
import { SiMoneygram } from "react-icons/si";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { BiCalendarExclamation } from "react-icons/bi";
import { CiCreditCard2 } from "react-icons/ci";
import { IoNewspaperOutline } from "react-icons/io5";
import { TbReportAnalytics } from "react-icons/tb";
import { MdPieChart } from "react-icons/md";
import { LuBarChart4 } from "react-icons/lu";
import {  FaCalendarCheck } from "react-icons/fa";
import { GiTakeMyMoney } from "react-icons/gi";
import { TbTemplate } from "react-icons/tb";
import { SiGoogleforms } from "react-icons/si";
import { HiMiniReceiptPercent } from "react-icons/hi2";
import { RiDiscountPercentFill } from "react-icons/ri";
import { RiUserShared2Fill } from "react-icons/ri";
import { FaSync } from "react-icons/fa";


const CardLink = ({ title, type, link, className }) => {
  const theme = useSelector((state) => state.theme.theme);
  const renderIcon = (type) => {
    if (type === "ManagePayrun") return <HiRefresh />;
    else if (type === "Leave") return <BiCalendarExclamation />;
    else if (type === "payroll") return <IoNewspaperOutline/>;
    else if (type === "salary") return <TbReportAnalytics/>;
    else if (type === "statutory") return <MdPieChart/>;
    else if (type === "tempelates") return <LuBarChart4/>;
    else if (type === "taxes") return <SiGoogleforms/>;
    else if (type === "UploadAttendance") return <PiUploadFill />;
    else if (type === "Loan") return <CiCreditCard2/>;
    else if (type === "Loan/AdvanceSalary") return <CiCreditCard2/>;
    else if (type === "manage-employee") return <IoPersonCircleOutline />;
    else if (type === "onboarding") return <RiUserShared2Fill />;
    else if (type === "profile") return <FaUser />;
    else if (type === "previous-salary") return <RiMoneyDollarCircleLine />;
    else if (type === "investment") return <SiMoneygram />;
    else if (type === "reimbursement") return <TbMoneybag />;
    else if (type === "approve-salary-revision") return <BiRevision />;
    else if (type === "masterdata") return <FaUser/>;
    else if (type === "money") return <GiTakeMyMoney/>;
    else if (type === "tempelate") return <TbTemplate/>;
    else if (type === "tax") return <HiMiniReceiptPercent />
    else if (type === "SyncAttendance") return <FaSync />
    ;
    else if (type === "statuatory") return <MdPieChart/>;
    else if (type === "schedule-payment") return <FaCalendarCheck/>;

  };

  return (
    <Link className="admin-panel__cards-link" to={`${link}`}>
      <div
        className={theme === "light" ? "admin-panel__cards_light" : className}
      >
        {renderIcon(type)}
        <h3 className="web-app__heading">{title}</h3>
      </div>
    </Link>
  );
};

export default CardLink;
