import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setCurrentStep } from "../../../reducers/setUpCurrentStepReducer";
import { useNavigate } from "react-router-dom";
import customToast from "../../../components/CustomToast";
import { addTaxInfo } from "../../../services/hrms";

const TaxInformation = () => {
  const CURRENT_STEP = 2;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [pan, setPan] = useState("");
  const [tan, setTan] = useState("");
  const [aoCode, setAoCode] = useState("");
  const [taxPaymentFreq, setTaxPaymentFreq] = useState("");

  const resetState = () => {
    setPan("");
    setTan("");
    setAoCode("");
    setTaxPaymentFreq("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!pan) {
        customToast.error("Please fill PAN Details !!");
        return;
      }
      const taxDetails = {
        pan,
        tan,
        aoCode,
        taxPaymentFreq,
      };
      await addTaxInfo(taxDetails);
      customToast.success("Tax Information Added Successfully");
      resetState();
      navigate("/setup/schedule-payment");
    } catch (error) {
      customToast.error("Something went wrong,please try again later");
    }
  };

  const handleSkip = (e) => {
    navigate("/setup/schedule-payment");
  };

  useEffect(() => {
    dispatch(setCurrentStep(CURRENT_STEP));
  }, []);

  return (
    <>
      <p>Tax Information</p>
      <form onSubmit={handleSubmit}>
        <div className="two_in_one">
          <div>
            <label htmlFor="cmp_name">PAN</label>
            <span className="imp_star">*</span>
            <br />
            <input
              type="text"
              placeholder="PAN number"
              value={pan}
              onChange={(e) => setPan(e.target.value)}
              className="text_input"
            />
          </div>
          <div>
            <label htmlFor="cmp_name">TAN</label>
            <br />
            <input
              type="text"
              placeholder="TAN number"
              value={tan}
              onChange={(e) => setTan(e.target.value)}
              className="text_input"
            />
          </div>
        </div>

        <div className="two_in_one">
          <div>
            <label htmlFor="cmp_name">TDS Circle/ AO Code</label>
            <br />
            <input
              type="text"
              placeholder="TDS Circle/ AO Code"
              value={aoCode}
              onChange={(e) => setAoCode(e.target.value)}
              className="text_input"
            />
          </div>
          <div>
            <label htmlFor="cmp_name">Tax Payment Frequency</label>
            <br />
            <input
              type="text"
              placeholder="Frequency"
              value={taxPaymentFreq}
              onChange={(e) => setTaxPaymentFreq(e.target.value)}
              className="text_input"
            />
          </div>
        </div>

        <div className="mt-4">
          <button className="submit_button" type="Submit">
            Save & Continue
          </button>
          <span className="skip" onClick={handleSkip}>
            Skip For Now
          </span>
        </div>
      </form>
    </>
  );
};

export default TaxInformation;
