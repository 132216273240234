import { useEffect, useState, useMemo } from "react";
import PageTitle from "../../../../components/PageTitle/index.jsx";
import { adminStyles, adminStylesLight } from "../../../../utils/selectStyles.js";
import Select from "react-select";
import './assignLoan.css'
import { RxCross1 } from "react-icons/rx";
import { useDispatch, useSelector } from 'react-redux'
import { clearEmployees, fetchEmployees } from '../../../../reducers/employeesReducer.js';
import { generateSelectData, generateSelectDataForLoans } from '../../../../utils/serialiseResponse.js';
import { convertNumberToWords, monthOptions } from '../../../../utils/helper.js';
import customToast from '../../../../components/CustomToast/index.js'
import { fetchAllEmployeesLoans } from "../../../../reducers/employeesloansReducer.js";
import { assignloan } from "../../../../services/loans.js";

const AssignLoan = () => {

  const dispatch = useDispatch();

  const theme = useSelector((state) => state.theme.theme);
  const employees = useSelector(({ employees }) => employees);
  const loansData = useSelector((state) => state.loans.data)

  const [showAssignDialog, setShowAssignDialog] = useState(false);
  const [intRate, setintRate] = useState();
  const [data, setData] = useState({
    loanId: "",
    interestRate: "",
    employeeId: "",
    nameOnPayslip: "",
    loanAmount: "",
    deduction_type: "",
    deduction_month: "",
    sanction_date: "",
    installmentAmount: ""
  })
  const [loanAmountInWords ,  setLoanAmountInwords] = useState();

  useEffect(() => {
    dispatch(fetchEmployees({}));
    dispatch(fetchAllEmployeesLoans());

    return () => dispatch(clearEmployees());
  }, [dispatch]);

  const selectLoans = useMemo(
    () => generateSelectDataForLoans(loansData),
    [loansData]
  );

  const emplSelect = useMemo(
    () => {
      let options = [];
      if (Array.isArray(employees.data)) {
        let data = generateSelectData(employees.data, "fullName");
        options.push(...data);
        options = options.filter((item) => {
          return item.value !== 0 && item.label !== "All";
        });
      }
      return options
    },
    [employees.data]
  );

  const submitHandler = async () => {
    if(!data.employeeId || !data.loanId || !data.loanAmount || !data.installmentAmount || !data.sanction_date) {
      return customToast.error("Please fill all the required fields");
    }

    try{
      const res = await assignloan({
        loanId: data.loanId.value,
        employeeId: data.employeeId.value,
        deduction_month: data.deduction_month.value,
        deduction_type: data.deduction_type.value,
        interestRate: data.interestRate,
        loanAmount: data.loanAmount,
        nameOnPayslip: data.nameOnPayslip,
        sanction_date: data.sanction_date,
        installmentAmount: data.installmentAmount
      })
      if (res) {
        dispatch(fetchAllEmployeesLoans());
        setData({ loanId: "", interestRate: "", employeeId: "", nameOnPayslip: "", loanAmount: "", deduction_type: "", deduction_month: "", sanction_date: "", installmentAmount: "" })
        setintRate('')
        return customToast.success("Loan has been assigned successsfully");
      }
    }
    catch(err){
      return customToast.error("Something went wrong !");
    }
  }

  const deductionTypes = [
    { label: "Monthly", value: "monthly" },
    // { label: "Yearly", value: "yearly" },
    // { label: "Quarterly", value: "quarterly" },
    // { label: "Half-yearly", value: "half-yearly" },
  ];

  useEffect(() => {
    let words = convertNumberToWords(data.loanAmount);
    setLoanAmountInwords(words);
  } , [data.loanAmount])

  return (

    <main className={"main-content " + `${theme == "light" ? "light_bg_main" : ""} w-full`} >
      <div className={"area-creation-content " + `${theme == "light" ? "light_bg" : ""}`}>
        <PageTitle title="Assign Loan" route={'../..'} />
      

      <section className="admin-content mr-8">
        <div className="row ">
          <div className="col-sm-12 col-md-4 col-lg-4">
            <label htmlFor="emp_name">
              EMPLOYEE NAME <span className="asterisk-imp">*</span>
            </label>
            <br />
            <Select
              id="emp_name"
              options={emplSelect}
              value={data.employeeId}
              onChange={(e) => {
                setData({ ...data, employeeId: e })
              }}
              styles={theme == "light" ? adminStylesLight : adminStyles}
              placeholder="Select Name"
              className="w-full"
            />
          </div>

          <div className="col-sm-12 col-md-4 col-lg-4">
            <label htmlFor="loan_name">
              LOAN NAME <span className="asterisk-imp">*</span>
            </label>
            <br />
            <Select
              id="loan_name"
              options={selectLoans}
              value={data.loanId}
              onChange={async (e) => {
                setData({ ...data, loanId: e });
                const selectedLoan = loansData.find(loan => loan._id === e.value);
                if (selectedLoan) {
                  setintRate(selectedLoan.interestRate);
                  setData(prevData => ({
                    ...prevData,
                    interestRate: selectedLoan.interestRate
                  }));
                }
              }}
              styles={theme == "light" ? adminStylesLight : adminStyles}
              placeholder="Select Name"
            />
          </div>

          <div className="col-sm-12 col-md-4 col-lg-4">
            <label htmlFor="int_rate">
              INTEREST RATE
            </label>
            <br />
            <input
              value={intRate}
              disabled
              type="number"
              placeholder="%"
              // className='bg-[#2B2C47] px-6 py-3 w-full outline-none'
            />
          </div>

          <div className="col-sm-12 col-md-4 col-lg-4">
            <label htmlFor="nameOnPayslip">
              NAME ON PAYSLIP
            </label>
            <br />
            <input
              id="nameOnPayslip"
              type="text"
              placeholder="Loan"
              className='bg-[#2B2C47] px-6 py-3 w-full outline-none'
              value={data.nameOnPayslip}
              onChange={(e) => setData({ ...data, nameOnPayslip: e.target.value })}
            />
          </div>
        </div>

        <div className="row ">
          <div className="col-sm-12 col-md-4 col-lg-4">
            <label htmlFor="loan_amt">
              LOAN AMOUNT
              <span className="asterisk-imp">*</span>
            </label>
            <br />
            <input
              type="number"
              placeholder="Enter Amount"
              // className='bg-[#2B2C47] px-6 py-3 w-full outline-none'
              value={data.loanAmount}
              onChange={(e) => setData({ ...data, loanAmount: e.target.value })}
            />
            <br/>
            <p className="font-semibold"> {data.loanAmount ? loanAmountInWords : null} </p>
          </div>

          <div className="col-sm-12 col-md-4 col-lg-4">
            <label htmlFor="ins_amt">
              INSTALLMENT AMOUNT
              <span className="asterisk-imp">*</span>
            </label>
            <br />
            <input
              type="number"
              placeholder="Enter Amount"
              // className='bg-[#2B2C47] px-6 py-3 w-full outline-none'
              value={data.installmentAmount}
              onChange={(e) => setData({ ...data, installmentAmount: e.target.value })}
            />
          </div>

          <div className="col-sm-12 col-md-4 col-lg-4">
            <label htmlFor="ded_type">
              DEDUCTION TYPE
            </label>
            <br />
            <Select
              id="ded_type"
              options={deductionTypes}
              value={data.deduction_type}
              onChange={(e) => setData({ ...data, deduction_type: e })}
              placeholder="Select Type"
              styles={theme == "light" ? adminStylesLight : adminStyles}
            />
          </div>

          <div className="col-sm-12 col-md-4 col-lg-4">
            <label htmlFor="ded_month">
              DEDUCTION MONTH
            </label>
            <br />
            <Select
              id="ded_month"
              options={monthOptions}
              value={data.deduction_month}
              onChange={(e) => setData({ ...data, deduction_month: e })}
              styles={theme == "light" ? adminStylesLight : adminStyles}
              placeholder="Select Month"
            />
          </div>
        </div>

        <div className="row ">
          <div className="col-sm-12 col-md-4 col-lg-4">
            <label htmlFor="sanction_date">
              LOAN SANCTION DATE
              <span className="asterisk-imp">*</span>
            </label>
            <br />
            <input
              id="sanction_date"
              type="date"
              style={{
                boxShadow: 'none',
              }}
              placeholder="Select Date"
              value={data.sanction_date}
              onChange={(e) => setData({ ...data, sanction_date: e.target.value })}
            />
          </div>
        </div>

        <div className="row pt-6 pb-8">
          <div className="col-sm-12 col-md-4 col-lg-4 area-creation-content__form-submit">
            <button className="button-blue-gradient"
              onClick={() => {
                setShowAssignDialog(true);
              }}
            >
              Assign Loan
            </button>
          </div>
        </div>
      </section>

      <div className={`${showAssignDialog ? "modal-overlay" : "hidden"}`}>
        <div
          className={
            " modal " +
            `${theme == "light" ? " !bg-white" : " !bg-primaryDark2"}`
          }
        >
          <div className="flex items-center justify-between py-10 px-16 border-b-4 border-b-primaryDark">
            <h2 className="text-3xl font-bold">Assign Loan</h2>
            <RxCross1
              size="30"
              onClick={() => {
                setShowAssignDialog(false);
              }}
              className="cursor-pointer text-tertiary"
            />
          </div>
          <div className="py-10 px-16 flex flex-col gap-10">
            <p>
              You are about to assign a loan to an Employee. Once assigned you cannot make <br /> changes to the loan. Are you sure you want to assign the loan ?
            </p>
            <div className="flex items-center gap-10">
              <button
                type="submit"
                className="px-8 py-2 text-white bg-tertiary rounded-xl cursor-pointer"
                onClick={() => {
                  setShowAssignDialog(false);
                  submitHandler();
                }}
              >Yes, Assign</button>
              <span
                className="text-cyan cursor-pointer"
                onClick={() => {
                  setShowAssignDialog(false);
                }}
              >
                Cancel
              </span>
            </div>
          </div>
        </div>
      </div>
      </div>
    </main>
  );
};

export default AssignLoan;