import React ,{useState}from "react";
import PageTitle from "../../../components/PageTitle";
import { useSelector } from "react-redux";
import { useLocation, useNavigate ,Link} from "react-router-dom";
import { getAttendance ,getDaycOunt} from "../../../services/attendance";
import customToast from "../../../components/CustomToast";
const ImportRegular = () => {
  const theme = useSelector((state) => state.theme.theme);
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setdata] = useState([])
  let today=new Date();
  let month=today.getMonth();
  let year=today.getFullYear();
  const handleImportClick =async () => {
    await getAttendance(location.state).then((res)=>customToast.success(res?.data))
    .catch(()=>customToast.error('Something went wrong!'))
    
      navigate(
        "/attendance/upload-attendance/attendance-summary",
        { state:{ view: 1 ,month,year}});
  };

  return (
    <main
      className={
        "main-content w-full" + `${theme == "light" ? " light_bg_main" : ""}`
      }
      
    >
      <div className="creation-content ">
        <PageTitle title="All Regular Attendance Details in your file are ready to be imported" route='../'/>
      </div>

      <div className="m-10 flex flex-col gap-10 text-wheat">
        <p className="text-3xl">
        Regular Attendance that are ready for the import ({location?.state?.length})
        </p>
        <p className="text-3xl">Number of records skipped</p>
        <p className="text-3xl">Unmapped Fields</p>
      </div>

      <div className="m-10 flex items-center gap-20">
        <button className="button-blue-gradient"  
          onClick={handleImportClick}>Import</button>
          <Link to='/attendance/upload-attendance/regular-attendance/regular-map-feilds'>
        <button className="button-red-gradient">Cancel</button>
          </Link>
      </div>
    </main>
  );
};

export default ImportRegular;
