import React, { useEffect } from "react";
import { title, links } from "./navdata";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import SideBar from "../../../components/SideBar/SideBar";

const Taxes = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (pathname == "/settings/taxes") navigate("/settings/taxes/manage-taxes");
  }, [pathname]);

  return (
    <>
      <SideBar title={title} links={links} />

      <Outlet />
    </>
  );
};

export default Taxes;
