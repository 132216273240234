import React, { useEffect, useState, useMemo } from 'react'
import Select from "react-select";
import {
    adminStyles,
    adminStylesLight,
    selectStyles,
} from "../../../utils/selectStyles";
import { generateSelectData } from '../../../utils/serialiseResponse';
import { useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import PageTitle from "../../../components/PageTitle";
import { monthOptions, yearOptions } from '../../../utils/helper';
import { getAtt, getAttDayCount, getAttendance } from '../../../services/attendance';
import Table from '../../../components/Table';
import { useDispatch } from "react-redux";
import { clearEmployees, fetchEmployees } from "../../../reducers/employeesReducer";

import customToast from '../../../components/CustomToast';
const AttendanceSummary = () => {
    const theme = useSelector((state) => state.theme.theme);
    const location = useLocation();
    const dispatch = useDispatch();
    const employees = useSelector(({ employees }) => employees);

    const [table2, setTable2] = useState([]);
    const [month, setmonth] = useState()
    const [year, setyear] = useState()
    const [empl, setEmpl] = useState({ value: 0, label: "All" });
    const [selectedOption, setSelectedOption] = useState("");
    const [data, setData] = useState(null);
    const [table1, setTable1] = useState([]);
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };
    let arr = [];
    useEffect(() => {
        dispatch(fetchEmployees());

        return () => dispatch(clearEmployees());
    }, [dispatch]);
    const emplSelect = useMemo(
        () => generateSelectData(employees.data, "fullName"),
        [employees.data]
    );
    const getAttendance = async () => {
        await getAtt({ month, year, empl }).then((res) => res.data?.length > 0 ? setData(res.data) : customToast.error('Data not found!'));

    }
    const getDayCountAtt = async () => {
        await getAttDayCount({ month, year, empl }).then((res) => res.data?.length > 0 ? setTable2(res.data) : customToast.error('Data not found!'));

    }
    useEffect(() => {
        if (location.state?.view == 2) {
            setSelectedOption("option2")
            setmonth(location.state?.month)
            setyear(location.state?.year)
        }
        else if (location.state?.view == 1) {
            setmonth(location.state?.month)
            setyear(location.state?.year)
            setSelectedOption("option1")
        }
    }, [location.state])
    useEffect(() => {
        if (selectedOption == "option2" && month && year) {
            getDayCountAtt();

        }
        else if (selectedOption == "option1" && month && year) {
            setmonth(4);
            setyear(2024);
            getAttendance();
        }
        else {
            arr = [];
        }
    }, [selectedOption])

    useEffect(() => {

        const attendanceArray =
            data?.map(entry =>
                entry?.attendance?.map(e =>
                    arr.push({
                        id: entry.uid,
                        name: entry.emp_name,
                        date: e.date,
                        intime: e.check_in_time,
                        whours: e.working_hours,
                        outtime: e.check_out_time,
                        wtype: e.w_type,
                    })
                )
            );
        setTable1(arr)
    }, [data])
    const handleChange = () => {

        if (selectedOption == 'option1' && month !== null && year) {
            getAttendance();
        }
        else if (selectedOption == 'option2' && month !== null && year) {
            getDayCountAtt();
        }
        else {
            customToast.error("Please select month and year")
        }
    }

    let columns = useMemo(() => [
        {
            Header: "Employee ID",
            accessor: "id",
            disableFilters: true,
            disableSortBy: true,
        },
        {
            Header: "Employee Name",
            accessor: "name",
            disableFilters: true,
            disableSortBy: true,
        },

        {
            Header: "Date",
            accessor: "date",
            disableFilters: true,
            disableSortBy: true,

        },
        {
            Header: "Check in Time",
            accessor: "intime",
            disableFilters: true,
            disableSortBy: true,
        },
        {
            Header: "Check out Time",
            accessor: "outtime",
            disableFilters: true,
            disableSortBy: true,
        },
        {
            Header: "Working Type",
            accessor: "wtype",
            disableFilters: true,
            disableSortBy: true,
        },
        {
            Header: "Total Hours",
            accessor: "whours",
            disableFilters: true,
            disableSortBy: true,
        },

    ], [table1])
    let columns2 = useMemo(() => [
        {
            Header: "Employee ID",
            accessor: "emp_code",
            disableFilters: true,
            disableSortBy: true,
        },
        {
            Header: "Employee Name",
            accessor: "emp_name",
            disableFilters: true,
            disableSortBy: true,
        },

        {
            Header: "Working Days",
            accessor: "working_day",
            disableFilters: true,
            disableSortBy: true,
        },
        {
            Header: "Unpaid Leave",
            accessor: "unpaid_leave",
            disableFilters: true,
            disableSortBy: true,
        },
        {
            Header: "Leave",
            accessor: "leave",
            disableFilters: true,
            disableSortBy: true,
        },
        {
            Header: "WeeklyOff",
            accessor: "weeklyoff",
            disableFilters: true,
            disableSortBy: true,
        },
        {
            Header: "Absent days",
            accessor: "absent",
            disableFilters: true,
            disableSortBy: true,
        },
    ], [table2])
    return (

        <main
            className={
                "main-content " + `${theme == "light" ? " light_bg_main" : ""}`
            }
            style={{ width: "100%" }}
        >
            <div className="creation-content ">
                <PageTitle title="Attendance Summary" route='../../' />

                <div className='flex cardlink-row gap-28 mb-10 mt-8'>
                    <label className='form-label-div'>
                        <input
                            type="radio"
                            value="option1"
                            checked={selectedOption === "option1"}
                            onChange={(e) => handleOptionChange(e)}
                            className='mr-6 '
                        />
                        Regular Attendance
                    </label>
                    <label className='form-label-div'>
                        <input
                            type="radio"
                            value="option2"
                            checked={selectedOption === "option2"}
                            onChange={(e) => handleOptionChange(e)}
                            className='mr-6'
                        />
                        Day Count Attendance
                    </label>

                </div>
                <div className='row pb-4'>
                    <div >
                        <label className="uppercase form-label-div">Select employee</label>
                        <Select
                            styles={
                                theme == "light" ? adminStylesLight : adminStyles
                            }
                            options={emplSelect}
                            onChange={(e) => setEmpl(e)}

                        />
                    </div >
                    <div >
                        <label className="uppercase form-label-div">Select Month</label>
                        <Select
                            styles={
                                theme == "light" ? adminStylesLight : adminStyles
                            }
                            options={monthOptions}
                            // value={selectOptions[selectedMapping[index]]}
                            onChange={(e) => setmonth(e.value)}
                        />
                    </div >
                    <div >
                        <label className="uppercase form-label-div">Select yEAR</label>
                        <Select
                            styles={
                                theme == "light" ? adminStylesLight : adminStyles
                            }
                            options={yearOptions}
                            onChange={(e) => setyear(e.label)}

                        />
                    </div >
                    <button className='button-blue-gradient h-[5rem] mt-9' onClick={() => { handleChange() }}>Submit</button>
                </div>
                {table2?.length > 0 || table1?.length > 0 ?
                    <section className='cardlink-row pb-6'>
                        {
                            selectedOption == "option2" ?
                                <div className={"filter-table "}>
                                    <Table columns={columns2} data={table2} />
                                </div> :
                                <div className={"filter-table "}>
                                    <Table columns={columns} data={table1} />
                                </div>
                        }
                    </section> : null
                }
            </div>
        </main>
    )
}

export default AttendanceSummary