import { createSlice } from "@reduxjs/toolkit";
import {
  getHrmsSetUpStatus,
  updateHrmsSetUpStatus,
  getCompanySetUpStatus,
} from "../services/hrms";

const hrmsSetUpStatusSlice = createSlice({
  name: "hrmsSetUpStatus",
  initialState: {
    status: "",
  },
  reducers: {
    setHrmsSetUpStatusInfo: (state, action) => {
      state.status = action.payload;
    },
  },
});

const { setHrmsSetUpStatusInfo } = hrmsSetUpStatusSlice.actions;

export const setHrmsSetUpStatus = () => async (dispatch) => {
  try {
    const data = await getHrmsSetUpStatus();
    dispatch(setHrmsSetUpStatusInfo(data[0].status));
  } catch (error) {
  }
};

export const changeHrmsSetUpStatus = (newStatus) => async (dispatch) => {
  try {
    await updateHrmsSetUpStatus(newStatus);
    dispatch(setHrmsSetUpStatusInfo(newStatus.status));
  } catch (error) {
  }
};

export const getCompanySetUpStatusAndUpdateHrmsSetUpStatus =
  () => async (dispatch) => {
    try {
      const newStatus = await getCompanySetUpStatus();
      await updateHrmsSetUpStatus(newStatus);
      dispatch(setHrmsSetUpStatusInfo(newStatus.status));
    } catch (error) {
    }
  };

export default hrmsSetUpStatusSlice.reducer;
