import React, { useState, useEffect } from 'react'
import { CiEdit } from "react-icons/ci";
import { useSelector } from 'react-redux'
import PageTitle from '../../../../components/PageTitle'
import { IoToggle } from "react-icons/io5";
import { PiToggleLeftFill } from "react-icons/pi";
import { getTaxInfo, updateTaxSettings } from '../../../../services/hrms'
import customToast from '../../../../components/CustomToast/index'


const TaxSetting = () => {
  const theme = useSelector((state) => state.theme.theme);
  const [payLoad, setPayLoad] = useState({ regimeType: "not-set", cessStatus: false })

  useEffect(() => {
    getTaxInfo().then((data) => setPayLoad(pre => ({ ...pre, ...data }))
    )
    console.log(payLoad);

  }, [])

  const handleCess = () => {
    setPayLoad(prv => ({ ...prv, cessStatus: !payLoad.cessStatus }))
  }

  const handleSubmit = async () => {
    const res = await updateTaxSettings(payLoad);
    customToast.success(res);

  }
  return (
    <main className="main-content bg-primaryLight2 dark:bg-primaryDark1">
      <div
        className={
          "area-creation-content !pb-5" + `${theme == "light" ? "light_bg" : ""}`
        }
      >
        <PageTitle title="Tax Setting" route={".."} />
        <div>
          <section className={"cardlink-row pb-6"}>
            <div className="flex justify-between item-center" >
              <section>CESS (4%)</section>
              <section className="cursor-pointer" onClick={handleCess}>{payLoad.cessStatus ? <IoToggle color={"#26C05E"} fontSize="30px" /> : <PiToggleLeftFill fontSize="30px" />}</section>
            </div>
          </section>
          <section className={"cardlink-row pb-6"}>
            <div className="flex justify-between item-center" >
              <section>Default Tax Regime</section>
              <SelectTaxSettings extClassName='' initial={payLoad.regimeType} lift={setPayLoad} />
            </div>
          </section>
        </div>
        <button
          onClick={handleSubmit}
          className="button-blue-gradient mx-8"
        >
          Save
        </button>
      </div>
    </main>
  )
}

export default TaxSetting

export const SelectTaxSettings = ({ initial, id, lift }) => {
  // const [regimeTypes,setRegimeTypes] = useState(initial);
  const [isEditMode, setIsEditMode] = useState(false);
  const handleRegimeChange = async (e) => {
    const { value } = e.target
    lift(pre => ({ ...pre, regimeType: value }))

    // setRegimeTypes(value)
    setIsEditMode(false)
  }

  const handleEditMode = () => {
    setIsEditMode(true);
  }
  return (
    <>
      {isEditMode ? <select id={id} className={`!p-0 !py-1 !border !w-fit rounded-lg custom-select`} value={initial} onChange={handleRegimeChange}>
        <option value={"old-regime"}>old-regime</option>
        <option value={"new-reigme"}>new-regime</option>
      </select> : <div className='flex justify-between !text-green-500 font-semibold'><span></span> <span className='capitalize'>{initial.split("-").join(" ")}</span> <CiEdit cursor={"pointer"} color='#c7c4e9' fontSize={"30px"} onClick={handleEditMode} /></div>}
    </>
  )

}