import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import PageTitle from "../../../components/PageTitle";
import Select from "react-select";
import {
  adminStyles,
  adminStylesLight,
  selectStyles,
} from "../../../utils/selectStyles";
import * as xlsx from "xlsx"

const MapFieldsOted = () => {
  const theme = useSelector((state) => state.theme.theme);
  const navigate = useNavigate();
  const location = useLocation();
  const [xlsData, setXlsData] = useState(null); 

 
  useEffect(() => {
    if (location.state?.uploadedFiles) {
      const file = location.state.uploadedFiles[0]; // Assuming uploadedFiles is an array
      if (file instanceof Blob) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = xlsx.read(data, { type: "array" });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const jsonData = xlsx.utils.sheet_to_json(worksheet, { header: 1 });
          setXlsData(jsonData);
        };
        reader.readAsArrayBuffer(file);
      } else {
        console.error("Uploaded file is not a Blob.");
      }
    } else {
      console.error("No uploaded file found in location state.");
    }
  }, [location.state?.uploadedFiles]);

  console.log(xlsData , "xlsData")
  
  return (
    <main
      className={
        "main-content " + `${theme == "light" ? " light_bg_main" : ""}`
      }
      style={{ width: "100%" }}
    >
      <div className="creation-content ">
        <PageTitle title="Map Fields" />
      </div>

      <div className="mr-10 mb-20 mt-10">
        <p>
          <span className="font-medium text-3xl">Selected File</span>
          {location.state?.uploadedFiles.map((file) => (
            <span className="bg-success p-2 bg-opacity-30 ml-10 text-cyan rounded-sm">
              {file.name}
            </span>
          ))}
        </p>
      </div>

      <div className="mr-10 mb-10">
        <table>
          <thead>
            <tr>
              <th className="p-4 text-left mr-10">Payroll Fields</th>
              <th className="p-4 text-left">Import File Fields</th>
            </tr>
          </thead>
          <tbody>
          {xlsData && xlsData[0].map((el) => (
            <tr>
              <td
                className={
                  "p-4 " +
                  `${
                    theme == "light" ? "text-secondaryDark" : "text-secondary"
                  }`
                }
              >
                {el} <span className="text-red-600">*</span>
              </td>
              <td className="text-secondary p-4">
                <Select
                  styles={theme == "light" ? adminStylesLight : adminStyles}
                />
              </td>
            </tr>))}
          </tbody>
        </table>
      </div>

      <div
        className={
          "mb-10 mr-10 flex items-center gap-10 bg-primaryDark2 p-8 " +
          `${theme == "light" && " bg-white "}`
        }
      >
        <input type="checkbox" className="check-box green_b" />
        <label className="text-secondary">
          Save these selection preferences for future imports
        </label>
      </div>

      <div className="mr-10 mb-20 flex items-center gap-20">
        <button
          className="button-green-gradient"
          onClick={() => {
            navigate("/payrun/manage-payrun/payrun-info/create/oted/import");
          }}
        >
          Next
        </button>
        <button className="button-red-gradient">Cancel</button>
      </div>
    </main>
  );
};

export default MapFieldsOted;
