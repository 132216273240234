import React, { useState } from "react";
import DropZone from "../../../../components/DropZone/DropZone";
import { useSelector } from "react-redux";
import PageTitle from "../../../../components/PageTitle";
import { useNavigate } from "react-router-dom";
import LeaveBalanceFormat from "../../../../assets/excelFormats/LeaveBalanceDetail.xlsx";
const UploadLeave = () => {
  const theme = useSelector((state) => state.theme.theme);
  const navigate = useNavigate();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  return (
    <main
      className={
        "main-content " + `${theme == "light" ? " light_bg_main" : ""}`
      }
      style={{ width: "100%" }}
    >
      <div className="creation-content ">
        <PageTitle title="Upload Leave" route='../../' />
      </div>

      <div className="cardlink-row mr-10 mb-5 py-10">
        <p className="text-3xl text-wheat">
          Download a sample <span className="text-tertiary">.csv format</span>{" "}
          or <span className="text-tertiary">.xls format</span> file and compare
          it with your import file to ensure that the file is ready to import.
        </p>
        <a href={LeaveBalanceFormat} download="LeaveBalanceDetail.xlsx" rel="noreferrer">
      <p className="text-green-700 mt-5 underline cursor-pointer">Download Format</p>
      </a>
      </div>

      <DropZone
        uploadedFiles={uploadedFiles}
        setUploadedFiles={setUploadedFiles}
      />

      <div className="mr-10 flex items-center  p-8 gap-20">
        <button
          className="button-green-gradient"
          onClick={() => {
            if (uploadedFiles.length === 0) return;
            navigate("leave-map-feilds", {
              state: {
                uploadedFiles: uploadedFiles,
              },
            });
          }}
        >
          Next
        </button>
        <button className="button-red-gradient" onClick={()=>setUploadedFiles([])}>Cancel</button>
      </div>
    </main>
  );
};

export default UploadLeave;
