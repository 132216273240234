import React, { useEffect, useState } from "react";
import "../WarningModal/warningModal.css";
import { IoIosWarning } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const NavigationModal = ({ isOpen, message, onClose, onConfirm }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  if (!isModalOpen) return null;

  return (
    <div className="warning-modal-overlay">
      <div className="warning-modal-content bg-primaryLight text-white px-40 py-4 border-2">
        <div className="flex justify-center text-8xl">
          {" "}
          <IoIosWarning className="warning-logo" />
        </div>
        <h2>Warning</h2>
        <p>{message}</p>
        <button
          className="border-2 border-green-500 px-4 py-2 rounded-lg hover:bg-green-400 hover:border-white"
          onClick={onConfirm}
        >
          {message?.toLowerCase()?.includes("delete") ? "Delete" : "Add"}
        </button>
        <button
          className="border-2 border-red-500 px-4 py-2 rounded-lg hover:bg-red-400 hover:border-white"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default NavigationModal;
