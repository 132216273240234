export const title = "Onboarding";

export const links = [
  {
    name: "Division",
    route: "division",
    accessor: "division",
  },
  {
    name: "Onboarding Process",
    route: "process",
    accessor: "process",
  },
  {
    name: "Initiation",
    route: "initiation",
    accessor: "initiation",
  },
  {
    name: "History",
    route: "history",
    accessor: "history",
  },
];


