import { createSlice } from "@reduxjs/toolkit";
import { createDepartment, editDepartment, getDepartments, deleteDepartment  } from "../services/metaData";

const departmentSlice = createSlice({
  name: "departments",
  initialState: {
    data: [],
    status: "idle",
    error: null,
  },
  reducers: {
    setDepart: (state, action) => {
      state.data = action.payload.data;
    },
    setStatus: (state, action) => {
      state.status = action.payload.status;
      state.error = action.payload.error || null;
    },
  },
});

export const { setDepart, setStatus } = departmentSlice.actions;

export const setDepartments = () => async (dispatch) => {
  dispatch(setStatus({ status: "loading", error: null }));
  try {
    const { data } = await getDepartments();
    dispatch(setDepart({ data }));
    dispatch(setStatus({ status: "idle", error: null }));
  } catch (err) {
    dispatch(setStatus({ status: "failed", error: err?.message }));
  }
};
export const createDepartments = (details) => async (dispatch) =>{
  dispatch(setStatus({ status: "loading", error: null }));
  try {
    const res = await createDepartment(details);
    const {data} = await getDepartments();
    dispatch(setDepart( {data} ));
    // dispatch(setDepart({ data }));
    dispatch(setStatus({ status: "idle", error: null }));
  } catch (err) {
    
    dispatch(setStatus({ status: "failed", error: err?.message }));
  }
}
export const deleteDepartemts = (id) => async (dispatch) =>{
  dispatch(setStatus({ status: "loading", error: null }));
  try {
    const res = await deleteDepartment(id);
    const {data} = await getDepartments();
    dispatch(setDepart( {data} ));
    dispatch(setStatus({ status: "idle", error: null }));
  } catch (err) {
    dispatch(setStatus({ status: "failed", error: err?.message }));
  }
}
export const editDepartments = (id, payload) => async (dispatch) =>{
  dispatch(setStatus({ status: "loading", error: null }));
  try {
    const { data } = await editDepartment(id, payload);
    dispatch(setDepart({ data }));
    dispatch(setStatus({ status: "idle", error: null }));
  } catch (err) {
    dispatch(setStatus({ status: "failed", error: err?.message }));
  }
}
export default departmentSlice.reducer;
