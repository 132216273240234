import React, { useEffect, useRef, useState } from 'react'
import { HiPencil } from 'react-icons/hi';

const Dropdown = ({ icon, options }) => {
    const [showOptions, setShowOptions] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowOptions(false);
            }
        };
        window.addEventListener("click", handleClickOutside);
        return () => {
            window.removeEventListener("click", handleClickOutside);
        };
    }, []);

    return (
        <>
            <span
                className="react-table-view-link"
                onClick={(e) => {
                    e.stopPropagation();
                    setShowOptions(!showOptions)
                }}
            >
                {icon}
                {/* <HiPencil className='text-[#36ABF9] cursor-pointer text-3xl' /> */}
            </span>
            <div
                ref={dropdownRef}
                className={` ${showOptions
                    ? "flex flex-col p-4 rounded-md gap-2 absolute right-10 bg-primaryDark z-10  w-96  h-auto overflow-y-scroll"
                    : "hidden"
                    } `}
            >
                {options.map((option, index) => (
                    <span
                        key={index}
                        className="hover:bg-primaryDark2 rounded-md p-4 cursor-pointer"
                        onClick={()=>{
                            option.onClick();
                            setShowOptions(false);
                        }}
                    >
                        {option.text}
                    </span>
                ))}
                {/* <span className="hover:bg-primaryDark2 rounded-md p-4 cursor-pointer" >{option}</span> */}
            </div>
        </>
    )
}

export default Dropdown


