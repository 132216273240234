import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts";
import Dashboard_stat_card from "../../components/Dashboard_stat_card/Dashboard_stat_card";
import EventReminderCard from "../../components/EventReminderCard/EventReminderCard";
import { fetchBirthDays } from "../../reducers/Dashboard/birthDayReducer";
import { fetchEmployeesWithAnniversary } from "../../reducers/Dashboard/aniversaryReducer";
import ComingSoon from "../../components/ComingSoon";
const chartSetting = {
  yAxis: [
    {
      label: "Salary Statistics",
    },
  ],
  width: 1000,
  height: 400,
  sx: {
    [`.${axisClasses.left} .${axisClasses.label}`]: {
      transform: "translate(-20px, 0)",
    },
  },
};

const dataset = [
  {
    gross: 59,
    ctc: 57,
    net_salary: 86,
    month: "Jan",
  },
  {
    gross: 50,
    ctc: 52,
    net_salary: 78,
    month: "Fev",
  },
  {
    gross: 47,
    ctc: 53,
    net_salary: 106,
    month: "Mar",
  },
  {
    gross: 54,
    ctc: 56,
    net_salary: 92,
    month: "Apr",
  },
  {
    gross: 57,
    ctc: 69,
    net_salary: 92,
    month: "May",
  },
  {
    gross: 60,
    ctc: 63,
    net_salary: 103,

    month: "June",
  },
  {
    gross: 59,
    ctc: 60,
    net_salary: 105,

    month: "July",
  },
  {
    gross: 65,
    ctc: 60,
    net_salary: 106,

    month: "Aug",
  },
  {
    gross: 51,
    ctc: 51,
    net_salary: 95,

    month: "Sept",
  },
  {
    gross: 60,
    ctc: 65,
    net_salary: 97,
    month: "Oct",
  },
  {
    gross: 67,
    ctc: 64,
    net_salary: 76,
    month: "Nov",
  },
  {
    gross: 61,
    ctc: 70,
    net_salary: 103,
    month: "Dec",
  },
];

const valueFormatter = (value) => `${value}mm`;

const Dashboard = () => {
  const dispatch = useDispatch();

  const [attendance, setAttendance] = useState(0);
  const [numberOfEmployees, setNumberOfEmployees] = useState(40);
  const [eps, setEps] = useState(40);
  const [epf, setEpf] = useState(23);
  const [pt, setPt] = useState(0);
  const [gross, setGross] = useState(0);

  const [reminders, setReminders] = useState([]);
  const [birthdays, setBirthdays] = useState([]);
  const [marriageAnniversary, setMarriageAnniversary] = useState([]);

  const theme = useSelector((state) => state.theme.theme);

  const birthdaysData = useSelector((state) => state.birthDays?.data);

  const employeesWithAnniversary = useSelector(
    (state) => state.aniversaries?.data,
  );

  useEffect(() => {
    dispatch(fetchBirthDays());
    dispatch(fetchEmployeesWithAnniversary());
  }, [dispatch]);

  useEffect(() => {
    if (birthdaysData?.length > 0) {
      setBirthdays(birthdaysData);
    }
    if (employeesWithAnniversary?.length > 0) {
      setMarriageAnniversary(employeesWithAnniversary);
    }
  }, [birthdaysData, employeesWithAnniversary]);

  return (
    <div className="main-content relative pl-10 bg-primaryLight2 dark:bg-primaryDark1">
      <ComingSoon />
      <div
        className={
          " flex items-center content-center area-creation-content rounded-md " +
          `${theme == "light" ? "light_bg" : ""}`
        }
      >
        <BarChart
          dataset={dataset}
          xAxis={[{ scaleType: "band", dataKey: "month" }]}
          series={[
            { dataKey: "gross", label: "GROSS", valueFormatter },
            { dataKey: "ctc", label: "CTC", valueFormatter },
            { dataKey: "net_salary", label: "NET_SALARY", valueFormatter },
          ]}
          {...chartSetting}
          colors={["#0092DA", "#00BF9A  ", "#C16E30"]}
          labelStyle={{ fill: "#FFFFFF" }}
          className="fill-white"
          sx={
            theme == "dark"
              ? {
                  ".css-1k2u9zb-MuiChartsAxis-root .MuiChartsAxis-line ": {
                    stroke: "#FFFFFF",
                  },
                  tspan: {
                    fill: "#FFFFFF",
                    fontWeight: "bold",
                  },
                }
              : {}
          }
        />
      </div>

      <div className="mt-6 mr-8 grid coming-soon-text grid-cols-3 grid-rows-2 gap-x-6 gap-y-10">
        <Dashboard_stat_card
          value={attendance}
          className="bg-sky-400"
          title="Attendance"
        />
        <Dashboard_stat_card
          value={numberOfEmployees}
          className="bg-emerald-400"
          title="No of Employees"
        />
        <Dashboard_stat_card
          value={eps}
          className=" bg-amber-400 "
          title="EPS"
        />
        <Dashboard_stat_card
          value={epf}
          className=" bg-amber-400 "
          title="EPF"
        />
        <Dashboard_stat_card value={pt} className="bg-sky-400" title="PT" />
        <Dashboard_stat_card
          value={gross}
          className="bg-emerald-400"
          title="GROSS"
        />
      </div>

      <div className=" mb-10 mt-20 mr-8 grid grid-cols-3 grid-rows-1 gap-x-6 ">
        <EventReminderCard
          title="REMINDERS"
          no_msg="No Reminders For Today"
          arr={reminders}
          type="reminder"
        />

        <EventReminderCard
          title="BIRTHDAYS"
          no_msg="No Birthdays This Week"
          arr={birthdays}
          type="birthday"
        />

        <EventReminderCard
          title="MARRIAGE ANNIVERSARIES"
          no_msg="No Marriage Anniversaries This Week"
          arr={marriageAnniversary}
          type="marriageAnniversary"
        />
      </div>
    </div>
  );
};

export default Dashboard;
