import { createSlice } from "@reduxjs/toolkit";
import {
  getHrmsSetUpStatus,
  updateHrmsSetUpStatus,
  getCompanySetUpStatus,
} from "../services/hrms";
import { getPayrunApproveStatus, updatePayrunApproveStatus, updatePayrunMonth } from "../services/payRun";

const payrunApproveStatusSlice = createSlice({
  name: "payrunApproveStatus",
  initialState: {
    status: "",
    month: 0,
    year: "",
  },
  reducers: {
    setPayrunApproveStatus: (state, action) => {
      state.status = action.payload;
    },
    setPayrunMonth: (state, action) => {
      state.month = action.payload;
    },
    setPayrunYear: (state, action) => {
      state.year = action.payload;
    },
  },
});

const { setPayrunApproveStatus , setPayrunMonth , setPayrunYear} =   payrunApproveStatusSlice.actions;

export const changePayrunApproveStatus = (newStatus) => async (dispatch) => {
  try {
    const status =  await updatePayrunApproveStatus(newStatus);
    dispatch(setPayrunApproveStatus(newStatus.approveStatus));
  } catch (error) {
    console.log(error)
  }
};

export const fetchPayrunApproveStatus = () => async (dispatch) => {
    try {
      const {data} = await getPayrunApproveStatus();
      dispatch(setPayrunApproveStatus(data.approveStatus));
      dispatch(setPayrunMonth(data.month));
      dispatch(setPayrunYear(data.year));
    } catch (error) {
      console.log(error)
    }
};

export const changePayrunMonth = (index) => async (dispatch) => {
    try {
        const {data} = await updatePayrunMonth(index);
        dispatch(setPayrunMonth(data.month));
      } catch (error) {
        console.log(error)
      }
};



export default payrunApproveStatusSlice.reducer;
