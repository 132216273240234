import React ,{useState}from "react";
import PageTitle from "../../../components/PageTitle";
import { useSelector } from "react-redux";
import { useLocation, useNavigate ,Link} from "react-router-dom";
import { importBalance } from "../../../services/leave";
import customToast from '../../../components/CustomToast'
const ImportLeave = () => {
  const theme = useSelector((state) => state.theme.theme);
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setdata] = useState([])
  // let month = 4, year = 2024;
  const handleImportClick =async () => {
    await importBalance(location.state).then((res)=>customToast.success(res.data?.msg))
    .catch(()=>customToast.error('Something went wrong!'))
  };

  return (
    <main
      className={
        "main-content w-full" + `${theme == "light" ? " light_bg_main" : ""}`
      }
      
    >
      <div className="creation-content ">
        <PageTitle title="All leave balance in your file are ready to be imported" route='../'/>
      </div>

      <div className="m-10 flex flex-col gap-10 text-wheat">
        <p className="text-3xl">
        Leave balance that are ready for the import ({location?.state?.length})
        </p>
        <p className="text-3xl">Number of records skipped</p>
        <p className="text-3xl">Unmapped Fields</p>
      </div>

      <div className="m-10 flex items-center gap-20">
        <button className="button-blue-gradient"  
          onClick={handleImportClick}>Import</button>
          <Link to='/attendance/manage-leave/upload-leave/leave-map-feilds'>
        <button className="button-red-gradient">Cancel</button>
          </Link>
      </div>
    </main>
  );
};

export default ImportLeave;
