export const formateAttendanceByTransformedData = (arr)=>{    
    const map = new Map();
    arr.forEach((data)=>{

        if(data?.EmployeeUID){
            if(!map.has(data?.EmployeeUID)){
            const obj = {
                uid:data?.EmployeeUID,
                month:Number(data?.Date?.split("-")[1])-1,
                year:data?.Date?.split("-")[0],
                name:data?.EmployeeName,
                totalWorkingDays:0,
                attendances:[
                    {
                        date:data?.Date,
                        initialStatus:"",
                        status:data?.Attendance?.toLowerCase()
                    }
                ]
            }
            if(data?.Attendance?.toLowerCase() !== "sunday" && data?.Attendance?.toLowerCase() !== "holiday" )
                obj.totalWorkingDays++
            map.set(data?.EmployeeUID,obj)
        }
        else{
            const getObj = map.get(data?.EmployeeUID)
                getObj?.attendances.push({
                        date:data?.Date,
                        initialStatus:"",
                        status:data?.Attendance?.toLowerCase()
                    })
                    if(data?.Attendance?.toLowerCase() !== "sunday" && data?.Attendance?.toLowerCase() !== "holiday" )
                        getObj.totalWorkingDays++
            map.set(data?.EmployeeUID,getObj)

        }
        }
    })

    return Array.from(map.values());
    
}