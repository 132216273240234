import React from 'react'
import PageTitle from '../../../../components/PageTitle'
import Table from '../../../../components/Table';
import { FaPen } from "react-icons/fa";
import { adminStyles, adminStylesLight } from '../../../../utils/selectStyles';
import { useMemo } from 'react'
import { useEffect, useState } from 'react'
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux'
import { clearEmployees, fetchEmployees } from '../../../../reducers/employeesReducer';
import EmployeeInfoInvestment from '../EmployeeInfoInvestment'
import EmployeeInfoLoan from '../EmployeeInfoLoan'
import { generateSelectData } from '../../../../utils/serialiseResponse'
import Tabs from '../../../../components/Tabs'
import ComingSoon from '../../../../components/ComingSoon';
const EmployeeInfoFinance = () => {
    const theme = useSelector(({ theme }) => theme);
    const [isActive, setIsActive] = useState(0);

    const tabs = ["Investment", "Loan/Advance"];
    const tabComponents = [<EmployeeInfoInvestment />, <EmployeeInfoLoan />]

    const employees = useSelector(({ employees }) => employees);
    const [selectEmpl, setSelectEmpl] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchEmployees({}));

        return () => dispatch(clearEmployees());
    }, [dispatch]);


    const emplSelect = useMemo(
        () => generateSelectData(employees.data, "fullName"),
        [employees.data]
    );

    return (
        <div className="main-content relative bg-primaryLight2 dark:bg-primaryDark1">
            <ComingSoon/>
            <div className="area-creation-content coming-soon-text">
                <PageTitle title="Employee Finance" route={'/employee/manage/create'} />
                <div className='bg-primaryLight2 dark:bg-primaryDark1 dark:text-secondaryDark'>
                    <div className="flex flex-row gap-x-40">
                        <div className="row px-0">
                            <div className="col-sm-12 col-md-4 col-lg-4 ">
                                <label htmlFor="state">SELECT EMPLOYEE</label>
                                <br />
                                <Select
                                    className="w-full"
                                    styles={theme == "light" ? adminStylesLight : adminStyles}
                                    options={emplSelect}
                                    onChange={(el) => setSelectEmpl(el)}
                                    placeholder="Select Employee"
                                />
                            </div>
                        </div>
                    </div>

                    <div>
                        <Tabs
                            width={"w-60"}
                            height={"h-16"}
                            textSize={"text-xl"}
                            tabs={[
                                {
                                    name: "Investment",
                                    content: <EmployeeInfoInvestment />
                                },
                                {
                                    name: "Loan/Advance",
                                    content: <EmployeeInfoLoan />
                                }
                            ]} />
                    </div>

                </div>
            </div>
        </div>
    )
}

export default EmployeeInfoFinance