export const title = "Manage Salary Components"

export const links = [
    {
        name:"Earnings",
        route:'earnings'
    },
    {
        name:"Deduction",
        route:'deduction'
    },
    {
        name:"Reimbursement",
        route:'reimbursement'
    }
]