import {useEffect, useMemo, useState } from "react";
import PageTitle from "../../../../components/PageTitle";
import { adminStyles} from "../../../../utils/selectStyles";
import Select from "react-select";
import { useSelector } from "react-redux";
import { FaRegCircle } from "react-icons/fa";
import { GrRadialSelected } from "react-icons/gr";
import { GetEarningTypes, PostEarning, UpdateEarning } from "../../../../services/earning";
import {MdOutlineCheckBoxOutlineBlank} from 'react-icons/md' ;
import { IoCheckbox } from "react-icons/io5";
import customToast from "../../../../components/CustomToast";
import { generateSelectData } from "../../../../utils/serialiseResponse";
import { useLocation, useNavigate } from "react-router-dom";
const EditEarnings = () => {

  const navigate = useNavigate();
  const location = useLocation(); 
  const [eType , setEtype] = useState(null);
  const [eName , setEname] = useState("");
  const [nPayslip , setnPayslip] = useState("");
  const [amount , setAmount] = useState("");
  const [paytype, setPaytype] = useState("fixed");
  const [calcType, setCalcType] = useState({type : "percentage" , value : "ctc"});
  const [taxable, setTaxable] = useState(false);
  const [proRata, setProRata] = useState(false);
  const [addToSalary, setAddToSalary] = useState(false);
  const [isEpf , setIsEpf] = useState(false);
  const [isEsi, setIsEsi] = useState(false);
  const [onPaySlip, setOnPaySlip] = useState(false);
  const [active, setActive] = useState(false);
  const [flexible, setFlexible] = useState(false);
  const [earnData, setEarnData] = useState([]);
  const [taxDeductionType , setTaxDeductionType] = useState("financialYear");
  const theme = useSelector((state) => state.theme.theme);
  
  const earnSelect = useMemo(()=>generateSelectData(earnData, "name"),[earnData]);

  useEffect(()=>{
    GetEarningTypes()
    .then(({data})=> setEarnData(data))
    .catch((err)=> customToast.error("Sorry Something Went Wrong!"));
  },[]);

  const defEtype = earnSelect?.find(el => el?.name === location?.state?.earningType?.name);

  useEffect(() => {
    setActive(location?.state?.active);
    setFlexible(location?.state?.flexible);
    setIsEpf(location?.state?.isEpf);
    setIsEsi(location?.state?.isEsi);
    setProRata(location?.state?.proRata);
    setOnPaySlip(location?.state?.onPaySlip);
    setAddToSalary(location?.state?.addToSalary);
    setTaxable(location?.state?.taxable);
    setCalcType(location?.state?.calcType);
    setPaytype(location?.state?.paytype);
    setAmount(location?.state?.amount);
    setEname(location?.state?.eName);
    setnPayslip(location?.state?.nPayslip);
    setEtype(defEtype || location?.state?.earningType);
  },[location, earnSelect]);

  const handleSubmit = async(e)=>{
    e.preventDefault();
    const payload = {
      active,
      flexible: flexible ? true : false,
      isEpf,
      isEsi,
      proRata,
      onPaySlip,
      addToSalary,
      taxable,
      calcType,
      paytype,
      amount,
      eName,
      nPayslip,
      earningType: eType?._id,
      earningTypeName: eType?.name,
    }
    if(paytype == "variable" && taxDeductionType){
      payload.taxDeductionType = taxDeductionType
    };

    let id = location?.state?._id;
    UpdateEarning(id , payload)
    .then(()=> {
      navigate("..");
      customToast.success("Earning updated Successfully")})
    .catch((err)=>{console.log(err) ;
      customToast.error("Sorry Something Went Wrong!")})
  }


  const handleEarning = (e) => {
     if(e.name === 'Basic'){
      setCalcType({type : "percentage" , value : "ctc"}),
      setTaxable(true)
      setProRata(true)
      setAddToSalary(true)
      setIsEpf(true)
      setIsEsi(true)
      setOnPaySlip(true)
      setActive(true)
      setFlexible(true)
     }
    else if(e.name == 'HRA'){
      setCalcType({type : "percentage" , value : "basic"}),
      setTaxable(true);
      setProRata(false);
      setAddToSalary(true);
      setIsEpf(false);
      setIsEsi(false);
      setOnPaySlip(false);
      setActive(true);
      setFlexible(false);
     }
    else if(e.name == 'Fixed Allowance'){
      setCalcType({type : "SystemGenerated" , value : "SystemGenerated"}),
      setTaxable(true);
      setProRata(true);
      setAddToSalary(true);
      setIsEpf(false);
      setIsEsi(true);
      setOnPaySlip(true);
      setActive(true);
      setFlexible(true);
    }
     else{
      setTaxable(false);
      setProRata(false);
      setAddToSalary(false);
      setIsEpf(false);
      setIsEsi(false);
      setOnPaySlip(false);
      setActive(false);
      setFlexible(false);
    }
  }

  return (

    <div
      className={"main-content " + `${theme == "light" ? "light_bg_main" : ""}`}
      style={{ width: "100%" }}
    >
      <div
        className={
          "creation-content " + `${theme == "light" ? "light_bg" : ""}`
        }
      >
        <PageTitle title="Edit Earning" route={'..'} />

        <section className="admin-content pb-10">
          <div className="row">
            <div>
              <label>Earning Type<span className="text-error">*</span></label>
              <br />
              <Select
                placeholder={location?.state?.earningType?.name}
                options={earnSelect}
                styles={adminStyles}
                value={eType}
                onChange={(e) => { handleEarning({ ...e }); setEtype({ ...e }) }}
                className="mt-4"
                defaultValue={defEtype}
              />
            </div>

            <div>
              <label>Earning Name <span className="text-error">*</span></label><br />
              <input
                id="eName"
                type="text"
                placeholder="Enter Earning Name"
                className="dark:bg-primary dark:text-secondary outline-none border-none rounded-lg mt-4"
                value={eName}
                onChange={({ target }) => setEname(target?.value)}
              />
            </div>

            <div>
              <label>Name on Payslip <span className="text-error">*</span></label>
              <br />
              <input
                id="eName"
                type="text"
                placeholder="Enter Name on Payslip"
                className="dark:bg-primary dark:text-secondary outline-none border-none rounded-lg mt-4"
                value={nPayslip}
                onChange={({ target }) => setnPayslip(target?.value)}
              />
            </div>
          </div>

          <div className="mx-8">
            {eType?.name !== "Fixed Allowance" ?
              <>
                <div className="mt-8">
                  <label>Pay Type <span className="text-error">*</span></label>
                  <div className="flex justify-start gap-8 items-center mt-2">
                    <div>
                      {paytype === 'variable'
                        ? <FaRegCircle
                          className="text-tertiary inline-block cursor-pointer"
                          onClick={() => setPaytype("fixed")}
                        />
                        : <GrRadialSelected
                          className="text-tertiary inline-block"
                        />
                      }
                      <label className="ml-3">Fixed Amount</label><br />
                      <span className="text-secondary text-sm">(Fixed amount paid at the month end)</span>
                    </div>
                    <div>
                      {paytype === 'fixed'
                        ? <FaRegCircle
                          className="text-tertiary inline-block cursor-pointer"
                          onClick={() => { setPaytype("variable"); setCalcType(calcType); setAmount(0) }}
                        />
                        : <GrRadialSelected
                          className="text-tertiary inline-block"
                        />
                      }
                      <label className="ml-3">Variable Amount</label> <br />
                      <span className="text-secondary text-sm">(Variable amount paid during payroll)</span>
                    </div>
                  </div>
                </div>

                <div className="mt-8 ">
                  {paytype == 'variable' ?
                    <>
                      <label>Tax Deduction Type<span className="text-error">*</span></label>
                      <div className="flex justify-start gap-8 items-center mt-2">
                        <div>
                          {taxDeductionType === 'month'
                            ? <FaRegCircle
                              className="text-tertiary inline-block cursor-pointer"
                              onClick={() => setTaxDeductionType("financialYear")}
                            />
                            : <GrRadialSelected
                              className="text-tertiary inline-block"
                            />
                          }
                          <label className="ml-3">Deduct Tax In Following Financial Year</label><br />
                        </div>
                        <div>
                          {taxDeductionType === 'financialYear'
                            ? <FaRegCircle
                              className="text-tertiary inline-block cursor-pointer"
                              onClick={() => setTaxDeductionType("month")}
                            />
                            : <GrRadialSelected
                              className="text-tertiary inline-block"
                            />
                          }
                          <label className="ml-3">Deduct In The Same Month</label> <br />
                        </div>
                      </div>
                    </>
                    :
                    <>
                      <label>Calculation Type<span className="text-error">*</span></label>
                      <div className="flex justify-start gap-8 items-center mt-2">
                        <div>
                          {calcType?.type === 'percentage'
                            ? <FaRegCircle
                              className="text-tertiary inline-block cursor-pointer"
                              onClick={() => setCalcType({ type: "fixed", value: "fixed" })}
                            />
                            : <GrRadialSelected
                              className="text-tertiary inline-block"
                            />
                          }
                          <label className="ml-3">Flat Amount</label><br />
                        </div>
                        <div>
                          {calcType?.type === 'fixed'
                            ? <FaRegCircle
                              className="text-tertiary inline-block cursor-pointer"
                              onClick={() => { eType?.name == 'Basic' ? setCalcType({ type: "percentage", value: "ctc" }) : setCalcType({ type: "percentage", value: "basic" }) }}
                            />
                            : <GrRadialSelected
                              className="text-tertiary inline-block"
                            />
                          }
                          {eType?.name == 'Basic' ? <label className="ml-3">Percentage of CTC</label> : <label className="ml-3">Percentage of Basic</label>} <br />
                        </div>
                      </div>
                    </>
                  }
                </div>
                {paytype == 'variable' ?
                  null :
                  <div className="mt-6 w- w-96">
                    <label className="text-3xl uppercase ml-1">{calcType === 'percentage' ? "Percentage %" : "Amount"}<span className="text-error">*</span></label><br />
                    <input
                      id="eName"
                      type="number"
                      placeholder={calcType?.type === 'percentage' ? "Enter Percentage %" : "Enter Amount"}
                      className="dark:bg-primary dark:text-secondary outline-none border-none !border-0 px-6 py-3 rounded-lg font-semibold mt-2"
                      value={amount}
                      onChange={({ target }) => setAmount(target?.value)}
                    />
                  </div>
                }
              </>
              :
              null
            }

            <div className="mt-10">
              <label className="show-entries">Other Configurations</label>
              <br />
                <div className="flex flex-col gap-1 mt-4">
                  <label className="text-3xl font-medium">
                    {addToSalary
                      ? <IoCheckbox
                        className="text-success inline-block text-4xl me-4 cursor-pointer"
                      />
                      :
                      <MdOutlineCheckBoxOutlineBlank
                        className="text-success inline-block text-4xl me-4 cursor-pointer"
                        onClick={() => setAddToSalary(true)}
                      />
                    }
                    Add to Employee's Salary Structure
                  </label>

                  {paytype == 'variable' ?
                    null :
                    <div className="flex flex-col gap-4 mb-4">
                      <div className="text-3xl font-medium ">
                        {taxable
                          ? <IoCheckbox
                            className="text-success inline-block text-4xl me-4 cursor-pointer"
                          />
                          :
                          <MdOutlineCheckBoxOutlineBlank
                            className="text-success inline-block text-4xl me-4 cursor-pointer"
                            onClick={() => setTaxable(true)}
                          />
                        }

                        <label>Taxable Earning</label><br />
                        <div className="text-secondary text-sm">( The Income Tax amount will be divided equally and deducted every month across Financial year )</div>
                      </div>
                      <div className="text-3xl font-medium ">
                        {proRata
                          ? <IoCheckbox
                            className="text-success inline-block text-4xl me-4 cursor-pointer"
                            onClick={() => setProRata(false)}
                          />
                          :
                          <MdOutlineCheckBoxOutlineBlank
                            className="text-success inline-block text-4xl me-4 cursor-pointer"
                            onClick={() => setProRata(true)}
                          />
                        }
                        <label>Calculate on Pro-Rata Basis</label><br />
                        <div className="text-secondary text-sm ">( Pay will be adjusted based on employees working days  )</div>
                      </div>
                    </div>
                  }

                  <label className="text-3xl font-medium ">
                    {flexible
                      ? <IoCheckbox
                        className="text-success inline-block text-4xl me-4 cursor-pointer"
                        onClick={() => setFlexible(false)}
                      />
                      :
                      <MdOutlineCheckBoxOutlineBlank
                        className="text-success inline-block text-4xl me-4 cursor-pointer"
                        onClick={() => setFlexible(true)}
                      />
                    }
                    Add as a Flexible Benefit Plan Component
                  </label>

                  <label className="text-3xl font-medium ">
                    {isEpf
                      ? <IoCheckbox
                        className="text-success inline-block text-4xl me-4 cursor-pointer"
                        onClick={() => setIsEpf(false)}
                      />
                      :
                      <MdOutlineCheckBoxOutlineBlank
                        className="text-success inline-block text-4xl me-4 cursor-pointer"
                        onClick={() => setIsEpf(true)}
                      />
                    }
                    Consider for EPF Calculation
                  </label>

                  <label className="text-3xl font-medium ">
                    {isEsi
                      ? <IoCheckbox
                        className="text-success inline-block text-4xl me-4 cursor-pointer"
                        onClick={() => setIsEsi(false)}
                      />
                      : <MdOutlineCheckBoxOutlineBlank
                        className="text-success inline-block text-4xl me-4 cursor-pointer"
                        onClick={() => setIsEsi(true)}
                      />
                    }
                    Consider for ESI Calculation
                  </label>

                  <label className="text-3xl font-medium ">
                    {onPaySlip
                      ? <IoCheckbox
                        className="text-success inline-block text-4xl me-4 cursor-pointer"
                        onClick={() => setOnPaySlip(false)}
                      />
                      :
                      <MdOutlineCheckBoxOutlineBlank
                        className="text-success inline-block text-4xl me-4 cursor-pointer"
                        onClick={() => setOnPaySlip(true)}
                      />
                    }
                    Show this Component on Payslip
                  </label>

                  <label className="text-3xl font-medium ">
                    {active
                      ? <IoCheckbox
                        className="text-success inline-block text-4xl me-4 cursor-pointer"
                        onClick={() => setActive(false)}
                      />
                      :
                      <MdOutlineCheckBoxOutlineBlank
                        className="text-tertiary inline-block text-4xl me-4 cursor-pointer"
                        onClick={() => setActive(true)}
                      />
                    }
                    Mark this as Active
                  </label>
                </div>
            </div>
          </div>

          <button
            onClick={handleSubmit}
            className="button_blue_gradient mx-8 mt-10"
          >
            Update
          </button>
        </section >
      </div >
    </div >

);
};

export default EditEarnings;