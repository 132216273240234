import React,{useState} from "react";
import PageTitle from "../../../components/PageTitle";
import { useSelector } from "react-redux";
import { useLocation, useNavigate,Link } from "react-router-dom";
import { getDaycOunt } from "../../../services/attendance";
import customToast from "../../../components/CustomToast";
const ImportDayCount = () => {
  const theme = useSelector((state) => state.theme.theme);
  const location = useLocation();
  const navigate = useNavigate();
const [data, setdata] = useState(null);
let month=location.state.month;
let year=location.state.year
  const handleImportClick =async () => {
    await getDaycOunt(location.state).then((res)=>customToast.success(res?.data))
    .catch(()=>customToast.error('Something went wrong!'))
    
  
      navigate(
      "/attendance/upload-attendance/attendance-summary",
      { state:{ view: 2 ,month,year}}
    )
  
  };
  // console.log(data)
  return (
    <main
      className={
        "main-content " + `${theme == "light" ? " light_bg_main" : ""}`
      }
      style={{ width: "100%" }}
    >
      <div className="creation-content ">
        <PageTitle title="All Day Count Attendance Details in your file are ready to be imported" route='../' />
      </div>

      <div className="m-10 flex flex-col gap-10 text-wheat">
        <p className="text-3xl">
        Day Count Attendance that are ready for the import ({location.state?.data?.length})
        </p>
        <p className="text-3xl">Number of records skipped</p>
        <p className="text-3xl">Unmapped Fields</p>
      </div>

      <div className="m-10 flex items-center gap-20">
        <button className="button-blue-gradient"  
          onClick={handleImportClick}>Import</button>
          <Link to='/attendance/upload-attendance/regular-attendance/regular-map-feilds'>
        <button className="button-red-gradient" >Cancel</button>
          </Link>
      </div>
    </main>
  );
};

export default ImportDayCount;
