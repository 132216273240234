import { createSlice } from "@reduxjs/toolkit";
import { fetchPt } from "../services/salaryTempelates";

const professionalTaxDeailsReducer = createSlice({
  name: "professionalTaxDeailsReducer",
  initialState: {
    data: [],
  },
  reducers: {
    setProfessionalTaxDeails: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setProfessionalTaxDeails } =
  professionalTaxDeailsReducer.actions;

export const fetchProfessionalTaxDeails = (state, userId) => async (dispatch) => {
  try {
    const response = await fetchPt(state , userId);
    dispatch(setProfessionalTaxDeails(response));
  } catch (error) {
    console.log(error);
  }
};

export default professionalTaxDeailsReducer.reducer;
