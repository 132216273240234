import React from "react";
import PageTitle from "../../../components/PageTitle";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const ImportLop = () => {
  const theme = useSelector((state) => state.theme.theme);
  const location = useLocation();
  const navigate = useNavigate();

  const handleImportClick = () => {
    console.log("Navigating with state:", location.state);
    navigate(
      "/payrun/manage-payrun/payrun-info/create",
      { state: location.state }
    );
  };

  return (
    <main
      className={
        "main-content " + `${theme == "light" ? " light_bg_main" : ""}`
      }
      style={{ width: "100%" }}
    >
      <div className="creation-content ">
        <PageTitle title="All Loss of Pay details in your file are ready to be imported" />
      </div>

      <div className="m-10 flex flex-col gap-10 ">
        <p className="text-3xl">
          Loss of Pay details that are ready for the import ({location.state.length})
        </p>
        <p className="text-3xl">Unmapped Fields</p>
      </div>

      <div className="mr-10 flex items-center gap-20">
        <button className="button-blue-gradient"  
          onClick={handleImportClick}>Import</button>
        <button className="button-red-gradient">Cancel</button>
      </div>
    </main>
  );
};

export default ImportLop;
