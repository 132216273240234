import { customAxios } from "../utils/axiosinstance";

export const addLeave = async (payload) => {
    const { data } = await customAxios.post(`/hrms/leave/add`, payload)
    return data;
};

export const editLeave = async (payload, filter) => {
    const { data } = await customAxios.post(`/hrms/leave/edit/${filter}`, payload)
    return data;
};

export const getAllLeaves = async (filter) => {
    const { data } = await customAxios.get(`hrms/leave/all-leaves`, {
        params: filter
    })
    return data;
};

export const getUserLeaveType = async (id) => {
    console.log(id)
    const { data } = await customAxios.get(`hrms/leave/user-leaves/${id}`)
    return data;
};
export const checkBalance = async (id) => {
    console.log(id)
    const { data } = await customAxios.get(`hrms/leave/check-balance/${id}`)
    return data;
};

export const getUserWiseLeaves = async (filter) => {
    const { data } = await customAxios.get(`hrms/leave/user-wise-leaves`, {
        params: filter
    })
    return data;
};

export const SaveLeaveAppl = async (payload) => {
    const { data } = await customAxios.post(`hrms/leave/leave-details`, payload)
    return data;
};

export const customizeBalance = async ({payload,emp}) => {
    const { data } = await customAxios.post(`hrms/leave/customize-balance`, {payload,emp})
    return data;
};

export const importBalance = async (payload) => {
    const { data } = await customAxios.post(`hrms/leave/import-balance`, payload)
    return data;
};

export const getLeaveApllDetails = async (filter) => {
    const { data } = await customAxios.get(`hrms/leave/leave-appl-details`, {
        params: filter
    })
    return data;
};


export const approveLeaveAppl = async ({id,status}) => {
    const { data } = await customAxios.post(`hrms/leave/approve-leave/${id}`,{status})
    return data;
};

export const getLeaveBalance = async (id) => {
    const { data } = await customAxios.get(`hrms/leave/get-balance/${id}`)
    return data;
};

export const filterLeaveApp = async ({ leaveType, empl, startDate, endDate }) => {
    const { data } = await customAxios.post(`hrms/leave/leave-appl-filter`, { leaveType, empl, startDate, endDate })
    return data;
};