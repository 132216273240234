import React from "react";
import SideBar from "../../components/SideBar/SideBar";
import { title, links } from "./masteNavData";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const MasterHomee = () => {
  const theme = useSelector((state) => state.theme.theme);

  return (
    <div
      className={"flex w-full " + `${theme == "light" ? "light_bg_main" : ""}`}
    >
      <SideBar title={title} links={links} />
      <Outlet />
    </div>
  );
};

export default MasterHomee;
