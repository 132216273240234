import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Table from '../../../components/Table'
import { FaPen } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux'
import PageTitle from '../../../components/PageTitle';
import { createDepartments, editDepartments, deleteDepartemts, setDepartments } from '../../../reducers/departmentReducer';
import serialise, { generateSelectData, generateSelectDatas } from '../../../utils/serialiseResponse';
import Select from 'react-select';
import { adminStyles } from '../../../utils/selectStyles';
import customToast from '../../../components/CustomToast';
import { clearHeadquarters, deleteHeadquarterAsync, editHeadquarterAsync, setHeadquarters } from '../../../reducers/locationReducer';
import { clearStates, fetchStates } from '../../../reducers/stateReducer';
import { RiCloseCircleLine, RiEdit2Fill } from 'react-icons/ri';
import { MdDelete, MdDeleteOutline, MdOutlineEdit } from 'react-icons/md';
import { addHeadquarterAsync } from '../../../reducers/locationReducer';
import { fetchDependents } from '../../../services/location';
import EditPopup from '../../../components/EditPopup';

const HeadQuarters = () => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.theme);
  const states = useSelector((state) => state.states.data);
  const headquarters = useSelector((state) => state.location.hq);
 
  const [showEdit, setShowEdit] = useState(false);
  const [hq, setHq] = useState("");
  const [state, setState] = useState(null);
  const [hqData, setHqData] = useState();
  const [showDelete, setShowDelete] = useState(false);
  const [dependents, setDependents] = useState([])
  let dependentsCount = 0;

 
    let updatedData = { ...hqData, name: hq };
    const handleEdit = async (e) => {
      e.preventDefault();
      await dispatch(editHeadquarterAsync(updatedData?._id, updatedData));
      setShowEdit(false)
      return customToast.success("HeadQuarter Edited successfully")
    };

    const handleDelete = () => {
      dispatch(deleteHeadquarterAsync(updatedData?._id));
      setShowEdit(false);
      return customToast.success("HeadQuarter deleted successfully")
    };

  const setEditForm = (data) => {
    setShowEdit(false);
    setHq(data?.name);
    setHqData(data);
  };

  useEffect(() => {
    dispatch(fetchStates("state"));
    dispatch(setHeadquarters());
    return () => {
      dispatch(clearStates());
      dispatch(clearHeadquarters());
    };
  }, [dispatch]);

  const fetchDependencies = async (data) => {
    try {
      const response = await fetchDependents(data._id, 'headquarter');
      setDependents(response);
    } catch (error) {
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!hq) return customToast.error("Please Enter Headquarter Name");
    var index = tableData.findIndex(p => (p.name).toLocaleLowerCase() == hq.toLocaleLowerCase());
    if(index === -1){
      try {
        await dispatch(addHeadquarterAsync({ name: hq, stateId: state.value }));
        customToast.success("Headquarter Successfully Created");
        setState(null)
        setHq('');
      } catch (e) {
        customToast.error("We Encountered an Error");
      }
    }
    else{
      customToast.error("Headquarter Already Exists!");
    }
  };
  
const selectData = useMemo(
    () => generateSelectData(states, "name"),
    [states]
 );


  const columns = useMemo(
    () => [
      {
        Header:"Sr no.",
        accessor:"sr",
        disableFilters:true,
        disableSortBy:true
      },
      {
        Header: "HQ",
        accessor: "name",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "State",
        accessor: "state",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "Action",
        accessor: "Action",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return (
            <span className="flex items-center justify-center">
              <span
                className="react-table-view-link"
                onClick={() => {
                  setEditForm(props.row.original);
                  setShowEdit(true);
                }}
              >
                <RiEdit2Fill className="icon-color-green" />
              </span>
            </span>
          );
        },
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [],
  );
  
  const tableData = useMemo(() => serialise(headquarters) , [headquarters]) ;

  return (
    <main className="main-content bg-primaryLight2 dark:bg-primaryDark1">
      <div className={"area-creation-content" + `${theme == "light" ? "light_bg" : ""}`}>
        <PageTitle title="HeadQuarter" route={'../..'} />

        <form onSubmit={(e) => handleSubmit(e)}>
          <section className="admin-content">
            <div className="row">
              <div>
                <label>Select State<span className='text-error'>*</span></label>
                <Select
                  value={state}
                  options={selectData}
                  onChange={({ value, label }) => setState({ value, label })}
                  styles={adminStyles}
                  placeholder="Select State"
                />
              </div>

              <div>
                <label>HeadQuarter Name <span className='text-error'>*</span></label>
                <input
                  placeholder='Enter Name'
                  onChange={(e) => setHq(e.target.value)}
                  value={hq}
                />
              </div>

              <div className="items-end flex">
                <button type='submit' className='button-blue-gradient py-[0.8rem] px-[2rem]'>Add HeadQuarter</button>
              </div>
            </div>

            <div className="mx-8 pb-10">
              {tableData.length > 0 && (
                <h2 className="show-entries">
                  Showing ({tableData.length}) Entries
                </h2>
              )}

              {tableData.length > 0 ? (
                <div className="filter-table">
                  <Table columns={columns} data={tableData} />
                </div>
              ) : (
                <div className='text-error font-medium text-3xl mt-5'>No headquarter Exists!</div>
              )}
              {showEdit ? (
                <EditPopup
                  handleDelete={handleDelete}
                  handleEdit={handleEdit}
                  setShowEdit={setShowEdit}
                  label="Headquarter"
                  value={hq}
                  setValue={setHq}
                  placeholder="Enter Headquarter"
                />
              ) : null}
            </div>
          </section>
        </form>
      </div>

    </main>

    //  <main className='mt-8 mr-8 w-full bg-whiteLight dark:bg-primaryDark pb-28' >
    //   <div className="dark:bg-primaryDark2">
    // <PageTitle title="HeadQuarter" route={'../..'}/>
    //   </div>
    //   <form onSubmit={(e) => handleSubmit(e)}>
    //   <div className='flex flex-row gap-10'>
    //     <div >
    // <div className='uppercase text-3xl font-medium dark:text-secondary mt-5'>Select State<span className='text-error'>*</span></div>
    // <div className='mt-0 flex gap-4 justify-start items-center'>
    //   <Select 
    //     className='p-0 !important inline-block mt-0 outline-none'
    //     value={state}
    //     options={selectData}
    //     onChange={({ value , label }) => setState({ value ,label })}
    //     styles={adminStyles}
    //     placeholder="Select State"
    //   />
    //   </div></div>
    //     <div>
    // <div className='uppercase text-3xl font-medium dark:text-secondary mt-5'>HeadQuarter Name <span className='text-error'>*</span></div>
    // <div className='mt-4 flex gap-8 justify-start items-center'>
    //   <input 
    //     className='px-5 py-3 inline-block dark:bg-primary dark:text-secondary outline-none'
    //     placeholder='Enter Name'
    //     onChange={(e)=> setHq(e.target.value)}
    //     value={hq}
    //   />
    // <button type='submit' className='inline-block button_blue_gradient dark:text-tertiary'>Add HeadQuarter</button>
    //   </div></div>
    //   </div>
    //   </form>
    // {tableData.length > 0 && (
    //   <h2 className={"mt-5 pt-5 ms-2 web-app__heading " + `${theme === 'light' ? "light_bg_logo" : ""}`}>
    //     Showing ({tableData.length}) Entries
    //   </h2>
    // )}

    // {tableData.length > 0 ? (
    //   <div className="filter-table">
    //     <Table columns={columns} data={tableData} />
    //   </div>
    // ) : (
    //   <div className='text-error font-medium text-3xl mt-5'>No headquarter Exists!</div>
    // )}
    //  {showEdit ? (
    //       <EditPopup
    //         handleDelete={handleDelete}
    //         handleEdit={handleEdit}
    //         setShowEdit={setShowEdit}
    //         label="Headquarter"
    //         value={hq}
    //         setValue={setHq}
    //         placeholder="Enter Headquarter"
    //       />
    //     ) : null}
    //  </main>
  )
}

export default HeadQuarters;