import { customAxios,customAxios2 } from "../utils/axiosinstance"


export const getAttendance = async (payload) => {
    const { data } = await customAxios.post(`hrms/attendance/get`, payload)
    return data;
}

export const getAtt = async ({ month, year, empl }) => {
    const { data } = await customAxios.post(`hrms/attendance/summary`, { month, year, empl })
    return data;
}

export const getDaycOunt = async (payload) => {
    const { data } = await customAxios.post(`hrms/attendance/day-count`, payload)
    return data;
}

export const getAttDayCount = async ({ month, year, empl }) => {
    const { data } = await customAxios.post(`hrms/attendance/summary-daycount`, { month, year, empl })
    return data;
}

export const syncAttendance = async ({ month, year }) => {
    const { data } = await customAxios2.get(`attendance/monthly-attendance?month=${month.value}&year=${year.label}`,)
    return data;
}

export const getMonthlyAttendance = async ({ month, year }) => {
    const { data } = await customAxios.get(`hrms/attendance/month-attendance?month=${month.value}&year=${year.label}`,)
    return data;
}
export const saveSyncAttendance = async (payload) => {
    const { data } = await customAxios.post(`hrms/attendance/sync`,payload)
    return data;
}
export const uploadRegularAttendance = async (payload) => {
    const { data } = await customAxios.post(`hrms/attendance/upload-regular-attendance`,payload)
    return data;
}
export const updateAttendance = async (payload) => {
    const { data } = await customAxios.patch(`hrms/attendance/update-month-attendance`,payload)
    return data;
}
export const getEmployeeMonthAttendance = async ({month,year,uid}) => {
    const { data } = await customAxios.get(`hrms/attendance/employee-month-attendance?month=${month.value}&year=${year.label}&uid=${uid}`)
    return data;
}
