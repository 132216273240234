export const title = "Dashboard";

export const links = [
  {
    name: "Create Company",
    route: "create",
    accessor: "create",
  },
  {
    name: "Company Working Address",
    route: "working-address",
    accessor: "working-address",
  },
//   {
//     name: "Employees Finance",
//     route: "finance",
//     accessor: "employees info",
//   },
  
];

