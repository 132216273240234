import { createSlice } from "@reduxjs/toolkit";
import { getCompanyProfile } from "../../services/hrms";

const companyBasicInfoReducerSlice = createSlice({
  name: "basicInfo",
  initialState: {
    data: [],
  },
  reducers: {
    setBasicInfo: (state, action) => {
      state.data = action.payload;
    },
  },
});

const { setBasicInfo } =   companyBasicInfoReducerSlice.actions;

export const fetchBasicInfo = () => async (dispatch) => {
    try {
      const res = await getCompanyProfile();
      dispatch(setBasicInfo(res.data[0]));
        
    } catch (error) {
      console.log(error)
    }
};


export default companyBasicInfoReducerSlice.reducer;
