import { useEffect, useState, useMemo, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminStyles, adminStylesLight } from "../../../../utils/selectStyles";
import PageTitle from "../../../../components/PageTitle";
import Select from "react-select";
import { generateSelectData } from "../../../../utils/serialiseResponse";
import {
  fetchEmployees,
  setEmployees,
} from "../../../../reducers/employeesReducer";
import { GetAllEmployees, UploadDocument } from "../../../../services/hrms";
import customToast from "../../../../components/CustomToast";
import { fileToBase64 } from "../../../../utils/helper";


const selectStyle = {
  control: (provided, state) => ({
    ...provided,
    padding: "0.1rem 1rem",
    backgroundColor: "#2B2C47",
    color: "#C7C4E9",
    border: "5px",
    borderRadius: "5px",
    boxShadow: "none",
    minHeight: "3.5rem",
    maxHeight: "3.5rem",
    width: "100%",
    marginTop: "1rem",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    color: "#9E9AD1",

    "&:hover": {
      backgroundColor: "#393b5f",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#9e9ad0",
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    width: "80%",
  }),
  input: (provided, state) => ({
    ...provided,
    color: "#fff",
    border: "none",
    width: "100%",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#C7C4E9",

    "&:hover": {
      color: "#9E9AD1",
    },
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#9E9AD1",
  }),
  indicatorSeparator: () => null,
};

const DriverLicence = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchEmployees({}));
  }, []);

  const employeeData = useSelector((state) => state.employees.data);

  const employeeSelectData = useMemo(
    () => generateSelectData(employeeData, "fullName"),
    [employeeData],
  );

  const [selectedEmployee, setSelectedEmployee] = useState();
  const [drivingLicenceNumber, setDrivingLicenceNumber] = useState("");
  const [drivingLicenceIssueDate, setDrivingLicenceIssueDate] = useState("");
  const [drivingLicenceExpiryDate, setDrivingLicenceExpiryDate] = useState("");
  const [uploadData, setUploadData] = useState([]);

  let changeHandler = (e) => {
    const validFiles = Array.from(e.target.files);
    setUploadData([]);
    Promise.all(validFiles.map(fileToBase64))
      .then((base64Images) => {
        setUploadData(base64Images);
      })
      .catch((error) => {
        customToast.error("Error adding images");
      });
  };

  const resetStates = () => {
    setSelectedEmployee("");
    setUploadData([]);
    setDrivingLicenceNumber("");
    setDrivingLicenceIssueDate("");
    setDrivingLicenceExpiryDate("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedEmployee) {
      customToast.error("Please Select Employee !!");
      return;
    }
    if (
      !drivingLicenceNumber ||
      !drivingLicenceIssueDate ||
      !drivingLicenceExpiryDate
    ) {
      customToast.error("Please Fill all The Required Details !!");
      return;
    }
    try {
      const payload = {
        docType: "drivingLicence",
        employeeId: selectedEmployee?.value,
        number: drivingLicenceNumber,
        issueDate: drivingLicenceIssueDate,
        expiryDate: drivingLicenceExpiryDate,
        uploadData,
      };
      const response = await UploadDocument(payload);
      resetStates();
      if (response?.ok) {
        dispatch(fetchEmployees());
        customToast.success("Driving Licence Details Uploaded Successfully !!");
      }
    } catch (error) {
      customToast.error("Error Uploading Driving Licence Details !!");
    }
  };

  const theme = useSelector((state) => state.theme.theme);

  return (
    <main
      className="main-content bg-primaryLight2 dark:bg-primaryDark1"
    >
      <div
        className={
          "area-creation-content " + `${theme == "light" ? "light_bg" : ""}`
        }
      >
        <PageTitle title="Driving Licence Details" route={'/employee/manage/create'} />
        <form>
          <section className="admin-content">
            <form className="row">
              <div>
                <label>Select Employee</label>
                <br />
                <Select
                  name="emp_select"
                  value={selectedEmployee}
                  styles={theme == "light" ? adminStylesLight : adminStyles}
                  options={employeeSelectData}
                  onChange={(e) => setSelectedEmployee({ ...e })}
                  placeholder="Select Employee"
                />
              </div>

              <div>
                <label>
                  Licence Number<span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                  placeholder="Enter Number"
                  value={drivingLicenceNumber}
                  onChange={(e) => setDrivingLicenceNumber(e.target.value)}
                />
              </div>

              <div>
                <label>
                  Date of issue <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="date"
                  placeholder="Select Date"
                  value={drivingLicenceIssueDate}
                  onChange={(e) => setDrivingLicenceIssueDate(e.target.value)}
                  style={{
                    boxShadow: 'none',
                  }}
                />
              </div>

              <div>
                <label>
                  Expiry date <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="date"
                  placeholder="Enter Date"
                  value={drivingLicenceExpiryDate}
                  onChange={(e) => setDrivingLicenceExpiryDate(e.target.value)}
                  style={{
                    boxShadow: 'none',
                  }}
                />
              </div>
            </form>

            <div className="w-max mx-[2rem]">
              <label>Upload Document</label> <span className="asterisk-imp">*</span>
              <br />
              <div className="flex flex-col">
                <input type="file" onChange={changeHandler} className="dark:bg-primaryDark2 px-5 py-2" />
                <span className="text-lg text-gray-500 mt-2">*Please upload only JPG, JPEG or PDF format, no larger than 5 MB.</span>
              </div>
            </div>

            <div className="row pb-10">
              <button type="submit" className="button-blue-gradient" onClick={(e) => handleSubmit(e)}>
                Upload
              </button>
            </div>
          </section>
        </form>
      </div>

    </main>
  );
};

export default DriverLicence;
