import { createSlice } from "@reduxjs/toolkit";
import { getUserData, login } from "../services/user";
import {
  clearUser,
  retrieveUser,
  storeUser,
  storeSubDomain,
} from "../utils/authStorage.js";

// Define the initial state
const initialState = {
  user: null,
  jwt: {
    token: "",
    id: "",
    isAdmin: false,
  },
  status: "idle",
  error: null,
};

// Define the user slice
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    initUserSuccess: (state, action) => {
      const { jwt, user } = action.payload;
      state.jwt = jwt;
      state.user = user;
      state.status = "succeeded";
    },
    initUserLoading: (state) => {
      state.status = "loading";
    },
    initUserFailure: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    loginUserSuccess: (state, action) => {
      state.jwt = action.payload.jwt;
      state.user = action.payload.user;
    },
    logoutUser: (state) => {
      clearUser();
      state.user = false;
      state.jwt = initialState.jwt;
    },
  },
});

export const {
  initUserSuccess,
  initUserLoading,
  initUserFailure,
  loginUserSuccess,
  logoutUser,
} = userSlice.actions;

// Define async thunk for initUser
export const initUser = () => async (dispatch) => {
  dispatch(initUserLoading());

  try {
    const data = retrieveUser();
    let resData;

    try {
      const response = await getUserData(data?.id);
      resData = response.data;
    } catch (error) {
      resData = null;
    }

    dispatch(
      initUserSuccess({
        jwt: data ? data : initialState.jwt,
        user: resData ? resData : false,
      }),
    );
  } catch (error) {
    dispatch(initUserFailure(error.message));
  }
};

export const loginUser = (credentials, remember) => async (dispatch) => {
  try {
    
    storeSubDomain(credentials.companyId);

    const data = await login(credentials);

    if (remember) storeUser(data.jwt, 30);
    else storeUser(data.jwt, 1);
    
    dispatch(loginUserSuccess(data));
  } catch (error) {
    throw error;
  }
};


export default userSlice.reducer;
