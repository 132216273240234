import React, { useEffect, useState } from "react";
import { GetEmployeeActivityById } from "../../../../services/employee-activity";
import PopoverAntd from "../../../../components/Popover/Popover";

const Activity = ({ emp }) => {
  const [activities, setActivities] = useState([]);
  useEffect(() => {
    emp &&
      GetEmployeeActivityById({ id: emp?.value }).then((data) =>
        setActivities(data),
      );
  }, [emp]);
  return (
    <main className="!gap-0">
      {activities?.map((activity) => (
        <ActivityNotification
          key={activity._id}
          date={activity.time.split(",")[0]}
          time={activity.time.split(",")[1]}
          field={activity.field}
          to={activity.to}
          from={activity.from}
          // designation="PA"
        />
      ))}
    </main>
  );
};

const ActivityNotification = ({ date, time, field, from, to }) => {
  return (
    <div className="flex w-full rounded gap-5 pl-8 text-secondary overflow-y-hidden">
      <div className="flex text-xl h-full">
        <div className="text-nowrap mr-2">
          <div>{date}</div>
          <div>{time}</div>
        </div>
      </div>
      <div className="flex-col items-center ">
        <div className="py-2">
          <div className=" bg-[#21c38b] w-4 h-4 rounded-full"></div>
        </div>
        <div className="w-full h-full flex justify-center">
          <div className="border-r border-secondary h-full"></div>
        </div>
      </div>
      <div>
        <div className="p-2 bg-opacity-10 bg-[#21c38b] rounded-md pr-6">
          <div className="ml-2 flex items-center border-l-4 rounded-tl-lg rounded-bl-lg border-[#21c38b] pl-1">
            <div className="flex gap-4">
              <div className="">
                <p className="m-0 text-[#21c38b] font-medium">Modified</p>
                {typeof to == "string" ? (
                  <p className="m-0 text-xl">
                    Your <b>{field}</b> has been changed to <b>{to}</b>
                  </p>
                ) : (
                  <p>
                    Your <b>{field}</b> has been changed.
                  </p>
                )}
              </div>
              <PopoverAntd
                className={"bg-primary"}
                title={"Modification"}
                content={
                  <div>
                    {typeof to == "string" && typeof from == "string" ? (
                      <p>
                        {field.toUpperCase()} has been changed from{" "}
                        {from ? <b>{from}</b> : <b>' '</b>} to <b>{to}</b>
                      </p>
                    ) : (
                      <p>
                        {field.toUpperCase()} has been modified on {date} {time}
                      </p>
                    )}
                  </div>
                }
              >
                <span className="text-[#21c38b] flex justify-center h-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5M12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5s5 2.24 5 5s-2.24 5-5 5m0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3s3-1.34 3-3s-1.34-3-3-3"
                    />
                  </svg>
                </span>
              </PopoverAntd>
            </div>
          </div>
          {/* <div className="flex-shrink-0 text-center w-8">
          <span className="bg-teal-500 text-white rounded-full px-2 py-1 text-sm">
            {designation}
          </span>
        </div> */}
        </div>
        <div className="h-5"></div>
      </div>
    </div>
  );
};
export default Activity;
