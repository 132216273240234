import { configureStore, combineReducers } from "@reduxjs/toolkit";

import sidenavReducer from "./reducers/sidenavReducer";
import userReducer from "./reducers/userReducer";
import locationReducer from "./reducers/locationReducer";
import divisionReducer from "./reducers/divisionReducer";
import designationReducer from "./reducers/designationReducer";
import stateReducer from "./reducers/stateReducer";
import employeesReducer from "./reducers/employeesReducer";
import themeReducer from "./reducers/themeReducer";
import birthDayReducer from "./reducers/Dashboard/birthDayReducer";
import aniversaryReducer from "./reducers/Dashboard/aniversaryReducer";
import setUpCurrentStepReducer from "./reducers/setUpCurrentStepReducer";
import hrmsSetUpStatusReducer from "./reducers/hrmsSetUpStatusReducer";
import departmentReducer from "./reducers/departmentReducer";
import cityReducer from "./reducers/cityReducer";
import reimbursementTypesReducer from "./reducers/reimbursementTypesReducer";
import reimbursementsReducer from "./reducers/reimbursementsReducer";
import deductionReducer from "./reducers/deductionReducer";
import esiDetailsReducer from "./reducers/esiDetailsReducer";
import epfDetailsReducer from "./reducers/epfDetailsReducer";
import professionalTaxDeailsReducer from "./reducers/professionalTaxDeailsReducer";
import lwfDetails from "./reducers/lwfDetails";
import salaryTempelate from "./reducers/salaryTempelate";
import loansReducer from "./reducers/loansReducer";
import employeesloansReducer from "./reducers/employeesloansReducer";
import onboardDivisions from "./reducers/onboardingDivisionsReducer";
import lopReducer from "./reducers/lopReducer";
import advSalaryReducer from "./reducers/advSalaryReducer";
import payrunAprroveStatus from "./reducers/payrunAprroveStatus";
import payrunReducer from "./reducers/payrunReducer";
import leaveReducer from "./reducers/leaveReducer";import notificationsReducer from "./reducers/notificationsReducer";
import earningsReducer from "./reducers/earningsReducer";
import companyBasicInfoReducer from "./reducers/Dashboard/companyBasicInfoReducer";
import earningTypesReducer from "./reducers/earningTypesReducer";
import deductionTypesReducer from "./reducers/deductionTypesReducer";

const rootReducer = combineReducers({
  sidenav: sidenavReducer,
  user: userReducer,
  location: locationReducer,
  divisions: divisionReducer,
  designations: designationReducer,
  states: stateReducer,
  employees: employeesReducer,
  theme: themeReducer,
  birthDays: birthDayReducer,
  aniversaries: aniversaryReducer,
  currentStep: setUpCurrentStepReducer,
  hrmsSubscription: hrmsSetUpStatusReducer,
  department: departmentReducer,
  city: cityReducer,
  reimbursements: reimbursementsReducer,
  reimbursementTypes: reimbursementTypesReducer,
  department: departmentReducer,
  city: cityReducer,
  deduction: deductionReducer,
  esiInfo: esiDetailsReducer,
  epfInfo: epfDetailsReducer,
  professionalTaxDeails: professionalTaxDeailsReducer,
  lwfInfo: lwfDetails,
  salaryTemp: salaryTempelate,
  loans: loansReducer,
  employeesloans: employeesloansReducer,
  onboardDivisions: onboardDivisions,
  lop:lopReducer,
  advSalary: advSalaryReducer,
  payrunApprove:payrunAprroveStatus,
  payrun:payrunReducer,
  leaves:leaveReducer,
  notification: notificationsReducer,
  earnings: earningsReducer,
  basicInfo:companyBasicInfoReducer,
  earningTypes: earningTypesReducer,
  deductionTypes: deductionTypesReducer,
});

export default configureStore({
  reducer: rootReducer,
});
