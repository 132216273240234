import React, { useReducer, useState } from "react";
import Select from "react-select";
import {
  adminStyles,
  adminStylesLight,
  selectStyles,
} from "../../../../../utils/selectStyles";
import PageTitle from "../../../../../components/PageTitle";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import customToast from "../../../../../components/CustomToast";
import { addEpf, updateEpf } from "../../../../../services/hrms";
import { IoIosInformationCircle } from "react-icons/io";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const deductionTypes = [
  { value: "monthly", label: "Monthly" },
  { value: "annual", label: "Annual" },
];

const employerContributionRateData = [{ value: 12, label: "12% of Basic" }];

const employeeContributionRateData = [{ value: 12, label: "12% of Basic" }];

const AddUpdateEpf = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useSelector((state) => state.theme.theme);
  const [active, setActive] = useState(location.state?.epfDetails?.enableEpf||false);

  const [epfNumber, setEpfNumber] = useState(
    location.state?.epfDetails?.epfNumber || null,
  );
  const [deductionType, setDeductionType] = useState(
    location.state?.epfDetails?.deductionType || null,
  );
  const [employerContributionRate, setEmployerContributionRate] = useState(
    location.state?.epfDetails?.employerContributionRate || null,
  );
  const [employeeContributionRate, setEmployeeContributionRate] = useState(
    location.state?.epfDetails?.employeeContributionRate || null,
  );

  //checks
  const [calculatOnProDataBasis, setCalculateOnProDataBasis] = useState(
    location.state?.epfDetails?.calculatOnProDataBasis || false,
  );

  const [
    allSalaryComponentsConsideration,
    setAllSalaryComponentsConsideration,
  ] = useState(
    location.state?.epfDetails?.allSalaryComponentsConsideration || false,
  );

  const [includeEmployersContribution, setIncludeEmployersContribution] =
    useState(location.state?.epfDetails?.includeEmployersContribution || false);
  //radio
  const [addPfAdminChargesInCtc, setAddPfAdminChargesInCtc] = useState(
    location.state?.epfDetails?.addPfAdminChargesInCtc || false,
  );
  const [
    addEmployersEdliContributionInCtc,
    setAddEmployersEdliContributionInCtc,
  ] = useState(
    location.state?.epfDetails?.addEmployersEdliContributionInCtc || false,
  );

  const [componentOnPayslip, setComponentOnPayslip] = useState(
    location.state?.epfDetails?.componentOnPayslip || false,
  );

  
  const [isActive, setIsActive] = useState(
    true || false,
  );

  //handlers
  const handleEditEpf = async (e) => {
    e.preventDefault();

    if(!epfNumber || deductionType === null || employerContributionRate === null || employeeContributionRate === null){
      customToast.error("Please fill all the required fields");
      return;
    }

    try {
      const payload = {
        epfNumber,
        deductionType,
        employerContributionRate,
        employeeContributionRate,
        calculatOnProDataBasis,
        allSalaryComponentsConsideration,
        includeEmployersContribution,
        addPfAdminChargesInCtc,
        addEmployersEdliContributionInCtc,
        componentOnPayslip,
        isActive,
      };
      await updateEpf(payload);
      customToast.success("EPF updated successfully");
       navigate("/settings/statutory/epf");
    } catch (error) {
      customToast.error("Failed to update EPF");
    }
  };
  const handleAddEpf = async (e) => {
    e.preventDefault();
    if (
      !epfNumber ||
      !deductionType ||
      !employerContributionRate ||
      !employeeContributionRate
    ) {
      customToast.error("Please fill all the required fields");
      return;
    }
    if (includeEmployersContribution) {
      if (!addPfAdminChargesInCtc && !addEmployersEdliContributionInCtc) {
        customToast.error(
          "Please select atleast one checkbox from Include Employer's Contribution in CTC",
        );
        return;
      }
    }
    try {
      const payload = {
        epfNumber,
        deductionType,
        employerContributionRate,
        employeeContributionRate,
        calculatOnProDataBasis,
        allSalaryComponentsConsideration,
        includeEmployersContribution,
        addPfAdminChargesInCtc,
        addEmployersEdliContributionInCtc,
        componentOnPayslip,
        isActive,
      };
      await addEpf(payload);
      customToast.success("EPF added successfully");
      navigate("/settings/statutory/epf");
    } catch (error) {
      customToast.error("Failed to add EPF");
    }
  };

  return (
    <main
      className={"main-content " + `${theme == "light" ? "light_bg_main" : ""}`}
      style={{ width: "100%" }}
    >
    
      <div
        className={
          "area-creation-content " + `${theme == "light" ? "light_bg" : ""}`
        }
      >
        <PageTitle
          title={`${location.state?.type == "add" ? "ADD EPF" : "EDIT EPF"}`}
          route={'..'}
        />

        <section className="admin-content">
          <form>
            <div className="cardlink-row flex flex-wrap gap-16">
              <div>
                <label>
                  EPF NUMBER <span className="asterisk-imp">*</span>
                </label>
                <div className="w-96">
                <input
                  type="number"
                  placeholder="Enter EPF Number"
                  value={epfNumber}
                  onChange={(e) => setEpfNumber(e.target.value)}
                />
                </div>
              </div>

              <div>
                <label>
                  DEDUCTION TYPE<span className="asterisk-imp">*</span>
                </label>
                <div className="w-96">
                <Select
                  styles={adminStyles}
                  options={deductionTypes}
                  placeholder="Select Type"
                  // isSearchable={false}
                  value={deductionType}
                  onChange={(e) => setDeductionType(e)}
                />
                </div>
              </div>

              {/* <div>
                <label>
                  EMPLOYER CONTRIBUTION RATE{" "}
                  <span className="asterisk-imp">*</span>
                </label>
                <Select
                  // isSearchable={false}
                  styles={adminStyles}
                  options={employerContributionRateData}
                  value={employerContributionRate}
                  onChange={(e) => setEmployerContributionRate(e)}
                />
              </div>

              <div>
                <label>
                  EMPLOYEE CONTRIBUTION RATE{" "}
                  <span className="asterisk-imp">*</span>
                </label>
                <Select
                  // isSearchable={false}
                  styles={adminStyles}
                  options={employeeContributionRateData}
                  value={employeeContributionRate}
                  onChange={(e) => setEmployeeContributionRate(e)}
                />
              </div> */}
            </div>

            <div className="cardlink-row flex flex-wrap gap-8">
              <div>
                <label>
                  EMPLOYER CONTRIBUTION RATE{" "}
                  <span className="asterisk-imp">*</span>
                </label>
                <div className="w-96">
                <Select
                  // isSearchable={false}
                  styles={adminStyles}
                  placeholder="Select Rate"
                  options={employerContributionRateData}
                  value={employerContributionRate}
                  onChange={(e) => setEmployerContributionRate(e)}
                />
                </div>
              </div>

              <div>
                <label>
                  EMPLOYEE CONTRIBUTION RATE{" "}
                  <span className="asterisk-imp">*</span>
                </label>
                {/* <br /> */}
                <div>
                <Select
                  // isSearchable={false}
                  placeholder="Select Rate"
                  styles={adminStyles}
                  options={employeeContributionRateData}
                  value={employeeContributionRate}
                  onChange={(e) => setEmployeeContributionRate(e)}
                />
                </div>
              </div>
            </div>

            <div className="mx-8 flex  flex-col">
              <label className="text-3xl text-wheat uppercase">Other Configurations :</label>
              <div className="flex flex-col gap-4">
                <div className="flex items-center gap-10">
                  <input
                    type="checkbox"
                    className="  check-box green_b"
                    checked={calculatOnProDataBasis}
                    onChange={(e) => setCalculateOnProDataBasis(e.target.checked)}
                  />
                  <OverlayTrigger
                    className="cursor-pointer"
                    key="right"
                    placement="right"
                    overlay={
                      <Tooltip id={`tooltip-$top`} className="text-[1.2rem] bg-[#353456] text-yellow w-60 p-4 text-center">
                        Pay will be adjusted based on employee working days
                      </Tooltip>
                    }>
                    <span className="text-gray-300 form-label-text text-3xl flex flex-row gap-x-1">
                      Calculate on Pro-Data Basis
                      <IoIosInformationCircle className="text-yellow" />
                    </span>
                  </OverlayTrigger>
                </div>
                <div className="flex items-center gap-10">
                  <input
                    type="checkbox"
                    className=" check-box green_b"
                    checked={allSalaryComponentsConsideration}
                    onChange={(e) =>
                      setAllSalaryComponentsConsideration(e.target.checked)
                    }
                  />
                  <span className="text-gray-300 form-label-text ">
                    Consider all salary components applicable if the pay is less
                    than 15000 after LOP calculation
                  </span>
                </div>
                <div className="flex items-center  gap-10">
                  <input
                    type="checkbox"
                    className="  check-box green_b"
                    checked={includeEmployersContribution}
                    onChange={(e) => {
                      setIncludeEmployersContribution(e.target.checked);
                      if (!e.target.checked) {
                        setAddPfAdminChargesInCtc(false);
                        setAddEmployersEdliContributionInCtc(false);
                      }
                    }}
                  />
                  <span className="text-gray-300 form-label-text ">
                    Include Employer’s Contribution in CTC{" "}
                  </span>
                </div>
                {/* {includeEmployersContribution && (
                  <div className="flex items-center  gap-10">
                      <input
                        type="checkbox"
                        name="ctc"
                        className="  check-box blue_b"
                        checked={addPfAdminChargesInCtc}
                        onClick={(e) => {
                          if (e.target.checked) {
                            setAddPfAdminChargesInCtc(true);
                            setAddEmployersEdliContributionInCtc(false);
                          } else {
                            setAddPfAdminChargesInCtc(false);
                          }
                        }}
                      />
                      <span className="text-gray-400 form-label-text ">
                        Add PF Admin charges in CTC{" "}
                      </span>
                      </div>
                    <div className="flex items-center gap-10">
                      <input
                        type="checkbox"
                        name="ctc"
                        className="  check-box blue_b"
                        checked={addEmployersEdliContributionInCtc}
                        onClick={(e) => {
                          if (e.target.checked) {
                            setAddEmployersEdliContributionInCtc(true);
                            setAddPfAdminChargesInCtc(false);
                          } else {
                            setAddEmployersEdliContributionInCtc(false);
                          }
                        }}
                      />
                      <span className="text-gray-400 form-label-text">
                        Add Employer’s EDLI Contribution in CTC{" "}
                      </span>
                    </div>
                  </div>
                )} */}
                {includeEmployersContribution && (
                  <div>
                    <div className="flex items-center gap-10 mb-4">
                      <input
                        type="checkbox"
                        // name="ctc"
                        className="check-box blue_b"
                        checked={addPfAdminChargesInCtc}
                        onClick={(e) => {
                          if (e.target.checked) {
                            setAddPfAdminChargesInCtc(true);
                            // setAddEmployersEdliContributionInCtc(false);
                          } else {
                            setAddPfAdminChargesInCtc(false);
                          }
                        }}
                      />
                      <span className="text-gray-400 form-label-text">
                        Add PF Admin charges in CTC
                      </span>
                    </div>
                    <div className="flex items-center gap-10">
                      <input
                        type="checkbox"
                        // name="ctc"
                        className="check-box blue_b"
                        checked={addEmployersEdliContributionInCtc}
                        onClick={(e) => {
                          if (e.target.checked) {
                            setAddEmployersEdliContributionInCtc(true);
                            // setAddPfAdminChargesInCtc(false);
                          } else {
                            setAddEmployersEdliContributionInCtc(false);
                          }
                        }}
                      />
                      <span className="text-gray-400 form-label-text">
                        Add Employer’s EDLI Contribution in CTC
                      </span>
                    </div>
                  </div>
                  // </div>
                )}

                <div className="flex items-center  gap-10">
                  <input
                    type="checkbox"
                    className="  check-box green_b"
                    checked={componentOnPayslip}
                    onChange={(e) => setComponentOnPayslip(e.target.checked)}
                  />
                  <span className="text-gray-300 form-label-text ">
                    Show this Component on Payslip
                  </span>
                </div>
                <div className="flex f items-center gap-10 ">
                  <input
                    type="checkbox"
                    className="  check-box-active blue_b"
                    checked={isActive}
                    onChange={(e) => setIsActive(e.target.checked)}
                  />
                  <span className="text-gray-300 form-label-text ">
                    Mark this as Active
                  </span>
                </div>
              </div>
            </div>

            <div className="mx-8 mt-12 note  ">
              <label>NOTE :</label>
              <p className="mt-6 text-2xl">
                Let's assume the PF Wages is ₹ 20,000. The breakup of
                contribution will be:
                <br /> Employer's Contribution
                <br /> EPS = 8.33 % of PF Wages(Maximum of ₹ 15,000) = 8.33 % of
                15000 = ₹ 1250.
                <br /> EPF = 12 % of PF Wages -EPS = ₹ 2400(12 % of 20000) - ₹
                1250 = ₹ 1150.
                <br /> Employee's Contribution
                <br /> EPF = 12 % of 20000 = ₹ 2400.
              </p>
            </div>

            <div className="row pb-10  ">
              <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                {location.state?.type == "add" ? (
                  <button
                    onClick={handleAddEpf}
                    type="submit"
                    className="button-blue-gradient"
                  >
                    Add
                  </button>
                ) : (
                  <button
                    onClick={handleEditEpf}
                    type="submit"
                    className="button-blue-gradient"
                  >
                    Update
                  </button>
                )}
              </div>
            </div>
          </form>
        </section>
      </div>
    </main>
  );
};

export default AddUpdateEpf;
