import React from "react";
import CardLink from "../../components/CardLink";
import { useSelector } from "react-redux";

const ManageEmployee = () => {
  const theme = useSelector((state) => state.theme.theme);
  return (
    <div
    className="main-content bg-primaryLight2 dark:bg-primaryDark1"
    >
      <div className="admin-panel_content">
        <div className="cardlink-row">
          <div className="admin-panel__box-container flex flex-row flex-wrap gap-8">
            <CardLink
              className={"admin-panel__cards"}
              title="LOAN"
              type="Loan"
              link="manage-loan/create-loan"
            />
            <CardLink
              className={"admin-panel__cards"}
              title="Advance Salary"
              type="Loan/AdvanceSalary"
              link="manage-advance-salary/assign-advance-salary"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageEmployee;
