import React, { useState } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa6';
import { useSelector } from 'react-redux';

const InfoBox = ({ title, des, div }) => {
    const theme = useSelector((state) => state.theme.theme);


    return (
        <div className={"user-detail-box " + `${theme == "light" ? "light_bg_logo" : ""}`}>
        <h4 className='text-[#faf9f9]'>{title}</h4>
        <p className='text-[#9e9ad1]'>{des}</p>
        {div}
      </div>
    )
}

export default InfoBox