import { createSlice } from "@reduxjs/toolkit";
import {
  getallloans,
} from "../services/loans";
import customToast from "../components/CustomToast";

const loansSlice = createSlice({
  name: "loans",
  initialState: {
    data: [],
  },
  reducers: {
    setLoanDetails(state, action) {
      state.data = action.payload;
    },
  },
});

export const { setLoanDetails } = loansSlice.actions;

export const fetchAllLoans = () => async (dispatch) => {
  try {
    const response = await getallloans();
    dispatch(setLoanDetails(response.data));
  } catch (error) {
    customToast.error("Something went wrong !")
  }
};


export default loansSlice.reducer;
