import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { GetEarnings } from "../../../../services/earning";
import customToast from "../../../../components/CustomToast";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdDelete, MdEdit } from "react-icons/md";
import PageTitle from "../../../../components/PageTitle";
import Select from "react-select";
import { generateSelectData } from "../../../../utils/serialiseResponse";
import {
  convertNumberToWords,
  getPercentageSalary,
} from "../../../../utils/helper";
import { GetAllDeduction } from "../../../../services/deduction";
import { getReimbursements } from "../../../../services/reimbursement";
import { fetchTemp } from "../../../../reducers/salaryTempelate";
import { FaPen } from "react-icons/fa";
import Table from "../../../../components/Table";
import { SlOptionsVertical } from "react-icons/sl";
import Dropdown from "../../../../components/DropDown/dropdown";
import {
  UpdateTempelateStatus,
  DuplicateTempelate,
  DeleteTemplateParmenently,
} from "../../../../services/salaryTempelates";
import NavigationModal from "../../../../components/NavigationModal";

const SalaryTemplates = () => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.theme);
  const navigate = useNavigate();

  const handleStatusChange = async (id, status) => {
    let newStatus;
    if (status) {
      newStatus = false;
    } else {
      newStatus = true;
    }
    await UpdateTempelateStatus({ id, newStatus });
    dispatch(fetchTemp());
  };

  const columns = useMemo(
    () => [
      {
        Header: "Salary Tempelate Name",
        accessor: (row) => row.tempName || "--",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Description",
        accessor: (row) => row.description || "--",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Status",
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <>
              <span
                className={
                  row.original?.status == true
                    ? "text-green-400"
                    : "text-red-500"
                }
              >
                {row.original?.status == true ? "Active" : "In-active" || "--"}
              </span>
            </>
          );
        },
      },
      {
        Header: "Action",
        disableFilters: true,
        disableSortBy: true,
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          const [showModal, setShowModel] = useState(false);

          const handleDuplicate = async (payload) => {
            try {
              const res = await DuplicateTempelate(payload);
              dispatch(fetchTemp());
              customToast.success("Template successfully duplicated");
            } catch (error) {
              customToast.error("Error in duplicating template");
            }
          };
          const handleDelete = async (payload) => {
            try {
              const res = await DeleteTemplateParmenently(payload);
              dispatch(fetchTemp());
              customToast.success("Template successfully deleted");
            } catch (error) {
              customToast.error("Error in deleteing template");
            }
          };
          const closeModal = () => setShowModel(false);
          return (
            <>
              <NavigationModal
                isOpen={showModal}
                message={"Are you sure to delete?"}
                onClose={closeModal}
                onConfirm={() => handleDelete(props?.row?.original?._id)}
              />

              <Dropdown
                icon={<SlOptionsVertical className="icon-color-tertiary" />}
                options={[
                  {
                    text: "Edit",
                    onClick: () => {
                      navigate("../edit", { state: props?.row?.original });
                    },
                  },
                  {
                    text: "Duplicate",
                    onClick: () =>
                      handleDuplicate({
                        templateId: props?.row?.original?._id,
                      }),
                  },
                  {
                    text: props?.row?.original?.status ? "In-Active" : "Active",
                    onClick: () =>
                      handleStatusChange(
                        props?.row?.original?._id,
                        props?.row?.original?.status,
                      ),
                  },
                  {
                    text: "Delete",
                    onClick: () => setShowModel(true),
                  },
                ]}
              />
            </>
          );
        },
      },
    ],
    [],
  );

  const tempData = useSelector((state) => state.salaryTemp.data);

  useEffect(() => {
    dispatch(fetchTemp());
  }, [dispatch]);

  const tableData = tempData || [];

  return (
    <div
      className={"main-content " + `${theme == "light" ? "light_bg_main" : ""}`}
      style={{ width: "100%" }}
    >
      <div
        className={
          "creation-content " + `${theme == "light" ? "light_bg" : ""}`
        }
      >
        <PageTitle title="Salary Tempelates" route={"../.."} />

        <section className="admin-content mx-8 py-8">
          <button
            onClick={() => navigate("/settings/templates/add-tempelate")}
            className="rounded-full font-medium text-2xl px-10 py-5 dark:bg-tertiary cursor-pointer"
          >
            ADD SALARY TEMPELATE
          </button>

          <section>
            {tableData.length > 0 && (
              <h2
                className={
                  "show-entries" + `${theme === "light" ? "light_bg_logo" : ""}`
                }
              >
                Showing ({tableData.length}) Entries
              </h2>
            )}
            <div
              className={
                "filter-table " + `${theme === "light" ? "table_light" : ""}`
              }
            >
              {tableData.length > 0 ? (
                <Table columns={columns} data={tableData} />
              ) : (
                // <table className="w-full">
                //   <thead className="uppercase dark:text-secondary dark:bg-primaryDark2 w-full text-center bg-white">
                //     <th className="border-2 border-collapse border-primaryLight3 dark:border-primaryDark dark:text-secondary font-normal px-6 py-4">
                //       Salary Tempelate Name
                //     </th>
                //     <th className="border-2 border-collapse border-primaryLight3 dark:border-primaryDark dark:text-secondary font-normal px-6 py-4">
                //       Description
                //     </th>
                //     <th className="border-2 border-collapse border-primaryLight3 dark:border-primaryDark dark:text-secondary font-normal px-6 py-4">
                //       Status
                //     </th>
                //     <th className="border-2 border-collapse border-primaryLight3 dark:border-primaryDark dark:text-secondary font-normal px-6 py-4">
                //       Action
                //     </th>
                //   </thead>
                //   <tbody>
                //     {tableData?.map((el, idx) => (
                //       <tr key={idx} className="dark:bg-primary text-center bg-white">
                //         <td className="border-2 border-collapse border-primaryLight3 dark:border-primaryDark text-black dark:text-wheat px-6 py-6">
                //           {el?.tempName || "--"}
                //         </td>
                //         <td className="border-2 border-collapse border-primaryLight3 dark:border-primaryDark text-black dark:text-wheat px-6 py-6">
                //           {el?.description || "--"}
                //         </td>
                //         <td className="border-2 border-collapse border-primaryLight3 dark:border-primaryDark text-black dark:text-wheat px-6 py-6">
                //           <span className={el?.status == true ? "text-green-400" : "text-red-500"}>{el?.status == true ? "Active" : "In-active" || "--"}</span>
                //         </td>
                //         <td className="border-2 border-collapse border-primaryLight3 dark:border-primaryDark text-black dark:text-wheat px-6 py-6 ">
                //           <FaPen
                //             className="text-tertiary text-4xl m-auto"
                //             style={{ cursor: "pointer" }}
                //           />
                //         </td>
                //       </tr>
                //     ))}
                //   </tbody>
                // </table>
                <h3 className="no-results-message">Nothing To Show !!</h3>
              )}
            </div>
          </section>
        </section>
      </div>
    </div>

    // <main className="w-full pr-8 dark:text-wheat pb-24">
    // <div className="dark:bg-primaryDark2 bg-primaryLight2 mt-8">
    // <PageTitle title="Salary Tempelates" route={'../..'}/>
    // </div>
    //    <div className='select-div mt-8'>
    //    <div></div>

    //   <div className='flex justify-evenly gap-8 mr-12 flex-wrap relative'>
    //  <button onClick={() => navigate('/settings/templates/add-tempelate')} className='inline-block px-16 py-4 text-xl font-medium text-center text-white bg-tertiary border-2 border-blue-400 rounded-full transition duration-300 ease-in-out whitespace-nowrap'>ADD SALARY TEMPELATE</button>
    //  </div>
    //  </div>

    //   <div className='inside-containt'>
    //      <div className='deduction-div'>
    //      <section
    //       className={
    //         "area-creation-content__info p-0"
    //       }
    //     >
    //   <p className='text-blue-400 font-normal'>Salary Tempelate</p>
    //   <div className="filter-table !mr-8">
    //   <h2 className="font-medium">SHOWING ({tableData.length}) ENTRIES</h2>
    // <table className="mt-8 w-full">
    //   <thead className="uppercase dark:text-secondary dark:bg-primaryDark2 w-full text-center bg-white">
    //     <th className="border-2 border-collapse border-primaryLight3 dark:border-primaryDark dark:text-secondary font-normal px-6 py-4">
    //       Salary Tempelate Name
    //     </th>
    //     <th className="border-2 border-collapse border-primaryLight3 dark:border-primaryDark dark:text-secondary font-normal px-6 py-4">
    //       Description
    //     </th>
    //     <th className="border-2 border-collapse border-primaryLight3 dark:border-primaryDark dark:text-secondary font-normal px-6 py-4">
    //       Status
    //     </th>
    //     <th className="border-2 border-collapse border-primaryLight3 dark:border-primaryDark dark:text-secondary font-normal px-6 py-4">
    //       Action
    //     </th>
    //   </thead>
    //   <tbody>
    //     {tableData?.map((el, idx) => (
    //       <tr key={idx} className="dark:bg-primary text-center bg-white">
    //         <td className="border-2 border-collapse border-primaryLight3 dark:border-primaryDark text-black dark:text-wheat px-6 py-6">
    //           {el?.tempName || "--"}
    //         </td>
    //         <td className="border-2 border-collapse border-primaryLight3 dark:border-primaryDark text-black dark:text-wheat px-6 py-6">
    //           {el?.description || "--"}
    //         </td>
    //         <td className="border-2 border-collapse border-primaryLight3 dark:border-primaryDark text-black dark:text-wheat px-6 py-6">
    //           <span className={el?.status == true ? "text-green-400" : "text-red-500"}>{el?.status == true ? "Active": "In-active" || "--"}</span>
    //         </td>
    //         <td className="border-2 border-collapse border-primaryLight3 dark:border-primaryDark text-black dark:text-wheat px-6 py-6 ">
    //           <FaPen
    //             className="text-tertiary text-4xl m-auto"
    //             style={{ cursor: "pointer" }}
    //           />
    //         </td>
    //       </tr>
    //     ))}
    //   </tbody>
    // </table>
    // </div>
    //     </section>
    //    </div>
    //   </div>
    //  </main>
  );
};

export default SalaryTemplates;
