import React from 'react'
import SideBar from '../../../components/SideBar/SideBar';
import { links, title } from './AdvSalaryNavData';
import { Outlet } from 'react-router-dom';

const AdvSalaryNav = () => {
  return (
    <>
     <SideBar title={title} links={links}/>
     <Outlet/>
    </>
  )
}

export default AdvSalaryNav;