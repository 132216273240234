import { createSlice } from "@reduxjs/toolkit";
import { PostReimbursementTypes, getReimbursementTypes } from "../services/reimbursement";

const reimbursementTypesSlice = createSlice({
  name: "reimbursementTypes",
  initialState: {
    types: [],
    loading: false,
    error: null,
  },
  reducers: {
    getReimbursementTypesStart(state) {
      state.loading = true;
      state.error = null;
    },
    getReimbursementTypesSuccess(state, action) {
      state.types = action.payload;
      state.loading = false;
      state.error = null;
    },
    getReimbursementTypesFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getReimbursementTypesFailure,
  getReimbursementTypesStart,
  getReimbursementTypesSuccess,
} = reimbursementTypesSlice.actions;

export const fetchReimbursementTypes = () => async (dispatch) => {
  try {
    dispatch(getReimbursementTypesStart());
    const response = await getReimbursementTypes();
    dispatch(getReimbursementTypesSuccess(response));
  } catch (error) {
    dispatch(getReimbursementTypesFailure(error));
  }
};

export const postReimbursementTypes = (payload) => async (dispatch) => {
  try {
    const res = await PostReimbursementTypes(payload);
    dispatch(fetchReimbursementTypes());
  } catch (error) {
    dispatch(getReimbursementTypesFailure(error));
  }
};

export default reimbursementTypesSlice.reducer;
