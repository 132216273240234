import React from 'react'
import { useSelector } from 'react-redux'
import PageTitle from '../../../../components/PageTitle';

const Profile = () => {

  const theme = useSelector(({theme}) => theme);
  const user = useSelector(({user}) => user.user);

  return (
    <main className="w-full">
      <div className={'creation-content m-8' + `${theme == "light" ? "light_bg" : ""}`}>

        <PageTitle title="My Profile"/>
      </div>
        <label className='mx-12'>
          Basic Info
        </label>
        <section className='user-detail-container user-detail-container-yellowDark-border m-8'>
        <div className={"user-detail-box " + `${theme == "light" ? "light_bg_logo" : ""}`}>
          <h4 className='!text-primaryDark dark:!text-primaryLight2'>Employee ID</h4>
          <p className='!text-primaryDark dark:!text-primaryLight2'>
            {user.uid}
          </p>
        </div>

        <div className={"user-detail-box " + `${theme == "light" ? "light_bg_logo" : ""}`}>
          <h4 className='!text-primaryDark dark:!text-primaryLight2'>First Name</h4>
          <p className='!text-primaryDark dark:!text-primaryLight2'>
          {user.firstName}
          </p>
        </div>

        <div className={"user-detail-box " + `${theme == "light" ? "light_bg_logo" : ""}`}>
          <h4 className='!text-primaryDark dark:!text-primaryLight2'>Middle Name</h4>
          <p className='!text-primaryDark dark:!text-primaryLight2'>
          {user.middleName ? user.middleName : "--"}
          </p>
        </div>

        <div className={"user-detail-box " + `${theme == "light" ? "light_bg_logo" : ""}`}>
          <h4 className='!text-primaryDark dark:!text-primaryLight2'>Last Name</h4>
          <p className='!text-primaryDark dark:!text-primaryLight2'>
          {user.lastName}
          </p>
        </div>

        <div className={"user-detail-box " + `${theme == "light" ? "light_bg_logo" : ""}`}>
          <h4 className='!text-primaryDark dark:!text-primaryLight2'>DATE OF JOINING</h4>
          <p className='!text-primaryDark dark:!text-primaryLight2'>
          {user.doj}
          </p>
        </div>

        <div className={"user-detail-box " + `${theme == "light" ? "light_bg_logo" : ""}`}>
          <h4 className='!text-primaryDark dark:!text-primaryLight2'>Designation</h4>
          <p className='!text-primaryDark dark:!text-primaryLight2'>
          {user.designation ? user.designation : "--"}
          </p>
        </div>

        <div className={"user-detail-box " + `${theme == "light" ? "light_bg_logo" : ""}`}>
          <h4 className='!text-primaryDark dark:!text-primaryLight2'>Gender</h4>
          <p className='!text-primaryDark dark:!text-primaryLight2'>
            {user.gender ? user.gender : "--"}
          </p>
        </div>

        <div className={"user-detail-box " + `${theme == "light" ? "light_bg_logo" : ""}`}>
          <h4 className='!text-primaryDark dark:!text-primaryLight2'>Email id</h4>
          <p className='!text-primaryDark dark:!text-primaryLight2'>
          {user.email}
          </p>
        </div>

        <div className={"user-detail-box " + `${theme == "light" ? "light_bg_logo" : ""}`}>
          <h4 className='!text-primaryDark dark:!text-primaryLight2'>Department</h4>
          <p className='!text-primaryDark dark:!text-primaryLight2'>
            {user.department?user.department: "--"}
          </p>
        </div>

        <div className={"user-detail-box " + `${theme == "light" ? "light_bg_logo" : ""}`}>
          <h4 className='!text-primaryDark dark:!text-primaryLight2'>Headquarter</h4>
          <p className='!text-primaryDark dark:!text-primaryLight2'>
          {user.headquarters?user.headquarters: "--"}
          </p>
        </div>
        </section>

        <label className='mx-12'>
          Location
        </label>

        <section className='user-detail-container user-detail-container-green-border m-8'>
        <div className={"user-detail-box " + `${theme == "light" ? "light_bg_logo" : ""}`}>
          <h4 className='!text-primaryDark dark:!text-primaryLight2'>Address</h4>
          <p className='!text-primaryDark dark:!text-primaryLight2'>
          {user.address?user.address.street1 + " " + user.address.street2: "--"}
          </p>
        </div>

        <div className={"user-detail-box " + `${theme == "light" ? "light_bg_logo" : ""}`}>
          <h4 className='!text-primaryDark dark:!text-primaryLight2'>City</h4>
          <p className='!text-primaryDark dark:!text-primaryLight2'>
          {user.address.city?user.address.city: "--"}
          </p>
        </div>

        <div className={"user-detail-box " + `${theme == "light" ? "light_bg_logo" : ""}`}>
          <h4 className='!text-primaryDark dark:!text-primaryLight2'>State</h4>
          <p className='!text-primaryDark dark:!text-primaryLight2'>
          {user.address.state?user.address.state: "--"}
          </p>
        </div>
        </section>

        <label className='mx-12'>
          Personal info
        </label>

        <section className='user-detail-container user-detail-container-yellowDark-border m-8'>
        <div className={"user-detail-box " + `${theme == "light" ? "light_bg_logo" : ""}`}>
          <h4 className='!text-primaryDark dark:!text-primaryLight2'>Date of birth</h4>
          <p className='!text-primaryDark dark:!text-primaryLight2'>
          {user.dob?user.dob: "--"}
          </p>
        </div>

        <div className={"user-detail-box " + `${theme == "light" ? "light_bg_logo" : ""}`}>
          <h4 className='!text-primaryDark dark:!text-primaryLight2'>PAN Number</h4>
          <p className='!text-primaryDark dark:!text-primaryLight2'>
          {user.pan?user.pan: "--"}
          </p>
        </div>

        <div className={"user-detail-box " + `${theme == "light" ? "light_bg_logo" : ""}`}>
          <h4 className='!text-primaryDark dark:!text-primaryLight2'>Aadhar Number</h4>
          <p className='!text-primaryDark dark:!text-primaryLight2'>
          {user.aadhaar?user.aadhaar: "--"}
          </p>
        </div>

        <div className={"user-detail-box " + `${theme == "light" ? "light_bg_logo" : ""}`}>
          <h4 className='!text-primaryDark dark:!text-primaryLight2'>Marital status</h4>
          <p className='!text-primaryDark dark:!text-primaryLight2'>
          {user.maritalStatus?user.maritalStatus: "--"}
          </p>
        </div>

        <div className={"user-detail-box " + `${theme == "light" ? "light_bg_logo" : ""}`}>
          <h4 className='!text-primaryDark dark:!text-primaryLight2'>Father's Name</h4>
          <p className='!text-primaryDark dark:!text-primaryLight2'>
          </p>
        </div>

         <div className={"user-detail-box " + `${theme == "light" ? "light_bg_logo" : ""}`}>
          <h4 className='!text-primaryDark dark:!text-primaryLight2'>Personal Email address</h4>
          <p className='!text-primaryDark dark:!text-primaryLight2'>
          </p>
        </div>
        </section>

        <label className='mx-12'>
          Statutory Info
        </label>

        <section className='user-detail-container user-detail-container-green-border m-8'>
        <div className={"user-detail-box " + `${theme == "light" ? "light_bg_logo" : ""}`}>
          <h4 className='!text-primaryDark dark:!text-primaryLight2'>EPF number</h4>
          <p className='!text-primaryDark dark:!text-primaryLight2'>
          </p>
        </div>

        <div className={"user-detail-box " + `${theme == "light" ? "light_bg_logo" : ""}`}>
          <h4 className='!text-primaryDark dark:!text-primaryLight2'>UAN Number</h4>
          <p className='!text-primaryDark dark:!text-primaryLight2'>
          </p>
        </div>

        <div className={"user-detail-box " + `${theme == "light" ? "light_bg_logo" : ""}`}>
          <h4 className='!text-primaryDark dark:!text-primaryLight2'>ESI Number</h4>
          <p className='!text-primaryDark dark:!text-primaryLight2'>
          </p>
        </div>
        </section>

        <label className='mx-12'>
          Payment Details
        </label>

        <section className='user-detail-container user-detail-container-yellowDark-border m-8'>
        <div className={"user-detail-box " + `${theme == "light" ? "light_bg_logo" : ""}`}>
          <h4 className='!text-primaryDark dark:!text-primaryLight2'>Account Type</h4>
          <p className='!text-primaryDark dark:!text-primaryLight2'>
          </p>
        </div>

        <div className={"user-detail-box " + `${theme == "light" ? "light_bg_logo" : ""}`}>
          <h4 className='!text-primaryDark dark:!text-primaryLight2'>Bank Name</h4>
          <p className='!text-primaryDark dark:!text-primaryLight2'>
          </p>
        </div>

        <div className={"user-detail-box " + `${theme == "light" ? "light_bg_logo" : ""}`}>
          <h4 className='!text-primaryDark dark:!text-primaryLight2'>Bank account name</h4>
          <p className='!text-primaryDark dark:!text-primaryLight2'>
          </p>
        </div>

        <div className={"user-detail-box " + `${theme == "light" ? "light_bg_logo" : ""}`}>
          <h4 className='!text-primaryDark dark:!text-primaryLight2'>Bank account Number</h4>
          <p className='!text-primaryDark dark:!text-primaryLight2'>
          </p>
        </div>

        <div className={"user-detail-box " + `${theme == "light" ? "light_bg_logo" : ""}`}>
          <h4 className='!text-primaryDark dark:!text-primaryLight2'>Ifsc Code</h4>
          <p className='!text-primaryDark dark:!text-primaryLight2'>
          </p>
        </div>
        </section>


    </main>
  )
}

export default Profile