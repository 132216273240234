import {RiCloseCircleLine} from "react-icons/ri" ;
import {MdOutlineEdit, MdDeleteOutline} from "react-icons/md" ;
import "./edit.css" ;

const EditPopup = ({ handleDelete, handleEdit, setShowEdit, value, setValue, label, placeholder }) => {

  return (
    <section className="edit-popup text-se">
      <div className="edit-content">
        <div className="edit-popup__heading">
          <h2 className="web-app__heading">Edit Details</h2>
          <RiCloseCircleLine
            className="edit-popup__close"
            onClick={() => setShowEdit(false)}
          />
        </div>

        <form className="w-full">
          <div className="edit-form">
            <div>
              <label htmlFor="division">
                {label}<span className="asterisk-imp">*</span>
              </label>
              <br />
              <input
                type="text"
                id="division"
                className="me-5 dark:bg-primary dark:text-secondary outline-none  !border-secondaryDark px-6 py-3 w-full my-3"
                placeholder={placeholder}
                value={value}
                onChange={({ target }) => setValue(target.value)}
              />
            </div>
          </div>

          <div className="edit-form-button-container ">
            <button onClick={(e)=>handleEdit(e)}  className="button-submit-green bg-success mt-4">
              <span className="button-submit-green__icon-container">
                <MdOutlineEdit className="button-submit-green__icon" />
              </span>
              <span className="button-submit-green__text">Edit</span>
            </button>

            <button
              
              className="button-delete mt-4 bg-error"
              onClick={(e) => handleDelete(e)}
            >
              <span className="button-delete__icon-container">
                <MdDeleteOutline className="button-delete__icon" />
              </span>
              <span className="button-delete__text">Delete</span>
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default EditPopup ;