import { createSlice } from "@reduxjs/toolkit";
import { GetEmployeesWithBirthDay } from "../../services/hrms";

const initialState = {
  data: [],
};

const birthDayReducer = createSlice({
  name: "birthDayReducer",
  initialState,
  reducers: {
    setEmployeesWithBirthDay: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setEmployeesWithBirthDay } = birthDayReducer.actions;

export const fetchBirthDays = () => async (dispatch) => {
  try {
    const data = await GetEmployeesWithBirthDay();

    dispatch(setEmployeesWithBirthDay(data));
  } catch (error) {
  }
};

export default birthDayReducer.reducer;
