import React from 'react'
import SideBar from '../../../components/SideBar/SideBar';
import { links, title } from './loanNavData';
import { Outlet } from 'react-router-dom';

const LoanNav = () => {
  return (
    <>
     <SideBar title={title} links={links}/>

     <Outlet/>
    </>
  )
}

export default LoanNav;