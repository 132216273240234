import { MdArrowBackIosNew, MdDelete } from "react-icons/md";
import {
  IoIosArrowDropdownCircle,
  IoIosArrowDropupCircle,
} from "react-icons/io";
import { useNavigate } from "react-router-dom";
import "../component.css";
import { useSelector } from "react-redux";
function PageTitle({
  title,
  isDelete,
  toggle,
  isDropdown,
  isDropup,
  hierarchy,
  route,
}) {
  const navigate = useNavigate();
  const theme = useSelector((state) => state.theme.theme);
  return (
    <>
      <section
        className={
          "pagetitle-heading " + `${theme == "light" ? "light_bg" : "  "}`
        }
      >
        <span
          className="flex items-center cursor-pointer"
          onClick={() => (hierarchy ? navigate(-1) : navigate(route))}
        >
          <MdArrowBackIosNew
            className={
              "pagetitle-backarrow " +
              `${theme == "light" ? "light_bg_logo" : "  "}`
            }
            onClick={() => (hierarchy ? navigate(-1) : navigate(route))}
          />
          <h2
            className={
              "pagetitle-header" +
              `${theme == "light" ? "light_bg_logo" : "  "}`
            }
          >
            {title}
          </h2>
        </span>
        {isDelete ? (
          <MdDelete
            className="icon-color-error ms-auto"
            style={{ cursor: "pointer" }}
            onClick={() => toggle(true)}
          />
        ) : null}
        {isDropdown ? (
          <IoIosArrowDropdownCircle
            className="icon-color-green ms-auto cursor-pointer expense-calculation__container-icon-money"
            onClick={() => toggle(true)}
          />
        ) : null}
        {isDropup ? (
          <IoIosArrowDropupCircle
            className="icon-color-green ms-auto cursor-pointer expense-calculation__container-icon-money"
            onClick={() => toggle(false)}
          />
        ) : null}
      </section>
    </>
  );
}

export default PageTitle;
