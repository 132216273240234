import React, { useEffect, useMemo } from "react";
import PageTitle from "../../../../components/PageTitle";
import { useNavigate } from "react-router-dom";
import { FaPen } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { fetchEsiDetails } from "../../../../reducers/esiDetailsReducer";
import Table from "../../../../components/Table";

const ESI = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useSelector((state) => state.theme.theme);

  let tableData = [useSelector((state) => state.esiInfo.data)];

  const handleAddEsi = () => {
    navigate("handle-esi", { state: { type: "add" } });
  };

  const handleEditEsi = () => {
    navigate("handle-esi", {
      state: {
        type: "edit",
        esiDetails: {
          esiNumber: tableData[0]?.esiNumber,
          deductionType: tableData[0]?.deductionType,
          employerContributionRate: tableData[0]?.employerContributionRate,
          employeeContributionRate: tableData[0]?.employeeContributionRate,
          includeEmployersContribution:
            tableData[0]?.includeEmployersContribution,
          overridePFContributionRate: tableData[0]?.overridePFContributionRate,
          componentOnPayslip: tableData[0]?.componentOnPayslip,
          enableEsi: tableData[0]?.enableEsi,
        },
      },
    });
  };

  useEffect(() => {
    dispatch(fetchEsiDetails());
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "ESI Number",
        accessor: (row) => row?.esiNumber || "--",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Deduction Type",
        accessor: (row) => row?.deductionType?.label || "--",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Employer Contribution",
        accessor: (row) => row?.employerContributionRate?.label || "--",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Employee Contribution",
        accessor: (row) => row?.employeeContributionRate?.label || "--",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Status",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return (
            <div
              className={`${
                props.row?.original.isActive || props.row?.original?.enableEsi
                  ? "text-green-500"
                  : "text-red-600"
              }`}
            >
              {props.row?.original?.isActive || props.row?.original?.enableEsi
                ? "Active"
                : "Inactive"}
            </div>
          );
        },
      },
      {
        Header: "Action",
        disableFilters: true,
        disableSortBy: true,
        Cell: () => {
          return (
            <FaPen
              onClick={handleEditEsi}
              className="text-tertiary text-3xl m-auto"
              style={{ cursor: "pointer" }}
            />
          );
        },
      },
    ],
    [],
  );

  return (
    <main className="main-content bg-primaryLight2 dark:bg-primaryDark1">
      <div
        className={
          "area-creation-content" + `${theme == "light" ? "light_bg" : ""}`
        }
      >
        <PageTitle title="ESI" route={"../.."} />

        <section className="admin-content">
          <div className="mx-8 pb-10">
            {tableData?.length > 0 && (
              <h2 className="show-entries">
                Showing ({tableData.length}) entries
              </h2>
            )}

            {tableData[0]?.docName == "esiInfo" ? (
              <div className="filter-table">
                <Table columns={columns} data={tableData} exportExcel={false} />
              </div>
            ) : (
              <div>
                <button
                  onClick={handleAddEsi}
                  className="rounded-full font-medium text-2xl px-24 py-5 dark:bg-tertiary cursor-pointer transform hover:scale-95"
                >
                  ADD ESI
                </button>
              </div>
            )}
          </div>
        </section>
      </div>
    </main>

    // <main
    //   className={"main-content " + `${theme == "light" ? "light_bg_main" : ""}`}
    //   style={{ width: "100%" }}
    // >
    //   <div className="creation-content">
    // <PageTitle title="ESI" route={'../..'}/>
    //   </div>

    // {tableData[0]?.docName == "esiInfo" ? (
    //   <div className="filter-table !mr-8">
    //     {/* <Table columns={columns} data={tableData} exportExcel={false} /> */}
    //     <table className="mt-8 w-full">
    //       <thead className="uppercase dark:text-secondary dark:bg-primaryDark2 w-full text-center">
    //         <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">
    //           ESI Number
    //         </th>
    //         <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">
    //           Deduction Type
    //         </th>
    //         <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">
    //           Employer Contribution
    //         </th>
    //         <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">
    //           Employee Contribution
    //         </th>
    //         <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">
    //           Status
    //         </th>
    //         <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">
    //           Action
    //         </th>
    //       </thead>
    //       <tbody>
    //         {tableData?.map((el, idx) => (
    //           <tr key={idx} className="dark:bg-primary text-center">
    //             <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6">
    //               {el?.esiNumber || "Loading ..."}
    //             </td>
    //             <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6">
    //               {el?.deductionType?.label || "Loading ..."}
    //             </td>
    //             <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6">
    //               {el?.employerContributionRate.label || "Loading ..."}
    //             </td>
    //             <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6">
    //               {el?.employeeContributionRate.label || "Loading ..."}
    //             </td>
    //             <td className={`border-2 border-collapse border-primarrDark border-primaryDark  px-6 py-6 ` +`${el?.enableEsi ? "text-green-500" : "text-red-600"}`}>
    //               {el?.enableEsi ? "Active" : "Inactive"}
    //             </td>
    //             <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6 ">
    //               <FaPen
    //                 onClick={handleEditEsi}
    //                 className="text-tertiary text-3xl m-auto"
    //                 style={{ cursor: "pointer" }}
    //               />
    //             </td>
    //           </tr>
    //         ))}
    //       </tbody>
    //     </table>
    //   </div>
    // ) : (
    //   <div>
    //     <button
    //       onClick={handleAddEsi}
    //       className="rounded-full font-medium text-2xl px-24 py-5 dark:bg-tertiary cursor-pointer transform hover:scale-95"
    //     >
    //       ADD ESI
    //     </button>
    //   </div>
    // )}
    // </main>
  );
};

export default ESI;
