import { Outlet } from "react-router-dom";
import SideBar from "../../components/SideBar/SideBar.jsx";
import { title, links } from "./navData.js";
import "./onboarding.css";
import { useSelector } from "react-redux";

const EmployeeHome = () => {
  const theme = useSelector((state) => state.theme.theme);
  
  return (
    <div className="flex w-full bg-primaryLight2 dark:bg-primaryDark1">
      <SideBar title={title} links={links} />
      <Outlet />
    </div>
  );
};

export default EmployeeHome;
