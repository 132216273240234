export const title = "Investment";

export const links = [
  {
    name: "Create POI",
    route: "create-POI",
    accessor: "create-POI",
  },
  // {
  //   name: "POI History",
  //   route: "history-POI",
  //   accessor: "history-POI",
  // },
];
