import React, { useState } from "react";
import Select from "react-select";
import { setUpSelectStyles } from "../../../utils/selectStyles";
import ToggleButton from "../../../components/Toggle/ToggleButton/ToggleButton";
const Lwf = () => {
  const [toggle, setToggle] = useState(false);
  return (
    <div
      className="comp cursor-pointer"
      onClick={() => setToggle((pre) => !pre)}
    >
      <div className="heading_container">
        <h1>
          Laboure Welfare Fund
          <ToggleButton
            disabled={false}
            value={toggle}
            onChange={() => setToggle(!toggle)}
          />
        </h1>

        <p>
          Any Company with more than 20 Employees Any Company with more than 20
          Employees Any Company with more than 20 Employees
        </p>
      </div>
      {/* <form className={"set-up " + `${toggle ? "show" : "hide"}`}>
        <div className="two_in_one">
          <div>
            <label htmlFor="">EPF Number</label>
            <br />
            <input type="text" placeholder="AA/AAA/0000000/000" />
          </div>
          <div>
            <label htmlFor="">Deduction Type</label>
            <br />
            <Select styles={setUpSelectStyles} />
          </div>
        </div>
        <div>
          <label htmlFor="">Employer Contribution Rate</label>
          <br />
          <Select styles={setUpSelectStyles} placeholder="12% of Basic + DA" />
        </div>
        <div>
          <label htmlFor="">Employer Contribution Rate</label>
          <br />
          <Select
            styles={setUpSelectStyles}
            isDisabled
            placeholder="12% of Basic + DA"
          />
        </div>
        <div className="terms">
          <span className="text-green-500 my-2">View Sample Calculation</span>
          <div>
            <input type="checkbox" />
            <span>Include Employers Contribution in the CTC</span>
          </div>
          <div>
            <input type="checkbox" />
            <span>Override PF Contribution Rate at employee Level</span>
          </div>
        </div>
        <div className="mt-4">
          <button className="submit_button">Enable</button>
          <span className="skip">cancel</span>
        </div>
      </form> */}
    </div>
  );
};

export default Lwf;
