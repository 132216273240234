import React from "react";
import SideBar from '../../../components/SideBar/SideBar'
import {title,links} from './navdata.js'
import { Outlet } from 'react-router-dom'

const SyncAttendance = () => {
  return <>
      <SideBar title={title} links={links}></SideBar>
          <Outlet></Outlet>
  </>
};

export default SyncAttendance;
