export const title = "Upload Documents";

export const links = [
  {
    name: "Pan Card",
    route: "pan-card",
    accessor: "pan-card",
  },
  {
    name: "Aadhar Card",
    route: "aadhar-card",
    accessor: "upload documents",
  },
  {
    name: "Passport",
    route: "passport",
    accessor: "passport",
  },
  {
    name: "Driver licence",
    route: "driver-licence",
    accessor: "driver licence",
  },
  {
    name: "Voter Id",
    route: "voters-id",
    accessor: "voters id",
  },
];
