import { DESIGNATIONS } from "./enums";

const serialise = (arr) => {
  if (!Array.isArray(arr)) return [];
  if (!arr) return;
  let sr = 0;
  const newArr = arr.map((e) => {
    sr++;

    const newObj = { sr };

    for (const [key, value] of Object.entries(e)) {
      if (
        typeof value === "object" &&
        !Array.isArray(value) &&
        value !== null
      ) {
        if (key === "stockist") {
          newObj[key] = value.businessName;
        } else if (
          key !== "workAreas" &&
          key !== "address" &&
          key !== "category" &&
          key !== "type" &&
          key !== "product" &&
          key !== "submitter" &&
          key !== "user" &&
          key !== "panDetails" &&
          key !== "aadharDetails" &&
          key !== "voterCardDetails" &&
          key !== "passportDetails" &&
          key !== "drivingLicenceDetails"
        ) {
          value.name ? (newObj[key] = value.name) : (newObj[key] = value.label);
        } else {
          newObj[key] = value;
        }
      } else {
        newObj[key] = value;
      }
    }

    return newObj;
  });
  return newArr;
};

export const serialiseChemStk = (arr) => {
  if (!arr) return;
  let sr = 0;

  const newArr = arr.map((e) => {
    sr++;
    const newObj = { sr };

    for (const [key, value] of Object.entries(e)) {
      if (
        typeof value === "object" &&
        !Array.isArray(value) &&
        value !== null
      ) {
        if (key !== "workAreas") newObj[key] = value.businessName;
        else newObj[key] = value;
      } else newObj[key] = value;
    }

    return newObj;
  });

  return newArr;
};

export const serialiseGift = (arr) => {
  if (!arr) return;
  let sr = 0;

  const newArr = arr.map((e) => {
    sr++;
    const newObj = { sr };

    for (const [key, value] of Object.entries(e)) {
      if (
        typeof value === "object" &&
        !Array.isArray(value) &&
        value !== null
      ) {
        if (key !== "workAreas") newObj[key] = value.gift;
        else newObj[key] = value;
      } else newObj[key] = value;
    }

    return newObj;
  });

  return newArr;
};

export const serialiseSSG = (arr) => {
  if (!arr) return;
  let sr = 0;

  const newArr = arr.map((e) => {
    sr++;
    const newObj = { sr };

    for (const [key, value] of Object.entries(e)) {
      if (
        typeof value === "object" &&
        !Array.isArray(value) &&
        value !== null
      ) {
        if (typeof key?.prod === "object") newObj[key] = value.focusProduct;
        else if (key?.docID === "docID") newObj[key] = value.name;
        else newObj[key] = value;
      } else newObj[key] = value;
    }

    return newObj;
  });

  return newArr;
};

export const generateSelectDefaultValue = (select, label) => {
  return select.find((e) => e.label === label)
};

export const generateSelectData = (arr, name) => {
  // const selectData = [{value:0,label:"All"}];
  const selectData = [];

  arr.forEach((e) => {
    selectData.push({
      label:
        name === "fullName" ? `${e["firstName"]} ${e["lastName"]}` : e[name],
      value: e._id,
      ...e,
    });
  });
  return selectData;
};

export const generateValueSelectData = (arr, name) => {
  const selectData = [{ value: 0, label: "EmployeeUID" }];
  // const selectData = [];

  arr.forEach((e, i) => {
    selectData.push({
      label:
        name === "fullName" ? `${e["firstName"]} ${e["lastName"]}` : e[name],
      value: i + 1,
      ...e,
    });
  });

  return selectData;
};



export const generateSelectDataForSalaryTemp = (arr, name) => {
  const selectData = [];

  arr.forEach((e) => {
    if (e?.isActive || e?.active || e?.enableEpf || e?.enableEsi) {
      selectData.push({
        label:
          name === "fullName" ? `${e["firstName"]} ${e["lastName"]}` : e[name],
        value: e._id,
        ...e,
      });
    }
    else {
      return selectData;
    }
  });
  return selectData;
};


export const generateSelectDataForEarnings_SalaryTemp = (arr, name) => {
  const selectData = [];

  arr.forEach((e) => {
    if ((e?.isActive || e?.active) && e?.paytype !== 'variable') {
      selectData.push({
        label:
          name === "fullName" ? `${e["firstName"]} ${e["lastName"]}` : e[name],
        value: e._id,
        ...e,
      });
    }
    else {
      return selectData;
    }
  });
  return selectData;
};

export const generateSelectDataForLoans = (arr) => {
  const selectData = [];

  arr.forEach((loan) => {
    selectData.push({
      label: loan.loanName,
      value: loan._id,
    });
  });

  return selectData;
};

export const generateSelectDataForReimbursement = (arr) => {
  const selectData = [];

  arr.forEach((r) => {
    if (r.isActive || r.active) {
      selectData.push({
        label:
          r.reimbursementType.name,
        value: r._id,
        ...r,
      });
    }
  });

  return selectData;
};


export const generateSelectDatas = (arr, name) => {
  const selectData = [];

  if (Array.isArray(arr)) {
    arr.forEach((e) => {
      selectData.push({
        label:
          name === "fullName" ? `${e["firstName"]} ${e["lastName"]}` : e[name],
        value: e._id,
        ...e,
      });
    });
  } else if (typeof arr === 'object') {
    selectData.push({
      label:
        name === "fullName" ? `${arr["firstName"]} ${arr["lastName"]}` : arr[name],
      value: arr._id,
      ...arr,
    });
  }

  return selectData;
};

export const generateSelectDataWithNameAndId = (arr) => {
  const selectData = [];

  arr.forEach((e) => {
    selectData.push({
      label: e.name,
      value: e._id,
      ...e
    });
  });

  return selectData;
};

export const generateMultiSelectData = (arr, name) => {
  const selectData = [];

  arr.forEach((e) => {
    selectData.push({
      label:
        name === "fullName" ? `${e["firstName"]} ${e["lastName"]}` : e[name],
      value: e._id,

    });
  });

  return selectData;
};

export const generateSelectDataForOnboarding = (arr) => {
  const selectData = [];

  arr.forEach((e) => {
    selectData.push({
      label: e.divisionName || e.processName,
      value: e._id,
      ...e
    });
  });

  return selectData;
};

export const generateSelectRoute = (arr) => {
  const selectData = [];

  arr.forEach((e) => {
    selectData.push({
      label: `${e.fromCity?.name} - ${e.toCity?.name}`,
      value: e._id,
      distance: e.distance,
      type: e.areatype,
    });
  });

  return selectData;
};

export const generateSelectUser = (arr, name) => {
  const selectData = [];

  arr.forEach((e) => {
    selectData.push({
      label:
        name === "fullName" ? `${e["firstName"]} ${e["lastName"]}` : e[name],
      value: e._id,
      designation: e?.des,
    });
  });

  return selectData;
};

export default serialise;

export const addIndex = (array) => {
  array?.forEach(function (item, index) {
    item.sr = index + 1;
  });
  return array;
};

export const addIndex2 = (array) => {
  return array.map((item, index) => {
    return { ...item, sr: index + 1 };
  });
};

export const createSetForAttendance = (array, leaveData) => {
  let set = new Set();
  let leaves = [];

  var getDaysArray = function (start, end) {
    for (
      let dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      leaves.push(new Date(dt));
    }
  };

  leaveData.forEach((elem) => getDaysArray(elem.startDate, elem.endDate));

  array.forEach(function (item) {
    set.add(`present:${new Date(item?.cdt).getDate()}`);
  });

  leaves.forEach(function (item) {
    set.add(`leave:${item.getDate()}`);
  });

  return set;
};

export const getDesignation = (desig) => {
  let Des = DESIGNATIONS.find((e) => e.value === desig);
  return Des?.name;
};
