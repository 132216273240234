import React, { useEffect, useMemo, useState } from 'react'
import './deduction.css'
import { tableData} from '../earning/upperNavData'
import { Link, useNavigate } from 'react-router-dom'
import Table from "../../../../components/Table";
import { FaEye } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { deleteDeduction, getPostDeductions , getPreDeductions } from '../../../../reducers/deductionReducer';
import serialise from '../../../../utils/serialiseResponse';
import { BsThreeDotsVertical } from "react-icons/bs";
import PageTitle from '../../../../components/PageTitle';
import Dropdown from '../../../../components/DropDown/dropdown';
import { SlOptionsVertical } from "react-icons/sl";
import DeletePopUp from '../reimbursement/DeletePopup';
import { DeleteDeduction } from '../../../../services/deduction';
import customToast from '../../../../components/CustomToast';

const Deduction = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useSelector((state) => state.theme.theme);
  const [showBtn , setShownBtn] = useState(false);
  const preDeduction = useSelector((state) => state.deduction.preData);
  const postDeduction = useSelector((state) => state.deduction.postData);
  const [showPopup, setShowPopup] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    dispatch(getPreDeductions());
    dispatch(getPostDeductions());
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Sr no.",
        accessor: "sr",
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: "Name",
        accessor: "nameOnPaySlip",
      },
      {
        Header: "Deduction Type",
        accessor: "deductionType",
      },
      {
        Header: "Deduction Frequency",
        accessor: "deductionFrequency",
      },
      {
        Header: "Status",
        accessor: "isActive",
        Cell: (props) => (
          <span
            style={{
              color: props.value ? "#21C38B" : "red",
            }}
          >
            {props.value ? "Active" : "In-active"}
          </span>
        ),
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => (
          <div>
           <Dropdown
                icon={
                  <SlOptionsVertical className="icon-color-tertiary" />
                }
                options={[
                  {
                    text: 'Edit',
                    onClick: () => {
                      navigate("./edit-pre-deduction",{state: props?.row?.original})
                    }
                  },
                  {
                    text: 'Delete',
                    onClick: () => {
                      setDeleteId(props?.row?.original?._id);
                      setShowPopup(true);
                    }
                  },
                ]}
              />
          </div>

        ),
      },
    ],
    [],
  );

const preTableData = useMemo(() => serialise(preDeduction) , [preDeduction]);
const postTableData = useMemo(() => serialise(postDeduction) , [postDeduction]);

const handleCancel = () => {
  setShowPopup(false);
};


const handleDelete = async(e) => {
  e.preventDefault();

  try {
    let payload = {
      id : deleteId
    };

    // await DeleteDeduction(payload);
    dispatch(deleteDeduction(payload));
    setDeleteId(null);
    setShowPopup(false);
    customToast.success("Deduction deleted successfully !");
  } catch (error) {
    console.log(error);
    setShowPopup(false);
    setDeleteId(null);
    customToast.error("Error while deleting deduction !");
  }
};

  return (
    <div
      className={"main-content " + `${theme == "light" ? "light_bg_main" : ""}`}
      style={{ width: "100%" }}
    >
      <div
        className={
          "creation-content " + `${theme == "light" ? "light_bg" : ""}`
        }
      >
        <PageTitle title="Deduction" route={'../..'} />

        <section className="admin-content mx-8 py-8">
          <div className='w-full flex flex-col'>
            <button
              className="rounded-full w-max font-medium text-2xl px-10 py-5 text-white dark:bg-tertiary cursor-pointer"
              onClick={() => setShownBtn((prev) => !prev)}
            >ADD DEDUCTION</button>
            <div
              className={` ${showBtn
                ? "p-4 rounded-md bg-primaryDark w-max flex flex-col "
                : "hidden"
                } `}
            >
              <Link to={'/settings/salary/deduction/add-pre-deduction'} className="hover:bg-primaryDark2 p-4 rounded-md cursor-pointer">
                <span>PRE TAX DEDUCTION</span>
              </Link>
              <Link to={'add-post-deduction'} className="hover:bg-primaryDark2 p-4 rounded-md cursor-pointer">
                <span>POST TAX DEDUCTION</span>
              </Link>
            </div>
          </div>

          <section className='py-6 mt-12'>
            <p className='text-blue-400'>PRE-TAX DEDUCTION</p>

            {preTableData?.length > 0 && (
              <h2
                className={
                  "show-entries" +
                  `${theme === "light" ? "light_bg_logo" : ""}`
                }
              >
                Showing ({preTableData.length}) Entries
              </h2>
            )}
            <div
              className={
                "filter-table " + `${theme === "light" ? "table_light" : ""}`
              }
            >
              {preTableData?.length > 0 ? (
                <Table columns={columns} data={preTableData} />
              ) : (
                <h3 className="no-results-message">Nothing To Show !!</h3>
              )}
            </div>
          </section>

          <section className='pt-6'>
            <p className='text-green-400'>POST TAX DEDUCTION</p>

            {postTableData?.length > 0 && (
              <h2
                className={
                  "show-entries" +
                  `${theme === "light" ? "light_bg_logo" : ""}`
                }
              >
                Showing ({postTableData.length}) Entries
              </h2>
            )}
            <div
              className={
                "filter-table " + `${theme === "light" ? "table_light" : ""}`
              }
            >
              {postTableData?.length > 0 ? (
                <Table columns={columns} data={postTableData} />
              ) : (
                <h3 className="no-results-message">Nothing To Show !!</h3>
              )}
            </div>
            {showPopup ? 
            (
            <DeletePopUp
            label="Are you sure you want to delete ? " 
            handleCancel={handleCancel}
            handleDelete={handleDelete}
            setShowEdit={setShowPopup}/>
          )
          :
          null
          }
          </section>
        </section>
      </div>
    </div>
  )
}

export default Deduction;


// <main className={"main-content " + `${theme == "light" ? "light_bg_main" : ""}`} style={{ width: '100%' }}>
    //   <div className="dark:bg-primaryDark2 mt-8">
    // <PageTitle title="Deduction" route={'../..'} />
    //   </div>
    //   <div className='select-div mt-8'>
    //     <div className='flex justify-evenly gap-8 mr-12 flex-wrap relative'>
    // <button className='inline-block px-16 py-4 text-xl font-medium text-center text-white bg-tertiary border-2 border-blue-400 rounded-full transition duration-300 ease-in-out whitespace-nowrap' onClick={() => setShownBtn((prev) => !prev)}>ADD DEDUCTION</button>
    // {showBtn &&
    //   <div className="absolute dark:bg-primaryDark2 w-auto flex flex-col top-16">
    //     <Link to={'/settings/salary/deduction/add-pre-deduction'}>
    //       <button className="p-4 text-lg">PRE TAX DEDUCTION</button>
    //     </Link>
    //     <Link to={'add-post-deduction'}>
    //       <button className="p-4 text-lg">POST TAX DEDUCTION</button>
    //     </Link>
    //   </div>
    // }
    //     </div>
    //   </div>

    //   <div className='inside-containt'>
    //     <section
    //       className={
    //         "area-creation-content__info p-0 "
    //       }
    //     >
    // <p className='text-blue-400'>PRE-TAX DEDUCTION</p>
    //       {tableData.length > 0 && (
    // <h2 className={"mt-5 pt-5 ms-2 web-app__heading " + `${theme === 'light' ? "light_bg_logo" : ""}`}>
    //   Showing ({preTableData.length}) Entries
    // </h2>
    //       )}

    //       {tableData.length > 0 ? (
    //         <div className={"filter-table " + `${theme === 'light' ? "table_light" : ""}`}>
    // <Table columns={columns} data={preTableData} />
    //         </div>
    //       ) : (
    //         ""
    //       )}
    //     </section>

    //     <div className='deduction-div'>
    //       <section
    //         className={
    //           "area-creation-content__info p-0"
    //         }
    //       >
    // <p className='text-green-400'>POST TAX DEDUCTION</p>
    //         {tableData.length > 0 && (
    //           <h2 className={"mt-5 pt-5 ms-2 web-app__heading " + `${theme === 'light' ? "light_bg_logo" : ""}`}>
    // Showing ({postTableData.length}) Entries
    //           </h2>
    //         )}

    //         {tableData.length > 0 ? (
    //           <div className={"filter-table " + `${theme === 'light' ? "table_light" : ""}`}>
    // <Table columns={columns} data={postTableData} />
    //           </div>
    //         ) : (
    //           ""
    //         )}
    //       </section>

    //     </div>
    //   </div>
    // </main>
