import { createSlice } from "@reduxjs/toolkit";
import { getAllLeaves } from "../services/leave";


const leaveReducerSlice = createSlice({
  name: "leaves",
  initialState: {
    data: [],
  },
  reducers: {
    setLeaves: (state, action) => {
      state.data = action.payload;
    },
  },
});

const { setLeaves } =   leaveReducerSlice.actions;

export const fetchAllLeaves = () => async (dispatch) => {
    try {
      const res = await getAllLeaves();
      dispatch(setLeaves(res.data));
        
    } catch (error) {
      console.log(error)
    }
};


export default leaveReducerSlice.reducer;
