export const title = "Sync ATTENDANCE";

export const links = [
  {
    name: "REGULAR ATTENDANCE",
    route: "regular-attendance",
    accessor: "regular",
  },
//   {
//     name: "DAY COUNT ATTENDANCE",
//     route: "day-count",
//     accessor: "day-count",
//   },
//   {
//     name: "ATTENDANCE Summary",
//     route: "attendance-summary",
//     accessor: "attendance-summary",
//   },
  
  
];