import React, { useEffect, useMemo, useState } from 'react'
import PageTitle from '../../../components/PageTitle'
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../../components/Table';
import { adminStyles, adminStylesLight } from "../../../utils/selectStyles";
import Dropdown from '../../../components/DropDown/dropdown';
import { BsThreeDotsVertical } from "react-icons/bs";
import { fetchAllOnboardProcesses, fetchAllOnboardingEmployeeTasks } from '../../../reducers/onboardingDivisionsReducer'
import { getAllOnboardingEmployeeTasks, getEmployeeTasks, updateStatusOfEmpTasks } from '../../../services/onboarding'
import Modal from '../../../components/Modal/index'
import customToast from '../../../components/CustomToast';

const index = () => {

  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.theme);
  const processes = useSelector((state) => state.onboardDivisions.processesData);
  const data = useSelector((state) => state.onboardDivisions.employeeTasks);
  const [employeesData, setemployeesData] = useState([])
  const [taskListModal, settaskListModal] = useState(false);
  const [userProcessDets, setuserProcessDets] = useState();

  const getInitiatedEmployees = async () => {
    let allEmployees = [];
    const res = await processes?.forEach(item => {
      allEmployees = allEmployees.concat(item.employees);
    });
    setemployeesData(allEmployees)
  }

  const columns = useMemo(
    () => [
      {
        Header: "EMPLOYEE UID",
        accessor: "uid",
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row }) => {

          const doc = row.original;

          return (
            <>
              {doc.employee.uid ?
                <div>
                  <span
                    className='cursor-pointer hover:underline'
                    onClick={() => {
                      setuserProcessDets(row.original);
                      settaskListModal(true)
                    }}
                  >
                    {doc.employee.uid}
                  </span>
                </div>
                :
                "--"
              }
            </>
          );
        },
      },
      {
        Header: "EMPLOYEE NAME",
        accessor: (row) => row.employee?.firstName + " " + row.employee?.lastName,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "DATE OF JOINING",
        disableFilters: true,
        disableSortBy: true,
        accessor: (row) => new Date(row.employee.joiningDate).toLocaleDateString('en-IN'),
      },
      {
        Header: "STATUS",
        accessor: "status",
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <>
              {row.original?.status === 'Initiated' ? (
                <span className='text-[#f7b500]'>{row.original.status}</span>
              ) : row.original.status === 'Pending' ? (
                <span className='text-[#e62626]'>{row.original.status}</span>
              ) : (
                <span className='text-[#00b74a]'>{row.original.status}</span>
              )
              }
            </>
          );
        },
      },
      {
        Header: "ACTION",
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <>
              <Dropdown
                icon={
                  <BsThreeDotsVertical className='text-white cursor-pointer text-3xl' />
                }
                options={[
                  {
                    text: 'Initiated',
                    onClick: async () => {
                      try {
                        const res = await updateStatusOfEmpTasks({ id: row.original._id, status: 'Initiated' });
                        if (res) {
                          dispatch(fetchAllOnboardingEmployeeTasks());
                          return customToast.success("Status updated successfully");
                        }
                      }
                      catch (err) {
                        if(err.response.data.message === "Bad Request!"){
                          return customToast.error(err.response.data.data)
                        }
                        return customToast.error("Something went wrong")
                      }
                    }
                  },
                  {
                    text: 'Pending',
                    onClick: async () => {
                      try {
                        const res = await updateStatusOfEmpTasks({ id: row.original._id, status: 'Pending' });
                        if (res) {
                          dispatch(fetchAllOnboardingEmployeeTasks());
                          return customToast.success("Status updated successfully")
                        }
                      }
                      catch (err) {
                        if(err.response.data.message === "Bad Request!"){
                          return customToast.error(err.response.data.data)
                        }
                        return customToast.error("Something went wrong")
                      }
                    }
                  },
                  {
                    text: 'Completed',
                    onClick: async () => {
                      try {
                        const res = await updateStatusOfEmpTasks({ id: row.original._id, status: 'Completed' });
                        if (res) {
                          dispatch(fetchAllOnboardingEmployeeTasks());
                          return customToast.success("Status updated successfully")
                        }
                      }
                      catch (err) {
                        if(err.response.data.message === "Bad Request!"){
                          return customToast.error(err.response.data.data)
                        }
                        return customToast.error("Something went wrong")
                      }
                    }
                  },
                ]}
              />
            </>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(fetchAllOnboardingEmployeeTasks());
  }, [])

  return (

    <main className={"main-content " + `w-full ${theme == "light" ? "light_bg_main" : ""}`}>
      <div className={"area-creation-content " + `${theme == "light" ? "light_bg" : ""}`}>
        <PageTitle title="Employees Onboarding" route={"../../"} />
      </div>

      <section>
        {data?.length > 0 ? (
          <div>
            <section className={"area-creation-content__info py-0 p-0"}>
              <h2 className='show-entries'>
                SHOWING ({data?.length}) ENTRIES
              </h2>

              <div className="filter-table pr-8">
                <Table columns={columns} data={data} />
              </div>
            </section>
          </div>
        ) : (
          <div className='mt-12'>
            <span className='text-[#e62626] text-2xl font-semibold'>No Onboarding Data Available</span>
          </div>
        )}
      </section>

      <Modal
        showModal={taskListModal}
        closeModal={() => settaskListModal(false)}
        title={userProcessDets?.employee.firstName + " " + userProcessDets?.employee.lastName}
        width="w-max"
        content={
          <div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                settaskListModal(false)
              }}
            >
              <table className="mt-8 w-full">
                <thead className="uppercase dark:text-secondary dark:bg-primaryDark2 w-full text-center">
                  <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">
                    Task List
                  </th>
                  <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">
                    Attachments
                  </th>
                  <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">
                    Due Date
                  </th>
                  <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">
                    Status
                  </th>
                </thead>
                <tbody>
                  {userProcessDets?.tasks.map((item, idx) => (
                    <tr key={idx} className="dark:bg-primary text-center">
                      <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6">
                        {item.taskDetails.taskName}
                      </td>
                      <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6">
                        {item.taskDetails.file ? (
                          <a
                            href={item.taskDetails.file}
                            className="text-blue-500 hover:underline"
                          >
                            Download File
                          </a>
                        ) : (
                          <span className="text-red-500">No Attachment</span>
                        )}
                      </td>
                      <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6">
                        <span>
                          {item.taskDetails.dueDays > 1 ? item.taskDetails.dueDays + " Day(s) " : item.taskDetails.dueDays + " Day "} after joining date
                        </span>
                      </td>
                      <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6">
                        {item.status === "Pending" ? (
                          <button
                            className='button-blue-gradient'
                            onClick={() => {
                            }}
                          >
                            Complete
                          </button>
                        ) : (
                          <button className='pointer-events-none button-green-gradient'>Completed</button>
                        )
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </form>
          </div>
        }
      />
    </main >
  )
}

export default index
