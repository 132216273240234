import React, { useReducer, useState } from "react";
import Select from "react-select";
import {
  adminStyles,
  adminStylesLight,
  selectStyles,
} from "../../../../../utils/selectStyles";
import PageTitle from "../../../../../components/PageTitle";
import { useSelector } from "react-redux";
import { MdOutlineCheckBoxOutlineBlank } from 'react-icons/md';
import { IoCheckbox } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import customToast from "../../../../../components/CustomToast";
import { addEsi, updateEsi } from "../../../../../services/hrms";

const deductionTypes = [
  { value: "monthly", label: "Monthly" },
  { value: "annual", label: "Annual" },
];

const employerContributionRateData = [
  { value: 3.25, label: "3.25% of Gross Salary" },
];

const employeeContributionRateData = [
  { value: 0.75, label: "0.75% of Gross Salary" },
];

const AddUpdateEsi = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useSelector((state) => state.theme.theme);
  const [active, setActive] = useState(location.state?.esiDetails?.enableEsi||false);

  const [esiNumber, setEsiNumber] = useState(
    location.state?.esiDetails?.esiNumber || null,
  );
  const [deductionType, setDeductionType] = useState(
    location.state?.esiDetails?.deductionType || null,
  );
  const [employerContributionRate, setEmployerContributionRate] = useState(
    location.state?.esiDetails?.employerContributionRate || null,
  );
  const [employeeContributionRate, setEmployeeContributionRate] = useState(
    location.state?.esiDetails?.employeeContributionRate || null,
  );
  const [includeEmployersContribution, setIncludeEmployersContribution] =
    useState(location.state?.esiDetails?.includeEmployersContribution || false);
    const [componentOnPayslip, setComponentOnPayslip] =
    useState(location.state?.esiDetails?.componentOnPayslip || false);  
  const [overridePFContributionRate, setOverridePFContributionRate] = useState(
    location.state?.esiDetails?.overridePFContributionRate || false,
  );
  const [isActive, setIsActive] = useState(
    true || false,
  );

  console.log(location , "location")
  const handleEditEsi = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        esiNumber: esiNumber,
        deductionType: deductionType,
        employerContributionRate: employerContributionRate,
        employeeContributionRate: employeeContributionRate,
        includeEmployersContribution: includeEmployersContribution,
        overridePFContributionRate: overridePFContributionRate,
        componentOnPayslip,
        isActive,
      };
      await updateEsi(payload);
      customToast.success("ESI updated successfully");
      navigate("/settings/statutory/esi");
    } catch (error) {
      customToast.error("Failed to update ESI");
    }
  };
  const handleAddEsi = async (e) => {
    e.preventDefault();

    if (!esiNumber || !deductionType || !employerContributionRate || !employeeContributionRate) {
      customToast.error("Please fill all the fields");
      return;
    }

    try {
      const payload = {
        esiNumber: esiNumber,
        deductionType: deductionType,
        employerContributionRate: employerContributionRate,
        employeeContributionRate: employeeContributionRate,
        includeEmployersContribution: includeEmployersContribution,
        overridePFContributionRate: overridePFContributionRate,
        componentOnPayslip,
        isActive,
      };
      await addEsi(payload);
      customToast.success("ESI added successfully");
      navigate("/settings/statutory/esi");
    } catch (error) {
      customToast.error("Failed to add ESI");
    }
  };

  return (
    <main
      className={"main-content " + `${theme == "light" ? "light_bg_main" : ""}`}
      style={{ width: "100%" }}
    >

      <div
        className={
          "area-creation-content " + `${theme == "light" ? "light_bg" : ""}`
        }
      >
        <PageTitle
          title={`${location.state?.type == "add" ? "ADD ESI" : "EDIT ESI"}`}
          route={'..'}
        />

        <section className="admin-content">
          <form>
            <div className=" cardlink-row flex flex-wrap gap-16">
              <div>
                <label>
                  ESI NUMBER <span className="asterisk-imp">*</span>
                </label>
                <div className="w-96 mt-4">
                <input
                  type="text"
                  placeholder="Enter ESI Number"
                  value={esiNumber}
                  onChange={(e) => setEsiNumber(e.target.value)}
                />
                </div>
              </div>

              <div>
                <label>
                  DEDUCTION TYPE<span className="asterisk-imp">*</span>
                </label>
                <div className="w-96">
                <Select
                  // isSearchable={false}
                  placeholder="Select Type"
                  styles={adminStyles}
                  options={deductionTypes}
                  value={deductionType}
                  onChange={(e) => setDeductionType(e)}
                />
                </div>
              </div>

              {/* <div>
                <label>
                  EMPLOYER CONTRIBUTION RATE{" "}
                  <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  // isSearchable={false}
                  styles={adminStyles}
                  options={employerContributionRateData}
                  value={employerContributionRate}
                  onChange={(e) => setEmployerContributionRate(e)}
                />
              </div>

              <div>
                <label>
                  EMPLOYEE CONTRIBUTION RATE{" "}
                  <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  // isSearchable={false}
                  styles={adminStyles}
                  options={employeeContributionRateData}
                  value={employeeContributionRate}
                  onChange={(e) => setEmployeeContributionRate(e)}
                />
              </div> */}
            </div>

            <div className="cardlink-row flex flex-wrap gap-8">
              <div>
                <label>
                  EMPLOYER CONTRIBUTION RATE{" "}
                  <span className="asterisk-imp">*</span>
                </label>
                <br />
                <div className="w-96">
                <Select
                  // isSearchable={false}
                  placeholder="Select Rate"
                  styles={adminStyles}
                  options={employerContributionRateData}
                  value={employerContributionRate}
                  onChange={(e) => setEmployerContributionRate(e)}
                />
                </div>
              </div>

              <div>
                <label>
                  EMPLOYEE CONTRIBUTION RATE{" "}
                  <span className="asterisk-imp">*</span>
                </label>
                <br />
                <div className="w-96">
                <Select
                  placeholder="Select Rate"
                  // isSearchable={false}
                  styles={adminStyles}
                  options={employeeContributionRateData}
                  value={employeeContributionRate}
                  onChange={(e) => setEmployeeContributionRate(e)}
                />
                </div>
              </div>
            </div>

            <div className="mx-8 flex flex-col">
              <label>Other Configurations :</label>
              <div className="flex items-center gap-10">
                <input
                  type="checkbox"
                  className="bg-red-100  check-box green_b"
                  checked={includeEmployersContribution}
                  value={includeEmployersContribution}
                  onChange={(e) => {
                    setIncludeEmployersContribution(e.target.checked);
                  }}
                />
                <span className="text-gray-300 form-label-text">
                  Include Employer’s contribution in the CTC{" "}
                </span>
              </div>
              
              <div className="flex items-center gap-10 mt-1">
                <input
                  type="checkbox"
                  className="bg-red-100  check-box green_b"
                  checked={componentOnPayslip}
                  value={componentOnPayslip}
                  onChange={(e) => {
                    setComponentOnPayslip(e.target.checked);
                  }}
                />
                <span className="text-gray-300 form-label-text">
                  Show Component On PaySlip{" "}
                </span>
              </div>

              <div className="flex items-center gap-10 mt-3">
                <input
                  type="checkbox"
                  className="  check-box-active blue_b"
                  checked={isActive}
                  onChange={(e) => setIsActive(e.target.checked)}
                />
                <span className="text-gray-300 form-label-text">
                  Mark this as Active
                </span>
              </div>
            </div>

            <div className="mx-8 mt-12 note  ">
              <label>NOTE :</label>
              <p className="mt-2 text-2xl">
                ESI deductions will be made only if the employee's monthly
                salary is less than or equal to ₴21,000. If the employee gets a
                salary revision which increases their monthly salary above
                €21,000, they would have to continue making ESI contributions
                till the end of the contribution period in which the salary was
                revised (April-September or October-March).
              </p>
            </div>

            <div className="row pb-10  ">
              <div>
                {location.state?.type == "add" ? (
                  <button
                    onClick={handleAddEsi}
                    type="submit"
                    className="button-blue-gradient"
                  >
                    Add
                  </button>
                ) : (
                  <button
                    onClick={handleEditEsi}
                    type="submit"
                    className="button-blue-gradient"
                  >
                    Update
                  </button>
                )}
              </div>
            </div>
          </form>
        </section>
      </div>
    </main>
  );
};

export default AddUpdateEsi;
