import React from "react";

const UploadedDocCard = ({ url, name, onClick }) => {
  return (
    <div
      className="my-4 w-3/5 h-3/5  rounded-xl overflow-hidden bg-cover content-center cursor-pointer"
      onClick={onClick}
    >
      {url && url.endsWith("pdf") ? (
        <div>
          <a href={url}>
            <span className="text-[#36abf9] underline">
              Download pdf
              </span>
              </a>
        </div>

      ) : (
        <img src={url} alt={name} className="w-full h-full " />
      )}
    </div>
  );
};

export default UploadedDocCard;
