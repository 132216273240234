import React, { useEffect, useMemo, useState } from "react";
import PageTitle from "../../../../components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { FaEye } from "react-icons/fa";
import Table from "../../../../components/Table";
import { adminStyles, adminStylesLight } from "../../../../utils/selectStyles";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import customToast from "../../../../components/CustomToast";
import { addProfessionalTaxDetails } from "../../../../services/hrms";
import { fetchProfessionalTaxDeails } from "../../../../reducers/professionalTaxDeailsReducer";
import { fetchStates } from "../../../../reducers/stateReducer";
import { generateSelectData } from "../../../../utils/serialiseResponse";
import {
  fetchPtDetails,
  taxSlabInfo,
} from "../../../../services/salaryTempelates";

const ProfessionalTaxes = () => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme?.theme);
  const navigate = useNavigate();

  const [professionalTaxNo, setProfessionalTaxNo] = useState(null);
  const [state, setState] = useState(null);
  const [deductionType, setDeductionType] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [data, setData] = useState([]);
  // const tableData = useSelector((state) => state?.professionalTaxDeails?.data);
  // console.log(tableData , "tableData")
  // const [incomeTax, setIncomeTax] = useState(0);
  const stateData = useSelector(({ states }) => states.data);
  const selectStateOptions = useMemo(
    () => generateSelectData(stateData, "name"),
    [stateData],
  );
  // const [deductionMonth, setDeductionMonth] = useState("");

  const columns = useMemo(
    () => [
      {
        Header: "State",
        accessor: (row) => row?.state?.name || "--",
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: "Deduction Type",
        accessor: (row) => row?.deductionType || "--",
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: "PT Number",
        accessor: (row) => row?.professionalTaxNo || "--",
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: "Action",
        accessor: "status",
        disableSortBy: true,
        disableFilters: true,
        Cell: (props) => (
          <div className="flex items-center justify-center">
            <FaEye
              color="#3883D7"
              size={20}
              className="cursor-pointer"
              onClick={() =>
                navigate("handlePt", {
                  state: props?.row?.original,
                })
              }
            />
          </div>
        ),
      },
    ],
    [],
  );

  const fetchData = async () => {
    await fetchPtDetails()
      .then((res) => {
        setData(res.data);
      })
      .catch(() => {
        return customToast.error("Error fetching data !");
      });
  };
  const handleAddPt = async (e) => {
    e.preventDefault();

    if (!professionalTaxNo) {
      customToast.error("Please Enter Professional Tax Number");
      return;
    }
    if (!state) {
      customToast.error("Please Select State");
      return;
    }
    // if (!deductionType) {
    //   customToast.error("Please Select Deduction Type");
    //   return;
    // }
    const data = {
      professionalTaxNo,
      state: state.value,
      // deductionType: deductionType.value,
    };
    await addProfessionalTaxDetails(data)
      .then((res) => {
        if (res.state == "added") {
          customToast.success(res.msg);
          setProfessionalTaxNo("");
          setState(null);
          setDeductionType(null);
          fetchData();
          dispatch(fetchProfessionalTaxDeails());
        } else {
          customToast.info(res.msg);
        }
      })
      .catch((err) => {
        customToast.error("Error Adding Professional Tax Details");
      });
  };

  useEffect(() => {
    // const fetchData = async () => {
    //   await fetchPtDetails()
    //     .then((res) => {
    //       setData(res.data);
    //     })
    //     .catch(() => {
    //       return customToast.error("Error fetching data !");
    //     });
    // };
    fetchData();
  }, [selectedState]);

  useEffect(() => {
    dispatch(fetchProfessionalTaxDeails());
    dispatch(fetchStates("state"));
  }, [dispatch]);

  return (
    <main
      className={"main-content " + `${theme == "light" ? "light_bg_main" : ""}`}
      style={{ width: "100%" }}
    >
      <div
        className={
          "area-creation-content " + `${theme == "light" ? "light_bg" : ""}`
        }
      >
        <PageTitle title="PROFESSIONAL TAX" route={"../.."} />

        <section className="admin-content">
          <form className={`${theme == "light" ? "lightbg" : ""}`}>
            <div className="cardlink-row flex flex-wrap gap-8">
              <div>
                <label>
                  Professional Tax Number<span className="asterisk-imp">*</span>
                </label>
                <div className="mt-4 w-96">
                  <input
                    type="text"
                    placeholder="Enter P.T Number"
                    value={professionalTaxNo}
                    style={{ width: "100%" }}
                    onChange={(e) => setProfessionalTaxNo(e.target.value)}
                  />
                </div>
              </div>

              <div>
                <label>
                  Select State <span className="asterisk-imp">*</span>
                </label>
                <br />
                <div className="w-96">
                  <Select
                    styles={theme == "light" ? adminStylesLight : adminStyles}
                    options={selectStateOptions}
                    value={state}
                    onChange={(e) => setState(e)}
                    placeholder="Select State"
                  />
                </div>
              </div>

              {/* <div>
                <label htmlFor="mName">
                  Deduction Type <span className="asterisk-imp">*</span>
                </label>
                <div className="w-96">
                  <Select
                    styles={theme == "light" ? adminStylesLight : adminStyles}
                    className="select-all"
                    options={deductionTypeOptions}
                    value={deductionType}
                    onChange={(e) => setDeductionType(e)}
                    placeholder="Select Type"
                  />
                </div>
              </div> */}

              {/* {deductionType?.value == "HalfYearly" ||
              deductionType?.value == "Yearly" ||
              deductionType?.value == "Quaterly" ? (
                <div>
                  <label htmlFor="mName">
                    Select Deduction Month{" "}
                    <span className="asterisk-imp">*</span>
                  </label>
                  <br />
                  <Select
                    styles={theme == "light" ? adminStylesLight : adminStyles}
                    className="select-all"
                    options={monthOptions}
                    onChange={(e) => setDeductionMonth(e)}
                    placeholder="Select Month"
                  />
                </div>
              ) : null} */}
              <div className="items-end flex">
                <button
                  onClick={handleAddPt}
                  type="submit"
                  className="button-blue-gradient py-[0.8rem] px-[4rem]"
                >
                  Add PT
                </button>
              </div>
            </div>
          </form>
        </section>

        <section className={"admin-content mx-8 pb-10"}>
          {data?.length > 0 && (
            <h2
              className={
                "show-entries" + `${theme === "light" ? "light_bg_logo" : ""}`
              }
            >
              Showing ({data[0]?.stateWiseInfo?.length}) Entries
            </h2>
          )}
          {data?.length > 0 ? (
            <div
              className={
                "filter-table " + `${theme === "light" ? "table_light" : ""}`
              }
            >
              <Table columns={columns} data={data[0]?.stateWiseInfo} />
            </div>
          ) : (
            ""
          )}
        </section>
      </div>
    </main>
  );
};

export default ProfessionalTaxes;
