import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../../../components/PageTitle";
import { adminStyles } from "../../../../utils/selectStyles";
import Select from "react-select";
import { fetchReimbursementTypes } from "../../../../reducers/reimbursementTypesReducer";
import { generateSelectData } from "../../../../utils/serialiseResponse";
import customToast from "../../../../components/CustomToast";
import {
  CreateReimbursement,
  updateReimbursement,
} from "../../../../services/reimbursement";
import { useLocation, useNavigate } from "react-router-dom";

const EditReimbursement = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useSelector((state) => state.theme.theme);
  const reimbursementTypes = useSelector(
    (state) => state.reimbursementTypes.types,
  );

  const [reimbursementType, setReimbursementType] = useState("");
  const [nameOnPaySlip, setNameOnPaySlip] = useState("");
  const [limitPerMonth, setLimitPerMonth] = useState(0);

  const [addFlexibleBenefit, setAddFlexibleBenefit] = useState(false);
  const [allowEmployeeOverriding, setAllowEmployeeOverriding] = useState(false);
  const [active, setActive] = useState(false);

  useEffect(() => {
    dispatch(fetchReimbursementTypes());
  }, []);

  const reimbursementTypesSelect = useMemo(
    () => generateSelectData(reimbursementTypes, "name"),
    [reimbursementTypes],
  );

  useEffect(() => {
    setNameOnPaySlip(location?.state?.nameOnPaySlip);
    setLimitPerMonth(location?.state?.limitPerMonth);
    setActive(location?.state?.active);
    const foundType = reimbursementTypesSelect.find(
      (el) => el.name === location?.state?.reimbursementType?.name,
    );
    setReimbursementType(foundType || "");
  }, [location, reimbursementTypesSelect]);

  const clearStates = () => {
    setReimbursementType("");
    setNameOnPaySlip("");
    setLimitPerMonth("");
    setAddFlexibleBenefit(false);
    setAllowEmployeeOverriding(false);
    setActive(false);
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    try {
      if (!reimbursementType || !nameOnPaySlip || !limitPerMonth) {
        customToast.error("Please fill all the required fields");
        return;
      }

      const payload = {
        id: location?.state?._id,
        nameOnPaySlip,
        limitPerMonth: Number(limitPerMonth),
        active,
        reimbursementType: reimbursementType?.value,
      };
      await updateReimbursement(payload);
      navigate("..");
      customToast.success("Reimbursement updated successfully");
    } catch (error) {
      customToast.error("Failed to update reimbursement !");
    }
  };

  return (
    <div
      className={"main-content " + `${theme == "light" ? "light_bg_main" : ""}`}
      style={{ width: "100%" }}
    >
      <div
        className={
          "creation-content " + `${theme == "light" ? "light_bg" : ""}`
        }
      >
        <PageTitle title="EDIT REIMBURSEMENT" route={".."} />

        <section className="admin-content">
          <form onSubmit={(e) => handleCreate(e)}>
            <div className="row">
              <div>
                <label className="dark:text-primaryDark1 uppercase">
                  REIMBURSEMENT TYPE<span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  styles={adminStyles}
                  placeholder="SELECT TYPE"
                  className="mt-4"
                  options={reimbursementTypesSelect}
                  value={reimbursementType}
                  onChange={(e) => {
                    setReimbursementType(e);
                  }}
                />
              </div>

              <div>
                <label>
                  NAME ON PAYSLIP<span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                  className="mt-4"
                  placeholder="ENTER NAME"
                  value={nameOnPaySlip}
                  onChange={({ target }) => {
                    setNameOnPaySlip(target?.value);
                  }}
                />
              </div>

              <div>
                <label>
                  ENTER LIMIT PER MONTH <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                  className="mt-4"
                  placeholder="ENTER AMOUNT"
                  value={limitPerMonth}
                  onChange={({ target }) => setLimitPerMonth(target?.value)}
                />
              </div>
            </div>

            <div className="flex gap-3 mx-8">
              <input
                id="chk"
                type="checkbox"
                // onChange={(e) => active ? setActive(false) : setActive(true)}
                onClick={(e) => {
                  setActive(!active);
                }}
                checked={active}
                className="check-box-active blue_b w-6 h-6"
              />
              <label htmlFor="chk" className="text-2xl cursor-pointer">
                Mark this as Active
              </label>
            </div>

            <div className="mx-8 mt-6 pb-10">
              {/* <button type="submit" className="button-blue-gradient">
                Create
              </button> */}
              <button onClick={handleCreate} className="button-blue-gradient">
                Update
              </button>
            </div>
          </form>
        </section>
      </div>
    </div>
  );
};

export default EditReimbursement;
