export const title = "Employee Info";

export const links = [
  {
    name: "Employee Profile",
    route: "profile",
    accessor: "create",
  },
  {
    name: "Employee Reports",
    route: "reports",
    accessor: "upload documents",
  },
  {
    name: "Employee Finance",
    route: "finance",
    accessor: "employees info",
  },
  
];




