import { useEffect, useMemo, useRef, useState } from "react";
import Select from "react-select";
import { RiCloseCircleLine, RiEdit2Fill } from "react-icons/ri";
import PageTitle from "../../../../components/PageTitle";
import Table from '../../../../components/Table'
import { useDispatch, useSelector } from "react-redux";
import { clearStates, fetchStates } from "../../../../reducers/stateReducer";
import { generateSelectData } from "../../../../utils/serialiseResponse";
import customToast from "../../../../components/CustomToast";
import { addLwf, deleteLwf, getLwf } from "../../../../services/hrms";
import { deleteLwfDetails, fetchLwfDetails, updateLwfStatus } from "../../../../reducers/lwfDetails";
import { FaPen } from "react-icons/fa";
import { SlOptionsVertical } from "react-icons/sl";
import { adminStyles, adminStylesLight } from "../../../../utils/selectStyles";

const deductionTypeOptions = [{ value: "Monthly", label: "Monthly" } , { value: "HalfYearly", label: "Half Yearly" } ,{ value: "Yearly", label: "Yearly" } ,{ value: "Quarterly", label: "Quarterly" }];


const Lwf = () => {
  const theme = useSelector((state) => state.theme.theme);

  const [state, setState] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState("");
  const [employeeContribution, setEmployeeContribution] = useState(null);
  const [employerContribution, setEmployerContribution] = useState(null);
  const [deductionType, setDeductionType] = useState(null);
  const [status, setStatus] = useState(true);
  const dispatch = useDispatch();
  const stateData = useSelector((state) => state.states.data);
  const lwfData = useSelector((state) => state.lwfInfo.data);

  const stateSelect = useMemo(() => generateSelectData(stateData, "name"), [stateData]);

  useEffect(() => {
    dispatch(fetchStates("state"));
    dispatch(fetchLwfDetails())
    return () => {
      dispatch(clearStates());
    };
  }, [dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!state || !employeeContribution || !employerContribution || !deductionType || !status) {
      return customToast.error("Please fill all Fields!")
    }

    try {
      const payload = {
        state,
        employeeContribution,
        employerContribution,
        deductionType,
        status
      }
      const response = await addLwf(payload);

      if (response.acknowledged == true) {
        customToast.success("New LWF inserted successfully")
        dispatch(fetchLwfDetails())
      }

    } catch (error) {
      customToast.error("Something Went Wrong!")
    }

  }

  const handleDelete = async (id) => {
    dispatch(deleteLwfDetails(id))
  }

  const handleStatusChange = async (id, payload) => {

    dispatch(updateLwfStatus(id, payload))
  }

  const seleStyles = {
    control: (provided, state) => ({
      ...provided,
      padding: "0.18rem 1rem",
      backgroundColor: "#2B2C47",
      color: "#C7C4E9",
      border: "5px",
      boxShadow: "none",
      minHeight: "3.6rem",
      width: "100%",
      borderRadius: "0rem"
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "#2B2C47",
      color: "#9E9AD1",

      "&:hover": {
        backgroundColor: "#393b5f",
      },
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#9e9ad0",
    }),
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: "#2B2C47",
      width: "80%",
    }),
    input: (provided, state) => ({
      ...provided,
      color: "#fff",
      border: "none",
      width: "100%",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: "#C7C4E9",

      "&:hover": {
        color: "#9E9AD1",
      },
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: "#9E9AD1",
    }),
    indicatorSeparator: () => null,
  };

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setSelectedRowIndex("");
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handlePenClick = (e, idx) => {
    e.stopPropagation();
    setSelectedRowIndex((prevIndex) => (prevIndex === idx ? "" : idx));
  };

  const columns = useMemo(
    () => [
      {
        Header: "State",
        accessor: (row) => row?.state?.name || "--",
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: "Employee Contribution",
        accessor: (row) => row?.employeeContribution || "--",
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: "Employer Contribution",
        accessor: (row) => row?.employerContribution || "--",
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: "Deduction Type",
        accessor: (row) => row?.deductionType || "--",
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: "Status",
        disableSortBy: true,
        disableFilters: true,
        Cell: (props) => {
          return (
            <span className={`${props?.row?.original?.status === true ? "text-green-600" : "text-red-600"}`}>{props?.row?.original?.status === true ? "Active" : "Inactive"}</span>
          )
        },
      },
      {
        Header: "Action",
        accessor: "status",
        disableSortBy: true,
        disableFilters: true,
        Cell: (props) => {
          const idx = props.row.index;

          return (
            <div>
              <FaPen
                className="text-tertiary text-4xl m-auto"
                style={{ cursor: "pointer" }}
                onClick={(e) => { handlePenClick(e, idx) }}
              />
              <div
                ref={dropdownRef}
                className={` ${selectedRowIndex === idx
                  ? "flex flex-col p-4 rounded-md gap-2 absolute bg-slate-800 bg-opacity-90 border-primaryDark"
                  : "hidden"
                  } `}
              >
                <span className="  hover:bg-primaryDark2 rounded-md p-4 cursor-pointer" onClick={(e) => handleStatusChange(props?.row?.original?._id, { status: props?.row?.original?.status })}>
                  {props?.row?.original?.status === true ? "Disabled" : "Enabled"}
                </span>
                <span className="  hover:bg-primaryDark2 rounded-md p-4 cursor-pointer " onClick={(e) => handleDelete(props?.row?.original?._id)}>
                  Delete
                </span>
              </div>
            </div>
          )
        },
      },
    ],
    [],
  );

  const tableData = lwfData || [];

  return (
    <main
      className={"main-content " + `${theme == "light" ? "light_bg_main" : ""}`}
      style={{ width: "100%" }}
    >
      <div
        className={
          "area-creation-content " + `${theme == "light" ? "light_bg" : ""}`
        }
      >        
      <PageTitle title="LABOUR WELFARE FUND" route={'../..'} />


        <section className="admin-content">
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="cardlink-row flex flex-wrap gap-8">
              <div>
                <label>Select State <span className='text-error'>*</span></label>
                <div className="w-96">
                <Select
                  name="state"
                  id="state"
                  onChange={(e) => setState({ value: e.value, label: e.label })}
                  styles={theme == "light" ? adminStylesLight : adminStyles}
                  placeholder="Select State"
                  options={stateSelect}
                />
                </div>
              </div>

              <div>
                <label>Employee Contribution <span className='text-error'>*</span></label>
                <div className="w-96 mt-4">
                <input
                  type="text"
                  id="city"
                  placeholder="Enter Amount"
                  onChange={(e) => setEmployeeContribution(e.target.value)}
                />
                </div>
              </div>

              <div>
                <label>Employer Contribution<span className='text-error'>*</span></label>
                <div className="w-96 mt-4">
                <input
                  type="text"
                  id="city"
                  placeholder="Enter Amount"
                  onChange={(e) => setEmployerContribution(e.target.value)}
                />
                </div>
              </div>

              <div>
                <label>Deduction Type <span className='text-error'>*</span></label>
                <div className="w-96">
                <Select
                  name="deductionType"
                  id="Type"
                  onChange={(e) => setDeductionType({ value: e.value, label: e.label })}
                  styles={theme == "light" ? adminStylesLight : adminStyles}
                  placeholder="Select Type"
                  options={deductionTypeOptions}
                />
                </div>
              </div>
            </div>

            <div className="row">
              <button type="submit" className="button-blue-gradient self-end ">
                ADD LWF
              </button>
            </div>
          </form>
        </section>

        <section className="admin-content mx-8 pb-10">
          {tableData?.length > 0 && (
            <h2
              className={
                "show-entries" +
                `${theme === "light" ? "light_bg_logo" : ""}`
              }
            >
              Showing ({tableData?.length}) Entries
            </h2>
          )}

          {tableData?.length > 0 ? (
            <div
              className={
                "filter-table " + `${theme === "light" ? "table_light" : ""}`
              }
            >
              <Table columns={columns} data={tableData} />
            </div>
          ) : (
            ""
          )}

          {/* <div className="filter-table !mr-8">
            <table className="mt-8 w-full">
              <thead className="uppercase dark:text-secondary dark:bg-primaryDark2 w-full text-center">
                <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">
                  State
                </th>
                <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">
                  Employee Contribution
                </th>
                <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">
                  Employer Contribution
                </th>
                <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">
                  Deduction Type
                </th>
                <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">
                  Status
                </th>
                <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">
                  Action
                </th>
              </thead>
              <tbody>
                {tableData?.map((el, idx) => (
                  <tr key={idx} className="dark:bg-primary text-center">
                    <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6">
                      {el?.state.name || "Loading ..."}
                    </td>
                    <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6">
                      {`${el?.employeeContribution}.00` || "Loading ..."}
                    </td>
                    <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6">
                      {`${el.employerContribution}.00` || "Loading"}
                    </td>
                    <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6">
                      {el.deductionType || "Loading"}
                    </td>
                    <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6">
                      <span className={el.status === true ? "text-green-600" : "text-red-600"}>{el.status === true ? "Active" : "Inactive"}</span>
                    </td>
                    <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6 ">
                      <FaPen
                        className="text-tertiary text-4xl m-auto"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => { handlePenClick(e, idx) }}
                      />
                      <div
                        ref={dropdownRef}
                        className={` ${selectedRowIndex === idx
                          ? "flex flex-col p-4 rounded-md gap-2 absolute bg-slate-800 bg-opacity-90 border-primaryDark"
                          : "hidden"
                          } `}
                      >
                        <span className="  hover:bg-primaryDark2 rounded-md p-4 cursor-pointer" onClick={(e) => handleStatusChange(el._id, { status: el.status })}>
                          {el.status === true ? "Disabled" : "Enabled"}
                        </span>
                        <span className="  hover:bg-primaryDark2 rounded-md p-4 cursor-pointer " onClick={(e) => handleDelete(el._id)}>
                          Delete
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div> */}
        </section>
      </div>
    </main>
  );
};

export default Lwf;
