export const title = "Manage Salary Advance";

export const links = [
  {
    name: "Assign Advance",
    route: "assign-advance-salary",
    accessor: "assign-advance",
  },
  {
    name: "Salary Advance",
    route: "salary-advance",
    accessor: "salary-advance",
  },
];
