import { createSlice } from "@reduxjs/toolkit";
import {
  createDivision,
  editDivision,
  getDivision,
} from "../services/metaData";

const divisionsSlice = createSlice({
  name: "divisions",
  initialState: {
    data: [],
    status: "idle",
    error: null,
  },
  reducers: {
    setDivs: (state, action) => {
      state.data = action.payload.data;
    },
    setStatus: (state, action) => {
      state.status = action.payload.status;
      state.error = action.payload.error || null;
    },
  },
});

export const { setDivs, setStatus } = divisionsSlice.actions;

export const setDivisions = () => async (dispatch) => {
  dispatch(setStatus({ status: "loading", error: null }));
  try {
    const { data } = await getDivision();
    dispatch(setDivs({ data }));
    dispatch(setStatus({ status: "idle", error: null }));
  } catch (err) {
    dispatch(setStatus({ status: "failed", error: err?.message }));
  }
};

export const createDivisions = (details) => async (dispatch) => {
  dispatch(setStatus({ status: "loading", error: null }));
  try {
    const res = await createDivision(details);
    const { data } = await getDivision();
    dispatch(setDivs({ data }));
    // dispatch(setDepart({ data }));
    dispatch(setStatus({ status: "idle", error: null }));
  } catch (err) {
    dispatch(setStatus({ status: "failed", error: err?.message }));
    throw err;
  }
};

export const editDivisions = (id, payload) => async (dispatch) => {
  dispatch(setStatus({ status: "loading", error: null }));
  try {
    const { data } = await editDivision(id, payload);
    dispatch(setDivs({ data }));
    dispatch(setStatus({ status: "idle", error: null }));
  } catch (err) {
    dispatch(setStatus({ status: "failed", error: err?.message }));
  }
};

export const clearDivisions = () => (dispatch) => {
  dispatch(setDivs({ data: [] }));
};
export default divisionsSlice.reducer;
