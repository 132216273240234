import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

import Table from "../../../components/Table";
import serialise, {addIndex2, generateSelectData } from "../../../utils/serialiseResponse";
import customToast from "../../../components/CustomToast";
import PageTitle from "../../../components/PageTitle";
import { RiPencilFill } from "react-icons/ri";
import Dropdown from "../../../components/DropDown/dropdown";

import {
  clearStates,
  fetchStates,
} from "../../../reducers/stateReducer";
import {
  clearHeadquarters,
  setHeadquarters,
} from "../../../reducers/locationReducer";
import {
  clearDivisions,
  setDivisions,
} from "../../../reducers/divisionReducer";
import {
  setEmployees,
  clearEmployees,
  fetchEmployees,
  fetchSingleEmployee,
  fetchAllEmployees,
} from "../../../reducers/employeesReducer";
import { GetAllEmployees } from "../../../services/user";
import { adminStyles, adminStylesLight } from "../../../utils/selectStyles";
import { BsThreeDotsVertical } from "react-icons/bs";
import { tableData } from "../../Settings/salary/earning/upperNavData";

const AdminInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [state, setState] = useState(null);
  const [headquarter, setHeadquarter] = useState(null);
  const [division, setDivision] = useState(null);
  const [emp, setEmp] = useState({ label: "All", value: "all" });
  const dropdownRef = useRef(null);

  const allStates = useSelector((state) => state.states.data);
  const allDivisions = useSelector((state) => state.divisions.data);
  const headquarters = useSelector((state) => state.location.hq);
  const employeeData = useSelector((state) => state.employees.data);
  const selectedEmployee = useSelector((state) => state.employees.singleEmp);

  useEffect(() => {
    if (emp.value === "all") {
      dispatch(fetchEmployees({}));
    } else {
      dispatch(fetchSingleEmployee(emp.value));
    }
  }, [emp, dispatch]);


  const redirectToDetails = (employee) => {
    navigate("/employee/manage/employees-info/details", { state: employee });
  };

  const redirectToSalary = (employee) => {
    navigate("/employee/manage/employees-info/salary", { state: employee });
  };

  const redirectToEditEmployee = (employee) => {
    navigate("/employee/manage/employees-info/edit-employee", { state: employee });
  };

  useEffect(() => {
    // dispatch(fetchStates("state"));
    // dispatch(setDivisions());
    dispatch(fetchEmployees());
    return () => {
      // dispatch(clearDivisions());
      // dispatch(clearStates());
      dispatch(clearEmployees({}));
    };
  }, [dispatch]);

  // useEffect(() => {
  //   if (!state) return;
  //   dispatch(setHeadquarters(state.value));
  //   return () => {
  //     dispatch(clearHeadquarters());
  //   };
  // }, [dispatch, state]);

  const stateSelect = useMemo(
    () => generateSelectData(allStates, "name"),
    [allStates]
  );
  const divisionSelect = useMemo(
    () => generateSelectData(allDivisions, "name"),
    [allDivisions]
  );
  const headquarterSelect = useMemo(
    () => generateSelectData(headquarters, "name"),
    [headquarters]
  );

  const employeeOptionsWithAll = useMemo(() => {
    const options = [{ value: "all", label: "All" }];
    if (Array.isArray(employeeData)) {
      const selectData = generateSelectData(employeeData, "fullName");
      options.push(...selectData);
    }
    return options;
  }, [employeeData]);

  const columns = useMemo(
    () => [
      {
        Header: "NAME",
        accessor: "firstName",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Designation",
        accessor: "designation.name",
        disableFilters: true,
        disableSortBy: true,
        Cell:(props)=>{
          if(props?.row?.original?.designation ) 
          { return props?.row?.original?.designation?.name}
          else return 'Not Alloted'
        }
      },
      {
        Header: "Department",
        accessor: "department.name",
        disableFilters: true,
        disableSortBy: true,
        Cell:(props)=>{
          
          if(props?.row?.original?.department) 
          { return props?.row?.original?.department?.name}
          else return 'Not Alloted'
        }
      },
      {
        Header:"Template",
        accessor:"salaryTempelate.tempName",
        disableFilters: true,
        disableSortBy: true,
        Cell:(props)=>{
          if(props?.row?.original?.salaryTempelate) 
          { return props?.row?.original?.salaryTempelate?.tempName}
          else return 'Not Alloted'
        }
      },
      {
        Header: "CTC per year",
        accessor: "salaryTempelate.ctc",
        disableFilters: true,
        disableSortBy: true,
        Cell: ( props ) => {
        if(props?.row?.original?.salaryTempelate)
          { return props?.row?.original?.salaryTempelate?.ctc}
        else return "--"
        },
      },
      {
        Header: "Action",
        accessor: "actions",
        disableFilters: true,
        disableSortBy: true,
        //   Cell: (props) => {
        //     const [showOptions, setShowOptions] = useState(false);
        //     useEffect(() => {
        //       const handleClickOutside = (event) => {
        //         if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        //           setShowOptions(false);
        //         }
        //       };
        //       window.addEventListener("click", handleClickOutside);
        //       return () => {
        //       window.removeEventListener("click", handleClickOutside);
        //       };
        //     }, []); 
        //     return(
        //     <>
        //     <span
        //       className="react-table-view-link"
        //       onClick={(e) => {e.stopPropagation();setShowOptions(!showOptions)}}
        //     >
        //       <BsThreeDotsVertical className="text-4xl dark:text-tertiary" />
        //     </span>
        //     <div
        //         ref={dropdownRef}
        //         className={` ${
        //           showOptions
        //             ? "flex flex-col p-4 rounded-md bg-whiteLight dark:!bg-primaryDark2 gap-2 absolute right-10  z-10  w-96  h-auto overflow-y-scroll"
        //             : "hidden"
        //         } `}
        //       >
        //      <span className="hover:bg-white dark:bg-primaryDark1 rounded-md p-4 cursor-pointer" onClick={() => {redirectToDetails(props.row.original)}}>Show Info</span>
        //      <span className="hover:bg-white dark:bg-primaryDark1 rounded-md p-4 cursor-pointer" >Edit Info</span>
        //      <span className="hover:bg-white dark:bg-primaryDark1 rounded-md p-4 cursor-pointer" onClick={() => {redirectToSalary(props.row.original)}}>Salary</span>
        //    </div>
        //    </>
        //   );
        // },
        Cell: (props) => {
          return (
            <>
              <Dropdown
                icon={
                  <BsThreeDotsVertical className="text-4xl dark:text-tertiary" />

                }
                options={[
                  {
                    text: 'Show Info',
                    onClick: () => {
                      { redirectToDetails(props.row.original) }
                    }
                  },
                  {
                    text: 'Edit Info',
                    onClick: () => {
                      { redirectToEditEmployee(props.row.original) }
                    }
                  },
                  {
                    text: 'Allot Salary Temp..',
                    onClick: () => {
                      { redirectToSalary(props.row.original) }
                    }
                  },
                ]}
              />
            </>
          );
        },
      },
    ],
    [navigate]
  );

  const tableData2 = useMemo(() => {
    if (emp.value === "all") {
      return addIndex2(employeeData);
    } else {
      return addIndex2(selectedEmployee);
    }
  }, [employeeData, selectedEmployee, emp]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let payload = {};
      if (state && headquarter && division) {
        payload = {
          state: state.value,
          headquarter: headquarter.value,
          division: division.value,
        };
      }

      dispatch(fetchEmployees(payload));

      setDivision(null);
      setHeadquarter(null);
      setState(null);
    } catch (error) {
      customToast.error("Error fetching employees !!");
    }
  };

  const theme = useSelector((state) => state.theme.theme);

  return (
    <div className="mt-8 mr-8 w-full">
      <div className="w-full bg-whiteLight dark:!bg-primaryDark2">
      <PageTitle title="Employee Profile" route={'/employee/manage/create'}/>
      
      <section className=" dark:text-secondaryDark">
        <form
          className="w-full"
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <div className="row">
            <div className="col-sm-12 col-md-4 col-lg-4">
              <label htmlFor="state">SELECT EMPLOYEE</label>
              <br />
              <Select
                name="emp"
                id="emp"
                value={emp}
                options={employeeOptionsWithAll}
                onChange={({ value, label }) => setEmp({ value, label })}
                styles={theme == "light" ? adminStylesLight : adminStyles}
                placeholder="Select Division"
                className="mt-5 !min-w-[19rem]"
              />
            </div>
          </div>
        </form>
      </section>

      <section className="cardlink-row pb-6">
        {tableData2.length > 0 && (
          <label
            className={
              "mt-5 pt-5 ms-2 web-app__heading"}
          >
            Showing ({tableData2.length}) Entries
          </label>
        )}

        {tableData2.length >= 0 && (
          <div className="filter-table">
            <Table columns={columns} data={tableData2} />
          </div>
        )}
      </section>
    </div>
    </div>
  );
};

export default AdminInfo;
