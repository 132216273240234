import { createSlice } from "@reduxjs/toolkit";
import { getPayrun } from "../services/payRun";


const payrunReducerSlice = createSlice({
  name: "payrun",
  initialState: {
    data: []
  },
  reducers: {
    setPayrun: (state, action) => {
      state.data = action.payload;
    },
  },
});

const { setPayrun } =   payrunReducerSlice.actions;

export const fetchPayrunRuns = () => async (dispatch) => {
    try {
      const {data} = await getPayrun();
      dispatch(setPayrun(data));
    } catch (error) {
      console.log(error)
    }
};


export default payrunReducerSlice.reducer;
