import React, { useEffect, useMemo, useState } from 'react';
import PageTitle from '../../../components/PageTitle';
import Select from "react-select";
import { useDispatch, useSelector } from 'react-redux';
import { adminStyles, adminStylesLight } from '../../../utils/selectStyles';
import { generateSelectData } from '../../../utils/serialiseResponse';
import { clearEmployees, fetchEmployees } from '../../../reducers/employeesReducer';
import { useNavigate } from 'react-router-dom';
import customToast from '../../../components/CustomToast';

const CreatePOI = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch(); 
    const theme = useSelector((state) => state.theme.theme);
    const employees = useSelector(({employees}) => employees);
    const [selectedEmpl, setSelectedEmpl] = useState(null);

    useEffect(()=>{
      dispatch(fetchEmployees());

      return () => {
        dispatch(clearEmployees());
        
      }
    },[dispatch]);

    const emplSelect = useMemo(
      () => generateSelectData(employees.data, "fullName"),
      [employees.data]
    );

    const redirectToDetails = async(element) =>{
      if(!element){
        return customToast.error("Select User !");
      }
      navigate(`./add-details`,{state: element});
    }

  return (
    <div className='main-content bg-primaryLight2 dark:bg-primaryDark1'>
      <div className="creation-content" >
        <PageTitle title="Create POI" route={'../..'} />

        <section className='admin-content pb-10'>
          <div className='row'>
            <div>
              <label>Select Employee</label>
              <Select
                styles={theme == "light" ? adminStylesLight : adminStyles}
                placeholder="Select Employee"
                options={emplSelect}
                onChange={(el) => setSelectedEmpl(el)}
              />
            </div>
          </div>

          <button className="mx-8 button-blue-gradient"
            onClick={() => redirectToDetails(selectedEmpl)}>
            Create
          </button>
        </section>
      </div>
    </div >
  )
}

export default CreatePOI