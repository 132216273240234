import React, { useState } from "react";
import DropZone from "../../../components/DropZone/DropZone";
import { useSelector } from "react-redux";
import PageTitle from "../../../components/PageTitle";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { monthOptions, yearOptions } from '../../../utils/helper';
import WorkingDayCountFormat from '../../../assets/excelFormats/WorkingDayCountFormat.xlsx'
import {
    adminStyles,
    adminStylesLight,
    selectStyles,
} from "../../../utils/selectStyles";
const RegularAttendance = () => {
    const theme = useSelector((state) => state.theme.theme);
    const navigate = useNavigate();
    const [month, setmonth] = useState()
    const [year, setyear] = useState()

    const [uploadedFiles, setUploadedFiles] = useState([]);
    const selectOptions = []
    return (
        <main
            className={
                "main-content " + `${theme == "light" ? " light_bg_main" : ""}`
            }
            style={{ width: "100%" }}
        >
            <div className="creation-content ">
                <PageTitle title="Day Count Attendance Upload" route="../../" />
            </div>
            <div className='row'>
                <div >
                    <label className="uppercase form-label-div">Select Month</label>
                    <Select
                        styles={
                            theme == "light" ? adminStylesLight : adminStyles
                        }
                        options={monthOptions}
                        // value={selectOptions[selectedMapping[index]]}
                        onChange={(e) => setmonth(e.value)}
                    />
                </div >
                <div >
                    <label className="uppercase form-label-div">Select yEAR</label>
                    <Select
                        styles={
                            theme == "light" ? adminStylesLight : adminStyles
                        }
                        options={yearOptions}
                        onChange={(e) => setyear(e.label)}

                    />
                </div >
            </div>
            <div className="mr-10 cardlink-row mb-5 py-10">
                <p className="text-3xl text-wheat">
                    Download a sample <span className="text-tertiary">.csv format</span>{" "}
                    or <span className="text-tertiary">.xls format</span> file and compare
                    it with your import file to ensure that the file is ready to import.
                </p>
                <a href={WorkingDayCountFormat} download='WorkingDayCountFormat.xlsx' rel="noreferrer">
                    <p className="text-green-700 mt-5 underline cursor-pointer">Download Format</p>
                </a>

            </div>

            <DropZone
                uploadedFiles={uploadedFiles}
                setUploadedFiles={setUploadedFiles}
            />

            <div className=" flex items-center pb-6 gap-20">
                <button
                    className="button-green-gradient"
                    onClick={() => {
                        if (uploadedFiles.length === 0) return;
                        navigate("dayCount-map-feilds", {
                            state: {
                                uploadedFiles: uploadedFiles,
                                month: month,
                                year: year
                            },
                        });
                    }}
                >
                    Next
                </button>

                <button className="button-red-gradient" onClick={() => setUploadedFiles([])}>Cancel</button>

            </div>
        </main>
    );
};

export default RegularAttendance;
