export const title = "Manage Leave";

export const links = [
  {
    name: "Leave Form",
    route: "leave-form",
    accessor: "leave form",
  },
  {
    name: "Leave Type",
    route: "leave-type",
    accessor: "leave type",
  },
  {
    name: "Customise Balance",
    route: "customise-balance",
    accessor: "customise balance",
  },
  {
    name: "Leave History",
    route: "leave-history",
    accessor: "leave history",
  },
  {
    name: "Leave Balance",
    route: "leave-balance",
    accessor: "leave balance",
  },
];
