
export const list1 = {
    
    titles:[
        {  
           name:"list1",
           id: "30 days",
           label: "30 days"
        },
        {  
           name:"list1",
           id: "days in month",
           label: "Days in Month"
        },
    ]
}

export const list3 = {
    titles:[
        {  
           name:"list3",
           id: "Enable",
           label: "Enable"
        },
        {  
           name:"list3",
           id: "Disable",
           label: "Disable"
        },
    ]
}

export const list2 = {
    titles:[
        {  
           name:"list2",
           id: "gross Salary",
           label: "Gross Salary"
        },
        {  
           name:"list2",
           id: "Earning Components",
           label: "Earning Components"
        },
    ]
}

export const list4 = {
    titles:[
        {  
           name:"list4",
           id: "Enable",
           label: "Enable"
        },
        {  
           name:"list4",
           id: "Disable",
           label: "Disable"
        },
    ]
}

export const list5 = {
    titles:[
        {  
           name:"list5",
           id: "Yes",
           label: "Yes"
        },
        {  
           name:"list5",
           id: "No",
           label: "No"
        },
    ]
}

export const list6 = {
    titles:[
        {  
           name:"list5",
           id: "Remove Zero Value Entries",
           label: "Remove Zero Value Entries"
        },
       
    ]
}

export const list7 = {
    titles:[
        {  
           name:"list7",
           id: "Professional Tax Standard Deduction",
           label: "Professional Tax Standard Deduction"
        },
       
    ]
}


