export const title = "Manage Payrun";

export const links = [
  {
    name: "Payrun Info",
    route: "payrun-info",
    accessor: "payrun info",
  },
  
  {
    name: "Processed Payrun",
    route: "processed-payrun",
    accessor: "processed payrun",
  },
];
