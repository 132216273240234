import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import "../component.css"
const customToast = {
	success(msg, options = {}) {
		return toast.success(<div className="toast-message">{msg}</div>, {
			...options,

			position: 'bottom-center',
			hideProgressBar: true,
			closeOnClick: false,
			pauseOnFocusLoss: true,
			pauseOnHover: true,

			className: 'toast-custom-container toast-success',
		});
	},

	error(msg, options = {}) {
		return toast.error(msg, {
			...options,

			position: 'bottom-center',
			hideProgressBar: true,
			closeOnClick: false,
			pauseOnFocusLoss: true,
			pauseOnHover: true,

			className: 'toast-custom-container toast-error',
		});
	},
	info(msg, options = {}) {
		return toast.info(msg, {
			...options,

			position: 'bottom-center',
			hideProgressBar: true,
			closeOnClick: false,
			pauseOnFocusLoss: true,
			pauseOnHover: true,

			className: 'toast-custom-container toast-info',
		});
	},
};

export default customToast;
