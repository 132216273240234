import { deleteLocation, editLocation, newLocation, viewLocations } from "../services/location";
import { createSlice } from "@reduxjs/toolkit";


const locationSlice = createSlice({
  name: "location",
  initialState: {
    hq: [],
    status: "idle",
    error: null,
  },
  reducers: {
    setLocationHQ: (state, action) => {
      state.hq = action.payload.data;
    },
    addHQ : (state, action) => {
      state.hq.push(action.payload.data);
    },
    editHQ: (state, action) => {
      const index = state.hq.findIndex((hq) => hq.id === action.payload.id);
      if (index !== -1) {
        state.hq[index] = action.payload.data;
      }
    },
    deleteHQ: (state, action) => {
      state.hq = state.hq.filter((hq) => hq.id !== action.payload.id);
    },
    setStatus: (state, action) => {
      state.status = action.payload.status;
      state.error = action.payload.error || null;
    },
 
  },
});

export const { setLocationHQ, setStatus , deleteHQ , editHQ , addHQ } = locationSlice.actions;

export const setHeadquarters = (parentId) => async (dispatch) => {
  dispatch(setStatus({ status: "loading", error: null }));
  try {
    const { data } = await viewLocations("headquarter", parentId);
    dispatch(setLocationHQ({ data }));
    dispatch(setStatus({ status: "idle", error: null }));
  } catch (err) {
    dispatch(setStatus({ status: "failed", error: err?.message }));
  }
};


export const addHeadquarterAsync = (data) => async (dispatch) => {
  try {
    const addedHq = await newLocation("headquarter", data);
    dispatch(addHQ(addedHq));
    dispatch(setHeadquarters());
  } catch (error) {
  }
};

export const editHeadquarterAsync = (id, updatedLoc) => async (dispatch) => {
  try {
    const updatedState = await editLocation("headquarter", id, updatedLoc);
    dispatch(editHQ(updatedState));
    dispatch(setHeadquarters()); 
  } catch (error) {
  }
};

export const deleteHeadquarterAsync = (id) => async (dispatch) => {
  try {
    await deleteLocation("headquarter", id);
    dispatch(deleteHQ({ id }));
    dispatch(setHeadquarters());
  } catch (error) {
  }
};

export const clearHeadquarters = () => (dispatch) => {
  dispatch(setLocationHQ({ data: [] }));
};

export default locationSlice.reducer;
