import React from "react";
import { title, links } from "./salaryNavData";
import { Outlet } from "react-router-dom";
import SideBar from "../../../components/SideBar/SideBar";

const Salary = () => {
  return (
    <>
      <SideBar title={title} links={links} />

      <Outlet />
    </>
  );
};

export default Salary;
