import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Route,
  Routes,
  Navigate,
  useLocation,
  Outlet,
  BrowserRouter as Router,
} from "react-router-dom";
import "../app.css";
import "../main.css";
import { initUser } from "../reducers/userReducer";
import { setHeadquarters } from "../reducers/locationReducer";
import IncomeTaxHome from "../views/Settings/taxes/incomeTaxHome/index";
import Regime from "../views/Settings/taxes/regime/Index";
import TaxSetting from "../views/Settings/taxes/settings/index";
import SyncAttendance from "../views/Attendance/SyncAttendance/index";
import SyncRegularAttendance from "../views/Attendance/SyncAttendance/SyncRegularAttendance/index";
import ViewAttendance from "../views/Attendance/SyncAttendance/ViewAttendance/index";
import {
  Home,
  ManageEmployeeHome,
  EmployeeHome,
  UserInfoForm,
  SingleEmployeeDetails,
  CreateEmployee,
  EditEmployee,
  Onboarding,
  Login,
  Dashboard,
  PayRunHome,
  ManagePayrun,
  CreatePayrun,
  ViewPayslip,
  ProcessedPayrun,
  ImportEarningAndDeduction,
  MapFieldsOted,
  ImportOted,
  MapFieldsLop,
  ImportLop,
  ImportLopDetails,
  AttendanceHome,
  ManageLeave,
  UploadAttendance,
  LeaveForm,
  LeaveType,
  CustomiseBalance,
  LeaveHistory,
  LeaveBalance,
  DocumentHome,
  PanCard,
  AadharCard,
  Passport,
  DriverLicence,
  VotersID,
  Settings,
  User,
  Profile,
  SalaryDetails,
  Attendance,
  Finance,
  Salary,
  Statutory,
  Taxes,
  Tempelates,
  Earnings,
  EditEarnings,
  Deduction,
  Reimbursement,
  AddReimbursement,
  EditReimbursement,
  AddComponent,
  AddPreDeductionn,
  AddPostDedcution,
  EditDeduction,
  ESI,
  AddUpdateEsi,
  EPF,
  AddUpdateEpf,
  Professionaltax,
  AssignLoan,
  Loans,
  Loan,
  Installments,
  AdvSalaryNav,
  AssignAdvSalary,
  SalaryAdvance,
  AdvSalaryInstallments,
  Reports,
  PayrollNav,
  PayrollSummary,
  AddLeave,
  LoanHome,
  VoterId,
  SignUp,
  SetUpHome,
  SetUpCompany,
  TaxInformation,
  SchedulePayment,
  StatutoryComponents,
  SalaryComponents,
  SetUpDepartment,
  LoanNav,
  CreateLoan,
  SalaryComparison,
  MasterHomee,
  Division,
  AddDivision,
  SubDivision,
  Department,
  AddSubDivision,
  AddDepartment,
  Designation,
  AddDesignation,
  HeadQuarters,
  AddHeadQuarter,
  City,
  AddCity,
  UploadLeave,
  PayRunInfo,
  SalaryInfo,
  Schedules,
  SchedulePayments,
  State,
  Lwf,
  ManageTaxes,
  SalaryTemplates,
  PayslipTemplates,
  HandleProfessionalTax,
  FinanceHome,
  ApproveSalaryRevision,
  ManageSalaryRevision,
  SalaryRevisionDetails,
  ManageReimbursement,
  AddClaim,
  AddDetails,
  ManageInvestment,
  CreatePOI,
  AddDetailsPOI,
  EmployeeInfoSideBar,
  EmployeeInfoProfile,
  EmployeeInfoReports,
  EmployeeInfoFinance,
  OnboardingHome,
  OnboardingDivision,
  OnboardingProcess,
  OnboardingInitiation,
  OnboardingHistory,
  RegularAttendance,
  MapFieldsRegular,
  ImportRegular,
  MapFieldsDayCount,
  DayCountAttendance,
  DayCountImport,
  CreateCompany,
  Create,
  AttendanceSummary,
  BankAdvice,
  MapFieldsLeave,
  ImportLeave,
  Notifications,
  AddSalaryTemplates,
  ReviseSalary,
  EditSalaryTemplate,
  WorkingAddress,
  AddWorkingAddress,
  EditWorkingAddress,
} from "../views";
import SetUpDivision from "../views/CompanySetUp/SetUpDivision";

const App = () => {
  const dispatch = useDispatch();
  const loggedIn = useSelector(({ user }) => user);
  console.log("$$@#&*");
  useEffect(() => {
    if (loggedIn.user) dispatch(setHeadquarters());
  }, [loggedIn, dispatch]);

  useEffect(() => {
    dispatch(initUser());
  }, []);
  if (loggedIn.user === null) return <div>Loading...</div>;

  return (
    <>
      <Routes>
        <Route path="login" element={<Login loggedUser={loggedIn.user} />} />

        {/* <Route path="signup" element={<SignUp />} /> */}

        <Route path="setup" element={<SetUpHome loggedUser={loggedIn.user} />}>
          <Route
            index
            path="company"
            element={<SetUpCompany loggedUser={loggedIn.user} />}
          />
          <Route path="tax-info" element={<TaxInformation />} />
          <Route path="schedule-payment" element={<SchedulePayment />} />
          <Route
            path="statutory-components"
            element={<StatutoryComponents />}
          />
          <Route path="salary-components" element={<SalaryComponents />} />
          <Route path="department" element={<SetUpDepartment />} />
          <Route path="division" element={<SetUpDivision />} />
        </Route>

        <Route
          path="/"
          element={
            <RequireAuth loggedUser={loggedIn.user}>
              <Home />
            </RequireAuth>
          }
        >
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="add" element={<CreateCompany />}>
            <Route path="create" element={<Create />} />
            <Route path="working-address" element={<Outlet />}>
              <Route index element={<WorkingAddress />} />
              <Route path="add" element={<AddWorkingAddress />} />
              <Route path="update" element={<EditWorkingAddress />} />
            </Route>
          </Route>
          <Route path="employee" element={<Outlet />}>
            <Route index element={<ManageEmployeeHome />} />
            <Route path="manage" element={<EmployeeHome />}>
              <Route path="create" element={<CreateEmployee />} />
              <Route path="edit-employee" element={<EditEmployee />} />
              <Route path="employees-info" element={<Outlet />}>
                <Route index element={<UserInfoForm />} />
                <Route path="details" element={<SingleEmployeeDetails />} />
                <Route path="salary" element={<SalaryInfo />}></Route>
                <Route path="revise-salary" element={<ReviseSalary />} />
              </Route>
              <Route path="onboarding" element={<Onboarding />} />
            </Route>
            <Route path="onboarding" element={<OnboardingHome />}>
              <Route path="division" element={<OnboardingDivision />} />
              <Route path="process" element={<OnboardingProcess />} />
              <Route path="initiation" element={<OnboardingInitiation />} />
              <Route path="history" element={<OnboardingHistory />} />
            </Route>
          </Route>
          <Route
            path="employee/manage/employees-info"
            element={<EmployeeInfoSideBar />}
          >
            <Route path="details" element={<SingleEmployeeDetails />} />
            <Route path="salary" element={<SalaryInfo />} />
            <Route path="edit-employee" element={<EditEmployee />} />
            <Route path="profile" element={<UserInfoForm />} />
            <Route path="reports" element={<EmployeeInfoReports />} />
            <Route path="finance" element={<EmployeeInfoFinance />} />
          </Route>
          <Route path="reports" element={<Outlet />}>
            <Route index element={<Reports />} />
            <Route path="manage-reports" element={<PayrollNav />}>
              <Route path="payroll-summary" element={<PayrollSummary />} />
              <Route path="salary-comparison" element={<SalaryComparison />} />
            </Route>
          </Route>

          <Route path="payrun" element={<Outlet />}>
            <Route index element={<PayRunHome />} />
            <Route path="manage-payrun" element={<ManagePayrun />}>
              <Route path="payrun-info" element={<Outlet />}>
                <Route index element={<PayRunInfo />} />
                <Route path="create" element={<Outlet />}>
                  <Route index element={<CreatePayrun />} />
                  <Route path="view-payslip" element={<ViewPayslip />} />
                  <Route path="bank-advice" element={<BankAdvice />} />
                  <Route path="oted" element={<Outlet />}>
                    <Route index element={<ImportEarningAndDeduction />} />
                    <Route path="map-fields" element={<MapFieldsOted />} />
                    <Route path="import" element={<ImportOted />} />
                  </Route>
                  <Route path="lop" element={<Outlet />}>
                    <Route index element={<ImportLopDetails />} />
                    <Route path="map-fields" element={<MapFieldsLop />} />
                    <Route path="import" element={<ImportLop />} />
                  </Route>
                </Route>
              </Route>
              <Route path="processed-payrun" element={<ProcessedPayrun />} />
            </Route>
          </Route>

          <Route path="attendance" element={<Outlet />}>
            <Route index element={<AttendanceHome />} />
            <Route path="manage-leave" element={<ManageLeave />}>
              <Route path="leave-form" element={<LeaveForm />} />
              <Route path="leave-type" element={<LeaveType />} />
              <Route path="add" element={<AddLeave />} />
              <Route path="customise-balance" element={<CustomiseBalance />} />
              <Route path="upload-leave" element={<Outlet />}>
                <Route index element={<UploadLeave />} />
                <Route path="leave-map-feilds" element={<MapFieldsLeave />} />
                <Route path="leave-import" element={<ImportLeave />} />
              </Route>

              <Route path="leave-history" element={<LeaveHistory />} />
              <Route path="leave-balance" element={<LeaveBalance />} />
            </Route>

            <Route path="upload-attendance" element={<UploadAttendance />}>
              <Route path="regular-attendance" element={<Outlet />}>
                <Route index element={<RegularAttendance />} />

                <Route
                  path="regular-map-feilds"
                  element={<MapFieldsRegular />}
                />
                <Route path="regular-import" element={<ImportRegular />} />
              </Route>
              <Route path="day-count" element={<Outlet />}>
                <Route index element={<DayCountAttendance />} />

                <Route
                  path="dayCount-map-feilds"
                  element={<MapFieldsDayCount />}
                />
                <Route path="dayCount-import" element={<DayCountImport />} />
              </Route>
              <Route
                path="attendance-summary"
                element={<AttendanceSummary />}
              />
            </Route>

            <Route path="sync-attendance" element={<SyncAttendance />}>
              <Route
                path="regular-attendance"
                element={<SyncRegularAttendance />}
              />
              <Route path="view/:id" element={<ViewAttendance />} />
            </Route>
          </Route>
          <Route path="finance" element={<Outlet />}>
            <Route index element={<FinanceHome />} />

            <Route
              path="manage-salary-revision"
              element={<ManageSalaryRevision />}
            >
              <Route
                path="approve-salary-revision"
                element={<ApproveSalaryRevision />}
              />
              <Route
                path="salary-revision-details"
                element={<SalaryRevisionDetails />}
              />
            </Route>

            <Route path="manage-claim" element={<ManageReimbursement />}>
              <Route path="add-details" element={<AddDetails />} />
            </Route>

            <Route path="manage-investment" element={<ManageInvestment />}>
              <Route path="create-POI" element={<CreatePOI />} />
              <Route
                path="create-POI/add-details"
                element={<AddDetailsPOI />}
              />
            </Route>
          </Route>

          <Route path="loan" element={<Outlet />}>
            <Route index element={<LoanHome />} />
            <Route path="manage-loan" element={<LoanNav />}>
              <Route path="create-loan" element={<CreateLoan />} />
              <Route path="assign-loan" element={<AssignLoan />} />
              <Route path="loans" element={<Loans />}></Route>
              <Route path="loans/installments/:id" element={<Installments />} />
            </Route>
            <Route path="manage-advance-salary" element={<AdvSalaryNav />}>
              <Route
                path="assign-advance-salary"
                element={<AssignAdvSalary />}
              />
              <Route path="salary-advance" element={<SalaryAdvance />} />
              <Route
                path="salary-advance/installments/:id"
                element={<AdvSalaryInstallments />}
              />
            </Route>
          </Route>
          <Route
            path="employee/manage/upload-documents"
            element={<DocumentHome />}
          >
            <Route path="pan-card" element={<PanCard />} />
            <Route path="aadhar-card" element={<AadharCard />} />
            <Route path="passport" element={<Passport />} />
            <Route path="driver-licence" element={<DriverLicence />} />
            <Route path="voters-id" element={<VoterId />} />
          </Route>

          <Route path="settings" element={<Outlet />}>
            <Route index element={<Settings />} />
            <Route path="master-data" element={<MasterHomee />}>
              <Route path="division" element={<Division />} />
              <Route path="add-division" element={<AddDivision />} />
              <Route path="sub-division" element={<SubDivision />} />
              <Route path="add-subdivision" element={<AddSubDivision />} />
              <Route path="department" element={<Department />} />
              <Route path="add-department" element={<AddDepartment />} />
              <Route path="designation" element={<Designation />} />
              <Route path="add-designation" element={<AddDesignation />} />
              <Route path="headquarter" element={<HeadQuarters />} />
              <Route path="add-headquarter" element={<AddHeadQuarter />} />
              {/* <Route path="city" element={<City />} /> */}
              <Route path="add-city" element={<AddCity />} />
              <Route path="state" element={<State />} />
            </Route>
            <Route path="salary" element={<Salary />}>
              <Route path="earnings" element={<Outlet />}>
                <Route index element={<Earnings />} />
                <Route path="add" element={<AddComponent />} />
                <Route path="edit" element={<EditEarnings />} />
              </Route>
              <Route path="deduction" element={<Outlet />}>
                <Route index element={<Deduction />} />
                <Route
                  path="add-pre-deduction"
                  element={<AddPreDeductionn />}
                />
                <Route
                  path="add-post-deduction"
                  element={<AddPostDedcution />}
                />
                <Route path="edit-pre-deduction" element={<EditDeduction />} />
              </Route>
              <Route path="reimbursement" element={<Outlet />}>
                <Route index element={<Reimbursement />} />
                <Route path="add" element={<AddReimbursement />} />
                <Route path="edit" element={<EditReimbursement />} />
              </Route>
            </Route>
            <Route path="statutory" element={<Statutory />}>
              <Route path="esi" element={<Outlet />}>
                <Route index element={<ESI />} />
                <Route path="handle-esi" element={<AddUpdateEsi />} />
              </Route>
              <Route path="epf" element={<Outlet />}>
                <Route index element={<EPF />} />
                <Route path="handle-epf" element={<AddUpdateEpf />} />
              </Route>
              <Route path="professional-tax" element={<Outlet />}>
                <Route index element={<Professionaltax />} />
                <Route path="handlePt" element={<HandleProfessionalTax />} />
              </Route>
              <Route path="labourwelfarefund" element={<Lwf />} />
            </Route>
            <Route path="taxes" element={<Taxes />}>
              <Route path="manage-taxes" element={<ManageTaxes />} />
              <Route path="income-tax" element={<IncomeTaxHome />} />
              <Route path="regime" element={<Regime />} />
              <Route path="setting" element={<TaxSetting />} />
            </Route>
            <Route path="templates" element={<Tempelates />}>
              <Route path="salary" element={<SalaryTemplates />} />
              <Route path="edit" element={<EditSalaryTemplate />} />
              <Route path="add-tempelate" element={<AddSalaryTemplates />} />
              <Route path="payslip" element={<PayslipTemplates />} />
            </Route>
            <Route path="schedule-payment" element={<Schedules />}>
              <Route path="payment" element={<Outlet />} />
              <Route index element={<SchedulePayments />} />
            </Route>
            <Route path="user" element={<User />}>
              <Route path="profile" element={<Profile />} />
              <Route path="salary-details" element={<SalaryDetails />} />
              <Route path="finance" element={<Finance />} />
              <Route path="attendance" element={<Attendance />} />
            </Route>
          </Route>

          <Route path="notifications" element={<Notifications />} />
        </Route>
      </Routes>
    </>
  );
};

const RequireAuth = ({ children, loggedUser }) => {
  const location = useLocation();

  if (!loggedUser) {
    return <Navigate to="/login" state={{ from: location }} />;
  }
  return children;
};

export default App;
