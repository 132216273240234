import { createSlice } from "@reduxjs/toolkit";
import {
  assignloan,
  getallEmployeesLoans,
  pauseLoanOfEmployee,
  closeLoanOfEmployee,
  editInstallmentAmount
} from "../services/loans";
import customToast from "../components/CustomToast";

const employeesloansSlice = createSlice({
  name: "employeesloans",
  initialState: {
    data: [],
  },
  reducers: {
    setLoanDetails(state, action) {
      state.data = action.payload;
    },
  },
});

export const { setLoanDetails } = employeesloansSlice.actions;

export const fetchAllEmployeesLoans = () => async (dispatch) => {
  try {
    const response = await getallEmployeesLoans();
    dispatch(setLoanDetails(response.data));
  } catch (error) {
    customToast.error("Something went wrong !")
  }
};

export const assignLoan = (payload) => async (dispatch) => {
    try {
      const response = await assignloan(payload);
      dispatch(setLoanDetails(response.data.value)); 
      dispatch(fetchAllEmployeesLoans());
    } catch (error) {
      customToast.error("Something went wrong !")  
    }
}

export const editInstallmentAmt = (payload) => async (dispatch) => {
  try {
    const response = await editInstallmentAmount(payload);
    dispatch(fetchAllEmployeesLoans());
  } catch (error) {
    customToast.error("Something went wrong !")  
  }
}

export const pauseLoan = (payload) => async (dispatch) => {
  try {
    const response = await pauseLoanOfEmployee(payload);
    dispatch(setLoanDetails(response.data.value)); 
    dispatch(fetchAllEmployeesLoans());
  } catch (error) {
    customToast.error("Something went wrong !")  
  }
}

export const closeLoan = (payload) => async (dispatch) => {
  try {
    const response = await closeLoanOfEmployee(payload);
    dispatch(setLoanDetails(response.data.value)); 
    dispatch(fetchAllEmployeesLoans());
  } catch (error) {
    customToast.error("Something went wrong !")  
  }
}

export default employeesloansSlice.reducer;
