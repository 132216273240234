import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate ,Link} from "react-router-dom";
import PageTitle from "../../../components/PageTitle";
import Select from "react-select";
import {
  adminStyles,
  adminStylesLight,
  selectStyles,
} from "../../../utils/selectStyles";
import * as xlsx from "xlsx";
import customToast from "../../../components/CustomToast";
import { getAttendance,uploadRegularAttendance } from "../../../services/attendance";
import { excelDateToJSDate } from "../../../utils/helper";
import { formateAttendanceByTransformedData } from "./attendanceFormator";
const selectOptions = [
  { value: 0, label: "EmployeeUID" },
  { value: 1, label: "EmployeeName" },
  { value: 2, label: "Date" },
  // { value: 3, label: "CheckInTime" },
  // { value: 4, label: "CheckOutTime" },
  // { value: 5, label: "WType" },
  { value: 3, label: "Attendance" },
  { value: 6, label: "Skip" },
];

const MapFieldsRegular = () => {
  const theme = useSelector((state) => state.theme.theme);
  const navigate = useNavigate();
  const location = useLocation();
  const [xlsData, setXlsData] = useState(null);
  const [fieldMappings, setFieldMappings] = useState([]);
  const [selectedMapping, setSelectedMapping] = useState({});
  const [nextClicked, setNextClicked] = useState(false);
  const [fileExt,setFileExt]=useState(null);
  useEffect(() => {
    if (location.state?.uploadedFiles) {
      let file = location.state.uploadedFiles[0]; // Assuming uploadedFiles is an array      
      setFileExt(file.name.split(".")[1]);
      if (file instanceof Blob) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = xlsx.read(data, { type: "array" });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          
          const jsonData = xlsx.utils.sheet_to_json(worksheet, { header: 1 });
          setXlsData(jsonData);
          if (jsonData && jsonData.length > 0) {
            const headers = jsonData[0].map((header, index) => ({
              value: index,
              label: header,
            }));
            setFieldMappings(headers);
          }
        };
        reader.readAsArrayBuffer(file);
      } else {
        // ;
        customToast.error("Please select appropriate file format.")
      }
    } else {
      console.error("No uploaded file found in location state.");
    }
  }, [location.state?.uploadedFiles]);

  const handleMappingChange = (fieldIndex, mappingIndex) => {
    setSelectedMapping((prevMappings) => ({
      ...prevMappings,
      [fieldIndex]: mappingIndex,
    }));
  };

  const [transformedData, setTransformedData] = useState([]);
  useEffect(() => {
    if (xlsData) {
      console.log("selectedMapping",selectedMapping);
      const newData = xlsData.slice(1).map((row) => {
        const rowData = {};
        row.forEach((cell, cellIndex) => {
          const selectedOption = selectOptions[selectedMapping[cellIndex]];
          if (selectedOption && selectedOption.label !== "Skip" && selectedOption.label !== "Date") {
            const header = selectedOption?.label;
            rowData[header] = cell;
          }
          else if (selectedOption && selectedOption.label !== "Skip" && selectedOption.label === "Date") {
            const header = selectedOption?.label;
            rowData[header] = excelDateToJSDate(cell);
          }
        });
        return rowData;
      });
      // console.log(newData)
      setTransformedData(newData);
    }
  }, [selectedMapping]);
// const handleSheet = async()=>{
//   await getAttendance(xlsData).then((res)=>console.log(res.data))
// }
  return (
    <main
      className={
        "main-content " + `${theme == "light" ? " light_bg_main" : "" }`
      }
      style={{ width: "100%" }}
    >
      <div className="creation-content ">
        <PageTitle title="Map Fields" route='../' />
      </div>

      <div className="mr-10 mb-20 mt-10">
        <p>
          <span className="font-medium text-3xl">Selected File</span>
          {location.state?.uploadedFiles.map((file) => (
            <span className="bg-success p-2 bg-opacity-30 ml-10 text-cyan rounded-sm">
              {file.name}
            </span>
          ))}
        </p>
      </div>

      <div className="mr-10 mb-10">
        <table>
          <thead>
            <tr>
              <th className="p-4 text-left mr-10">Attendance Fields</th>
              <th className="p-4 text-left">Import File Fields</th>
            </tr>
          </thead>

          <tbody>
            {xlsData &&
              xlsData[0]?.map((el, index) => (
                <tr key={index}>
                  <td
                    className={
                      "p-4 " +
                      `${
                        theme == "light"
                          ? "text-secondaryDark"
                          : "text-secondary"
                      }`
                    }
                  >
                    {el} {selectOptions.some(option => option.label.toLowerCase().trim() === el.toLowerCase().trim())?<span className="text-red-600">*</span>:null}
                  </td>
                  <td className="text-secondary p-4 w-[25rem]">
                    <Select
                      styles={
                        theme == "light" ? adminStylesLight : adminStyles
                      }
                      options={selectOptions}
                      value={selectOptions[selectedMapping[index]]}
                      onChange={(selectedOption) =>
                        handleMappingChange(index, selectedOption.value)
                      }
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <div
        className={
          "mb-10 mr-10 flex items-center gap-10 p-8 " +
          `${theme == "light" && " bg-white "}`
        }
      >
        <input
          type="checkbox"
          className="markActive blue_b"
        />
        <label className="text-secondary">
          Save these selection preferences for future imports
        </label>
      </div>

      <div className="mr-10 mb-20 flex items-center gap-20">
        <button
          className="button-green-gradient"
          onClick={async() => 
           { if (transformedData.length === 0) return;
            try {
              const payload = {formattedAttendance:formateAttendanceByTransformedData(transformedData)}
            const res = await uploadRegularAttendance(payload)
            customToast.success("Attendance uploaded successfully")
            } catch (error) {
              customToast.error("Error in uploading attendance")
            }
            
            // console.log(formateAttendanceByTransformedData(transformedData))
            // navigate(
            //   "/attendance/upload-attendance/regular-attendance/regular-import", {state:{transformedData,fileExt}}
            // )
            // handleSheet()
          }}
        >
        Upload
        </button>
        <Link to='/attendance/upload-attendance/regular-attendance'>
        <button className="button-red-gradient">Cancel</button>
        </Link>
      </div>
    </main>
  );
};

export default MapFieldsRegular;
