import { customAxios } from "../utils/axiosinstance";

// export const setToken = (newToken) => (token = `bearer ${newToken}`);

export const login = async (credentials) => {
  const { data } = await customAxios.post("client/auth/login", credentials);
  return data.data;
};

export const getUserData = async (id) => {
  const { data } = await customAxios.get("client/auth/user-info", {
    params: { id },
  });
  return data.data;
};

// export const viewUsersForUtilities = async () => {
//   const { data } = await customAxios.get(
//     "client/utilities/view-users-for-reports",
//   );
//   return data;
// };  //this route fetches data from medorn's database we should not show user instead use employees

// export const viewDivisions = async () => {
//   const { data } = await customAxios.get("/client/user/division");
//   return data;
// };

export const viewDesignations = async () => {
  const { data } = await customAxios.get("/client/user/designation");
  return data;
};

export const newDesignation = async (details) => {
  const { data } = await customAxios.post("/client/user/designation", details);
  return data;
};

export const removeDesignation = async (type, id) => {
  const { data } = await customAxios.post(`/client/user/${type}/${id}`);
  return data;
};

export const editDesig = async (id, type, editedDesignation) => {
  const { data } = await customAxios.put(
    `/client/user/${type}/${id}`,
    editedDesignation,
  );
  return data;
};
