import React, { useEffect, useMemo, useState } from "react";
import "./division.css";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../../components/PageTitle";
import {
  createDivisions,
  editDivisions,
  setDivisions,
} from "../../../reducers/divisionReducer";
import customToast from "../../../components/CustomToast";
import Table from "../../../components/Table";
import { FaPen } from "react-icons/fa";

const Division = () => {
  const theme = useSelector((state) => state.theme.theme);
  const dispatch = useDispatch();
  const { data: divisions } = useSelector(({ divisions }) => divisions);

  const [divName, setDivName] = useState("");
  const [updateId, setUpdateId] = useState("");

  const columns = useMemo(
    () => [
      {
        Header: "Sr no.",
        accessor: "sr",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          // console.log(props?.row?.original);

          return <span>{props?.row?.index + 1}</span>;
        },
      },
      {
        Header: "Division",
        accessor: "name",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "division Code",
        accessor: "uid",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Action",
        accessor: "Action",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => (
          <span
            className="react-table-view-link"
            onClick={() => {
              // setEditing(true);
              setUpdateId(props?.row?.original?._id);
              setDivName(props?.row?.original?.name);
            }}
          >
            <FaPen className="text-3xl dark:text-tertiary" />
          </span>
        ),
      },
    ],
    [],
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!divName) return;
      if (!updateId) {
        await dispatch(createDivisions({ name: divName }));
        setDivName("");
        return customToast.success("Division Added successFully");
      }
      if (updateId) {
        await dispatch(editDivisions(updateId, { name: divName }));
        setDivName("");
        setUpdateId("");
        return customToast.success("Division Updated successFully");
      }
    } catch (error) {
      customToast.error("Error in adding division !");
    }
  };

  useEffect(() => {
    dispatch(setDivisions());
  }, []);
  return (
    <main className="main-content bg-primaryLight2 dark:bg-primaryDark1">
      <div
        className={
          "area-creation-content" + `${theme == "light" ? "light_bg" : ""}`
        }
      >
        <PageTitle title="Division" route={"../.."} />
        <section className="admin-content">
          <div className="row">
            <div>
              <label>
                Enter Division Name <span className="asterisk-imp">*</span>
              </label>
              <input
                placeholder="Enter Division"
                value={divName}
                onChange={(e) => setDivName(e.target.value)}
              />
            </div>

            <div className="items-end flex">
              <button
                onClick={handleSubmit}
                className={
                  updateId
                    ? "button-green-gradient py-[0.8rem] px-[2rem]"
                    : "button-blue-gradient py-[0.8rem] px-[2rem]"
                }
              >
                {updateId ? "Update Division" : "Add Division"}
              </button>
            </div>
          </div>

          <div className="mx-8 pb-10">
            {divisions.length > 0 && (
              <h2
                className={
                  "show-entries" + `${theme === "light" ? "light_bg_logo" : ""}`
                }
              >
                Showing ({divisions.length}) Entries
              </h2>
            )}

            {divisions.length > 0 ? (
              <div className="filter-table">
                <Table columns={columns} data={divisions} />
              </div>
            ) : (
              <div className="text-error font-medium text-3xl mt-5">
                No departments Exists!
              </div>
            )}
            {/* {editing && (
                <EditPopup
                  placeholder="Enter Department"
                  value={deptName}
                  setValue={setDept}
                  setShowEdit={setEditing}
                  handleDelete={handleDelete}
                  handleEdit={handleEdit}
                  label="Department Name"
                />
              )} */}
          </div>
        </section>
      </div>
    </main>
  );
};

export default Division;
