import React, { useEffect, useMemo } from "react";
import PageTitle from "../../../../components/PageTitle";
import { useNavigate } from "react-router-dom";
import { FaPen } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { fetchEpfDetails } from "../../../../reducers/epfDetailsReducer";
import Table from "../../../../components/Table";

const EPF = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useSelector((state) => state.theme.theme);

  let tableData = [useSelector((state) => state.epfInfo.data)];

  const handleAddEpf = () => {
    navigate("handle-epf", { state: { type: "add" } });
  };

  const handleEditEpf = () => {
    navigate("handle-epf", {
      state: {
        type: "edit",
        epfDetails: {
          epfNumber: tableData[0]?.epfNumber,
          deductionType: tableData[0]?.deductionType,
          employerContributionRate: tableData[0]?.employerContributionRate,
          employeeContributionRate: tableData[0]?.employeeContributionRate,
          calculatOnProDataBasis: tableData[0]?.calculatOnProDataBasis,
          allSalaryComponentsConsideration:
            tableData[0]?.allSalaryComponentsConsideration,
          includeEmployersContribution:
            tableData[0]?.includeEmployersContribution,
          addPfAdminChargesInCtc: tableData[0]?.addPfAdminChargesInCtc,
          addEmployersEdliContributionInCtc:
            tableData[0]?.addEmployersEdliContributionInCtc,
          componentOnPayslip: tableData[0]?.componentOnPayslip,
          enableEpf: tableData[0]?.enableEpf,
        },
      },
    });
  };

  useEffect(() => {
    dispatch(fetchEpfDetails());
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "EPF Number",
        accessor: (row) => row?.epfNumber || "--",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Deduction Type",
        accessor: (row) => row?.deductionType?.label || "--",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Employer Contribution",
        accessor: (row) => row?.employerContributionRate?.label || "--",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Employee Contribution",
        accessor: (row) => row?.employeeContributionRate?.label || "--",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Status",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return (
            <div
              className={`${
                props.row?.original.isActive || props.row?.original.enableEpf
                  ? "text-green-500"
                  : "text-red-600"
              }`}
            >
              {props.row?.original.isActive || props.row?.original.enableEpf
                ? "Active"
                : "Inactive"}
            </div>
          );
        },
      },
      {
        Header: "Action",
        disableFilters: true,
        disableSortBy: true,
        Cell: () => {
          return (
            <FaPen
              onClick={handleEditEpf}
              className="text-tertiary text-3xl m-auto"
              style={{ cursor: "pointer" }}
            />
          );
        },
      },
    ],
    [],
  );

  return (
    <main className="main-content bg-primaryLight2 dark:bg-primaryDark1">
      <div
        className={
          "area-creation-content" + `${theme == "light" ? "light_bg" : ""}`
        }
      >
        <PageTitle title="EPF" route={"../.."} />

        <section className="admin-content">
          <div className="mx-8 pb-10">
            {tableData?.length > 0 && (
              <h2 className="show-entries">
                Showing ({tableData.length}) entries
              </h2>
            )}

            {tableData[0]?.docName == "epfInfo" ? (
              <div className="filter-table">
                <Table columns={columns} data={tableData} exportExcel={false} />
              </div>
            ) : (
              <div>
                <button
                  onClick={handleAddEpf}
                  className="rounded-full font-medium text-2xl px-24 py-5 dark:bg-tertiary cursor-pointer transform hover:scale-95"
                >
                  ADD EPF
                </button>
              </div>
            )}
          </div>
        </section>
      </div>
    </main>

    // <main
    //   className={"main-content " + `${theme == "light" ? "light_bg_main" : ""}`}
    //   style={{ width: "100%" }}
    // >
    //   <div className="creation-content">
    // <PageTitle title="EPF" route={'../..'}/>
    //   </div>

    //   {tableData[0]?.docName == "epfInfo" ? (
    //     <div className="filter-table !mr-8">
    //       {/* <Table columns={columns} data={tableData} exportExcel={false} /> */}
    // <table className="mt-8 w-full">
    //   <thead className="uppercase dark:text-secondary dark:bg-primaryDark2 w-full text-center">
    //     <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">
    //       EPF Number
    //     </th>
    //     <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">
    //       Deduction Type
    //     </th>
    //     <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">
    //       Employer Contribution
    //     </th>
    //     <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">
    //       Employee Contribution
    //     </th>
    //     <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">
    //       Status
    //     </th>
    //     <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">
    //       Action
    //     </th>
    //   </thead>
    //   <tbody>
    //     {tableData?.map((el, idx) => (
    //       <tr key={idx} className="dark:bg-primary text-center">
    //         <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6">
    //           {el?.epfNumber || "Loading ..."}
    //         </td>
    //         <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6">
    //           {el?.deductionType?.label || "Loading ..."}
    //         </td>
    //         <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6">
    //           {el?.employerContributionRate.label || "Loading ..."}
    //         </td>
    //         <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6">
    //           {el?.employeeContributionRate.label || "Loading ..."}
    //         </td>
    //         <td className={`border-2 border-collapse border-primarrDark border-primaryDark px-6 py-6 `+ `${el?.enableEpf ? "text-green-500" : "text-red-600"}`}>
    //           {el?.enableEpf ? "Active" : "Inactive"}
    //         </td>
    //         <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6 ">
    //           <FaPen
    //             onClick={handleEditEpf}
    //             className="text-tertiary text-3xl m-auto"
    //             style={{ cursor: "pointer" }}
    //           />
    //         </td>
    //       </tr>
    //     ))}
    //   </tbody>
    // </table>
    //     </div>
    //   ) : (
    //     <div>
    //       <button
    //         onClick={handleAddEpf}
    //         className="rounded-full font-medium text-2xl px-24 py-5 dark:bg-tertiary cursor-pointer transform hover:scale-95"
    //       >
    //         ADD EPF
    //       </button>
    //     </div>
    //   )}
    // </main>
  );
};

export default EPF;
