import { FaCartPlus, FaRoute, FaHome, FaRoad, FaCity, FaStethoscope, FaBriefcaseMedical } from "react-icons/fa";
import { CgPill } from "react-icons/cg";
import { GiTakeMyMoney } from "react-icons/gi";
import { MdNotificationsActive } from "react-icons/md";
import { RiUserShared2Fill } from "react-icons/ri";
import { Link } from "react-router-dom";

const NotificationBox = ({ title, time, description, type, link }) => {

    let icon = null;
    switch (type) {
        case "Onboarding":
            icon = (<RiUserShared2Fill />)
            break;
        default: {
            icon = (<MdNotificationsActive />)
        }
    }

    const date = time.split(" ")[0];
    const time_ = time.split(" ")[1];

    return (
        <Link to={link}>
            <div className="notification__box">
                <div className="notification__avatar">
                    {icon}
                </div>
                <div className="notification__text">
                    <div className="notification__text-top">
                        <div className="notification__title">{title}</div>
                        <div className="notification__time">{date}</div>
                    </div>
                    <div className="flex justify-between">
                    <div className="notification__description">{description}</div>
                    <div className="notification__time">{time_}</div>
                    </div>

                </div>
            </div>
        </Link>
    );
};

export default NotificationBox;
