import React from 'react'
import { BsEyeSlash } from "react-icons/bs";

const ComingSoon = () => {
  return (
    <div className="coming-soon  absolute inset-0 text-center flex flex-col  items-center">
        <BsEyeSlash className="text-[6rem] mt-80"/>
        <h1 className="text-[4rem] text-bold">COMING SOON</h1>
        <p>This screen contains New Features</p>
      </div>
  )
}

export default ComingSoon