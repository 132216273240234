import React from 'react'
import Form from 'react-bootstrap/Form';

const OtherConfig = ({others , handleCheckboxChange}) => {
  return (
   <>
    {others.titles.map((title) => 
      <Form> 
      <div className="mt-3 other-config">
     {['checkbox'].map((type) => (
              <Form.Check
               inline
               label={title.label}
               name={title.name}
               type={type}
               id={title.id}
               className="d-flex"
               onChange={() => handleCheckboxChange(title.id)}
               value={title.id}
          />
          
      ))} 
      <p>{title.warning}</p>
      </div>
     </Form>)}
    </>
  )
}

export default OtherConfig