import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RxCross1 } from "react-icons/rx";
import "./Modal.css"

const Modal = ({ showModal, closeModal, width, title, content }) => {
    const theme = useSelector((state) => state.theme.theme);

    return (
        <div>
            {
                showModal &&
                (
                    <main className={`w-full ${theme === "light" ? "light_bg_main" : ""}`}>
                        <div className={`modal-overlay z-[100]`}>
                            <div
                                className={
                                    " modal " +
                                    `${theme === "light" ? "bg-white" : "bg-primaryDark2"}`
                                }
                            >
                                <div className="flex items-center justify-between py-10 px-16 border-b-4 border-b-primaryDark">
                                    <h2 className={`${width} text-3xl uppercase`}>{title}</h2>
                                    <RxCross1
                                        size="30"
                                        onClick={closeModal}
                                        className="cursor-pointer text-tertiary"
                                    />
                                </div>
                                <div className='modal-content py-10 px-16'>
                                    {content}
                                </div>
                            </div>
                        </div>
                    </main>
                )
            }
        </div>
    );
};

export default Modal;


