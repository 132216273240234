import { useCallback, useEffect, useMemo, useState } from "react";
import PageTitle from "../../../components/PageTitle";
import { adminStyles, selectStyles } from "../../../utils/selectStyles";
import Select from "react-select";
import { useSelector } from "react-redux";
import { FaRegCircle } from "react-icons/fa";
import { GrRadialSelected } from "react-icons/gr";
import { GetEarningTypes, PostEarning } from "../../../services/earning";
import { MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
import { IoCheckbox } from "react-icons/io5";
import customToast from "../../../components/CustomToast";
import { generateSelectData } from "../../../utils/serialiseResponse";
import { postTax } from "../../../services/deduction";
import { addTaxInfo, getTaxInfo } from "../../../services/hrms";
const ManageTaxes = () => {
  const theme = useSelector((state) => state.theme.theme);
  const [pan, setPan] = useState("");
  const [tan, setTan] = useState("");
  const [tdsCircle, setTdsCircle] = useState("");
  const [tpf, setTpf] = useState({ value: "monthly", label: "monthly" });
  const [fatherName, setFatherName] = useState("");
  const [des, setDes] = useState("");
  const [isEmployee, setIsEmployee] = useState(false);
  const [deductor, setDeductor] = useState("");
  const tpfOptions = [
    { value: "monthly", label: "monthly" },
    { value: "yearly", label: "yearly" },
    { value: "halfYearly", label: "halfYearly" },
    { value: "quarterly", label: "quarterly" },
  ];

  useEffect(() => {
    getTaxInfo().then((data) => {
      const {
        pan,
        tan,
        taxPaymentFreq,
        isEmployee,
        fatherName,
        designation,
        deductorName,
        aoCode,
      } = data;

      setPan(pan);
      setTan(tan);
      setTdsCircle(aoCode);
      setFatherName(fatherName);
      setDes(designation);
      setIsEmployee(isEmployee);
      setDeductor(deductorName);
      setTpf({ value: taxPaymentFreq, label: taxPaymentFreq });
    });
  }, []);
  const resetStates = () => {
    setPan("");
    setTan("");
    setTpf(null);
    setFatherName("");
    setDes("");
    setIsEmployee(false);
    setDeductor("");
    setTdsCircle("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!pan) {
      customToast.error("Please fill all the required fields");
      return;
    }

    const payload = {
      pan,
      tan,
      taxPaymentFreq: tpf?.value,
      fatherName,
      designation: des,
      isEmployee,
      deductorName: deductor,
      aoCode: tdsCircle,
    };
    addTaxInfo(payload)
      .then(() => {
        resetStates();
        customToast.success("Tax Details Saved Successfully");
      })
      .catch((err) => customToast.error("Sorry something went wrong!"));
  };

  return (
    <main className="main-content bg-primaryLight2 dark:bg-primaryDark1">
      <div
        className={
          "area-creation-content" + `${theme == "light" ? "light_bg" : ""}`
        }
      >
        <PageTitle title="Manage Taxes" route={".."} />

        <form>
          <section className="admin-content">
            <div className="row">
              <div>
                <label>
                  PAN<span className="text-error">*</span>
                </label>
                <input
                  id="eName"
                  type="text"
                  placeholder="Enter PAN"
                  value={pan}
                  onChange={({ target }) => setPan(target?.value)}
                />
              </div>

              <div>
                <label>TAN</label>
                <input
                  id="eName"
                  type="text"
                  placeholder="Enter TAN"
                  value={tan}
                  onChange={({ target }) => setTan(target?.value)}
                />
              </div>

              <div>
                <label>TDS Circle/AO Code</label>
                <input
                  id="eName"
                  type="text"
                  placeholder="AOB/VU/478/7"
                  value={tdsCircle}
                  onChange={({ target }) => setTdsCircle(target?.value)}
                />
              </div>

              <div>
                <label>Tax Payment Frequency</label>
                <Select
                  options={tpfOptions}
                  styles={adminStyles}
                  value={tpf}
                  onChange={(e) => setTpf({ ...e })}
                />
              </div>
            </div>

            <div className="pb-10">
              <div className="mx-8">
                <label>TAX Deduction Details</label>
                <div className="flex gap-14 mt-4">
                  <label
                    className="cursor-pointer"
                    onClick={() => setIsEmployee(true)}
                  >
                    {isEmployee ? (
                      <IoCheckbox className="text-success inline-block text-4xl me-4 cursor-pointer" />
                    ) : (
                      <MdOutlineCheckBoxOutlineBlank
                        className="text-success inline-block text-4xl me-4 cursor-pointer"
                        onClick={() => setIsEmployee(true)}
                      />
                    )}
                    Employee
                  </label>

                  <label
                    className="cursor-pointer"
                    onClick={() => setIsEmployee(false)}
                  >
                    {!isEmployee ? (
                      <IoCheckbox className="text-success inline-block text-4xl me-4 cursor-pointer" />
                    ) : (
                      <MdOutlineCheckBoxOutlineBlank
                        className="text-success inline-block text-4xl me-4 cursor-pointer"
                        onClick={() => setIsEmployee(false)}
                      />
                    )}
                    Non Employee
                  </label>
                </div>
              </div>

              <div className="row">
                <div>
                  <label>Enter Deductor's Name</label>
                  <input
                    id="eName"
                    type="text"
                    placeholder="Enter Name"
                    value={deductor}
                    onChange={({ target }) => setDeductor(target?.value)}
                  />
                </div>

                <div>
                  <label>Enter Father Name</label>
                  <input
                    id="eName"
                    type="text"
                    placeholder="Enter Name"
                    value={fatherName}
                    onChange={({ target }) => setFatherName(target?.value)}
                  />
                </div>

                {/* <div>
                  <label>Enter Deductor's Designation<span className="text-error">*</span></label>
                  <input
                    id="eName"
                    type="text"
                    placeholder="Enter Designation"
                    value={des}
                    onChange={({ target }) => setDes(target?.value)}
                  />
                </div> */}
              </div>

              <div className="row">
                <div>
                  <label>Enter Deductor's Designation</label>
                  <input
                    id="eName"
                    type="text"
                    placeholder="Enter Designation"
                    value={des}
                    onChange={({ target }) => setDes(target?.value)}
                  />
                </div>
              </div>

              <button
                onClick={handleSubmit}
                className="button-blue-gradient mx-8"
              >
                Save
              </button>
            </div>
          </section>
        </form>
      </div>
    </main>

    // <main className="mt-8 pr-8 w-full">
    //   <div className="dark:bg-primaryDark2"  >
    //     <PageTitle title="Manage Taxes" route={'..'}/>
    //   </div>
    //   <div className="mt-6 py-8 lg:w-1/2">
    //     <div className="flex justify-between items-center gap-16 flex-wrap">
    //       <div>
    // <label  className="text-3xl uppercase">PAN<span className="text-error">*</span></label><br/>
    // <input
    //   id="eName"
    //   type="text"
    //   placeholder="Enter PAN"
    //   className="dark:bg-primary dark:text-secondary outline-none border-none px-6 py-2 rounded-lg mt-2"
    //   value={pan}
    //   onChange={({target})=>setPan(target?.value)}
    // />
    //       </div>
    // <div>
    //   <label  className="text-3xl uppercase">TAN</label><br/>
    //   <input
    //     id="eName"
    //     type="text"
    //     placeholder="Enter TAN"
    //     className="dark:bg-primary dark:text-secondary outline-none border-none px-6 py-2 rounded-lg mt-2"
    //     value={tan}
    //     onChange={({target})=>setTan(target?.value)}
    //   />
    // </div>
    // <div>
    //   <label  className="text-3xl uppercase">TDS Circle/AO Code</label><br/>
    //   <input
    //     id="eName"
    //     type="text"
    //     placeholder="AOB/VU/478/7"
    //     className="dark:bg-primary dark:text-secondary outline-none border-none px-6 py-2 rounded-lg mt-2"
    //     value={tdsCircle}
    //     onChange={({target})=>setTdsCircle(target?.value)}
    //   />
    // </div>
    // <div className="w-96">
    //   <label className="text-3xl uppercase">Tax Payment Frequency</label>
    //   <br />
    //   <Select
    //     options={tpfOptions}
    //     styles={adminStyles}
    //     value={tpf}
    //     onChange={(e) => setTpf({...e})}
    //     className="mt-2"
    //   />
    // </div>
    //     </div>
    //   <div className=" text-3xl mt-16 uppercase">TAX Deduction Details</div>
    //   <div className="flex  gap-14">
    //     <div className="text-3xl font-medium mt-5">
    //       {isEmployee
    //       ? <IoCheckbox
    //         className="text-success inline-block text-4xl me-4 cursor-pointer"
    //         />
    //       :
    //         <MdOutlineCheckBoxOutlineBlank
    //         className="text-success inline-block text-4xl me-4 cursor-pointer"
    //         onClick={()=>setIsEmployee(true)}
    //         />
    //       }
    //       Employee
    //     </div>
    //     <div className="text-3xl font-medium mt-5">
    //       { !isEmployee
    //       ? <IoCheckbox
    //         className="text-success inline-block text-4xl me-4 cursor-pointer"
    //         />
    //       :
    //         <MdOutlineCheckBoxOutlineBlank
    //         className="text-success inline-block text-4xl me-4 cursor-pointer"
    //         onClick={()=>setIsEmployee(false)}
    //         />
    //       }
    //       Non Employee
    //     </div>
    //   </div>
    //   <div className="flex justify-between my-16 gap-16" >
    //     <div>
    //       <label  className="text-3xl uppercase">Enter Deductor's Name<span className="text-error">*</span></label><br/>
    //       <input
    //         id="eName"
    //         type="text"
    //         placeholder="Enter Name"
    //         className="dark:bg-primary dark:text-secondary outline-none border-none px-6 py-2 rounded-lg mt-2"
    //         value={deductor}
    //         onChange={({target})=>setDeductor(target?.value)}
    //       />
    //     </div>
    //     <div>
    //       <label  className="text-3xl uppercase">Enter Father Name<span className="text-error">*</span></label><br/>
    //       <input
    //         id="eName"
    //         type="text"
    //         placeholder="Enter Name"
    //         className="dark:bg-primary dark:text-secondary outline-none border-none px-6 py-2 rounded-lg mt-2"
    //         value={fatherName}
    //         onChange={({target})=>setFatherName(target?.value)}
    //       />
    //     </div>
    //   </div>
    //   <div>
    //     <label  className="text-3xl uppercase">Enter Deductor's Designation<span className="text-error">*</span></label><br/>
    //     <input
    //       id="eName"
    //       type="text"
    //       placeholder="Enter Designation"
    //       className="dark:bg-primary dark:text-secondary outline-none border-none px-6 py-2 rounded-lg mt-2"
    //       value={des}
    //       onChange={({target})=>setDes(target?.value)}
    //     />
    //   </div>
    //   <button
    //    onClick={handleSubmit}
    //    className="button_blue_gradient mt-10"
    //   >Save</button>
    // </div>
    // </main>
  );
};

export default ManageTaxes;
