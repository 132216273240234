import React from "react";
import { title, links } from "./userNavData";
import { Outlet } from "react-router-dom";
import SideBar from "../../../components/SideBar/SideBar";

const User = () => {
  return (
    <>
      <SideBar title={title} links={links} />
      <Outlet />
    </>
  );
};

export default User;
