import { useEffect, useState, useMemo } from "react";
import Select from "react-select";
import customToast from "../../../components/CustomToast";
import PageTitle from "../../../components/PageTitle";
import { adminStyles, adminStylesLight } from "../../../utils/selectStyles";
import { useDispatch, useSelector } from "react-redux";
import { clearEmployees, fetchEmployees } from "../../../reducers/employeesReducer";
import { generateSelectData } from "../../../utils/serialiseResponse";
import { SaveLeaveAppl, getAllLeaves, getUserLeaveType,checkBalance } from "../../../services/leave";

const LeaveForm = () => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.theme);
  const employees = useSelector(({ employees }) => employees);
  const [empl, setEmpl] = useState(null);
  const [leaves, setLeaves] = useState([]);
  const [selectedLeave, setSelectedLeave] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(null);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [reason, setReason] = useState("");
  
  const resetForm =async () => {
    setEmpl(null);
    setSelectedLeave(null);
    setReason("");
    setFromDate("");
    setSelectedUnit(null);
    setToDate("");
  };

  useEffect(() => {
    dispatch(fetchEmployees());

    return () => dispatch(clearEmployees());
  }, [dispatch]);

  
  useEffect(() => {

    empl && checkBalance(empl?._id).then((res) => setLeaves(res.data))
      .catch(() => customToast.error("Error occurred"))
    setSelectedLeave(null)
  }, [empl]);

  const emplSelect = useMemo(
    () => generateSelectData(employees.data, "fullName"),
    [employees.data]
  );

  const leaveTypes = useMemo(
    () => leaves&&generateSelectData(leaves, "leave_Name"),
    [leaves]
  );
  
  const leaveUnits = [
    { value: 1, label: "Hours" },
    { value: 2, label: "Days" },
  ]


  const handleSave = (e) => {
    e.preventDefault();

    let ToDate = new Date(toDate);
    let FromDate = new Date(fromDate);
    let duration = (ToDate.getDate() - FromDate.getDate())+1;
    
    if (ToDate.getTime() < FromDate.getTime() ) {
      return customToast.error("Invalid duration !");
    }

    if (!empl && !selectedLeave) {
      return customToast.error("Enter required details !");
    }
    if(duration>selectedLeave.leave_balance){
      return customToast.error("Duration exceed leave balance limit !")
    }

    let data = {
      employee: empl?._id,
      leave_type: selectedLeave?._id,
      fromDate: String(fromDate),
      toDate: String(toDate),
      reason: reason,
      leave_unit: selectedUnit
    };

    SaveLeaveAppl(data).then(() =>{resetForm(); customToast.success("Saved successfully !")})
      .catch(() => customToast.error("Error occurred"));

    
  };

  return (
    <div className="main-content  bg-primaryLight2 dark:bg-primaryDark1" >
      <div className={"area-creation-content " + `${theme == "light" ? "light_bg" : ""}`}>
        <PageTitle title="Add Leave Details" route={'../../'} />

        <section className="admin-content pb-2">
          <form className={`${theme == "light" ? "lightbg" : ""}`}>
            <div className="row">
              <div >
                <label htmlFor="emp_name" >
                  Employee Name
                  <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  id="emp_name"
                  options={emplSelect}
                  value={empl}
                  onChange={(el) => setEmpl(el)}
                  styles={theme == "light" ? adminStylesLight : adminStyles}
                  placeholder="Select  Name"
                />
              </div>
              <div >
                <label htmlFor="leave_type">
                  Leave Type
                  <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  id="leave_type"
                  value={selectedLeave}
                  options={leaveTypes}
                  onChange={(el) => setSelectedLeave(el)}
                  styles={theme == "light" ? adminStylesLight : adminStyles}
                  placeholder="Leave Type"
                />
              </div>
              <div>
                <label htmlFor="leave_unit">
                  Leave Unit
                  <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  id="leave_unit"
                  value={selectedUnit}
                  options={leaveUnits}
                  onChange={(el) => setSelectedUnit(el)}
                  styles={theme == "light" ? adminStylesLight : adminStyles}
                  placeholder="Leave Unit"
                />
              </div>
              <div className="w-full">
                <label htmlFor="from_date">
                  From Date
                  <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  required
                  type="date"
                  
                  style={{ width: "100%", color: '#9E9AD1' }}
                  onChange={(e) => setFromDate(e.target.value)}
                  className=" mt-4 w-30 h-[4rem]"
                  placeholder="dd-mm-yy"
                  value={fromDate}
                />
              </div>
              <div className="w-full ">
                <label htmlFor="to_date">
                  TO Date
                  <span className="asterisk-imp">*</span>
                </label>
                <br />
                {/* <div className="w-64"> */}
                <input
                  required
                  
                  type="date"
                  style={{ width: "100%", color: '#9E9AD1' }}
                  className={"mt-4 h-[4rem] m-0 p-0 pr-0" + `${theme === 'light' ?
                    "text-area-bg-light " : "text-area-bg"}`}
                  onChange={(e) => setToDate(e.target.value)}
                  placeholder="dd-mm-yy"
                  value={toDate}
                />
                {/* </div> */}
              </div>


              <div className="mt-6 col-start-1 col-span-2">
                <label htmlFor="leave_reason">
                  Reason For Leave
                </label>
                <br />
                <textarea
                  name="leave reason"
                  placeholder="Enter Reason For Leave"
                  id="mName"
                  value={reason}
                  rows={3}
                  style={{ color: '#9E9AD1' }}
                  className={`w-full mt-4 p-4  rounded-md ${theme === 'light' ?
                    "text-area-bg-light" : "text-area-bg"}`}
                  onChange={(e) => setReason(e.target.value)}
                ></textarea>
              </div>
            </div>

            <div className="row ">
              <div className="col-sm-12 col-md-4 col-lg-4 area-creation-content__form-submit">
                <button type="submit" className="button-blue-gradient" onClick={(e) => handleSave(e)}>
                  Save
                </button>
              </div>
            </div>
          </form>
        </section>
      </div>
    </div>
  );
};

export default LeaveForm;
