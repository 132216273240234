import React, { useEffect, useMemo, useRef } from 'react';
import PageTitle from '../../../../components/PageTitle';
import Select from "react-select";
import { useSelector } from 'react-redux';
import { adminStyles, adminStylesLight } from '../../../../utils/selectStyles';
import { useState } from 'react';
import { FaChevronUp, FaChevronDown } from "react-icons/fa6";
import { yearOptions } from '../../../../utils/helper';
import { addPOI, get80Cschemes, get80Dschemes, getOtherInvestments } from '../../../../services/deduction';
import customToast from '../../../../components/CustomToast';
import { generateSelectData } from '../../../../utils/serialiseResponse';
import { IoIosAddCircleOutline } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { useLocation } from 'react-router-dom';
import Accordion from '../../../../components/Accordion';

const AddDetailsPOI = () => {

    const location = useLocation();
    const theme = useSelector((state) => state.theme.theme);
    const totalAmtRef = useRef(0);
    const totalAmtRef80D = useRef(0);
    const totalAmtRefOtherInves = useRef(0);
    const [HRDtoggle, setHRDtoggle] = useState(false);
    const [eightyCToggle, setEightyCToggle] = useState(false);
    const [eightyDToggle, setEightyDToggle] = useState(false);
    const [otherInvestToggle, setOtherInvestToggle] = useState(false);
    const [isPreviousEmplToggle, setIsPreviousEmplToggle] = useState(false);
    const [isOtherIncomeToggle, setIsOtherIncomeToggle] = useState(false);
    const [isRentedtoggle, setIsRentedtoggle] = useState(false);
    const [isRepayLoanToggle, setIsRepayLoanToggle] = useState(false);
    const [isRentalIncomeToggle, setIsRentalIncomeToggle] = useState(false);
    const [FinancialYear, setFinancialYear] = useState(yearOptions[2]);
    const [eightyCdata, setEightyCdata] = useState([]);
    const [eightyDdata, setEightyDdata] = useState([]);
    const [otherInvestmentsData, setOtherInvestmentsData] = useState([]);
    const [formValues80C, setFormValue80C] = useState([
        { investment: null, amount: '' }
      ]);
      const [formValues80D, setFormValues80D] = useState([
        { investment: null, amount: '' }
      ]);
      const [formValuesOtherInves, setFormValuesOtherInves] = useState([
        { investment: null, amount: '' }
      ]);
    const [oldRegimeToggle, setOldRegimeToggle] = useState(true);
    const [newRegimeToggle, setNewRegimeToggle] = useState(false);
    const [formValuesRentedHouse, setFormValuesRentedHouse] = useState([{fromDate: null, toDate : null, rentAmt : "", cityType :null, address : null, landlordName :null, PAN: ""}]);
    const [formValuesRepayLoan, setFormValuesRepayLoan] = useState([{principalAmt: null, interest: null, lenderName: null, lenderPAN: null}]);
    const [formValuesRentalIncome, setFormValuesRentalIncome] = useState([{rentReceived : null, taxPaid: null, netValue: null, StdDeduction: null, principal: null, interest:null, lenderName:null, lenderPAN:null, netIncome: null}]);
    const [previousEmplData, setPreviousEmplData] = useState([{exempt_income: null, incomeTax: null, emplPF:null}]);
    const [otherSrcIncomeData, setOtherSrcIncomeData] = useState([{FD_interest: null, otherSourceIncome: null}]);

    const cityTypeOption = [
        {value: 1, label: "Metro"},
        {value: 2, label: "Non-Metro"},
    ];

    const handleChangeRentedHouse = (idx, updatedValue) =>{
        let newFormValues = [...formValuesRentedHouse];
        newFormValues[idx] = {...newFormValues[idx], ...updatedValue};
        setFormValuesRentedHouse(newFormValues);
    };

    const handleChange80C = (idx, updatedValue) => {
        let newFormValues = [...formValues80C];
        newFormValues[idx] = { ...newFormValues[idx], ...updatedValue };
        setFormValue80C(newFormValues);

        const totalValue = newFormValues.reduce((sum, currentVal) =>{
            return sum + parseFloat(currentVal.amount || 0);
        },0);

        totalAmtRef.current = totalValue
    };

    const handleChange80D = (idx, updatedValue) => {
        let newFormValues = [...formValues80D];
        newFormValues[idx] = { ...newFormValues[idx], ...updatedValue };
        setFormValues80D(newFormValues);

        const totalValue = newFormValues.reduce((sum, currentVal) =>{
            return sum + parseFloat(currentVal.amount || 0);
        },0);

        totalAmtRef80D.current = totalValue;
    };

    const handleChangeOtherInves = (idx, updatedValue) => {
        let newFormValues = [...formValuesOtherInves];
        newFormValues[idx] = { ...newFormValues[idx], ...updatedValue };
        setFormValuesOtherInves(newFormValues);

        const totalValue = newFormValues.reduce((sum, currentVal) =>{
            return sum + parseFloat(currentVal.amount || 0);
        },0);

        totalAmtRefOtherInves.current = totalValue;
    };

    const handleChangeRepayLoan = (idx, updatedValue) =>{
        let newFormValues = [...formValuesRepayLoan];
        newFormValues[idx] = {...newFormValues[idx], ...updatedValue};
        setFormValuesRepayLoan(newFormValues);
    };

    const handleChangeRentalIncome = (idx, updatedValue) =>{
        let newFormValues = [...formValuesRentalIncome];
        newFormValues[idx] = {...newFormValues[idx], ...updatedValue};
        setFormValuesRentalIncome(newFormValues);
    };

    const addFormFields80C = () => {
        setFormValue80C([...formValues80C, { investment: null, amount: '' }]);
    };

    const addFormFields80D = () => {
        setFormValues80D([...formValues80D, { investment: null, amount: '' }]);
    };

    const addFormFieldsOtherInves = () => {
        setFormValuesOtherInves([...formValuesOtherInves, { investment: null, amount: '' }]);
    };

    const addFormFieldsRentedHouse = () =>{
        setFormValuesRentedHouse([...formValuesRentedHouse, {fromDate: null, toDate : null, rentAmt : "", cityType :null, address : null, landlordName :null, PAN: ""}])
    };

    const addFormValuesRepayLoan = () =>{
        setFormValuesRepayLoan([...formValuesRepayLoan, {principalAmt : null, interest: null, lenderName: null, lenderPAN: null}]);
    };

    const addFormValuesRentalIncome = () =>{
        setFormValuesRentalIncome([...formValuesRentalIncome, {rentReceived : null, taxPaid: null, netValue: null, StdDeduction: null, principal: null, interest:null, lenderName:null, lenderPAN:null, netIncome: null}]);
    };

    const removeFormFields80C = (idx) => {
        let newFormValues = [...formValues80C];
        newFormValues.splice(idx, 1);
        setFormValue80C(newFormValues);

        const totalValue = newFormValues.reduce((sum, currentVal) =>{
            return sum + parseFloat(currentVal.amount || 0);
        },0);

        totalAmtRef.current = totalValue
    };

    const removeFormFieldsRentalIncome = (idx) => {
        let newFormValues = [...formValuesRentalIncome];
        newFormValues.splice(idx, 1);
        setFormValuesRentalIncome(newFormValues);
    };

    const removeFormFields80D = (idx) => {
        let newFormValues = [...formValues80D];
        newFormValues.splice(idx, 1);
        setFormValues80D(newFormValues);

        const totalValue = newFormValues.reduce((sum, currentVal) =>{
            return sum + parseFloat(currentVal.amount || 0);
        },0);

        totalAmtRef80D.current = totalValue;
    };

    const removeFormFieldsOtherInves = (idx) => {
        let newFormValues = [...formValuesOtherInves];
        newFormValues.splice(idx, 1);
        setFormValuesOtherInves(newFormValues);

        const totalValue = newFormValues.reduce((sum, currentVal) =>{
            return sum + parseFloat(currentVal.amount || 0);
        },0);

        totalAmtRefOtherInves.current = totalValue;
    };

    const removeFormFieldsRentedHouse = (idx) =>{
        let newFormValues = [...formValuesRentedHouse];
        newFormValues.splice(idx, 1);
        setFormValuesRentedHouse(newFormValues);
    }

    const removeFormFieldsRepayLoan = (idx) =>{
        let newFormValues = [...formValuesRepayLoan];
        newFormValues.splice(idx,1);
        setFormValuesRepayLoan(newFormValues);
    }

  const handleOldRegimeChange = () => {
    if (!oldRegimeToggle) {
      setOldRegimeToggle(true);
      setNewRegimeToggle(false);
    }
  };

  const handleNewRegimeChange = () => {
    if (!newRegimeToggle) {
      setNewRegimeToggle(true);
      setOldRegimeToggle(false);
    }
  };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [eightyCRes, eightyDRes, otherInvestmentsRes] = await Promise.all([
                    get80Cschemes(),
                    get80Dschemes(),
                    getOtherInvestments()
                ]);
    
                setEightyCdata(eightyCRes.data);
                setEightyDdata(eightyDRes.data);
                setOtherInvestmentsData(otherInvestmentsRes.data);
            } catch (error) {
                customToast.error("Error Occurred !");
            }
        };

        fetchData();
    }, []);
    

    const eightyCoptions = useMemo(
        () => generateSelectData(eightyCdata, "schemeName"),
        [eightyCdata]
    );

    const eightyDoptions = useMemo(
        () => generateSelectData(eightyDdata, "schemeName"),
        [eightyDdata]
    );

    const otherInvestmentsOptions = useMemo(
        () => generateSelectData(otherInvestmentsData, "schemeName"),
        [otherInvestmentsData]
    );
    
    const handleSave = async(e) =>{
        e.preventDefault();

        let payload = {
            emplName : location?.state?.value,
            FinancialYear: FinancialYear?.label,
            regime: oldRegimeToggle === true ? "old regime"  : "new regime",
            HRD : HRDtoggle === true ? 
            {
                Rented_house_Details : isRentedtoggle ? formValuesRentedHouse : [],
                Repay_HomeLoan_Details : isRepayLoanToggle ? formValuesRepayLoan : [],
                Rental_Income_Details : isRentalIncomeToggle ? formValuesRentalIncome : [],

            }
            :
            null,
            eightyC : eightyCToggle ? [...formValues80C, {total_Amt: totalAmtRef.current}] : null,
            eightyD : eightyDToggle ? [...formValues80D, {total_Amt: totalAmtRef80D.current}] : null,
            other_investments : otherInvestToggle ? [...formValuesOtherInves, {total_Amt: totalAmtRefOtherInves.current}] : null,
            previous_employment : isPreviousEmplToggle ? previousEmplData : null,
            other_Income : isOtherIncomeToggle ? otherSrcIncomeData : null,
        }

        await addPOI(payload)
        .then(() => {
            return customToast.success("POI added Successfully !");
        })
        .catch(() =>{
            return customToast.error("Error occurred");
        });
    }

  return (
    <div className='admin-content  main-content bg-white dark:bg-primaryDark1'>
        <div className='area-creation-content '>
            <PageTitle title="Add Details" route={'../create-POI'}/>
        </div> 

            <section className='admin-content'>
                <div className='flex flex-col gap-6'>
                    <div className='row1 mx-0'>
                        <div>
                            <label>
                                Employee Name
                            </label>
                            <input
                                className='mt-4'
                                disabled={true}
                                value={`${location?.state?.firstName} ${location?.state?.lastName}`}
                            />
                        </div>

                        <div>
                            <label>
                                Select Financial Year
                            </label>
                            <Select
                                options={yearOptions}
                                onClick={({ value, label }) => setFinancialYear({ value, label })}
                                placeholder="Select Year"
                                styles={theme == "light" ? adminStylesLight : adminStyles}
                            />
                        </div>
                    </div>

                    <div className='flex flex-col'>
                        <label>
                            Select Regime Type
                        </label>
                        <div className='flex flex-row gap-x-20'>
                            <div className="form__control w-fit">
                                <label className="toggle-label">
                                    <input
                                        type="checkbox"
                                        checked={oldRegimeToggle}
                                        onChange={handleOldRegimeChange}
                                    />
                                    <span />
                                </label>
                                <label className='ml-4'>
                                    Old Regime
                                </label>
                            </div>

                            <div className="form__control w-fit">
                                <label className="toggle-label">
                                    <input
                                        type="checkbox"
                                        checked={newRegimeToggle}
                                        onChange={handleNewRegimeChange}
                                    />
                                    <span />
                                </label>
                                <label className='ml-4'>
                                    New Regime
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    {oldRegimeToggle &&
                        <div className="flex flex-col pr-8">
                            <Accordion
                                title=" House Rent Details"
                                defaultState={false}
                                titleStyle={"accordion-title"}
                                content={
                                    <div className='flex flex-col py-8'>
                                        <div className='w-full flex justify-between'>
                                            <label>
                                                Is the Employee staying in a rented house ?
                                            </label>
                                            <label className="toggle-label">
                                                <input
                                                    type="checkbox"
                                                    defaultChecked={isRentedtoggle === "true" ? true : false}
                                                    onChange={() => setIsRentedtoggle(!isRentedtoggle)}
                                                />
                                                <span />
                                            </label>
                                        </div>

                                        {isRentedtoggle === true ?
                                            <div>
                                                {formValuesRentedHouse.map((element, idx) => (
                                                    <div key={idx}>
                                                        <span className='mx-8 show-entries'>
                                                            Rent details
                                                        </span>

                                                        <div className='row'>
                                                            <div>
                                                                <label> Rented From </label>
                                                                <input
                                                                    type='date'
                                                                    value={element.fromDate || ''}
                                                                    onChange={(e) => handleChangeRentedHouse(idx, { fromDate: e.target.value })}
                                                                    style={theme === "light" ? adminStylesLight : adminStyles} />
                                                            </div>

                                                            <div>
                                                                <label> Rented To </label>
                                                                <input
                                                                    type='date'
                                                                    value={element.toDate || ''}
                                                                    onChange={(e) => handleChangeRentedHouse(idx, { toDate: e.target.value })}
                                                                    style={theme === "light" ? adminStylesLight : adminStyles} />
                                                            </div>

                                                            <div>
                                                                <label> Rent Amount per month </label>
                                                                <input
                                                                    type='number'
                                                                    value={element.rentAmt || ''}
                                                                    onChange={(e) => handleChangeRentedHouse(idx, { rentAmt: Number(e.target.value) })}
                                                                    style={theme === "light" ? adminStylesLight : adminStyles} />
                                                            </div>

                                                            <div>
                                                                <label>
                                                                    Rental City Type
                                                                </label>
                                                                <Select
                                                                    value={element.cityType || ''}
                                                                    onChange={(selectedOption) => handleChangeRentedHouse(idx, { cityType: selectedOption })}
                                                                    styles={theme === "light" ? adminStylesLight : adminStyles}
                                                                    options={cityTypeOption} />
                                                            </div>
                                                        </div>

                                                        <div className='row'>
                                                            <div>
                                                                <label> Rental Address </label>
                                                                <input
                                                                    type='text'
                                                                    value={element.address || ''}
                                                                    onChange={(e) => handleChangeRentedHouse(idx, { address: e.target.value })}
                                                                    style={theme === "light" ? adminStylesLight : adminStyles}
                                                                    placeholder='Enter Address' />
                                                            </div>

                                                            <div>
                                                                <label> Landlord Name </label>
                                                                <input
                                                                    type='text'
                                                                    value={element.landlordName || ''}
                                                                    onChange={(e) => handleChangeRentedHouse(idx, { landlordName: e.target.value })}
                                                                    style={theme === "light" ? adminStylesLight : adminStyles}
                                                                    placeholder='Enter name' />
                                                            </div>

                                                            <div>
                                                                <label> Landlord PAN </label>
                                                                <input
                                                                    type='text'
                                                                    value={element.PAN || ''}
                                                                    onChange={(e) => handleChangeRentedHouse(idx, { PAN: e.target.value })}
                                                                    style={theme === "light" ? adminStylesLight : adminStyles}
                                                                    placeholder='Enter PAN' />
                                                            </div>

                                                            {
                                                                idx ?
                                                                    <div>
                                                                        <MdDelete
                                                                            className='icon-color-error mt-16 cursor-pointer'
                                                                            size={25}
                                                                            onClick={() => removeFormFieldsRentedHouse(idx)}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </div>
                                                ))}

                                                <div className='mx-8 flex flex-row gap-x-4'
                                                    onClick={() => addFormFieldsRentedHouse()}>
                                                    <IoIosAddCircleOutline className='icon-color-tertiary cursor-pointer' size={25} />
                                                    <label>Add Investment</label>
                                                </div>
                                            </div>
                                            :
                                            null
                                        }

                                        <div className='w-full flex justify-between'>
                                            <label>
                                                Is the Employee repaying home loan for a self occupied house property
                                            </label>
                                            <label className="toggle-label">
                                                <input
                                                    type="checkbox"
                                                    defaultChecked={isRepayLoanToggle === "true" ? true : false}
                                                    onChange={() => setIsRepayLoanToggle(!isRepayLoanToggle)}
                                                />
                                                <span />
                                            </label>
                                        </div>

                                        {isRepayLoanToggle === true ?
                                            <div>
                                                {formValuesRepayLoan.map((element, idx) => (
                                                    <div key={idx}>
                                                        <span className='mx-8 show-entries'>
                                                            Home Loan
                                                        </span>

                                                        <div className='row'>
                                                            <div>
                                                                <label>
                                                                    Principal Amount Paid
                                                                </label>
                                                                <input
                                                                    type='number'
                                                                    placeholder='Enter Amount'
                                                                    value={element.principalAmt || ''}
                                                                    onChange={(e) => handleChangeRepayLoan(idx, { principalAmt: Number(e.target.value) })}
                                                                    style={theme === "light" ? adminStylesLight : adminStyles} />
                                                            </div>

                                                            <div>
                                                                <label>
                                                                    Interest Paid on Loan
                                                                </label>
                                                                <input
                                                                    type='number'
                                                                    value={element.interest || ''}
                                                                    onChange={(e) => handleChangeRepayLoan(idx, { interest: Number(e.target.value) })}
                                                                    placeholder='Enter Interest'
                                                                    style={theme === "light" ? adminStylesLight : adminStyles} />
                                                            </div>

                                                            <div>
                                                                <label>
                                                                    Name of Lender
                                                                </label>
                                                                <input
                                                                    type='text'
                                                                    value={element.lenderName || ''}
                                                                    onChange={(e) => handleChangeRepayLoan(idx, { lenderName: e.target.value })}
                                                                    placeholder='Enter Name'
                                                                    style={theme === "light" ? adminStylesLight : adminStyles} />
                                                            </div>

                                                            <div>
                                                                <label>
                                                                    Lender PAN
                                                                </label>
                                                                <input
                                                                    type='text'
                                                                    placeholder='Enter PAN'
                                                                    value={element.lenderPAN || ''}
                                                                    onChange={(e) => handleChangeRepayLoan(idx, { lenderPAN: e.target.value })}
                                                                    style={theme === "light" ? adminStylesLight : adminStyles} />
                                                            </div>

                                                            {
                                                                idx ?
                                                                    <div>
                                                                        <MdDelete
                                                                            className='icon-color-error mt-16 cursor-pointer'
                                                                            size={25}
                                                                            onClick={() => removeFormFieldsRepayLoan(idx)}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </div>
                                                ))}

                                                <div className='mx-8 flex flex-row gap-x-4'
                                                    onClick={() => addFormValuesRepayLoan()}>
                                                    <IoIosAddCircleOutline className='icon-color-tertiary' size={25} />
                                                    <label>Add Investment</label>
                                                </div>
                                            </div>
                                            :
                                            null
                                        }

                                        <div className='w-full flex justify-between'>
                                            <label>
                                                Is the Employee receiving rental income from let out property
                                            </label>
                                            <label className="toggle-label">
                                                <input
                                                    type="checkbox"
                                                    defaultChecked={isRentalIncomeToggle === "true" ? true : false}
                                                    onChange={() => setIsRentalIncomeToggle(!isRentalIncomeToggle)}
                                                />
                                                <span />
                                            </label>
                                        </div>

                                        {isRentalIncomeToggle === true ?
                                            <div className='area-creation-content'>
                                                {formValuesRentalIncome.map((element, idx) => (
                                                    <div key={idx}>
                                                        <span className='mx-8 show-entries'>
                                                            Rental Income
                                                        </span>

                                                        <div className='row'>
                                                            <div>
                                                                <label>
                                                                    Annual Rent Received
                                                                </label>
                                                                <input
                                                                    type='number'
                                                                    placeholder='Enter Amount'
                                                                    value={element.rentReceived || ''}
                                                                    onChange={(e) => handleChangeRentalIncome(idx, { rentReceived: Number(e.target.value) })}
                                                                    style={theme === "light" ? adminStylesLight : adminStyles} />
                                                            </div>

                                                            <div>
                                                                <label>
                                                                    Municipal Taxes Paid
                                                                </label>
                                                                <input
                                                                    type='number'
                                                                    value={element.taxPaid || ''}
                                                                    onChange={(e) => handleChangeRentalIncome(idx, { taxPaid: Number(e.target.value) })}
                                                                    placeholder='Enter Interest'
                                                                    style={theme === "light" ? adminStylesLight : adminStyles} />
                                                            </div>

                                                            <div>
                                                                <label>
                                                                    Net Annual Value
                                                                </label>
                                                                <input
                                                                    type='number'
                                                                    placeholder='Enter Value'
                                                                    value={element.netValue || ''}
                                                                    onChange={(e) => handleChangeRentalIncome(idx, { netValue: Number(e.target.value) })}
                                                                    style={theme === "light" ? adminStylesLight : adminStyles} />
                                                            </div>

                                                            <div>
                                                                <label>
                                                                    Standard Deduction
                                                                </label>
                                                                <input
                                                                    type='number'
                                                                    placeholder='Enter Standard..'
                                                                    value={element.StdDeduction || ''}
                                                                    onChange={(e) => handleChangeRentalIncome(idx, { StdDeduction: Number(e.target.value) })}
                                                                    style={theme === "light" ? adminStylesLight : adminStyles} />
                                                            </div>
                                                        </div>

                                                        <span className='mx-8 show-entries'>
                                                            Repaying Home Loan for this property
                                                        </span>

                                                        <div className='row'>
                                                            <div>
                                                                <label>
                                                                    Principal Paid on Home Loan for Let Out Property
                                                                </label>
                                                                <input
                                                                    type='number'
                                                                    placeholder='Enter Amount'
                                                                    value={element.principal || ''}
                                                                    onChange={(e) => handleChangeRentalIncome(idx, { principal: Number(e.target.value) })}
                                                                    style={theme === "light" ? adminStylesLight : adminStyles} />
                                                            </div>

                                                            <div>
                                                                <label>
                                                                    Interest Paid On Home Loan
                                                                </label>
                                                                <input
                                                                    type='number'
                                                                    placeholder='Enter Value'
                                                                    value={element.interest || ''}
                                                                    onChange={(e) => handleChangeRentalIncome(idx, { interest: Number(e.target.value) })}
                                                                    style={theme === "light" ? adminStylesLight : adminStyles} />
                                                            </div>
                                                        </div>

                                                        <div className='row'>
                                                            <div>
                                                                <label>
                                                                    Name of Lender
                                                                </label>
                                                                <input
                                                                    type='text'
                                                                    value={element.lenderName || ''}
                                                                    onChange={(e) => handleChangeRentalIncome(idx, { lenderName: e.target.value })}
                                                                    placeholder='Enter Name'
                                                                    style={theme === "light" ? adminStylesLight : adminStyles} />
                                                            </div>

                                                            <div>
                                                                <label>
                                                                    Lender PAN
                                                                </label>
                                                                <input
                                                                    type='text'
                                                                    value={element.lenderPAN || ''}
                                                                    onChange={(e) => handleChangeRentalIncome(idx, { lenderPAN: e.target.value })}
                                                                    placeholder='Enter PAN'
                                                                    style={theme === "light" ? adminStylesLight : adminStyles} />
                                                            </div>
                                                        </div>

                                                        <div className='row'>
                                                            <div>
                                                                <label>
                                                                    Net Income/Loss from House Property
                                                                </label>
                                                                <input
                                                                    type='number'
                                                                    placeholder='Enter Income'
                                                                    value={element.netIncome || ''}
                                                                    onChange={(e) => handleChangeRentalIncome(idx, { netIncome: Number(e.target.value) })}
                                                                    style={theme === "light" ? adminStylesLight : adminStyles} />
                                                            </div>

                                                            {idx ?
                                                                <div>
                                                                    <MdDelete
                                                                        className='icon-color-error mt-16'
                                                                        size={25}
                                                                        onClick={() => removeFormFieldsRentalIncome(idx)}
                                                                    />
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    </div>
                                                ))}

                                                <div className='flex mx-8 flex-row gap-x-4'
                                                    onClick={() => addFormValuesRentalIncome()}>
                                                    <IoIosAddCircleOutline className='icon-color-tertiary' size={25} />
                                                    <label>Add Investment</label>
                                                </div>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                }
                            />

                            <Accordion
                                title="80C INVESTMENTS"
                                defaultState={false}
                                titleStyle={"accordion-title"}
                                content={
                                    <div>
                                        {formValues80C.map((element, idx) => (
                                            <div className='row px-0' key={idx}>
                                                <div>
                                                    <label>Select Investment</label>
                                                    <Select
                                                        options={[
                                                            {
                                                                label: "80C",
                                                                options: eightyCoptions?.filter((item) => item.type === '80C'),
                                                            },
                                                            {
                                                                label: '80CCC',
                                                                options: eightyCoptions?.filter((item) => item.type === '80CCC'),
                                                            },
                                                            {
                                                                label: '80CCC1',
                                                                options: eightyCoptions?.filter((item) => item.type === '80CCC1'),
                                                            },
                                                        ]}
                                                        placeholder="Select 80C inv.."
                                                        styles={theme === "light" ? adminStylesLight : adminStyles}
                                                        onChange={(selectedOption) => handleChange80C(idx, { investment: selectedOption })}
                                                        value={element.investment || ''} // Bind selected value
                                                    />
                                                </div>

                                                <div>
                                                    <label>Amount</label>
                                                    <input
                                                        type='number'
                                                        value={element.amount || ''} // Bind input value
                                                        placeholder='Enter Amount'
                                                        onChange={(e) => handleChange80C(idx, { amount: Number(e.target.value) })}
                                                    />
                                                </div>

                                                {idx === 0 ?
                                                    <div>
                                                        <label>Max Amount</label>
                                                        <input
                                                            type='number'
                                                            disabled={true}
                                                            value={formValues80C[idx]?.investment?.maxAmt}
                                                            placeholder='Max Amount'
                                                        />
                                                    </div>
                                                    :
                                                    null
                                                }

                                                {idx === 0 ?
                                                    <div>
                                                        <label>Total Amount</label>
                                                        <input
                                                            type='number'
                                                            readOnly
                                                            value={totalAmtRef.current || 0}
                                                        />
                                                    </div>
                                                    :
                                                    null
                                                }

                                                {idx ?
                                                    <MdDelete
                                                        className='icon-color-error mt-16 cursor-pointer'
                                                        size={25}
                                                        onClick={() => removeFormFields80C(idx)}
                                                    />
                                                    :
                                                    null
                                                }
                                            </div>
                                        ))}

                                        <div className='flex flex-row gap-x-4'
                                            onClick={() => addFormFields80C()}>
                                            <IoIosAddCircleOutline className='icon-color-tertiary cursor-pointer' size={25} />
                                            <label>Add Investment</label>
                                        </div>
                                    </div>
                                }
                            />

                            <Accordion
                                title="80D INVESTMENTS"
                                defaultState={false}
                                titleStyle={"accordion-title"}
                                content={
                                    <div>
                                        {formValues80D.map((element, idx) => (
                                            <div className='row px-0' key={idx}>
                                                <div>
                                                    <label>Select Investment</label>
                                                    <Select
                                                        options={[
                                                            {
                                                                label: "80D",
                                                                options: eightyDoptions,
                                                            },
                                                        ]}
                                                        placeholder="Select 80D inv.."
                                                        styles={theme === "light" ? adminStylesLight : adminStyles}
                                                        onChange={(selectedOption) => handleChange80D(idx, { investment: selectedOption })}
                                                        value={element.investment || ''} // Bind selected value
                                                    />
                                                </div>

                                                <div>
                                                    <label>Amount</label>
                                                    <input
                                                        type='number'
                                                        // className='mt-3 rounded-[5px]'
                                                        value={element.amount || ''} // Bind input value
                                                        placeholder='Enter Amount'
                                                        onChange={(e) => handleChange80D(idx, { amount: Number(e.target.value) })}
                                                    />
                                                </div>

                                                {idx === 0 ?
                                                    <div>
                                                        <label>Max Amount</label>
                                                        <input
                                                            type='number'
                                                            // className='mt-3 rounded-[5px]'
                                                            disabled={true}
                                                            // value={element.maxAmt || ''} // Bind input value
                                                            placeholder='Enter Amount'
                                                        // onChange={(e) => handleChange80D(idx, { amount: e.target.value })}
                                                        />
                                                    </div>
                                                    :
                                                    null
                                                }

                                                {idx === 0 ?
                                                    <div>
                                                        <label>Total Amount</label>
                                                        <input
                                                            type='number'
                                                            // className='mt-3 rounded-[5px]'
                                                            value={totalAmtRef80D.current || 0}
                                                            placeholder='Enter Amount'
                                                        />
                                                    </div>
                                                    :
                                                    null
                                                }

                                                {idx ?
                                                    <div>
                                                        <MdDelete
                                                            className='icon-color-error mt-16 cursor-pointer'
                                                            size={25}
                                                            onClick={() => removeFormFields80D(idx)}
                                                        />
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </div>
                                        ))}

                                        <div className='flex flex-row gap-x-4'
                                            onClick={() => addFormFields80D()}>
                                            <IoIosAddCircleOutline className='icon-color-tertiary cursor-pointer' size={25} />
                                            <label>Add Investment</label>
                                        </div>
                                    </div>
                                }
                            />

                            <Accordion
                                title="Other Investments"
                                defaultState={false}
                                titleStyle={"accordion-title"}
                                content={
                                    <div>
                                        {formValuesOtherInves.map((element, idx) => (
                                            <div className='row px-0' key={idx}>
                                                <div>
                                                    <label>Select Investment</label>
                                                    <Select
                                                        options={[
                                                            {
                                                                label: "80CCCD(1B)",
                                                                options: otherInvestmentsOptions?.filter((item) => item.type === '80CCCD(1B)'),
                                                            },
                                                            {
                                                                label: '80CCG',
                                                                options: otherInvestmentsOptions?.filter((item) => item.type === '80CCG'),
                                                            },
                                                            {
                                                                label: '80DD',
                                                                options: otherInvestmentsOptions?.filter((item) => item.type === '80DD'),
                                                            },
                                                            {
                                                                label: '80DDB',
                                                                options: otherInvestmentsOptions?.filter((item) => item.type === '80DDB'),
                                                            },
                                                            {
                                                                label: '80E',
                                                                options: otherInvestmentsOptions?.filter((item) => item.type === '80E'),
                                                            },
                                                            {
                                                                label: '80EE',
                                                                options: otherInvestmentsOptions?.filter((item) => item.type === '80EE'),
                                                            },
                                                            {
                                                                label: '80EEA',
                                                                options: otherInvestmentsOptions?.filter((item) => item.type === '80EEA'),
                                                            },
                                                            {
                                                                label: '80EEB',
                                                                options: otherInvestmentsOptions?.filter((item) => item.type === '80EEB'),
                                                            },
                                                            {
                                                                label: '80G',
                                                                options: otherInvestmentsOptions?.filter((item) => item.type === '80G'),
                                                            },
                                                            {
                                                                label: '80GG',
                                                                options: otherInvestmentsOptions?.filter((item) => item.type === '80GG'),
                                                            },
                                                            {
                                                                label: '80TTA',
                                                                options: otherInvestmentsOptions?.filter((item) => item.type === '80TTA'),
                                                            },
                                                            {
                                                                label: '80U',
                                                                options: otherInvestmentsOptions?.filter((item) => item.type === '80U'),
                                                            },
                                                        ]}
                                                        placeholder="Select other in.."
                                                        styles={theme === "light" ? adminStylesLight : adminStyles}
                                                        onChange={(selectedOption) => handleChangeOtherInves(idx, { investment: selectedOption })}
                                                        value={element.investment || ''} // Bind selected value
                                                    />
                                                </div>

                                                <div>
                                                    <label>Amount</label>
                                                    <input
                                                        type='number'
                                                        value={element.amount || ''}
                                                        placeholder='Enter Amount'
                                                        onChange={(e) => handleChangeOtherInves(idx, { amount: Number(e.target.value) })}
                                                    />
                                                </div>

                                                {idx === 0 ?
                                                    <div>
                                                        <label>Max Amount</label>
                                                        <input
                                                            type='number'
                                                            disabled={true}
                                                            // value={element.maxAmt || ''} 
                                                            placeholder='Enter Amount'
                                                        // onChange={(e) => handleChangeOtherInves(idx, { amount: Number(e.target.value) })}
                                                        />
                                                    </div>
                                                    :
                                                    null
                                                }

                                                {idx === 0 ?
                                                    <div>
                                                        <label>Total Amount</label>
                                                        <input
                                                            type='number'
                                                            value={totalAmtRefOtherInves.current || 0}
                                                            readOnly
                                                        />
                                                    </div>
                                                    :
                                                    null
                                                }

                                                {idx ?
                                                    <div>
                                                        <MdDelete
                                                            className='icon-color-error mt-16 cursor-pointer'
                                                            size={25}
                                                            onClick={() => removeFormFieldsOtherInves(idx)}
                                                        />
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </div>
                                        ))}

                                        <div className='flex flex-row gap-x-4'
                                            onClick={() => addFormFieldsOtherInves()}>
                                            <IoIosAddCircleOutline className='icon-color-tertiary cursor-pointer' size={25} />
                                            <label>Add Investment</label>
                                        </div>
                                    </div>
                                }
                            />

                            <Accordion
                                title="Previous Employment"
                                defaultState={false}
                                titleStyle={"accordion-title"}
                                content={
                                    <div>
                                        <div className='row px-0'>
                                            <div>
                                                <label>
                                                    Income After Exemption
                                                </label>
                                                <input
                                                    type='number'
                                                    value={previousEmplData[0].exempt_income || ''}
                                                    onChange={(e) => {
                                                        const newValue = Number(e.target.value);
                                                        setPreviousEmplData([{ ...previousEmplData[0], exempt_income: newValue }]);
                                                    }}
                                                    // className='mb-3 mt-3 rounded-[5px]'
                                                    placeholder='Enter Income'
                                                />
                                            </div>

                                            <div>
                                                <label>
                                                    Income Tax
                                                </label>
                                                <input
                                                    type='number'
                                                    // className='mb-3 mt-3 rounded-[5px]'
                                                    placeholder='Enter Tax'
                                                    value={previousEmplData[0].incomeTax || ''}
                                                    onChange={(e) => {
                                                        const newValue = Number(e.target.value);
                                                        setPreviousEmplData([{ ...previousEmplData[0], incomeTax: newValue }]);
                                                    }}
                                                />
                                            </div>

                                            <div>
                                                <label>
                                                    Employee Provident Fund
                                                </label>
                                                <input
                                                    type='number'
                                                    // className='mb-3 mt-3 rounded-[5px]'
                                                    placeholder='Enter Amount'
                                                    value={previousEmplData[0].emplPF || ''}
                                                    onChange={(e) => {
                                                        const newValue = Number(e.target.value);
                                                        setPreviousEmplData([{ ...previousEmplData[0], emplPF: newValue }]);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                            />

                            <Accordion
                                title="Other Source of Income"
                                defaultState={false}
                                titleStyle={"accordion-title"}
                                content={
                                    <div>
                                        <div className='row px-0'>
                                            <div>
                                                <label>
                                                    Interest on FD
                                                </label>
                                                <input
                                                    type='number'
                                                    // className='mb-3 mt-3 rounded-[5px]'
                                                    value={otherSrcIncomeData[0].FD_interest || ''}
                                                    onChange={(e) => {
                                                        const newValue = Number(e.target.value);
                                                        setOtherSrcIncomeData([{ ...otherSrcIncomeData[0], FD_interest: newValue }]);
                                                    }}
                                                    placeholder='Enter Amount'
                                                />
                                            </div>

                                            <div>
                                                <label className='text-sm'>
                                                    Other Sources Income
                                                </label>
                                                <input
                                                    type='number'
                                                    placeholder='Enter Amount'
                                                    value={otherSrcIncomeData[0].otherSourceIncome || ''}
                                                    onChange={(e) => {
                                                        const newValue = Number(e.target.value);
                                                        setOtherSrcIncomeData([{ ...otherSrcIncomeData[0], otherSourceIncome: newValue }]);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                            />
                        </div>
                    }
                </div>

                <div className='py-8'>
                    <button className='button-green-gradient'
                        onClick={(e) => handleSave(e)}>
                        Save
                    </button>
                </div>
            </section>
        </div>
    )
}

export default AddDetailsPOI



// <div>

//     <div className='area-creation-content my-1'>
//         <div className='subheading flex cursor-pointer'
//             onClick={() => setHRDtoggle(!HRDtoggle)}>
//             House Rent Details
//             {HRDtoggle === true ? <FaChevronUp /> : <FaChevronDown />}
//         </div>
//     </div>

//     {HRDtoggle === true ?
//         <div>
//             <form>
//                 <div className='flex flex-col'>
//                     <div className='flex flex-row gap-x-[40rem]'>
//                         <div className='w-[60rem]'>
//                             Is the Employee staying in a rented house
//                         </div>
//                         <label className="toggle-label">
//                             <input
//                                 type="checkbox"
//                                 defaultChecked={isRentedtoggle === "true" ? true : false}
//                                 onChange={() => setIsRentedtoggle(!isRentedtoggle)}
//                             />
//                             <span />
//                         </label>
//                     </div>
//                     {isRentedtoggle === true ?
//                         <div>
//                             <div className='area-creation-content'>
//                                 {formValuesRentedHouse.map((element, idx) => (
//                                     <div key={idx}>
//                                         <form>
//                                             <label className='p-8 mt-4 underline underline-offset-2'>
//                                                 Rent details
//                                             </label>
//                                             <div className='flex flex-row gap-x-40 p-8'>
//                                                 <div className='w-80'>
//                                                     <label>
//                                                         Rented From
//                                                     </label>
//                                                     <input
//                                                         type='date'
//                                                         className='w-fit'
//                                                         value={element.fromDate || ''}
//                                                         onChange={(e) => handleChangeRentedHouse(idx, { fromDate: e.target.value })}
//                                                         style={theme === "light" ? adminStylesLight : adminStyles} />
//                                                 </div>

//                                                 <div className='w-80'>
//                                                     <label>
//                                                         Rented To
//                                                     </label>
//                                                     <input
//                                                         type='date'
//                                                         className='w-20'
//                                                         value={element.toDate || ''}
//                                                         onChange={(e) => handleChangeRentedHouse(idx, { toDate: e.target.value })}
//                                                         style={theme === "light" ? adminStylesLight : adminStyles} />
//                                                 </div>

//                                                 <div className='w-80'>
//                                                     <label>
//                                                         Rent Amount per month
//                                                     </label>
//                                                     <input
//                                                         type='number'
//                                                         className='mt-3 rounded-[5px]'
//                                                         value={element.rentAmt || ''}
//                                                         onChange={(e) => handleChangeRentedHouse(idx, { rentAmt: Number(e.target.value) })}
//                                                         style={theme === "light" ? adminStylesLight : adminStyles} />
//                                                 </div>

//                                                 <div className='w-80'>
//                                                     <label>
//                                                         Rental City Type
//                                                     </label>
//                                                     <Select
//                                                         value={element.cityType || ''}
//                                                         onChange={(selectedOption) => handleChangeRentedHouse(idx, { cityType: selectedOption })}
//                                                         styles={theme === "light" ? adminStylesLight : adminStyles}
//                                                         options={cityTypeOption} />
//                                                 </div>

//                                             </div>

//                                             <div className='flex flex-row gap-x-40 p-8'>
//                                                 <div className='w-80'>
//                                                     <label>
//                                                         Rental Address
//                                                     </label>
//                                                     <input
//                                                         type='text'
//                                                         className='w-fit mt-3 rounded-[5px]'
//                                                         value={element.address || ''}
//                                                         onChange={(e) => handleChangeRentedHouse(idx, { address: e.target.value })}
//                                                         style={theme === "light" ? adminStylesLight : adminStyles}
//                                                         placeholder='Enter Address' />
//                                                 </div>
//                                                 <div className='w-80'>
//                                                     <label>
//                                                         Landlord Name
//                                                     </label>
//                                                     <input
//                                                         type='text'
//                                                         className='w-20 mt-3 rounded-[5px]'
//                                                         value={element.landlordName || ''}
//                                                         onChange={(e) => handleChangeRentedHouse(idx, { landlordName: e.target.value })}
//                                                         style={theme === "light" ? adminStylesLight : adminStyles}
//                                                         placeholder='Enter name' />
//                                                 </div>

//                                                 <div className='w-80'>
//                                                     <label>
//                                                         Landlord PAN
//                                                     </label>
//                                                     <input
//                                                         type='text'
//                                                         className='w-20 mt-3 rounded-[5px]'
//                                                         value={element.PAN || ''}
//                                                         onChange={(e) => handleChangeRentedHouse(idx, { PAN: e.target.value })}
//                                                         style={theme === "light" ? adminStylesLight : adminStyles}
//                                                         placeholder='Enter PAN' />
//                                                 </div>
//                                                 {
//                                                     idx ?
//                                                         <div>
//                                                             <MdDelete
//                                                                 className='icon-color-error mt-16 cursor-pointer'
//                                                                 size={25}
//                                                                 onClick={() => removeFormFieldsRentedHouse(idx)}
//                                                             />
//                                                         </div>
//                                                         :
//                                                         null
//                                                 }
//                                             </div>
//                                         </form>
//                                     </div>
//                                 ))}
//                             </div>
//                             <div className='flex flex-row gap-x-4'
//                                 onClick={() => addFormFieldsRentedHouse()}>
//                                 <IoIosAddCircleOutline className='icon-color-tertiary cursor-pointer' size={25} />
//                                 <label>Add Investment</label>
//                             </div>
//                         </div>
//                         :
//                         null
//                     }


//                     <div className='flex flex-row gap-x-[40rem]'>
//                         <div className='w-[60rem]'>
//                             Is the Employee repaying home loan for a self occupied house property
//                         </div>
//                         <label className="toggle-label">
//                             <input
//                                 type="checkbox"
//                                 defaultChecked={isRepayLoanToggle === "true" ? true : false}
//                                 onChange={() => setIsRepayLoanToggle(!isRepayLoanToggle)}
//                             />
//                             <span />
//                         </label>
//                     </div>

//                     {isRepayLoanToggle === true ?
//                         <div>
//                             <div className='area-creation-content'>
//                                 {formValuesRepayLoan.map((element, idx) => (
//                                     <div key={idx}>
//                                         <form>
//                                             <label className='m-8'>
//                                                 Home Loan
//                                             </label>
//                                             <div className='flex flex-row gap-x-40 p-8'>
//                                                 <div className='w-80'>
//                                                     <label>
//                                                         Principal Amount Paid
//                                                     </label>
//                                                     <input
//                                                         type='number'
//                                                         className='w-fit mt-3 rounded-[5px]'
//                                                         placeholder='Enter Amount'
//                                                         value={element.principalAmt || ''}
//                                                         onChange={(e) => handleChangeRepayLoan(idx, { principalAmt: Number(e.target.value) })}
//                                                         style={theme === "light" ? adminStylesLight : adminStyles} />
//                                                 </div>

//                                                 <div className='w-80'>
//                                                     <label>
//                                                         Interest Paid on Loan
//                                                     </label>
//                                                     <input
//                                                         type='number'
//                                                         className='w-20 mt-3 rounded-[5px]'
//                                                         value={element.interest || ''}
//                                                         onChange={(e) => handleChangeRepayLoan(idx, { interest: Number(e.target.value) })}
//                                                         placeholder='Enter Interest'
//                                                         style={theme === "light" ? adminStylesLight : adminStyles} />
//                                                 </div>

//                                                 <div className='w-80'>
//                                                     <label>
//                                                         Name of Lender
//                                                     </label>
//                                                     <input
//                                                         type='text'
//                                                         className='w-20 mt-3 rounded-[5px]'
//                                                         value={element.lenderName || ''}
//                                                         onChange={(e) => handleChangeRepayLoan(idx, { lenderName: e.target.value })}
//                                                         placeholder='Enter Name'
//                                                         style={theme === "light" ? adminStylesLight : adminStyles} />
//                                                 </div>

//                                                 <div className='w-80'>
//                                                     <label>
//                                                         Lender PAN
//                                                     </label>
//                                                     <input
//                                                         type='text'
//                                                         placeholder='Enter PAN'
//                                                         className='w-20 mt-3 rounded-[5px]'
//                                                         value={element.lenderPAN || ''}
//                                                         onChange={(e) => handleChangeRepayLoan(idx, { lenderPAN: e.target.value })}
//                                                         style={theme === "light" ? adminStylesLight : adminStyles} />
//                                                 </div>
//                                                 {
//                                                     idx ?
//                                                         <div>
//                                                             <MdDelete
//                                                                 className='icon-color-error mt-16 cursor-pointer'
//                                                                 size={25}
//                                                                 onClick={() => removeFormFieldsRepayLoan(idx)}
//                                                             />
//                                                         </div>
//                                                         :
//                                                         null
//                                                 }
//                                             </div>
//                                         </form>
//                                     </div>
//                                 ))}
//                             </div>
//                             <div className='flex flex-row gap-x-4'
//                                 onClick={() => addFormValuesRepayLoan()}>
//                                 <IoIosAddCircleOutline className='icon-color-tertiary' size={25} />
//                                 <label>Add Investment</label>
//                             </div>
//                         </div>
//                         :
//                         null
//                     }


//                     <div className='flex flex-row gap-x-[40rem]'>
//                         <div className='w-[60rem]'>
//                             Is the Employee receiving rental income from let out property
//                         </div>
//                         <label className="toggle-label">
//                             <input
//                                 type="checkbox"
//                                 defaultChecked={isRentalIncomeToggle === "true" ? true : false}
//                                 onChange={() => setIsRentalIncomeToggle(!isRentalIncomeToggle)}
//                             />
//                             <span />
//                         </label>
//                     </div>

//                     {isRentalIncomeToggle === true ?
//                         <div>
//                             <div className='area-creation-content'>
//                                 {formValuesRentalIncome.map((element, idx) => (
//                                     <div key={idx}>
//                                         <form>
//                                             <label className='p-8 mt-4'>
//                                                 Rental Income
//                                             </label>
//                                             <div className='flex flex-row gap-x-40 p-8'>
//                                                 <div className='w-80'>
//                                                     <label>
//                                                         Annual Rent Received
//                                                     </label>
//                                                     <input
//                                                         type='number'
//                                                         className='w-fit mt-3 rounded-[5px]'
//                                                         placeholder='Enter Amount'
//                                                         value={element.rentReceived || ''}
//                                                         onChange={(e) => handleChangeRentalIncome(idx, { rentReceived: Number(e.target.value) })}
//                                                         style={theme === "light" ? adminStylesLight : adminStyles} />
//                                                 </div>

//                                                 <div className='w-80'>
//                                                     <label>
//                                                         Municipal Taxes Paid
//                                                     </label>
//                                                     <input
//                                                         type='number'
//                                                         className='w-20 mt-3 rounded-[5px]'
//                                                         value={element.taxPaid || ''}
//                                                         onChange={(e) => handleChangeRentalIncome(idx, { taxPaid: Number(e.target.value) })}
//                                                         placeholder='Enter Interest'
//                                                         style={theme === "light" ? adminStylesLight : adminStyles} />
//                                                 </div>

//                                                 <div className='w-80'>
//                                                     <label>
//                                                         Net Annual Value
//                                                     </label>
//                                                     <input
//                                                         type='number'
//                                                         className='w-20 mt-3 rounded-[5px]'
//                                                         placeholder='Enter Value'
//                                                         value={element.netValue || ''}
//                                                         onChange={(e) => handleChangeRentalIncome(idx, { netValue: Number(e.target.value) })}
//                                                         style={theme === "light" ? adminStylesLight : adminStyles} />
//                                                 </div>

//                                                 <div className='w-80'>
//                                                     <label>
//                                                         Standard Deduction
//                                                     </label>
//                                                     <input
//                                                         type='number'
//                                                         placeholder='Enter Standard..'
//                                                         className='w-20 mt-3 rounded-[5px]'
//                                                         value={element.StdDeduction || ''}
//                                                         onChange={(e) => handleChangeRentalIncome(idx, { StdDeduction: Number(e.target.value) })}
//                                                         style={theme === "light" ? adminStylesLight : adminStyles} />
//                                                 </div>
//                                             </div>
//                                             <div className='p-4'>
//                                                 Repaying Home Loan for this property
//                                             </div>

//                                             <div className='flex flex-row gap-x-64'>
//                                                 <div className='w-fit m-8'>
//                                                     <label className='w-full'>
//                                                         Principal Paid on Home Loan for Let Out Property
//                                                     </label>
//                                                     <div className='w-80'>
//                                                         <input
//                                                             type='number'
//                                                             placeholder='Enter Amount'
//                                                             className='w-fit mt-3 rounded-[5px]'
//                                                             value={element.principal || ''}
//                                                             onChange={(e) => handleChangeRentalIncome(idx, { principal: Number(e.target.value) })}
//                                                             style={theme === "light" ? adminStylesLight : adminStyles} />
//                                                     </div>
//                                                 </div>

//                                                 <div className='w-fit m-8'>
//                                                     <label className='w-full'>
//                                                         Interest Paid On Home Loan
//                                                     </label>
//                                                     <div className='w-80'>
//                                                         <input
//                                                             type='number'
//                                                             placeholder='Enter Value'
//                                                             value={element.interest || ''}
//                                                             onChange={(e) => handleChangeRentalIncome(idx, { interest: Number(e.target.value) })}
//                                                             className='w-fit mt-3 rounded-[5px]'
//                                                             style={theme === "light" ? adminStylesLight : adminStyles} />
//                                                     </div>
//                                                 </div>
//                                             </div>

//                                             <div className='flex flex-row gap-x-40 m-8'>
//                                                 <div className='w-80'>
//                                                     <label>
//                                                         Name of Lender
//                                                     </label>
//                                                     <input
//                                                         type='text'
//                                                         className='w-fit mt-3 rounded-[5px]'
//                                                         value={element.lenderName || ''}
//                                                         onChange={(e) => handleChangeRentalIncome(idx, { lenderName: e.target.value })}
//                                                         placeholder='Enter Name'
//                                                         style={theme === "light" ? adminStylesLight : adminStyles} />
//                                                 </div>
//                                                 <div className='w-80'>
//                                                     <label>
//                                                         Lender PAN
//                                                     </label>
//                                                     <input
//                                                         type='text'
//                                                         className='w-fit mt-3 rounded-[5px]'
//                                                         value={element.lenderPAN || ''}
//                                                         onChange={(e) => handleChangeRentalIncome(idx, { lenderPAN: e.target.value })}
//                                                         placeholder='Enter PAN'
//                                                         style={theme === "light" ? adminStylesLight : adminStyles} />
//                                                 </div>
//                                             </div>

//                                             <div className='flex flex-row gap-x-8 '>
//                                                 <div className='w-fit m-8'>
//                                                     <label className='w-full'>
//                                                         Net Income/Loss from House Property
//                                                     </label>
//                                                     <div className='w-80 '>
//                                                         <input
//                                                             type='number'
//                                                             placeholder='Enter Income'
//                                                             value={element.netIncome || ''}
//                                                             onChange={(e) => handleChangeRentalIncome(idx, { netIncome: Number(e.target.value) })}
//                                                             className='w-fit mt-3 rounded-[5px]'
//                                                             style={theme === "light" ? adminStylesLight : adminStyles} />
//                                                     </div>
//                                                 </div>
//                                                 {idx ?
//                                                     <div>
//                                                         <MdDelete
//                                                             className='icon-color-error mt-16'
//                                                             size={25}
//                                                             onClick={() => removeFormFieldsRentalIncome(idx)}
//                                                         />
//                                                     </div>
//                                                     :
//                                                     null
//                                                 }
//                                             </div>
//                                         </form>
//                                     </div>
//                                 ))}
//                             </div>
//                             <div className='flex flex-row gap-x-4'
//                                 onClick={() => addFormValuesRentalIncome()}>
//                                 <IoIosAddCircleOutline className='icon-color-tertiary' size={25} />
//                                 <label>Add Investment</label>
//                             </div>
//                         </div>
//                         :
//                         null
//                     }


//                 </div>
//             </form>

//         </div>
//         :
//         null
//     }

//    <div className='area-creation-content my-1'>
//         <div className='subheading flex cursor-pointer'
//             onClick={() => setEightyCToggle(!eightyCToggle)}>
//             80C investments
//             {eightyCToggle === true ? <FaChevronUp /> : <FaChevronDown />}
//         </div>
//     </div>

//     {eightyCToggle === true ?

//         <div>
//             <div className='area-creation-content my-1'>
//                 <form>
//                     {formValues80C.map((element, idx) => (
//                         <div className='row py-8' key={idx}>
//                             <div>
//                                 <label>Select Investment</label>
//                                 <Select
//                                     className='mt-2'
//                                     options={[
//                                         {
//                                             label: "80C",
//                                             options: eightyCoptions?.filter((item) => item.type === '80C'),
//                                         },
//                                         {
//                                             label: '80CCC',
//                                             options: eightyCoptions?.filter((item) => item.type === '80CCC'),
//                                         },
//                                         {
//                                             label: '80CCC1',
//                                             options: eightyCoptions?.filter((item) => item.type === '80CCC1'),
//                                         },
//                                     ]}
//                                     placeholder="Select 80C inv.."
//                                     styles={theme === "light" ? adminStylesLight : adminStyles}
//                                     onChange={(selectedOption) => handleChange80C(idx, { investment: selectedOption })}
//                                     value={element.investment || ''} // Bind selected value
//                                 />
//                             </div>
//                             <div>
//                                 <label>Amount</label>
//                                 <input
//                                     type='number'
//                                     // className='mt-3 rounded-[5px]'
//                                     value={element.amount || ''} // Bind input value
//                                     placeholder='Enter Amount'
//                                     onChange={(e) => handleChange80C(idx, { amount: Number(e.target.value) })}
//                                 />
//                             </div>
//                             {idx === 0 ?
//                                 <div>
//                                     <label>Max Amount</label>
//                                     <input
//                                         type='number'
//                                         // className='mt-3 rounded-[5px]'
//                                         disabled={true}
//                                         value={formValues80C[idx]?.investment?.maxAmt}
//                                         placeholder='Max Amount'
//                                     />
//                                 </div>
//                                 :
//                                 null
//                             }
//                             {idx === 0 ?
//                                 <div>
//                                     <label>Total Amount</label>
//                                     <input
//                                         type='number'
//                                         // className='mt-3 rounded-[5px]'
//                                         readOnly
//                                         value={totalAmtRef.current || 0}
//                                     />
//                                 </div>
//                                 :
//                                 null
//                             }
//                             {idx ?
//                                 <MdDelete
//                                     className='icon-color-error mt-16 cursor-pointer'
//                                     size={25}
//                                     onClick={() => removeFormFields80C(idx)}
//                                 />
//                                 :
//                                 null
//                             }
//                         </div>
//                     ))}
//                 </form>
//             </div>

//             <div className='flex flex-row gap-x-4'
//                 onClick={() => addFormFields80C()}>
//                 <IoIosAddCircleOutline className='icon-color-tertiary cursor-pointer' size={25} />
//                 <label>Add Investment</label>
//             </div>
//         </div>
//         :
//         null
//     }

//     <div className='area-creation-content my-1'>
//         <div className='subheading flex cursor-pointer'
//             onClick={() => setEightyDToggle(!eightyDToggle)}>
//             80D investments
//             {eightyDToggle === true ? <FaChevronUp /> : <FaChevronDown />}
//         </div>
//     </div>

//     {eightyDToggle === true ?
//         <div>
//             <div className='area-creation-content my-1'>
//                 <form>
//                     {formValues80D.map((element, idx) => (
//                         <div className='flex flex-row gap-x-36 mx-8 py-8' key={idx}>
//                             <div className='w-80'>
//                                 <label>Select Investment</label>
//                                 <Select
//                                     options={[
//                                         {
//                                             label: "80D",
//                                             options: eightyDoptions,
//                                         },
//                                     ]}
//                                     placeholder="Select 80D inv.."
//                                     styles={theme === "light" ? adminStylesLight : adminStyles}
//                                     onChange={(selectedOption) => handleChange80D(idx, { investment: selectedOption })}
//                                     value={element.investment || ''} // Bind selected value
//                                 />
//                             </div>
//                             <div className='w-80'>
//                                 <label>Amount</label>
//                                 <input
//                                     type='number'
//                                     className='mt-3 rounded-[5px]'
//                                     value={element.amount || ''} // Bind input value
//                                     placeholder='Enter Amount'
//                                     onChange={(e) => handleChange80D(idx, { amount: Number(e.target.value) })}
//                                 />
//                             </div>
//                             {idx === 0 ?
//                                 <div className='w-80'>
//                                     <label>Max Amount</label>
//                                     <input
//                                         type='number'
//                                         className='mt-3 rounded-[5px]'
//                                         disabled={true}
//                                         // value={element.maxAmt || ''} // Bind input value
//                                         placeholder='Enter Amount'
//                                     // onChange={(e) => handleChange80D(idx, { amount: e.target.value })}
//                                     />
//                                 </div>
//                                 :
//                                 null
//                             }
//                             {idx === 0 ?
//                                 <div className='w-80'>
//                                     <label>Total Amount</label>
//                                     <input
//                                         type='number'
//                                         className='mt-3 rounded-[5px]'
//                                         value={totalAmtRef80D.current || 0}
//                                         placeholder='Enter Amount'
//                                     />
//                                 </div>
//                                 :
//                                 null
//                             }
//                             {idx ?
//                                 <div>
//                                     <MdDelete
//                                         className='icon-color-error mt-16 cursor-pointer'
//                                         size={25}
//                                         onClick={() => removeFormFields80D(idx)}
//                                     />
//                                 </div>
//                                 :
//                                 null
//                             }
//                         </div>
//                     ))}
//                 </form>
//             </div>

//             <div className='flex flex-row gap-x-4'
//                 onClick={() => addFormFields80D()}>
//                 <IoIosAddCircleOutline className='icon-color-tertiary cursor-pointer' size={25} />
//                 <label>Add Investment</label>
//             </div>
//         </div>
//         :
//         null
//     }

//      <div className='area-creation-content my-1'>
//         <div className='subheading flex cursor-pointer'
//             onClick={() => setOtherInvestToggle(!otherInvestToggle)}>
//             Other Investments
//             {otherInvestToggle === true ? <FaChevronUp /> : <FaChevronDown />}
//         </div>
//     </div>

//     {otherInvestToggle === true ?
//         <div>
//             <div className='area-creation-content my-1'>
//                 <form>
//                     {formValuesOtherInves.map((element, idx) => (
//                         <div className='flex flex-row gap-x-36 mx-8 py-8' key={idx}>
//                             <div className='w-80'>
//                                 <label>Select Investment</label>
//                                 <Select
//                                     options={[
//                                         {
//                                             label: "80CCCD(1B)",
//                                             options: otherInvestmentsOptions?.filter((item) => item.type === '80CCCD(1B)'),
//                                         },
//                                         {
//                                             label: '80CCG',
//                                             options: otherInvestmentsOptions?.filter((item) => item.type === '80CCG'),
//                                         },
//                                         {
//                                             label: '80DD',
//                                             options: otherInvestmentsOptions?.filter((item) => item.type === '80DD'),
//                                         },
//                                         {
//                                             label: '80DDB',
//                                             options: otherInvestmentsOptions?.filter((item) => item.type === '80DDB'),
//                                         },
//                                         {
//                                             label: '80E',
//                                             options: otherInvestmentsOptions?.filter((item) => item.type === '80E'),
//                                         },
//                                         {
//                                             label: '80EE',
//                                             options: otherInvestmentsOptions?.filter((item) => item.type === '80EE'),
//                                         },
//                                         {
//                                             label: '80EEA',
//                                             options: otherInvestmentsOptions?.filter((item) => item.type === '80EEA'),
//                                         },
//                                         {
//                                             label: '80EEB',
//                                             options: otherInvestmentsOptions?.filter((item) => item.type === '80EEB'),
//                                         },
//                                         {
//                                             label: '80G',
//                                             options: otherInvestmentsOptions?.filter((item) => item.type === '80G'),
//                                         },
//                                         {
//                                             label: '80GG',
//                                             options: otherInvestmentsOptions?.filter((item) => item.type === '80GG'),
//                                         },
//                                         {
//                                             label: '80TTA',
//                                             options: otherInvestmentsOptions?.filter((item) => item.type === '80TTA'),
//                                         },
//                                         {
//                                             label: '80U',
//                                             options: otherInvestmentsOptions?.filter((item) => item.type === '80U'),
//                                         },
//                                     ]}
//                                     placeholder="Select other in.."
//                                     styles={theme === "light" ? adminStylesLight : adminStyles}
//                                     onChange={(selectedOption) => handleChangeOtherInves(idx, { investment: selectedOption })}
//                                     value={element.investment || ''} // Bind selected value
//                                 />
//                             </div>
//                             <div className='w-80'>
//                                 <label>Amount</label>
//                                 <input
//                                     type='number'
//                                     className='mt-3 rounded-[5px]'
//                                     value={element.amount || ''}
//                                     placeholder='Enter Amount'
//                                     onChange={(e) => handleChangeOtherInves(idx, { amount: Number(e.target.value) })}
//                                 />
//                             </div>
//                             {idx === 0 ?
//                                 <div className='w-80'>
//                                     <label>Max Amount</label>
//                                     <input
//                                         type='number'
//                                         className='mt-3 rounded-[5px]'
//                                         disabled={true}
//                                         // value={element.maxAmt || ''}
//                                         placeholder='Enter Amount'
//                                     // onChange={(e) => handleChangeOtherInves(idx, { amount: Number(e.target.value) })}
//                                     />
//                                 </div>
//                                 :
//                                 null
//                             }
//                             {idx === 0 ?
//                                 <div className='w-80'>
//                                     <label>Total Amount</label>
//                                     <input
//                                         type='number'
//                                         className='mt-3 rounded-[5px]'
//                                         value={totalAmtRefOtherInves.current || 0}
//                                         readOnly
//                                     />
//                                 </div>
//                                 :
//                                 null
//                             }
//                             {idx ?
//                                 <div>
//                                     <MdDelete
//                                         className='icon-color-error mt-16 cursor-pointer'
//                                         size={25}
//                                         onClick={() => removeFormFieldsOtherInves(idx)}
//                                     />
//                                 </div>
//                                 :
//                                 null
//                             }
//                         </div>
//                     ))}
//                 </form>
//             </div>

//             <div className='flex flex-row gap-x-4'
//                 onClick={() => addFormFieldsOtherInves()}>
//                 <IoIosAddCircleOutline className='icon-color-tertiary cursor-pointer' size={25} />
//                 <label>Add Investment</label>
//             </div>
//         </div>
//         :
//         null
//     }

//     <div className='area-creation-content my-1'>
//         <div className='subheading flex cursor-pointer'
//             onClick={() => setIsPreviousEmplToggle(!isPreviousEmplToggle)}>
//             Previous Employment
//             {isPreviousEmplToggle === true ? <FaChevronUp /> : <FaChevronDown />}
//         </div>
//     </div>

//     {isPreviousEmplToggle === true ?
//         <div className='area-creation-content my-1'>
//             <form>
//                 <div className='flex flex-row gap-x-36 mx-8 py-8'>
//                     <div className='w-80'>
//                         <label>
//                             Income After Exemption
//                         </label>
//                         <input
//                             type='number'
//                             value={previousEmplData[0].exempt_income || ''}
//                             onChange={(e) => {
//                                 const newValue = Number(e.target.value);
//                                 setPreviousEmplData([{ ...previousEmplData[0], exempt_income: newValue }]);
//                             }}
//                             className='mb-3 mt-3 rounded-[5px]'
//                             placeholder='Enter Income'
//                         />
//                     </div>

//                     <div className='w-80'>
//                         <label>
//                             Income Tax
//                         </label>
//                         <input
//                             type='number'
//                             className='mb-3 mt-3 rounded-[5px]'
//                             placeholder='Enter Tax'
//                             value={previousEmplData[0].incomeTax || ''}
//                             onChange={(e) => {
//                                 const newValue = Number(e.target.value);
//                                 setPreviousEmplData([{ ...previousEmplData[0], incomeTax: newValue }]);
//                             }}
//                         />
//                     </div>

//                     <div className='w-80'>
//                         <label>
//                             Employee Provident Fund
//                         </label>
//                         <input
//                             type='number'
//                             className='mb-3 mt-3 rounded-[5px]'
//                             placeholder='Enter Amount'
//                             value={previousEmplData[0].emplPF || ''}
//                             onChange={(e) => {
//                                 const newValue = Number(e.target.value);
//                                 setPreviousEmplData([{ ...previousEmplData[0], emplPF: newValue }]);
//                             }}
//                         />
//                     </div>
//                 </div>
//             </form>
//         </div>
//         :
//         null
//     }

//   <div className='area-creation-content my-1'>
//         <div className='subheading flex cursor-pointer'
//             onClick={() => setIsOtherIncomeToggle(!isOtherIncomeToggle)}>
//             Other Source of Income
//             {isOtherIncomeToggle === true ? <FaChevronUp /> : <FaChevronDown />}
//         </div>
//     </div>

//     {isOtherIncomeToggle === true ?
//         <div className='area-creation-content my-1'>
//             <form>
//                 <div className='flex flex-row gap-x-36 mx-8 py-8'>
//                     <div className='w-80'>
//                         <label>
//                             Interest on FD
//                         </label>
//                         <input
//                             type='number'
//                             className='mb-3 mt-3 rounded-[5px]'
//                             value={otherSrcIncomeData[0].FD_interest || ''}
//                             onChange={(e) => {
//                                 const newValue = Number(e.target.value);
//                                 setOtherSrcIncomeData([{ ...otherSrcIncomeData[0], FD_interest: newValue }]);
//                             }}
//                             placeholder='Enter Amount'
//                         />
//                     </div>
//                     <div className='w-96'>
//                         <label className='w-fit'>
//                             Income From Other Sources
//                         </label>
//                         <input
//                             type='number'
//                             className='mb-3 mt-3 rounded-[5px]'
//                             placeholder='Enter Amount'
//                             value={otherSrcIncomeData[0].otherSourceIncome || ''}
//                             onChange={(e) => {
//                                 const newValue = Number(e.target.value);
//                                 setOtherSrcIncomeData([{ ...otherSrcIncomeData[0], otherSourceIncome: newValue }]);
//                             }}
//                         />
//                     </div>
//                 </div>
//             </form>
//         </div>
//         :
//         null
//     }

// </div>