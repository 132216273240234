import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import PageTitle from '../../../../components/PageTitle';
import Select from 'react-select';
import { adminStyles, adminStylesLight } from '../../../../utils/selectStyles';


const AddCity = () => {
    const theme = useSelector((state) => state.theme.theme);

    const [divisionName, setDivisionName] = useState(null);
    const [city, setCity] = useState('');
    const [cityCode, setCityCode] = useState('');
   
  return (
    <main className={"main-content " + `${theme == "light" ? "light_bg_main" : ""}`} style={{width:'100%'} }>
        <div className="creation-content" style={{color:"white"}}>
        <PageTitle title="Add Department" />
        </div>

        <section className={"creation-content_form" + `${theme == "light" ? "section-form_content" : ""}`}>
          <form >
          <div className="row">
            <div className="row">
            <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                <label >
                  DIVISION NAME<span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  styles={theme == "light" ? adminStylesLight : adminStyles}
                  placeholder="Enter Divsion" 
                  className='mt-4'
                  value={divisionName}
                  onChange={({ value, label }) => setDivisionName({ value, label })}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                <label htmlFor="mName">
                  CITY <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                  className={"me-5 mt-4 "  + (theme === 'light' ? 'input-padding' : '')}
                  id="mName"
                  placeholder="Enter City"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                <label htmlFor="mName">
                  CITY CODE <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                  className={"me-5 mt-4 "  + (theme === 'light' ? 'input-padding' : '')}
                  id="mName"
                  placeholder="Enter City Code"
                  value={cityCode}
                  onChange={(e) => setCityCode(e.target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                <label htmlFor="mName">
                  CITY CODE <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                  className={"me-5 mt-4 "  + (theme === 'light' ? 'input-padding' : '')}
                  id="mName"
                  placeholder="Enter City Code"
                  value={cityCode}
                  onChange={(e) => setCityCode(e.target.value)}
                />
              </div>
            </div>
           </div>

            <div className="col-sm-2 col-md-4 col-lg-2 mt-5">
              <button type="submit" className="button-blue-gradient">
                Save
              </button>
            </div> 
          </form>   
        </section>
    </main>
  )
}

export default AddCity;