import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PageTitle from '../../../components/PageTitle';
import { generateSelectData } from '../../../utils/serialiseResponse';
import { fetchEmployees, clearEmployees } from '../../../reducers/employeesReducer';
import { monthOptions, yearOptions } from '../../../utils/helper';
import { ImCheckboxUnchecked, ImCheckboxChecked } from "react-icons/im";

import Select from "react-select";
import {
  adminStyles,
  adminStylesLight,
  selectStyles,
} from "../../../utils/selectStyles";
import Table from '../../../components/Table';
import customToast from '../../../components/CustomToast';
import { getPayrunWithMonthAndYear, getProcessedPayruns } from '../../../services/payRun';
import { IoEye } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

const ProcessedPayrun = () => {
  const theme = useSelector((state) => state.theme.theme);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allSelected, setAllSelected] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [month, setmonth] = useState();
  const [year , setYear] = useState();


  useEffect(() => {
    dispatch(fetchEmployees());

    return () => dispatch(clearEmployees());
  }, [dispatch]);
  
  const handleSubmit = async() => {
     try {
       const payload = {
         month,
         year,
       }
      const response = await getProcessedPayruns(payload);
      setTableData(response?.data)
      console.log(response , "response")
      if(response?.data?.length > 0){
       return customToast.success("Successfully Retireved Data")
      }
      else{
       return customToast.error("No such Data exist")
      }
     } catch (error) {
      return customToast.error("Server Error" , error)
     }
  }

  const SearchColumnFilter = useCallback(
    ({ column, columns }) => {
      return (
        <div className="search-filter__container" onClick={(e) => { e.stopPropagation() }}>
          {<span className="d-flex gap-3 align-items-center">{column.render("Header")}
            {allSelected ? (
              <ImCheckboxChecked onClick={(e) => {
                e.preventDefault();
                column?.filteredRows?.forEach((el, idx) => {
                  el.original.isSelected = false;
                });
                setAllSelected(false);
              }} />
            ) : (
              <ImCheckboxUnchecked onClick={(e) => {
                e.preventDefault();
                column?.filteredRows?.forEach((el, idx) => {
                  el.original.isSelected = true;
                });
                setAllSelected(true);
              }} />
            )
            }
          </span>
          }
        </div>
      );
    }, [allSelected])



  let columns = [
    {
      Header: "Employee ID",
      disableFilters: false,
      disableSortBy: true,
      Cell:(props) => {
        return (
          <span
            className="cursor-pointer active:text-tertiary active:font-semibold">
              {props?.row?.original?.employeeDetails?._id?.uid}
          </span>
        );
      }
    },
    {
      Header: "Employee Name",
      // accessor: "EmployeeName",
      disableFilters: false,
      disableSortBy: true,
      Cell:(props) => {
        return (
          <span
            className="cursor-pointer active:text-tertiary active:font-semibold">
              {props?.row?.original?.employeeDetails?._id?.firstName + " " + props?.row?.original?.employeeDetails?._id?.lastName}
          </span>
        );
      }
    },
    {
      Header: "Pay Slip",
      // accessor: "EmployeeName",
      disableFilters: false,
      disableSortBy: true,
      Cell: (props) => {
        let url = props?.row?.original?.employeeDetails?.salarySlipUrl;
        
        return (
          <a target="_blank" href={url} className="flex items-center justify-center cursor-pointer">
            <IoEye className="text-tertiary" size={25} />
          </a>
        );
      },
    },
    // [SearchColumnFilter, table]
  ]
  return (
    <main
      className={
        "main-content " + `${theme == "light" ? " light_bg_main" : ""}`
      }
      style={{ width: "100%" }}
    >
      <div className="area-creation-content ">
        <PageTitle title="Processed Payrun" route="../payrun-info" />
      <section className='admin-content'>
      <div className='row'>
        <div >
          <label className="uppercase">Select Month</label>
          <Select
            styles={
              theme == "light" ? adminStylesLight : adminStyles
            }
            className="w-[25rem]"
            options={monthOptions}
            // value={selectOptions[selectedMapping[index]]}
            onChange={(e) => setmonth(e.value)}
          />
        </div >
        <div >
          <label className="uppercase">Select Year</label>
          <Select
            styles={
              theme == "light" ? adminStylesLight : adminStyles
            }
            className="w-[25rem]"
            options={yearOptions}
            // value={selectOptions[selectedMapping[index]]}
            onChange={(e) => setYear(Number(e.label))}
          />
        </div >
        <button className='button-blue-gradient h-[5rem] mt-9' onClick={(e) => handleSubmit(e)} >Submit</button>
      </div>
      </section>
      <section className="cardlink-row pb-6">
      <div className={"filter-table "}>
        <Table columns={columns} data={tableData} />
      </div>
      </section>
      </div>
    </main>
  )
}

export default ProcessedPayrun