import { useEffect, useState, useMemo, useRef } from "react";
import PageTitle from "../../../../components/PageTitle";
import { adminStyles, adminStylesLight } from "../../../../utils/selectStyles";
import Select from "react-select";
import './loans.css'
import Table from '../../../../components/Table';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { clearEmployees, fetchEmployees } from '../../../../reducers/employeesReducer';
import { generateSelectData } from '../../../../utils/serialiseResponse';
import { HiPencil } from "react-icons/hi";
import { fetchAllEmployeesLoans } from "../../../../reducers/employeesloansReducer";
import { SlReload } from "react-icons/sl";
import Dropdown from '../../../../components/DropDown/dropdown'
import { deleteEmployeeloan } from '../../../../services/loans'
import customToast from "../../../../components/CustomToast";

const Loans = () => {

  const navigate = useNavigate();
  const theme = useSelector((state) => state.theme.theme);
  const employees = useSelector(({ employees }) => employees);
  const empLoansData = useSelector((state) => state.employeesloans.data)
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const [selectEmpl, setSelectEmpl] = useState(null);
  const [filteredLoans, setfilteredLoans] = useState([])

  const filter = async (employeeId) => {
    try {
      if (employeeId) {
        const filteredLoans = empLoansData.filter(loan => {
          return loan.employee._id === employeeId._id;
        });
        setfilteredLoans(filteredLoans);
      }
      else {
        setfilteredLoans(empLoansData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch(fetchEmployees({}));
    dispatch(fetchAllEmployeesLoans());

    return () => {
      dispatch(clearEmployees());
    };
  }, [dispatch]);

  useEffect(() => {
    if (empLoansData) {
      setfilteredLoans(empLoansData);
    }
  }, [empLoansData]);

  const emplSelect = useMemo(
    () => generateSelectData(employees.data, "fullName"),
    [employees.data]
  );

  const columns = useMemo(
    () => [
      {
        Header: "LOAN NAME",
        accessor: (row) => row.loan?.loanName,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "EMPLOYEE NAME",
        accessor: (row) => row.employee?.firstName + " " + row.employee?.lastName,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "LOAN AMOUNT",
        accessor: "loanAmount",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "AMOUNT PAID",
        accessor: "amountPaid",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "BALANCE AMOUNT",
        accessor: "balanceAmount",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "STATUS",
        accessor: "status",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "ACTION",
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row }) => {

          return (
            <>
              <Dropdown
                icon={
                  <HiPencil className='text-[#3893d7] cursor-pointer text-4xl' />
                }
                options={[
                  {
                    text: 'View',
                    onClick: () => {
                      navigate(`/loan/manage-loan/loans/installments/${row.original._id}`)
                    }
                  },
                  {
                    text: 'Delete',
                    onClick: () => {
                      deleteEmployeeloan({ emploanId: row.original._id })
                        .then((res) => {
                          dispatch(fetchAllEmployeesLoans());
                          return customToast.success("Loan deleted successfully")
                        })
                        .catch((err) => {
                          return customToast.error("Something went wrong !")
                        });
                    }
                  },
                ]}
              />
            </>
          );
        },

      },
    ],
    [filteredLoans]
  );

  return (
    <main className={"main-content " + `${theme == "light" ? "light_bg_main" : ""} w-full`} >
      <div className={"area-creation-content " + `${theme == "light" ? "light_bg" : ""}`}>
        <PageTitle title="Loan" route={'../..'}/>
      

      <section className="admin-content">
        <div className="row ">
          <div className="col-sm-12 col-md-4 col-lg-4">
            <label htmlFor="loan_name">
              SELECT EMPLOYEE
            </label>
            <br />
            <div className="flex gap-4 items-center">
              <Select
                id="emp_name"
                options={emplSelect}
                value={selectEmpl}
                onChange={(el) => {
                  setSelectEmpl(el);
                  filter(el)
                }}
                styles={theme == "light" ? adminStylesLight : adminStyles}
                placeholder="Select Employee"
                className="w-max"
              />
              <SlReload
                className="cursor-pointer ml-6 text-3xl text-[#36abf9] mt-3"
                onClick={() => {
                  setSelectEmpl(null);
                  filter();
                }}
              />
            </div>
          </div>
        </div>

        {/* <div className="row">
          <div className="col-sm-12 col-md-4 col-lg-4 area-creation-content__form-submit">
            <button className="button-blue-gradient" onClick={() => filter(selectEmpl)}>
              Filter
            </button>
          </div>
        </div> */}

        
          <section className={"cardlink-row pb-4"}>
            <label className="show-entries">
              Show Entries ({filteredLoans.length})
            </label>

            <div className="filter-table">
              <Table columns={columns} data={[...filteredLoans].reverse()} />
            </div>
          </section>
        
      </section>
      </div>
    </main>
  );
};

export default Loans;