import React, { useEffect, useMemo, useRef } from 'react'
import { useState } from "react";
import PageTitle from "../../../../components/PageTitle";
import { adminStyles, adminStylesLight } from "../../../../utils/selectStyles";
import { useDispatch, useSelector } from "react-redux";
import Table from '../../../../components/Table';
import { useNavigate, useParams } from 'react-router-dom';
import { HiPencil } from 'react-icons/hi'
import { getLoanInstallments } from "../../../../services/loans";
import { fetchAllEmployeesLoans, pauseLoan } from '../../../../reducers/employeesloansReducer'
import customToast from '../../../../components/CustomToast/index.js'
import { pauseLoanOfEmployee, editInstallmentAmount } from '../../../../services/loans';
import { useForm } from 'react-hook-form';
import Modal from '../../../../components/Modal'
import Select from "react-select";
import { monthOptions, returnMonthFromIndex, generateMonthYearList } from '../../../../utils/helper.js';
import InfoBox from '../../../../components/InfoBox';

const Installments = () => {

  const dispatch = useDispatch();
  const { id } = useParams();
  const {
    register: registerForForm1,
    handleSubmit: handleSubmitForForm1,
    setValue: setValueForForm1,
    reset: resetForForm1
  } = useForm();

  const theme = useSelector((state) => state.theme.theme);
  const empLoansData = useSelector((state) => state.employeesloans.data);

  const [InstallmentData, setInstallmentData] = useState([]);
  const [filteredInstallmentData, setfilteredInstallmentData] = useState([]);
  const [emploanDetails, setemploanDetails] = useState()
  const [showInsAmtModal, setshowInsAmtModal] = useState(false);
  const [showPauseModal, setshowPauseModal] = useState(false)
  const [pauseCheck, setpauseCheck] = useState(false)
  const [pauseStartMonth, setpauseStartMonth] = useState()
  const [resumeMonth, setresumeMonth] = useState()
  const monthsList = generateMonthYearList();

  const getInstallments = async () => {
    try {
      const res = await getLoanInstallments({ loanId: id })
      if (res) {
        let result = res.data;
        setInstallmentData(res.data)
        if (result) {
          let filteredResult = [];
          filteredResult = result.filter((item) => item.isPaid === true || item.isPaused === true);
          const firstUnpaidInstallment = result.find(item => !item.isPaid && !item.isPaused);
          if(firstUnpaidInstallment) filteredResult.push(firstUnpaidInstallment)
          setfilteredInstallmentData(filteredResult)
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getInstallments();
    const res = empLoansData?.find((loan) => {
      return loan._id === id;
    });
    if (res) {
      setemploanDetails(res);
    }
  }, [dispatch, empLoansData, id])

  const columns = useMemo(
    () => [
      {
        Header: "PEROID",
        accessor: "month",
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row }) => {

          return (
            <>
              <span>
                {returnMonthFromIndex(row.original.month) + " " + row.original.year}
              </span>
            </>
          );
        },
      },
      {
        Header: "INSTALLMENT",
        accessor: "installmentAmount",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "STATUS",
        accessor: "status",
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row }) => {
          const status = row.original.status;
          return (
            <>
              {status === "Paid" ? (
                <span className="text-[#3893d7] flex items-center justify-center w-full h-full">
                  Paid
                </span>
              ) : status === "Pending" ? (
                <span className="text-[#ea9423] flex items-center justify-center w-full h-full">
                  Pending
                </span>
              ) : status === "Paused" ? (
                <span className="text-[#F7CD77] flex items-center justify-center w-full h-full">
                  Paused
                </span>
              ) : null}
            </>
          );
        },
      },
      {
        Header: "ACTION",
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row }) => {

          const handleClick = () => {
            setValueForForm1('installmentId', row.original._id);
            setshowInsAmtModal(true);
          };

          return (
            <>
              <span
                className={`react-table-view-link ${row.original.isPaid || row.original.isPaused ? "pointer-events-none" : ""}`}
                onClick={() => {
                  handleClick();
                }}
              >
                <HiPencil className={`text-[#36ABF9] cursor-pointer text-3xl ${row.original.isPaid || row.original.isPaused ? "pointer-events-none text-gray-500" : ""}`} />
              </span>
            </>
          );
        },
      },
    ],
    []
  );

  const submitHandler = async (data) => {
    try {
      const res = await editInstallmentAmount(data)
      if (res) {
        dispatch(fetchAllEmployeesLoans())
        resetForForm1();
        setshowInsAmtModal(false)
        return customToast.success("Installment amount has been edited successfully")
      }
    } catch (error) {
      console.log(error);
    }
  };

  const pauseHandler = async (e) => {
    try {
      e.preventDefault();
      const res = await pauseLoanOfEmployee({ employeeloanId: emploanDetails._id, pauseStartMonth: pauseStartMonth.month, pauseStartYear: pauseStartMonth.year, resumeMonth: resumeMonth.month, resumeYear: resumeMonth.year })
      if (res) {
        dispatch(fetchAllEmployeesLoans())
        return customToast.success("Installment have been paused for selected months")
      }
    } catch (error) {
      console.log(error);
    }
  };

  const monthYear = [];
  const unpaid_installments_months = InstallmentData?.filter((item) => !item.isPaid);
  if (unpaid_installments_months && unpaid_installments_months.length > 0) {
    unpaid_installments_months.forEach((item) => {
      monthYear.push({ label: returnMonthFromIndex(item.month) + " " + item.year, value: { month: item.month, year: item.year } })
    });
  }

  return (
    <main className={"main-content " + `${theme == "light" ? "light_bg_main" : ""} w-full`} >
      <div className={"area-creation-content " + `${theme == "light" ? "light_bg" : ""}`}>
        <PageTitle title="Loan" route={'/loan/manage-loan/loans'} />
      </div>

      <section className={"user-detail-container user-detail-container-yellowDark-border " + `${theme == "light" ? "light_bg" : ""}`} >
        {/* <div className={"user-detail-box " + `${theme == "light" ? "light_bg_logo" : ""}`}>
          <h4 className='!text-primaryDark dark:!text-primaryLight2'>Employee Name</h4>
          <p className='!text-primaryDark dark:!text-primaryLight2'>
            {emploanDetails?.employee.firstName + " " + emploanDetails?.employee.lastName}
          </p>
        </div> */}

        {/* <div className={"user-detail-box " + `${theme == "light" ? "light_bg_logo" : ""}`}>
                  <h4 className='!text-primaryDark dark:!text-primaryLight2'>Loan Sanction Date</h4>
                  <p className='!text-primaryDark dark:!text-primaryLight2'>
                    {emploanDetails?.sanctionDate ? new Date(emploanDetails.sanctionDate).toLocaleDateString('en-IN', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric'
                    }) : ''}
                  </p>
                </div> */}

        <InfoBox title="Employee Name" des={emploanDetails?.employee.firstName + " " + emploanDetails?.employee.lastName} />
        <InfoBox title="Loan Sanction Date" des={emploanDetails?.sanctionDate ? new Date(emploanDetails.sanctionDate).toLocaleDateString('en-IN', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        }) : ''} />
        <InfoBox title="Loan Amount" des={emploanDetails?.loanAmount} />

        {/* <div className={"user-detail-box " + `${theme == "light" ? "light_bg_logo" : ""}`}>
          <h4 className='!text-primaryDark dark:!text-primaryLight2'>Loan Amount</h4>
          <p className='!text-primaryDark dark:!text-primaryLight2'>
            {emploanDetails?.loanAmount}
          </p>
        </div> */}

        <InfoBox title="Amount Paid" des={emploanDetails?.amountPaid} />

        {/* <div className={"user-detail-box " + `${theme == "light" ? "light_bg_logo" : ""}`}>
          <h4 className='!text-primaryDark dark:!text-primaryLight2'>Amount Paid</h4>
          <p className='!text-primaryDark dark:!text-primaryLight2'>
            {emploanDetails?.amountPaid}
          </p>
        </div> */}

        <InfoBox title="Balance Amount" des={emploanDetails?.balanceAmount} />


        {/* <div className={"user-detail-box " + `${theme == "light" ? "light_bg_logo" : ""}`}>
          <h4 className='!text-primaryDark dark:!text-primaryLight2'>Balance Amount</h4>
          <p className='!text-primaryDark dark:!text-primaryLight2'>
            {emploanDetails?.balanceAmount}
          </p>
        </div> */}

        <InfoBox title="Installment Amount" des={filteredInstallmentData?.[filteredInstallmentData.length - 1]?.installmentAmount} />

        {/* <div className={"user-detail-box " + `${theme == "light" ? "light_bg_logo" : ""}`}>
          <h4 className='!text-primaryDark dark:!text-primaryLight2'>Installment Amount</h4>
          <p className='!text-primaryDark dark:!text-primaryLight2'>
            {filteredInstallmentData?.[filteredInstallmentData.length - 1]?.installmentAmount}
          </p>
        </div> */}

        <InfoBox title="Taxable Amount" des={emploanDetails?.taxableAmount} />

        {/* <div className={"user-detail-box " + `${theme == "light" ? "light_bg_logo" : ""}`}>
          <h4 className='!text-primaryDark dark:!text-primaryLight2'>Taxable Amount</h4>
          <p className='!text-primaryDark dark:!text-primaryLight2'>
            {emploanDetails?.taxableAmount}
          </p>
        </div> */}

        <InfoBox title="Next Installment Date" des="--" />

        {/* <div className={"user-detail-box " + `${theme == "light" ? "light_bg_logo" : ""}`}>
          <h4 className='!text-primaryDark dark:!text-primaryLight2'>Next Installment Date</h4>
          <p className='!text-primaryDark dark:!text-primaryLight2'>
            --
          </p>
        </div> */}
      </section>

      <section>
        <div>
          <section className={"area-creation-content__info py-0 p-0"}>
            <div className="filter-table mt-20 mr-8">
              <Table columns={columns} data={filteredInstallmentData} />
            </div>
          </section>
        </div>

        <div className='flex gap-6 mb-6'>
          {/* {emploanDetails?.loan.loanType === "Interest-free Loan" && */}
          <button className="button-green-gradient"
            onClick={() => {
              setshowPauseModal(true)
            }}
          >
            Pause Loan
          </button>
          {/* } */}

          <button className="button-blue-gradient" >
            Close Loan
          </button>
        </div>
      </section>

      <Modal
        showModal={showInsAmtModal}
        closeModal={() => setshowInsAmtModal(false)}
        title="Edit Installment Amount"
        width="w-[300px]"
        content={
          <div>
            <form onSubmit={handleSubmitForForm1(submitHandler)}>
              <div className="flex flex-col gap-10">
                <div>
                  <input
                    type="number"
                    placeholder='Enter Amount'
                    onChange={(e) => {
                      const val = e.target.value;
                    }}
                    className='bg-[#2b2c47] px-6 py-4 outline-none w-full'
                    {...registerForForm1("newInstallmentAmt")}
                  />
                </div>
                <div className="flex items-center gap-10">
                  <button
                    type="submit"
                    className="px-8 py-2 text-white bg-tertiary rounded-xl cursor-pointer"
                    value="Update"
                  >Update</button>
                  <span
                    className="text-cyan cursor-pointer"
                    onClick={() => {
                      setshowInsAmtModal(false);
                    }}
                  >
                    Cancel
                  </span>
                </div>
              </div>
            </form>
          </div>
        }
      />

      <Modal
        showModal={showPauseModal}
        closeModal={() => {
          setshowPauseModal(false)
        }}
        title="Pause Loan"
        width="w-[300px]"
        content={
          <form onSubmit={pauseHandler}>
            <div className='flex flex-col gap-10'>
              {InstallmentData[0]?.year === new Date().getFullYear() && InstallmentData[0]?.month > new Date().getMonth() ? (
                <></>
              ) : (
                <div className='flex gap-6'>
                  <input type="checkbox" className='cursor-pointer'
                    onChange={(e) => {
                      if (e.target.checked) {
                        const currentMonthIndex = new Date().getMonth();
                        setpauseStartMonth(currentMonthIndex)
                      }
                      else {
                        setpauseStartMonth(null)
                      }
                      setpauseCheck(e.target.checked)
                    }}
                  />
                  <span>Pause Now</span>
                </div>
              )}

              {!pauseCheck &&
                <div>
                  <label htmlFor="start_month_name">
                    Select Month To Pause From
                  </label>
                  <Select
                    id="start_month_name"
                    // options={monthYear}
                    options={monthsList}
                    onChange={(e) => {
                      setpauseStartMonth(e.value)
                    }}
                    styles={theme == "light" ? adminStylesLight : adminStyles}
                    placeholder="Select Month"
                    className="w-full"
                  />
                </div>
              }

              <div>
                <label htmlFor="end_month_name">
                  Select Month To resume From
                </label>
                <Select
                  id="end_month_name"
                  // options={monthYear}
                  options={monthsList}
                  onChange={
                    (e) => {
                      setresumeMonth(e.value)
                    }}
                  styles={theme == "light" ? adminStylesLight : adminStyles}
                  placeholder="Select Month"
                  className="w-full"
                />
              </div>

              <div className="flex items-center gap-10">
                <button
                  type='submit'
                  className="px-8 py-2 w-max text-white bg-tertiary rounded-xl cursor-pointer"
                  value="Save"
                >Save</button>
                <span
                  className="text-cyan cursor-pointer"
                  onClick={() => {
                    setshowPauseModal(false);
                  }}
                >
                  Cancel
                </span>
              </div>
            </div>
          </form>
        }
      />
    </main>
  );
};

export default Installments;

