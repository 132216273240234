import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
// import '../../antd.css'
// const items = [
//   {
//     key: '1',
//     type: 'group',
//     label: 'Group title',
//     children: [
//       {
//         key: '1-1',
//         label: '1st menu item',
//       },
//       {
//         key: '1-2',
//         label: '2nd menu item',
//       },
//     ],
//   },
//   {
//     key: '2',
//     label: 'sub menu',
//     children: [
//       {
//         key: '2-1',
//         label: '3rd menu item',
//       },
//       {
//         key: '2-2',
//         label: '4th menu item',
//       },
//     ],
//   },
//   {
//     key: '3',
//     label: 'disabled sub menu',
//     disabled: true,
//     children: [
//       {
//         key: '3-1',
//         label: '5d menu item',
//       },
//       {
//         key: '3-2',
//         label: '6th menu item',
//       },
//     ],
//   },
// ];

const CascadingMenu = ({items,title}) => (
  <Dropdown 
  // overlayClassName="custom-dropdown-menu" 
  className='border p-2 border-secondary rounded-xl bg-primaryDark1'
    menu={{
      items,
    }}
  >
    <a className="hover:bg-primaryDark2" onClick={(e) => e.preventDefault()}>
      <Space>
        <span className='text-xl font-semibold text-secondary'>{title}</span>
        <DownOutlined className='bg-primaryDark1 !text-secondary' />
      </Space>
    </a>
  </Dropdown>
);
export default CascadingMenu;