import React, { useEffect, useMemo, useState } from 'react';
import './prededuction.css';
import PageTitle from '../../../../../components/PageTitle';
import { adminStyles, adminStylesLight } from '../../../../../utils/selectStyles';
import Select, {components} from "react-select";
import { useDispatch, useSelector } from 'react-redux';
import { AddDeduction, CreateDeduction, GetDeductionTypes } from '../../../../../services/deduction';
import customToast from "../../../../../components/CustomToast";
import { generateSelectData } from '../../../../../utils/serialiseResponse';
import { useNavigate } from 'react-router-dom';
import { IoAddOutline } from "react-icons/io5";
import Modal from '../../../../../components/Modal';
import { fetchDeductionTypes, postDeductionTypes } from '../../../../../reducers/deductionTypesReducer';

const deductionFrequencyOptions = [
  { value: 'recurring', label: 'Recurring' },
  { value: 'one-time', label: 'One-time' }
];

const AddPreDeductionn = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.theme);
  const deductionData = useSelector(({deductionTypes}) => deductionTypes.data);
  const [config, setConfig] = useState([]);
  const [deductionType, setDeductionType] = useState("");
  const [deductionFrequency, setDeductionFrequency] = useState("");
  const [paySlipName, setPaySlipName] = useState("");
  const [active, setActive] = useState(false);
  const [employerContribution , setEmployerContribution] = useState(false);
  const [proRata , setProRata] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [newDeductionType, setNewDeductionType] = useState(null);

  const deductionSelect = useMemo(() => {
    let options = generateSelectData(deductionData, "name");

    options.push({label: "Add Other", value: "Add Other", icon:<IoAddOutline/>});
    return options;
  },
   [deductionData]);

  useEffect(() => {
    dispatch(fetchDeductionTypes());
  },[]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    
    if(!deductionType || !deductionFrequency || !paySlipName) {
      customToast.error("Please Fill All Required Fields");
      return;
    }

    const payload = {
      name: deductionType.label,
      deductionType: deductionType._id,
      nameOnPaySlip: paySlipName,
      deductionFrequency: deductionFrequency.label,
      isActive:active,
      // proRata,
      // employerContribution, 
      categorie: "Pre-Tax Deduction"
    };

    try {
      const response = await AddDeduction(payload);
      if (response.message === "OK") {
        customToast.success("Deduction Details Uploaded Successfully");
        navigate("..");
      } else {
        customToast.error("Error Uploading Deduction Details");
      }
    } catch (error) {
      if (error.response && error.response.status && error.response.status === 403) {
        customToast.error(`${error.response.data.data} Already Exist`);
      } else {
        customToast.error("Error Uploading Deduction Details");
      }
    }
  };

  const handleActive = () => {
    setActive(active === "active" ? "In-active" : "active");
  };

  const handleDeductionTypes = async(e) => {
    e.preventDefault();
    try {
      let payload = {
        name: newDeductionType
      };
      
      dispatch(postDeductionTypes(payload));
      setShowModal(false);
      setNewDeductionType(null);
      customToast.success("New deduction type created !");
    } catch (error) {
      console.log(error);
      customToast.error("Error in adding new type");
    }
  };

  return (
   
    <div
      className={"main-content " + `${theme == "light" ? "light_bg_main" : ""}`}
      style={{ width: "100%" }}
    >
      <div
        className={
          "creation-content " + `${theme == "light" ? "light_bg" : ""}`
        }
      >
        <PageTitle title="Pre Tax Deduction" route={'..'} />

        <section className="admin-content">
          <form onSubmit={(e) => handleFormSubmit(e)}>
            <div className="row">
              <div>
                <label className='dark:text-primaryDark1 uppercase'>
                  Deduction Type<span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  options={deductionSelect}
                  styles={theme === "light" ? adminStylesLight : adminStyles}
                  placeholder="Select Type"
                  onChange={(selectedOption) => {
                    selectedOption?.label === "Add Other" ? 
                    setShowModal(true) 
                    :
                    setDeductionType(selectedOption)
                  }}
                  className='mt-4'
                  components={{
                    Option:
                      ({ data, ...props }) => (
                        <components.Option {...props}>
                          <div className='flex gap-2 items-center'>
                            <span>{data.label}</span>
                            <div>
                              {data.icon}
                            </div>
                          </div>
                        </components.Option>
                      )
                  }}
                />
              </div>

              <div>
                <label htmlFor="mName" className='dark:text-primaryDark1 uppercase'>
                  Deduction Frequency
                </label>
                <br />
                <Select
                  options={deductionFrequencyOptions}
                  styles={theme === "light" ? adminStylesLight : adminStyles}
                  placeholder="Select Frequency"
                  onChange={(selectedOption) => setDeductionFrequency(selectedOption)}
                  className='mt-4'
                />
              </div>

              <div>
                <label className='dark:text-primaryDark1 uppercase'>
                  Name on Payslip <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                  className='mt-4'
                  placeholder="Enter Name"
                  onChange={({ target }) => setPaySlipName(target.value)}
                />
              </div>
            </div>

            <div className='flex gap-3 mx-8'>
              <input
                type='checkbox'
                onChange={(e) => active ? setActive(false) : setActive(true)}
                // style={{ width: 'auto' }}
                className='check-box-active blue_b w-6 h-6'
              />
              <label className='text-2xl'>Mark this as Active</label>
            </div>

            <div className='mx-8 mt-6 pb-10'>
              <button type="submit" className="button-blue-gradient">
                Create
              </button>
            </div>
          </form>
        </section>

        <Modal
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        width="w-[300px]"
        title="Add New Deduction Type"
        content={
          <div>
            <form>
              <div className='flex flex-col gap-10'>
                <div className='flex flex-col gap-2'>
                  <label htmlFor="loan_name">
                    Enter Deduction Type
                  </label>
                  <input
                    id='loan_name'
                    type="text"
                    placeholder='Enter Type'
                    className="bg-[#2B2C47] px-6 py-4 outline-none"
                    onChange={(e) => setNewDeductionType(e?.target?.value)}
                  />
                </div>

                <div className="flex items-center gap-10">
                  <button
                    type='submit'
                    className="px-8 py-2 w-max text-white bg-tertiary rounded-xl cursor-pointer"
                    value="Save"
                    onClick={(e) => handleDeductionTypes(e)}
                  >Submit</button>
                  <span className="text-cyan cursor-pointer" onClick={() => setShowModal(false)}>
                    Cancel
                  </span>
                </div>
              </div>
            </form>
          </div>
        }
        />
      </div>
    </div>
  )
}

export default AddPreDeductionn;