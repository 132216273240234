import React, { useEffect, useMemo, useState } from "react";
import PageTitle from "../../../../../components/PageTitle";
import { useSelector } from "react-redux";
import { FaPen } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { taxSlabInfo } from "../../../../../services/salaryTempelates";
import customToast from "../../../../../components/CustomToast";

const HandleProfessionalTax = () => {
  const theme = useSelector(({ theme }) => theme);
  const location = useLocation();
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchTaxSlab = async () => {
      await taxSlabInfo({
        // stateName: location?.state?.state?.name,
        // deductionType: location?.state?.deductionType,
        id: location?.state.stateWiseSlabInfoId,
      })
        .then((res) => {
          console.log(res.data);

          setData(res.data);
        })
        .catch(() => {
          return customToast.error("error occurred !");
        });
    };
    fetchTaxSlab();
  }, []);

  const tableData2 = data?.tax_slabs;
  // console.log(tableData2)

  const columns = useMemo(
    () => [
      {
        Header: "Start Range",
        accessor: "minimum_income",
      },
      {
        Header: "End Range",
        accessor: "maximum_income",
        Cell: (props) => {
          return <span>{props.value ? props.value : "And Above"}</span>;
        },
      },
      {
        Header: "Jan",
        accessor: (row) => row.tax_rate[0],
        Cell: (props) => {
          console.log(props);
          return <span>{props.value ? props.value[0] : null}</span>;
        },
      },
      {
        Header: "feb",
        accessor: "tax_rate",
        Cell: (props) => {
          // console.log(props)
          return <span>{props.value ? props.value[1] : null}</span>;
        },
      },

      {
        Header: "Action",
        accessor: "status",
        Cell: (props) => (
          <div className="flex items-center justify-center">
            <FaPen color="#3883D7" size={20} className="cursor-pointer" />
          </div>
        ),
      },
    ],
    [],
  );

  return (
    <main
      className={"main-content " + `${theme == "light" ? "light_bg_main" : ""}`}
      style={{ width: "100%" }}
    >
      <div
        className={
          "area-creation-content " + `${theme == "light" ? "light_bg" : ""}`
        }
      >
        <PageTitle title="TAX SLAB" route={".."} />
        <section className="admin-content">
          <form className={"" + `${theme == "light" ? "lightbg" : ""}`}>
            <div className="row">
              <div>
                <label>Professional Tax Number</label>
                <input
                  type="text"
                  placeholder="Enter Type"
                  disabled={true}
                  value={location?.state?.professionalTaxNo}
                />
                <h2 className="text-tertiaryLight  font-semibold text-6xl mt-4 ">
                  {location.state?.stateWiseInfo?.professionalTaxNo}
                </h2>
              </div>

              <div>
                <label>State</label>
                <br />
                <input
                  type="text"
                  placeholder="Enter Type"
                  disabled={true}
                  value={location?.state?.state?.name}
                />
                <h2 className="text-success  font-semibold text-6xl mt-4 ">
                  {location.state?.stateWiseSlabInfo?.state}
                </h2>
              </div>

              <div>
                <label htmlFor="mName">Deduction Type</label>
                <br />
                <input
                  type="text"
                  placeholder="Enter Type"
                  disabled={true}
                  value={location?.state?.deductionType}
                />
                <h2 className="text-yellowDark  font-semibold text-6xl mt-4 " s>
                  {location.state?.stateWiseInfo?.deductionType}
                </h2>
              </div>
            </div>
          </form>
        </section>
        <section className={"admin-content mx-8 pb-10"}>
          <label>Tax Slab ({data?.tax_deduction_period})</label>

          {tableData2 && tableData2.length > 0 && (
            <h2
              className={
                "show-entries" + `${theme === "light" ? "light_bg_logo" : ""}`
              }
            >
              Showing ({tableData2.length}) Entries
            </h2>
          )}

          {tableData2 && tableData2.length > 0 ? (
            <div
              className={
                "filter-table " + `${theme === "light" ? "table_light" : ""}`
              }
            >
              <table>
                <thead className="uppercase dark:text-secondary dark:bg-primaryDark2 w-full text-center">
                  <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">
                    Start Range
                  </th>
                  <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">
                    End Range
                  </th>
                  <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">
                    Jan
                  </th>
                  <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">
                    Feb
                  </th>
                  <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">
                    Mar
                  </th>
                  <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">
                    Apr
                  </th>
                  <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">
                    May
                  </th>
                  <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">
                    Jun
                  </th>
                  <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">
                    Jul
                  </th>
                  <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">
                    Aug
                  </th>
                  <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">
                    Sep
                  </th>
                  <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">
                    Oct
                  </th>
                  <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">
                    Nov
                  </th>
                  <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">
                    Dec
                  </th>
                </thead>
                <tbody>
                  {tableData2.map((data) => {
                    return (
                      <tr className="dark:bg-primary text-center">
                        <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6 w-1/5">
                          {data?.minimum_income}
                        </td>
                        <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6 w-1/5 ">
                          {data?.maximum_income == null
                            ? "Above"
                            : data.maximum_income}
                        </td>
                        <td className="border-2 border-collapse  border-primaryLight3  dark:border-primaryDark dark:text-wheat text-black px-6 py-6 w-1/5">
                          {data?.tax_rate[0]}
                        </td>
                        <td className="border-2 border-collapse  border-primaryLight3  dark:border-primaryDark dark:text-wheat text-black px-6 py-6 w-1/5">
                          {data?.tax_rate[1]}
                        </td>
                        <td className="border-2 border-collapse  border-primaryLight3  dark:border-primaryDark dark:text-wheat text-black px-6 py-6 w-1/5">
                          {data?.tax_rate[2]}
                        </td>
                        <td className="border-2 border-collapse  border-primaryLight3  dark:border-primaryDark dark:text-wheat text-black px-6 py-6 w-1/5">
                          {data?.tax_rate[3]}
                        </td>
                        <td className="border-2 border-collapse  border-primaryLight3  dark:border-primaryDark dark:text-wheat text-black px-6 py-6 w-1/5">
                          {data?.tax_rate[4]}
                        </td>
                        <td className="border-2 border-collapse  border-primaryLight3  dark:border-primaryDark dark:text-wheat text-black px-6 py-6 w-1/5">
                          {data?.tax_rate[5]}
                        </td>
                        <td className="border-2 border-collapse  border-primaryLight3  dark:border-primaryDark dark:text-wheat text-black px-6 py-6 w-1/5">
                          {data?.tax_rate[6]}
                        </td>
                        <td className="border-2 border-collapse  border-primaryLight3  dark:border-primaryDark dark:text-wheat text-black px-6 py-6 w-1/5">
                          {data?.tax_rate[7]}
                        </td>
                        <td className="border-2 border-collapse  border-primaryLight3  dark:border-primaryDark dark:text-wheat text-black px-6 py-6 w-1/5">
                          {data?.tax_rate[8]}
                        </td>
                        <td className="border-2 border-collapse  border-primaryLight3  dark:border-primaryDark dark:text-wheat text-black px-6 py-6 w-1/5">
                          {data?.tax_rate[9]}
                        </td>
                        <td className="border-2 border-collapse  border-primaryLight3  dark:border-primaryDark dark:text-wheat text-black px-6 py-6 w-1/5">
                          {data?.tax_rate[10]}
                        </td>
                        <td className="border-2 border-collapse  border-primaryLight3  dark:border-primaryDark dark:text-wheat text-black px-6 py-6 w-1/5">
                          {data?.tax_rate[11]}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            ""
          )}
        </section>
      </div>
    </main>
  );
};

export default HandleProfessionalTax;
