import { useEffect, useMemo, useState, useRef } from "react";
import { useTable, useFilters, useSortBy, usePagination } from "react-table";
import { matchSorter } from "match-sorter";
// import * as XLSX from "xlsx";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import Select from "react-select";
import { ReactComponent as SearchIcon } from "../../views/public/img/svg/magnifying-glass.svg";
import { BsArrowDown } from "react-icons/bs";
import { BsArrowUp } from "react-icons/bs";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import { RiFileExcel2Fill } from "react-icons/ri";
import { AiOutlineClose } from "react-icons/ai";
import { useSelector } from "react-redux";

const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    border: "2px solid #36ABF9",
    borderRadius: "5px",
    color: "#9e9ad0",
    fontSize: "1.5rem",
    fontWeight: "500",

    width: "20rem",
    cursor: "pointer",

    margin: "1rem 0",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    color: "#9E9AD1",
    fontFamily: "inherit",
    fontSize: "1.5rem",

    "&:hover": {
      backgroundColor: "#393b5f",
    },
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#9e9ad0",
    textAlign: "left",
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: "#353456",
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: "#9e9ad0",
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
  }),
  input: (provided, state) => ({
    ...provided,
    color: "#fff",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#C7C4E9",

    "&:hover": {
      color: "#9E9AD1",
    },
  }),
};
const renderFilterButton = ({ isSorted, isSortedDesc }) => {
  return (
    <div
    // className="sort-filter__container"
    >
      {/* <MdOutlineFilterAlt className="sort-filter__icon" /> */}
      {isSortedDesc ? (
        <BsArrowDown className="fs-4" />
      ) : (
        <BsArrowUp className="fs-4" />
      )}
    </div>
  );
};
// Search Filter column: { filterValue, setFilter,Header }
function SearchColumnFilter({ column }) {
  const [searchActive, setSearchActive] = useState(false);
  return (
    <div
      className="search-filter__container"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {searchActive && (
        <input
          value={column.filterValue || ""}
          onChange={(e) => {
            column.setFilter(e.target.value || undefined);
          }}
          onFocus={() => setSearchActive(true)}
          onBlur={() => setSearchActive(false)}
          placeholder={`Search`}
          className="search-filter"
          autoFocus={true}
        />
      )}
      {!searchActive ? (
        <SearchIcon
          onClick={() => setSearchActive(!searchActive)}
          className={searchActive ? "search-filter__icon" : "me-1"}
          style={{
            fill: searchActive || column.filterValue ? "#36abf9" : "#9e9ad1",
          }}
        />
      ) : (
        <AiOutlineClose
          className="search-filter__icon icon-color-error"
          onClick={() => setSearchActive(false)}
        />
      )}
      {!searchActive && (
        <span className="flex gap-1 items-center">
          {column.render("Header")}
          {column.canSort
            ? renderFilterButton({
                isSorted: column.isSorted,
                isSortedDesc: column.isSortedDesc,
              })
            : null}
        </span>
      )}
    </div>
  );
}

// Filter with Select Dropdown
export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  const selectOptions = options.map((o) => ({ label: o, value: o }));

  return (
    <Select
      isMulti={true}
      options={selectOptions}
      onChange={(options) => {
        const allValues = [...options].map((o) => o.value).filter(Boolean);

        setFilter(allValues && allValues.length ? allValues : undefined);
      }}
      styles={selectStyles}
    />
  );
}

export function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}
fuzzyTextFilterFn.autoRemove = (val) => !val;

const Table = ({ columns, data, defaultPageSize, exportExcel = true }) => {
  const [tableData, setTableData] = useState([]);
  const [columnTotals, setColumnTotals] = useState({});
  const tableRef = useRef(null);
  useEffect(() => {
    const newColumnTotals = {};
    columns.forEach((column) => {
      if (column.showTotal) {
        const total = data.reduce((sum, row) => {
          if (Array.isArray(row[column.totalAccessor] || 0)) {
            return sum + (parseFloat(row[column.totalAccessor].length) || 0);
          }
          return sum + (parseFloat(row[column.totalAccessor]) || 0);
        }, 0);
        newColumnTotals[column.totalAccessor] = total.toFixed(2);
      }
    });

    setColumnTotals(newColumnTotals);
  }, [data, columns]);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  const exportEXCEL = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Report");

    // Define column headers and their styles
    const headers = columns.map((column) => {
      return {
        header: column.Header,
        key: column.accessor,
        width: 20,
        style: {
          font: { bold: true },
          // fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFF00' } }
        },
      };
    });
    worksheet.columns = headers;

    // Extract data from the table
    const rows = Array.from(tableRef.current.querySelectorAll("tbody tr")).map(
      (row) => {
        const cells = Array.from(row.querySelectorAll("td"));
        return columns.reduce((acc, col, idx) => {
          acc[col.accessor] = cells[idx].innerText;
          return acc;
        }, {});
      },
    );

    // Add rows to the worksheet
    rows.forEach((row) => {
      worksheet.addRow(row);
    });

    // Apply style to data rows

    worksheet.eachRow((row, rowNumber) => {
      let credit = false;
      let debit = false;
      let initial = true;
      row.eachCell((cell) => {
        if (rowNumber === 1) {
          if ((initial = true))
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "B3B9C4" },
            };
          if (credit)
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "7EE2B8" },
            };
          if (debit)
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FD9891" },
            };

          if (cell._column._header == "LOP") {
            credit = true;
            initial = false;
          }

          if (cell._column._header == "Total Earning") {
            credit = false;
            debit = true;
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "2ABB7F" },
            };
          }
          if (cell._column._header == "Total Deduction") {
            debit = false;
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "F15B50" },
            };
          }

          if (cell._column._header == "Net Salary") {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "2ABB7F" },
            };
          }
        }
        // else {
        //   // Apply data row style
        //   cell.fill = {
        //     type: 'pattern',
        //     pattern: 'solid',
        //     fgColor: { argb: 'FF0000' }
        //   };
        //   cell.font = {
        //     color: { argb: 'FFFFFF' }
        //   };
        // }
      });
    });

    // Generate buffer and save the file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/octet-stream" });
    saveAs(blob, "Report.xlsx");
  };

  const filterTypes = useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      multiple: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined ? filterValue.includes(rowValue) : true;
        });
      },
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    [],
  );

  const defaultColumn = useMemo(() => ({ Filter: SearchColumnFilter }), []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setPageSize,
    state: { pageIndex, pageSize },
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,

      // initialState: { sortBy: [{ id: 'date', desc: true }] },
    },
    useFilters,
    useSortBy,
    usePagination,
  );
  //adding this effect so that components can provide default page size if they want
  useEffect(() => {
    if (defaultPageSize) {
      setPageSize(defaultPageSize);
    }
  }, [defaultPageSize]);

  return (
    <>
      <table
        className="text-2xl border-2 border-primaryDark border-collapse  dark:text-secondary"
        ref={tableRef}
        {...getTableProps()}
      >
        <thead className="dark:bg-primaryLight border-collapse">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  className="border-2 text-2xl dark:border-primaryDark border-collapse dark:text-secondary"
                  {...column.getHeaderProps(
                    {
                      style: {
                        width: column.width,
                        minWidth: column.minWidth,
                        maxWidth: column.maxWidth,
                      },
                    },
                    column.getSortByToggleProps(),
                  )}
                  onClick={() =>
                    column.canSort && column.toggleSortBy(!column.isSortedDesc)
                  }
                >
                  <div className="flex flex-col justify-center items-center gap-4">
                    {column.canFilter ? (
                      column.render("Filter")
                    ) : (
                      <div className="flex gap-2">
                        <span>{column.render("Header")}</span>
                        <span>
                          {column.canSort
                            ? renderFilterButton({
                                isSorted: column.isSorted,
                                isSortedDesc: column.isSortedDesc,
                              })
                            : null}
                        </span>
                      </div>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody
          {...getTableBodyProps()}
          className="dark:bg-primary dark:text-secondary border-collapse"
        >
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td
                    className="text-2xl py-4  border-2 dark:border-primaryDark border-collapse dark:text-secondary"
                    {...cell.getCellProps({
                      style: {
                        minWidth: cell.column.minWidth,
                        maxWidth: cell.column.maxWidth,
                        width: cell.column.width,
                        wordWrap: cell.column.wordWrap,
                      },
                    })}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>

        {data.length > 0 && (
          <tfoot className="dark:bg-primary">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <td
                    {...column.getHeaderProps({
                      style: {
                        width: column.width,
                        minWidth: column.minWidth,
                        maxWidth: column.maxWidth,
                      },
                    })}
                  >
                    <div className="flex flex-col justify-between gap-4">
                      {column.showTotal && (
                        <div
                          className="flex flex-col justify-between gap-4 font-bold"
                          style={{ color: "#3498db" }}
                        >
                          {!column.placeholderTotal ? (
                            columnTotals[column.totalAccessor]
                          ) : (
                            <span>{column.placeholderTotal}</span>
                          )}
                        </div>
                      )}
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        )}
      </table>

      <div className="flex justify-between sticky left-0 bottom-0 w-full">
        <div className="text-md font-medium rounded-b-xl px-2 py-6 dark:bg-primary  dark:text-secondary h-20">
          <button
            className="px-1 py-1 inline-block"
            disabled={!canPreviousPage}
            onClick={() => previousPage()}
          >
            <MdArrowBackIos className="inline-block" /> Prev
          </button>
          <span className="mx-5">
            Page {pageIndex + 1} of {pageOptions.length}
          </span>
          <button
            className="px-1 py-1 inline-block"
            disabled={!canNextPage}
            onClick={() => nextPage()}
          >
            Next <MdArrowForwardIos className="inline-block" />{" "}
          </button>
        </div>

        <div className="flex dark:text-secondary dark:bg-primary rounded-b-xl p-1 h-20">
          {exportExcel ? (
            <button
              className="flex items-center gap-2 export-button dark:text-secondary dark:bg-primary"
              onClick={exportEXCEL}
            >
              <RiFileExcel2Fill className="fs-3" />
              <span>Export</span>
              <div className="ms-4 dark:bg-secondary h-full w-[1px]"></div>
            </button>
          ) : null}
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            className="text-2xl font-bold w-auto px-4 dark:bg-primary"
          >
            {[5, 10, 25, 50, 100, 200, 500, 1000].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
};

export default Table;
