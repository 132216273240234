import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SlOptionsVertical } from "react-icons/sl";
import "./reimbursement.css";

import PageTitle from "../../../../components/PageTitle";
import Table from "../../../../components/Table";
import Dropdown from "../../../../components/DropDown/dropdown";

import { DeleteReimbursement, fetchReimbursements } from "../../../../reducers/reimbursementsReducer";
import { addIndex2 } from "../../../../utils/serialiseResponse";
import DeletePopUp from "./DeletePopup";
import customToast from "../../../../components/CustomToast";

const Reimbursement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useSelector((state) => state.theme.theme);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const reimbursements = useSelector((state) => state.reimbursements.data);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "nameOnPaySlip",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Reimbursement Type",
        accessor: "reimbursementType.name",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Reimburseable Limit",
        accessor: "limitPerMonth",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Status",
        accessor: "active",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return (
            <>
              {props?.row?.original?.active ? (
                <span className="text-green-500">Active</span>
              ) : (
                <span className="text-red-500">Inactive</span>
              )}
            </>
          );
        },
      },
      {
        Header: "Options",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          const [showOptions, setShowOptions] = useState(false);
          return (
            <>
              <Dropdown
                icon={
                  <SlOptionsVertical className="icon-color-tertiary" />
                }
                options={[
                  {
                    text: 'Edit',
                    onClick: () => {
                      navigate("../reimbursement/edit",{state: props?.row?.original})
                    }
                  },
                  {
                    text: 'Delete',
                    onClick: () => {
                      setDeleteId(props?.row?.original?._id);
                      setShowEditPopup(true);
                    }
                  },
                ]}
              />
            </>
          );
        },
      },
    ],
    [],
  );

  const handleAddReimbursement = () => {
    navigate("add");
  };

  const tableData = useMemo(() => addIndex2(reimbursements), [reimbursements]);

  useEffect(() => {
    dispatch(fetchReimbursements());
  }, []);

  const handleDelete = async(e) => {
    e.preventDefault();

    try {
      let payload = {
        id : deleteId
      };
      
      dispatch(DeleteReimbursement(payload));
      setDeleteId(null);
      setShowEditPopup(false);
      customToast.success("Reimbursement deleted successfully !");
    } catch (error) {
      console.log(error);
      setShowEditPopup(false);
      customToast.error("Error occurred while deleting reimbursement !");
    }
  };

  const handleCancel = () => {
    setShowEditPopup(false);
  }

  return (
    <div
      className={"main-content " + `${theme == "light" ? "light_bg_main" : ""}`}
      style={{ width: "100%" }}
    >
      <div
        className={
          "creation-content " + `${theme == "light" ? "light_bg" : ""}`
        }
      >
        <PageTitle title="REIMBURSEMENT" route={'../..'} />

        <section className="admin-content mx-8 py-8">
          <div className="">
            <button
              type="button"
              className="rounded-full font-medium text-2xl px-10 py-5 dark:bg-tertiary cursor-pointer"
              onClick={handleAddReimbursement}
            >
              ADD REIMBURSEMENT
            </button>
          </div>

          <section>
            {tableData.length > 0 && (
              <h2
                className={
                  "show-entries" +
                  `${theme === "light" ? "light_bg_logo" : ""}`
                }
              >
                Showing ({tableData.length}) Entries
              </h2>
            )}
            <div
              className={
                "filter-table " + `${theme === "light" ? "table_light" : ""}`
              }
            >
              {tableData.length > 0 ? (
                <Table columns={columns} data={tableData} />
              ) : (
                <h3 className="no-results-message">Nothing To Show !!</h3>
              )}
            {showEditPopup ?
            (<DeletePopUp
            setShowEdit={setShowEditPopup}
            label="Are you sure you want to delete ? "
            handleCancel={handleCancel}
            handleDelete={handleDelete}/>
          )
          :
          null
          }
          </div>
          </section>
        </section>
      </div>
    </div>
  );
};

export default Reimbursement;
