import React, { useState } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa6';
import { useSelector } from 'react-redux';
import "./accordion.css"

const Accordion = ({ title, content, defaultState, titleStyle ,contentStyle }) => {

    const [HRDtoggle, setHRDtoggle] = useState(defaultState);
    const theme = useSelector((state) => state.theme.theme);

    return (
        <main className={`h-full w-full`}>
            <div className='flex flex-col'>
                <div
                    className={`head flex ${titleStyle ? titleStyle :""}`}
                    onClick={() => setHRDtoggle(!HRDtoggle)}
                >
                    {title}
                    {HRDtoggle ? <FaChevronUp /> : <FaChevronDown />}
                </div>

                <div>
                    {HRDtoggle &&
                        <div className={`${contentStyle?contentStyle:""}`}>
                            {content}
                        </div>
                    }
                </div>
            </div>
        </main >
    )
}

export default Accordion