export const title = "Manage Master Data";

export const links = [
  {
    name: "Division",
    route: "division",
    accessor: "division",
  },
  // {
  //   name: "Sub-Division",
  //   route: "sub-division",
  //   accessor: "sub-division",
  // },
  {
    name: "Department",
    route: "department",
    accessor: "department",
  },
  {
    name: "Designation",
    route: "designation",
    accessor: "designation",
  },
  {
    name: "State",
    route: "state",
    accessor: "state",
  },
  {
    name: "HeadQuarters",
    route: "headquarter",
    accessor: "headquarter",
  },
  // {
  //   name: "City",
  //   route: "city",
  //   accessor: "city",
  // },
];
