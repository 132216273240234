import React, { useEffect, useState } from "react";
import { GetEmployeeTemp } from "../../../../services/hrms";
import customToast from "../../../../components/CustomToast";
import { useSelector } from "react-redux";

const RevisedSalary = (props) => {
  const { emp } = props;
  const [userTemp, setUserTemp] = useState(null);
  useEffect(() => {
    {
      emp &&
        GetEmployeeTemp({ id: emp?._id })
          .then(({ data }) => {
            if (data) {
              setUserTemp(data);
            } else {
              setUserTemp(null);
            }
          })
          .catch((err) => {
            customToast.error(
              "Salary details not uploaded for selected employee!",
            );
          });
    }
  }, [emp]);
  return (
    <div>
      {userTemp && userTemp?.employeeId === emp?._id ? (
        <div className="w-full ">
          <table className="w-full table-fixed text-center border-separate">
            <tr>
              <th className="px-6 py-2 w-1/5 border-b-2 bg-primaryLight2 dark:bg-primaryDark1 border-blue-400">
                Employee Name
              </th>
              <th className="px-6 py-2 w-1/5 border-b-2 bg-primaryLight2 dark:bg-primaryDark1 border-blue-400">
                Emp ID
              </th>
              <th className="px-6 py-2 w-1/5 border-b-2 bg-primaryLight2 dark:bg-primaryDark1 border-blue-400">
                Revised Date
              </th>
              <th className="px-6 py-2 w-1/5 border-b-2 bg-primaryLight2 dark:bg-primaryDark1 border-blue-400">
                Applicable From
              </th>
              <th className="px-6 py-2 w-1/5 border-b-2 bg-primaryLight2 dark:bg-primaryDark1 border-blue-400">
                Old Monthly Salary
              </th>
              <th className="px-6 py-2 w-1/5 border-b-2 bg-primaryLight2 dark:bg-primaryDark1 border-blue-400">
                Old Annual Salary
              </th>
              <th className="px-6 py-2 w-1/5 border-b-2 bg-primaryLight2 dark:bg-primaryDark1 border-blue-400">
                New Monthly Salary
              </th>
              <th className="px-6 py-2 w-1/5 border-b-2 bg-primaryLight2 dark:bg-primaryDark1 border-blue-400">
                New Annual Salary
              </th>
            </tr>
            {userTemp?.ReviseSalaryTempelate?.map((salary) => (
              <tr>
                <td className="px-6 py-2 w-1/5 bg-primaryLight2 dark:bg-primaryDark1">
                  {props.emp?.label}
                </td>
                <td className="px-6 py-2 w-1/5 bg-primaryLight2 dark:bg-primaryDark1">
                  {props.emp?.employeeId}
                </td>
                <td className="px-6 py-2 w-1/5 bg-primaryLight2 dark:bg-primaryDark1">
                  -
                </td>
                <td className="px-6 py-2 w-1/5 bg-primaryLight2 dark:bg-primaryDark1">
                  -
                </td>
                <td className="px-6 py-2 w-1/5 bg-primaryLight2 dark:bg-primaryDark1">
                  {salary?.monthlyTotal}
                </td>
                <td className="px-6 py-2 w-1/5 bg-primaryLight2 dark:bg-primaryDark1">
                  {salary?.total}
                </td>
                <td className="px-6 py-2 w-1/5 bg-primaryLight2 dark:bg-primaryDark1">
                  -
                </td>
                <td className="px-6 py-2 w-1/5 bg-primaryLight2 dark:bg-primaryDark1">
                  -
                </td>
              </tr>
            ))}
          </table>
        </div>
      ) : null}
    </div>
  );
};

export default RevisedSalary;
