import React, { useEffect, useMemo, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { CreateReimbursement } from "../../../services/reimbursement";
import customToast from "../../../components/CustomToast";
import { useNavigate } from "react-router-dom";
import { changeHrmsSetUpStatus } from "../../../reducers/hrmsSetUpStatusReducer";
import { useDispatch, useSelector } from "react-redux";
import { fetchReimbursementTypes } from "../../../reducers/reimbursementTypesReducer";
import Select from "react-select";
import { generateSelectData } from "../../../utils/serialiseResponse";
import { setUpSelectStyles } from "../../../utils/selectStyles";

const Reimbursements = ({ sidePanelOpen, setSidePanelOpen }) => {
  const reimbursementTypes = useSelector(
    ({ reimbursementTypes }) => reimbursementTypes,
  );

  const colums = [
    {
      name: "Name",
      key: "nameOnPaySlip",
    },
    {
      name: "Reimbursement Type",
      key: "reimbursementType",
    },
    {
      name: "Reimburseable Limit",
      key: "limitPerMonth",
    },
  ];
  const [data, setData] = useState([
    {
      nameOnPaySlip: "Fuel",
      reimbursementType: "Fuel",
      limitPerMonth: 5000,
      active: false,
    },
    {
      nameOnPaySlip: "Driver",
      reimbursementType: "Driver",
      limitPerMonth: 3000,
      active: false,
    },
    {
      nameOnPaySlip: "Phone",
      reimbursementType: "Phone",
      limitPerMonth: 500,
      active: false,
    },
    {
      nameOnPaySlip: "Leave Travel",
      reimbursementType: "Leave Travel",
      limitPerMonth: 4000,
      active: false,
    },
  ]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState([]);
  const [reimbursementType, setReimbursementType] = useState(null);
  const [paySlipName, setPaySlipName] = useState(null);
  const [limit, setLimit] = useState(null);
  const [active, setActive] = useState(false);
  const [editEarning, setEditEarning] = useState(false);

  useEffect(() => {
    dispatch(fetchReimbursementTypes());
  }, [dispatch]);

  const clearState = () => {
    setReimbursementType(null);
    setLimit(null);
    setPaySlipName(null);
    setActive(false);
  };

  const reimbursementOptions = useMemo(
    () => generateSelectData(reimbursementTypes?.types, "name"),
    [reimbursementTypes?.types],
  );

  const handleSkip = async () => {
    await dispatch(changeHrmsSetUpStatus({ status: "0" }));
    navigate("../department");
  };

  const handleCancel = () => {
    setSidePanelOpen(false);
  };

  const handleUpdateChanges = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        paySlipName,
        reimbursementType,
        limit,
        active,
      };

      data.map((el) => {
        if (
          el?.reimbursementType === payload?.reimbursementType &&
          el?.nameOnPaySlip === payload?.paySlipName
        ) {
          el.limitPerMonth = payload?.limit;
          el.nameOnPaySlip = payload?.paySlipName;
          el.active = payload?.active;
        }
      });

      clearState();
      setEditEarning(false);
      setSidePanelOpen(false);
      customToast.success("Reimbursement updated !");
    } catch (error) {
      console.log(error);
      customToast.error("Error in updating !");
    }
  };

  const handleAddSubmit = async (e) => {
    e.preventDefault();
    if (!reimbursementType || !paySlipName || !limit) {
      return customToast.error("Please Fill All Fields !!");
    }
    try {
      const payload = {
        reimbursementType: reimbursementType,
        nameOnPaySlip: paySlipName,
        limitPerMonth: Number(limit),
        active: active,
      };

      setSelected([...selected, payload]);
      setData((prevData) => [...prevData, payload]);
      setReimbursementType(null);
      setLimit(null);
      setPaySlipName(null);
      setActive(false);
      setSidePanelOpen(false);
    } catch (error) {
      customToast.error("Something went Wrong");
    }
  };

  const handleFormSubmit = async () => {
    try {
      const response = await CreateReimbursement(data);
      if (response.message == "OK") {
        customToast.success("Reimbursement Details Uploaded Successfully");
        setTimeout(() => {
          navigate("../department");
        }, 1000);
      }
    } catch (error) {
      if (error.response.status && error.response.status == 403) {
        customToast.error(`${error.response.data.data} Already Exist`);
      } else {
        customToast.error("Error Uploading Reimbursement Details");
      }
    }
  };

  return (
    <div className="reimbursements">
      <div className="new_table mt-5">
        <div className="new_thead ">
          {colums.map((col) => {
            return <span>{col.name}</span>;
          })}
        </div>

        {data.map((row) => {
          return (
            <div
              className={
                "new_table_row " +
                `${selected.includes(row.name) ? "bg-blue-200" : ""}`
              }
            >
              {colums.map((col) => {
                return col.name == "Name" ? (
                  <span className="flex items-center content-center gap-2">
                    <input
                      className="checkbox_input"
                      type="checkbox"
                      checked={selected.includes(row)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSidePanelOpen(true);
                          setEditEarning(true);
                          setReimbursementType(row?.reimbursementType);
                          setLimit(row?.limitPerMonth);
                          setActive(row?.active);
                          setPaySlipName(row?.nameOnPaySlip);
                          row.active = true;
                          setSelected([...selected, row]);
                        } else {
                          row.active = false;
                          setSelected(
                            selected.filter(
                              (item) =>
                                item.reimbursementType !==
                                row.reimbursementType,
                            ),
                          );
                        }
                      }}
                      name="select reimbursement"
                      id={row[col.key]}
                    />
                    <label
                      className="!m-0 !font-normal !text-2xl cursor-pointer"
                      htmlFor={row[col.key]}
                    >
                      {row[col.key]}
                    </label>
                  </span>
                ) : (
                  <span>{row[col.key]}</span>
                );
              })}
            </div>
          );
        })}
      </div>

      <div
        className=" side_panel "
        style={{
          width: sidePanelOpen ? "100%" : "0",
        }}
      >
        <div
          className="side_panel_form"
          style={{
            display: sidePanelOpen ? "block" : "none",
          }}
        >
          {editEarning ? (
            <form onSubmit={(e) => handleUpdateChanges(e)}>
              <div className="side_panel_form_head">
                <p>Edit Reimbursement </p>
                <RxCross1
                  color="black"
                  size={20}
                  style={{ marginTop: "-2rem", cursor: "pointer" }}
                  onClick={() => {
                    clearState();
                    setSidePanelOpen(false);
                    setEditEarning(false);
                  }}
                />
              </div>

              <div>
                <label htmlFor="">Reimbursement Type </label>
                <span className="imp_star">*</span>
                <br />
                <input
                  disabled={true}
                  className="text_input cursor-not-allowed"
                  type="text"
                  onChange={(e) => setReimbursementType(e.target.value)}
                  value={reimbursementType}
                />
              </div>

              <div>
                <label htmlFor="">Name on Payslip </label>
                <span className="imp_star">*</span>
                <br />
                <input
                  className="text_input"
                  type="text"
                  onChange={(e) => setPaySlipName(e.target.value)}
                  value={paySlipName}
                />
              </div>

              <div>
                <label htmlFor="">Enter Reimursable Limit </label>
                <br />
                <div className="flex items-center content-center gap-4">
                  <input
                    className="text_input"
                    type="text"
                    onChange={(e) => setLimit(e.target.value)}
                    value={limit}
                  />
                  <span className="w-full"> per month</span>
                </div>
              </div>

              <div className="terms">
                <div>
                  <input
                    className="checkbox_input"
                    type="checkbox"
                    id="rei-opt-1"
                    checked={active}
                    onClick={() => setActive(!active)}
                  />
                  <label
                    className="!m-0 !font-normal !text-xl cursor-pointer"
                    htmlFor="rei-opt-1"
                  >
                    Mark this as Active
                  </label>
                </div>
              </div>

              <div className="note">
                Note: You will not be able to edit this salary component once
                you associate it with an Employee
              </div>

              <div>
                <button className="submit_button">Save </button>
                <span
                  className="skip"
                  onClick={() => {
                    clearState();
                    setEditEarning(false);
                    setSidePanelOpen(false);
                  }}
                >
                  Cancel
                </span>
              </div>
            </form>
          ) : (
            <div>
              <form onSubmit={(e) => handleAddSubmit(e)}>
                <div className="side_panel_form_head">
                  <p>New Reimbursement </p>
                  <RxCross1
                    color="black"
                    size={20}
                    style={{ marginTop: "-2rem", cursor: "pointer" }}
                    onClick={() => {
                      setSidePanelOpen(false);
                    }}
                  />
                </div>

                <div>
                  <label htmlFor="">Reimbursement Type </label>
                  <span className="imp_star">*</span>
                  <br />
                  <Select
                    // className="text_input"
                    styles={setUpSelectStyles}
                    options={reimbursementOptions}
                    onChange={(e) => setReimbursementType(e?.name)}
                  />
                </div>

                <div>
                  <label htmlFor="">Name on Payslip </label>
                  <span className="imp_star">*</span>
                  <br />
                  <input
                    className="text_input"
                    type="text"
                    onChange={(e) => setPaySlipName(e.target.value)}
                  />
                </div>

                <div>
                  <label htmlFor="">Enter Reimursable Limit </label>
                  <br />
                  <div className="flex items-center content-center gap-4">
                    <input
                      className="text_input"
                      type="text"
                      onChange={(e) => setLimit(e.target.value)}
                    />
                    <span className="w-full"> per month</span>
                  </div>
                </div>

                <div className="terms">
                  <div>
                    <input
                      className="checkbox_input"
                      type="checkbox"
                      checked={active}
                      onChange={() => setActive(!active)}
                    />
                    <span>Mark this as Active</span>
                  </div>
                </div>

                <div className="note">
                  Note: You will not be able to edit this salary component once
                  you associate it with an Employee
                </div>

                <div>
                  <button className="submit_button">Save </button>
                  <span className="skip" onClick={handleCancel}>
                    Cancel
                  </span>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
      <div>
        <button className="submit_button" onClick={handleFormSubmit}>
          Save & Continue
        </button>
        <span className="skip" onClick={handleSkip}>
          Skip for now
        </span>
      </div>
    </div>
  );
};

export default Reimbursements;
