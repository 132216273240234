import { useEffect, useState, useMemo, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import customToast from "../../../components/CustomToast";
import PageTitle from "../../../components/PageTitle";
import { adminStylesLight, adminStyles } from "../../../utils/selectStyles";
import {
  generateSelectData,
  generateSelectUser,
  generateSelectDefaultValue,
} from "../../../utils/serialiseResponse";

import { setDivisions } from "../../../reducers/divisionReducer";
import { setDesignations } from "../../../reducers/designationReducer";
import { useNavigate } from "react-router-dom";
import { editEmployee, getCompanyWorkingAddress } from "../../../services/hrms";
import { deepObjCompare, formatDate } from "../../../utils/helper";
import "./create.css";
import { setDepartments } from "../../../reducers/departmentReducer";
import { fetchStates } from "../../../reducers/stateReducer";
import { fetchCities } from "../../../reducers/cityReducer";
import { fetchEmployees } from "../../../reducers/employeesReducer";
import Accordion from "../../../components/Accordion";
import { useLocation } from "react-router-dom";
import { fetchPtDetails } from "../../../services/salaryTempelates";

const genderOptions = [
  { value: 0, label: "Male" },
  { value: 1, label: "Female" },
  { value: 2, label: "Other" },
];

const maritalOptions = [
  { value: 0, label: "Married" },
  { value: 1, label: "UnMarried" },
  { value: 2, label: "Not Disclosed" },
];

const bloodOptions = [
  { value: 0, label: "AB" },
  { value: 1, label: "O+" },
  { value: 2, label: "O-" },
  { value: 3, label: "A+" },
  { value: 4, label: "B+" },
  { value: 5, label: "A-" },
  { value: 6, label: "B-" },
];

export const selectStyle = {
  control: (provided, state) => ({
    ...provided,
    padding: "0rem 1rem",
    backgroundColor: "#2B2C47",
    color: "#C7C4E9",
    border: "5px",
    borderRadius: "5px",
    boxShadow: "none",
    minHeight: "3.6rem",
    width: "100%",

    marginTop: "1rem",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    color: "#9E9AD1",

    "&:hover": {
      backgroundColor: "#393b5f",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#9e9ad0",
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    width: "100%",
  }),
  input: (provided, state) => ({
    ...provided,
    color: "#fff",
    border: "none",
    width: "100%",
  }),
  multiValue: (provided, state) => ({
    ...provided,
    color: "#9e9ad0",
    backgroundColor: "#393b5f",
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: "#9e9ad0",
    backgroundColor: "#393b5f",
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    color: "#9e9ad0",
    backgroundColor: "#393b5f",
    ":hover": {
      backgroundColor: "#9e9ad0",
      color: "white",
    },
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#C7C4E9",

    "&:hover": {
      color: "#9E9AD1",
    },
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#9E9AD1",
    marginLeft: "0.5rem",
  }),
  indicatorSeparator: () => null,
};

export const ptStyle = {
  control: (provided, state) => ({
    ...provided,
    padding: "0rem 1rem",
    backgroundColor: "#353456",
    color: "#C7C4E9",
    border: "5px",
    borderRadius: "5px",
    boxShadow: "none",
    minHeight: "3.6rem",
    width: "100%",

    // marginTop: "1rem",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#353456",
    color: "#9E9AD1",

    "&:hover": {
      backgroundColor: "#393b5f",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#9e9ad0",
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#353456",
    width: "100%",
  }),
  input: (provided, state) => ({
    ...provided,
    color: "#fff",
    border: "none",
    width: "100%",
  }),
  multiValue: (provided, state) => ({
    ...provided,
    color: "#9e9ad0",
    backgroundColor: "#393b5f",
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: "#9e9ad0",
    backgroundColor: "#393b5f",
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    color: "#9e9ad0",
    backgroundColor: "#393b5f",
    ":hover": {
      backgroundColor: "#9e9ad0",
      color: "white",
    },
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#C7C4E9",

    "&:hover": {
      color: "#9E9AD1",
    },
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#9E9AD1",
    marginLeft: "0.5rem",
  }),
  indicatorSeparator: () => null,
};

const EditEmployee = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useSelector(({ location }) => location);
  const divisions = useSelector(({ divisions }) => divisions);
  const designations = useSelector(({ designations }) => designations);
  const department = useSelector(({ department }) => department);
  const theme = useSelector((state) => state.theme.theme);
  const state = useSelector((state) => state.states);
  const city = useSelector(({ city }) => city);

  const [editFields, seteditedFields] = useState({});

  const [accountName, setAccountName] = useState("");
  const [accountLastName, setAccountLastName] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [reEnterAccNo, setReEnterAccNo] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [accountType, setAccountType] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const loc = useLocation();
  const [initialResidentialAddress, setInitialResindentialAddress] = useState(
    loc?.state?.residentialAddress,
  );
  const [initialPaymentDetails, setInitialPaymentDetails] = useState(
    loc?.state?.paymentDetails,
  );
  const [residentialAddress, setResidentialAddress] = useState(
    loc?.state?.residentialAddress,
  );
  const [paymentDetails, setPaymentDetails] = useState(
    loc?.state?.paymentDetails,
  );
  const [addressData, setAddressData] = useState(null);
  const [officeAddress, setOfficeAddress] = useState(loc?.state?.address);
  const [isSubmited, setIsSubmited] = useState(false);
  const [statuatoryInfoShowState, setStatuatoryInfoShowState] = useState({
    epf: loc?.state?.hasEpfNumber,
    uan: loc?.state?.hasUanNumber,
    esi: loc?.state?.hasEsiNumber,
    pt: loc?.state?.hasPtDetails,
  });
  const [ptData, setPtData] = useState([]);

  useEffect(() => {
    setdata();
    getCompanyWorkingAddress({})
      .then(({ data }) => setAddressData(data))
      .catch((err) => {
        customToast.error("Server Error");
      });

    fetchPtDetails()
      .then((res) => {
        console.log(res.data[0]?.stateWiseInfo);
        setPtData(res.data[0]?.stateWiseInfo);
      })
      .catch(() => {
        return customToast.error("Error fetching pt data !");
      });
  }, []);
  const setdata = async () => {
    if (loc?.state) {
      setPaymentType(loc?.state?.paymentDetails?.paymentType);
      setAccountType(loc?.state?.paymentDetails?.accountType);
    }
  };
  const handleCheckboxChange = (option) => {
    if (paymentType === option) {
      setPaymentType("");
    } else {
      setPaymentType(option);
    }
  };

  const headquarterSelect = useMemo(
    () => generateSelectData(location.hq, "name"),
    [location.hq],
  );
  const defHeadquarter = useMemo(
    () =>
      generateSelectDefaultValue(
        headquarterSelect,
        loc?.state?.headquarter?.name,
      ),
    [headquarterSelect],
  );

  const departmentSelect = useMemo(
    () => generateSelectData(department.data, "name"),
    [department.data],
  );
  const defdepartment = useMemo(
    () =>
      generateSelectDefaultValue(
        departmentSelect,
        loc?.state?.department?.name,
      ),
    [departmentSelect],
  );

  const citySelect = useMemo(
    () => generateSelectData(city.data, "name"),
    [divisions.data],
  );
  const defCity = useMemo(
    () =>
      generateSelectDefaultValue(
        citySelect,
        loc?.state?.residentialAddress?.city?.name,
      ),
    [citySelect],
  );

  const stateSelect = useMemo(
    () => generateSelectData(state.data, "name"),
    [state.data],
  );
  const defState = useMemo(
    () =>
      generateSelectDefaultValue(
        stateSelect,
        loc?.state?.residentialAddress?.residentialState?.name,
      ),
    [stateSelect],
  );

  const designationSelect = useMemo(
    () => generateSelectData(designations.data, "name"),
    [designations.data],
  );
  const defDesignation = useMemo(
    () => generateSelectDefaultValue(designationSelect, "MR"),
    [designationSelect],
  );

  const defGender = useMemo(
    () => generateSelectDefaultValue(genderOptions, loc?.state?.gender.label),
    [genderOptions],
  );

  const defMaritalStatus = useMemo(
    () =>
      generateSelectDefaultValue(
        maritalOptions,
        loc?.state?.maritalStatus?.label,
      ),
    [maritalOptions],
  );

  const selectOfficeAddress = useMemo(() => {
    if (addressData?.length > 0) {
      return generateSelectData(addressData, "officeName");
    }
    return [];
  }, [addressData]);

  useEffect(() => {
    dispatch(setDivisions());
    dispatch(setDesignations());
    dispatch(setDepartments());
    dispatch(fetchStates("state"));
    dispatch(fetchCities());
  }, [dispatch]);

  const ptDetailsOption = useMemo(() => {
    const options = [];
    ptData?.forEach((pt) => {
      options.push({ label: pt?.state?.name, value: pt?.state?._id, ...pt });
    });
    return options;
  }, [ptData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const editedFields = {
      ...editFields,
    };

    if (!deepObjCompare(residentialAddress, initialResidentialAddress)) {
      const resAdd = { ...residentialAddress };
      editedFields.residentialAddress = resAdd;
    }
    if (!deepObjCompare(paymentDetails, initialPaymentDetails)) {
      const payDet = { ...paymentDetails };
      editedFields.paymentDetails = payDet;
    }
    if (!deepObjCompare(loc?.state.address, officeAddress)) {
      console.log("LOC-------------------->", loc, officeAddress);

      const add = { ...officeAddress };
      editedFields.address = add;
    }

    if (
      editedFields.firstName === "" ||
      editedFields.lastName === "" ||
      editedFields.phoneNumber === "" ||
      editedFields.email === "" ||
      editedFields.gender === "" ||
      editedFields.maritalStatus === "" ||
      editedFields.birthDate === "" ||
      editedFields.fatherName === "" ||
      editedFields.personalEmail === "" ||
      editedFields.joiningDate === "" ||
      editedFields.dateOfConfirmation === "" ||
      editedFields.headquarter === "" ||
      editedFields.des === "" ||
      editedFields.department === "" ||
      editedFields.street1 === "" ||
      editedFields.city === "" ||
      editedFields.state === "" ||
      editedFields.officeAddress === ""
    ) {
      return customToast.error("Please fill all the required fields !!");
    }
    if (
      editedFields?.panNumber?.length > 10 ||
      editedFields?.panNumber?.length < 10
    ) {
      customToast.error("PAN number must be 10 character alphanumeric");
      return;
    }
    if (
      editedFields?.aadharNumber?.length > 12 ||
      editedFields?.aadharNumber?.length < 12
    ) {
      customToast.error("Aadhaar number must be 12 character alphanumeric");
      return;
    }
    console.log(editFields, "editFields");
    if (editedFields?.paymentDetails?.paymentType === "Bank Transfer") {
      if (
        editedFields.paymentDetails.bankAccountNumber !==
        editedFields.paymentDetails.reEnterAccNo
      ) {
        customToast.error(
          "Account Number and Confirm Account Number must be same",
        );
        return;
      }
    }

    try {
      if (Object.keys(editedFields).length != 0) {
        const res = await editEmployee({ editedFields, id: loc?.state?._id });
        if (res.message === "OK") {
          dispatch(fetchEmployees());
          navigate("/employee/manage/employees-info");
          return customToast.success("Employee edited successfully");
        } else {
          customToast.error(
            "Account Number and confirm Account number not matched",
          );
        }
        return;
      } else {
        customToast.info("Nothing Change");
      }
    } catch (error) {
      // console.log(error)
      customToast.error("Something went wrong !!");
    }
  };

  const handleOfficeAddressChange = (e) => {
    console.log(e, "value");

    const fields = {
      addressLine1: "street1",
      addressLine2: "street2",
      city: "city",
      state: "state",
    };

    Object.keys(fields).forEach((key) => {
      if (e?.[key]) {
        handleChange(fields[key], e[key]?._id ?? e[key]);
      }
    });
  };

  const handleChange = (field, value) => {
    console.log(value, "value");
    console.log("before----->", field, value);
    if (
      field === "residentialCity" ||
      field === "residentialStreet1" ||
      field === "residentialStreet2" ||
      field === "residentialState"
    ) {
      setResidentialAddress((prevAddress) => ({
        ...prevAddress,
        [field]: value,
      }));
    } else if (
      field === "accountName" ||
      field === "accountLastName" ||
      field === "bankAccountNumber" ||
      field === "ifsc" ||
      field === "bankName" ||
      field === "reEnterAccNo" ||
      field === "accountType" ||
      field === "paymentType"
    ) {
      if (value === "Bank Transfer" || paymentType === "Bank Transfer") {
        setPaymentDetails((prevDetails) => ({
          ...prevDetails,
          [field]: value,
        }));
      } else if (paymentType === "Cheque") {
        setPaymentDetails({ paymentType: value });
      } else {
        setPaymentDetails({});
      }
    } else if (
      field === "street1" ||
      field === "street2" ||
      field === "city" ||
      field === "state"
    ) {
      setOfficeAddress((prevAddress) => ({ ...prevAddress, [field]: value }));
    } else {
      seteditedFields((prevFields) => ({
        ...prevFields,
        [field]: value,
      }));
    }
  };
  console.log(officeAddress, "officeAddress---------------->");
  return (
    <main className="main-content">
      <div className="area-creation-content">
        <PageTitle title="Edit Employee" route={".."} />

        <section className="admin-content">
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className={`subheading1`}>
              <span className="text-[15px]">Basic Details</span>
              <div className=" autofill_container">
                <input
                  type="text"
                  placeholder="Enter UID"
                  value={loc?.state?.uid}
                  onChange={({ target }) =>
                    handleChange("employeeId", target?.value)
                  }
                />
              </div>
            </div>

            <div className="flex flex-col gap-2 py-2">
              <div className="row1">
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <label>
                    Employee id <span className="asterisk-imp">*</span>
                  </label>
                  <br />
                  <input
                    type="text"
                    className="me-5 mt-4"
                    placeholder="Enter Employee Id"
                    value={loc?.state?.employeeId}
                    onChange={({ target }) =>
                      handleChange("employeeId", target?.value)
                    }
                  />
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <label>
                    First Name <span className="asterisk-imp">*</span>
                  </label>
                  <br />
                  <input
                    type="text"
                    className="me-5 mt-4"
                    placeholder="Enter First Name"
                    defaultValue={loc?.state?.firstName}
                    onChange={({ target }) =>
                      handleChange("firstName", target?.value)
                    }
                  />
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <label htmlFor="mName">Middle Name</label>
                  <br />
                  <input
                    type="text"
                    className="me-5 mt-4"
                    id="mName"
                    placeholder="Enter Middle Name"
                    defaultValue={loc?.state?.middleName || ""}
                    onChange={({ target }) =>
                      handleChange("middleName", target?.value)
                    }
                  />
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <label htmlFor="lName">
                    Last Name <span className="asterisk-imp">*</span>
                  </label>
                  <br />
                  <input
                    type="text"
                    className="me-5 mt-4"
                    id="lName"
                    placeholder="Enter last Name"
                    defaultValue={loc?.state?.lastName}
                    onChange={({ target }) =>
                      handleChange("lastName", target?.value)
                    }
                  />
                </div>
              </div>

              <div className="row1">
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <label>
                    Date of joining <span className="asterisk-imp">*</span>
                  </label>

                  <input
                    type="date"
                    placeholder="Select Date"
                    style={{
                      boxShadow: "none",
                      borderRadius: "0%",
                    }}
                    defaultValue={
                      loc?.state?.joiningDate
                        ? new Date(loc.state.joiningDate)
                            .toISOString()
                            .split("T")[0]
                        : ""
                    }
                    onChange={({ target }) =>
                      handleChange("joiningDate", target?.value)
                    }
                  />
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <label>
                    Date of Confirmation <span className="asterisk-imp">*</span>
                  </label>
                  <br />
                  <input
                    type="date"
                    style={{
                      boxShadow: "none",
                      borderRadius: "0%",
                    }}
                    placeholder="Select Date"
                    defaultValue={
                      loc?.state?.dateOfConfirmation
                        ? new Date(loc.state.dateOfConfirmation)
                            .toISOString()
                            .split("T")[0]
                        : ""
                    }
                    onChange={({ target }) =>
                      handleChange("dateOfConfirmation", target?.value)
                    }
                  />
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <label>
                    Designation<span className="asterisk-imp">*</span>
                  </label>
                  <br />
                  <Select
                    className="me-5 mt-4 w-full"
                    isSearchable={false}
                    options={designationSelect}
                    defaultValue={defDesignation}
                    styles={theme == "light" ? adminStylesLight : adminStyles}
                    onChange={(e) => handleChange("designation", { ...e })}
                  />
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <label>Gender</label>
                  <br />
                  <Select
                    isSearchable={false}
                    options={genderOptions}
                    styles={theme == "light" ? adminStylesLight : selectStyle}
                    className="me-5 mt-4 w-full"
                    defaultValue={defGender}
                    onChange={(e) => handleChange("gender", { ...e })}
                  />
                </div>
              </div>

              <div className="row1">
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <label>
                    Email<span className="asterisk-imp">*</span>
                  </label>
                  <br />
                  <input
                    type="text"
                    className="me-5 mt-4"
                    placeholder="Enter Email"
                    defaultValue={loc?.state?.email}
                    onChange={({ target }) =>
                      handleChange("email", target?.value)
                    }
                  />
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <label>Phone Number</label>
                  <br />
                  <input
                    type="text"
                    className="me-5 mt-4"
                    placeholder="Enter Number"
                    defaultValue={loc?.state?.phoneNumber}
                    style={{ textTransform: "lowercase" }}
                    onChange={({ target }) =>
                      handleChange("phoneNumber", target?.value)
                    }
                  />
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <label>Headquarter</label>
                  <br />
                  <Select
                    isSearchable={false}
                    options={headquarterSelect}
                    styles={theme == "light" ? adminStylesLight : selectStyle}
                    className="me-5 mt-4 w-full"
                    defaultValue={defHeadquarter}
                    onChange={(e) => handleChange("headquarter", { ...e })}
                  />
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <label>Department</label>
                  <br />
                  <Select
                    isSearchable={false}
                    options={departmentSelect}
                    styles={theme == "light" ? adminStylesLight : selectStyle}
                    className="me-5 mt-4 w-full"
                    defaultValue={defdepartment}
                    onChange={(e) => handleChange("department", { ...e })}
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col">
              <Accordion
                defaultState={true}
                title="Work Location"
                content={
                  <div className="area-creation-content_form py-0 my-0">
                    <div className="row1">
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        <label>
                          Select Office Name{" "}
                          <span className="asterisk-imp">*</span>
                        </label>
                        <br />
                        <div
                          className="mt-4"
                          style={
                            isSubmited && !officeAddress
                              ? { border: "solid red 2px" }
                              : {}
                          }
                        >
                          <Select
                            isSearchable={true}
                            options={selectOfficeAddress}
                            styles={
                              theme == "light" ? adminStylesLight : selectStyle
                            }
                            // onChange={(e) => handleChange("workState", { ...e })}
                            onChange={(e) => {
                              handleOfficeAddressChange({ ...e });
                              // handleChange("officeAddress", { ...e });
                            }}
                            className="w-full"
                          />
                        </div>
                      </div>

                      {/* <div className="col-sm-12 col-md-4 col-lg-4 flex items-center justify-end capitalize font-semibold">
                        {`${formData?.street1 ? formData?.street1 : ""} ${
                          formData?.street2 ? formData?.street2 : ""
                        }`}
                      </div>

                      <div className="col-sm-12 col-md-4 col-lg-4 flex items-center justify-start capitalize font-semibold">
                        {` ${
                          formData?.state?.name ? formData?.state?.name : ""
                        } ${formData?.city?.name ? formData?.city?.name : ""}`}
                      </div> */}
                    </div>
                  </div>
                }
              />
            </div>

            <div className="flex flex-col">
              <Accordion
                defaultState={true}
                title="Residential Address"
                content={
                  <div className="area-creation-content_form">
                    <div className="row1 py-2">
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        <label>street Address 1</label>
                        <br />
                        <input
                          type="text"
                          className="me-5 mt-4"
                          placeholder="Enter Street Address "
                          defaultValue={
                            loc?.state?.residentialAddress?.residentialStreet1
                          }
                          onChange={({ target }) =>
                            handleChange("residentialStreet1", target?.value)
                          }
                        />
                      </div>
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        <label>street Address 2</label>
                        <br />
                        <input
                          type="text"
                          className="me-5 mt-4"
                          placeholder="Enter Street Address "
                          defaultValue={
                            loc?.state?.residentialAddress?.residentialStreet2
                          }
                          onChange={({ target }) =>
                            handleChange("residentialStreet2", target?.value)
                          }
                        />
                      </div>
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        <label>state</label>
                        <br />
                        <input
                          type="text"
                          className="me-5 mt-4"
                          placeholder="Enter State "
                          defaultValue={
                            loc?.state?.residentialAddress?.residentialState
                          }
                          onChange={({ target }) =>
                            handleChange("residentialState", target?.value)
                          }
                        />
                      </div>
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        <label>City</label>
                        <br />
                        <input
                          type="text"
                          className="me-5 mt-4"
                          placeholder="Enter City "
                          defaultValue={
                            loc?.state?.residentialAddress?.residentialCity
                          }
                          onChange={({ target }) =>
                            handleChange("residentialCity", target?.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                }
              />

              <Accordion
                defaultState={true}
                title="Personal Info"
                content={
                  <div className="area-creation-content_form">
                    <div className="row1 py-2">
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        <label>Date of birth</label>
                        <br />
                        <input
                          type="date"
                          className="me-5 mt-4"
                          placeholder="Select Date"
                          defaultValue={
                            loc?.state?.birthDate
                              ? new Date(loc.state.birthDate)
                                  .toISOString()
                                  .split("T")[0]
                              : ""
                          }
                          onChange={({ target }) =>
                            handleChange("birthDate", target?.value)
                          }
                        />
                      </div>
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        <label>
                          Pan Number<span className="asterisk-imp">*</span>
                        </label>
                        <br />
                        <input
                          type="text"
                          className="me-5 mt-4"
                          placeholder="Enter PAN Number"
                          defaultValue={loc?.state?.panDetails?.panNumber}
                          onChange={({ target }) => {
                            handleChange("panNumber", target?.value);
                          }}
                        />
                      </div>
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        <label>
                          Aadhar Number <span className="asterisk-imp">*</span>
                        </label>
                        <br />
                        <input
                          type="text"
                          className="me-5 mt-4"
                          placeholder="Enter Aadhar Number"
                          defaultValue={loc?.state?.aadharDetails?.aadharNumber}
                          onChange={({ target }) =>
                            handleChange("aadharNumber", target?.value)
                          }
                        />
                      </div>
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        <label>Marital Status</label>
                        <br />
                        <Select
                          options={maritalOptions}
                          styles={
                            theme == "light" ? adminStylesLight : selectStyle
                          }
                          className="me-5 mt-4"
                          defaultValue={defMaritalStatus}
                          onChange={(e) =>
                            handleChange("maritalStatus", { ...e })
                          }
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        <label>
                          Father's Name <span className="asterisk-imp">*</span>
                        </label>
                        <br />
                        <input
                          type="text"
                          className="me-5 mt-4"
                          placeholder="Enter Father Name"
                          defaultValue={loc?.state?.fatherName}
                          onChange={({ target }) =>
                            handleChange("fatherName", target?.value)
                          }
                        />
                      </div>
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        <label>
                          Personal Email Address{" "}
                          <span className="asterisk-imp">*</span>
                        </label>
                        <br />
                        <input
                          type="text"
                          className="me-5 mt-4"
                          placeholder="Enter Personal Email"
                          defaultValue={loc?.state?.personalEmail}
                          onChange={({ target }) =>
                            handleChange("personalEmail", target?.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                }
              />
              <Accordion
                defaultState={true}
                title="Statuatory Info"
                content={
                  <div className="area-creation-content_form">
                    <div className="flex flex-wrap justify-between my-4 mx-8 py-2 gap-y-4">
                      <div className="w-[48%] bg-primary px-4 rounded-lg h-fit">
                        <div className="flex items-center justify-between w-full ">
                          <label
                            className="mt-6 cursor-pointer"
                            htmlFor="statu_opt-1"
                          >
                            Does this employee have EPF Number
                          </label>
                          <input
                            type="checkbox"
                            name=""
                            id="statu_opt-1"
                            className="check-box green_b"
                            onChange={(e) =>
                              setStatuatoryInfoShowState((pre) => ({
                                ...pre,
                                epf: e.target.checked,
                              }))
                            }
                            checked={statuatoryInfoShowState.epf}
                          />
                        </div>
                        {statuatoryInfoShowState.epf && (
                          <div
                            className={`${
                              statuatoryInfoShowState.epf ? "initial" : "hidden"
                            } slider`}
                          >
                            <label>EPF Number</label>
                            <br />
                            <input
                              type="text"
                              max={22}
                              className="me-5 mt-4"
                              placeholder="Enter EPF Number"
                              defaultValue={loc.state.epfNumber}
                              onChange={({ target }) => {
                                target?.value?.length > 22
                                  ? customToast.error(
                                      "EPF number must be 22 character alphanumeric",
                                    )
                                  : handleChange("epfNumber", target?.value);
                              }}
                            />
                          </div>
                        )}
                      </div>
                      <div className="w-[48%] bg-primary px-4 rounded-lg h-fit">
                        <div
                          className="flex items-center justify-between w-full cursor-pointer"
                          onClick={(e) =>
                            setStatuatoryInfoShowState((pre) => ({
                              ...pre,
                              uan: e.target.checked,
                            }))
                          }
                        >
                          <label className="mt-6" htmlFor="statu_opt-2">
                            Does this employee have UAN Number
                          </label>
                          <input
                            type="checkbox"
                            name=""
                            id="statu_opt-2"
                            className="check-box green_b"
                            checked={statuatoryInfoShowState.uan}
                          />
                        </div>
                        {statuatoryInfoShowState.uan && (
                          <div
                            className={`${
                              statuatoryInfoShowState.uan ? "initial" : "hidden"
                            } slider`}
                          >
                            <label>Uan number</label>
                            <br />
                            <input
                              type="text"
                              className="me-5 mt-4"
                              max={12}
                              placeholder="Enter UAN Number"
                              defaultValue={loc.state.uanNumber}
                              onChange={({ target }) => {
                                target?.value?.length > 12
                                  ? customToast.error(
                                      "UAN number must be 12 character alphanumeric",
                                    )
                                  : handleChange("uanNumber", target?.value);
                              }}
                            />
                          </div>
                        )}
                      </div>
                      <div className="w-[48%] bg-primary px-4 rounded-lg h-fit">
                        <div
                          className="flex items-center justify-between w-full"
                          onClick={(e) =>
                            setStatuatoryInfoShowState((pre) => ({
                              ...pre,
                              esi: e.target.checked,
                            }))
                          }
                        >
                          <label
                            className="mt-6 cursor-pointer"
                            htmlFor="statu_opt-3"
                          >
                            Does this employee have ESI Number
                          </label>
                          <input
                            type="checkbox"
                            name=""
                            id="statu_opt-3"
                            className="check-box green_b"
                            checked={statuatoryInfoShowState.esi}
                          />
                        </div>
                        {statuatoryInfoShowState.esi && (
                          <div className="slider">
                            <label>ESI Number</label>
                            <br />
                            <input
                              type="text"
                              max={17}
                              className="me-5 mt-4"
                              placeholder="Enter ESI Number"
                              defaultValue={loc.state.esiNumber}
                              onChange={({ target }) => {
                                target?.value?.length > 17
                                  ? customToast.error(
                                      "UAN number must be 12 character alphanumeric",
                                    )
                                  : handleChange("esiNumber", target?.value);
                              }}
                            />
                          </div>
                        )}
                      </div>
                      <div className="w-[48%] bg-primary px-4 rounded-lg h-fit">
                        <div
                          className="flex items-center justify-between w-full "
                          onClick={(e) =>
                            setStatuatoryInfoShowState((pre) => ({
                              ...pre,
                              pt: e.target.checked,
                            }))
                          }
                        >
                          <label
                            className="mt-6 cursor-pointer"
                            htmlFor="statu_opt-4"
                          >
                            Does this employee have Professional tax
                          </label>
                          <input
                            type="checkbox"
                            name=""
                            id="statu_opt-4"
                            className="check-box green_b"
                            checked={statuatoryInfoShowState.pt}
                          />
                        </div>
                        {statuatoryInfoShowState.pt && (
                          <div className="mb-4">
                            <label>State</label>
                            <br />
                            <Select
                              className="me-5  w-full"
                              isSearchable={false}
                              options={ptDetailsOption}
                              styles={ptStyle}
                              defaultValue={loc.state.ptDetails}
                              onChange={(e) =>
                                handleChange("ptDetails", { ...e })
                              }
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                }
              />

              <Accordion
                defaultState={true}
                title="Payment Details"
                content={
                  <div>
                    <div className={`subheading1`}>
                      <span className="text-2xl">
                        Bank Transfer
                        <span className="ml-4 text-lg text-green-600">
                          (Manual Payment Process)
                        </span>
                      </span>
                      <div className="flex gap-4 items-center">
                        <input
                          type="checkbox"
                          className="check-box green_b"
                          checked={paymentType == "Bank Transfer"}
                          onChange={() => {
                            handleCheckboxChange("Bank Transfer");
                            handleChange("paymentType", "Bank Transfer");
                          }}
                        />
                      </div>
                    </div>

                    {paymentType === "Bank Transfer" && (
                      <div className="flex flex-col smallbdr">
                        <div className="row">
                          <div className="col-sm-12 col-md-4 col-lg-4">
                            <div className="flex w-fit gap-4 !h-4 items-center mb-2">
                              <input
                                type="checkbox"
                                checked={accountType == "savings"}
                                className="check-box-active blue_b padding-checkbox"
                                onChange={() => {
                                  setAccountType("savings");
                                  handleChange("accountType", "savings");
                                }}
                              />
                              <label className="mt-10">Saving Account</label>
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4 col-lg-4">
                            <div className="flex w-fit gap-4 !h-4 items-center mb-2">
                              <input
                                type="checkbox"
                                checked={accountType === "current"}
                                className="check-box-active blue_b padding-checkbox"
                                onChange={() => {
                                  setAccountType("current");
                                  handleChange("accountType", "current");
                                }}
                              />
                              <label className="mt-10">Current Account</label>
                            </div>
                          </div>
                        </div>

                        {/* {accountType.length > 0 && */}
                        <div>
                          <div className="row">
                            <div className="col-sm-12 col-md-4 col-lg-4">
                              <label>
                                Account Name{" "}
                                <span className="asterisk-imp">*</span>
                              </label>
                              <br />
                              <input
                                type="text"
                                className="me-5 mt-4"
                                placeholder="Enter Account Name"
                                defaultValue={
                                  loc?.state?.paymentDetails?.accountName
                                }
                                onChange={({ target }) =>
                                  handleChange("accountName", target?.value)
                                }
                              />
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4">
                              <label>
                                Account Last Name{" "}
                                <span className="asterisk-imp">*</span>
                              </label>
                              <br />
                              <input
                                type="text"
                                className="me-5 mt-4"
                                placeholder="Enter Account Last Name"
                                defaultValue={
                                  loc?.state?.paymentDetails?.accountLastName
                                }
                                onChange={({ target }) =>
                                  handleChange("accountLastName", target?.value)
                                }
                              />
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4">
                              <label>
                                Bank Name{" "}
                                <span className="asterisk-imp">*</span>
                              </label>
                              <br />
                              <input
                                type="text"
                                className="me-5 mt-4"
                                placeholder="Enter Bank Name"
                                defaultValue={
                                  loc?.state?.paymentDetails?.bankName
                                }
                                onChange={({ target }) =>
                                  handleChange("bankName", target?.value)
                                }
                              />
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4">
                              <label>
                                Ifsc code{" "}
                                <span className="asterisk-imp">*</span>
                              </label>
                              <br />
                              <input
                                type="text"
                                className="me-5 mt-4"
                                placeholder="Enter IFSC"
                                defaultValue={loc?.state?.paymentDetails?.ifsc}
                                onChange={({ target }) =>
                                  handleChange("ifsc", target?.value)
                                }
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-12 col-md-4 col-lg-4">
                              <label>
                                Bank Account Number{" "}
                                <span className="asterisk-imp">*</span>
                              </label>
                              <br />
                              <input
                                type="text"
                                className="me-5 mt-4"
                                placeholder="Enter Account Number"
                                defaultValue={
                                  loc?.state?.paymentDetails?.bankAccountNumber
                                }
                                onChange={({ target }) =>
                                  handleChange(
                                    "bankAccountNumber",
                                    target?.value,
                                  )
                                }
                              />
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4">
                              <label>
                                Re-Enter Account Number{" "}
                                <span className="asterisk-imp">*</span>
                              </label>
                              <br />
                              <input
                                type="text"
                                className="me-5 mt-4 "
                                placeholder="Enter Account Number"
                                defaultValue={
                                  loc?.state?.paymentDetails?.reEnterAccNo
                                }
                                onChange={({ target }) =>
                                  handleChange("reEnterAccNo", target?.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                        {/* } */}
                      </div>
                    )}

                    <div className={`subheading1`}>
                      <span className="text-2xl">Cheque</span>
                      <input
                        type="checkbox"
                        className="check-box green_b"
                        checked={paymentType === "Cheque"}
                        onChange={() => {
                          handleCheckboxChange("Cheque");
                          handleChange("paymentType", "Cheque");
                        }}
                      />
                    </div>
                  </div>
                }
              />
            </div>

            <div className="bg-[#25253D] py-6 col-sm-2 col-md-4 col-lg-2">
              <button type="submit" className="button-blue-gradient">
                Submit
              </button>
            </div>
          </form>
        </section>
      </div>
    </main>
  );
};

export default EditEmployee;
