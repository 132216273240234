import {useCallback, useEffect, useMemo, useState } from "react";
import PageTitle from "../../../../components/PageTitle";
import { others , calcType} from "./listData";
import { adminStyles, selectStyles } from "../../../../utils/selectStyles";
import Select, {components} from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { FaRegCircle } from "react-icons/fa";
import { GrRadialSelected } from "react-icons/gr";
import { GetEarningTypes, PostEarning } from "../../../../services/earning";
import {MdOutlineCheckBoxOutlineBlank} from 'react-icons/md' ;
import { IoCheckbox } from "react-icons/io5";
import customToast from "../../../../components/CustomToast";
import { generateSelectData } from "../../../../utils/serialiseResponse";
import { useNavigate } from "react-router-dom";
import { BiSolidCheckbox } from "react-icons/bi";
import { IoAddOutline } from "react-icons/io5";
import Modal from "../../../../components/Modal";
import { fetchEarningTypes, postEarningType } from "../../../../reducers/earningTypesReducer";

const AddComponent = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const earnData = useSelector(({earningTypes}) => earningTypes.data);
  const theme = useSelector((state) => state.theme.theme);
  const [eType , setEtype] = useState("");
  const [eName , setEname] = useState("");
  const [nPayslip , setnPayslip] = useState("");
  const [amount , setAmount] = useState("");
  const [paytype, setPaytype] = useState("fixed");
  const [calcType, setCalcType] = useState({type : "percentage" , value : "basic"});
  const [taxable, setTaxable] = useState(false);
  const [proRata, setProRata] = useState(false);
  const [addToSalary, setAddToSalary] = useState(false);
  const [isEpf , setIsEpf] = useState(false);
  const [isEsi, setIsEsi] = useState(false);
  const [onPaySlip, setOnPaySlip] = useState(false);
  const [active, setActive] = useState(false);
  const [flexible, setFlexible] = useState(false);
  const [taxDeductionType , setTaxDeductionType] = useState("financialYear");
  const [showModal, setShowModal] = useState(false);
  const [newEarnType, setNewEarnType] = useState(null);
  
  useEffect(() => {
    dispatch(fetchEarningTypes());
  },[]);
  
  const earnSelect = useMemo(()=>{
    
    let options =  generateSelectData(earnData, "name");
    options.push({ label: "Add Other", value: "Add Other", icon:<IoAddOutline/>})
    return options;
  },[earnData]);


  const resetStates = () => {
    setActive(false);
    setFlexible(false);
    setIsEpf(false);
    setIsEsi(false);
    setProRata(false);
    setOnPaySlip(false);
    setAddToSalary(false);
    setTaxable(false);
    setCalcType(calcType);
    setPaytype("fixed");
    setAmount("");
    setEname("");
    setnPayslip("");
    setEtype(null);
  }

  const handleSubmit = async(e)=>{
    e.preventDefault();
    console.log(eName, eType, onPaySlip)
    if(!eName || !eType || !nPayslip){
     return customToast.error("Please Fill All Fields")
    }

    const payload = {
      active,
      flexible,
      isEpf,
      isEsi,
      proRata,
      onPaySlip,
      addToSalary,
      taxable,
      calcType,
      paytype,
      amount,
      eName,
      nPayslip,
      earningType: eType?._id,
      earningTypeName: eType?.name,
      isActive:active,
    }
    if(paytype == "variable" && taxDeductionType){
      payload.taxDeductionType = taxDeductionType,
      payload.calcType = {
         type : 'fixed',
         value : 'fixed',
      }
    }
    PostEarning(payload)
    .then(()=> {
      navigate("..")
      resetStates();
      customToast.success("Earning Created Successfully")})
    .catch(({response})=>{console.log(response.status) ;customToast.error("Sorry Something Went Wrong!")})
  }

  const handleEarning = (e) => {
    // console.log(e.name , "name")
     if(e.name === 'Basic'){
      setCalcType({type : "percentage" , value : "ctc"}),
      setTaxable(true)
      setProRata(true)
      setAddToSalary(true)
      setIsEpf(true)
      setIsEsi(true)
      setOnPaySlip(true)
      setActive(true)
      setFlexible(true)
     }
    else if(e.name == 'HRA'){
      setCalcType({type : "percentage" , value : "basic"}),
      setTaxable(true);
      setProRata(false);
      setAddToSalary(true);
      setIsEpf(false);
      setIsEsi(false);
      setOnPaySlip(false);
      setActive(true);
      setFlexible(false);
     }
    else if(e.name == 'Fixed Allowance'){
      setCalcType({type : "SystemGenerated" , value : "SystemGenerated"}),
      setTaxable(true);
      setProRata(true);
      setAddToSalary(true);
      setIsEpf(false);
      setIsEsi(true);
      setOnPaySlip(true);
      setActive(true);
      setFlexible(true);
    }
    else if(e.name == 'One-Time Earning'){
      setCalcType({type : "percentage" , value : "ctc"}),
      setTaxable(false);
      setProRata(false);
      setAddToSalary(false);
      setIsEpf(false);
      setIsEsi(false);
      setOnPaySlip(false);
      setActive(false);
      setFlexible(false);
    }
     else{
      setTaxable(false);
      setProRata(false);
      setAddToSalary(false);
      setIsEpf(false);
      setIsEsi(false);
      setOnPaySlip(false);
      setActive(false);
      setFlexible(false);
    }
  }

  const handleEarningTypes = async(e) =>{
    e.preventDefault();

    try {
      let payload = {
        earningType: newEarnType
      };
      dispatch(postEarningType([payload]))
      setShowModal(false);
      setNewEarnType(null);
      customToast.success("New Earning type created !");
    } catch (error) {
      console.log(error);
      console.log("Error adding new type !");
    }
  } 

  return (

    <div
      className={"main-content " + `${theme == "light" ? "light_bg_main" : ""}`}
      style={{ width: "100%" }}
    >
      <div
        className={
          "creation-content " + `${theme == "light" ? "light_bg" : ""}`
        }
      >
        <PageTitle title="Add New Earning" route={'..'} />

        <section className="admin-content pb-10">
          <div className="row">
            <div>
              <label>Earning Type<span className="text-error">*</span></label>
              <br />
              <Select
                options={earnSelect}
                styles={adminStyles}
                value={eType}
                onChange={(e) => { 
                  e?.label === "Add Other" ? 
                  setShowModal(true)
                  :
                  handleEarning({ ...e }); setEtype({ ...e }) 
                }}
                className="mt-4"
                placeholder="Select Earning Type"
                components={{
                  Option:
                    ({ data, ...props }) => (
                      <components.Option {...props}>
                        <div className='flex gap-2 items-center'>
                          <span>{data.label}</span>
                          <div>
                            {data.icon}
                          </div>
                        </div>
                      </components.Option>
                    )
                }}
              />
            </div>

            <div>
              <label>Earning Name <span className="text-error">*</span></label><br />
              <input
                id="eName"
                type="text"
                placeholder="Enter Earning Name"
                className="dark:bg-primary dark:text-secondary outline-none border-none rounded-lg mt-4"
                value={eName}
                onChange={({ target }) => setEname(target?.value)}
              />
            </div>

            <div>
              <label>Name on Payslip <span className="text-error">*</span></label>
              <br />
              <input
                id="eName"
                type="text"
                placeholder="Enter Name on Payslip"
                className="dark:bg-primary dark:text-secondary outline-none border-none rounded-lg mt-4"
                value={nPayslip}
                onChange={({ target }) => setnPayslip(target?.value)}
              />
            </div>
          </div>

          <div className="mx-8">
            {eType?.name !== "Fixed Allowance" ?
              <>
                <div className="mt-8">
                  <label>Pay Type <span className="text-error">*</span></label>
                  <div className="flex justify-start gap-8 items-center mt-2">
                    <div>
                      {paytype === 'variable'
                        ? <FaRegCircle
                          className="text-tertiary inline-block cursor-pointer"
                          onClick={() => setPaytype("fixed")}
                        />
                        : <GrRadialSelected
                          className="text-tertiary inline-block"
                        />
                      }
                      <label className="ml-3">Fixed Amount</label><br />
                      <span className="text-secondary text-sm">(Fixed amount paid at the month end)</span>
                    </div>
                    <div>
                      {paytype === 'fixed'
                        ? <FaRegCircle
                          className="text-tertiary inline-block cursor-pointer"
                          onClick={() => { setPaytype("variable"); setCalcType(calcType); setAmount(0) }}
                        />
                        : <GrRadialSelected
                          className="text-tertiary inline-block"
                        />
                      }
                      <label className="ml-3">Variable Amount</label> <br />
                      <span className="text-secondary text-sm">(Variable amount paid during payroll)</span>
                    </div>
                  </div>
                </div>

                <div className="mt-8 ">
                  {paytype == 'variable' ?
                    <>
                      <label>Tax Deduction Type<span className="text-error">*</span></label>
                      <div className="flex justify-start gap-8 items-center mt-2">
                        <div>
                          {taxDeductionType === 'month'
                            ? <FaRegCircle
                              className="text-tertiary inline-block cursor-pointer"
                              onClick={() => setTaxDeductionType("financialYear")}
                            />
                            : <GrRadialSelected
                              className="text-tertiary inline-block"
                            />
                          }
                          <label className="ml-3">Deduct Tax In Following Financial Year</label><br />
                        </div>
                        <div>
                          {taxDeductionType === 'financialYear'
                            ? <FaRegCircle
                              className="text-tertiary inline-block cursor-pointer"
                              onClick={() => setTaxDeductionType("month")}
                            />
                            : <GrRadialSelected
                              className="text-tertiary inline-block"
                            />
                          }
                          <label className="ml-3">Deduct In The Same Month</label> <br />
                        </div>
                      </div>
                    </>
                    :
                    <>
                      <label>Calculation Type<span className="text-error">*</span></label>
                      <div className="flex justify-start gap-8 items-center mt-2">
                        <div>
                          {calcType.type === 'percentage'
                            ? <FaRegCircle
                              className="text-tertiary inline-block cursor-pointer"
                              onClick={() => setCalcType({ type: "fixed", value: "fixed" })}
                            />
                            : <GrRadialSelected
                              className="text-tertiary inline-block"
                            />
                          }
                          <label className="ml-3">Flat Amount</label><br />
                        </div>
                        <div>
                          {calcType.type === 'fixed'
                            ? <FaRegCircle
                              className="text-tertiary inline-block cursor-pointer"
                              onClick={() => { eType?.name == 'Basic' ? setCalcType({ type: "percentage", value: "ctc" }) : setCalcType({ type: "percentage", value: "basic" }) }}
                            />
                            : <GrRadialSelected
                              className="text-tertiary inline-block"
                            />
                          }
                          {eType?.name == 'Basic' ? <label className="ml-3">Percentage of CTC</label> : <label className="ml-3">Percentage of Basic</label>} <br />
                        </div>
                      </div>
                    </>
                  }
                </div>
                {paytype == 'variable' ?
                  null :
                  <div className="mt-6 w- w-96">
                    <label className="text-3xl uppercase ml-1">{calcType === 'percentage' ? "Percentage %" : "Amount"}<span className="text-error">*</span></label><br />
                    <input
                      id="eName"
                      type="number"
                      placeholder={calcType === 'percentage' ? "Enter Percentage %" : "Enter Amount"}
                      value={amount}
                      className="mt-4"
                      onChange={({ target }) => setAmount(target?.value)}
                    />
                  </div>
                }
              </>
              :
              null
            }

            <div className="mt-10">
              <label className="show-entries">Other Configurations</label>
              <br />

              {eType?.name === 'Basic' &&
                <div className="flex flex-col gap-1 mt-4">
                  <label className="text-3xl font-medium">
                    {addToSalary
                      ?
                      <IoCheckbox
                        className="inline-block text-4xl me-4 cursor-pointer text-gray-400"
                      />
                      :
                      null
                    }
                    Add to Employee's Salary Structure
                  </label>

                  {paytype == 'variable' ?
                    null :
                    <div className="flex flex-col gap-4">
                      <div className="text-3xl font-medium">
                        {taxable
                          ? <IoCheckbox
                            className="text-gray-400 inline-block text-4xl me-4 cursor-pointer"
                          />
                          :
                          null
                        }
                        <label>Taxable Earning</label><br />
                        <div className="text-secondary text-sm">(The Income Tax amount will be divided equally and deducted every month across Financial year )</div>
                      </div>
                      <div className="text-3xl font-medium mb-3">
                        {proRata
                          ? <IoCheckbox
                            className="text-gray-400 inline-block text-4xl me-4 cursor-pointer"

                          />
                          :
                          null
                        }
                        <label>Calculate on Pro-Rata Basis</label><br />
                        <div className="text-secondary text-sm">(Pay will be adjusted based on employees working days )</div>
                      </div>
                    </div>
                  }

                  <label className="text-3xl font-medium">
                    {flexible
                      ? <IoCheckbox
                        className="text-gray-400 inline-block text-4xl me-4 cursor-pointer"
                      />
                      :
                      null
                    }
                    Add as a Flexible Benefit Plan Component
                  </label>

                  <label className="text-3xl font-medium">
                    {isEpf
                      ? <IoCheckbox
                        className="text-gray-400 inline-block text-4xl me-4 cursor-pointer"
                      />
                      :
                      null
                    }
                    Consider for EPF Calculation
                  </label>

                  <label className="text-3xl font-medium">
                    {isEsi
                      ? <IoCheckbox
                        className="text-gray-400 inline-block text-4xl me-4 cursor-pointer"
                      />
                      : null
                    }
                    Consider for ESI Calculation
                  </label>

                  <label className="text-3xl font-medium">
                    {onPaySlip
                      ? <IoCheckbox
                        className="text-gray-400 inline-block text-4xl me-4 cursor-pointer"
                      />
                      :
                      null
                    }
                    Show this Component on Payslip
                  </label>

                  <label className="text-3xl font-medium">
                    {active
                      ? <IoCheckbox
                        className="text-gray-400 inline-block text-4xl me-4 cursor-pointer"
                      />
                      :
                      null
                    }
                    Mark this as Active
                  </label>
                </div>
              }

              {eType?.name === 'One-Time Earning' &&
                <div className="flex flex-col gap-1 mt-4">
                  <label className="text-3xl font-medium">
                    {addToSalary
                      ? <IoCheckbox
                      className="text-success inline-block text-4xl me-4 cursor-pointer"
                    />
                    :
                    <MdOutlineCheckBoxOutlineBlank
                      className="text-success inline-block text-4xl me-4 cursor-pointer"
                      onClick={() => setAddToSalary(true)}
                    />
                    }
                    Add to Employee's Salary Structure
                  </label>

                  {paytype == 'variable' ?
                    null :
                    <div className="flex flex-col gap-4">
                      <div className="text-3xl font-medium">
                        {taxable
                          ? <IoCheckbox
                          className="text-success inline-block text-4xl me-4 cursor-pointer"
                        />
                        :
                        <MdOutlineCheckBoxOutlineBlank
                          className="text-success inline-block text-4xl me-4 cursor-pointer"
                          onClick={() => setTaxable(true)}
                        />
                        }
                        <label>Taxable Earning</label><br />
                        <div className="text-secondary text-sm">(The Income Tax amount will be divided equally and deducted every month across Financial year )</div>
                      </div>
                      <div className="text-3xl font-medium mb-3">
                        {proRata === false
                          ? <BiSolidCheckbox
                          size={25}
                            className="text-gray-400 inline-block text-4xl me-4 cursor-not-allowed"

                          />
                          :
                          null
                        }
                        <label>Calculate on Pro-Rata Basis</label><br />
                        <div className="text-secondary text-sm">(Pay will be adjusted based on employees working days )</div>
                      </div>
                    </div>
                  }

                  <label className="text-3xl font-medium">
                    {flexible === false
                          ? <BiSolidCheckbox
                          size={25}
                            className="text-gray-400 inline-block text-4xl me-4 cursor-not-allowed"

                          />
                          :
                          null
                    }
                    Add as a Flexible Benefit Plan Component
                  </label>

                  <label className="text-3xl font-medium">
                    {isEpf === false
                          ? <BiSolidCheckbox
                          size={25}
                            className="text-gray-400 inline-block text-4xl me-4 cursor-not-allowed"

                          />
                          :
                          null
                    }
                    Consider for EPF Calculation
                  </label>

                  <label className="text-3xl font-medium">
                    {isEsi === false
                          ? <BiSolidCheckbox
                          size={25}
                            className="text-gray-400 inline-block text-4xl me-4 cursor-not-allowed"

                          />
                          :
                          null
                    }
                    Consider for ESI Calculation
                  </label>

                  <label className="text-3xl font-medium">
                    {onPaySlip
                      ? <IoCheckbox
                      className="text-success inline-block text-4xl me-4 cursor-pointer"
                    />
                    :
                    <MdOutlineCheckBoxOutlineBlank
                      className="text-success inline-block text-4xl me-4 cursor-pointer"
                      onClick={() => setOnPaySlip(true)}
                    />
                    }
                    Show this Component on Payslip
                  </label>

                  <label className="text-3xl font-medium">
                    {active
                      ? <IoCheckbox
                      className="text-success inline-block text-4xl me-4 cursor-pointer"
                    />
                    :
                    <MdOutlineCheckBoxOutlineBlank
                      className="text-success inline-block text-4xl me-4 cursor-pointer"
                      onClick={() => setActive(true)}
                    />
                    }
                    Mark this as Active
                  </label>
                </div>
              }

              {eType?.name === 'House Rent Allowance' &&
                <div className="flex flex-col gap-1 mt-4">

                  <label className="text-3xl font-medium">
                    {addToSalary
                      ?
                      <IoCheckbox
                        className="inline-block text-4xl me-4 cursor-pointer text-gray-400"
                      />
                      :
                      null
                    }
                    Add to Employee's Salary Structure
                  </label>

                  {paytype == 'variable' ?
                    null :
                    <div className="flex flex-col gap-4">
                      <div className="text-3xl font-medium ">
                        {taxable
                          ? <IoCheckbox
                            className="text-gray-400 inline-block text-4xl me-4 cursor-pointer"
                          />
                          :
                          null
                        }
                        <div className="flex flex-col">
                          <label>Taxable Earning</label>
                          <div className="text-secondary text-sm pl-8">The Income Tax amount will be divided equally and deducted every month across Financial year </div>
                        </div>
                      </div>
                      <div className="text-3xl font-medium ">
                        {proRata
                          ? <IoCheckbox
                            className="text-success inline-block text-4xl me-4 cursor-pointer"
                            onClick={() => setProRata(false)}
                          />
                          :
                          <MdOutlineCheckBoxOutlineBlank
                            className="text-success inline-block text-4xl me-4 cursor-pointer"
                            onClick={() => setProRata(true)}
                          />
                        }
                        <label>Calculate on Pro-Rata Basis</label><br />
                        <div className="text-secondary text-sm pl-8">Pay will be adjusted based on employees working days </div>
                      </div>
                    </div>
                  }

                  <label className="text-3xl font-medium ">
                    {flexible
                      ? <IoCheckbox
                        className="text-success inline-block text-4xl me-4 cursor-pointer"
                        onClick={() => setFlexible(false)}
                      />
                      :
                      <MdOutlineCheckBoxOutlineBlank
                        className="text-success inline-block text-4xl me-4 cursor-pointer"
                        onClick={() => setFlexible(true)}
                      />
                    }
                    Add as a Flexible Benefit Plan Component
                  </label>

                  <label className="text-3xl font-medium ">
                    {isEpf
                      ? <IoCheckbox
                        className="text-success inline-block text-4xl me-4 cursor-pointer"
                        onClick={() => setIsEpf(false)}
                      />
                      :
                      <MdOutlineCheckBoxOutlineBlank
                        className="text-success inline-block text-4xl me-4 cursor-pointer"
                        onClick={() => setIsEpf(true)}
                      />
                    }
                    Consider for EPF Calculation
                  </label>

                  <label className="text-3xl font-medium ">
                    {isEsi
                      ? <IoCheckbox
                        className="text-success inline-block text-4xl me-4 cursor-pointer"
                        onClick={() => setIsEsi(false)}
                      />
                      : <MdOutlineCheckBoxOutlineBlank
                        className="text-success inline-block text-4xl me-4 cursor-pointer"
                        onClick={() => setIsEsi(true)}
                      />
                    }
                    Consider for ESI Calculation
                  </label>

                  <label className="text-3xl font-medium ">
                    {onPaySlip
                      ? <IoCheckbox
                        className="text-success inline-block text-4xl me-4 cursor-pointer"
                        onClick={() => setOnPaySlip(false)}
                      />
                      :
                      <MdOutlineCheckBoxOutlineBlank
                        className="text-success inline-block text-4xl me-4 cursor-pointer"
                        onClick={() => setOnPaySlip(true)}
                      />
                    }
                    Show this Component on Payslip
                  </label>

                  <label className="text-3xl font-medium ">
                    {active
                      ? <IoCheckbox
                        className="text-gray-400 inline-block text-4xl me-4 cursor-pointer"
                      />
                      :
                      null
                    }
                    Mark this as Active
                  </label>
                </div>
              }

              {eType?.name === 'Fixed Allowance' &&
                <div className="flex flex-col gap-1 mt-4">
                  <label className="text-3xl font-medium">
                    {addToSalary
                      ? <IoCheckbox
                        className="inline-block text-4xl me-4 cursor-pointer text-gray-400"
                      />
                      :
                      null
                    }
                    Add to Employee's Salary Structure
                  </label>

                  {paytype == 'variable' ?
                    null :
                    <div className="flex flex-col gap-4 mb-4">
                      <div className="text-3xl font-medium ">
                        {taxable
                          ? <IoCheckbox
                            className="text-gray-400 inline-block text-4xl me-4 cursor-pointer"
                          />
                          :
                          null
                        }
                        <label>Taxable Earning</label><br />
                        <div className="text-secondary text-sm">(The Income Tax amount will be divided equally and deducted every month across Financial year)</div>
                      </div>
                      <div className="text-3xl font-medium ">
                        {proRata
                          ? <IoCheckbox
                            className="text-gray-400 inline-block text-4xl me-4 cursor-pointer"

                          />
                          :
                          null
                        }
                        <label>Calculate on Pro-Rata Basis</label><br />
                        <div className="text-secondary text-sm">(Pay will be adjusted based on employees working days )</div>
                      </div>
                    </div>
                  }

                  <label className="text-3xl font-medium ">
                    {flexible
                      ? <IoCheckbox
                        className="text-gray-400 inline-block text-4xl me-4 cursor-pointer"
                      />
                      :
                      null
                    }
                    Add as a Flexible Benefit Plan Component
                  </label>

                  <label className="text-3xl font-medium ">
                    {isEpf
                      ? <IoCheckbox
                        className="text-success inline-block text-4xl me-4 cursor-pointer"
                        onClick={() => setIsEpf(false)}
                      />
                      :
                      <MdOutlineCheckBoxOutlineBlank
                        className="text-success inline-block text-4xl me-4 cursor-pointer"
                        onClick={() => setIsEpf(true)}
                      />
                    }
                    Consider for EPF Calculation
                  </label>

                  <label className="text-3xl font-medium ">
                    {isEsi
                      ? <IoCheckbox
                        className="text-gray-400 inline-block text-4xl me-4 cursor-pointer"
                      />
                      : null
                    }
                    Consider for ESI Calculation
                  </label>

                  <label className="text-3xl font-medium ">
                    {onPaySlip
                      ? <IoCheckbox
                        className="text-gray-400 inline-block text-4xl me-4 cursor-pointer"
                      />
                      :
                      null
                    }
                    Show this Component on Payslip
                  </label>

                  <label className="text-3xl font-medium ">
                    {active
                      ? <IoCheckbox
                        className="text-gray-400 inline-block text-4xl me-4 cursor-pointer"
                      />
                      :
                      null
                    }
                    Mark this as Active
                  </label>
                </div>
              }

              {eType?.name !== 'Basic' && eType?.name !== 'House Rent Allowance' && eType?.name !== 'Fixed Allowance' && eType?.name !== 'One-Time Earning' &&
                <div className="flex flex-col gap-1 mt-4">
                  <label className="text-3xl font-medium">
                    {addToSalary
                      ? <IoCheckbox
                        className="text-success inline-block text-4xl me-4 cursor-pointer"
                      />
                      :
                      <MdOutlineCheckBoxOutlineBlank
                        className="text-success inline-block text-4xl me-4 cursor-pointer"
                        onClick={() => setAddToSalary(true)}
                      />
                    }
                    Add to Employee's Salary Structure
                  </label>

                  {paytype == 'variable' ?
                    null :
                    <div className="flex flex-col gap-4 mb-4">
                      <div className="text-3xl font-medium ">
                        {taxable
                          ? <IoCheckbox
                            className="text-success inline-block text-4xl me-4 cursor-pointer"
                          />
                          :
                          <MdOutlineCheckBoxOutlineBlank
                            className="text-success inline-block text-4xl me-4 cursor-pointer"
                            onClick={() => setTaxable(true)}
                          />
                        }

                        <label>Taxable Earning</label><br />
                        <div className="text-secondary text-sm">( The Income Tax amount will be divided equally and deducted every month across Financial year )</div>
                      </div>
                      <div className="text-3xl font-medium ">
                        {proRata
                          ? <IoCheckbox
                            className="text-success inline-block text-4xl me-4 cursor-pointer"
                            onClick={() => setProRata(false)}
                          />
                          :
                          <MdOutlineCheckBoxOutlineBlank
                            className="text-success inline-block text-4xl me-4 cursor-pointer"
                            onClick={() => setProRata(true)}
                          />
                        }
                        <label>Calculate on Pro-Rata Basis</label><br />
                        <div className="text-secondary text-sm ">( Pay will be adjusted based on employees working days)</div>
                      </div>
                    </div>
                  }

                  <label className="text-3xl font-medium ">
                    {flexible
                      ? <IoCheckbox
                        className="text-success inline-block text-4xl me-4 cursor-pointer"
                        onClick={() => setFlexible(false)}
                      />
                      :
                      <MdOutlineCheckBoxOutlineBlank
                        className="text-success inline-block text-4xl me-4 cursor-pointer"
                        onClick={() => setFlexible(true)}
                      />
                    }
                    Add as a Flexible Benefit Plan Component
                  </label>

                  <label className="text-3xl font-medium ">
                    {isEpf
                      ? <IoCheckbox
                        className="text-success inline-block text-4xl me-4 cursor-pointer"
                        onClick={() => setIsEpf(false)}
                      />
                      :
                      <MdOutlineCheckBoxOutlineBlank
                        className="text-success inline-block text-4xl me-4 cursor-pointer"
                        onClick={() => setIsEpf(true)}
                      />
                    }
                    Consider for EPF Calculation
                  </label>

                  <label className="text-3xl font-medium ">
                    {isEsi
                      ? <IoCheckbox
                        className="text-success inline-block text-4xl me-4 cursor-pointer"
                        onClick={() => setIsEsi(false)}
                      />
                      : <MdOutlineCheckBoxOutlineBlank
                        className="text-success inline-block text-4xl me-4 cursor-pointer"
                        onClick={() => setIsEsi(true)}
                      />
                    }
                    Consider for ESI Calculation
                  </label>

                  <label className="text-3xl font-medium ">
                    {onPaySlip
                      ? <IoCheckbox
                        className="text-success inline-block text-4xl me-4 cursor-pointer"
                        onClick={() => setOnPaySlip(false)}
                      />
                      :
                      <MdOutlineCheckBoxOutlineBlank
                        className="text-success inline-block text-4xl me-4 cursor-pointer"
                        onClick={() => setOnPaySlip(true)}
                      />
                    }
                    Show this Component on Payslip
                  </label>

                  <label className="text-3xl font-medium ">
                    {active
                      ? <IoCheckbox
                        className="text-success inline-block text-4xl me-4 cursor-pointer"
                        onClick={() => setActive(false)}
                      />
                      :
                      <MdOutlineCheckBoxOutlineBlank
                        className="text-tertiary inline-block text-4xl me-4 cursor-pointer"
                        onClick={() => setActive(true)}
                      />
                    }
                    Mark this as Active
                  </label>
                </div>
              }
            </div>
          </div>

          <button
            onClick={handleSubmit}
            className="button-blue-gradient mx-8 mt-10"
          >
            Save
          </button>
          {/* <form onSubmit={(e) => handleFormSubmit(e)}>
            <div className="row">
              <div>
                <label className='dark:text-primaryDark1 uppercase'>
                  Deduction Type<span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  options={deductionSelect}
                  styles={theme === "light" ? adminStylesLight : adminStyles}
                  placeholder="SELECT REASON"
                  onChange={(selectedOption) => setDeductionType(selectedOption)}
                  className='mt-4'
                />
              </div>

              <div>
                <label htmlFor="mName" className='dark:text-primaryDark1 uppercase'>
                  deduction frequency
                </label>
                <br />
                <Select
                  options={deductionFrequencyOptions}
                  styles={theme == "light" ? adminStylesLight : adminStyles}
                  placeholder="SELECT REASON"
                  onChange={({ value, label }) => setDeductionFrequency({ value, label })}
                  className='mt-4'
                />
              </div>

              <div>
                <label className='text-primaryDark1 uppercase'>
                  Name on Payslip <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                  // className="me-5 py-2 mt-4 dark:bg-primary pl-4"
                  className='mt-4'
                  placeholder="ENTER NAME"
                  onChange={({ target }) => setPaySlipName(target.value)}
                />
              </div>
            </div>

            <div className='flex gap-3 mx-8'>
              <input
                type='checkbox'
                onChange={(e) => active ? setActive(false) : setActive(true)}
                className='check-box-active blue_b w-6 h-6'
              />
              <label className='text-2xl'>Mark this as Active</label>
            </div>

            <div className='mx-8 mt-6 pb-10'>
              <button type="submit" className="button-blue-gradient">
                Create
              </button>
            </div>
          </form> */}
        </section >

        <Modal
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        title="Add New Earning Type"
        width="w-[300px]"
        content={
          <div>
            <form>
              <div className='flex flex-col gap-10'>
                <div className='flex flex-col gap-2'>
                  <label htmlFor="loan_name">
                    Enter Earning Type
                  </label>
                  <input
                    id='loan_name'
                    type="text"
                    placeholder='Enter Type'
                    className="bg-[#2B2C47] px-6 py-4 outline-none"
                    onChange={(e) => setNewEarnType(e?.target?.value)}
                  />
                </div>

                <div className="flex items-center gap-10">
                  <button
                    type='submit'
                    className="px-8 py-2 w-max text-white bg-tertiary rounded-xl cursor-pointer"
                    value="Save"
                    onClick={(e) => handleEarningTypes(e)}
                  >Submit</button>
                  <span className="text-cyan cursor-pointer" onClick={() => setShowModal(false)}>
                    Cancel
                  </span>
                </div>
              </div>
            </form>
          </div>
        }/>
      </div >
    </div >

);
};

export default AddComponent;
