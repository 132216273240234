import React, { useEffect, useMemo, useState, useCallback } from "react";
import { adminStyles, adminStylesLight, dcrStyles } from "../../../utils/selectStyles";
import Select from "react-select";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Table from "../../../components/Table";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getLeaveApllDetails, filterLeaveApp, approveLeaveAppl } from "../../../services/leave";
import { addIndex } from "../../../utils/serialiseResponse";
import { fetchEmployees, clearEmployees } from "../../../reducers/employeesReducer";
import PageTitle from "../../../components/PageTitle";
import { generateSelectData } from "../../../utils/serialiseResponse";
import customToast from "../../../components/CustomToast";
const LeaveHistory = () => {
  const dispatch = useDispatch();
  const [leaveType, setleaveType] = useState({ value: 0, label: "All" });
  const [editing, setEditing] = useState(false);
  const [empID, setEmpl] = useState({ value: 0, label: "All" });
  const employees = useSelector(({ employees }) => employees);
  const [selectedOption, setSelectedOption] = useState({ value: 0, label: "This Month" });
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [data, setData] = useState([])
  const today = new Date().toISOString().split('T')[0];
  const [date, setDate] = useState({ value: "", label: "" })

  const leaveOptions = [
    { value: 0, label: "All" }, { value: 1, label: "Leave" }, { value: 2, label: "Personal" }
  ]
  useEffect(() => {
    dispatch(fetchEmployees());

    return () => dispatch(clearEmployees());
  }, [dispatch]);

  const emplSelect = useMemo(
    () => generateSelectData(employees.data, "fullName"),
    [employees.data]
  );



  const dateOptions = [
    { value: 0, label: "This Month" },
    { value: 1, label: "Previous Month" },
    { value: 4, label: "This Year" },
    { value: 3, label: "Cutsom Range" }
  ]


  useEffect(() => {
    if (selectedOption?.value === 0) setThisMonthRange();
    else if (selectedOption?.value === 1) setPreviousMonthRange();
    else if (selectedOption?.value === 4) setThisYearRange();
  }, [selectedOption]);

  useEffect(() => {
    
    filterData();
  }, [startDate,endDate, leaveType, empID]);


  const handleOptionChange = (option) => {
    setSelectedOption(option);
    if (option.value === 0) setThisMonthRange();
    if (option.value === 1) setPreviousMonthRange();
    if (option.value === 4) setThisYearRange();
  };

  const handleCustomDateChange = (e) => {
    if (e.target.name === 'startDate') setStartDate(e.target.value);
    if (e.target.name === 'endDate') setEndDate(e.target.value);
    setDate({ label: `${startDate + " to " + endDate}` })
  };


  const setThisMonthRange = useCallback(() => {
    const today = new Date();
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 2);
    const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    setDateRange(startOfMonth, endOfMonth);
  }, [selectedOption]);

  const setPreviousMonthRange = useCallback(() => {
    const today = new Date();
    const startOfPrevMonth = new Date(today.getFullYear(), today.getMonth() - 1, 2);
    const endOfPrevMonth = new Date(today.getFullYear(), today.getMonth(), 0);
    setDateRange(startOfPrevMonth, endOfPrevMonth);
  }, [selectedOption]);

  const setThisYearRange = useCallback(() => {
    const today = new Date();
    const startOfYear = new Date(today.getFullYear(), 0, 1);
    const endOfYear = new Date(today.getFullYear(), 11, 31);
    setDateRange(startOfYear, endOfYear);
  }, [selectedOption]);

  const setDateRange = (start, end) => {
    
    setStartDate(start?.toISOString().split('T')[0]);
    setEndDate(end?.toISOString().split('T')[0]);
    setDate({ label: `${startDate + " to " + endDate}` });
  };


  const filterData = useCallback(() => {
  const empl = { _id: empID?._id, value: empID?.value };
    filterLeaveApp({ startDate, endDate, leaveType, empl })
      .then((res) => {
        if (res?.data?.length > 0) {
          setData(res?.data);
        } else {
          setData([]);
          customToast.error('Data Not Found!');
        }
      })
      .catch(() => customToast.error('Something went wrong!'))

  }, [startDate, endDate, leaveType, empID]);

  const columns = useMemo(
    () => [
      {
        Header: "Employee ID",
        accessor: "employee.uid",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Employee Name",
        accessor: "employee.firstName",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Leave Type",
        accessor: "leave_type.leave_Name",
        disableFilters: true,
        disableSortBy: true,
        // Cell: (props) => {
        //   const editable = Number(props?.row?.original?.sr) === Number(editRow);
        //   if (editable) expiryRef.current = props?.row?.original?.expiryDate;
        //   return editable ? (
        //     <input
        //       defaultValue={props?.row?.original?.expiryDate}
        //       onChange={(e) => {
        //         expiryRef.current = e.target.value;
        //       }}
        //       type="date"
        //       className="sales-table__input"
        //     />
        //   ) : (
        //     <span>{props?.row?.original?.expiryDate}</span>
        //   );
        // },
      },
      {
        Header: "Requested Date",
        accessor: "createdAt",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return <span>{props?.row?.original?.createdAt.split("T")[0]}</span>
        }
        // Cell: (props) => {
        //   const editable = Number(props?.row?.original?.sr) === Number(editRow);
        //   if (editable) expiryRef.current = props?.row?.original?.expiryDate;
        //   return editable ? (
        //     <input
        //       defaultValue={props?.row?.original?.expiryDate}
        //       onChange={(e) => {
        //         expiryRef.current = e.target.value;
        //       }}
        //       type="date"
        //       className="sales-table__input"
        //     />
        //   ) : (
        //     <span>{props?.row?.original?.expiryDate}</span>
        //   );
        // },
      },
      {
        Header: "Duration",
        accessor: "duration",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return <span>{props?.row?.original?.duration + " days"}</span>
        }
        // Cell: (props) => {
        //   const editable = Number(props?.row?.original?.sr) === Number(editRow);
        //   if (editable) expiryRef.current = props?.row?.original?.expiryDate;
        //   return editable ? (
        //     <input
        //       defaultValue={props?.row?.original?.expiryDate}
        //       onChange={(e) => {
        //         expiryRef.current = e.target.value;
        //       }}
        //       type="date"
        //       className="sales-table__input"
        //     />
        //   ) : (
        //     <span>{props?.row?.original?.expiryDate}</span>
        //   );
        // },
      },
      {
        Header: "From",
        accessor: "fromDate",
        disableFilters: true,
        disableSortBy: true,
        // Cell: (props) => {
        //   const editable = Number(props?.row?.original?.sr) === Number(editRow);
        //   if (editable) expiryRef.current = props?.row?.original?.expiryDate;
        //   return editable ? (
        //     <input
        //       defaultValue={props?.row?.original?.expiryDate}
        //       onChange={(e) => {
        //         expiryRef.current = e.target.value;
        //       }}
        //       type="date"
        //       className="sales-table__input"
        //     />
        //   ) : (
        //     <span>{props?.row?.original?.expiryDate}</span>
        //   );
        // },
      },
      {
        Header: "To",
        accessor: "toDate",
        disableFilters: true,
        disableSortBy: true,
        // Cell: (props) => {
        //   const editable = Number(props?.row?.original?.sr) === Number(editRow);
        //   if (editable) expiryRef.current = props?.row?.original?.expiryDate;
        //   return editable ? (
        //     <input
        //       defaultValue={props?.row?.original?.expiryDate}
        //       onChange={(e) => {
        //         expiryRef.current = e.target.value;
        //       }}
        //       type="date"
        //       className="sales-table__input"
        //     />
        //   ) : (
        //     <span>{props?.row?.original?.expiryDate}</span>
        //   );
        // },
      },
      {
        Header: "Reason",
        accessor: "reason",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          if (props?.row?.original?.length > 0) {
            return props?.row?.original;
          }
          else {
            return "__"
          }
          // const editable = Number(props?.row?.original?.sr) === Number(editRow);
          // if (editable) expiryRef.current = props?.row?.original?.expiryDate;
          // return editable ? (
          //   <input
          //     defaultValue={props?.row?.original?.expiryDate}
          //     onChange={(e) => {
          //       expiryRef.current = e.target.value;
          //     }}
          //     type="date"
          //     className="sales-table__input"
          //   />
          // ) : (
          //   <span>{props?.row?.original?.expiryDate}</span>
          // );
        },
      },
      {
        Header: "Status",
        accessor: "isApproved",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return <span className="text-green-600">{props?.row?.original?.isApproved ? "Approved" : ""}</span>

          // <OverlayTrigger
          // className="cursor-pointer"
          // key="right"
          // placement="top"
          // overlay={
          //   props?.row?.original.isApproved ?
          //   <Tooltip id={`tooltip-$top`} className="text-[1.2rem] bg-[#353456] text-red-600 w-60 p-4 text-center">
          //     Click to cancel the approved leave
          //   </Tooltip>:
          //   <Tooltip id={`tooltip-$top`} className="text-[1.2rem] bg-[#353456] text-blue-400 w-60 p-4 text-center">
          //   Click to approve leave
          // </Tooltip>
          // }>
          // <span className="text-gray-300 text-3xl flex flex-row gap-x-1">
          // <button onClick={()=>handleApprove(props?.row?.original._id,props?.row?.original.isApproved)} className="button-greensmall-gradient text-xl">{props?.row?.original?.isApproved ? "Approved" : "Approve"}</button>

          // </span>
          // </OverlayTrigger>


        },
      },
      // {
      //   Header: "Actions",
      //   accessor: "action",
      //   disableFilters: true,
      //   disableSortBy: true,
      //   minWidth: 80,
      //   maxWidth: 90,
      //   // Cell: (props) => {
      //   //   return editing && Number(props?.row?.original?.sr) === editRow ? (
      //   //     <div className="d-flex gap-2 justify-content-center">
      //   //       <AiFillSave
      //   //         onClick={(e) => {
      //   //           submitEditedRow(props?.row?.original?.sr);
      //   //         }}
      //   //         type="button"
      //   //         className="icon-color-green"
      //   //       />
      //   //       <MdDelete
      //   //         onClick={() => {
      //   //           deleteHandler(props?.row?.original?.product);
      //   //         }}
      //   //         type="button"
      //   //         className="button-delete__icon"
      //   //       />
      //   //       <MdCancel
      //   //         type="button"
      //   //         className="icon-color-yellow"
      //   //         onClick={() => {
      //   //           cancelHandler(props?.row?.original?.sr);
      //   //         }}
      //   //       />
      //   //     </div>
      //   //   ) : (
      //   //     <span
      //   //       className="react-table-view-link"
      //   //       onClick={() => {
      //   //         handleEditRow(props?.row?.original?.sr);
      //   //       }}
      //   //     >
      //   //       <RiEdit2Fill className="icon-color-green" />
      //   //     </span>
      //   //   );
      //   // },
      // },
    ],
    [],
  );

  const theme = useSelector((state) => state.theme.theme);



  const tableData = useMemo(() => addIndex(data), [data]);
  return (
    <div className="main-content  bg-primaryLight2 dark:bg-primaryDark1" >
      <div className='area-creation-content'>

        <PageTitle title="Leave history" route='../../' />

        <section className="admin-content">
          <form className={`${theme == "light" ? "lightbg" : ""}`}>
            <div className="row">
              <div >
                <label htmlFor="all_emp" className="web-app__heading">All</label>
                <br />
                <Select
                  name="all employee"
                  id="all_emp"
                  // value={}
                  options={leaveOptions}
                  onChange={({ value, label }) => setleaveType({ value, label })}
                  styles={theme == "light" ? adminStylesLight : adminStyles}
                  placeholder="All"
                />
              </div>
              <div >
                <label htmlFor="select_emp">Select Employee</label>
                <br />
                <Select
                  className="mt-3"
                  name="select employee"
                  id="select_emp"
                  options={emplSelect}
                  onChange={(el) => setEmpl(el)}
                  styles={theme == "light" ? adminStylesLight : adminStyles}
                />
              </div>
              <div >
                <label htmlFor="select_date">Select Date</label>
                <br />

                <Select
                  className="mt-3"
                  name="Select Date"
                  options={dateOptions}
                  value={selectedOption?.lable}
                  onChange={handleOptionChange}
                  styles={theme == "light" ? adminStylesLight : adminStyles}

                />
                {selectedOption.value === 3 ?
                  <div className="flex gap-4 w-[40rem]">

                    <input type="date" name="startDate" placeholder="Start Date" value={startDate}
                      onChange={handleCustomDateChange} />

                    <input type="date" name="endDate" placeholder="End Date" value={endDate}
                      onChange={handleCustomDateChange} />
                  </div> : null
                }
              </div>
            </div>
          </form>
        </section>

        <section className={"cardlink-row pb-6"} >
          <h2 className={"mt-5 ms-2 web-app__heading " + `${theme === 'light' ? "light_bg_logo" : ""}`}>
            Showing ({tableData?.length}) Entries
          </h2>

          <div className={"filter-table"}>
            <Table columns={columns} data={tableData} />
          </div>
        </section>
      </div>
    </div>
  );
};

export default LeaveHistory;
