import { customAxios } from "../utils/axiosinstance";

export const CreateReimbursement = async (payload) => {
  const { data } = await customAxios.post(`/hrms/reimbursement`, payload);
  return data;
};

export const getReimbursements = async () => {
  const { data } = await customAxios.get(`/hrms/reimbursement`);
  return data.data;
};

export const getActiveReimbursements = async () => {
  const { data } = await customAxios.get(`/hrms/reimbursement/active-reimbursement`);
  return data.data;
};

export const getReimbursementTypes = async () => {
  const { data } = await customAxios.get(`/hrms/reimbursement/types`);
  return data.data;
};

export const updateReimbursement = async (payload) => {
  const { data } = await customAxios.post(`/hrms/reimbursement/update`,payload);
  return data.data;
};

export const PostReimbursementTypes = async(payload) => {
  const {data} = await customAxios.post(`/hrms/reimbursement/types` , payload )
  return data;
};

export const deleteReimbursement = async (payload) => {
  const {data} = await customAxios.post(`/hrms/reimbursement/delete`, payload)
  return data;
}