import { createSlice } from "@reduxjs/toolkit";
import { DeleteDeduction, GetAllDeduction, GetPostDeduction, GetPreDeduction, UpdateDeduction } from "../services/deduction";

const initialState = {
     data: [],
     preData: [],
     postData: [],
}

const deductionSlice = createSlice({
    name: 'deduction',
    initialState,
    reducers:{
       setState(state , action){
        state.data = action.payload
       },
       setPreState(state , action){
        state.preData = action.payload
      },
      setPostState(state , action){
       state.postData = action.payload
      }
    }
})

export const { setState , setPostState , setPreState } = deductionSlice.actions;

export const getAllDeductions = () => async(dispatch) => {
   try {
     const response = await GetAllDeduction();
     dispatch(setState(response.data));
   } catch (error) {
   }
  
}

export const getPreDeductions = () => async(dispatch) => {
    try {
      const response = await GetPreDeduction();
      dispatch(setPreState(response.data));
    } catch (error) {
    }
   
 }

 export const getPostDeductions = () => async(dispatch) => {
    try {
      const response = await GetPostDeduction();
      dispatch(setPostState(response.data));
    } catch (error) {
    }
 }

 export const updateDeduction = (id, payload) => async(dispatch) => {
  try {
    const res = await UpdateDeduction(id, payload);
  } catch (error) {
    console.log(error);
  }
 }

 export const deleteDeduction = (payload) => async(dispatch) => {
  try {
    const res = await DeleteDeduction(payload);
    dispatch(getPostDeductions());
    dispatch(getPreDeductions());
  } catch (error) {
    console.log(error);
  }
 }

export default deductionSlice.reducer;