export const title = "Manage Taxes";

export const links = [
  {
    name: "Taxes",
    route: "/settings/taxes/manage-taxes",
  },
  {
    name: "Income Tax",
    route: "/settings/taxes/income-tax",
  },
  {
    name: "Tax Regime",
    route: "/settings/taxes/regime",
  },
  {
    name: "Tax Settings",
    route: "/settings/taxes/setting",
  },
];
