import React from "react";
import CardLink from "../../../components/CardLink";
import { UseSelector, useSelector } from "react-redux";

const FinanceHome = () =>{
    const theme = useSelector(({theme}) => theme);

    return (
    <div  className="main-content  bg-primaryLight2 dark:bg-primaryDark1">
      <div className="admin-panel_content">
        <div className="cardlink-row">
          <div className="admin-panel__box-container flex flex-wrap flex-row gap-8">
            {/* <div className="grid grid-cols-3"> */}
            
          <div className="mt-0 admin-panel__box-container">
            {/* <CardLink
              className={"admin-panel__cards"}
              title="Approve Salary Revision"
              type="approve-salary-revision"
              link="manage-salary-revision/approve-salary-revision"
            /> */}

            <CardLink
              className={"admin-panel__cards"}
              title="Reimbursement"
              type="reimbursement"
              link="manage-claim/add-details"
            />

            <CardLink
              className={"admin-panel__cards"}
              title="Investment"
              type="investment"
              link="manage-investment/create-POI"
            />

            {/* <CardLink
              className={"admin-panel__cards"}
              title="Previous Salary"
              type="previous-salary"
              link="manage-salary-revision/previous-salary"
          /> */}
           </div> 
          
          </div>
        </div>
      </div>
    </div>
    );
};

export default FinanceHome;