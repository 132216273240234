import React from "react";
import CardLink from "../../components/CardLink";
import { useSelector } from "react-redux";
import ComingSoon from "../../components/ComingSoon";
import ReportCard from "../../components/Reports/ReportCard";
const Reports = () => {
  const theme = useSelector((state) => state.theme.theme);
  return (
    <div className="main-content relative bg-primaryLight2 dark:bg-primaryDark1">
      {/* <ComingSoon /> */}
      <div className="admin-panel_content">
        <div className="cardlink-row">
          <div className="admin-panel__box-container flex flex-row flex-wrap gap-8">
            <ReportCard
              title="PAYROLL OVERVIEW"
              type="payroll"
              link="manage-reports/payroll-summary"
            />
            {/* <ReportCard title="SALARY" type="salary" link="salary-comparison" />
            <ReportCard
              title="STATUTORY"
              type="statutory"
              link="manage-reports"
            />
            <ReportCard
              title="TEMPELATES"
              type="tempelates"
              link="manage-reports"
            />
            <ReportCard title="TAXES" type="taxes" link="manage-reports" /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reports;
