import React from "react";
import CardLink from "../../../components/CardLink";
import { useSelector } from "react-redux";

const AttendanceHome = () => {
  const theme = useSelector((state) => state.theme.theme);
  return (
    <div  className="main-content bg-primaryLight2 dark:bg-primaryDark1">
      <div className="admin-panel_content">
        <div className="cardlink-row">
          <div className="admin-panel__box-container flex flex-row flex-wrap gap-8">
            <CardLink
              className={"admin-panel__cards"}
              title="Leave"
              type="Leave"
              link="manage-leave/leave-form"
            />

            <CardLink
              className={"admin-panel__cards"}
              title="Upload Attendance"
              type="UploadAttendance"
              link="upload-attendance/regular-attendance"
            />

            <CardLink
              className={"admin-panel__cards"}
              title="Sync Attendance"
              type="SyncAttendance"
              link="sync-attendance/regular-attendance"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttendanceHome;
