import { customAxios } from "../utils/axiosinstance";


export const viewLocations = async (type, parentId, areaType) => {
  const { data } = await customAxios.get("/client/location/" + type, {
    params: { parentId , areaType},
  });

  return data;
};

export const newLocation = async (type, details) => {
  const { data } = await customAxios.post("/client/location/" + type, details);
  return data;
};

export const deleteLocation = async (type, id) => {
  await customAxios.post(
    "/client/location/" + `${type}/${id}`,
    { params: { id } },
  );
};

export const editLocation = async (type, id, updatedLoc) => {
  const { data } = await customAxios.put("/client/location/" + `${type}/${id}`, updatedLoc);

  return data;
};

export const fetchDependents = async (id, type) => {
  const { data } = await customAxios.get("/client/location/dependents/"+ `${type}?Id=${id}`)
  return data;
}