import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../../components/PageTitle";
import Table from "../../../components/Table";
import { SlOptionsVertical } from "react-icons/sl";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";
import { IoSave } from "react-icons/io5";
import { fetchEmployeesWithTemp } from "../../../reducers/employeesReducer";
import {
  generateSelectData,
  generateSelectDataForSalaryTemp,
} from "../../../utils/serialiseResponse";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { GetEarnings } from "../../../services/earning";
import { GetAllDeduction } from "../../../services/deduction";
import { getReimbursements } from "../../../services/reimbursement";
import customToast from "../../../components/CustomToast";
import { fetchEpfDetails } from "../../../reducers/epfDetailsReducer";
import { fetchEsiDetails } from "../../../reducers/esiDetailsReducer";
import {
  getPercentageSalary,
  returnDaysInMonth,
  returnMonthFromIndex,
} from "../../../utils/helper";
import { fetchPayrunApproveStatus } from "../../../reducers/payrunAprroveStatus";
import {
  calculatePayrunData,
  createPayrun,
  getPayrunWithMonthAndYear,
  updatePayrunApproveStatus,
} from "../../../services/payRun";
import { AiOutlineMinusCircle } from "react-icons/ai";
import { FaSave } from "react-icons/fa";
import { fetchPt } from "../../../services/salaryTempelates";
import {
  IncomeTaxTotal,
  UpdateIncomeTaxTotal,
} from "../../../services/incomeTax";
import {
  CreatePaymentSchedule,
  GetPaymentSchedule,
} from "../../../services/paymentschedule";
import { getEmployeeLoanDetailsByMonthAndYear } from "../../../services/loans";
import Modal from "../../../components/Modal";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { IoIosInformationCircle } from "react-icons/io";
import { getEmployeeAdvSalaryDetailsByMonthAndYear } from "../../../services/advanceSalary";

const CheckContainer = ({
  text,
  initialStatus,
  includeToolTip,
  toolTipText,
}) => {
  const [status, setStatus] = useState(initialStatus);

  console.log(initialStatus, "Stattus");

  const handleClick = () => {
    const newStatus = !status;
    setStatus(newStatus);
    // onStatusChange(newStatus);
  };

  return (
    <div>
      <label className="text-3xl font-medium flex">
        {status ? (
          <MdCheckBox className="inline-block text-4xl me-4 cursor-pointer text-[#21c38b]" />
        ) : (
          <MdCheckBoxOutlineBlank className="inline-block text-4xl me-4 cursor-pointer text-[#21c38b]" />
        )}

        {!includeToolTip ? (
          text
        ) : (
          <OverlayTrigger
            className="cursor-pointer"
            placement="right"
            overlay={
              <Tooltip
                id={`tooltip-top`}
                className="text-[1.2rem] w-60 pb-8 cursor-pointer text-yellow"
              >
                {toolTipText}
              </Tooltip>
            }
          >
            <div className="flex gap-4">
              <span>{text}</span>
              <IoIosInformationCircle className="text-yellow" />
            </div>
          </OverlayTrigger>
        )}
      </label>
    </div>
  );
};

const CreatePayrun = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const dropdownRef = useRef(null);
  const [additionalDeduction, setAdditionalDeduction] = useState(0);
  // const [deductData, setDeductData] = useState([]);
  // const [totalEarning, setTotalEarning] = useState(0);
  // const lopData = useSelector((state) => state.lop.data);
  // const paymentYear = location.state.paymentYear;
  // const paymentDetails = location.state.paymentDetails;
  // const payDateMonth = location.state.payDateMonth;
  const [empData, setEmpData] = useState(null);
  const [showEarning, setShowEarning] = useState(false);
  const [showDeduction, setShowDeduction] = useState(false);
  const [showReimb, setShowReimb] = useState(false);
  const [earn, setEarn] = useState([]);
  const [deduct, setDeduct] = useState([]);
  const [reimbData, setReimbData] = useState([]);
  const [paidDays, setPaidDays] = useState(31);
  const [earnings, setEarnings] = useState([]);
  const [newEarnings, setNewEarnings] = useState([]);
  const [deductions, setDeductions] = useState([]);
  const [reimbursement, setReimbursement] = useState([]);
  const [totalPayrunCost, setTotalPayrunCost] = useState(0);
  const [totalNetPay, setTotalNetPay] = useState(0);
  const [totalEmp, setTotalEmp] = useState(0);
  const [preTaxDeductionTotal, setPreTaxDeductionTotal] = useState(0);
  const [postTaxDeductionTotal, setPostTaxDeductionTotal] = useState(0);
  const [professionalTax, setProfessionalTax] = useState([]);
  const [paymentScheduleDetails, setPaymentScheduleDetails] = useState();
  const [payDayMonth, setPayDayMonth] = useState(0);
  const [allEmpData, setAllEmpData] = useState([]);
  const [payableDays, setPayableDays] = useState(31);
  const [lops, setLops] = useState(0);
  const [lopDays, setLopDays] = useState(0);
  const [bankTransferCount, setBankTransferCount] = useState(0);
  const [chequeCount, setChequeCount] = useState(0);
  const [selectedEmployee, setSelectedEmployee] = useState({});
  const [isPaymentSubmitted, setIsPaymentSubmitted] = useState(false);

  const employeeData = useSelector((state) => state.employees.data);
  const epfData = useSelector((state) => [state.epfInfo.data]);
  const esiData = useSelector((state) => [state.esiInfo.data]);
  const status = useSelector((state) => state.payrunApprove.status);
  const month = location.state.payingMonth;
  const year = location.state.payingYear;

  useEffect(() => {
    setEmpData(employeeData);
  }, [employeeData]);

  useEffect(() => {
    const allEmployees = async () => {
      if (!empData) return;
      const updatedEmpData = await Promise.all(
        empData?.map(async (el) => {
          const { salaryTempelate, employeeId, ...rest } = el;
          let professionalTax = [];
          let loanDetails = [];
          let advSalaryDetails = [];
          let financialYearGross = 0;
          if (employeeId?.ptDetails) {
            professionalTax = employeeId?.ptDetails;
          }

          try {
            const LoanPromises = await getEmployeeLoanDetailsByMonthAndYear({
              id: employeeId?._id,
              month: month?.value,
              year: year,
            });
            const LoanResponses = await Promise.all(LoanPromises?.data);
            loanDetails = LoanResponses.map((response) => response);
          } catch (error) {
            loanDetails = [];
          }

          try {
            const AdvSalaryPromises =
              await getEmployeeAdvSalaryDetailsByMonthAndYear({
                id: employeeId?._id,
                month: month?.value,
                year: year,
              });
            const advSalaryResponses = await Promise.all(
              AdvSalaryPromises?.data,
            );
            advSalaryDetails = advSalaryResponses.map((response) => response);
          } catch (error) {
            advSalaryDetails = [];
          }

          try {
            const incomeTaxTotal = await IncomeTaxTotal({
              id: employeeId?._id,
              month: month?.value,
              year: year,
            });
            financialYearGross = incomeTaxTotal?.data?.financialYearGross || 0;
          } catch (error) {}

          const payrunData = await calculatePayrunData({
            earnings: salaryTempelate?.earnings,
            deductions: salaryTempelate?.deductions,
            reimbursement: salaryTempelate?.reimbursements,
            monthlySalary: salaryTempelate?.monthlyTotal,
            professionalTax: professionalTax,
            loanDetails: loanDetails,
            advSalaryDetails: advSalaryDetails,
            lops: paidDays,
            paidDays,
            month,
            monthlyEarningTotal: salaryTempelate?.monthlyTotal,
            earnTotal: salaryTempelate?.earningTotal,
            netPayMonthly: salaryTempelate?.netPayMonthly,
            totalDeduction: salaryTempelate?.deductionTotalMonthly,
            financialYearGross,
            year: year,
            uid: employeeId?.uid,
          });

          return {
            ...rest,
            employeeId,
            salaryTempelate: {
              ...salaryTempelate,
              earnings: payrunData?.data?.earnings,
              deductions: payrunData?.data?.deduction,
              totalTaxes: payrunData?.data?.totalTaxes,
              netPayMonthly: payrunData?.data?.netPayMonthly,
              allTotalDeduction: payrunData?.data?.allTotalDeduction,
              earnTotal: payrunData?.data?.earningTotal,
              earningTotalMonthly: payrunData?.data?.earningTotal,
              deductionTotalMonthly: payrunData?.data?.totalDeduction,
            },
            loanDetails,
            advSalaryDetails,
            paidDays: paidDays,
            payableDays: payrunData?.data?.payableDays,
            lops: payrunData?.data?.absentdays,
            prevPayableDays: payrunData?.data?.prevPayableDays,
          };
        }),
      );
      setAllEmpData(updatedEmpData);
    };
    allEmployees();
  }, [empData, payableDays]);

  // console.log(allEmpData, "allEmpData");

  useEffect(() => {
    if (allEmpData && allEmpData.length > 0 && status == 0) {
      let totalPayrunCost = 0;
      let totalNetPay = 0;
      let totalDeduction = 0;
      let totalTaxes = 0;
      let cheque = 0;
      let bankTransfer = 0;
      let notAssignCount = 0;

      allEmpData.forEach((el) => {
        totalPayrunCost += Number(el?.salaryTempelate?.monthlyTotal);
        totalNetPay += Number(el?.salaryTempelate?.netPayMonthly);
      });
      setTotalPayrunCost(totalPayrunCost.toFixed(2));
      setTotalNetPay(totalNetPay.toFixed(2));
      setTotalEmp(allEmpData?.length);

      allEmpData.map((el) => {
        totalDeduction = totalDeduction + el?.salaryTempelate?.deductionTotal;
        totalTaxes = totalTaxes + el?.salaryTempelate?.totalTaxes;
        if (el?.employeeId?.paymentDetails?.payType == "cheque") {
          cheque = cheque + 1;
        } else if (el?.employeeId?.paymentDetails?.payType == "bankTransfer") {
          bankTransfer = bankTransfer + 1;
        } else {
          notAssignCount = notAssignCount + 1;
        }
      });
      totalDeduction = totalDeduction.toFixed(2);
      totalTaxes = totalTaxes.toFixed(2);
      setPreTaxDeductionTotal(totalDeduction);
      setPostTaxDeductionTotal(totalTaxes);
      setChequeCount(cheque);
      setBankTransferCount(bankTransfer);
    }
  }, [allEmpData]);

  useEffect(() => {
    dispatch(fetchPayrunApproveStatus());
    dispatch(fetchEmployeesWithTemp());
    dispatch(fetchEpfDetails());
    dispatch(fetchEsiDetails());
  }, [dispatch]);

  useEffect(() => {
    GetEarnings()
      .then(({ data }) => setEarn(data))
      .catch((err) => customToast.error("Sorry Something Went Wrong!"));
    GetAllDeduction()
      .then(({ data }) => setDeduct(data))
      .catch((err) => customToast.error("Sorry Something Went Wrong!"));
    getReimbursements()
      .then((data) => setReimbData(data))
      .catch((err) => customToast.error("An Error Occured"));
    GetPaymentSchedule({})
      .then((data) => {
        setPaymentScheduleDetails(data);
      })
      .catch((err) => customToast.error("Sorry Something Went Wrong!"));
  }, []);

  useEffect(() => {
    if (paymentScheduleDetails) {
      const m = returnMonthFromIndex(
        paymentScheduleDetails?.paymentMonth?.month,
      );
      setPayDayMonth(m);
      setPaidDays(paymentScheduleDetails?.salaryCalculationBasis?.value);
      setPayableDays(paymentScheduleDetails?.salaryCalculationBasis?.value);
    }
  }, [paymentScheduleDetails]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowEarning(false);
        setShowDeduction(false);
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const professionalTaxes = {
    name: "Professional Tax",
    monthly: null,
    yearly: null,
    status: true,
    calcType: "User Define",
    employeeContributionRate: { label: "User Define" },
    paySlipName: "Professional Tax",
  };

  const earnSelect = useMemo(
    () => generateSelectDataForSalaryTemp(earn, "eName"),
    [earn],
  );
  const deductSelect = useMemo(
    () => generateSelectDataForSalaryTemp(deduct, "nameOnPaySlip"),
    [deduct],
  );
  const ptSelect = useMemo(() => {
    if (!selectedEmployee?.employeeId?.hasPt) return [];
    return generateSelectData([professionalTaxes], "name");
  }, [professionalTaxes]);
  const epfSelect = useMemo(() => {
    if (!selectedEmployee?.employeeId?.hasEpfNumber) return [];

    return generateSelectDataForSalaryTemp(epfData, "docName");
  }, [epfData]);
  const esiSelect = useMemo(() => {
    if (!selectedEmployee?.employeeId?.hasEsiNumber) return [];
    return generateSelectDataForSalaryTemp(esiData, "docName");
  }, [esiData]);
  const deductionSelect = [
    ...deductSelect,
    ...epfSelect,
    ...esiSelect,
    ...ptSelect,
  ];
  const reimburseSelect = useMemo(
    () => generateSelectDataForSalaryTemp(reimbData, "nameOnPaySlip"),
    [reimbData],
  );

  const handleAddEarning = (e) => {
    if (earnings.filter((el, idx) => el?._id === e?._id).length > 0) {
      return customToast.error("Earning Already exists");
    } else {
      if (e.calcType === "percentage") {
        if (ctc) {
          const p = getPercentageSalary(ctc, e.amount);
          e.monthly = p;
        } else {
          e.monthly = 0;
        }
      } else {
        e.monthly = e.amount;
        e.iseditable = 1;
      }
      setEarnings([...earnings, { ...e }]);
    }
  };

  const handleSkipEmp = (id) => {
    const updatedEmployeeData = empData?.filter((emp) => emp._id != id);
    setEmpData(updatedEmployeeData);
  };

  const columns = [
    {
      Header: "Employee Name",
      accessor: "employeeId.firstName",
      disableFilters: true,
      disableSortBy: true,
      Cell: (props) => {
        return (
          <div
            title={
              props?.row?.original?.employeeId?.firstName +
              " " +
              props?.row?.original?.employeeId?.lastName
            }
            className="cursor-pointer active:text-tertiary active:font-semibold whitespace-nowrap overflow-hidden text-ellipsis w-60"
            onClick={() => {
              setSelectedEmployee(props?.row?.original);
              setShowSelectedEmployee(true);
            }}
          >
            {props?.row?.original?.employeeId?.firstName +
              " " +
              props?.row?.original?.employeeId?.lastName}
          </div>
        );
      },
    },
    {
      Header: "Paid Days",
      accessor: "paidDays",
      disableFilters: true,
      disableSortBy: true,
      Cell: (props) => {
        return (
          <span className="cursor-pointer active:text-tertiary active:font-semibold">
            {props?.row?.original?.payableDays}
          </span>
        );
      },
    },
    {
      Header: "LOP",
      accessor: "lopDays",
      disableFilters: true,
      disableSortBy: true,
      Cell: (props) => {
        return (
          <span className="cursor-pointer active:text-tertiary active:font-semibold">
            {props?.row?.original?.lops ? props?.row?.original?.lops : 0}
          </span>
        );
      },
    },
    {
      Header: "Gross Pay",
      accessor: "employeeId.earningTotalMonthly",
      disableFilters: true,
      disableSortBy: true,
      Cell: (props) => {
        const grossPay =
          props.row.original.salaryTempelate &&
          props.row.original.salaryTempelate.earningTotalMonthly
            ? props.row.original.salaryTempelate.earningTotalMonthly
            : 0;
        return (
          <span className="cursor-pointer active:text-tertiary active:font-semibold">
            {grossPay}
          </span>
        );
      },
    },
    {
      Header: "Deduction",
      accessor: "employeeId.deduction",
      disableFilters: true,
      disableSortBy: true,
      Cell: (props) => {
        const additional =
          props.row.original?.salaryTempelate &&
          props.row.original?.salaryTempelate?.deductionTotalMonthly
            ? props.row.original?.salaryTempelate?.deductionTotalMonthly
            : 0;
        return (
          <span className="cursor-pointer active:text-tertiary active:font-semibold">
            {additional}
          </span>
        );
      },
    },
    {
      Header: "Taxes",
      accessor: "employeeId.deductions",
      disableFilters: true,
      disableSortBy: true,
      Cell: (props) => {
        const deduction =
          props?.row?.original?.salaryTempelate &&
          props?.row?.original?.salaryTempelate?.totalTaxes
            ? props?.row?.original?.salaryTempelate?.totalTaxes
            : 0;
        return (
          <span className="cursor-pointer active:text-tertiary active:font-semibold">
            {deduction}
          </span>
        );
      },
    },
    {
      Header: "Reimbursements",
      accessor: "employeeId.reimbursements",
      disableFilters: true,
      disableSortBy: true,
      Cell: (props) => {
        const reimbursement =
          props.row.original.salaryTempelate &&
          props.row.original.salaryTempelate.reimbTotalMonthly
            ? props.row.original.salaryTempelate.reimbTotalMonthly
            : 0;
        return (
          <span className="cursor-pointer active:text-tertiary active:font-semibold">
            {reimbursement}
          </span>
        );
      },
    },
    {
      Header: "Net Pay",
      accessor: "netPay",
      disableFilters: true,
      disableSortBy: true,
      Cell: (props) => {
        const grossSalary =
          props.row.original.salaryTempelate &&
          props.row.original.salaryTempelate.netPayMonthly
            ? props.row.original.salaryTempelate.netPayMonthly
            : 0;
        return (
          <span className="cursor-pointer active:text-tertiary active:font-semibold">
            {grossSalary}
          </span>
        );
      },
    },
    {
      Header: "Options",
      disableFilters: true,
      disableSortBy: true,
      Cell: (props) => {
        const dropdownRef = useRef(null);
        const [showOptions, setShowOptions] = useState(false);
        useEffect(() => {
          const handleClickOutside = (event) => {
            if (
              dropdownRef.current &&
              !dropdownRef.current.contains(event.target)
            ) {
              setShowOptions(false);
            }
          };

          window.addEventListener("click", handleClickOutside);

          return () => {
            window.removeEventListener("click", handleClickOutside);
          };
        }, []);

        const handlePenClick = (e) => {
          e.stopPropagation();
          setShowOptions(!showOptions);
        };
        return (
          <div className="relative">
            <SlOptionsVertical
              className="text-tertiary text-4xl m-auto"
              style={{ cursor: "pointer" }}
              onClick={handlePenClick}
            />
            <div
              ref={dropdownRef}
              className={` ${
                showOptions
                  ? "flex flex-col p-4 rounded-md gap-2 absolute right-10 top-14 bg-primaryDark z-10  w-96  h-80 overflow-y-scroll"
                  : "hidden"
              } ${
                theme === "light"
                  ? "bg-white shadow-primaryDark shadow-2xl "
                  : ""
              }`}
            >
              <span
                className={
                  "   rounded-md p-4 cursor-pointer " +
                  `${
                    theme == "light"
                      ? "hover:bg-wheat"
                      : "hover:bg-primaryDark2"
                  }`
                }
                onClick={() => handleSkipEmp(props?.row?.original?._id)}
              >
                Skip from this payroll
              </span>
              <span
                className={
                  "   rounded-md p-4 cursor-pointer " +
                  `${
                    theme == "light"
                      ? "hover:bg-wheat"
                      : "hover:bg-primaryDark2"
                  }`
                }
              >
                Withhold Salary
              </span>
              <span
                className={
                  "   rounded-md p-4 cursor-pointer " +
                  `${
                    theme == "light"
                      ? "hover:bg-wheat"
                      : "hover:bg-primaryDark2"
                  }`
                }
              >
                Revise Salary
              </span>
              <span
                className={
                  "   rounded-md p-4 cursor-pointer " +
                  `${
                    theme == "light"
                      ? "hover:bg-wheat"
                      : "hover:bg-primaryDark2"
                  }`
                }
              >
                View TDS Sheet
              </span>
              <span
                className={
                  "   rounded-md p-4 cursor-pointer " +
                  `${
                    theme == "light"
                      ? "hover:bg-wheat"
                      : "hover:bg-primaryDark2"
                  }`
                }
              >
                Terminate this Employee
              </span>
              <span
                className={
                  "   rounded-md p-4 cursor-pointer " +
                  `${
                    theme == "light"
                      ? "hover:bg-wheat"
                      : "hover:bg-primaryDark2"
                  }`
                }
              >
                View Employee Details
              </span>
            </div>
          </div>
        );
      },
    },
  ];

  const columns2 = useMemo(() => {
    const col = [
      {
        Header: "Employee Name",
        accessor: "",
        Cell: (props) => {
          return (
            <span className="cursor-pointer active:text-tertiary active:font-semibold">
              {props?.row?.original?.employeeDetails?._id?.firstName +
                " " +
                props?.row?.original?.employeeDetails?._id?.lastName}
            </span>
          );
        },
      },
      {
        Header: "Paid Days",
        accessor: "paidDays",
        Cell: (props) => {
          return (
            <span className="cursor-pointer active:text-tertiary active:font-semibold">
              {props?.row?.original?.employeeDetails?.payableDays}
            </span>
          );
        },
      },
      {
        Header: "Payment Mode",
        accessor: "paymentMode",
        Cell: (props) => {
          return (
            <span
              className="cursor-pointer active:text-tertiary active:font-semibold"
              onClick={() => {
                setSelectedEmployee(props?.row?.original);
                setInitialSelectedEmp(props?.row?.original);
                setShowSelectedEmployee(true);
              }}
            >
              {props?.row?.original?.employeeDetails?._id?.paymentDetails
                ?.paymentType
                ? props?.row?.original?.employeeDetails?._id?.paymentDetails
                    ?.paymentType
                : "Not Assigned"}
            </span>
          );
        },
      },
      {
        Header: "Net Pay",
        accessor: "netPay",
        Cell: (props) => {
          const grossSalary =
            props?.row?.original?.employeeDetails?.salaryTempelate &&
            props?.row?.original?.employeeDetails?.salaryTempelate
              ?.netPayMonthly
              ? props?.row?.original?.employeeDetails?.salaryTempelate
                  ?.netPayMonthly
              : 0;
          return (
            <span className="cursor-pointer active:text-tertiary active:font-semibold flex gap-2 justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M13.725 21L7 14v-2h3.5q1.325 0 2.288-.862T13.95 9H6V7h7.65q-.425-.875-1.263-1.437T10.5 5H6V3h12v2h-3.25q.35.425.625.925T15.8 7H18v2h-2.025q-.2 2.125-1.75 3.563T10.5 14h-.725l6.725 7z"
                />
              </svg>
              <span>{grossSalary}</span>
            </span>
          );
        },
      },
      {
        Header: "Status",
        Cell: () => {
          return (
            <>
              {!isPaymentSubmitted ? (
                <span>Yet to Pay</span>
              ) : (
                <span className="text-green-500">Paid on {paymentDate}</span>
              )}
            </>
          );
        },
      },
    ];
    // if(isPaymentSubmitted) col.splice(-2,0,{
    //     Header: "Pay Slip",
    //     Cell: (props) => {
    //       console.log(props.row.original, "PY");
    //       return (
    //         <a className="flex items-center justify-center cursor-pointer" href={props?.row?.original?.salarySlipUrl} target="_blank">
    //           <IoEye
    //             className="text-tertiary"
    //             size={25}
    //           />
    //         </a>
    //       );
    //     },
    //   },)
    return col;
  }, [isPaymentSubmitted]);

  const theme = useSelector((state) => state.theme.theme);

  const [isEmployeeSummaryActive, setIsEmployeeSummaryActive] = useState(true);
  const [isAdditionalDeductionActive, setIsAdditionalDeductionActive] =
    useState(false);

  const [isApproved, setIsApproved] = useState(false);

  const [showImportOptions, setShowImportOptions] = useState(false);

  const [showApproveDialog, setShowApproveDialog] = useState(false);

  const [showSubmitPaymentDialog, setShowSubmitPaymentDialog] = useState(false);

  const [showMailPayslipDialog, setShowMailPayslipDialog] = useState(false);
  const [isPayslipMailed, setIsPayslipMailed] = useState(false);

  const [showSelectedEmployee, setShowSelectedEmployee] = useState(false);

  const [showLop, setShowLop] = useState(false);
  const [showAdditionalDetails, setShowAdditionalDetails] = useState(false);

  const [monthlySalary, setMonthlySalary] = useState(0);

  // const [earnData, setEarnData] = useState([]);

  // const [basicAmount, setBasicAmount] = useState(0);

  // const [earningTotal, setEarningTotal] = useState(0);

  // const [newEarningsTotal, setNewEarningsTotal] = useState(0);

  // const [newDeductions, setNewDeductions] = useState([]);

  // const [newDeductionTotal, setNewDeductionTotal] = useState(0);
  // const [employeeNetPay, setEmployeeNetPay] = useState(0);

  // const [allEarningTotal, setAllEarningTotal] = useState(0);

  // const [grossSalary, setGrossSalary] = useState(0);
  // const [newReimbursements, setNewReimbursements] = useState([]);

  // const [newReimbTotal, setNewReimbTotal] = useState(0);
  // const [taxRate, setTaxRate] = useState(0);

  // const [tax, setTax] = useState(0);
  // const [pt, setPt] = useState(0);
  // const [initialSelectedEmp, setInitialSelectedEmp] = useState(null);
  const [deductionTotal, setDeductionTotal] = useState(0);

  const [reimbTotal, setReimbTotal] = useState(0);

  const [netPay, setNetPay] = useState(0);

  const [paymentDate, setPaymentDate] = useState(0);

  const [clickedEarnings, setClickedEarnings] = useState([]);

  const [incomeTax, setIncomeTax] = useState(0);

  const [earning, setEarning] = useState([]);

  const [deduction, setDeduction] = useState([]);

  const [reimbursements, setReimbursements] = useState([]);

  const [gross, setGross] = useState(0);

  const [monthlyEarningTotal, setMonthlyEarningTotal] = useState(0);

  const [responseMessage, setResponseMessage] = useState("");

  const [fetchedEmp, setFetchedEmp] = useState([]);

  const [earnTotal, setEarnTotal] = useState(null);

  const [deductTotal, setDeductTotal] = useState(null);

  const [totalTaxes, setTotalTaxes] = useState(0);

  const [allTotalDeduction, setAllTotalDeduction] = useState(0);

  const [loanDetails, setLoanDetails] = useState([]);

  const [advSalaryDetails, setAdvSalaryDetails] = useState([]);

  const [showEarnModal, setshowEarnModal] = useState(false);

  const [selectedearn, setselectedearn] = useState();

  const [showDedModal, setshowDedModal] = useState(false);

  const [selectedDed, setselectedDed] = useState(false);

  const [financialYearGross, setFinancialYearGross] = useState(0);
  const [showLopSaveBtn, setShowLopSaveBtn] = useState(false);

  const [prevPayableDays, setPrevPayableDays] = useState(0);

  useEffect(() => {
    setEarnings(selectedEmployee?.salaryTempelate?.earnings);
    setDeductions(selectedEmployee?.salaryTempelate?.deductions);
    setReimbursement(
      Array.isArray(selectedEmployee?.salaryTempelate?.reimbursements)
        ? selectedEmployee?.salaryTempelate?.reimbursements
        : [],
    );
    setMonthlySalary(selectedEmployee?.salaryTempelate?.monthlyTotal);
    setMonthlyEarningTotal(selectedEmployee?.salaryTempelate?.monthlyTotal);
    setLops(selectedEmployee?.payableDays);
    setEarnTotal(selectedEmployee?.salaryTempelate?.earningTotal);
    setDeductTotal(selectedEmployee?.salaryTempelate?.deductionTotal);
    setLoanDetails(selectedEmployee?.loanDetails);
    setAdvSalaryDetails(selectedEmployee?.advSalaryDetails);
    setProfessionalTax(selectedEmployee?.employeeId?.ptDetails);
    IncomeTaxTotal({
      id: selectedEmployee?.employeeId?._id,
      month: month?.value,
      year: year,
    })
      .then(({ data }) => {
        setFinancialYearGross(data?.financialYearGross);
      })
      .catch((error) => {
        setFinancialYearGross(0);
      });
  }, [selectedEmployee, payableDays]);

  useEffect(() => {
    if (
      earnings &&
      deductions &&
      monthlySalary &&
      payableDays &&
      paidDays &&
      month
    ) {
      calculatePayrunData({
        earnings,
        deductions,
        reimbursement,
        monthlySalary,
        professionalTax,
        lops,
        paidDays,
        month,
        incomeTax,
        monthlyEarningTotal,
        earnTotal,
        deductTotal,
        financialYearGross,
        loanDetails,
        year: year,
      }).then(({ data }) => {
        setEarning(data?.earnings);
        setDeduction(data?.deduction);
        setReimbursements(data?.reimbursement ? data?.reimbursement : []);
        setGross(data?.earningTotal);
        setNetPay(data?.netPayMonthly);
        setDeductionTotal(data?.totalDeduction);
        setReimbTotal(data?.TotalReimb);
        setTotalTaxes(data?.totalTaxes);
        setAllTotalDeduction(data?.allTotalDeduction);
        setPrevPayableDays(data?.prevPayableDays);
      });
    }
  }, [
    earnings,
    deductions,
    reimbursement,
    selectedEmployee,
    lops,
    professionalTax,
    financialYearGross,
  ]);

  const handleEarningAmountChange = (id, amount) => {
    let monthly = 0;
    const updatedEarnings = earning.map((ele) => {
      if (ele._id == id) {
        monthly = Number(amount);
        return { ...ele, amount: amount, monthly: monthly };
      } else {
        return ele;
      }
    });
    setEarnings(updatedEarnings);
  };

  const handleRemoveEarning = (id) => {
    const updatedEarnings = earnings.filter((el) => el._id != id);
    setEarnings(updatedEarnings);
    setClickedEarnings(clickedEarnings.filter((id) => id !== id));
  };

  const handleAddDeduction = (e) => {
    if (e?.name == "Professional Tax") {
      const d = deductions.filter((el) => el?.name == e?.name);
      if (d.length > 0) {
        return customToast.error("Deduction Already exists");
      } else {
        setDeductions([...deductions, e]);
        fetchPt({
          state: selectedEmployee?.state?.name,
          userId: selectedEmployee?.employeeId?._id,
        })
          .then(({ data }) => {
            setProfessionalTax(data);
          })
          .catch((error) => setProfessionalTax(null));
      }
    } else if (deductions.some((el) => el?._id === e?._id)) {
      return customToast.error("Deduction Already exists");
    } else {
      let updatedDeduction;
      let monthly = 0;
      let yearly = 0;
      let iseditable = 1;
      updatedDeduction = { ...e, yearly, monthly, iseditable };
      setDeductions([...deductions, updatedDeduction]);
    }
  };

  const handleDeductionChange = (id, value, type) => {
    const updatedDeductions = deduction.map((deduction) => {
      if (deduction._id === id) {
        let monthly = 0;
        let yearly = 0;

        if (type === "monthly") {
          monthly = Number(value);
        } else if (type === "yearly") {
          monthly = Number(yearly / 12);
        }
        return { ...deduction, monthly };
      }
      return deduction;
    });
    setDeductions(updatedDeductions);
  };

  const handleRemoveDeduction = (id) => {
    const updatedDeductions = deductions.filter((el) => el._id != id);
    setDeductions(updatedDeductions);
  };

  const handleReimbursementAmount = (id, amount) => {
    const updatedReimbursement = reimbursements?.map((reimb) => {
      if (reimb?._id === id) {
        if (amount <= reimb.limitPerMonth) {
          const monthly = amount;
          return { ...reimb, monthly };
        } else {
          customToast.error("max limit exceed");
          return { ...reimb, monthly: reimb.limitPerMonth };
        }
      }
      return reimb;
    });
    setReimbursement(updatedReimbursement);
  };

  const handleAddReimburse = (e) => {
    const reimb = reimbursements.filter((el) => el._id == e._id);
    if (reimb.length > 0) {
      return customToast.error("Reimbursement already exist");
    }
    const updatedReimbursment = [
      ...reimbursements,
      { ...e, monthly: 0, yearly: 0, editable: 1 },
    ];
    setReimbursement(updatedReimbursment);
    setShowReimb(false);
  };

  const handleRemoveReimbursement = (id) => {
    const updatedReimbursements = reimbursements.filter((el) => el._id != id);
    setReimbursement(updatedReimbursements);
    setClickedEarnings(clickedEarnings.filter((id) => id !== id));
  };

  const handleSave = async (id) => {
    let hasInvalidFixedAllowance = false;

    earning.map((el) => {
      if (el.earningType.name == "Fixed Allowance" && el.monthly <= 0) {
        hasInvalidFixedAllowance = true;
        setShowSelectedEmployee(true);
        return customToast.error(
          "Fixed Allowances can not pe less or Equal to zero",
        );
      }
    });

    if (hasInvalidFixedAllowance) {
      return;
    }

    const updatedEmployeeData = allEmpData.map((el) => {
      if (el.employeeId._id === id && el.salaryTempelate) {
        return {
          ...el,
          salaryTempelate: {
            ...el.salaryTempelate,
            earnings: [...earning],
            deductions: [...deduction],
            reimbursements: reimbursement ? [...reimbursement] : [],
            earningTotalMonthly: gross,
            deductionTotalMonthly: deductionTotal,
            reimbTotalMonthly: reimbTotal,
            netPayMonthly: netPay,
            totalTaxes: totalTaxes,
            allTotalDeduction: allTotalDeduction,
          },
          payableDays: lops,
          lops: lopDays,
          additionalDetails: selectedEmployee?.additionalDetails,
        };
      }
      return el;
    });
    console.log(updatedEmployeeData, "Updated Employees");

    setAllEmpData(updatedEmployeeData);
    setLopDays(0);
    setLops(0);
    setShowAdditionalDetails(false);
    setShowLop(false);
    setSelectedEmployee([]);
    setShowSelectedEmployee(false);
  };

  const handleApprove = async () => {
    try {
      // Update income tax for all employees
      await Promise.all(
        allEmpData?.map(async (el) => {
          try {
            const { salaryTempelate, employeeId, lops } = el;
            await UpdateIncomeTaxTotal({
              employee: employeeId,
              earning: salaryTempelate?.earnings,
              deduction: salaryTempelate?.deductions,
              ctc: salaryTempelate?.ctc,
              earningTotalMonthly: salaryTempelate?.earningTotalMonthly,
              year,
              month: month.value,
              lops,
            });
          } catch (innerError) {
            console.warn(
              `Error updating income tax for employee ${el.employeeId}:`,
              innerError,
            );
          }
        }),
      );

      // Prepare employee details payload
      const employeeDetails = allEmpData.map((employee) => {
        const { _id: employeeId } = employee.employeeId;
        const {
          salaryTempelate,
          payableDays,
          lops,
          loanDetails,
          advSalaryDetails,
          additionalDetails,
        } = employee;
        const { netPayMonthly } = employee.salaryTempelate;
        return {
          employeeId,
          salaryTempelate,
          netPayMonthly,
          payableDays,
          lops,
          loanDetails,
          advSalaryDetails,
          additionalDetails,
        };
      });

      // Create payrun payload
      const payload = {
        employeeDetails,
        totalNetPay,
        totalPayrunCost,
        paymentDate,
        paidStatus: false,
        month: month.value,
        year,
      };

      // Create payrun
      const response = await createPayrun(payload);
      if (response) {
        setResponseMessage(response);
        customToast.success("Successfully submitted this month's payrun");
      } else {
        customToast.error("Error in submitting payrun");
      }
    } catch (error) {
      console.error("Error in submitting payroll:", error);
      customToast.error("Error in submitting payroll");
    }
  };

  const handleSubmit = async () => {
    try {
      if (!paymentDate) {
        return customToast.error("Select Payment Date !");
      }
      const updatedLoanData = fetchedEmp?.flatMap((el) => {
        const { employeeDetails } = el;
        const loanDetail = employeeDetails.loanDetails;
        return loanDetail;
      });
      const updatedAdvSalaryData = fetchedEmp?.flatMap((el) => {
        const { employeeDetails } = el;
        const advSalaryDetail = employeeDetails.advSalaryDetails;
        return advSalaryDetail;
      });
      if (!paymentScheduleDetails) return;

      const updatedDetails = { ...paymentScheduleDetails };

      if (updatedDetails?.firstPayMonth) {
        if (updatedDetails?.firstPayMonth?.month == 11) {
          updatedDetails.firstPayMonth.month = 0;
          updatedDetails.firstPayMonth.year =
            Number(updatedDetails.firstPayMonth.year) + 1;
        } else {
          updatedDetails.firstPayMonth.month = month.value + 1;
          updatedDetails.firstPayMonth.year = updatedDetails.firstPayMonth.year;
        }
      }

      if (updatedDetails?.paymentMonth) {
        if (updatedDetails.firstPayMonth.month === 11) {
          let obj = {
            month: 0,
            year: Number(updatedDetails.firstPayMonth.year) + 1,
          };
          updatedDetails.paymentMonth = obj;
        } else {
          if (updatedDetails.makePaymentOn.type === "lastWorkingDay") {
            let obj = {
              month: updatedDetails.firstPayMonth.month,
              year: updatedDetails.firstPayMonth.year,
            };
            updatedDetails.paymentMonth = obj;
          } else {
            let obj = {
              month: Number(updatedDetails.paymentMonth.month) + 1,
              year: updatedDetails.paymentMonth.year,
            };
            updatedDetails.paymentMonth = obj;
          }
        }
      }

      if (updatedDetails.salaryCalculationBasis) {
        if (updatedDetails.salaryCalculationBasis.type === "company") {
          updatedDetails.salaryCalculationBasis =
            updatedDetails.salaryCalculationBasis;
        } else {
          const month = updatedDetails.firstPayMonth.month;
          const year = parseInt(updatedDetails.firstPayMonth.year);
          // const isLeapYear =
          //   (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
          const daysInMonth = returnDaysInMonth(month, year);
          // month === 1 ? (isLeapYear ? 29 : 28) : month % 2 === 0 ? 31 : 30;
          updatedDetails.salaryCalculationBasis = {
            ...updatedDetails.salaryCalculationBasis,
            value: daysInMonth,
          };
        }
      }

      if (updatedDetails.makePaymentOn) {
        if (updatedDetails.makePaymentOn.type === "dayOfFollowingMonth") {
          updatedDetails.makePaymentOn = updatedDetails.makePaymentOn;
        } else {
          const date = new Date(
            parseInt(updatedDetails.firstPayMonth.year),
            parseInt(updatedDetails.firstPayMonth.month),
            parseInt(updatedDetails.salaryCalculationBasis.value),
          );
          let day = date.getDay();
          const newVal = updatedDetails?.selectedDay.find((d) => {
            return d.value == day;
          });
          if (newVal) {
            updatedDetails.makePaymentOn = {
              ...updatedDetails.makePaymentOn,
              value: updatedDetails?.salaryCalculationBasis.value,
            };
          } else {
            if (day == 0) {
              day = 7;
            }
            let difference = updatedDetails.selectedDay.length - day;
            if (difference < 0) {
              difference = difference * -1;
            }
            const newValue =
              difference <= 0
                ? updatedDetails.salaryCalculationBasis.value
                : updatedDetails.salaryCalculationBasis.value - difference;
            updatedDetails.makePaymentOn = {
              ...updatedDetails.makePaymentOn,
              value: newValue,
            };
          }
        }
      }
      const resp = await CreatePaymentSchedule(updatedDetails);

      const payload = {
        loanDetail: updatedLoanData,
        advSalaryDetail: updatedAdvSalaryData,
        approveStatus: 0,
        month: month.value,
        year,
        paymentDate,
        paidStatus: true,
      };
      const response = await updatePayrunApproveStatus(payload);
      if (response && resp) {
        return customToast.success("Successfully submitted this month payrun");
      } else {
        return customToast.error("error in submitting oayrun");
      }
    } catch (error) {
      customToast.error("Error in Submitting payroll");
    }
  };

  const handleEarningClick = (earningId) => {
    if (!clickedEarnings.includes(earningId)) {
      setClickedEarnings([...clickedEarnings, earningId]);
    } else {
      setClickedEarnings(clickedEarnings.filter((id) => id !== earningId));
    }
  };
  useEffect(() => {
    if ((status == 1 || responseMessage) && month && year) {
      getPayrunWithMonthAndYear({ month: month.value, year: year }).then(
        ({ data }) => setFetchedEmp(data),
      );
    }
  }, [status, responseMessage, month, year]);

  const handleLopInputChange = (e, emp) => {
    let lopDays = Number(e.target.value);
    let day = 0;
    emp.payableDays = Number(emp.prevPayableDays) - lopDays;
    day = emp?.payableDays;
    setSelectedEmployee(emp);
    // setPayableDays(day);
    setLops(day);
    setLopDays(lopDays);
  };

  const handleCancelSelectedEmp = (id) => {
    //   const updatedEmployeeData = allEmpData.map((el) => {
    //     if (el.employeeId._id === id) {
    //         return initialSelectedEmp;
    //     }
    //     return el;
    // });
    //      setAllEmpData(updatedEmployeeData)
    setLopDays(0);
    setSelectedEmployee([]);
    setShowSelectedEmployee(false);
  };

  useEffect(() => {
    if (fetchedEmp && fetchedEmp.length > 0 && status == 1) {
      let totalPayrunCost = 0;
      let totalNetPay = 0;
      let totalDeduction = 0;
      let totalTaxes = 0;
      let cheque = 0;
      let bankTransfer = 0;
      let notAssignCount = 0;

      totalPayrunCost = totalPayrunCost + Number(fetchedEmp[0].totalPayrunCost);
      totalNetPay = totalNetPay + Number(fetchedEmp[0].totalNetPay);
      setTotalPayrunCost(totalPayrunCost.toFixed(2));
      setTotalNetPay(totalNetPay.toFixed(2));
      setTotalEmp(fetchedEmp?.length);

      fetchedEmp.map((el) => {
        totalDeduction =
          totalDeduction + el?.employeeDetails.salaryTempelate?.deductionTotal;
        totalTaxes =
          totalTaxes + el?.employeeDetails.salaryTempelate?.totalTaxes;
        // if (el?.employeeId?.paymentDetails?.payType == "cheque") {
        //   cheque = cheque + 1;
        // } else if (el?.employeeId?.paymentDetails?.payType == "bankTransfer") {
        //   bankTransfer = bankTransfer + 1;
        // } else {
        //   notAssignCount = notAssignCount + 1;
        // }
      });
      totalDeduction = totalDeduction.toFixed(2);
      totalTaxes = totalTaxes.toFixed(2);
      setPreTaxDeductionTotal(totalDeduction);
      setPostTaxDeductionTotal(totalTaxes);
      // setChequeCount(cheque);
      // setBankTransferCount(bankTransfer);
    }
  }, [fetchedEmp]);

  return (
    <div className="main-content pl-10 bg-primaryLight2 dark:bg-primaryDark1 ">
      {allEmpData?.length < 1 ? (
        <div className="coming-soon  absolute inset-0 text-center flex flex-col  items-center justify-center">
          <h1 className="text-[2rem] text-bold">CALCULATION LOADING.....</h1>
        </div>
      ) : null}
      <div className="creation-content">
        <PageTitle title="PAYRUN INFO" route={"../../payrun-info"} />
      </div>

      <div className="payrun-info-title flex items-center justify-between mr-8  ">
        <h3 className="!text-primaryDark dark:!text-primaryLight2 flex items-center">
          Process Payrun For {month?.month} {year}
          {isApproved || status ? (
            <span className="flex items-center gap-10">
              <span className="!text-cyan font-semibold">Approved</span>
              <Link to="/payrun/manage-payrun/payrun-info/create/bank-advice">
                <button className="button-blue-gradient">
                  Download Payment Advice
                </button>
              </Link>
            </span>
          ) : (
            <span></span>
          )}
        </h3>
        <div>
          {isApproved || status ? (
            isPaymentSubmitted ? (
              isPayslipMailed ? (
                <button className="button-green-gradient">
                  DownLoad Payslip
                </button>
              ) : (
                <button
                  className="button-green-gradient"
                  onClick={() => {
                    setShowMailPayslipDialog(true);
                  }}
                >
                  Email Payslip
                </button>
              )
            ) : (
              <button
                className="button-green-gradient"
                onClick={() => {
                  setShowSubmitPaymentDialog(true);
                }}
              >
                Submit Pay Run
              </button>
            )
          ) : (
            <button
              className="button-green-gradient"
              onClick={() => {
                setShowApproveDialog(true);
              }}
            >
              Approve
            </button>
          )}
        </div>
      </div>

      <div className="mt-10 mr-8 grid grid-cols-2 grid-rows-1 gap-x-6 gap-y-10 mb-20">
        <div className={"bg-cyan rounded-md p-10"}>
          <div className="flex flex-col gap-6 mb-8">
            <p>{totalEmp} EMPLOYEES</p>
            <p>
              Period: {month?.month} {year} |{" "}
              {paymentScheduleDetails?.salaryCalculationBasis?.value} Base Days
            </p>
            {/* <p>Pay Day: {paymentScheduleDetails?.makePaymentOn.value} {payDayMonth}, {paymentYear}</p> */}
          </div>
          <div className="flex justify-between">
            <div className="flex flex-col items-center gap-8">
              <h1 className="text-6xl font-bold drop-shadow">
                Rs {totalNetPay}
              </h1>
              <p>EMPLOYEE NET PAY</p>
            </div>
            <div className="flex flex-col items-center gap-8">
              <h1 className="text-6xl font-bold drop-shadow">
                Rs {totalPayrunCost}
              </h1>
              <p>PAYRUN COST</p>
            </div>
          </div>
        </div>
        <div
          className={
            "bg-tertiary rounded-md p-10 flex flex-col gap-10 uppercase"
          }
        >
          <h3 className="!p-0">TAXES AND DEDUCTIONS</h3>
          <p className=" flex gap-20">
            <span>Total Deductions</span>
            <span>Rs {preTaxDeductionTotal}</span>
          </p>
          <p className=" flex gap-24">
            <span>Total Taxes</span>
            <span>Rs {postTaxDeductionTotal}</span>
          </p>
        </div>
      </div>

      <div className=" mr-10 mb-10">
        <div className="flex justify-between mt-10">
          <div className="flex items-center justify-center">
            <button
              className={
                "border-b-4 p-4 text-gray-400 " +
                `${
                  isEmployeeSummaryActive &&
                  " border-cyan text-tertiary font-bold"
                }`
              }
              onClick={() => {
                setIsEmployeeSummaryActive(true);
                setIsAdditionalDeductionActive(false);
              }}
            >
              Employee Summary
            </button>
            <button
              className={
                "border-b-4 p-4 text-gray-400 " +
                `${
                  isAdditionalDeductionActive &&
                  " border-cyan text-tertiary font-bold"
                }`
              }
              onClick={() => {
                setIsEmployeeSummaryActive(false);
                setIsAdditionalDeductionActive(true);
              }}
            >
              Additional Deduction
            </button>
          </div>
          <div className="relative">
            <button
              className="button-red-gradient"
              onClick={() => setShowImportOptions(!showImportOptions)}
            >
              Import
            </button>
            <div
              className={` ${
                showImportOptions
                  ? " flex flex-col p-4 rounded-md gap-2 absolute right-20 top-20 z-10  w-96  overflow-y-scroll"
                  : " hidden"
              } ${
                theme === "light"
                  ? "bg-white shadow-primaryDark shadow-2xl "
                  : "bg-primary"
              }`}
            >
              <span
                onClick={() => {
                  navigate("oted");
                }}
                className={
                  "   rounded-md p-4 cursor-pointer " +
                  `${
                    theme == "light"
                      ? "hover:bg-wheat"
                      : "hover:bg-primaryDark2"
                  }`
                }
              >
                One time Earning and Deduction
              </span>
              <span
                onClick={() => {
                  navigate("lop");
                }}
                className={
                  "   rounded-md p-4 cursor-pointer " +
                  `${
                    theme == "light"
                      ? "hover:bg-wheat"
                      : "hover:bg-primaryDark2"
                  }`
                }
              >
                LOP Details
              </span>
            </div>
          </div>
        </div>
      </div>

      {isEmployeeSummaryActive && empData && (
        <section
          className={"area-creation-content__info py-0 p-0  mr-10 mb-20"}
        >
          {empData.length > 0 && (
            <h2
              className={
                "mt-5 pt-5 ms-2 web-app__heading " +
                `${theme === "light" ? "light_bg_logo" : ""}`
              }
            >
              Showing and ({allEmpData.length}) Entries
            </h2>
          )}

          {allEmpData.length > 0 && (
            <div
              className={
                "filter-table " + `${theme === "light" ? "!bg-white" : ""}`
              }
            >
              {(isApproved && responseMessage) || status ? (
                <Table columns={columns2} data={fetchedEmp} />
              ) : (
                <Table columns={columns} data={allEmpData} />
              )}
            </div>
          )}
        </section>
      )}

      {isAdditionalDeductionActive && (
        <>
          <div className="row">
            <div className=" mt-5">
              <label>
                Deduction <span className="asterisk-imp">*</span>
              </label>
              <br />
              <input
                type="number"
                className="p-4 outline-none bg-primaryLight rounded-md"
                placeholder="Enter Amount"
                onChange={(e) => setAdditionalDeduction(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <button
              onClick={handleAddDeduction}
              className="button-blue-gradient"
            >
              Add
            </button>
          </div>
          <section
            className={"area-creation-content__info py-0 p-0  mr-10 mb-20"}
          >
            {empData.length > 0 && (
              <h2
                className={
                  "mt-5 pt-5 ms-2 web-app__heading " +
                  `${theme === "light" ? "light_bg_logo" : ""}`
                }
              >
                Showing ({empData.length}) Entries
              </h2>
            )}

            {empData.length > 0 && (
              <div
                className={
                  "filter-table " + `${theme === "light" ? "!bg-white" : ""}`
                }
              >
                <Table columns={columns} data={empData} />
              </div>
            )}
          </section>
        </>
      )}

      <div className={`${showApproveDialog ? "modal-overlay" : "hidden"}`}>
        <div
          className={
            " modal " +
            `${theme == "light" ? " !bg-white" : " !bg-primaryDark2"}`
          }
        >
          <div className="flex items-center justify-between py-10 px-16 border-b-4 border-b-primaryDark">
            <h2 className="text-3xl font-bold">Approve Payroll</h2>
            <RxCross1
              size="30"
              onClick={() => {
                setShowApproveDialog(false);
              }}
              className="cursor-pointer text-tertiary"
            />
          </div>
          <div className="py-10 px-16 flex flex-col gap-10">
            <p>
              You are about to approve payroll for the month of {month.month}{" "}
              {year}.
              <br />
              You will be able to pay your Employees on the payment date once
              the payroll is submitted
            </p>
            <div className="flex items-center gap-10">
              <button
                className="px-8 py-2 text-white bg-tertiary rounded-xl"
                onClick={() => {
                  setIsApproved(true);
                  setShowApproveDialog(false);
                  handleApprove();
                }}
              >
                Approve
              </button>
              <span
                className="text-cyan cursor-pointer"
                onClick={() => {
                  setShowApproveDialog(false);
                }}
              >
                Cancel
              </span>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`${showSubmitPaymentDialog ? "modal-overlay" : "hidden"}`}
      >
        <div
          className={
            " modal " +
            `${theme == "light" ? " !bg-white" : " !bg-primaryDark2"}`
          }
        >
          <div className="flex items-center justify-between py-10 px-16 border-b-4 border-b-primaryDark">
            <h2 className="text-3xl font-bold">Submit Payment</h2>
            <RxCross1
              size="30"
              onClick={() => {
                setShowSubmitPaymentDialog(false);
              }}
              className="cursor-pointer text-tertiary"
            />
          </div>

          <div className="flex items-start justify-start flex-col gap-4 py-10 px-16 border-b-4 border-b-primaryDark">
            <p>You are about to submit the payment for this pay run. </p>
            <span>
              Pay Employees On {"  "}
              <input
                className={theme == "light" ? "!bg-white" : "!bg-primaryDark1"}
                type="date"
                onChange={(e) => setPaymentDate(e.target.value)}
              />
            </span>
          </div>

          {/* <div className="px-10 py-4 border-b-4 border-b-primaryDark">
            <table>
              <thead>
                <tr>
                  <th className="p-4">Payment Mode</th>
                  <th className="p-4">No Of Employees</th>
                </tr>
              </thead>
              <tbody>
              </tbody>
            </table>
          </div> */}

          <div className="flex items-center gap-10 p-10">
            <button
              className="px-8 py-2 text-white bg-tertiary rounded-xl"
              onClick={() => {
                setIsPaymentSubmitted(true);
                setShowSubmitPaymentDialog(false);
                handleSubmit();
              }}
            >
              Submit
            </button>
            <span
              className="text-cyan cursor-pointer"
              onClick={() => {
                handleCancel();
                setShowSubmitPaymentDialog(false);
              }}
            >
              Cancel
            </span>
          </div>
        </div>
      </div>

      <div className={`${showMailPayslipDialog ? "modal-overlay" : "hidden"}`}>
        <div
          className={
            " modal " +
            `${theme == "light" ? " !bg-white" : " !bg-primaryDark2"}`
          }
        >
          <div className="flex items-center justify-between py-10 px-16 border-b-4 border-b-primaryDark">
            <h2 className="text-3xl font-bold">Mail PaySlip</h2>
            <RxCross1
              size="30"
              onClick={() => {
                setShowMailPayslipDialog(false);
              }}
              className="cursor-pointer text-tertiary"
            />
          </div>

          <div className="flex items-start justify-start flex-col gap-4 py-10 px-16">
            <p>
              You are about to email the payslip to the Employees for this
              month.
              <br /> Are you sure you want to send the payslip.
            </p>
          </div>

          <div className="flex items-center gap-10 p-10">
            <button
              className="px-8 py-2 text-white bg-tertiary rounded-xl"
              onClick={() => {
                setIsPayslipMailed(true);
                setShowMailPayslipDialog(false);
              }}
            >
              Send
            </button>
            <span
              className="text-cyan cursor-pointer"
              onClick={() => {
                handleCancel();
                setShowMailPayslipDialog(false);
              }}
            >
              Cancel
            </span>
          </div>
        </div>
      </div>

      <div className={`${showSelectedEmployee ? "modal-overlay" : "hidden"}`}>
        <div
          className={
            " modal h-full " +
            `${theme == "light" ? " !bg-white" : " !bg-primaryDark"}`
          }
        >
          {selectedEmployee ? (
            <>
              <div
                className={
                  "flex items-center justify-between py-10 px-10 border-b-4 border-b-primaryDark space-x-20  " +
                  `${theme == "light" ? " !bg-white" : " !bg-primaryDark2"}`
                }
              >
                <div className="flex items-center space-x-32">
                  <div className="flex flex-col space-y-2">
                    <span className="text-tertiary font-bold text-4xl">
                      {`${selectedEmployee?.employeeId?.firstName}${" "}${
                        selectedEmployee?.employeeId?.lastName
                      }`}
                    </span>
                    <span className="text-3xl">
                      Employee ID : {selectedEmployee?.employeeId?.employeeId}
                    </span>
                  </div>
                  <div className="flex flex-col space-y-2">
                    <span className="text-4xl">Net Pay :</span>
                    <span className="text-4xl">&#8377; {netPay}</span>
                  </div>
                </div>
                <div className="flex-1  ">
                  <RxCross1
                    size="30"
                    onClick={() => {
                      setSelectedEmployee([]);
                      setShowSelectedEmployee(false);
                    }}
                    className="cursor-pointer text-tertiary"
                  />
                </div>
              </div>
              <div className="h-3/4 overflow-y-scroll">
                <div className="flex items-center space-x-64 ml-10 mt-10">
                  <div className="flex flex-col space-y-32">
                    <span className="">Payable Days :</span>
                  </div>
                  <div className="flex flex-col space-y-2">
                    <span className="">{selectedEmployee?.payableDays}</span>
                  </div>
                </div>
                {showLop ? (
                  <div className="flex items-center space-x-64 ml-10 mt-10">
                    <div className="flex flex-col space-y-32">
                      <span className="">LOP</span>
                    </div>
                    <div className="flex gap-4 space-y-2">
                      <input
                        className="text-center"
                        value={lopDays}
                        onChange={(e) => {
                          // handleLopInputChange(e, selectedEmployee)
                          setShowLopSaveBtn(true);
                          setLopDays(e.target.value);
                        }}
                        type="number"
                      />
                      {showLopSaveBtn && (
                        <IoSave
                          color="#23846C"
                          onClick={() => {
                            const e = { target: { value: lopDays } };
                            handleLopInputChange(e, selectedEmployee);
                            setShowLopSaveBtn(false);
                          }}
                        />
                      )}
                    </div>
                  </div>
                ) : null}
                <div className="flex items-center space-x-64 mt-10 ml-10">
                  <div className="flex gap-2 items-center">
                    <span className="">Add LOP</span>

                    <span className="text-tertiary font-bold text-4xl">
                      <AiOutlinePlusCircle
                        onClick={() => setShowLop(!showLop)}
                      />
                    </span>
                  </div>
                </div>
                <div
                  className={
                    "flex items-center justify-between py-5 px-10 border-b-4 border-b-primaryDark space-x-20 !bg-white dark:!bg-primaryDark2 mt-7"
                  }
                >
                  <div className="flex items-center space-x-64 ">
                    <div className="flex flex-col">
                      <span className="">Add Earnings</span>
                    </div>
                    <div className="flex flex-col">
                      <span className="">Amount</span>
                    </div>
                  </div>
                </div>
                {earning?.map((el, index) => {
                  return el.iseditable == "1" ? (
                    <div
                      className="flex items-center space-x-64 ml-10 mt-10 mr-16"
                      key={index}
                    >
                      <div
                        className="flex flex-col space-y-32 cursor-pointer"
                        onClick={() => {
                          setshowEarnModal(true);
                          setselectedearn(el);
                        }}
                      >
                        <span className="break-words w-44">{el.eName}</span>
                      </div>
                      <div className="flex flex-col space-x-5 w-8">
                        {clickedEarnings.includes(el._id) ? (
                          <div className="flex flex-col space-y-32">
                            <span className="break-words w-44">
                              {el.monthly}
                            </span>
                          </div>
                        ) : (
                          <input
                            type="number"
                            className="dark:bg-primary dark:text-secondary outline-none border-none rounded-lg font-semibold w-32 flex h-16 text-center"
                            value={el?.monthly}
                            onChange={(e) =>
                              handleEarningAmountChange(el._id, e.target.value)
                            }
                          />
                        )}
                      </div>
                      <div className="flex flex-col space-y-2">
                        {clickedEarnings.includes(el._id) ? (
                          <span className="text-red-600">
                            <AiOutlineMinusCircle
                              onClick={() => {
                                handleRemoveEarning(el._id);
                              }}
                            />
                          </span>
                        ) : (
                          <span
                            className="text-green-700"
                            onClick={() => handleEarningClick(el._id)}
                          >
                            <FaSave />
                          </span>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div
                      className="flex items-center space-x-64 ml-10 mt-10 mr-16"
                      key={index}
                    >
                      <div
                        className="flex flex-col space-y-32 cursor-pointer"
                        onClick={() => {
                          setshowEarnModal(true);
                          setselectedearn(el);
                        }}
                      >
                        <span className="break-words w-44">{el.eName}</span>
                      </div>
                      <div className="flex flex-col space-y-2 w-8">
                        <span>{el.monthly}</span>
                      </div>
                    </div>
                  );
                })}

                {deduction?.map((el, index) => {
                  return el?.subtitle === "Employer Contribution" &&
                    el?.componentOnPayslip ? (
                    <div
                      className="flex items-center space-x-64 ml-10 mt-10 mr-16"
                      key={index}
                    >
                      <div
                        className="flex flex-col space-y-32 cursor-pointer"
                        onClick={() => {
                          setshowDedModal(true);
                          setselectedDed(el);
                        }}
                      >
                        <span className="break-words w-44">{el.name}</span>
                      </div>
                      <div className="flex flex-col space-x-5 w-8">
                        <span> {el.monthly} </span>
                      </div>
                    </div>
                  ) : null;
                })}

                {newEarnings?.map((el) => {
                  if (selectedEmployee?._id === el?.userId) {
                    return el.earnings.map((earning, index) => (
                      <div
                        className="flex items-center space-x-64 ml-10 mt-10 mr-16"
                        key={index}
                      >
                        <div className="flex flex-col space-y-32">
                          <span className="break-words w-44">
                            {earning.eName}
                          </span>
                        </div>
                        <div className="flex flex-col space-x-5 w-8">
                          {clickedEarnings.includes(earning._id) ? (
                            <div className="flex flex-col space-y-32">
                              <span className="break-words w-44">
                                {earning.monthly}
                              </span>
                            </div>
                          ) : (
                            <input
                              type="number"
                              className="dark:bg-primary dark:text-secondary outline-none border-none rounded-lg font-semibold w-32 flex h-16 text-center"
                              value={earning?.monthly}
                              onChange={(e) =>
                                handleEarningAmountChange(
                                  earning._id,
                                  e.target.value,
                                  el?.userId,
                                )
                              }
                            />
                          )}
                        </div>
                        <div className="flex flex-col space-y-2">
                          {clickedEarnings.includes(earning._id) ? (
                            <span className="text-red-600">
                              <AiOutlineMinusCircle
                                onClick={() => {
                                  handleRemoveEarning(el?.userId, earning._id);
                                }}
                              />
                            </span>
                          ) : (
                            <span
                              className="text-green-700"
                              onClick={() => handleEarningClick(earning._id)}
                            >
                              <FaSave />
                            </span>
                          )}
                        </div>
                      </div>
                    ));
                  } else {
                    return null;
                  }
                })}

                <div className="flex items-center space-x-64 mt-10 ml-10 relative">
                  <div className="flex gap-2 items-center">
                    <span className="">Add Earning</span>
                    <span className="text-tertiary font-bold text-4xl">
                      <AiOutlinePlusCircle
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowDeduction(false);
                          setShowEarning(!showEarning);
                        }}
                      />
                      <div
                        ref={dropdownRef}
                        className={` ${
                          showEarning
                            ? "flex flex-col p-4 rounded-md gap-2 absolute bg-primaryDark z-10  w-96  h-80 overflow-y-scroll"
                            : "hidden"
                        } ${
                          theme === "light"
                            ? "bg-white shadow-primaryDark shadow-2xl "
                            : ""
                        }`}
                      >
                        {earnSelect.map((data, idx) => (
                          <span
                            className={
                              "   rounded-md p-4 cursor-pointer text-2xl " +
                              `${
                                theme == "light"
                                  ? "hover:bg-wheat"
                                  : "hover:bg-primaryDark2"
                              }`
                            }
                            onClick={(e) => handleAddEarning(data)}
                          >
                            {data.label}
                          </span>
                        ))}
                      </div>
                    </span>
                  </div>
                </div>

                <div
                  className={
                    "flex items-center justify-between py-5 px-10 border-b-4 border-b-primaryDark space-x-20 !bg-white dark:!bg-primaryDark2 mt-7"
                  }
                >
                  <div className="flex items-center space-x-56 ">
                    <div className="flex flex-col">
                      <span className="">Add Deductions</span>
                    </div>
                    <div className="flex flex-col">
                      <span className="">Amount</span>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col ml-10 mt-5 text-tertiary">
                  <span className="">Pre Tax Deductions</span>
                </div>
                {deduction !== undefined
                  ? deduction?.map((el, index) => (
                      <>
                        {el?.subtitle === "Employee Contribution" ||
                        (el?.subtitle === "Employer Contribution" &&
                          el?.includeEmployersContribution &&
                          el?.componentOnPayslip) ? (
                          <div
                            className="flex items-center space-x-64 ml-10 mt-10"
                            key={el._id}
                          >
                            <div
                              className="flex flex-col space-y-32 cursor-pointer"
                              onClick={() => {
                                setshowDedModal(true);
                                setselectedDed(el);
                              }}
                            >
                              <span className="break-words w-44">
                                <span> {el?.name} </span>
                                <br />
                                <span className="text-base text-tertiary font-bold">
                                  {el?.subtitle == "Employer Contribution"
                                    ? "Employer"
                                    : "Employee"}
                                </span>
                              </span>
                            </div>
                            <div className="flex flex-col space-y-2">
                              <span>{el.monthly}</span>
                            </div>
                          </div>
                        ) : null}
                        {el.categorie === "Pre-Tax Deduction" ? (
                          el.iseditable == "1" ? (
                            <div
                              className="flex items-center space-x-64 mr-16"
                              key={el._id}
                            >
                              {clickedEarnings.includes(el._id) ? (
                                <div
                                  className="flex items-center space-x-64 ml-10 mt-10"
                                  key={el._id}
                                >
                                  <div
                                    className="flex flex-col space-y-32 cursor-pointer"
                                    onClick={() => {
                                      setshowDedModal(true);
                                      setselectedDed(el);
                                    }}
                                  >
                                    <span className="break-words w-44">
                                      {el.name}
                                    </span>
                                  </div>
                                  <div className="flex flex-col space-y-2">
                                    <span>{el.monthly}</span>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="flex items-center space-x-64 ml-10 mt-10"
                                  key={el._id}
                                >
                                  <div
                                    className="flex flex-col space-y-32 cursor-pointer"
                                    onClick={() => {
                                      setshowDedModal(true);
                                      setselectedDed(el);
                                    }}
                                  >
                                    <span className="break-words w-44">
                                      {el.name}
                                    </span>
                                  </div>
                                  <input
                                    type="number"
                                    className="dark:bg-primary dark:text-secondary outline-none border-none rounded-lg font-semibold w-32 flex h-16 text-center"
                                    onChange={(e) =>
                                      handleDeductionChange(
                                        el._id,
                                        e.target.value,
                                        "monthly",
                                      )
                                    }
                                  />
                                </div>
                              )}
                              <div className="flex flex-col space-y-2 !mt-4 !ml-40">
                                {clickedEarnings.includes(el._id) ? (
                                  <span className="text-red-600">
                                    <AiOutlineMinusCircle
                                      onClick={() => {
                                        handleRemoveDeduction(el._id);
                                        handleEarningClick(el._id);
                                      }}
                                    />
                                  </span>
                                ) : (
                                  <span
                                    className="text-green-700"
                                    onClick={() => handleEarningClick(el._id)}
                                  >
                                    <FaSave />
                                  </span>
                                )}
                              </div>
                            </div>
                          ) : (
                            <div
                              className="flex items-center space-x-64 ml-10 mt-10"
                              key={el._id}
                            >
                              <div
                                className="flex flex-col space-y-32 cursor-pointer"
                                onClick={() => {
                                  setshowDedModal(true);
                                  setselectedDed(el);
                                }}
                              >
                                <span className="break-words w-44">
                                  {el.name}
                                </span>
                              </div>
                              <div className="flex flex-col space-y-2">
                                <span>{el.monthly}</span>
                              </div>
                            </div>
                          )
                        ) : null}
                      </>
                    ))
                  : null}

                {loanDetails?.map((el) =>
                  el?.loanInstallment?.length > 0 ? (
                    <div
                      className="flex items-center space-x-64 ml-10 mt-4"
                      key={el.id}
                    >
                      <div className="flex flex-col space-y-32">
                        <span className="break-words w-44">
                          {el?.nameOnPayslip}
                        </span>
                      </div>
                      {el.loanInstallment.map((d, index) => (
                        <div
                          className="flex flex-col space-y-2 w-12"
                          key={index}
                        >
                          {d?.installmentAmount}
                        </div>
                      ))}
                    </div>
                  ) : null,
                )}

                {advSalaryDetails?.map((el) =>
                  el?.salaryInstallment?.length > 0 ? (
                    <div
                      className="flex items-center space-x-64 ml-10 mt-6"
                      key={el.id}
                    >
                      <div className="flex flex-col space-y-32">
                        <span className="break-words w-44">
                          {el?.nameOnPayslip}
                        </span>
                      </div>
                      {el.salaryInstallment.map((d, index) => (
                        <div
                          className="flex flex-col space-y-2 w-12"
                          key={index}
                        >
                          {d?.installmentAmount}
                        </div>
                      ))}
                    </div>
                  ) : null,
                )}

                {/* <div className="flex flex-col ml-10 mt-5 text-success">
                  <span className="">Post Tax Deductions</span>
                </div> */}

                {deduction !== undefined
                  ? deduction?.map((el, index) => (
                      <>
                        {el.categorie === "Post-Tax Deduction" && (
                          <div className="flex flex-col ml-10 mt-5 text-success">
                            <span className="">Post Tax Deductions</span>
                          </div>
                        )}
                        {el.categorie === "Post-Tax Deduction" ? (
                          el.iseditable == "1" ? (
                            <div
                              className="flex items-center space-x-64 mr-16"
                              key={el._id}
                            >
                              {clickedEarnings.includes(el._id) ? (
                                <div
                                  className="flex items-center space-x-64 ml-10 mt-10"
                                  key={el._id}
                                >
                                  <div
                                    className="flex flex-col space-y-32 cursor-pointer"
                                    // onClick={() => {
                                    //   setshowDedModal(true);
                                    //   setselectedDed(el);
                                    // }}
                                  >
                                    <span className="break-words w-44">
                                      {el.name}
                                    </span>
                                  </div>
                                  <div className="flex flex-col space-y-2">
                                    <span>{el.monthly}</span>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="flex items-center space-x-64 ml-10 mt-10"
                                  key={el._id}
                                >
                                  <div
                                    className="flex flex-col space-y-32 cursor-pointer"
                                    onClick={() => {
                                      setshowDedModal(true);
                                      setselectedDed(el);
                                    }}
                                  >
                                    <span className="break-words w-44">
                                      {el.name}
                                    </span>
                                  </div>
                                  <input
                                    type="number"
                                    className="dark:bg-primary dark:text-secondary outline-none border-none rounded-lg font-semibold w-32 flex h-16 text-center"
                                    onChange={(e) =>
                                      handleDeductionChange(
                                        el._id,
                                        e.target.value,
                                        "monthly",
                                      )
                                    }
                                  />
                                </div>
                              )}
                              <div className="flex flex-col space-y-2 !mt-4 !ml-40">
                                {clickedEarnings.includes(el._id) ? (
                                  <span className="text-red-600">
                                    <AiOutlineMinusCircle
                                      onClick={() => {
                                        handleRemoveDeduction(el._id);
                                        handleEarningClick(el._id);
                                      }}
                                    />
                                  </span>
                                ) : (
                                  <span
                                    className="text-green-700"
                                    onClick={() => handleEarningClick(el._id)}
                                  >
                                    <FaSave />
                                  </span>
                                )}
                              </div>
                            </div>
                          ) : (
                            <div
                              className="flex items-center space-x-64 ml-10 mt-10"
                              key={el._id}
                            >
                              <div
                                className="flex flex-col space-y-32 cursor-pointer"
                                onClick={() => {
                                  setshowDedModal(true);
                                  setselectedDed(el);
                                }}
                              >
                                <span className="break-words w-44">
                                  {el.name}
                                </span>
                              </div>
                              <div className="flex flex-col space-y-2">
                                <span>{el.monthly}</span>
                              </div>
                            </div>
                          )
                        ) : null}
                      </>
                    ))
                  : null}

                <div className="flex flex-col ml-10 mt-5 text-tertiary">
                  <span className="">Taxes</span>
                </div>
                {deduction?.map((el) => {
                  if (el.label === "Professional Tax") {
                    return (
                      <div
                        className="flex items-center space-x-64 ml-10 mt-10"
                        key={el.name}
                      >
                        <div className="flex flex-col space-y-32">
                          <span className="break-words w-44">{el.name}</span>
                        </div>
                        <div className="flex flex-col space-y-2">
                          <span>{el.monthly}</span>
                        </div>
                      </div>
                    );
                  }
                  if (el.name === "Income Tax") {
                    return (
                      <div
                        className="flex items-center space-x-64 ml-10 mt-10"
                        key={el.name}
                      >
                        <div className="flex flex-col space-y-32">
                          <span className="break-words w-44">{el.name}</span>
                        </div>
                        <div className="flex flex-col space-y-2">
                          <span>{el.monthly}</span>
                        </div>
                      </div>
                    );
                  }
                })}

                <div className="flex items-center space-x-64 mt-10 ml-10 relative">
                  <div className="flex gap-2 items-center">
                    <span className="">Add Deduction</span>
                    <span className="text-tertiary font-bold text-4xl">
                      <AiOutlinePlusCircle
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowEarning(false);
                          setShowDeduction(!showDeduction);
                        }}
                      />
                      <div
                        ref={dropdownRef}
                        className={` ${
                          showDeduction
                            ? "flex flex-col p-4 rounded-md gap-2 absolute bg-primaryDark z-10  w-96  h-80 overflow-y-scroll"
                            : "hidden"
                        } ${
                          theme === "light"
                            ? "bg-white shadow-primaryDark shadow-2xl "
                            : ""
                        }`}
                      >
                        {deductionSelect.map((data) => (
                          <span
                            className={
                              "   rounded-md p-4 cursor-pointer !text-2xl " +
                              `${
                                theme == "light"
                                  ? "hover:bg-wheat"
                                  : "hover:bg-primaryDark2"
                              }`
                            }
                            onClick={(e) => handleAddDeduction(data)}
                          >
                            {data.label}
                          </span>
                        ))}
                      </div>
                    </span>
                  </div>
                </div>
                <div
                  className={
                    "flex items-center justify-between py-5 px-10 border-b-4 border-b-primaryDark space-x-20 !bg-white dark:!bg-primaryDark2 mt-7"
                  }
                >
                  <div className="flex items-center space-x-36">
                    <div className="flex flex-col">
                      <span>Add Reimbursement</span>
                    </div>
                    <div className="flex flex-col">
                      <span className="">Amount</span>
                    </div>
                  </div>
                </div>
                {reimbursements?.map((el, index) => {
                  return el.editable == "1" ? (
                    <div
                      className="flex items-center space-x-64 ml-10 mt-10 mr-16"
                      key={index}
                    >
                      <div className="flex flex-col space-y-32">
                        <span className="break-words w-44">
                          {el?.label}
                          <br />
                          <span className="text-base text-red-500 font-bold">{`Max limit-- ${el?.limitPerMonth}`}</span>
                        </span>
                      </div>
                      <div className="flex flex-col space-x-5 w-8">
                        {clickedEarnings.includes(el._id) ? (
                          <div className="flex flex-col space-y-32">
                            <span className="break-words w-44">
                              {el.monthly}
                            </span>
                          </div>
                        ) : (
                          <input
                            type="number"
                            className="dark:bg-primary dark:text-secondary outline-none border-none rounded-lg font-semibold w-32 flex h-16 text-center"
                            max={el?.limitPerMonth}
                            onChange={(e) =>
                              handleReimbursementAmount(
                                el._id,
                                e.target.value,
                                "monthly",
                              )
                            }
                          />
                        )}
                      </div>
                      <div className="flex flex-col space-y-2">
                        {clickedEarnings.includes(el._id) ? (
                          <span className="text-red-600">
                            <AiOutlineMinusCircle
                              onClick={() => handleRemoveReimbursement(el._id)}
                            />
                          </span>
                        ) : (
                          <span
                            className="text-green-700"
                            onClick={() => handleEarningClick(el._id)}
                          >
                            <FaSave />
                          </span>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div
                      className="flex items-center space-x-64 ml-10 mt-10"
                      key={index}
                    >
                      <div className="flex flex-col space-y-32">
                        <span className="break-words w-44">{el?.label}</span>
                      </div>
                      <div className="flex flex-col space-y-2">
                        <span>{el.monthly}</span>
                      </div>
                    </div>
                  );
                })}

                <div className="flex items-center space-x-64 mt-10 ml-10 relative">
                  <div className="flex gap-2 items-center">
                    <span className="">Add Reimbursement</span>
                    <span className="text-tertiary font-bold text-4xl">
                      <AiOutlinePlusCircle
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowEarning(false);
                          setShowDeduction(false);
                          setShowReimb(!showReimb);
                        }}
                      />
                      <div
                        ref={dropdownRef}
                        className={` ${
                          showReimb
                            ? "flex flex-col p-4 rounded-md gap-2 absolute bg-primaryDark z-10  w-96  h-80 overflow-y-scroll"
                            : "hidden"
                        } ${
                          theme === "light"
                            ? "bg-white shadow-primaryDark shadow-2xl "
                            : ""
                        }`}
                      >
                        {reimburseSelect.map((data) => (
                          <span
                            className={
                              "   rounded-md p-4 cursor-pointer !text-2xl " +
                              `${
                                theme == "light"
                                  ? "hover:bg-wheat"
                                  : "hover:bg-primaryDark2"
                              }`
                            }
                            onClick={(e) => handleAddReimburse(data)}
                          >
                            {data.label}
                          </span>
                        ))}
                      </div>
                    </span>
                  </div>
                </div>
                {showAdditionalDetails ? (
                  <div className="flex items-center space-x-32 ml-10 mt-10">
                    <div className="flex flex-col space-y-32">
                      <span className="">Additional Details</span>
                    </div>
                    <div className="flex flex-col space-y-2">
                      <input
                        className="text-center"
                        value={selectedEmployee?.additionalDetails || ""}
                        onChange={(e) =>
                          setSelectedEmployee((pre) => ({
                            ...pre,
                            additionalDetails: e.target.value,
                          }))
                        }
                        type="text"
                      />
                    </div>
                  </div>
                ) : null}
                <div className="flex items-center space-x-64 mt-10 ml-10">
                  <div className="flex gap-2 items-center">
                    <span className="">Add Additional Details</span>

                    <span className="text-tertiary font-bold text-4xl">
                      <AiOutlinePlusCircle
                        onClick={() =>
                          setShowAdditionalDetails(!showAdditionalDetails)
                        }
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-10 p-10">
                <button
                  className="button-blue-gradient"
                  onClick={(e) => handleSave(selectedEmployee?.employeeId._id)}
                >
                  Save
                </button>
                <button
                  className="button-red-gradient"
                  onClick={(e) =>
                    handleCancelSelectedEmp(selectedEmployee?.employeeId._id)
                  }
                >
                  Cancel
                </button>
              </div>
            </>
          ) : null}
        </div>
      </div>

      <Modal
        showModal={showEarnModal}
        closeModal={() => setshowEarnModal(false)}
        title="Other Configurations"
        width="w-max"
        content={
          <div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setshowEarnModal(false);
              }}
            >
              <div className="admin-content">
                <div className="flex flex-col gap-1 mt-4">
                  <CheckContainer
                    text="Add to Employee's Salary Structure"
                    initialStatus={selectedearn?.addToSalary}
                    // onStatusChange={(e) => {
                    //   updateFieldAnswer(setupdatedEarningsConf, "addToSalary", e);
                    // }}
                  />

                  <CheckContainer
                    text="Taxable Earnings"
                    initialStatus={selectedearn?.taxable}
                    includeToolTip={true}
                    toolTipText="The Income Tax amount will be divided equally and deducted every month across Financial year"
                    // onStatusChange={(e) => {
                    //   updateFieldAnswer(setupdatedEarningsConf, "taxable", e);
                    // }}
                  />

                  <CheckContainer
                    text="Calculate on Pro-Rata Basis"
                    initialStatus={selectedearn?.proRata}
                    includeToolTip={true}
                    toolTipText="Pay will be adjusted based on employees working days"
                    // onStatusChange={(e) => {
                    //   updateFieldAnswer(setupdatedEarningsConf, "proRata", e);
                    // }}
                  />

                  <CheckContainer
                    text="Add as a Flexible Benefit Plan Component"
                    initialStatus={selectedearn?.flexible}
                    // onStatusChange={(e) => {
                    //   updateFieldAnswer(setupdatedEarningsConf, "flexible", e);
                    // }}
                  />

                  <CheckContainer
                    text="Consider for EPF Calculation"
                    initialStatus={selectedearn?.isEpf}
                    // onStatusChange={(e) => {
                    //   updateFieldAnswer(setupdatedEarningsConf, "isEpf", e);
                    // }}
                  />

                  <CheckContainer
                    text="Consider for ESI Calculation"
                    initialStatus={selectedearn?.isEsi}
                    // onStatusChange={(e) => {
                    //   updateFieldAnswer(setupdatedEarningsConf, "isEsi", e);
                    // }}
                  />

                  <CheckContainer
                    text="Show this Component on Payslip"
                    initialStatus={selectedearn?.onPaySlip}
                    // onStatusChange={(e) => {
                    //   updateFieldAnswer(setupdatedEarningsConf, "onPaySlip", e);
                    // }}
                  />
                </div>
              </div>
            </form>
          </div>
        }
      />

      <Modal
        showModal={showDedModal}
        closeModal={() => setshowDedModal(false)}
        title="Other Configurations"
        width="w-max"
        content={
          <div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setshowDedModal(false);
              }}
            >
              <div className="admin-content">
                <div className="flex flex-col gap-1 mt-4">
                  <CheckContainer
                    text="Include Employer's Contribution in CTC"
                    initialStatus={selectedDed?.includeEmployersContribution}
                    // onStatusChange={(e) => {
                    //   updateFieldAnswer(setupdatedDeductionsConf, "includeEmployersContribution", e);
                    // }}
                  />

                  <CheckContainer
                    text="Calculate on Pro-Rata Basis"
                    initialStatus={selectedDed?.calculatOnProDataBasis}
                    includeToolTip={true}
                    toolTipText="Pay will be adjusted based on employees working days"
                    // onStatusChange={(e) => {
                    //   updateFieldAnswer(setupdatedDeductionsConf, "calculatOnProDataBasis", e);
                    // }}
                  />
                </div>
              </div>
            </form>
          </div>
        }
      />
    </div>
  );
};

export default CreatePayrun;
