import React from "react";
import "./warningModal.css";
import { IoIosWarning } from "react-icons/io";

const WarningModal = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="warning-modal-overlay">
      <div className="warning-modal-content bg-primaryLight text-white">
        <IoIosWarning className="warning-logo" />
        <h2>Unsaved Changes</h2>
        <p>You have unsaved changes. Are you sure you want to leave?</p>
        <button
          className="border-2 border-green-500 px-4 py-2 rounded-lg hover:bg-green-400 hover:border-white"
          onClick={onConfirm}
        >
          Yes
        </button>
        <button
          className="border-2 border-red-500 px-4 py-2 rounded-lg hover:bg-red-400 hover:border-white"
          onClick={onClose}
        >
          No
        </button>
      </div>
    </div>
  );
};

export default WarningModal;
