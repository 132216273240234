import { createSlice } from "@reduxjs/toolkit";
import { getEsiDetails } from "../services/hrms";

const esiDetailsSlice = createSlice({
  name: "esiDetails",
  initialState: {
    data: [],
  },
  reducers: {
    setesiDetails(state, action) {
      state.data = action.payload;
    },
  },
});

export const { setesiDetails } = esiDetailsSlice.actions;

export const fetchEsiDetails = () => async (dispatch) => {
  try {
    const response = await getEsiDetails();
    dispatch(setesiDetails(response));
  } catch (error) {
  }
};

export default esiDetailsSlice.reducer;
