import React, { useEffect, useMemo, useState } from "react";
import { IoIosAddCircleOutline } from "react-icons/io";
import { FaArrowRight } from "react-icons/fa6";
import {
  CreateDeduction,
  GetDeductionTypes,
} from "../../../services/deduction";
import customToast from "../../../components/CustomToast";
import { RxCross1 } from "react-icons/rx";
import { generateSelectData } from "../../../utils/serialiseResponse";
import Select from "react-select";
import { setUpSelectStyles } from "../../../utils/selectStyles";
import { act } from "react";
const Deductions = ({
  onReimbursement,
  setSidePanelOpen,
  sidePanelOpen,
  postSidePanelOpen,
  setPostSidePanelOpen,
}) => {
  const colums = [
    {
      name: "Deduction Name",
      key: "name",
    },
    {
      name: "Deduction Type",
      key: "deductionType",
    },
    {
      name: "Deduction Frequency",
      key: "deductionFrequency",
    },
  ];
  const [preData, setPreData] = useState([
    {
      name: "Voluntary Provident Fund",
      nameOnPaySlip: "Voluntary Provident Fund",
      deductionType: "Voluntary Provident Fund",
      deductionFrequency: "Recurring",
      categorie: "Pre-Tax Deduction",
      isActive: false,
    },
  ]);

  const [postData, setPostData] = useState([
    {
      name: " Notice Pay Deduction",
      nameOnPaySlip: "Notice Pay Deduction",
      deductionType: "Notice Pay Deduction",
      deductionFrequency: "one-time",
      categorie: "Post-Tax Deduction",
      isActive: false,
    },
  ]);

  const deductionFrequencyOptions = [
    { value: 0, label: "recurring" },
    { value: 0, label: "one-time" },
  ];

  const [selected, setSelected] = useState([...preData, ...postData]);
  const [deductionType, setDeductionType] = useState("");
  const [paySlipName, setPaySlipName] = useState("");
  const [deductionFrequency, setDeductionFrequency] = useState("");
  const [active, setActive] = useState(true);
  const [deductionData, setDeductionData] = useState([]);
  const [selectedDeduction , setSelectedDeduction] = useState([]);

  const deductionSelect = useMemo(
    () => generateSelectData(deductionData, "name"),
    [deductionData],
  );
  useEffect(() => {
    async function fetchDeductionTypes() {
      try {
        const { data } = await GetDeductionTypes();
        setDeductionData(data);
      } catch (error) {
        customToast.error("Sorry Something Went Wrong!");
      }
    }
    fetchDeductionTypes();
  }, []);

  const handleFormSubmit = async () => {
    try {
      const payload = [...selected];
      // if(selected){
      //   const payload = [];
      //   for(const deduction of deductionSelect){
      //      for(const select of selected){
      //        if((deduction.label).toLowerCase() === (select.deductionType).toLowerCase()){
      //           payload.push({
      //             name: select.deductionType,
      //             deductionType:deduction._id,
      //             paySlipName:select.paySlipName || select.deductionType,
      //             deductionFrequency:select.deductionFrequency,
      //             label: select.label,
      //             isActive: active,
      //             categorie : select.categorie
      //           })
      //        }
      //      }
      //   }
      const response = await CreateDeduction(payload);
      if (response.message == "OK") {
        customToast.success("Deduction Details Uploaded Successfully");
        onReimbursement();
      }
    } catch (error) {
      customToast.error("Error Uploading Deduction Details");
    }
  };

  const handleActive = () => {
    active == "active" ? setActive("") : setActive("active");
  };

  
  const handleAddPostTaxSubmit = async(e) => {
    e.preventDefault();
    if (!deductionType || !paySlipName || !deductionFrequency) {
      customToast.error("Please Fill All Fields !!");
      return;
    }
    try {
      for (let select of selected) {
        if (select?.deductionType == deductionType) {
          select.isActive = active;
          select.nameOnPaySlip = paySlipName;
          select.deductionFrequency = deductionFrequency;
        }
      }
      // const payload = {
      //    name:deductionType,
      //    deductionType,
      //    paySlipName,
      //    deductionFrequency,
      //    active : active,
      //    categorie: "Post-Tax Deduction"
      // }

      setSelected([...selected]);
      setPostSidePanelOpen(false);
    } catch (error) {
      customToast.error("Something went Wrong");
    }
  };

  const handleAddPreTaxSubmit = async (e) => {
    e.preventDefault();
    if (!deductionType || !paySlipName || !deductionFrequency) {
      customToast.error("Please Fill All Fields !!");
      return;
    }
    try {
      for (let select of selected) {
        if (select?.deductionType == deductionType) {
          select.isActive = active;
          select.nameOnPaySlip = paySlipName;
          select.deductionFrequency = deductionFrequency;
        }
      }
      // const payload = {
      //    name:deductionType,
      //    deductionType,
      //    paySlipName,
      //    deductionFrequency,
      //    active : false,
      //    categorie: "Pre-Tax Deduction"
      // }

    setSelected([...selected]);
    // setPreData(prevData => [...prevData, payload]);
    setSidePanelOpen(false);
  } catch (error) {
    customToast.error("Something went Wrong")
  }  

}

const isSelected = (row) => selectedDeduction.some(item => item.eName === row.eName);
console.log(selectedDeduction , "selectedDeduction");
console.log(selected , "selected")

  return (
    <div className="reimbursements">
      <div className="new_table mt-5">
        <div className="new_thead ">
          {colums.map((col) => {
            return <span>{col.name}</span>;
          })}
        </div>

        <h1 className="sub_heading">
          <FaArrowRight />
          Pre - Tax Deduction :
        </h1>
        {preData.map((row) => {
          return (
            <div
              className={
                "new_table_row " + `${row?.active ? "bg-blue-200" : ""}`
              }
            >
              {colums.map((col) => {
                return col.name == "Deduction Name" ? (
                  <span className="flex items-center content-center gap-2">
                    <input
                      className="checkbox_input"
                      type="checkbox"
                      checked={isSelected(row)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setActive(true);
                          row.categorie = "Pre-Tax Deduction";
                          setSelected([...selected]);
                          setSidePanelOpen(true);
                          setDeductionType(row?.deductionType);
                          setPaySlipName(row?.nameOnPaySlip);
                          setSelectedDeduction([...selectedDeduction, row]);
                          // setDeductionFrequency({value: row?.deductionFrequency , label: row?.deductionFrequency});
                        } else {
                          setActive(false);
                          setSelectedDeduction(
                            selectedDeduction.filter((item) => item.name !== row.name),
                          );
                        }
                      }}
                      name="select deduction"
                      id={row[col.key]}
                    />
                    <label className="cursor-pointer" htmlFor={row[col.key]}>
                      {row[col.key]}
                    </label>
                  </span>
                ) : (
                  <span>{row[col.key]}</span>
                );
              })}
            </div>
          );
        })}

        <div
          className=" side_panel "
          style={{
            width: sidePanelOpen ? "100%" : "0",
          }}
        >
          <div
            className="side_panel_form"
            style={{
              display: sidePanelOpen ? "block" : "none",
            }}
          >
            <form onSubmit={handleAddPreTaxSubmit}>
              <div className="side_panel_form_head">
                <p>New Pre - Tax Deduction</p>
                <RxCross1
                  size={20}
                  style={{ marginTop: "-2rem", cursor: "pointer" }}
                  color="black"
                  onClick={() => {
                    setSidePanelOpen(false);
                  }}
                />
              </div>

              <div>
                <div className="flex">
                  <label htmlFor="">Pre-Tax Deduction Type</label>
                  <span className="imp_star">*</span>
                </div>
                {/* <input className="text_input cursor-not-allowed" type="text" placeholder="Pre - Tax Deduction Type" value={deductionType} disabled={true}/> */}
                <input
                  className="text_input"
                  type="text"
                  placeholder="Deduction Type"
                  onChange={(e) => setDeductionType(e.target.value)}
                  value={deductionType}
                />
              </div>

              <div>
                <div className="flex">
                  <label htmlFor="">Name on Payslip</label>
                  <span className="imp_star">*</span>
                </div>
                <input
                  className="text_input"
                  type="text"
                  placeholder="Name on Payslip"
                  value={paySlipName}
                  onChange={(e) => setPaySlipName(e.target.value)}
                />
              </div>

              <div>
                <div className="flex">
                  <label htmlFor="">Deduction Frequency</label>
                  <span className="imp_star">*</span>
                </div>
                {/* <input className="text_input" type="text" placeholder="Earning Name" value={deductionFrequency}  onChange={(e) => setDeductionFrequency(e.target.value)}/> */}
                <Select
                  styles={setUpSelectStyles}
                  options={deductionFrequencyOptions}
                  onChange={(e) => setDeductionFrequency(e?.label)}
                />
              </div>

              <div className="terms">
                {/* <div>
                  <input className="checkbox_input" type="checkbox" name="" id="" onChange={(e) => setCalculate(e.target.value)}/>
                  <span>Calculate based on Pro-rata basis</span>
                </div>

                <div>
                  <input className="checkbox_input" type="checkbox" name="" id="" onChange={(e) => setBenefitPlan(e.target.value)}/>
                  <span>Include this as a flexible Benefit Plan Component</span>
                </div> */}

                <div>
                  <input
                    className="checkbox_input"
                    id="ded-opt-1"
                    type="checkbox"
                    checked={active}
                    onChange={() => setActive(!active)}
                  />
                  <label
                    htmlFor="ded-opt-1"
                    className="!m-0 !font-normal !text-xl cursor-pointer"
                  >
                    Mark this as Active
                  </label>
                </div>
              </div>

              <div className="note">
                Note: You will not be able to edit this salary component once
                you associate it with an Employee
              </div>

              <div className="d-flex">
                <button className="submit_button">Save </button>
                <span className="skip">Cancel</span>
              </div>
            </form>
          </div>
        </div>

        <h1 className="sub_heading">
          <FaArrowRight />
          Post - Tax Deduction :
        </h1>
        {postData.map((row) => {
          console.log(row, "row");
          return (
            <div
              className={
                "new_table_row " + `${row?.active ? "bg-blue-200" : ""}`
              }
            >
              {colums.map((col) => {
                return col.name == "Deduction Name" ? (
                  <span className="flex items-center content-center gap-2">
                    <input
                      className="checkbox_input"
                      type="checkbox"
                      name=""
                      id={row[col.key]}
                      checked={row?.isActive == true}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setActive(true);
                          row.categorie = "Post-Tax Deduction";
                          // setSelected([...selected, row]);
                          // setPaySlipName(row?.name);
                          // setDeductionFrequency({value : 0 , label: 'recurring'},)
                          // setActive(true);
                          setPostSidePanelOpen(true);
                          setDeductionType(row?.deductionType);
                          setPaySlipName(row?.nameOnPaySlip);
                          // setDeductionFrequency({value: row?.deductionFrequency , label: row?.deductionFrequency});
                          // for(const deduction of deductionSelect){
                          //     if((deduction.label).toLowerCase() === (row.deductionType).toLowerCase()){
                          //       row.deductionType = deduction._id;
                          //       setDeductionType(deduction);
                          //     }
                          // }
                        } else {
                          setSelected(
                            selected.filter((item) => item.name !== row.name),
                          );
                        }
                      }}
                    />
                    <label htmlFor={row[col.key]} className="cursor-pointer">
                      {row[col.key]}
                    </label>
                  </span>
                ) : (
                  <span>{row[col.key]}</span>
                );
              })}
            </div>
          );
        })}

        <div
          className=" side_panel "
          style={{
            width: postSidePanelOpen ? "100%" : "0",
          }}
        >
          <div
            className="side_panel_form"
            style={{
              display: postSidePanelOpen ? "block" : "none",
            }}
          >
            <form onSubmit={(e) => handleAddPostTaxSubmit(e)}>
              <div className="side_panel_form_head">
                <p>New Post - Tax Deduction</p>
                <RxCross1
                  color="black"
                  size={20}
                  style={{ marginTop: "-2rem", cursor: "pointer" }}
                  onClick={() => {
                    setPostSidePanelOpen(false);
                  }}
                />
              </div>

              <div>
                <div className="flex">
                  <label htmlFor="">Post-Tax Deduction Type</label>
                  <span className="imp_star">*</span>
                </div>
                {/* <input className="text_input" type="text" placeholder="Post - Tax Deduction Type" onChange={(e) => setDeductionType(e.target.value)}/> */}
                <input
                  className="text_input"
                  type="text"
                  placeholder="Deduction Type"
                  onChange={(e) => setDeductionType(e.target.value)}
                  value={deductionType}
                />
              </div>

              <div>
                <div className="flex">
                  <label htmlFor="">Name on the Payslip</label>
                  <span className="imp_star">*</span>
                </div>
                <input
                  className="text_input"
                  type="text"
                  placeholder="Name on the Slip"
                  onChange={(e) => setPaySlipName(e.target.value)}
                  value={paySlipName}
                />
              </div>

              <div>
                <div className="flex">
                  <label htmlFor="">Deduction Frequency</label>
                  <span className="imp_star">*</span>
                </div>
                {/* <input className="text_input" type="text" placeholder="Deduction Frequency" onChange={(e) => setDeductionFrequency(e.target.value)}/> */}
                <Select
                  styles={setUpSelectStyles}
                  options={deductionFrequencyOptions}
                  onChange={(e) => setDeductionFrequency(e)}
                  value={deductionFrequency}
                />
              </div>

              <div className="terms">
                <div>
                  <input
                    className="checkbox_input"
                    id="ded-opt-2"
                    type="checkbox"
                    onChange={() => setActive(!active)}
                    checked={active}
                  />
                  <label
                    htmlFor="ded-opt-2"
                    className="!m-0 !font-normal !text-xl cursor-pointer"
                  >
                    Mark this as Active
                  </label>
                </div>
              </div>
              <div className="d-flex">
                <button className="submit_button">Save </button>
                <span className="skip" onClick={() => {
                    setPostSidePanelOpen(false);
                  }}>Cancel</span>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div>
        <button className="submit_button mt-3" onClick={handleFormSubmit}>
          Save & Continue
        </button>
        <span className="skip" onClick={onReimbursement}>
          Skip for now
        </span>
      </div>
    </div>
  );
};

export default Deductions;
