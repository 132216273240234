import { deleteReimbursement, getReimbursements } from "../services/reimbursement";
import { createSlice } from "@reduxjs/toolkit";

const reimbursementSlice = createSlice({
  name: "reimbursements",
  initialState: {
    data: [],
  },
  reducers: {
    setReimbursements: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setReimbursements } = reimbursementSlice.actions;

export const fetchReimbursements = () => async (dispatch) => {
  try {
    const response = await getReimbursements();
    dispatch(setReimbursements(response));
  } catch (error) {
  }
};

export const DeleteReimbursement = (payload) => async (dispatch) => {
  try {
    const res = await deleteReimbursement(payload);
    dispatch(fetchReimbursements());
  } catch (error) {
    console.log(error);
  }
  
}

export default reimbursementSlice.reducer;
