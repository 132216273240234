import { customAxios } from "../utils/axiosinstance";

export const CalcTaxOldRegime = async(payload) =>{
    const {data} = await customAxios.get(`hrms/income-tax/old-regime`,{
        params: payload
    });
    return data;
}

export const CalcTaxNewRegime = async(payload) =>{
    const {data} = await customAxios.get(`hrms/income-tax/new-regime`,{
        params: payload
    });
    return data;
}

export const GetIncomeTaxAllComponents = async(payload) => {
    const {data} = await customAxios.get(`hrms/income-tax/income-tax-components/${payload}`);
    return data;
}

export const IncomeTaxTotal = async(payload) => {
    const {data} = await customAxios.get(`hrms/income-tax/income-tax-total`, {
        params: payload
    });
    return data;
}

export const GetFiniancialYearEmployee = async(payload) => {
    const {data} = await customAxios.get(`hrms/income-tax/financial-year-employee/${payload}`);
    return data;
}

export const UpdateIncomeTaxTotal = async(payload) => {
    const {data} = await customAxios.post(`hrms/income-tax/income-tax-total`, payload);
    return data;
}