import React, { useEffect, useMemo, useRef, useState } from 'react'
import PageTitle from '../../../components/PageTitle'
import { useDispatch, useSelector } from 'react-redux';
import { IoIosAddCircleOutline } from "react-icons/io";
import Accordion from '../../../components/Accordion';
import Modal from '../../../components/Modal';
import Select from "react-select";
import { adminStyles, adminStylesLight } from "../../../utils/selectStyles";
import { HiPencil } from 'react-icons/hi';
import Table from '../../../components/Table';
import { createDivision, addTaskToDivision, editTaskDetails, deleteTask } from '../../../services/onboarding'
import { set, useForm } from 'react-hook-form';
import customToast from '../../../components/CustomToast/index'
import { generateSelectData, generateSelectDefaultValue } from "../../../utils/serialiseResponse";
import { setDesignations } from '../../../reducers/designationReducer'
import { fileToBase64 } from "../../../utils/helper";
import { fetchAllOnboardDivisions } from '../../../reducers/onboardingDivisionsReducer'
import { SlReload } from 'react-icons/sl';
import Dropdown from '../../../components/DropDown/dropdown'

const division = () => {

  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.theme);
  const divisions = useSelector((state) => state.onboardDivisions.data);
  const designations = useSelector(({ designations }) => designations);
  const [addDivModal, setaddDivModal] = useState(false)
  const [divTaskModal, setdivTaskModal] = useState(false)
  const { register: registerForForm1, handleSubmit: handleSubmitForForm1, reset: resetForForm1 } = useForm()
  const { register: registerForForm2, handleSubmit: handleSubmitForForm2, reset: resetForForm2, setValue: setValueForForm2 } = useForm()
  const [uploadData, setUploadData] = useState([])
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const editFileInputRef = useRef(null);
  const [editModal, seteditModal] = useState(false);
  const [editedFields, seteditedFields] = useState({});

  const designationSelect = useMemo(
    () => {
      let options = [];
      if (Array.isArray(designations.data)) {
        let data = generateSelectData(designations.data, "name");
        options.push(...data);
        options = options.filter((item) => {
          return item.value !== 0 && item.label !== "All";
        });
      }
      return options;
    },
    [designations.data],
  );

  const handleEditChange = (field, value) => {
    seteditedFields(prevFields => ({
      ...prevFields,
      [field]: value
    }));
  };

  const columns = useMemo(
    () => [
      {
        Header: "TASK LIST",
        accessor: "taskName",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "DUE",
        accessor: "dueDays",
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <>
              {row.original.dueDays > 1 ? row.original.dueDays + " Day(s) " : row.original.dueDays + " Day "} after joining date
            </>
          );
        },
      },
      {
        Header: "ALLOTED TO",
        accessor: "allotedTo",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "ACTION",
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <>
              <Dropdown
                icon={
                  <HiPencil className={`text-[#36ABF9] cursor-pointer text-3xl `} />
                }
                options={[
                  {
                    text: 'Edit',
                    onClick: () => {
                      seteditedFields(row.original);
                      seteditModal(true);
                    }
                  },
                  {
                    text: 'Delete',
                    onClick: async () => {
                      const res = await deleteTask({ taskId: row.original._id })
                      if (res.message === "OK") {
                        customToast.success("Task has been deleted successfully")
                        dispatch(fetchAllOnboardDivisions());
                      }
                    }
                  },
                ]}
              />
            </>
          );
        },
      },
    ],
    []
  );

  let changeHandler = (e) => {
    setFile(e.target.files[0])
    const validFiles = Array.from(e.target.files);
    setUploadData([]);
    Promise.all(validFiles.map(fileToBase64))
      .then((base64Images) => {
        setUploadData(base64Images);
      })
      .catch((error) => {
        customToast.error("Error adding images");
      });
  };

  useEffect(() => {
    dispatch(setDesignations());
    dispatch(fetchAllOnboardDivisions());
  }, [])

  const createDivisionHandler = async (data) => {

    if (!data.divisionName) {
      return customToast.error("Please enter division name")
    }

    const res = await createDivision(data)

    if (res) {
      resetForForm1();
      setaddDivModal(false)
      dispatch(fetchAllOnboardDivisions());
      return customToast.success("Division has been added successfully")
    }
  }

  const addTaskHandler = async (data) => {
    data.file = uploadData;

    try {
      if(!data.taskName || !data.reminderDate || !data.allotedTo) {
        return customToast.error("Please fill all the required fields")
      }

      const res = await addTaskToDivision(data)
      if (res) {
        setUploadData([])
        resetForForm2();
        setdivTaskModal(false)
        dispatch(fetchAllOnboardDivisions());
        return customToast.success("Task has been added successfully")
      }
    }
    catch (err) {
      customToast.error("Something went wrong")
    }
  }

  const defAllotment =
    designationSelect?.find((e) => e.label === editedFields?.allotedTo)
  // useMemo(
  //   () => generateSelectDefaultValue(designations,editedFields?.allotedTo),
  //   [designationSelect],
  // );

  return (

    <main className={"main-content " + `${theme == "light" ? "light_bg_main" : ""} w-full`} >
      <div className={"area-creation-content " + `${theme == "light" ? "light_bg" : ""}`}>
        <PageTitle title="Onboarding division" route={'../..'} />
      </div>

      <section>
        <button className='flex gap-2 text-3xl text-[#3893d7]' onClick={() => {
          setaddDivModal(true);
        }}>
          <IoIosAddCircleOutline />
          <span>ADD DIVISION</span>
        </button>

        <div className='flex flex-col gap-4 mt-10 pr-8'>
          {divisions?.map((item) => (
            <Accordion
              key={item._id}
              defaultState={false}
              title={item.divisionName}
              titleStyle={"accordion-title"}
              contentStyle={"area-creation-head"}
              content={
                <div className='py-8 cardlink-row area-creation-content'>
                  <button className="button-blue-gradient"
                    onClick={() => {
                      setdivTaskModal(true);
                    }}
                  >
                    Add Task
                  </button>

                  {item.tasks.length > 0 &&
                    <div className="filter-table">
                      <Table columns={columns} data={item.tasks} />
                    </div>
                  }

                  <Modal
                    showModal={divTaskModal}
                    closeModal={() => setdivTaskModal(false)}
                    title="Division List"
                    width="w-[400px]"
                    content={
                      <form className='admin-content' onSubmit={handleSubmitForForm2(addTaskHandler)}>
                        <div className="flex flex-col gap-6">
                          <div>
                            <label htmlFor="task_name">
                              Add Task
                              <span className="asterisk-imp">*</span>
                            </label>
                            <input
                              id='task_name'
                              type="text"
                              placeholder="Enter Task Name"
                              {...registerForForm2("taskName")}
                            />
                          </div>

                          <div>
                            <label htmlFor="des">
                              Description
                            </label>
                            <textarea
                              id='des'
                              rows={2}
                              cols={10}
                              type="text"
                              placeholder="Enter Description"
                              style={{ resize: 'none' }}
                              {...registerForForm2("description")}
                            />
                          </div>

                          <div className="flex items-center space-x-2">
                            <label>Due on</label>
                            <Select
                              options={[
                                { label: 1, value: 1 },
                                { label: 2, value: 2 },
                                { label: 3, value: 3 },
                                { label: 4, value: 4 },
                                { label: 5, value: 5 },
                                { label: 6, value: 6 },
                                { label: 7, value: 7 },
                                { label: 8, value: 8 },
                                { label: 9, value: 9 },
                                { label: 10, value: 10 }
                              ]}
                              placeholder=""
                              styles={theme === "light" ? adminStylesLight : adminStyles}
                              className="w-max mb-12"
                              onChange={(e) => {
                                setValueForForm2("dueDays", e.value);
                              }}
                            />
                            <label>days after joining date <span className="asterisk-imp">*</span></label>
                          </div>

                          <div>
                            <label htmlFor="act_name">
                              Attach file
                            </label>
                            <div className='flex gap-4 items-center'>
                              <input
                                type="file"
                                onChange={changeHandler}
                                ref={fileInputRef}
                              />
                              <SlReload
                                className="cursor-pointer font-extrabold ml-6 text-3xl text-[#36abf9] mt-3"
                                onClick={() => {
                                  setFile(null);
                                  if (fileInputRef.current) fileInputRef.current.value = '';
                                }}
                              />
                            </div>
                          </div>

                          <div>
                            <label htmlFor="act_name">
                              Allot division to <span className="asterisk-imp">*</span>
                            </label>
                            <Select
                              options={designationSelect}
                              placeholder="Select Designation"
                              styles={theme == "light" ? adminStylesLight : adminStyles}
                              className='w-max'
                              onChange={(e) => {
                                setValueForForm2("allotedTo", e.name)
                              }}
                            />
                          </div>

                          <div>
                            <label htmlFor="remin">
                              Send Reminder to assignee
                              <span className="asterisk-imp">*</span>
                            </label>
                            <input
                              id='remin'
                              type="date"
                              placeholder="Select Date"
                              {...registerForForm2("reminderDate")}
                              style={{
                                boxShadow: "none"
                              }}
                            />
                          </div>

                          <div className="flex items-center mt-6 gap-10">
                            <button
                              type="submit"
                              className="px-16 py-2 text-white bg-[#36abf9] rounded-xl cursor-pointer"
                              onClick={() => {
                                setValueForForm2("divisionId", item._id)
                              }}
                            >
                              Add
                            </button>
                            <span
                              className="text-cyan cursor-pointer"
                              onClick={() => {
                                setdivTaskModal(false)
                              }}
                            >
                              Cancel
                            </span>
                          </div>
                        </div>
                      </form>
                    }
                  />
                </div>
              }
            />
          ))}
        </div>
      </section>

      <Modal
        showModal={addDivModal}
        closeModal={() => setaddDivModal(false)}
        title="Add Division"
        width="w-[400px]"
        content={
          <form className='admin-content' onSubmit={handleSubmitForForm1(createDivisionHandler)}>
            <div className="flex flex-col gap-10">
              <div>
                <label htmlFor="act_name">
                  Division Name
                  <span className="asterisk-imp">*</span>
                </label>
                <input
                  id='act_name'
                  type="text"
                  placeholder="Enter Division Name"
                  className='bg-[#2B2C47] px-6 mt-4 w-full outline-none'
                  {...registerForForm1("divisionName")}
                />
              </div>

              <div>
                <label htmlFor="des">
                  Description
                </label>
                <input
                  id='des'
                  type="text"
                  placeholder="Enter Description"
                  className='bg-[#2B2C47] px-6 mt-4 w-full outline-none'
                  {...registerForForm1("description")}
                />
              </div>

              <div className="flex items-center gap-10">
                <button
                  type="submit"
                  className="px-16 py-2 text-white bg-tertiary rounded-xl cursor-pointer"
                >
                  Add
                </button>
                <span
                  className="text-cyan cursor-pointer"
                  onClick={() => {
                    setaddDivModal(false)
                  }}
                >
                  Cancel
                </span>
              </div>
            </div>
          </form>
        }
      />


      <Modal
        showModal={editModal}
        closeModal={() => seteditModal(false)}
        title="Edit Task"
        width="w-[400px]"
        content={
          <form className='admin-content'
            onSubmit={(e) => {
              e.preventDefault();
              editTaskDetails({ id: editedFields._id, editedFields: editedFields })
                .then((res) => {
                  if (res.message === "OK") {
                    customToast.success("Task has been edited successfully")
                    dispatch(fetchAllOnboardDivisions());
                    seteditModal(false)
                  }
                  else{
                    customToast.error("Something went wrong")
                  }
                })
                .catch((err) => {
                  customToast.error("Something went wrong")
                })
            }}
          >
            <div className="flex flex-col gap-6">
              <div>
                <label htmlFor="task_name">
                  Task Name
                  <span className="asterisk-imp">*</span>
                </label>
                <input
                  id='task_name'
                  type="text"
                  placeholder="Enter Task Name"
                  value={editedFields?.taskName || ""}
                  onChange={(e) => handleEditChange("taskName", e.target.value)}
                />
              </div>

              <div>
                <label htmlFor="des">
                  Description
                </label>
                <textarea
                  id='des'
                  rows={2}
                  cols={10}
                  type="text"
                  placeholder="Enter Description"
                  style={{ resize: 'none' }}
                  value={editedFields?.description}
                  onChange={({ target }) =>
                    handleEditChange("description", target?.value)
                  }
                />
              </div>

              <div className="flex items-center space-x-2">
                <label>Due on</label>
                <Select
                  options={[
                    { label: 1, value: 1 },
                    { label: 2, value: 2 },
                    { label: 3, value: 3 },
                    { label: 4, value: 4 },
                    { label: 5, value: 5 },
                    { label: 6, value: 6 },
                    { label: 7, value: 7 },
                    { label: 8, value: 8 },
                    { label: 9, value: 9 },
                    { label: 10, value: 10 }
                  ]}
                  placeholder=""
                  styles={theme === "light" ? adminStylesLight : adminStyles}
                  className="w-max mb-12"
                  value={{ label: editedFields?.dueDays, value: editedFields?.dueDays }}
                  onChange={(e) =>
                    handleEditChange("dueDays", e.value)
                  }
                />
                <label>days after joining date <span className="asterisk-imp">*</span></label>
              </div>

              {/* <div>
                <label htmlFor="act_name">
                  Attach file
                </label>
                <div className='flex gap-4 items-center'>
                  <input
                    type="file"
                    ref={editFileInputRef}
                    onChange={({ target }) =>
                      handleEditChange("file", target.files[0])
                    }
                  />
                  <SlReload
                    className="cursor-pointer font-extrabold ml-6 text-3xl text-[#36abf9] mt-3"
                    onClick={() => {
                      if (editFileInputRef.current) editFileInputRef.current.value = '';
                    }}
                  />
                </div>
              </div> */}

              <div>
                <label htmlFor="act_name">
                  Allot division to <span className="asterisk-imp">*</span>
                </label>
                <Select
                  options={designationSelect}
                  placeholder="Select Designation"
                  styles={theme == "light" ? adminStylesLight : adminStyles}
                  className='w-max'
                  value={defAllotment}
                  onChange={(e) =>
                    handleEditChange("allotedTo", e.label)
                  }
                />
              </div>

              <div>
                <label htmlFor="remin">
                  Send Reminder to assignee
                  <span className="asterisk-imp">*</span>
                </label>
                <input
                  id='remin'
                  type="date"
                  placeholder="Select Date"
                  style={{
                    boxShadow: "none"
                  }}
                  value={editedFields?.reminderDate}
                  onChange={({ target }) =>
                    handleEditChange("reminderDate", target?.value)
                  }
                />
              </div>

              <div className="flex items-center mt-6 gap-10">
                <button
                  type="submit"
                  className="px-16 py-2 text-white bg-[#36abf9] rounded-xl cursor-pointer"
                >
                  Edit
                </button>
                <span
                  className="text-cyan cursor-pointer"
                  onClick={() => {
                    seteditModal(false)
                  }}
                >
                  Cancel
                </span>
              </div>
            </div>
          </form>
        }
      />

    </main >
  )
}

export default division
