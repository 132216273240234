import { useEffect, useState, useMemo, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../../../components/PageTitle";
import customToast from "../../../../components/CustomToast";
import { GetAllEmployees, UploadDocument } from "../../../../services/hrms";
import { fileToBase64 } from "../../../../utils/helper";
import Select from "react-select";
import {
  fetchEmployees,
  setEmployees,
} from "../../../../reducers/employeesReducer";
import { generateSelectData } from "../../../../utils/serialiseResponse";
import { adminStyles, adminStylesLight } from "../../../../utils/selectStyles";


const selectStyle = {
  control: (provided, state) => ({
    ...provided,
    padding: "0.1rem 1rem",
    backgroundColor: "#2B2C47",
    color: "#C7C4E9",
    border: "5px",
    borderRadius: "5px",
    boxShadow: "none",
    minHeight: "3.5rem",
    maxHeight: "3.5rem",
    width: "100%",
    marginTop: "1rem",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    color: "#9E9AD1",

    "&:hover": {
      backgroundColor: "#393b5f",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#9e9ad0",
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    width: "80%",
  }),
  input: (provided, state) => ({
    ...provided,
    border: "none",
    width: "100%",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#C7C4E9",

    "&:hover": {
      color: "#9E9AD1",
    },
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#9E9AD1",
  }),
  indicatorSeparator: () => null,
};


const PanCard = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchEmployees({}));
  }, []);

  const employeeData = useSelector((state) => state.employees.data);

  const employeeSelectData = useMemo(
    () => generateSelectData(employeeData, "fullName"),
    [employeeData],
  );

  const [selectedEmployee, setSelectedEmployee] = useState();
  const [panNumber, setPanNumber] = useState("");
  const [uploadData, setUploadData] = useState([]);

  let changeHandler = (e) => {
    const validFiles = Array.from(e.target.files);
    setUploadData([]);
    Promise.all(validFiles.map(fileToBase64))
      .then((base64Images) => {
        setUploadData(base64Images);
      })
      .catch((error) => {
        customToast.error("Error adding images");
      });
  };

  const resetStates = () => {
    setSelectedEmployee("");
    setPanNumber("");
    setUploadData([]);
  };

  const handleEmployeeSelect = (e) => {
    setSelectedEmployee({ ...e });
    if (e.panDetails.panNumber) {
      setPanNumber(e.panDetails.panNumber);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedEmployee) {
      customToast.error("Please Select Employee !!");
      return;
    }
    if (!panNumber) {
      customToast.error("Please Enter Pan Number !!");
    }
    try {
      const payload = {
        employeeId: selectedEmployee?.value,
        docType: "panCard",
        number: panNumber,
        uploadData,
      };
      const response = await UploadDocument(payload);
      if (response.ok) {
        dispatch(fetchEmployees());
        customToast.success("Pan Card Details Uploaded Successfully");
      }
      resetStates();
    } catch (error) {
      customToast.error("Error Uploading Pan Card Details");
    }
  };

  const theme = useSelector((state) => state.theme.theme);

  return (
    <main className="main-content bg-primaryLight2 dark:bg-primaryDark1">
      <div className={"area-creation-content" + `${theme == "light" ? "light_bg" : ""}`}>
        <PageTitle title="PAN CARD DETAILS" route={'/employee/manage/create'} />

        <form>
          <section className="admin-content">
            <div className="row">
              <div>
                <label>Select Employee <span className="asterisk-imp">*</span></label>
                <Select
                  name="emp_select"
                  value={selectedEmployee}
                  styles={theme == "light" ? adminStylesLight : adminStyles}
                  options={employeeSelectData}
                  onChange={handleEmployeeSelect}
                  placeholder="Select Employee"
                />
              </div>
              <div>
                <label>
                  Pan Number <span className="asterisk-imp">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter PAN Number"
                  value={panNumber}
                  onChange={({ target }) => setPanNumber(target?.value)}
                />
              </div>
            </div>

            <div className="mx-[2rem]">
              <div className="w-max">
                <label className="">UPLOAD DOCUMENT</label> <span className="asterisk-imp">*</span>
                <div className="flex flex-col">
                  <input type="file" onChange={changeHandler} className="dark:bg-primaryDark2 w-full" />
                  <span className="text-lg text-gray-500 mt-2">*Please upload only JPG, JPEG or PDF format, no larger than 5 MB.</span>
                </div>
              </div>
            </div>

            <div className="row pb-10">
              <button type="submit" className="button-blue-gradient" onClick={(e) => handleSubmit(e)}>
                Upload
              </button>
            </div>
          </section>
        </form>
      </div>

    </main>
  );
};

export default PanCard;
