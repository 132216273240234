import React, { useEffect, useMemo, useRef, useState } from 'react'
import PageTitle from '../../../../components/PageTitle';
import Select from "react-select";
import { useDispatch, useSelector } from 'react-redux';
import { adminStylesLight, adminStyles } from '../../../../utils/selectStyles';
import { clearEmployees, fetchEmployees } from '../../../../reducers/employeesReducer';
import { generateSelectData, generateSelectDataForReimbursement } from '../../../../utils/serialiseResponse';
import { fetchReimbursements } from '../../../../reducers/reimbursementsReducer';
import { fileToBase64 } from '../../../../utils/helper';
import { addClaim } from '../../../../services/addClaim';
import customToast from '../../../../components/CustomToast';

const AddDetails = () => {

    const inputRef = useRef(null);
    const dispatch = useDispatch();
    const theme = useSelector((state) => state.theme.theme);
    const employees = useSelector(({ employees }) => employees);
    const reimbursements = useSelector(({ reimbursements }) => reimbursements);
    const [selectEmpl, setSelectEmpl] = useState(null);
    const [reimbursementType, setReimbursementType] = useState(null);
    const [claimDate, setClaimDate] = useState(null);
    const [billDate, setBillDate] = useState(null);
    const [billNumber, setbillNumber] = useState(null);
    const [amtApproved, setAmtApproved] = useState(0);
    const [amtClaimed, setAmtClaimed] = useState(0);
    const [uploadData, setUploadData] = useState(null);

    useEffect(() => {
        dispatch(fetchEmployees());
        dispatch(fetchReimbursements());

        return () => {
            dispatch(clearEmployees());
        }
    }, [dispatch]);

    const epmlSelect = useMemo(
        () => generateSelectData(employees.data, "fullName"),
        [employees.data]
    );

    const reimburseOptions = useMemo(
        () => generateSelectDataForReimbursement(reimbursements.data),
        [reimbursements.data]
    );

    let changeHandler = (e) => {
        const validFiles = Array.from(e.target.files);
        setUploadData([]);
        Promise.all(validFiles.map(fileToBase64))
            .then((base64Images) => {
                setUploadData(base64Images);
            })
            .catch((error) => {
                customToast.error('Error adding images');
            });
    };

    const resetForm = () => {
        setAmtApproved("");
        setAmtClaimed("");
        setSelectEmpl("");
        setBillDate("");
        setbillNumber("");
        setReimbursementType("");
        setUploadData("");
        setClaimDate("");
    };

    const handleApprove = async (e) => {
        e.preventDefault();

        let payload = {
            employeeName: selectEmpl?.value,
            claimDate: claimDate,
            reimbursementType: reimbursementType,
            billDate: billDate,
            billNumber: billNumber,
            amt_claimed: amtClaimed,
            amt_approved: amtApproved,
            files: uploadData
        }
        try {
            await addClaim(payload);
            resetForm();
            customToast.success("Claim added successfully !");
        } catch (error) {
            console.log(error);
            customToast.error("something went wrong !");
        }
    };

    return (
        <main className='w-full'>
            <div className={
                "creation-content " + `${theme == "light" ? "light_bg" : ""}`
            }>

                <PageTitle title="Add Details" route={'../..'} />

                <section className="admin-content">

                    <div className='row'>
                        <div>
                            <label>
                                Employee Name
                            </label>
                            <Select
                                options={epmlSelect}
                                styles={theme == "light" ? adminStylesLight : adminStyles}
                                placeholder="Select Employee"
                                onChange={(el) => setSelectEmpl(el)}
                                value={selectEmpl}
                            />
                        </div>

                        <div>
                            <label>
                                Claim date
                            </label>
                            <br />
                            <input
                                type='date'
                                onChange={(e) => setClaimDate(e?.target?.value)}
                                value={claimDate} />
                        </div>

                        <div>
                            <label>
                                Reimbursement Type
                            </label>
                            <Select
                                // options={reimburseOptions}
                                styles={theme == "light" ? adminStylesLight : adminStyles}
                                placeholder="Select Employee"
                                onChange={(el) => setReimbursementType(el)}
                                value={reimbursementType}
                            />
                        </div>

                        <div>
                            <label>
                                Date on bill
                            </label>
                            <input
                                type='date'
                                onChange={(e) => setBillDate(e.target.value)}
                                value={billDate} />
                        </div>
                    </div>

                    <div className='row'>
                        <div>
                            <label>
                                Bill Number
                            </label>
                            <input
                                type='text'
                                placeholder='Enter Bill number'
                                onChange={(e) => setbillNumber(e?.target?.value)}
                                value={billNumber} />
                        </div>

                        <div>
                            <label>
                                Amount Claimed
                            </label>
                            <input
                                type='number'
                                placeholder='Enter amount claimed'
                                onChange={(e) => setAmtClaimed(e?.target?.value)}
                                value={amtClaimed}
                            />
                        </div>

                        <div>
                            <label>
                                Amount Approved
                            </label>
                            <input
                                type='number'
                                placeholder='Enter amount approved'
                                onChange={(e) => setAmtApproved(e?.target?.value)}
                                value={amtApproved} />
                        </div>
                    </div>

                    <div className='mx-8 w-[35vw]'>
                        <label>Upload Document</label> <span className="asterisk-imp">*</span>
                        <input
                            ref={inputRef}
                            type="file"
                            onChange={changeHandler}
                        />
                    </div>

                    <div className='mx-8 py-10'>
                        <button className="button-green-gradient"
                            onClick={(e) => handleApprove(e)}>
                            Approve
                        </button>
                    </div>

                </section>
            </div >
        </main >
    )
}

export default AddDetails