import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setCurrentStep } from "../../../reducers/setUpCurrentStepReducer";
import Earnings from "./Earnings";
import Deductions from "./Deductions";
import Reimbursements from "./Reimbursements";
import { ToastContainer } from "react-toastify";

const SalaryComponents = () => {
  const dispatch = useDispatch();
  const CURRENT_STEP = 5;
  useEffect(() => {
    dispatch(setCurrentStep(CURRENT_STEP));
  }, []);

  const [active, setActive] = useState("e");
  const [sidePanelOpen, setSidePanelOpen] = useState(false);
  const [postSidePanelOpen, setPostSidePanelOpen] = useState(false);
  const [showDiv, setShowDiv] = useState(false);

  const handleNextComponent = () => {
    setActive("d");
  };

  const handleDeductionNext = () => {
    setActive("r");
  };

  return (
    <>
      <div className="d-flex justify-content-between mt-2">
        <p>Salary Components </p>
        {active === "e" && (
          <div
            className="new_earning"
            onClick={() => {
              setSidePanelOpen(true);
            }}
          >
            <button className="submit_button submit_button_border-radius">
              Add New Earning
            </button>
          </div>
        )}
        {active === "d" && (
          <div className="new_earning" onClick={() => setShowDiv(!showDiv)}>
            <button className="submit_button submit_button_border-radius">
              Add New Deduction
            </button>
            {showDiv ? (
              <div className="deduction-button_div">
                <button
                  onClick={() => {
                    setSidePanelOpen(true);
                  }}
                >
                  Pre-Tax Deduction
                </button>
                <button
                  onClick={() => {
                    setPostSidePanelOpen(true);
                  }}
                >
                  Post-Tax Deduction
                </button>
              </div>
            ) : null}
          </div>
        )}
        {active === "r" && (
          <div
            className="new_earning"
            onClick={() => {
              setSidePanelOpen(true);
            }}
          >
            <button className="submit_button submit_button_border-radius">
              Add New Reimbursement
            </button>
          </div>
        )}
      </div>
      <div className="sal_comp_header mt-3" style={{ minWidth: "600px" }}>
        <h1
          className={active == "e" ? "active" : "not-active"}
          onClick={() => setActive("e")}
        >
          Earnings
        </h1>
        <h1
          className={active == "d" ? "active" : "not-active"}
          onClick={() => setActive("d")}
        >
          Deductions
        </h1>
        <h1
          className={active == "r" ? "active" : "not-active"}
          onClick={() => setActive("r")}
        >
          Reimbursements
        </h1>
      </div>

      {active === "e" && (
        <Earnings
          onDeduction={handleNextComponent}
          setSidePanelOpen={setSidePanelOpen}
          sidePanelOpen={sidePanelOpen}
        />
      )}
      {active === "d" && (
        <Deductions
          onReimbursement={handleDeductionNext}
          setSidePanelOpen={setSidePanelOpen}
          sidePanelOpen={sidePanelOpen}
          setPostSidePanelOpen={setPostSidePanelOpen}
          postSidePanelOpen={postSidePanelOpen}
        />
      )}
      {active === "r" && (
        <Reimbursements
          setSidePanelOpen={setSidePanelOpen}
          sidePanelOpen={sidePanelOpen}
        />
      )}

      <ToastContainer />
    </>
  );
};

export default SalaryComponents;
