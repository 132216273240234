import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import PageTitle from '../../../../components/PageTitle';
import Select from 'react-select';
import { adminStyles, adminStylesLight } from '../../../../utils/selectStyles';
import './addsubdivision.css'

const AddSubDivision = () => {
    const theme = useSelector((state) => state.theme.theme);

    const [divisionName, setDivisionName] = useState(null);
    const [subDivisionName, setSubDivisionName] = useState('');
    const [subDivisionCode, setSubDivisionCode] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [location, setLocation] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [bankBranch, setBankBranch] = useState('');
    const [bankAccountNumber, setBankAccountNumber] = useState('');
    const [ifscCode, setIfscCode] = useState('');
    const [bankName, setBankName] = useState('');

  return (
    <main className={"main-content " + `${theme == "light" ? "light_bg_main" : ""}`} style={{width:'100%'} }>
        <div className="creation-content" style={{color:"white"}}>
        <PageTitle title="Add Sub Division" />
        </div>

        <section className={"creation-content_form" + `${theme == "light" ? "section-form_content" : ""}`}>
          <form >
          <div className="row">
            <div className="row">
            <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                <label >
                  DIVISION NAME<span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  styles={theme == "light" ? adminStylesLight : adminStyles}
                  placeholder="Enter Divsion Name" 
                  className='mt-4'
                  value={divisionName}
                  onChange={({ value, label }) => setDivisionName({ value, label })}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                <label htmlFor="mName">
                  SUB DIVISION NAME <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                  className={"me-5 mt-4 "  + (theme === 'light' ? 'input-padding' : '')}
                  id="mName"
                  placeholder="Enter Sub division"
                  value={subDivisionName}
                  onChange={(e) => setSubDivisionName(e.target.name)}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                <label htmlFor="mName">
                  SUB DIVISION CODE <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                  className={"me-5 mt-4 "  + (theme === 'light' ? 'input-padding' : '')}
                  id="mName"
                  placeholder="Enter Sub Division Code"
                  value={subDivisionCode}
                  onChange={(e) => setSubDivisionCode(e.target.name)}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                <label htmlFor="mName">
                  PHONE NUMBER <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                  className={"me-5 mt-4 "  + (theme === 'light' ? 'input-padding' : '')}
                  id="mName"
                  placeholder="Enter Phone Number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.name)}
                />
              </div>
            </div>

            <div className="row">
            <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                <label >
                  ADDRESS LINE 1<span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                  className={"me-5 mt-4 "  + (theme === 'light' ? 'input-padding' : '')}
                  id="mName"
                  placeholder="Enter Address line 1"
                  value={addressLine1}
                  onChange={(e) => setAddressLine1(e.target.name)}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                <label htmlFor="mName">
                  ADDRESS LINE 2<span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                  className={"me-5 mt-4 "  + (theme === 'light' ? 'input-padding' : '')}
                  id="mName"
                  placeholder="Enter Address line 2"
                  value={addressLine2}
                  onChange={(e) => setAddressLine2(e.target.name)}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                <label htmlFor="mName">
                  LOCATION 
                </label>
                <br />
                <input
                  type="text"
                  className={"me-5 mt-4 "  + (theme === 'light' ? 'input-padding' : '')}
                  id="mName"
                  placeholder="Enter Location"
                  value={location}
                  onChange={(e) => setLocation(e.target.name)}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                <label htmlFor="mName">
                  EMAIL ADDRESS
                </label>
                <br />
                <input
                  type="email"
                  className={"me-5 mt-4 "  + (theme === 'light' ? 'input-padding' : '')}
                  id="mName"
                  placeholder="Enter Email Address"
                  value={emailAddress}
                  onChange={(e) => setEmailAddress(e.target.name)}
                />
              </div>
            </div>

            <div className="row">
            <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                <label >
                  BANK BRANCH
                </label>
                <br />
                <input
                  type="text"
                  className={"me-5 mt-4 "  + (theme === 'light' ? 'input-padding' : '')}
                  id="mName"
                  placeholder="Enter Bank Branch"
                  value={bankBranch}
                  onChange={(e) => setBankBranch(e.target.name)}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                <label htmlFor="mName">
                  BANK ACCOUNT NUMBER
                </label>
                <br />
                <input
                  type="text"
                  className={"me-5 mt-4 "  + (theme === 'light' ? 'input-padding' : '')}
                  id="mName"
                  placeholder="Enter Account Number"
                  value={bankAccountNumber}
                  onChange={(e) => setBankAccountNumber(e.target.name)}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                <label htmlFor="mName">
                  IFSC CODE
                </label>
                <br />
                <input
                  type="text"
                  className={"me-5 mt-4 "  + (theme === 'light' ? 'input-padding' : '')}
                  id="mName"
                  placeholder="Enter IFSC Code"
                  value={ifscCode}
                  onChange={(e) => setIfscCode(e.target.name)}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                <label htmlFor="mName">
                   BANK NAME
                </label>
                <br />
                <input
                  type="text"
                  className={"me-5 mt-4 "  + (theme === 'light' ? 'input-padding' : '')}
                  id="mName"
                  placeholder="Enter Bank Name"
                  value={bankName}
                  onChange={(e) => setBankName(e.target.name)}
                />
              </div>
            </div>     
           </div>

            <div className="col-sm-2 col-md-4 col-lg-2 mt-5">
              <button type="submit" className="button-blue-gradient">
                Save
              </button>
            </div> 
          </form>   
        </section>
    </main>
  )
}

export default AddSubDivision;