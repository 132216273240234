import { MdArrowBackIosNew, MdDelete } from "react-icons/md";
import {
  IoIosArrowDropdownCircle,
  IoIosArrowDropupCircle,
} from "react-icons/io";
import { useNavigate } from "react-router-dom";
import "../component.css";
import { useSelector } from "react-redux";
import React from "react";

function PageTitleForEmployeeInfo({
  title,
  isDelete,
  toggle,
  isDropdown,
  isDropup,
  hierarchy,
  route,
  showUpdateButton,
}) {
  const navigate = useNavigate();
  const theme = useSelector((state) => state.theme.theme);

  const handleBackNavigation = () => {
    if (showUpdateButton) {
      if (window.confirm("Are you sure you want to navigate back?")) {
        navigate(-1);
      }
    } else {
      navigate(route);
    }
  };

  return (
    <>
      <section
        className={
          "pagetitle-heading " + `${theme === "light" ? "light_bg" : ""}`
        }
      >
        <span
          className="flex items-center cursor-pointer"
          onClick={handleBackNavigation}
        >
          <MdArrowBackIosNew
            className={
              "pagetitle-backarrow " +
              `${theme === "light" ? "light_bg_logo" : ""}`
            }
            onClick={handleBackNavigation}
          />
          <h2
            className={
              "pagetitle-header " +
              `${theme === "light" ? "light_bg_logo" : ""}`
            }
          >
            {title}
          </h2>
        </span>
        {isDelete ? (
          <MdDelete
            className="icon-color-error ms-auto"
            style={{ cursor: "pointer" }}
            onClick={() => toggle(true)}
          />
        ) : null}
        {isDropdown ? (
          <IoIosArrowDropdownCircle
            className="icon-color-green ms-auto cursor-pointer expense-calculation__container-icon-money"
            onClick={() => toggle(true)}
          />
        ) : null}
        {isDropup ? (
          <IoIosArrowDropupCircle
            className="icon-color-green ms-auto cursor-pointer expense-calculation__container-icon-money"
            onClick={() => toggle(false)}
          />
        ) : null}
      </section>
    </>
  );
}

export default PageTitleForEmployeeInfo;
