import React from "react";
import { BiCalendarExclamation, BiRevision } from "react-icons/bi";
import { CiCreditCard2 } from "react-icons/ci";
import { FaCalendarCheck, FaUser } from "react-icons/fa6";
import { GiTakeMyMoney } from "react-icons/gi";
import { HiRefresh } from "react-icons/hi";
import { HiMiniReceiptPercent } from "react-icons/hi2";
import { IoNewspaperOutline, IoPersonCircleOutline } from "react-icons/io5";
import { LuBarChart4 } from "react-icons/lu";
import {
  MdOutlineDownloading,
  MdOutlineVerified,
  MdPieChart,
} from "react-icons/md";
import { PiUploadFill } from "react-icons/pi";
import { RiMoneyDollarCircleLine, RiUserShared2Fill } from "react-icons/ri";
import { SiGoogleforms, SiMoneygram } from "react-icons/si";
import { TbMoneybag, TbReportAnalytics, TbTemplate } from "react-icons/tb";
import { Link } from "react-router-dom";

const ReportCard = ({ title, type, link }) => {
  const renderIcon = (type) => {
    if (type === "ManagePayrun") return <HiRefresh />;
    else if (type === "Leave") return <BiCalendarExclamation />;
    else if (type === "payroll") return <IoNewspaperOutline />;
    else if (type === "salary") return <TbReportAnalytics />;
    else if (type === "statutory") return <MdPieChart />;
    else if (type === "tempelates") return <LuBarChart4 />;
    else if (type === "taxes") return <SiGoogleforms />;
    else if (type === "UploadAttendance") return <PiUploadFill />;
    else if (type === "Loan") return <CiCreditCard2 />;
    else if (type === "Loan/AdvanceSalary") return <CiCreditCard2 />;
    else if (type === "manage-employee") return <IoPersonCircleOutline />;
    else if (type === "onboarding") return <RiUserShared2Fill />;
    else if (type === "profile") return <FaUser />;
    else if (type === "previous-salary") return <RiMoneyDollarCircleLine />;
    else if (type === "investment") return <SiMoneygram />;
    else if (type === "reimbursement") return <TbMoneybag />;
    else if (type === "approve-salary-revision") return <BiRevision />;
    else if (type === "masterdata") return <FaUser />;
    else if (type === "money") return <GiTakeMyMoney />;
    else if (type === "tempelate") return <TbTemplate />;
    else if (type === "tax") return <HiMiniReceiptPercent />;
    else if (type === "statuatory") return <MdPieChart />;
    else if (type === "schedule-payment") return <FaCalendarCheck />;
  };
  return (
    <Link
      to={link}
      className="admin-report-card bg-primaryLight pl-14 py-14 !opacity-100"
    >
      <section className="!opacity-100 flex gap-5">
        <span className="text-[#1ABC9C] text-5xl">{renderIcon(type)}</span>
        <section className="flex flex-col gap-7">
          <span className="!opacity-100 dark:text-slate-200 font-semibold">
            {title}
          </span>
          <section className="!opacity-100 dark:text-slate-200 flex gap-4 !text-[#359CE4] items-center">
            <span className="text-3xl">
              <MdOutlineDownloading />
            </span>
            <span className="text-xl">{title}</span>
          </section>
          <section className="!opacity-100 dark:text-slate-200 flex gap-4 !text-[#209A8A] items-center">
            <span className="text-3xl">
              <MdOutlineVerified />
            </span>
            <span className="text-xl">{title}</span>
          </section>
        </section>
      </section>
    </Link>
  );
};

export default ReportCard;
