import { createSlice } from "@reduxjs/toolkit";
import { customAxios } from "../utils/axiosinstance";
import {
  newDesignation,
  viewDesignations,
  removeDesignation,
  editDesig,
} from "../services/user";

const divisionsSlice = createSlice({
  name: "designations",
  initialState: {
    data: [],
    status: "idle",
    error: null,
  },
  reducers: {
    setDesg: (state, action) => {
      state.data = action.payload.data;
    },
    setStatus: (state, action) => {
      state.status = action.payload.status;
      state.error = action.payload.error || null;
    },
  },
});

export const { setDesg, setStatus } = divisionsSlice.actions;

export const setDesignations = () => async (dispatch) => {
  dispatch(setStatus({ status: "loading", error: null }));
  try {
    const { data } = await viewDesignations();
    dispatch(setDesg({ data }));
    dispatch(setStatus({ status: "idle", error: null }));
  } catch (err) {
    dispatch(setStatus({ status: "failed", error: err?.message }));
  }
};

export const addDesignation = (data) => async (dispatch) => {
  try {
    await newDesignation(data);
    dispatch(setDesignations());
  } catch (error) {
  }
};

export const clearDesignations = () => async (dispatch) => {
  dispatch(setDesg({ data: [] }));
  dispatch(setStatus({ status: "idle", error: null }));
};

export const deleteDesignation = (id) => async (dispatch) => {
  try {
    const type = "designation";
    await removeDesignation(type, id);
    dispatch(setDesignations());
  } catch (error) {
  }
};

export const editDesignation = (id, data) => async (dispatch) => {
  try {
    const type = "designation";
    await editDesig(id, type, data);
    dispatch(setDesignations());
  } catch (error) {
  }
};

export default divisionsSlice.reducer;
