export const title = "Manage Statutory Components";

export const links = [
  {
    name: "ESI",
    route: "esi",
    accessor: "esi",
  },
  {
    name: "EPF",
    route: "epf",
    accessor: "epf",
  },
  {
    name: "PROFESSIONAL TAX",
    route: "professional-tax",
    accessor: "professionalTax",
  },
  {
    name: "LABOUR WELFARE FUND",
    route: "labourwelfarefund",
    accessor: "labourWelfareFund",
  },
];
