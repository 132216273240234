import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import PageTitle from '../../../../components/PageTitle';
import Select from 'react-select';
import { adminStyles, adminStylesLight } from '../../../../utils/selectStyles';


const AddDesignation = () => {
    const theme = useSelector((state) => state.theme.theme);

    const [divisionName, setDivisionName] = useState(null);
    const [designation, setDesignation] = useState('');
    const [designationCode, setDesignationCode] = useState('');
    const [noticePeriod, setNoticePeriod] = useState('');
    const [description , setDescription] = useState(null);
   
  return (
    <main className={"main-content " + `${theme == "light" ? "light_bg_main" : ""}`} style={{width:'100%'} }>
        <div className="creation-content" style={{color:"white"}}>
        <PageTitle title="Add Department" />
        </div>

        <section className={"creation-content_form" + `${theme == "light" ? "section-form_content" : ""}`}>
          <form >
          <div className="row">
            <div className="row">
            <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                <label >
                  DIVISION NAME<span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  styles={theme == "light" ? adminStylesLight : adminStyles}
                  placeholder="Enter Divsion" 
                  className='mt-4'
                  value={divisionName}
                  onChange={({ value, label }) => setDivisionName({ value, label })}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                <label htmlFor="mName">
                  DESIGNATION <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                  className={"me-5 mt-4 "  + (theme === 'light' ? 'input-padding' : '')}
                  id="mName"
                  placeholder="Enter Designation"
                  value={designation}
                  onChange={(e) => setDesignation(e.target.name)}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                <label htmlFor="mName">
                  DESIGNATION CODE <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                  className={"me-5 mt-4 "  + (theme === 'light' ? 'input-padding' : '')}
                  id="mName"
                  placeholder="Enter Designation Code"
                  value={designationCode}
                  onChange={(e) => setDesignationCode(e.target.name)}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                <label htmlFor="mName">
                  NOTICE PERIOD <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                  className={"me-5 mt-4 "  + (theme === 'light' ? 'input-padding' : '')}
                  id="mName"
                  placeholder="Enter Notice Period"
                  value={noticePeriod}
                  onChange={(e) => setNoticePeriod(e.target.name)}
                />
              </div>
            </div>
             
            <div className="row"> 
               <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                <label htmlFor="mName">
                  DESIGNATION <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                  className={"me-5 mt-4 "  + (theme === 'light' ? 'input-padding' : '')}
                  id="mName"
                  placeholder="Enter Designation"
                  value={designation}
                  onChange={(e) => setDesignation(e.target.name)}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                <label >
                  DIVISION NAME<span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  styles={theme == "light" ? adminStylesLight : adminStyles}
                  placeholder="Enter Divsion" 
                  className='mt-4'
                  value={divisionName}
                  onChange={({ value, label }) => setDivisionName({ value, label })}
                />
              </div> 
            </div>
             
            <div className="row mt-5">
              <div className="col-sm-12 col-md-4 col-lg-3 mt-2">
                <label htmlFor="mName">
                  Description <span className="asterisk-imp">*</span>
                </label>
                <br />
                <textarea rows="4" cols="50"
                 placeholder="Enter description..." 
                 className={theme==='light' ? 
                 "text-area-bg-light" :"text-area-bg"}
                 value={description}
                 onChange={(e) => setDescription(e.target.value)}
                 ></textarea>
              </div>
            </div>

           </div>

            <div className="col-sm-2 col-md-4 col-lg-2 mt-5">
              <button type="submit" className="button-blue-gradient">
                Save
              </button>
            </div> 
          </form>   
        </section>
    </main>
  )
}

export default AddDesignation;