import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./department.css";
import Table from "../../../components/Table";
import { FaPen } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../../components/PageTitle";
import {
  createDepartments,
  editDepartments,
  deleteDepartemts,
  setDepartments,
} from "../../../reducers/departmentReducer";
import serialise from "../../../utils/serialiseResponse";
import EditPopup from "../../../components/EditPopup";
import customToast from "../../../components/CustomToast";

const Department = () => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.theme);
  const { data, status, error } = useSelector(({ department }) => department);

  const [editing, setEditing] = useState(false);
  const [deptName, setDept] = useState("");
  const [updateId, setUpdateId] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!deptName)
        return customToast.error("Please mention department Name ");
      dispatch(createDepartments({ deptName }));
      setDept("");
      return customToast.success("Department Added successFully");
    } catch (error) {
      customToast.error("Error in adding department !");
    }
  };

  useEffect(() => {
    dispatch(setDepartments());
  }, []);

  const handleEdit = async (e) => {
    e.preventDefault();

    try {
      dispatch(editDepartments(updateId, { name: deptName }));
      setEditing(false);
      setDept("");
      return customToast.success("Edited successfully");
    } catch (err) {
      return customToast.error("Unable to edit");
    }
  };

  const handleDelete = () => {
    try {
      dispatch(deleteDepartemts(updateId));
      setEditing(false);
      setDept("");
      return customToast.success("Deleted successfully");
    } catch (err) {
      return customToast.err("Unable to delete");
    }
  };
  const columns = useMemo(
    () => [
      {
        Header: "Sr no.",
        accessor: "sr",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Department",
        accessor: "name",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Department Code",
        accessor: "uid",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Action",
        accessor: "Action",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => (
          <span
            className="react-table-view-link"
            onClick={() => {
              setEditing(true);
              setUpdateId(props?.row?.original?._id);
              setDept(props?.row?.original?.name);
            }}
          >
            <FaPen className="text-3xl dark:text-tertiary" />
          </span>
        ),
      },
    ],
    [],
  );
  const tableData = useMemo(() => serialise(data), [data]);
  console.log(tableData);

  return (
    <main className="main-content bg-primaryLight2 dark:bg-primaryDark1">
      <div
        className={
          "area-creation-content" + `${theme == "light" ? "light_bg" : ""}`
        }
      >
        <PageTitle title="Department" route={"../.."} />
        <form>
          <section className="admin-content">
            <div className="row">
              <div>
                <label>
                  Enter Department Name <span className="asterisk-imp">*</span>
                </label>
                <input
                  placeholder="Enter Department"
                  value={deptName}
                  onChange={(e) => setDept(e.target.value)}
                />
              </div>

              <div className="items-end flex">
                <button
                  onClick={handleSubmit}
                  className="button-blue-gradient py-[0.8rem] px-[2rem]"
                >
                  Add Department
                </button>
              </div>
            </div>

            <div className="mx-8 pb-10">
              {tableData.length > 0 && (
                <h2
                  className={
                    "show-entries" +
                    `${theme === "light" ? "light_bg_logo" : ""}`
                  }
                >
                  Showing ({tableData.length}) Entries
                </h2>
              )}

              {tableData.length > 0 ? (
                <div className="filter-table">
                  <Table columns={columns} data={tableData} />
                </div>
              ) : (
                <div className="text-error font-medium text-3xl mt-5">
                  No departments Exists!
                </div>
              )}
              {editing && (
                <EditPopup
                  placeholder="Enter Department"
                  value={deptName}
                  setValue={setDept}
                  setShowEdit={setEditing}
                  handleDelete={handleDelete}
                  handleEdit={handleEdit}
                  label="Department Name"
                />
              )}
            </div>
          </section>
        </form>
      </div>
    </main>

    // <main className="mt-8 mr-8 w-full bg-whiteLight dark:bg-primaryDark pb-28">
    //   <div className="dark:bg-primaryDark2">
    //     <PageTitle title="Department" route={'../..'}/>
    //   </div>
    //   <div className="uppercase text-3xl font-medium dark:text-secondary mt-8">
    //     Department Name <span className="text-error">*</span>
    //   </div>
    //   <div className="mt-4 flex gap-8 justify-start items-center">
    // <input
    //   className="px-5 py-3 inline-block dark:bg-primary dark:text-secondary outline-none"
    //   placeholder="Enter Department Name"
    //   value={deptName}
    //   onChange={(e) => setDept(e.target.value)}
    // />
    // <button
    //   onClick={handleSubmit}
    //   className="inline-block button_blue_gradient dark:text-tertiary"
    // >
    //   Add Department
    // </button>
    //   </div>
    // {tableData.length > 0 && (
    //   <h2
    //     className={
    //       "mt-5 pt-5 ms-2 web-app__heading " +
    //       `${theme === "light" ? "light_bg_logo" : ""}`
    //     }
    //   >
    //     Showing ({tableData.length}) Entries
    //   </h2>
    // )}

    // {tableData.length > 0 ? (
    //   <div className="filter-table">
    //     <Table columns={columns} data={tableData} />
    //   </div>
    // ) : (
    //   <div className="text-error font-medium text-3xl mt-5">
    //     No departments Exists!
    //   </div>
    // )}
    // {editing && (
    //   <EditPopup
    //     placeholder="Enter Department"
    //     value={deptName}
    //     setValue={setDept}
    //     setShowEdit={setEditing}
    //     handleDelete={handleDelete}
    //     handleEdit={handleEdit}
    //     label="Department Name"
    //   />
    // )}
    // </main>
  );
};

export default Department;
