import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { setCurrentStep } from "../../../reducers/setUpCurrentStepReducer";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { setUpSelectStyles } from "../../../utils/selectStyles";
import customToast from "../../../components/CustomToast";
import { CreatePaymentSchedule } from "../../../services/paymentschedule";
import { IoCalendarClear } from "react-icons/io5";
import { ConfigurePayRunApproveStatus } from "../../../services/payRun";

const weekDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
const companyWorkingDaysOptions = [
  { value: 20, label: "20" },
  { value: 21, label: "21" },
  { value: 22, label: "22" },
  { value: 23, label: "23" },
  { value: 24, label: "24" },
  { value: 25, label: "25" },
  { value: 26, label: "26" },
  { value: 27, label: "27" },
  { value: 28, label: "28" },
  { value: 29, label: "29" },
  { value: 30, label: "30" },
  { value: 31, label: "31" },
];
const makePaymentOnOptions = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
  { value: 7, label: "7" },
  { value: 8, label: "8" },
  { value: 9, label: "9" },
  { value: 10, label: "10" },
  { value: 11, label: "11" },
  { value: 12, label: "12" },
  { value: 13, label: "13" },
  { value: 14, label: "14" },
  { value: 15, label: "15" },
  { value: 16, label: "16" },
  { value: 17, label: "17" },
  { value: 18, label: "18" },
  { value: 19, label: "19" },
  { value: 20, label: "20" },
  { value: 21, label: "21" },
  { value: 22, label: "22" },
  { value: 23, label: "23" },
  { value: 24, label: "24" },
  { value: 25, label: "25" },
  { value: 26, label: "26" },
  { value: 27, label: "27" },
  { value: 28, label: "28" },
  { value: 29, label: "29" },
  { value: 30, label: "30" },
  { value: 31, label: "31" },
];

const SchedulePayment = () => {
  const CURRENT_STEP = 3;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setCurrentStep(CURRENT_STEP));
  }, []);

  const [selectedDay, setSelectedDay] = useState([]);
  const [actualDays, setActualDays] = useState(false);
  const [companyDays, setCompanyDays] = useState(false);
  const [lastDay, setLastDay] = useState(false);
  const [dayOfmonth, setDayOfMonth] = useState(false);

  const [salaryCalculationBasis, setSalaryCalculationBasis] = useState({
    type: "",
    value: null,
  });
  const [makePaymentOn, setMakePaymentOn] = useState({
    type: "",
    value: null,
  });

  const [selectedOption, setSelectedOption] = useState({
    value: null,
    label: "",
  });
  const [selectedOption2, setSelectedOption2] = useState({
    value: null,
    label: "",
  });

  const [paymentDate, setPaymentDate] = useState("");
  const [firstPayMonth, setFirstPayMonth] = useState({});
  const [paymentMonth, setPaymentMonth] = useState({});

  // const dateRef = useRef(null);
  // const handleCalendarClick = () => {
  //   dateRef.current.click();
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     if (selectedDay.length === 0) {
  //       customToast.error("Please select work days");
  //       return;
  //     }
  //     if (salaryCalculationBasis.type === "") {
  //       customToast.error("Please select salary calculation basis");
  //       return;
  //     }
  //     if (makePaymentOn.type === "") {
  //       customToast.error("Please select make payment on");
  //       return;
  //     }
  //     if (
  //       salaryCalculationBasis.type === "company" &&
  //       !salaryCalculationBasis.value
  //     ) {
  //       customToast.error("Please select company working days");
  //       return;
  //     }
  //     if (
  //       makePaymentOn.type === "dayOfFollowingMonth" &&
  //       !makePaymentOn.value
  //     ) {
  //       customToast.error("Please select day of following month");
  //       return;
  //     }
  //     const paymentScheduleDetails = {
  //       selectedDay,
  //       salaryCalculationBasis,
  //       makePaymentOn,
  //     };
  //     console.log("payload",paymentScheduleDetails);
  //     await addPaymentScheduleInfo(paymentScheduleDetails);
  //     customToast.success("Payment Schedule Added Successfully");
  //     navigate("/setup/statutory-components");
  //   } catch (error) {
  //     customToast.error("Something went wrong,please try again later");
  //   }
  // };

  const getMonthName = (monthIndex) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return months[monthIndex];
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (selectedDay.length === 0) {
        customToast.error("Please select work days");
        return;
      }
      if (salaryCalculationBasis.type === "") {
        customToast.error("Please select salary calculation basis");
        return;
      }
      if (makePaymentOn.type === "") {
        customToast.error("Please select make payment on");
        return;
      }
      if (
        salaryCalculationBasis.type === "company" &&
        !salaryCalculationBasis.value
      ) {
        customToast.error("Please select company working days");
        return;
      }
      if (
        makePaymentOn.type === "dayOfFollowingMonth" &&
        !makePaymentOn.value
      ) {
        customToast.error("Please select day of following month");
        return;
      }
      if (Object.keys(paymentMonth).length == 0) {
        customToast.error("Select Date for first month payment");
        return;
      }
      const paymentScheduleDetails = { selectedDay };

      if (firstPayMonth) {
        if (firstPayMonth.month == "0") {
          paymentScheduleDetails.firstPayMonth = firstPayMonth;
        } else {
          (firstPayMonth.month = parseInt(firstPayMonth.month) - 1),
            (paymentScheduleDetails.firstPayMonth = firstPayMonth);
        }
      }
      if (paymentMonth) {
        if (makePaymentOn.type === "lastWorkingDay") {
          paymentMonth.month = parseInt(firstPayMonth.month);
          paymentScheduleDetails.paymentMonth = paymentMonth;
        } else if (makePaymentOn.type === "dayOfFollowingMonth") {
          const nextMonth =
            firstPayMonth.month === 11 ? 0 : firstPayMonth.month + 1;
          const nextYear =
            firstPayMonth.month === 11
              ? parseInt(firstPayMonth.year) + 1
              : firstPayMonth.year;
          paymentScheduleDetails.paymentMonth = {
            month: nextMonth,
            year: nextYear,
          };
        } else if (firstPayMonth.month == "11") {
          paymentMonth.month = 0;
          paymentScheduleDetails.paymentMonth = paymentMonth;
        }
        paymentMonth.month = firstPayMonth.month;
        paymentScheduleDetails.paymentMonth = paymentMonth;
      }
      if (salaryCalculationBasis) {
        if (salaryCalculationBasis.type == "company") {
          paymentScheduleDetails.salaryCalculationBasis =
            salaryCalculationBasis;
        } else {
          let month = firstPayMonth.month;
          if (month == "1") {
            const year = parseInt(firstPayMonth.year);
            const isLeapYear =
              (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
            salaryCalculationBasis.value = isLeapYear ? 29 : 28;
            paymentScheduleDetails.salaryCalculationBasis =
              salaryCalculationBasis;
          } else if (month == "0" || month % 2 == 0) {
            salaryCalculationBasis.value = 31;
            paymentScheduleDetails.salaryCalculationBasis =
              salaryCalculationBasis;
          } else {
            salaryCalculationBasis.value = 30;
            paymentScheduleDetails.salaryCalculationBasis =
              salaryCalculationBasis;
          }
        }
      }
      if (makePaymentOn) {
        if (makePaymentOn.type == "dayOfFollowingMonth") {
          paymentScheduleDetails.makePaymentOn = makePaymentOn;
        } else {
          let date = new Date(
            parseInt(firstPayMonth.year),
            parseInt(firstPayMonth.month),
            parseInt(salaryCalculationBasis.value),
          );
          let difference = selectedDay.length - date.getDay();
          if (difference <= 0) {
            makePaymentOn.value = salaryCalculationBasis.value;
            paymentScheduleDetails.makePaymentOn = makePaymentOn;
          } else {
            makePaymentOn.value = salaryCalculationBasis.value - difference;
            paymentScheduleDetails.makePaymentOn = makePaymentOn;
          }
        }
      }

      // const response = await CreatePaymentSchedule(paymentScheduleDetails);

      const setUpStatusData = {
        docName: "payrunApproveStatus",
        month: 0,
        approveStatus: 0,
        year: 2024,
      };

      const res = await ConfigurePayRunApproveStatus(setUpStatusData);
      if (res) {
        const response = await CreatePaymentSchedule(paymentScheduleDetails);
        if (response) {
          customToast.success("Payment Schedule Added Successfully");
          setSelectedDay("");
          setActualDays("");
          setCompanyDays("");
          setFirstPayMonth("");
          setMakePaymentOn("");
          setSalaryCalculationBasis("");
          setPaymentMonth("");
          navigate("/setup/statutory-components");
        } else {
          customToast.error("Error in Adding Payment Schedule");
        }
      } else {
        customToast.error("Error in Adding Payment Schedule");
      }
    } catch (error) {
      customToast.error(error);
    }
  };

  const handleSkip = () => {
    navigate("/setup/statutory-components");
  };

  return (
    <>
      <p>Schedule Payment</p>
      <form onSubmit={handleSubmit} className="w-full">
        <div>
          <label>Select Work Days</label>
          <br />
          <div className=" week_input  flex flex-row justify-between mt-4 mb-2">
            {weekDays.map((day, index) => (
              <div
                key={index}
                onClick={() => {
                  if (selectedDay.includes(day)) {
                    setSelectedDay(selectedDay.filter((item) => item !== day));
                  } else {
                    setSelectedDay([...selectedDay, day]);
                  }
                }}
                className={
                  "px-10 py-2 w-20 flex cursor-pointer justify-center items-center rounded-lg border-2 border-blue-400 " +
                  `${selectedDay.includes(day) ? " bg-blue-400" : ""}`
                }
              >
                <span
                  className={
                    selectedDay.includes(day) ? "text-black" : "text-gray-500"
                  }
                >
                  {day}
                </span>
              </div>
            ))}
          </div>
        </div>

        <div className="terms">
          <label> Calculate Salary Based On</label>
          <div>
            <input
              id="chk-1"
              type="checkbox"
              checked={salaryCalculationBasis.type === "actual"}
              onClick={() =>
                setSalaryCalculationBasis({
                  type: "actual",
                  value: null,
                })
              }
              className="checkbox_input"
            />
            <label
              className="!m-0 !font-normal !text-xl cursor-pointer"
              htmlFor="chk-1"
            >
              Actual Days In a Month
            </label>
          </div>
          <div>
            <input
              id="chk-2"
              type="checkbox"
              checked={salaryCalculationBasis.type === "company"}
              onClick={() =>
                setSalaryCalculationBasis({
                  type: "company",
                  value: null,
                })
              }
              className="checkbox_input"
            />
            <span>
              <label htmlFor="chk-2" className="!m-0 !font-normal !text-xl">
                Company Working Days
              </label>{" "}
              &nbsp;
              {salaryCalculationBasis.type === "company" && (
                <>
                  <Select
                    isSearchable={false}
                    defaultValue={selectedOption.value}
                    onChange={(e) => {
                      setSelectedOption(e);
                      setSalaryCalculationBasis({
                        type: "company",
                        value: e.value,
                      });
                    }}
                    options={companyWorkingDaysOptions}
                    styles={setUpSelectStyles}
                  />
                  &nbsp; Days Per Month
                </>
              )}
            </span>
          </div>
        </div>

        <div className="terms">
          <label> Make Payment On</label>
          <div>
            <input
              id="chk-3"
              type="checkbox"
              checked={makePaymentOn.type === "lastWorkingDay"}
              onClick={(e) => {
                if (e.target.checked) {
                  setMakePaymentOn({
                    type: "lastWorkingDay",
                    value: null,
                  });
                }
              }}
              className="checkbox_input"
            />
            <label className="!m-0 !font-normal !text-xl" htmlFor="chk-3">
              Last Working Day Of Every Month
            </label>
          </div>
          <div>
            <input
              id="chk-4"
              type="checkbox"
              checked={makePaymentOn.type === "dayOfFollowingMonth"}
              onClick={(e) => {
                if (e.target.checked) {
                  setMakePaymentOn({
                    type: "dayOfFollowingMonth",
                    value: selectedOption2.value,
                  });
                }
              }}
              className="checkbox_input"
            />
            <span>
              <label htmlFor="chk-4" className="!m-0 !font-normal !text-xl">
                Day
              </label>
              &nbsp;
              <Select
                isSearchable={false}
                defaultValue={selectedOption2.value}
                onChange={(e) => {
                  setSelectedOption2(e);
                  setMakePaymentOn({
                    type: "dayOfFollowingMonth",
                    value: e.value,
                  });
                }}
                options={makePaymentOnOptions}
                styles={setUpSelectStyles}
              />
              &nbsp;{" "}
              <label htmlFor="chk-4" className="!m-0 !font-normal !text-xl">
                Of Following Month
              </label>
            </span>
          </div>
        </div>

        {/* <div className="pay_from">
          <label>Start Making Payment From</label>
          <br />

          <input ref={dateRef} type="date" style={{display:"none"}} />
          <FaCalendar
            onClick={handleCalendarClick}
            size={25}
            className="cursor-pointer"
          />
        </div> */}
        <div className="flex flex-col gap-5  ">
          <div className="text-sml font-medium text-neutral-900 gap-10">
            Payment Date
          </div>
          <div className="flex gap-5">
            <div className="flex items-center">
              <input
                value={paymentDate}
                placeholder="Select Month"
                className="dark:bg-[#C9E9FF] dark:text-black border-2 border-tertiary rounded-lg placeholder-[#777] w-52 px-2 py-1"
              />
            </div>
            <div className="flex items-center relative">
              <IoCalendarClear
                size={25}
                className="cursor-pointer text-tertiary"
              />
              <input
                type="month"
                className="absolute opacity-0 w-12 h-8"
                onChange={(e) => {
                  const [year, month] = e.target.value.split("-");
                  const monthName = getMonthName(parseInt(month) - 1);
                  const m = month + 1;
                  setFirstPayMonth({ month, year });
                  setPaymentMonth({ month, year });
                  setPaymentDate(`${monthName} ${year}`);
                }}
              />
            </div>
          </div>
        </div>

        <div className="mt-20">
          <button
            className="submit_button"
            type="Submit"
            onSubmit={() => handleSubmit(e)}
          >
            Save & Continue
          </button>
          <span className="skip" onClick={handleSkip}>
            Skip for now
          </span>
        </div>
      </form>
    </>
  );
};

export default SchedulePayment;
