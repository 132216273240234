import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import WarningModal from "../WarningModal";

const WarningPopup = ({ showUpdateBtn }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState(null);
  const navigate = useNavigate();

  const handlePopState = (event) => {
    if (showUpdateBtn) {
      setPendingNavigation("/employee/manage/employees-info");
      setIsModalOpen(true);
      window.history.pushState(null, "", window.location.href);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setPendingNavigation(null);
  };

  const confirmNavigation = () => {
    setIsModalOpen(false);
    window.removeEventListener("popstate", handlePopState);
    navigate(pendingNavigation);
  };

  useEffect(() => {
    if (showUpdateBtn) {
      window.history.pushState(null, "", window.location.href);
      window.addEventListener("popstate", handlePopState);
    }
    // else{
    //   return () => {
    //     window.removeEventListener('popstate', handlePopState);
    //   };
    // }
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [showUpdateBtn]);

  return (
    <div>
      <WarningModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onConfirm={confirmNavigation}
      />
    </div>
  );
};

export default WarningPopup;
