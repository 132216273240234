import { customAxios } from "../utils/axiosinstance"

export const CreateTempelate = async(payload) => {
    const {data} = await customAxios.post("/hrms/tempelate/" , payload);
    return data;
}
export const DuplicateTempelate = async(payload) => {
    const {data} = await customAxios.post("/hrms/tempelate/duplicate" , payload);
    return data;
}

export const UpdateTempelate = async(payload) => {
    const {data} = await customAxios.post("/hrms/tempelate/update" , payload);
    return data;
}

export const UpdateTempelateStatus = async(payload) => {
    const {data} = await customAxios.post("/hrms/tempelate/update-status" , payload);
    return data;
}

export const getTempelate = async(payload) => {
    const {data} = await customAxios.get("/hrms/tempelate/" , payload);
    return data;
}

export const fetchPt = async(payload) => {
    const {data} = await customAxios.get(`hrms/tempelate/professional-tax`, {
        params: payload,
    });
    return data;
}

export const fetchPtDetails = async(payload) =>{
    const {data} = await customAxios.get(`hrms/tempelate/professional-tax-details`,{
        params: payload
    });
    return data;
};

export const taxSlabInfo = async(payload) =>{
    const {data} = await customAxios.get(`hrms/tempelate/slab-info`, {
        params: payload
    });
    return data;
};
export const DeleteTemplateParmenently = async(payload) =>{
    const {data} = await customAxios.delete(`hrms/tempelate/permanent-delete?templateId=${payload}`);
    return data;
};
