import React, { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getLeaveBalance,getAllLeaves } from "../../../services/leave";
import { clearEmployees, fetchEmployees } from "../../../reducers/employeesReducer";
import { addIndex, generateSelectData } from "../../../utils/serialiseResponse";
import { adminStyles, adminStylesLight } from "../../../utils/selectStyles";
import PageTitle from "../../../components/PageTitle";
const LeaveBalance = () => {
  const employees = useSelector(({employees}) => employees);
  const dispatch = useDispatch();
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [compensationOff, setCompensationOff] = useState(0);
  const [annualLeave, setAnnualLeave] = useState(2);
  const [sickLeave, setSickLeave] = useState(3);
  const [permission, setPermission] = useState(3);
  const [allLeaves, setAllLeaves] = useState([]);

  const theme =  useSelector((state) => state.theme.theme);

  useEffect(()=>{

    dispatch(fetchEmployees());
    
    getAllLeaves().then((res)=>setAllLeaves(res?.data))


    return ()=> dispatch(clearEmployees());
  },[dispatch]);
  // useEffect(()=>{
  //   getAllLeaves().then((res)=>setAllLeaves(res?.data))
  // },[])
  const emplSelect = useMemo(
    () => generateSelectData(employees.data,"fullName"),
    [employees.data]
  );

  useEffect(()=>{
    getLeaveBalance(selectedEmployee?.value).then((res)=>setAllLeaves(res?.data))
  },[selectedEmployee])
  const tableData = useMemo(() => addIndex(allLeaves),[allLeaves]);

  return (
    <div className="main-content  bg-primaryLight2 dark:bg-primaryDark1 ">
      <div className={"area-creation-content" + `${theme == "light" ? "light_bg" : ""}`}>
        <PageTitle title="Leave Balance" route={'../../'}/>
        </div>
      <div className="mt-8 mr-8">
        
          <div className="row">
            <div>
            <label className="form-label-div" >
              Select Employee
            </label>
            <br />
            <Select
              className=" mb-6"
              options={emplSelect}
              onChange={({ value, label }) => setSelectedEmployee({value, label})}
              styles={theme == "light" ? adminStylesLight : adminStyles}
              placeholder="Select  Employee"
            />
          </div>
          </div>
        
        <div className="flex cardlink-row flex-wrap gap-12 py-4">
        {tableData?.map((el,i) =>{
          return  <div key={i}
          className="leave_balance_card"
          style={{
          backgroundColor: el?.sr % 2 === 0 ? "#21c38ac3" : "#37abf87b",
          border: el?.sr % 2 === 0 ? "#21c38ac3 3px solid" : "#37abf87b 3px solid",
          }}
        >
          <div className="title">
            {el?.balance>0?<span>AVAILABLE</span>:<span>NOT AVAILABLE</span>}
          </div>
          <div className="count">
            <h2 className="count_val">{el?.balance}</h2>
          </div>
          <div
            className="l_b_type"
            style={{
              backgroundColor: el?.sr % 2 === 0 ? "#2ECC71" :"#46b5ff",
              borderTop: el?.sr % 2 === 0 ? "#21C38B  " :"#37abf87b ",
            }}
          >
            {el?.leave_type?.leave_Name }  {el?.leave_Name }
          </div>
        </div>
        })}
        </div>

      </div>
    </div>
  );
};

export default LeaveBalance;
