import React from 'react'
import { Outlet } from "react-router-dom";

import SideBar from '../../../components/SideBar/SideBar.jsx'
import {title,links} from "./employeeInfoNvdata.js"
const EmployeeInfoSideBar = () => {
  return (
    <>
        <SideBar title={title} links={links}></SideBar>
        <Outlet></Outlet>
        
    </>
  )
}

export default EmployeeInfoSideBar