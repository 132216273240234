import { useCallback, useDebugValue, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import { RiEdit2Fill } from "react-icons/ri";
import Table from "../../../../components/Table";
import serialise, {
  generateSelectData,
} from "../../../../utils/serialiseResponse";
import customToast from "../../../../components/CustomToast";
import PageTitle from "../../../../components/PageTitle";
import { fetchTemp } from "../../../../reducers/salaryTempelate";
import { AddSalaryTempelate, GetEmployeeTemp, ReviseSalaryTempelate, calculateTemplateData } from "../../../../services/hrms";
import { fetchPt } from "../../../../services/salaryTempelates";
import { GetEarnings } from "../../../../services/earning";
import { GetAllDeduction } from "../../../../services/deduction";
import { getReimbursements } from "../../../../services/reimbursement";
import { fetchEpfDetails } from "../../../../reducers/epfDetailsReducer";
import { fetchEsiDetails } from "../../../../reducers/esiDetailsReducer";
import { fetchProfessionalTaxDeails } from "../../../../reducers/professionalTaxDeailsReducer";
import { CiCircleMinus } from "react-icons/ci";
import { convertNumberToWords, getPercentageSalary } from "../../../../utils/helper";



const adminStyles = {
  control: (provided, state) => ({
    ...provided,
    padding: "0.2rem 1rem",
    backgroundColor: "#37AAF8",
    color: "#FFFFF",
    cursor:"pointer",
    border: "5px",
    borderRadius: "50px",
    boxShadow: "none",
    minHeight: "3.6rem",
    width: "100%",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    color: "#9E9AD1",

    "&:hover": {
      backgroundColor: "#393b5f",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#9e9ad0",
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    width: "80%",
  }),
  input: (provided, state) => ({
    ...provided,
    color: "#fff",
    border:"none",
    width:"100%"
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#C7C4E9",
    display:"none",
    "&:hover": {
      color: "#9E9AD1",
    },
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#FFFFF",
  }),
  indicatorSeparator: () => null,
};

const seleStyles = {
  control: (provided, state) => ({
    ...provided,
    padding: "0.18rem 1rem",
    backgroundColor: "#2B2C47",
    color: "#C7C4E9",
    border: "5px",
    boxShadow: "none",
    minHeight: "3.6rem",
    width: "100%",
    borderRadius:"0rem"
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    color: "#9E9AD1",

    "&:hover": {
      backgroundColor: "#393b5f",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#9e9ad0",
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    width: "80%",
  }),
  input: (provided, state) => ({
    ...provided,
    color: "#fff",
    border: "none",
    width: "100%",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#C7C4E9",

    "&:hover": {
      color: "#9E9AD1",
    },
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#9E9AD1",
  }),
  indicatorSeparator: () => null,
};


const ReviseSalary = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [temp , setTemp] = useState(null);
  const [userTemp , setUserTemp] = useState(null);
  const [earnData, setEarnData] = useState([]);
  const [deductData, setDeductData] = useState([]);
  const [reimbData , setReimbData] = useState([]);
  const [deductionData  , setDeductionData ] = useState([]);
  const [total , setTotal] = useState(0);
  const [monthlyTotal , setMonthlyTotal] = useState(0);
  const [earningTotal , setEarningTotal] = useState(0);
  const [earningTotalMonthly , setEarningTotalMonthly] = useState(0);
  const [deductionTotal , setDeductionTotal] = useState(0);
  const [deductionTotalMonthly , setDeductionTotalMonthly] = useState(0);
  const [ reimbTotal ,setReimbTotal] = useState(0);
  const [ reimbTotalMonthly , setReimbTotalMonthly] = useState(0);
  const [showUpdateBtn , setShowUpdateBtn] = useState(false);
  const [ctcAmountExceed,setCtcAmountExceed] = useState(false);
  const [ctc , setCtc] = useState(0);
  const [eTotal , setETotal] = useState(0);
  const [totalE , setTotalE ] = useState(0);
  const [professionalTax , setProfessionalTax] = useState(null);
  const [grossSalary , setGrossSalary] = useState(0);
  const [netPay , setNetPay] = useState(0);
  const [netPayMonthly , setNetPayMonthly] = useState(0);
  const [fixedAllowance , setFixedAllowance] = useState(0);
  const [ctcPercetage , setCtcPercentage] = useState(0);
  const [demoCtc , setDemoCtc] = useState(0);
  const [oldCtc , setOldCtc] = useState(0);
  const [ctcInWords , setCtcInwords] = useState("");

  const professionalTaxes = [{
    name : "Professional Tax",
    monthly: null,
    yearly: null,
    status: true,
    calcType: "User Define",
    employeeContributionRate: {label:"User Define"},
    paySlipName: "Professional Tax"
}]

  // const epfData = useSelector((state) => [state.epfInfo.data]);
  // const esiData = useSelector((state) => [state.esiInfo.data]);
  // const earnSelect = useMemo(()=> generateSelectData(earnData, "eName"), [earnData]);
  // const deductSelect = useMemo(()=> generateSelectData(deductData, "paySlipName"), [deductData]);
  // const epfSelect = useMemo(() => generateSelectData(epfData , "docName"), [epfData]);
  // const esiSelect = useMemo(() => generateSelectData(esiData , "docName"), [esiData]);
  // const reimburseSelect = useMemo(()=> generateSelectData(reimbData, "nameOnPaySlip"), [reimbData]);
  // const deductionSelect = [...deductSelect , ...epfSelect , ...esiSelect , professionalTaxes[0]];
  const tempData = useSelector((state) => state?.salaryTemp?.data);
  const tempSelect = useMemo(() => generateSelectData(tempData , "tempName") , [tempData]);
  const [basicAmount , setBasicAmount] = useState(0);
  const ptdetails = useSelector((state) => state?.professionalTaxDeails?.data?.data);
  const [earning , setEarning] = useState(null);
  const [deduction , setDeduction] = useState(null);
  const [reimbursement , setReimbursement] = useState(null);
  const [userTempName , setUserTempName] = useState("");
  const [paidDays , setPaidDays] = useState(31);

  useEffect(() => {
    GetEmployeeTemp({id: location?.state?.state}).then(({data})=> {setUserTemp(data?.salaryTempelate);}).
    catch((err)=> customToast.error("Sorry Something Went Wrong!"))
    // fetchPt({state:location?.state.address.state?.name , userId : location?.state._id}).then(({data}) => {setProfessionalTax(data)})
    // .catch((err)=> customToast.error("Sorry Something Went Wrong!"));
  //   GetEarnings().then(({data})=>setEarnData(data)).
  //  catch((err)=> customToast.error("Sorry Something Went Wrong!"))
  //  GetAllDeduction().then(({data})=>setDeductData(data)).
  //  catch((err)=> customToast.error("Sorry Something Went Wrong!"))
  //  getReimbursements().then((data)=> setReimbData(data)).
  //  catch((err)=> customToast.error("An Error Occured"));
 },[])
  
 

  useEffect(() => {
    if(userTemp){
    setEarning([...userTemp?.earnings]);
    setDeduction([...userTemp?.deductions]);
    setReimbursement(Array.isArray(userTemp?.reimbursements) ? [...userTemp.reimbursements] : []);
    setCtc(userTemp?.ctc);
    setDemoCtc(userTemp?.ctc);
    setOldCtc(userTemp?.ctc)
    setGrossSalary(userTemp?.grossSalary);
    setMonthlyTotal(Number((Number(userTemp?.ctc)/12).toFixed(2)));
    setTotal(userTemp?.ctc);
    setUserTempName(userTemp?.tempName)
  };
  }, [userTemp])

  useEffect(() => {
    dispatch(fetchTemp());
    //  dispatch(fetchEpfDetails());
    // dispatch(fetchEsiDetails());
  },[dispatch])
  
  
  useEffect(() => {
    if(earning && deduction  && monthlyTotal){
    calculateTemplateData({earning , deduction , reimbursement , monthlyTotal , professionalTax , paidDays })
    .then(({data}) => {setEarnData(data?.earning) ; setDeductData(data?.deduction) ; setReimbData(data?.reimbursement) ;setEarningTotal(data?.earningTotal); setNetPay(data?.netPayMonthly); setDeductionTotal(data?.totalDeduction); setReimbTotal(data?.TotalReimb)})}
  } , [earning , deduction , reimbursement , monthlyTotal ])
  console.log(monthlyTotal , "monthlytotal")
  // useEffect(() => {
  //   dispatch(fetchProfessionalTaxDeails({state:location?.state.address.state?.name , userId : location?.state._id}));
  // }, [ctc]);

  // useEffect(() => {
  //   setProfessionalTax(ptdetails);
  // },[ptdetails])
  
  
  const handleSubmit = async(tempId) => {
    try {
     earnData?.map((el) => {
        if(el.earningType.name == "Fixed Allowance" && el.monthly <= 0){
          return customToast.error("Fixed Allowance cannot be zero or negative")
        }
     })

     const payload = {
      tempData : {
        ctc,
        tempName:userTemp.tempName,
        description:userTemp.description,
        earnings:earnData,
        deductions:deductData,
        reimbursements:reimbData,
        earningTotal,
        earningTotalMonthly:earningTotal,
        deductionTotal,
        deductionTotalMonthly:deductionTotal,
        reimbTotal,
        reimbTotalMonthly:reimbTotal,
        status:true,
        monthlyTotal,
        total,
        netPay,
        netPayMonthly:netPay,
      }
    };
     const id = location.state.state;
     console.log(payload , "payload-revise-ssalary")
    const response = await ReviseSalaryTempelate(id , payload);
     if(response.ok){
      customToast.success("Salary Tempelate Added/Updated to Employee Successfully")
    }
    } catch (error) { 
      customToast.error("Something went wrong" , error)
    }  
  }

// useEffect(() => {
//   const updatedEarnings = earning?.map(el => {
//     if (el?.earningType.name === "Basic" || el?.earningType.name === "HRA") {
//       let monthly, yearly;

//       if (el.earningType.name === "Basic") {
//         if (el.calcType.type === 'percentage') {
//           let p = getPercentageSalary(ctc, el.amount);
//           yearly = p;
//         } else {
//           yearly = el.amount * 12;
//         }
//         monthly = (yearly / 12).toFixed(2);
//         setBasicAmount(yearly); 
//       }

//       if (el.earningType.name === "HRA") {
//         if (el.calcType.type === 'percentage') {
//           let p = getPercentageSalary(basicAmount, el.amount);
//           yearly = p;
//         } else {
//           yearly = el.amount * 12;
//         }
//         monthly = (yearly / 12).toFixed(2);
//       }
//       return { ...el, monthly, yearly };
//     } else if (el.earningType.name === "Fixed Allowance") {
//       const yearly = (ctc - totalE).toFixed(2);
//       setFixedAllowance(yearly)
//       const monthly = (yearly / 12).toFixed(2);
//       return { ...el, monthly, yearly };
//     } else {
//       return el;
//     }
//   });

//   setEarnData(updatedEarnings);
//   setShowUpdateBtn(true);
// }, [earning, ctc, grossSalary, basicAmount , totalE]); 

// useEffect(() => {
//   earnData?.forEach(el => {
//     if (el.earningType.name === "Basic") {
//       setBasicAmount(el.yearly);
//     }
//   });
// }, [earnData]); 


const handleCtcChange = (e) => {
  if (e.key === 'Enter') {
    const newCtc = Number(e.target.value);
    const percentageChange = ((newCtc - oldCtc) / oldCtc) * 100;
  
    setCtc(newCtc);
    setDemoCtc(newCtc);
    setMonthlyTotal(Number(Number(newCtc / 12).toFixed(2)));
    setTotal(newCtc);
    setCtcPercentage(percentageChange.toFixed(2));
  }
};

 const handleEarningAmountChange = (id, amount) => {
  const updatedEarnings = earning?.map(el => {
    if (el._id === id) {
      let monthly, yearly;

      if (el.calcType === "percentage") {
        if (el.earningType.name == "HRA") { 
          yearly = getPercentageSalary(basicAmount, amount);;
          monthly = (yearly / 12).toFixed(2);
        } else { 
          yearly = getPercentageSalary(ctc, amount);
          monthly = (yearly / 12).toFixed(2);
        }
      } else { 
        yearly = amount;
        monthly = (yearly / 12).toFixed(2); 
      }
      return {
        ...el,
        amount: amount,
        monthly: monthly,
        yearly: yearly
      };
    }
    return el; 
  });

  setEarning(updatedEarnings)
  setShowUpdateBtn(true); 
};


const handleEarningAmount = (id, amount) => {
  const updatedEarnings = earning?.map((el) => {
    if (el._id === id) {
      if (amount <= el.amount) {
        const monthly = amount;
        const yearly = (12 * monthly).toFixed(2);
        return { ...el, monthly, yearly }; // Create a new object with updated properties
      } else {
        customToast.error("Max Amount Exceed");
      }
    }
    return el;
  });
  setEarning(updatedEarnings)
  setShowUpdateBtn(true);
};


// useEffect(() => {
//     const updatedDeductions = deduction?.map(el => {
//       const updatedEl = { ...el };
//       if (el.docName === "epfInfo") {
//         updatedEl.yearly = getPercentageSalary(basicAmount, el.employeeContributionRate?.value).toFixed(2);
//         updatedEl.monthly = (Number(updatedEl.yearly) / 12).toFixed(2);
//       } else if (el.docName === "esiInfo") {
//         let esiTotal = Number(earningTotal) + reimbTotal;
//         updatedEl.yearly = getPercentageSalary(esiTotal, el.employeeContributionRate?.value).toFixed(2);
//         updatedEl.monthly = (Number(updatedEl.yearly) / 12).toFixed(2);
//       }else{
//         updatedEl.yearly = el.yearly
//         updatedEl.monthly = el.monthly
//       }
//       return updatedEl;
//     });

//     setDeductData(updatedDeductions)
// }, [deduction, basicAmount, reimbTotal , ctc, earningTotal]);


// useEffect(() => {
//   if (professionalTax && userTemp) {
//     const monthlyIncome = ctc;
//     const matchingTaxSlab = professionalTax.tax_slabs.find(tx => {
//       return tx.minimum_income <= monthlyIncome && (tx.maximum_income === null || tx.maximum_income >= monthlyIncome);
//     });
//     let taxRate = matchingTaxSlab ? matchingTaxSlab.tax_rate : 0;

//     const updatedDeductions = deduction?.map(el => {
//       if (el?.name === "Professional Tax") {
//         return { ...el, monthly: taxRate, yearly: taxRate * 12 };
//       }
//       return el;
//     });

//     setDeduction(updatedDeductions);
//     setShowUpdateBtn(true);
//   }
// }, [professionalTax]);


// useEffect(() => {
//   let totalEarning = 0;
//   let totalDeduction = 0;
//   let totalearn = 0

//   earnData?.forEach(el => {
//       if (el.earningType.name !== "Fixed Allowance") {
//           totalEarning += Number(el.yearly);
//       }
//   });

//   earnData?.forEach(el => {
//         totalearn += Number(el.yearly);
//   });

//   deductData?.forEach(el => {
//     const yearly = Number(el.yearly);
//     if (!isNaN(yearly)) {
//         totalDeduction += yearly;
//     }
// });
//   setETotal(totalEarning);
//   setEarningTotal(totalearn);
//   setEarningTotalMonthly((totalearn/12).toFixed(2));
//   setDeductionTotal(totalDeduction);
//   setDeductionTotalMonthly((totalDeduction/12).toFixed(2));
// }, [earnData, deductData]);
  
   const handleDeductionChange = (id, value, type) => {
    const updatedDeductions = deduction.map((el) => {
        if (el._id === id) {
            if (type === "monthly") {
                const monthly = value;
                const yearly = value * 12;
                return { ...el, monthly, yearly };
            } else if (type === "yearly") {
                const yearly = value;
                const monthly = value / 12;
                return { ...el, monthly, yearly };
            }
        }
        return el;
    });
    setDeduction(updatedDeductions)
    setShowUpdateBtn(true);
  };

//    const handleReimbursementAmount = (id, amount) => {
//     const updatedReimbursements = reimbursement?.map((el) => {
//       if (el._id === id) {
//         if (amount <= el.limitPerMonth) {
//           const monthly = amount;
//           const yearly = (12 * monthly).toFixed(2);
//           return { ...el, monthly, yearly };
//         } else {
//           customToast.error("Max Amount Exceed");
//         }
//       }
//       return el;
//     });
  
//     setReimbursement(updatedReimbursements)
//     setShowUpdateBtn(true);
//   };
  

// const calculateTotalReimbursement = useCallback(() => {
//   let totalyearly = 0;
//   let totalmonthly = 0;
//   reimbursement?.forEach((el) => {
//      totalyearly = totalyearly + Number(el.yearly);
//      totalmonthly = totalmonthly + Number(el.monthly);
//  })   
//    setReimbTotal(totalyearly);
//    setReimbTotalMonthly(totalmonthly);
// }, [reimbursement]); 

// useEffect(() => {
//   calculateTotalReimbursement();
// }, [reimbursement]);

// useEffect(() => {
//   let net = ((earningTotal - deductionTotal) + reimbTotal).toFixed(2);
  
//   setNetPay(net);
//   setNetPayMonthly((net/12).toFixed(2));
// }, [reimbTotal, earningTotal, deductionTotal]);

// useEffect(() => {
//   if(userTemp){
//   let t = Number((eTotal).toFixed(2))
//   let m = (t/12).toFixed(2);
//  setTotalE(t);
// //  setMonthlyTotal(m);
// }}, [reimbTotal , eTotal , deductionTotal , ctc]);


const handleKeyDown = (e) => {
  if (e.key === 'Enter') {
    let currentCtc = Number(ctc);
    let updatedCtc = 0;
    let c = ((currentCtc) * (ctcPercetage)) / 100;
    updatedCtc = (currentCtc + c);
    updatedCtc = Number(Number(updatedCtc.toFixed(2)))
    setCtc(updatedCtc);
    setDemoCtc(updatedCtc.toFixed(2));
    setTotal(updatedCtc)
    let newTotal = Number(Number(updatedCtc / 12).toFixed(2));
    setMonthlyTotal(newTotal)
  }
};

useEffect(() => {
  let words = convertNumberToWords(demoCtc);
  setCtcInwords(words);
} , [demoCtc])
console.log(ctc , "ctc")
return (
  <div className="mt-8 mr-8 w-full bg-whiteLight dark:bg-primaryDark pb-28" >
    <div className="dark:bg-primaryDark2">
      <PageTitle title="Salary"  route={'/employee/manage/employees-info'}/>
    </div>
    <div className="flex justify-between mt-16 items-center px-1">
      <div className="dark:text-tertiary font-semibold uppercase">Revise Salary for {`${location?.state?.firstName}${" "}${location?.state?.lastName}`}</div>
    </div>
    <div className="row flex w-full justify-between !p-0">
     <div className="col-sm-12 col-md-4 col-lg-4">
      <div className='text-3xl font-medium dark:text-wheat'>Select Salary Template <span className='text-error'>*</span></div>
       <div>
        <Select
          name="temp"
          id="temp"
          onChange={(e) => {setUserTemp(e); setCtc(e.ctc); setUserTempName(userTemp.tempName); setShowUpdateBtn(true);}}
          styles={seleStyles}
          placeholder="Select Tempelate"
          className="mt-3 !rounded-s-none min-w-90 max-w-90"  
          options={tempSelect}
          value={userTempName}
        />
       </div>
      </div>
    </div>
    <div className="dark:text-successOpaque text-3xl font-semibold tracking-wide mt-10">Select Revision Type</div>
    <div className="dark:text-white mt-10 flex items-center gap-32">
      <span className="text-2xl mr-4">Enter New CTC* Amount</span>
      <div>
      <input className="w-15 py-4 px-4 text-center rounded-sm dark:bg-primary" value={demoCtc} onChange={(e) => setDemoCtc(e.target.value)} onKeyDown={(e) => handleCtcChange(e)}/>
      <span className="text-2xl ml-5">Per Year</span>
      <p className="font-semibold mt-2"> {demoCtc ? ctcInWords : null} </p> 
      </div>
    </div>
    <div className="dark:text-white mt-10 flex items-center gap-x-52">
      <span className="text-2xl mr-4">Enter Percentage</span>
      <div>
      <input className="w-48 py-4 text-center rounded-sm dark:bg-primary" value={ctcPercetage} onChange={(e) => setCtcPercentage(e.target.value)} onKeyDown={(e) => handleKeyDown(e)}/>
      <span className="p-4 dark:bg-tertiary">%</span>
      </div>
    </div>
    <>
  {userTemp && !temp ? ( <>
    <div className="h-auto mt-20  ">
    <p className="text-xl font-medium text-gray-500">Please Input The Value And Press <span className="font-bold text-red-600">Enter</span> to Proceed Further</p>
        <table className="w-full">
          <thead className="uppercase dark:text-secondary dark:bg-primaryDark2 w-full text-center">
            <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">Salary Components</th>
            <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">Calculation Type</th>
            <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">Total Monthly</th>
            <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4">Total Annually</th>
            <th className="border-2 border-collapse border-primaryDark dark:text-secondary font-normal px-6 py-4"></th>
          </thead>
          <tbody>
            <tr className="uppercase text-2x2">
              <td className="px-3 py-8">Earnings</td>
            </tr>
            {earnData?.map((el,idx)=> {
              return (
                <tr key={idx} className="dark:bg-primary text-center">
                  <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6 w-1/5">{el?.eName}</td>
                  <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6 w-1/5 ">
                    {el.calcType.type === "percentage" ?
                    <>
                    <span className="dark:bg-primaryDark1 p-8 px-14">
                      <input
                        type="number"
                        placeholder= ""
                        className="dark:bg-primaryDark1 dark:text-secondary outline-none border-none rounded-lg font-semibold w-16 p-4 "
                        value={el?.amount}
                        onChange={(e) => handleEarningAmountChange(el._id, e.target.value)}
                      /></span> <span className="p-8 dark:bg-tertiary">%</span></>
                    : <span >{`${el?.calcType?.type}`}</span>}
                  </td>
                  <td className="border-2 border-collapse  border-primaryLight3  dark:border-primaryDark dark:text-wheat text-black px-6 py-6 w-1/5">
                    {el?.earningType?.name == "Basic" || el?.earningType?.name == "HRA" ? <span>{el?.monthly}</span> 
                    :<input
                        type="number"
                        placeholder= ""
                        className="dark:bg-primaryDark1 dark:text-secondary outline-none border-none rounded-lg font-semibold w-16 w-full flex h-16 text-center"
                        value={el.monthly}
                        max={el.monthly}
                        onChange={(e) => handleEarningAmount(el._id, e.target.value)}
                      />}</td>
                  <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6 w-1/5">{el?.yearly}</td>
                  <td className="border-2 border-collapse  border-primaryLight3  dark:border-primaryDark dark:text-wheat text-black px-6 py-6 w-1/12 ">
                  </td>
                </tr>
              )}
            )}
          </tbody>
        </table>
      </div>
  
  
      <div className="text-2x2 uppercase ml-1 my-8">Deductions</div>
<div className="h-auto mt-8">
  <table className="w-full">
    <tbody>
      {deductData?.map((el, idx) => (
        <tr key={idx} className="dark:bg-primary text-center">
          {el?.subtitle !== "Employee Contribution" && el.includeEmployersContribution ? (
            <>
              <td className="border-2 border-collapse border-primaryDark text-wheat px-6 py-6 w-1/5">
                <span>{el?.name}</span>
               <br/>
                {el?.subtitle && (
                  <span className="text-base text-tertiary font-bold">
                    ({el?.subtitle})
                  </span>
                )}
              </td>
              <td className="border-2 border-collapse border-primaryLight3 dark:border-primaryDark dark:text-wheat text-black px-6 py-6 w-1/5">
                <span>
                  {el.employeeContributionRate?.label
                    ? `${el.employeeContributionRate?.label}`
                    : `${el.deductionFrequency}`}
                </span>
              </td>
              {el.employeeContributionRate?.label === "User Define" ? (
                <>
                  <td className="border-2 border-collapse border-primaryLight3 dark:border-primaryDark dark:text-wheat text-black px-16 py-6 w-1/5">
                    <span>{"--"}</span>
                  </td>
                  <td className="border-2 border-collapse border-primaryLight3 dark:border-primaryDark dark:text-wheat text-black px-16 py-6 w-1/5">
                    <span>{"--"}</span>
                  </td>
                </>
              ) : (
                <>
                  <td className="border-2 border-collapse border-primaryDark text-wheat px-16 py-6 w-1/5">
                    {el?.docName && el?.subtitle !== "Employee Contribution" ? (
                      <span>{el?.monthly}</span>
                    ) : (
                      <input
                        type="number"
                        placeholder=""
                        className="dark:bg-primaryDark1 dark:text-secondary outline-none border-none rounded-lg font-semibold w-full h-16 text-center"
                        value={el.monthly}
                        onChange={(e) => handleDeductionChange(el._id, e.target.value, "monthly")}
                      />
                    )}
                  </td>
                  <td className="border-2 border-collapse border-primaryDark text-wheat px-16 py-6 w-1/5">
                    {el?.docName ? (
                      <span>{el?.yearly}</span>
                    ) : (
                      <input
                        type="number"
                        placeholder=""
                        className="dark:bg-primary dark:text-secondary outline-none border-none rounded-lg font-semibold w-full h-16 text-center"
                        value={el.yearly}
                      />
                    )}
                  </td>
                </>
              )}
              <td className="border-2 border-collapse border-primaryLight3 dark:border-primaryDark dark:text-wheat text-black px-6 py-6 w-1/12"></td>
            </>
          ) : (
            <>
              {el.docName !== 'esiInfo' && el?.docName !== 'epfInfo' && (
                <>
                  <td className="border-2 border-collapse border-primaryDark text-wheat px-6 py-6 w-1/5">
                    <span>{el?.name}</span>
                    {el?.subtitle && (
                      <span className="text-base text-tertiary font-bold">
                        ({el?.subtitle})
                      </span>
                    )}
                  </td>
                  <td className="border-2 border-collapse border-primaryLight3 dark:border-primaryDark dark:text-wheat text-black px-6 py-6 w-1/5">
                    <span>
                      {el.employeeContributionRate?.label
                        ? `${el.employeeContributionRate?.label}`
                        : `${el.deductionFrequency}`}
                    </span>
                  </td>
                  {el.employeeContributionRate?.label === "User Define" ? (
                    <>
                      <td className="border-2 border-collapse border-primaryLight3 dark:border-primaryDark dark:text-wheat text-black px-16 py-6 w-1/5">
                        <span>{"--"}</span>
                      </td>
                      <td className="border-2 border-collapse border-primaryLight3 dark:border-primaryDark dark:text-wheat text-black px-16 py-6 w-1/5">
                        <span>{"--"}</span>
                      </td>
                    </>
                  ) : (
                    <>
                      <td className="border-2 border-collapse border-primaryDark text-wheat px-16 py-6 w-1/5">
                        {el?.docName && el?.subtitle !== "Employee Contribution" ? (
                          <span>{el?.monthly}</span>
                        ) : (
                          <input
                            type="number"
                            placeholder=""
                            className="dark:bg-primaryDark1 dark:text-secondary outline-none border-none rounded-lg font-semibold w-full h-16 text-center"
                            value={el.monthly}
                            onChange={(e) => handleDeductionChange(el._id, e.target.value, "monthly")}
                          />
                        )}
                      </td>
                      <td className="border-2 border-collapse border-primaryDark text-wheat px-16 py-6 w-1/5">
                        {el?.docName ? (
                          <span>{el?.yearly}</span>
                        ) : (
                          <input
                            type="number"
                            placeholder=""
                            className="dark:bg-primary dark:text-secondary outline-none border-none rounded-lg font-semibold w-full h-16 text-center"
                            value={el.yearly}
                          />
                        )}
                      </td>
                    </>
                  )}
                  <td className="border-2 border-collapse border-primaryLight3 dark:border-primaryDark dark:text-wheat text-black px-6 py-6 w-1/12"></td>
                </>
              )}
            </>
          )}
        </tr>
      ))}
    </tbody>
  </table>
</div>

    
      <div  className="text-3xl uppercase ml-1 my-8">reimbursement</div>
      <div className="h-auto mt-8 ">
        <table className="w-full">
          <tbody>
            {reimbursement?.map((el,idx)=> {
              return (
                <tr key={idx} className="dark:bg-primary text-center">
                  <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6 w-1/5">{el?.nameOnPaySlip}</td>
                  <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6 w-1/5">
                    <span >{"Fixed Amount"}</span>
                  </td>
                  <td className="border-2 border-collapse  border-primaryLight3  dark:border-primaryDark dark:text-wheat text-black px-6 py-6 w-1/5">
                   <input
                        type="number"
                        placeholder= ""
                        className="dark:bg-primaryDark1 dark:text-secondary outline-none border-none rounded-lg font-semibold w-16 w-full flex h-16 text-center"
                        value={el.monthly}
                        max={el.monthly}
                        onChange={(e) => handleReimbursementAmount(el._id, e.target.value)}
                   /></td>
                  <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6 w-1/5">{el?.yearly}</td>
                  <td className="border-2 border-collapse  border-primaryLight3 dark:border-primaryDark dark:text-wheat text-black px-6 py-6 w-1/12">
                  </td>
                </tr>
              )}
            )}
          </tbody>
        </table>
      </div> 
       
      <div className="max-h-[400px] mt-8 overflow-y-scroll ">
        <table className="w-full">
          <tbody>
                <tr className="dark:bg-primary text-center">
                  <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6 w-1/5"></td>
                  <td className="border-2 border-collapse border-primarrDark border-primaryDark text-wheat px-6 py-6 w-1/5">
                    <span className="text-tertiary">{"Total Gross Salary"}</span>
                  </td>
                  <td className={`${ctcAmountExceed ? 'text-tertiary' : 'text-tertiary'} border-2 border-collapse border-primaryLight3 dark:border-primaryDark px-6 py-6 w-1/5`}>{monthlyTotal}</td>
                  <td className={`${ctcAmountExceed ? 'text-tertiary' : 'text-tertiary'} border-2 border-collapse border-primaryLight3 dark:border-primaryDark px-6 py-6 w-1/5`}>{total}</td>
                  <td className="border-2 border-collapse border-primarrDark border-primaryDark px-6 py-6 w-1/12">
                  </td>
                </tr>
          </tbody>
        </table>
      </div>
      {/* {showUpdateBtn ?  <button className="button_blue_gradient mt-20" onClick={() => handleSubmit(userTemp._id)}>Update</button> : null} */}
      <button className="button_blue_gradient mt-20" onClick={() => handleSubmit(userTemp._id)}>Update</button> 
      </>)
    :
  (null)} </>
  </div>
);
};

export default ReviseSalary;
