import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { showSidenav, hideSidenav } from "../../reducers/sidenavReducer";

const SideBar = ({ title, links, response }) => {
  const theme = useSelector((state) => state.theme.theme);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(hideSidenav());

    return () => dispatch(showSidenav());
  }, [dispatch]);

  return (
    <nav className={"sidebar-inner "}>
      <div
        className={
          "sidebar-inner-heading " + `${theme == "light" ? "light_bg" : ""}`
        }
      >
        <h2 className={"web-app__heading " + `${theme == 'light' ? 'light_bg_logo' : ""}`}>{title}</h2>
      </div>

      <ul className={"side-nav " + `${theme == "light" ? "light_bg" : ""}`}>
        {links.map((link, idx) => (
          <li
            className={`side-nav__item ${
              pathname.includes(link.route) && "inner-nav__active"
            }`}
            key={idx}
          >
            <Link
              to={link.route}
              state={{ index: idx }}
              className="side-nav__link"
            >
              <div
                className={
                  "d-flex align-items-center w-100 " +
                  `${theme == "light" ? "light_text" : ""}`
                }
              >
                {link.name}{" "}
                {response?.length && response[idx]?.data?.length ? (
                  <div className="content-length__shower">
                    <span>
                      {response[idx]?.data?.length
                        ? response[idx]?.data?.length
                        : 0}
                    </span>
                  </div>
                ) : null}
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default SideBar;
