import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMemo, useState, useEffect } from 'react'
import Select from 'react-select';
import { generateSelectData } from '../../../../utils/serialiseResponse'
import { adminStyles, adminStylesLight } from '../../../../utils/selectStyles';
import { periodOptions } from '../../../../utils/helper';

const ReportTDS = () => {
  const theme = useSelector((state) => state.theme.theme);

  return (
    <div>
      <div className='flex flex-col mb-12'>
        <div>
          
            Select Period
          
        </div>
        <div>
          <Select
            className="w-80"
            styles={theme == "light" ? adminStylesLight : adminStyles}
            options={periodOptions}
          // onChange={(el) => setMonth(el?.value)}
          />
        </div>
      </div>
      <div  className='w-full'>
        <table className='w-full  table-fixed border-separate '>
          <tr>
            <th className='py-2 border-b-2 bg-primaryLight2 dark:bg-primaryDark1 border-blue-400'>Month</th>
            <th className='py-2 border-b-2 bg-primaryLight2 dark:bg-primaryDark1 border-blue-400'>View</th>
            <th className='py-2 border-b-2 bg-primaryLight2 dark:bg-primaryDark1 border-blue-400'>Download</th>
          </tr>
        </table>
      </div>
      <div  className='w-full mt-24'>
        <table className='w-full table-fixed  border-separate'>
          <tr>
            <th className='py-2 border-b-2 bg-primaryLight2 dark:bg-primaryDark1 border-blue-400'>Yearly TDS</th>
            <th className='py-2 border-b-2 bg-primaryLight2 dark:bg-primaryDark1 border-blue-400'>View</th>
            <th className='py-2 border-b-2 bg-primaryLight2 dark:bg-primaryDark1 border-blue-400'>Download</th>
          </tr>
        </table>
      </div>
    </div>
  )
}

export default ReportTDS