import React,{useEffect, useState} from 'react'
import PageTitle from '../../../components/PageTitle';
// import {BlobProvider, PDFViewer } from '@react-pdf/renderer';
import PDFDocument from './PdfDocument';
import { useLocation } from 'react-router-dom';
import { fileToBase64 } from '../../../utils/helper';
import customToast from '../../../components/CustomToast';
import { uploadPayslip } from '../../../services/payRun';
import { Link } from 'react-router-dom';
import { getCompanyProfile } from '../../../services/hrms';

const ViewPayslip = () => {
    const location = useLocation();
    console.log("location -------->", location?.state);
    const [blobURL, setblobURL] = useState(null);
    const [blobData, setBlobData] = useState([]);
    const [blobObj, setBlobObj] = useState({});
    const [basicInfo , setBasicInfo] = useState(null);

    // useEffect(() => {
    //   alert()
    // },[])
    // let changeHandler = (urls) => {
    //   const validFiles = Array.from(urls);

    //   Promise.all(validFiles.map)(fileToBase64)
    //   .then((base64Images) => {
    //     setBlobData(base64Images);
    //   })
    //   .catch((error) => {
    //     console.log("error ---------->", error);
    //     customToast.error("error occurred");
    //   });
    // };

    // let changeHandler = (urls) => {
    //   const validFiles = Array.from(urls);
      
    //   Promise.all(validFiles.map(fileToBase64))
    //     .then((base64Images) => {
    //       setBlobData(base64Images);
    //     })
    //     .catch((error) => {
    //       console.log("error ------>", error);
    //       customToast.error("Error adding images");
    //     });

    //     let uploadData = async() => {
    //       let payload = {
    //         id: location?.state?.employeeId?._id,
    //         payslip : blobData
    //       };

    //       await uploadPayslip(payload).then((res) => customToast.success("Payslip uploaded successfully !"))
    //       .catch((error)=> {
    //         console.log("error --->",error)
    //         customToast.error('Something went wrong !')}
    //       )
    //     };

    //     blobData?.length > 0 ? 
    //     uploadData()
    //     :
    //     null;

    // };

    let changeHandler = (urls) => {
      const validFiles = Array.from(urls);
      
      Promise.all(validFiles.map(fileToBase64))
        .then((base64Images) => {
          setBlobData(base64Images);
    
          let uploadData = async () => {
            let payload = {
              id: location?.state?.employeeId?._id,
              payslip: base64Images // Using base64Images here instead of blobData
            };
    
            try {
              await uploadPayslip(payload);
              customToast.success("Payslip uploaded successfully !");
            } catch (error) {
              console.log("error --->", error);
              customToast.error("Something went wrong !");
            }
          };
    
          uploadData(); // Call uploadData here, after setting blobData
        })
        .catch((error) => {
          console.log("error ------>", error);
          customToast.error("Error adding images");
        });
    };
    
    // if(blobURL){
    //   changeHandler([blobObj]);
    // };

    const handleSubmit = async(e) => {
      e.preventDefault();

      let payload = {
        id: location?.state?.employeeId?._id,
        payslip : blobData
      };
      
      await uploadPayslip(payload).then((res) => customToast.success("Payslip uploaded successfully !"))
        .catch((error)=> {
          console.log("error --->",error)
          customToast.error('Something went wrong !')}
        )
    };
    
    useEffect(() => {
      getCompanyProfile().then((details) => setBasicInfo(details?.data[0])).catch((error) => console.log(error , "error"))
    } , [])

    console.log(basicInfo , "basicinfo")

  return (
    <div className='main-content pl-10 bg-primaryLight2 dark:bg-primaryDark1'>
      <div className='creation-content'>
        <PageTitle title="View PaySlip" route={-1}/>
        </div>  

        {/* <BlobProvider document={<PDFDocument paySlip={location?.state} companyBasicInfo={basicInfo}/>}>
          {({url,blob}) => (
            url != null
            ?
            <div>
            <a href={url} target="_blank" onClick={() => {
              setBlobObj(blob)
              setblobURL(url)}}>View PDF in new tab</a>
              <button className='button-blue-gradient m-8' onClick={() => changeHandler([blob])}>
                Upload PaySlip
              </button>
            </div>
            :
            null
          )}
        </BlobProvider> */}

        {/* <div className='w-full p-8'>
        <PDFViewer width="70%" height="70%" style={{width:"100%", height:"70rem", marginBottom:"3rem"}}>
            <PDFDocument paySlip={location?.state}/>
        </PDFViewer>
        </div> */}
    </div>
  )
}

export default ViewPayslip;