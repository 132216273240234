import React from "react";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import Select from "react-select";
import { adminStyles, adminStylesLight } from "../../../../utils/selectStyles";
import "../employeereports.css";
import { GetPaymentSchedule } from "../../../../services/paymentschedule";
import { GetEmployeePayslipsOnTheBasisOfYear } from "../../../../services/employee";
import { returnMonthFromIndex } from "../../../../utils/helper";
const PaySlip = ({ emp }) => {
  const theme = useSelector((state) => state.theme.theme);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState("");
  const [paySlipDetails, setPaySlipDetails] = useState([]);
  useEffect(() => {
    GetPaymentSchedule().then((data) => {
      const { year } = data.paymentStartsFrom;
      const currentYear = new Date().getFullYear();
      const years = [];
      for (let i = currentYear; i >= Number(year); i--) {
        years.push({ value: `${i}-${i + 1}`, label: `${i}-${i + 1}` });
      }
      setOptions(years);
    });
  }, []);

  useEffect(() => {
    if (selected.value && emp.value) {
      GetEmployeePayslipsOnTheBasisOfYear({
        emplId: emp?.value,
        year: selected?.value,
      })
        .then((data) => setPaySlipDetails(data))
        .catch((err) => {
          customToast.error("Employee payslips no found");
        });
    }
  }, [emp]);
  return (
    <div>
      <div className="flex flex-col mb-12">
        <div>Select Period</div>

        <Select
          className="w-80"
          styles={theme == "light" ? adminStylesLight : adminStyles}
          options={options}
          onChange={(el) => {
            setSelected(el);
            GetEmployeePayslipsOnTheBasisOfYear({
              emplId: emp?.value,
              year: el?.value,
            })
              .then((data) => setPaySlipDetails(data))
              .catch((err) => {
                customToast.error("Employee payslips no found");
              });
          }}
          // onChange={(el) => setMonth(el?.value)}
        />
      </div>
      <div className="w-full">
        <table className="w-full table-fixed  border-separate">
          <thead>
            <tr>
              <th className="py-2 border-b-2 bg-primaryLight2 dark:bg-primaryDark1 border-blue-400">
                Month
              </th>
              <th className="py-2 border-b-2 bg-primaryLight2 dark:bg-primaryDark1 border-blue-400">
                View
              </th>
              <th className="py-2 border-b-2 bg-primaryLight2 dark:bg-primaryDark1 border-blue-400">
                Download
              </th>
            </tr>
          </thead>
          <tbody>
            {paySlipDetails?.map((det) => (
              <tr key={det._id} className="text-white text-center">
                <td className="border border-[#3c3c72] p-4">
                  {returnMonthFromIndex(det.month)}
                </td>
                <td className="border border-[#3c3c72] p-4">
                  <span className="text-[#00bf9a] flex justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5M12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5s5 2.24 5 5s-2.24 5-5 5m0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3s3-1.34 3-3s-1.34-3-3-3"
                      />
                    </svg>
                  </span>
                </td>
                <td className="border border-[#3c3c72] p-4">
                  <span className="text-[#37aaf8] flex justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 5v8.5m0 0l3-3m-3 3l-3-3M5 15v2a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-2"
                      />
                    </svg>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PaySlip;
