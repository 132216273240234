import { useEffect, useState, useMemo, useRef } from "react";
import PageTitle from "../../../../components/PageTitle";
import { adminStyles, adminStylesLight } from "../../../../utils/selectStyles";
import Select from "react-select";
import Table from '../../../../components/Table';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { clearEmployees, fetchEmployees } from '../../../../reducers/employeesReducer';
import { generateSelectData } from '../../../../utils/serialiseResponse';
import { HiPencil } from "react-icons/hi";
import { fetchAllAdvSalaries } from "../../../../reducers/advSalaryReducer";
import Dropdown from '../../../../components/DropDown/dropdown'
import { SlReload } from "react-icons/sl";
import { deleteAdvSalary } from '../../../../services/advanceSalary'
import customToast from "../../../../components/CustomToast";

const SalaryAdvance = () => {

  const navigate = useNavigate();
  const theme = useSelector((state) => state.theme.theme);
  const employees = useSelector(({ employees }) => employees);
  const salaryAdvances = useSelector((state) => state.advSalary.data)
  const dispatch = useDispatch();
  const [selectEmpl, setSelectEmpl] = useState(null);
  const [filteredData, setfilteredData] = useState([])

  const filter = async (employeeId) => {
    try {
      if (employeeId) {
        const filteredData = salaryAdvances.filter(item => {
          return item.employee._id === employeeId._id;
        });
        setfilteredData(filteredData);
      }
      else {
        setfilteredData(salaryAdvances)
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch(fetchEmployees({}));
    dispatch(fetchAllAdvSalaries());

    return () => {
      dispatch(clearEmployees());
    };
  }, [dispatch]);

  useEffect(() => {
    if (salaryAdvances) {
      setfilteredData(salaryAdvances);
    }
  }, [salaryAdvances]);

  const emplSelect = useMemo(
    () => generateSelectData(employees.data, "fullName"),
    [employees.data]
  );

  const columns = useMemo(
    () => [
      {
        Header: "SR NO",
        accessor: (row, index) => index + 1,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "EMPLOYEE NAME",
        accessor: (row) => row?.employee?.firstName + " " + row?.employee?.lastName,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "ADVANCE AMOUNT",
        accessor: "advanceAmount",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "AMOUNT PAID",
        accessor: "amountPaid",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "BALANCE AMOUNT",
        accessor: "balanceAmount",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "STATUS",
        accessor: "status",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "ACTION",
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <>
              <Dropdown
                icon={
                  <HiPencil className='text-[#3893d7] cursor-pointer text-4xl' />
                }
                options={[
                  {
                    text: 'View',
                    onClick: () => {
                      navigate(`/loan/manage-advance-salary/salary-advance/installments/${row.original._id}`)
                    }
                  },
                  {
                    text: 'Delete',
                    onClick: () => {
                      deleteAdvSalary({ advanceSalaryId: row.original._id })
                        .then((res) => {
                          dispatch(fetchAllAdvSalaries());
                          return customToast.success("Advance salary deleted successfully")
                        })
                        .catch((err) => {
                          return customToast.error("Something went wrong !")
                        });
                    }
                  },
                ]}
              />
            </>
          );
        },

      },
    ],
    [filteredData]
  );

  return (
    <main className={"main-content " + `${theme == "light" ? "light_bg_main" : ""} w-full`} >
      <div className={"area-creation-content " + `${theme == "light" ? "light_bg" : ""}`}>
        <PageTitle title="Salary Advance" route={"../.."} />
      

      <section className="admin-content">
        <div className="row ">
          <div className="col-sm-12 col-md-4 col-lg-4">
            <label htmlFor="emp_name">
              SELECT EMPLOYEE
            </label>
            <br />
            <div className="flex gap-6 items-center">
              <Select
                id="emp_name"
                className="w-max"
                options={emplSelect}
                value={selectEmpl}
                onChange={(el) =>{
                  setSelectEmpl(el);
                  filter(el);
                } 
              }
                styles={theme == "light" ? adminStylesLight : adminStyles}
                placeholder="Select Employee"
              />
              <SlReload
                className="cursor-pointer ml-6 text-3xl text-[#36abf9] mt-3"
                onClick={() => {
                  setSelectEmpl(null);
                  filter();
                }}
              />
            </div>
          </div>
        </div>

        <div>
          <section className={"cardlink-row pb-6"}>
            <label className="show-entries">
              Show Entries ({filteredData.length})
            </label>

            <div className="filter-table">
              <Table columns={columns} data={[...filteredData].reverse()} />
            </div>
          </section>
        </div>
      </section>
      </div>
    </main>
  );
};

export default SalaryAdvance;