import React from "react";
import { useSelector } from "react-redux";
import { formatDate } from "../../utils/helper";

const EventReminderCard = ({ title, no_msg, type, arr = [] }) => {
  const theme = useSelector((state) => state.theme.theme);
  return (
    <div className={"eve_rem coming-soon-text" + `${theme == "light" ? "eve_rem_light" : ""}`}>
      <div className="title "><span>{title}</span></div>
      <div className="eve_info flex flex-col ">
        {arr.length == 0 ? (
          <span className="no_eve_msg">{no_msg}</span>
        ) : (
          arr.map((item) => {
            return (
              <div key={item._id} className="w-full  p-4 border-2 text-green-300   border-green-400 rounded-md my-4 flex items-center justify-between cursor-pointer">
                <span className="font-medium">{`${item.firstName} ${item.middleName} ${item.lastName}`}</span>
                <span className="font-bold">
                  {/* {type=="reminder" && ""} */}
                  {type == "birthday" && formatDate(item.birthDate).slice(0, 7)}
                  {type == "marriageAnniversary" &&
                    formatDate(item.marriageDate).slice(0, 7)}
                </span>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default EventReminderCard;
