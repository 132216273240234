import React, { useEffect, useRef, useState, useMemo } from "react";
import Select from "react-select";
import { adminStyles, adminStylesLight } from "../../../../utils/selectStyles";
import Table from "../../../../components/Table";
import { getPayrun } from "../../../../services/payRun";
import { GetActiveEarnings } from "../../../../services/earning";
import { GetAllActiveDeduction } from "../../../../services/deduction";
import { getActiveReimbursements } from "../../../../services/reimbursement";
import { GetPaymentSchedule } from "../../../../services/paymentschedule";
import CascadingMenu from "../../../../components/DropDown/CascadingMenu";
import { useSelector } from "react-redux";
import { returnMonthFromIndex } from "../../../../utils/helper";

import icon from "../Payroll-Summary/RupeeIcon.png";
import Logo from "../../../../assets/img/logo.png";
import PageTitle from "../../../../components/PageTitle";

const PayrollSummary = () => {
  const theme = useSelector((state) => state.theme.theme);
  const [payrolls, setPayrolls] = useState([]);
  const [durationMenu, setDurationMenu] = useState([]);
  const [activeEarnings, setActiveEarnings] = useState([]);
  const [activeDeductions, setActiveDeductions] = useState([]);
  const [activeReimbursements, setActiveReimbursements] = useState([]);

  const columns = useMemo(() => {
    let temp = 14;
    let initial = [
      {
        Header: "Employee ID",
        accessor: "employeeId",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          // console.log(props.row.original);
          return (
            <span>
              {props?.row?.original?.employeeDetails?.detail?.employeeId || "_"}
            </span>
          );
        },
      },
      {
        Header: "Employee Name",
        accessor: "employeeName",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return (
            <span>
              {`${props?.row?.original?.employeeDetails?.detail?.firstName} ${props?.row?.original?.employeeDetails?.detail?.lastName}` ||
                "_"}
            </span>
          );
        },
      },
      {
        Header: "Designation",
        accessor: "designation",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return (
            <span>
              {props?.row?.original?.employeeDetails?.detail?.designation
                ?.name || "_"}
            </span>
          );
        },
      },
      {
        Header: "Department",
        accessor: "department",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return (
            <span>
              {props?.row?.original?.employeeDetails?.detail?.department
                ?.name || "_"}
            </span>
          );
        },
      },
      {
        Header: "Headquarters",
        accessor: "headquarter",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return (
            <span>
              {props?.row?.original?.employeeDetails?.detail?.headquarter
                ?.name || "_"}
            </span>
          );
        },
      },
      {
        Header: "Date of Joining",
        accessor: "dateOfJoining",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          const joiningDate =
            props?.row?.original?.employeeDetails?.detail?.joiningDate;
          return (
            <span>
              {joiningDate
                ? joiningDate.split("T")[0].split("-").reverse().join("-")
                : "_"}
            </span>
          );
        },
      },
      {
        Header: "Bank Account Number",
        accessor: "bankAccountNumber",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return (
            <span>
              {props?.row?.original?.employeeDetails?.detail?.paymentDetails
                ?.bankAccountNumber || "_"}
            </span>
          );
        },
      },
      {
        Header: "IFSC Code",
        accessor: "ifscCode",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return (
            <span>
              {props?.row?.original?.employeeDetails?.detail?.paymentDetails
                ?.ifsc || "_"}
            </span>
          );
        },
      },
      {
        Header: "EPF Number",
        accessor: "epfNumber",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return (
            <span>
              {props?.row?.original?.employeeDetails?.detail?.epfNumber || "_"}
            </span>
          );
        },
      },
      {
        Header: "ESI Number",
        accessor: "esiNumber",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return (
            <span>
              {props?.row?.original?.employeeDetails?.detail?.esiNumber || "_"}
            </span>
          );
        },
      },
      {
        Header: "UAN",
        accessor: "uanNumber",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return (
            <span>
              {props?.row?.original?.employeeDetails?.detail?.uanNumber || "_"}
            </span>
          );
        },
      },
      {
        Header: "PAN",
        accessor: "panNumber",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return (
            <span>
              {props?.row?.original?.employeeDetails?.detail?.panDetails
                ?.panNumber || "_"}
            </span>
          );
        },
      },
      {
        Header: "Attendance",
        accessor: "attendance",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return (
            <span>
              {props?.row?.original?.employeeDetails?.payableDays || "_"}
            </span>
          );
        },
      },
      {
        Header: "LOP",
        accessor: "lop",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return (
            <span>{props?.row?.original?.employeeDetails?.lopDays ?? 0}</span>
          );
        },
      },
      {
        Header: "Total Earning",
        accessor: "totalEarning",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return (
            <span>
              {props?.row?.original?.employeeDetails?.salaryTempelate
                ?.earningTotalMonthly || "_"}
            </span>
          );
        },
      },
      {
        Header: "Loan",
        accessor: "loan",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return (
            <span>
              {props?.row?.original?.employeeDetails?.loanDetails?.length || 0}
            </span>
          );
        },
      },
      {
        Header: "Advance Salary",
        accessor: "advanceSalary",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return (
            <span>
              {props?.row?.original?.employeeDetails?.advSalaryDetails
                ?.length || 0}
            </span>
          );
        },
      },
      {
        Header: "EPF Amount",
        accessor: "epfAmount",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          const epf =
            props?.row?.original?.employeeDetails?.salaryTempelate?.deductions?.find(
              (deduction) =>
                deduction.label === "epfInfo" &&
                deduction?.subtitle?.toString()?.toLowerCase() ===
                  "employee contribution",
            );
          return <span>{epf?.monthly || "_"}</span>;
        },
      },
      {
        Header: "ESI Amount",
        accessor: "esiAmount",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          const epf =
            props?.row?.original?.employeeDetails?.salaryTempelate?.deductions?.find(
              (deduction) =>
                deduction.label === "esiInfo" &&
                deduction?.subtitle?.toString()?.toLowerCase() ===
                  "employee contribution",
            );
          return <span>{epf?.monthly || "_"}</span>;
        },
      },
      {
        Header: "Professional Tax",
        accessor: "professionalTax",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          const epf =
            props?.row?.original?.employeeDetails?.salaryTempelate?.deductions?.find(
              (deduction) => deduction.label === "Professional Tax",
            );
          return <span>{epf?.monthly || "_"}</span>;
        },
      },
      {
        Header: "LOP Amount",
        accessor: "lopAmount",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          const { salaryTempelate, lopDays } =
            props?.row?.original?.employeeDetails;
          return (
            <span>{lopDays ? lopDays * salaryTempelate.perDayGross : 0}</span>
          );
        },
      },
      {
        Header: "Total Deduction",
        accessor: "totalDeduction",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          const { allTotalDeduction } =
            props?.row?.original?.employeeDetails?.salaryTempelate;
          return <span>{allTotalDeduction || "_"}</span>;
        },
      },
      {
        Header: "Net Salary",
        accessor: "netSalary",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return (
            <span>
              {props?.row?.original?.employeeDetails?.salaryTempelate
                ?.netPayMonthly || "_"}
            </span>
          );
        },
      },
    ];

    activeEarnings?.forEach((element) => {
      initial.splice(temp, 0, {
        Header: element.eName,
        accessor: element.eName,
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          const basic =
            props?.row?.original?.employeeDetails?.salaryTempelate?.earnings?.find(
              (earning) =>
                earning.label.toString().toLowerCase() ===
                element.eName.toLowerCase(),
            );
          return <span>{basic?.monthly || "_"}</span>;
        },
      });
      temp += 1;
    });
    temp = temp + 7;

    activeDeductions?.forEach((element) => {
      initial.splice(temp, 0, {
        Header: element.nameOnPaySlip,
        accessor: element.nameOnPaySlip,
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          const epf =
            props?.row?.original?.employeeDetails?.salaryTempelate?.deductions?.find(
              (deduction) => deduction.nameOnPaySlip === element?.nameOnPaySlip,
            );
          return <span>{epf?.monthly || "_"}</span>;
        },
      });
      temp += 1;
    });

    activeReimbursements?.forEach((element) => {
      initial.splice(temp, 0, {
        Header: element.nameOnPaySlip,
        accessor: element.nameOnPaySlip,
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          const epf =
            props?.row?.original?.employeeDetails?.salaryTempelate?.reimbursements?.find(
              (deduction) =>
                deduction?.nameOnPaySlip === element?.nameOnPaySlip,
            );
          return <span>{epf?.monthly || "_"}</span>;
        },
      });
      temp += 1;
    });

    return initial;
  }, [activeEarnings, activeDeductions, activeReimbursements]);

  useEffect(() => {
    GetActiveEarnings().then((data) => setActiveEarnings(data.data));
    GetAllActiveDeduction().then((data) => setActiveDeductions(data.data));
    getActiveReimbursements().then((data) => setActiveReimbursements(data));
    getPayrun().then((data) => {
      setPayrolls(data.data);
    });
    GetPaymentSchedule().then((data) => {
      const { month, year } = data.paymentStartsFrom;
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth();

      const menu1 = {
        key: `${currentYear}`,
        type: "group",
        label: `${currentYear} - ${currentYear + 1}`,
        children: [],
      };

      for (let index = 0; index <= currentMonth - 3; index++) {
        if (index < 9) {
          const indexedMonth = returnMonthFromIndex(index + 3).slice(0, 3);
          menu1.children.push({
            key: `${currentYear}-${index}`,
            label: `${indexedMonth} ${currentYear}`,
          });
        } else {
          const indexedMonth = returnMonthFromIndex(index - 9).slice(0, 3);
          menu1.children.push({
            key: `${currentYear}-${index}`,
            label: `${indexedMonth} ${currentYear + 1}`,
          });
        }
      }
      setDurationMenu((pre) => [...pre, menu1]);

      for (let index = currentYear - 1; index >= Number(year); index--) {
        const sessionMonts = [
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
          "Jan",
          "Feb",
          "Mar",
        ];
        const menu = {
          key: `${index}-${index + 1}`,
          label: `${index}-${index + 1}`,
          children: [],
        };
        sessionMonts.forEach((elm, idx) => {
          if (idx <= 8) {
            menu.children.push({
              key: `${index}-${idx}`,
              label: `${elm} ${index}`,
            });
          } else
            menu.children.push({
              key: `${index}-${idx}`,
              label: `${elm} ${index + 1}`,
            });
        });

        setDurationMenu((pre) => {
          const temp = { ...menu };
          menu.children = [];
          return [...pre, temp];
        });
      }

      console.table({ month, year, currentYear, currentMonth });
    });
  }, []);

  return (
    <main
      className={
        "main-content pr-7" + `${theme == "light" ? " light_bg_main" : ""}`
      }
      // style={{ width: "100%" }}
    >
      <div className="area-creation-content ">
        <PageTitle title="Processed Payrun" route="../.." />
        <section className="admin-content">
          <div className="row ">
            <div className="col-sm-12 col-md-4 col-lg-4">
              {/* <label htmlFor="emp_name">
              SELECT Duration
            </label>
            <br /> */}
              <div className="flex gap-6 items-center">
                <CascadingMenu items={durationMenu} title={"SELECT DURATION"} />
              </div>
            </div>
          </div>

          <div>
            <section className={"cardlink-row pb-6"}>
              <label className="show-entries">Show Entries (0)</label>

              <div className="filter-table">
                <Table columns={columns} data={payrolls} />
              </div>
            </section>
          </div>
        </section>
      </div>
    </main>
  );
};

export default PayrollSummary;
