import { customAxios, customAxios2 } from "../utils/axiosinstance";

export const GetAutoFillData = async (id) => {
  const { data } = await customAxios2.get(`/v1/hrms/user/auto-fill/${id}`);
  return data.data;
};

export const createEmployee = async (data) => {
  await customAxios.post("hrms/employee", data);
};

export const editEmployee = async (payload) => {
  const { data } = await customAxios.post(
    "hrms/employee/edit-employee",
    payload,
  );
  return data;
};

export const GetAllEmployees = async (payload) => {
  const { data } = await customAxios.get(`hrms/employee/all`, {
    params: payload,
  });
  return data.data;
};

export const GetEmployees = async () => {
  const { data } = await customAxios.get(`hrms/employee/`);
  return data.data;
};

export const GetSingleEmployee = async (id) => {
  const { data } = await customAxios.get(`hrms/employee/${id}`);

  return data;
};

export const GetEmployeeTemp = async (payload) => {
  const { id } = payload;
  const { data } = await customAxios.get(
    `hrms/employee/get-employee-salary-temp/${id}`,
  );

  return data;
};

export const GetEmployeesWithTemp = async (payload) => {
  const { data } = await customAxios.get(
    `hrms/employee/get-employees-with-temp`,
    {
      params: payload,
    },
  );

  return data;
};

export const UploadDocument = async (payload) => {
  const { data } = await customAxios.post(
    `hrms/employee/upload-document`,
    payload,
  );
  return data.data;
};

export const GetEmployeesWithAniversary = async () => {
  const { data } = await customAxios.get(
    `hrms/employee/get-employees-with-marriage-aniversary`,
  );
  return data.data;
};

export const GetEmployeesWithBirthDay = async () => {
  const { data } = await customAxios.get(
    `hrms/employee/get-employees-with-birthday`,
  );
  return data.data;
};

export const calculateTemplateData = async (payload) => {
  const { data } = await customAxios.put(
    `hrms/employee/template-calculation`,
    payload,
  );
  return data;
};

export const AddSalaryTempelate = async (id, payload) => {
  const { data } = await customAxios.put(
    `hrms/employee/add-temp/${id}`,
    payload,
  );
  return data.data;
};

export const ReviseSalaryTempelate = async (id, payload) => {
  const { data } = await customAxios.put(
    `hrms/employee/revise-temp/${id}`,
    payload,
  );
  return data.data;
};

export const ConfigureHrmsSetUpStatus = async (payload) => {
  const { data } = await customAxios.post(`hrms/set-up-status`, payload);
  return data.data;
};

export const getHrmsSetUpStatus = async () => {
  const { data } = await customAxios.get(`hrms/set-up-status`);
  console.log(data , "data")
  return data.data;
};

export const updateHrmsSetUpStatus = async (status) => {
  await customAxios.put(`hrms/set-up-status`, status);
};

export const registerCompany = async (payload) => {
  const { data } = await customAxios.post(`hrms/organization`, payload);
  return data.data;
};

export const addTaxInfo = async (payload) => {
  const { data } = await customAxios.post(
    `hrms/organization/tax-info`,
    payload,
  );
  return data.data;
};

export const updateTaxSettings = async (payload) => {
  const { data } = await customAxios.put(
    `hrms/organization/tax-settings`,
    payload,
  );
  return data.data;
};

export const getTaxInfo = async (payload) => {
  const { data } = await customAxios.get(`hrms/organization/tax-info`, payload);
  return data.data;
};

export const addPaymentScheduleInfo = async (payload) => {
  const { data } = await customAxios.post(
    `hrms/organization/pay-schedule`,
    payload,
  );
  return data.data;
};

export const addStatutoryComponents = async (payload) => {
  const { data } = await customAxios.post(
    `hrms/organization/statutory-components`,
    payload,
  );
  return data.data;
};

export const getEsiDetails = async () => {
  const { data } = await customAxios.get(
    "hrms/organization/statutory-components/esi-details",
  );
  return data.data;
};

export const addEsi = async (payload) => {
  const { data } = await customAxios.post(
    "hrms/organization/statutory-components/add-esi",
    payload,
  );
  return data.data;
};

export const updateEsi = async (payload) => {
  const { data } = await customAxios.put(
    "hrms/organization/statutory-components/update-esi",
    payload,
  );
  return data.data;
};

export const getEpfDetails = async () => {
  const { data } = await customAxios.get(
    "hrms/organization/statutory-components/epf-details",
  );
  return data.data;
};

export const addEpf = async (payload) => {
  const { data } = await customAxios.post(
    "hrms/organization/statutory-components/add-epf",
    payload,
  );
  return data.data;
};

export const updateEpf = async (payload) => {
  const { data } = await customAxios.put(
    "hrms/organization/statutory-components/update-epf",
    payload,
  );
  return data.data;
};

export const getCompanySetUpStatus = async () => {
  const { data } = await customAxios.get(
    "hrms/organization/company-setup-status",
  );
  return data.data;
};

export const addProfessionalTaxDetails = async (payload) => {
  const { data } = await customAxios.post(
    "hrms/organization/statutory-components/add-professional-tax",
    payload,
  );
  return data.data;
};

export const getProfessionalTaxDetails = async () => {
  const { data } = await customAxios.get(
    "hrms/organization/statutory-components/professional-tax-details",
  );
  return data.data;
};

export const addLwf = async (payload) => {
  const { data } = await customAxios.post(
    "hrms/organization/statutory-components/add-lwf",
    payload,
  );
  return data.data;
};

export const getLwf = async () => {
  const { data } = await customAxios.get(
    "hrms/organization/statutory-components/get-lwf",
  );
  return data.data;
};

export const deleteLwf = async (id) => {
  const { data } = await customAxios.delete(
    `hrms/organization/statutory-components/lwf/${id}`,
  );
  return data.data;
};

export const updateLwf = async (id, payload) => {
  const { data } = await customAxios.put(
    `hrms/organization/statutory-components/lwf/${id}`,
    payload,
  );
  return data.data;
};

export const updateCompanyProfile = async (payload) => {
  const { data } = await customAxios.put(
    `hrms/organization/basic-info/update`,
    payload,
  );
  return data.data;
};

export const getCompanyProfile = async () => {
  const { data } = await customAxios.get(`hrms/organization/basic-info`);
  return data;
};

export const addCompanyWorkingAddress = async (payload) => {
  const { data } = await customAxios.post(
    `hrms/organization/add-working-address`,
    payload,
  );
  return data;
};

export const getCompanyWorkingAddress = async () => {
  const { data } = await customAxios.get(
    `hrms/organization/get-working-address`,
  );
  return data;
};

export const updateCompanyWorkingAddress = async (payload) => {
  const { data } = await customAxios.put(
    `hrms/organization/add-working-address`,
    payload,
  );
  return data;
};

// export const updateEmployeeSalaryConfigs = async (payload)=>{
//   console.log(payload , "payload")
//   const { data } = await customAxios.post(
//     `hrms/employee/updateEmployeeSalaryConfigs`,
//     payload,
//   );
//   return data.data;
// }
