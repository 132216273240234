import React,{useState} from 'react';
import PageTitle from '../../../../components/PageTitle';
import PDFDocument from '../../../PayRun/CreatePayrun/PdfDocument';
import { BlobProvider, PDFViewer} from '@react-pdf/renderer';
import { useSelector } from 'react-redux';

const index = () => {
  const [defaultPDF, setDefaultPDF] = useState("std");

  
  const theme = useSelector((state) => state.theme.theme);

  return (

    <div
      className={"main-content " + `${theme == "light" ? "light_bg_main" : ""}`}
      style={{ width: "100%" }}
    >
      <div
        className={
          "creation-content " + `${theme == "light" ? "light_bg" : ""}`
        }
      >
        <PageTitle title="SELECT PAYSLIP TEMPLATE" route={'../..'} />

        <section className="admin-content py-8">
          <div className='flex justify-center justify-around'>
            <div className='flex flex-col gap-y-8 items-center justify-center'>
              <div className='text-[#37AAF8] font-semibold text-3xl'>
                Standard
              </div>
              <BlobProvider document={<PDFDocument/>}>
                {({ url }) => (
                  url== null ? 
                  <div>
                    Loading Doc....</div>
                  :
                  <PDFViewer style={{width:"100%", height:"40rem", marginBottom:"3rem"}} showToolbar={false}>
                <PDFDocument paySlip={[]}/>
              </PDFViewer>
                )}
              </BlobProvider>
              <div className='flex flex-row gap-x-4 justify-center'
                onClick={() => {
                  defaultPDF === "std" ?
                    setDefaultPDF("basic")
                    :
                    setDefaultPDF("std");
                }}>
                {defaultPDF === 'std' ?
                  <button className='button-green-gradient'>
                    Default Payslip
                  </button>
                  :
                  <button className='button-blue-gradient'>
                    Set as Default
                  </button>
                }
              </div>
            </div>

            <div className='flex flex-col gap-y-8 items-center justify-center'>
              <div className='text-[#37AAF8] mt-5 font-semibold text-3xl'>
                Basic
              </div>
              <BlobProvider document={<PDFDocument />}>
                {({ url }) => (
                  url== null ? 
                  <div>Loading Doc....</div>
                  :
                  <PDFViewer style={{width:"100%", height:"40rem", marginBottom:"3rem"}} showToolbar={false}>
                <PDFDocument paySlip={[]}/>
              </PDFViewer>
                )}
              </BlobProvider>
              <div className='flex flex-row gap-x-4 justify-center'
                onClick={() => {
                  defaultPDF === "basic" ?
                    setDefaultPDF("std")
                    :
                    setDefaultPDF("basic");
                }}>
                {defaultPDF === 'basic' ?
                  <button className='button-green-gradient'>
                    Default Payslip
                  </button>
                  :
                  <button className='button-blue-gradient'>
                    Set as Default
                  </button>
                }
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>

    // <div className='main-content pl-10 bg-primaryLight2 dark:bg-primaryDark1'>
    //     <div className='creation-content'>
    // <PageTitle title="SELECT PAYSLIP TEMPLATE" route={'../..'}/>

    //         <section className='creation-conetent_form border-t-4 border-solid border-b-4 border-primaryLight3 dark:border-primary p-8'>

    //   <div className='flex flex-row justify-between mx-12'>
    //   <div className='w-fit flex flex-col gap-y-8'>
    //   <div className='text-[#37AAF8] font-semibold text-3xl'>
    //     Standard
    //   </div>
    //   <BlobProvider document={<PDFDocument/>}>
    //     {({ url }) => (
    //       url== null ? 
    //       <div>
    //         Loading Doc....</div>
    //       :
    //       <PDFViewer style={{width:"100%", height:"40rem", marginBottom:"3rem"}} showToolbar={false}>
    //     <PDFDocument paySlip={[]}/>
    //   </PDFViewer>
    //     )}
    //   </BlobProvider>

    // <div className='flex flex-row gap-x-4 justify-center'
    //   onClick={() => {
    //     defaultPDF === "std" ?
    //     setDefaultPDF("basic")
    //     :
    //     setDefaultPDF("std");
    //   }}>
    //     {defaultPDF === 'std' ? 
    //   <button className='approved-button w-fit px-4'>
    //     Default Payslip
    //   </button>
    //   :
    //   <button className='button-blue-gradient'>
    //     Set as Default
    //   </button>
    //     }
    //   </div>

    //   </div>

    //   <div className=' w-fit flex flex-col gap-y-8'>
    //     <div className='justify-center'>
    //   <div className='text-[#37AAF8] font-semibold text-3xl'>
    //     Basic
    //   </div>
    //     </div>
    //   {/* <BlobProvider document={<PDFDocument/>}>
    //   { ({url}) =>(
    //      url ? 
    //      <div>
    //       Loading Doc...
    //      </div> 
    //      ?
    //     <PDFViewer style={{width:"100%", height:"40rem", marginBottom:"3rem"}} showToolbar={false}>
    //     <PDFDocument paySlip={[]}/>
    //   </PDFViewer>
    //   )
    //   }
    //   </BlobProvider> */}
    //   <BlobProvider document={<PDFDocument/>}>
    //     {({ url }) => (
    //       url== null ? 
    //       <div>Loading Doc....</div>
    //       :
    //       <PDFViewer style={{width:"100%", height:"40rem", marginBottom:"3rem"}} showToolbar={false}>
    //     <PDFDocument paySlip={[]}/>
    //   </PDFViewer>
    //     )}
    //   </BlobProvider>

    //   <div className='flex flex-row gap-x-4 justify-center'
    //   onClick={() => {
    //     defaultPDF === "basic" ?
    //     setDefaultPDF("std")
    //     :
    //     setDefaultPDF("basic");
    //   }}>
    //     {defaultPDF === 'basic' ? 
    //   <button className='approved-button w-fit px-4'>
    //     Default Payslip
    //   </button>
    //   :
    //   <button className='button-blue-gradient'>
    //     Set as Default
    //   </button>
    //     }
    //   </div>

    //   </div>
    //   </div>

    //         </section>
    //     </div>
    // </div>
  )
}

export default index