import { useLocation } from "react-router-dom";
import PageTitle from "../../../components/PageTitle/index";
import { formatDate } from "../../../utils/helper";
import UploadedDocCard from "../../../components/UploadedDocCard/UploadedDocCard";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import InfoBox from "../../../components/InfoBox";
import Modal from "../../../components/Modal";

const singleEmployeeDetails = () => {
  const [selectedImage, setSelectedImage] = useState();
  const theme = useSelector((state) => state.theme.theme);

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const location = useLocation();
  console.log(location.state);

  return (
    <div className="main-content admin-content bg-primaryLight2 dark:bg-primaryDark1">
      <div className="area-creation-content">
        <PageTitle title="Employees Info" hierarchy={true} />
      </div>
      <div
        className={
          "info_header " + `${theme == "light" ? "light_bg_logo" : ""}`
        }
      >
        <h2>Basic Info</h2>
      </div>
      <section
        className={
          "user-detail-container user-detail-container-yellowDark-border " +
          `${theme == "light" ? "light_bg" : ""}`
        }
      >
        <InfoBox
          title="First Name"
          des={location.state?.firstName ? location.state?.firstName : "--"}
        />
        <InfoBox
          title="Middle Name"
          des={location.state?.middleName ? location.state?.middleName : "--"}
        />
        <InfoBox
          title="Last Name"
          des={location.state?.lastName ? location.state?.lastName : "--"}
        />
        <InfoBox
          title="Employee Id"
          des={location.state?.employeeId ? location.state?.employeeId : "--"}
        />
        <InfoBox
          title="Date of Joining"
          des={
            location.state?.joiningDate
              ? formatDate(location.state?.joiningDate)
              : "--"
          }
        />
        <InfoBox
          title="Date of Confirmation"
          des={
            location.state?.dateOfConfirmation
              ? formatDate(location.state?.dateOfConfirmation)
              : "--"
          }
        />
        <InfoBox
          title="Designation"
          des={
            location.state?.designation?.name
              ? location.state?.designation?.name
              : "--"
          }
        />
        <InfoBox
          title="Gender"
          des={
            location.state?.gender?.label ? location.state?.gender?.label : "--"
          }
        />
        <InfoBox
          title="Email Address"
          des={location.state?.email ? location.state?.email : "--"}
        />
        <InfoBox
          title="Department"
          des={
            location.state?.department?.name
              ? location.state?.department?.name
              : "--"
          }
        />
        <InfoBox
          title="Headquarters"
          des={
            location.state?.headquarter?.name
              ? location.state?.headquarter?.name
              : "--"
          }
        />
        <InfoBox
          title="Phone Number"
          des={location.state?.phoneNumber ? location.state?.phoneNumber : "--"}
        />
      </section>

      <div
        className={
          "info_header " + `${theme == "light" ? "light_bg_logo" : ""}`
        }
      >
        <h2>Location</h2>
      </div>

      <section
        className={
          "user-detail-container user-detail-container-green-border " +
          `${theme == "light" ? "light_bg" : ""}`
        }
      >
        <InfoBox title="Division" des={location?.state?.division?.name} />
        <InfoBox
          title="Address"
          des={
            location?.state?.address?.street1 +
            location?.state?.address?.street2
          }
        />
        <br />
      </section>

      <div
        className={
          "info_header " + `${theme == "light" ? "light_bg_logo" : ""}`
        }
      >
        <h2>Personal Info</h2>
      </div>

      <section
        className={
          "user-detail-container user-detail-container-yellowDark-border " +
          `${theme == "light" ? "light_bg" : ""}`
        }
      >
        <InfoBox
          title="Date of Birth"
          des={
            location.state?.birthDate
              ? formatDate(location.state?.birthDate)
              : "--"
          }
        />
        <InfoBox
          title="PAN Number"
          des={
            location.state?.panDetails
              ? location.state?.panDetails.panNumber
              : "--"
          }
        />
        <InfoBox
          title="Aadhar Number"
          des={
            location.state?.aadharDetails
              ? location.state?.aadharDetails.aadharNumber
              : "--"
          }
        />
        <InfoBox
          title="Marital Status"
          des={
            location.state?.maritalStatus?.label
              ? location.state?.maritalStatus?.label
              : "--"
          }
        />
        {/* <InfoBox title="Blood Group" des={location.state?.bloodGroup ? location.state?.bloodGroup : "--"} /> */}
      </section>

      <div
        className={
          "info_header " + `${theme == "light" ? "light_bg_logo" : ""}`
        }
      >
        <h2>Statuatory Info</h2>
      </div>

      <section
        className={
          "user-detail-container user-detail-container-green-border " +
          `${theme == "light" ? "light_bg" : ""}`
        }
      >
        <InfoBox
          title="EPF Number"
          des={location.state?.epfNumber ? location.state?.epfNumber : "--"}
        />
        <InfoBox
          title="ESI Number"
          des={location.state?.esiNumber ? location.state?.esiNumber : "--"}
        />
        <InfoBox
          title="UAN Number"
          des={location.state?.uanNumber ? location.state?.uanNumber : "--"}
        />
      </section>

      <div
        className={
          "info_header " + `${theme == "light" ? "light_bg_logo" : ""}`
        }
      >
        <h2>Payment Details</h2>
      </div>

      <section
        className={
          "grid grid-cols-12 user-detail-container user-detail-container-yellowDark-border " +
          `${theme == "light" ? "light_bg" : ""}`
        }
      >
        <section className="col-span-3">
          <InfoBox
            title="Payment Type"
            des={
              location.state?.paymentDetails?.paymentType
                ? location.state?.paymentDetails?.paymentType?.toUpperCase()
                : "--"
            }
          />
        </section>
        {location.state?.paymentDetails?.paymentType?.toLowerCase() !==
          "cheque" && (
          <section className="col-span-8">
            <section className="flex justify-between">
              <InfoBox
                title="Bank Name"
                des={
                  location.state?.paymentDetails?.bankName
                    ? location.state?.paymentDetails?.bankName
                    : "--"
                }
              />
              <InfoBox
                title="Bank Account Number"
                des={
                  location.state?.paymentDetails?.bankAccountNumber
                    ? location.state?.paymentDetails?.bankAccountNumber
                    : "--"
                }
              />
              <InfoBox
                title="IFSC code"
                des={
                  location.state?.paymentDetails?.ifsc
                    ? location.state?.paymentDetails?.ifsc
                    : "--"
                }
              />
            </section>
          </section>
        )}
        <section className="col-span-1"></section>
      </section>

      <div
        className={
          "info_header " + `${theme == "light" ? "light_bg_logo" : ""}`
        }
      >
        <h2>Uploaded Documents</h2>
      </div>

      <section
        className={
          "user-detail-container user-detail-container-green-border " +
          `${theme == "light" ? "light_bg" : ""}`
        }
      >
        <div
          className={
            "user-detail-box " + `${theme == "light" ? "light_bg_logo" : ""}`
          }
        >
          <h4 className="!text-primaryDark dark:!text-primaryLight2">
            Pan Card{" "}
          </h4>
          {location?.state?.panDetails?.panCardImage ? (
            <UploadedDocCard
              url={location?.state?.panDetails?.panCardImage}
              name="pan card"
              onClick={() => {
                setSelectedImage(location?.state?.panDetails?.panCardImage);
                openModal();
              }}
            />
          ) : (
            <p>Image Not Uploaded !!</p>
          )}
        </div>

        <div
          className={
            "user-detail-box " + `${theme == "light" ? "light_bg_logo" : ""}`
          }
        >
          <h4 className="!text-primaryDark dark:!text-primaryLight2">
            Aadhar Card
          </h4>
          <div>
            <div>
              {location?.state?.aadharDetails?.aadharCardImage1 ||
              location?.state?.aadharDetails?.aadharCardImage2 ? (
                <div className="flex gap-4">
                  <UploadedDocCard
                    url={location?.state?.aadharDetails?.aadharCardImage1}
                    name="aadhar card"
                    onClick={() => {
                      setSelectedImage(
                        location?.state?.aadharDetails?.aadharCardImage,
                      );
                      openModal();
                    }}
                  />
                  {location?.state?.aadharDetails?.aadharCardImage2 ? (
                    <UploadedDocCard
                      url={location?.state?.aadharDetails?.aadharCardImage2}
                      name="aadhar card"
                      onClick={() => {
                        setSelectedImage(
                          location?.state?.aadharDetails?.aadharCardImage2,
                        );
                        openModal();
                      }}
                    />
                  ) : null}
                </div>
              ) : (
                <p>Image Not Uploaded !!</p>
              )}
            </div>
          </div>
        </div>

        <div
          className={
            "user-detail-box " + `${theme == "light" ? "light_bg_logo" : ""}`
          }
        >
          <h4 className="!text-primaryDark dark:!text-primaryLight2">
            Voter Card
          </h4>
          <div>
            {location?.state?.voterCardDetails?.voterIdCardImage1 ||
            location?.state?.voterCardDetails?.voterIdCardImage2 ? (
              <div className="flex gap-4">
                <UploadedDocCard
                  url={location?.state?.voterCardDetails?.voterIdCardImage1}
                  name="voter card"
                  onClick={() => {
                    setSelectedImage(
                      location?.state?.voterCardDetails?.voterIdCardImage1,
                    );
                    openModal();
                  }}
                />
                {location?.state?.voterCardDetails?.voterIdCardImage2 ? (
                  <UploadedDocCard
                    url={location?.state?.voterCardDetails?.voterIdCardImage2}
                    name="voter card"
                    onClick={() => {
                      setSelectedImage(
                        location?.state?.voterCardDetails?.voterIdCardImage2,
                      );
                      openModal();
                    }}
                  />
                ) : null}
              </div>
            ) : (
              <p>Image Not Uploaded !!</p>
            )}
          </div>
        </div>

        <div
          className={
            "user-detail-box " + `${theme == "light" ? "light_bg_logo" : ""}`
          }
        >
          <h4 className="!text-primaryDark dark:!text-primaryLight2">
            Passport
          </h4>
          <div>
            {location?.state?.passportDetails?.passportImage1 ||
            location?.state?.passportDetails?.passportImage2 ? (
              <div className="flex gap-4">
                <UploadedDocCard
                  url={location?.state?.passportDetails?.passportImage1}
                  name="passport"
                  onClick={() => {
                    setSelectedImage(
                      location?.state?.passportDetails?.passportImage1,
                    );
                    openModal();
                  }}
                />
                {location?.state?.passportDetails?.passportImage2 ? (
                  <UploadedDocCard
                    url={location?.state?.passportDetails?.passportImage2}
                    name="passport"
                    onClick={() => {
                      setSelectedImage(
                        location?.state?.passportDetails?.passportImage2,
                      );
                      openModal();
                    }}
                  />
                ) : null}
              </div>
            ) : (
              <p>Image Not Uploaded !!</p>
            )}
          </div>
        </div>

        <div
          className={
            "user-detail-box " + `${theme == "light" ? "light_bg_logo" : ""}`
          }
        >
          <h4 className="!text-primaryDark dark:!text-primaryLight2">
            Driving Licence
          </h4>
          {location?.state?.drivingLicenceDetails?.drivingLicenceImage1 ||
          location?.state?.drivingLicenceDetails?.drivingLicenceImage2 ? (
            <div className="flex gap-4">
              <UploadedDocCard
                url={
                  location?.state?.drivingLicenceDetails?.drivingLicenceImage1
                }
                name="Driving licence card"
                onClick={() => {
                  setSelectedImage(
                    location?.state?.drivingLicenceDetails
                      ?.drivingLicenceImage1,
                  );
                  openModal();
                }}
              />
              {location?.state?.drivingLicenceDetails?.drivingLicenceImage2 ? (
                <UploadedDocCard
                  url={
                    location?.state?.drivingLicenceDetails?.drivingLicenceImage2
                  }
                  name="Driving licence card"
                  onClick={() => {
                    setSelectedImage(
                      location?.state?.drivingLicenceDetails
                        ?.drivingLicenceImage2,
                    );
                    openModal();
                  }}
                />
              ) : null}
            </div>
          ) : (
            <p>Image Not Uploaded !!</p>
          )}
        </div>
      </section>
      <Modal
        showModal={modalIsOpen}
        closeModal={() => closeModal()}
        title="Image preview"
        width="w-90"
        content={
          <div className="w-[460px] flex justify-center items-center h-[280px]">
            <img src={selectedImage} alt="" />
          </div>
        }
      />
    </div>
  );
};

export default singleEmployeeDetails;
