import React, { useEffect, useMemo, useState } from 'react'
import PageTitle from '../../../components/PageTitle'
import { useDispatch, useSelector } from 'react-redux';
import { IoIosAddCircleOutline } from "react-icons/io";
import { RxCross1 } from "react-icons/rx";
import Accordion from '../../../components/Accordion';
import Modal from '../../../components/Modal';
import Select from "react-select";
import { HiPencil } from 'react-icons/hi';
import Table from '../../../components/Table';
import { adminStyles, adminStylesLight } from "../../../utils/selectStyles";
import { createProcess, assignActivityToProcess, deleteProcess } from '../../../services/onboarding'
import customToast from '../../../components/CustomToast/index'
import { set, useForm } from 'react-hook-form';
import { fetchAllOnboardDivisions, fetchAllOnboardProcesses } from '../../../reducers/onboardingDivisionsReducer'
import { generateSelectData, generateSelectDataForOnboarding } from '../../../utils/serialiseResponse';

const activity = () => {

  const dispatch = useDispatch();
  const divisions = useSelector((state) => state.onboardDivisions.data)
  const processes = useSelector((state) => state.onboardDivisions.processesData);
  const theme = useSelector((state) => state.theme.theme);
  const [addActModal, setaddActModal] = useState(false)
  const { register, handleSubmit, reset } = useForm();
  const [tasks, settasks] = useState([])
  const [formData, setformData] = useState({ processId: null, divisionId: null });

  const divisionsSelect = useMemo(
    () => generateSelectDataForOnboarding(divisions),
    [divisions]
  );

  const filter = async (divisionId) => {
    try {
      if (divisionId) {
        const filteredData = divisions.find(item => {
          return item._id === divisionId;
        });
        settasks(filteredData.tasks);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "TASK LIST",
        accessor: (row) => row.taskName,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "DUE DATE",
        accessor: "dueDays",
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <>
              {row.original.dueDays > 1 ? row.original.dueDays + " Day(s) " : row.original.dueDays + " Day "} after joining date
            </>
          );
        },
      },
      {
        Header: "ALLOTED TO",
        accessor: (row) => row.allotedTo,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "ACTION",
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <>
              <span
                className={`react-table-view-link`}>
                <HiPencil className={`text-[#36ABF9] cursor-pointer text-3xl `} />
              </span>
            </>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(fetchAllOnboardDivisions())
    dispatch(fetchAllOnboardProcesses())
  }, [])

  const createProcessHandler = async (data) => {
    if (!data.processName) return customToast.error("Process name is required")
    try {
      const res = await createProcess(data)
      if (res) {
        reset();
        setaddActModal(false)
        dispatch(fetchAllOnboardProcesses())
        return customToast.success("Process has been added successfully")
      }
    }
    catch (error) {
      return customToast.error("Something went wrong !")
    }
  }

  const AssignActivityToProcessHandler = async (e) => {
    e.preventDefault();

    if (!formData.divisionId) return customToast.error("Please select division")

    try {
      const res = await assignActivityToProcess(formData);
      if (res) {
        dispatch(fetchAllOnboardProcesses())
        return customToast.success("Activity has been assigned to the process successfully")
      }
    }
    catch (error) {
      return customToast.error("Something went wrong !")
    }
  }

  return (
    <main className={"main-content " + `${theme == "light" ? "light_bg_main" : ""} w-full`} >
      <div className={"area-creation-content " + `${theme == "light" ? "light_bg" : ""}`}>
        <PageTitle title="Onboarding Process" route={'../..'} />
      </div>

      <section>
        <button className='flex gap-2 text-3xl text-[#3893d7]' onClick={() => {
          setaddActModal(true);
        }}>
          <IoIosAddCircleOutline />
          <span>ADD NEW ONBOARDING PROCESS</span>
        </button>

        <div className='mt-12'>
          <span className='text-[#00bf9a] text-2xl font-semibold'>Onboarding process(s)</span>
        </div>

        {
          processes?.length === 0 &&
          <div className='admin-content mt-8'>
            <label>No onboarding process found</label>
          </div>
        }

        <div className='flex flex-col gap-4 mt-6 pr-8'>
          {processes?.map((item) => (
            <Accordion
              key={item._id}
              defaultState={false}
              title={item.processName}
              titleStyle={"accordion-title"}
              contentStyle={"area-creation-head"}
              content={
                <div className='py-8 area-creation-content'>
                  <form
                    onSubmit={AssignActivityToProcessHandler}
                    className='w-full flex justify-between'
                  >
                    <div className='w-full flex justify-between items-center'>
                      <div className='flex gap-12 items-end cardlink-row'>
                        <div className="col-sm-12 admin-content col-md-4 col-lg-4">
                          <label htmlFor="div_name">
                            Select Division
                          </label>
                          <br />
                          {item.division?.divisionName ? (
                            <Select
                              id="div_name"
                              options={divisionsSelect}
                              styles={theme == "light" ? adminStylesLight : adminStyles}
                              placeholder="Select Division"
                              value={{ label: item.division?.divisionName, value: item.division?.divisionId }}
                              className="w-max"
                              onChange={(e) => {
                                setformData({ processId: item._id, divisionId: e.value })
                                filter(e.value)
                              }
                              }
                            />
                          ) : (
                            <Select
                              id="div_name"
                              options={divisionsSelect}
                              styles={theme == "light" ? adminStylesLight : adminStyles}
                              placeholder="Select Division"
                              className="w-max"
                              onChange={(e) => {
                                setformData({ processId: item._id, divisionId: e.value })
                                filter(e.value)
                              }
                              }
                            />
                          )}
                        </div>

                        <button
                          className="button-blue-gradient"
                          type='submit'
                        >
                          Save
                        </button>
                      </div>

                      <div>
                        <button
                          className='button-red-gradient'
                          onClick={() => {
                            deleteProcess({ processId: item._id })
                              .then((res) => {
                                customToast.success("Process has been deleted successfully")
                                dispatch(fetchAllOnboardProcesses())
                              })
                              .catch((error) => {
                                customToast.error("Something went wrong !")
                              }
                              )
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    </div>


                  </form>

                  <section className={"cardlink-row"}>
                    <div className="filter-table ">
                      {item?.division?.tasks && item?.division?.tasks.length > 0 ? (
                        <Table columns={columns} data={item?.division?.tasks} />
                      ) : (
                        tasks?.length > 0 && <Table columns={columns} data={tasks} />
                      )}
                    </div>
                  </section>
                </div>
              }
            />
          ))}
        </div>
      </section>

      <Modal
        showModal={addActModal}
        closeModal={() => setaddActModal(false)}
        title="Add new onboarding process"
        width="w-[400px]"
        content={
          <form className='admin-content' onSubmit={handleSubmit(createProcessHandler)}>
            <div className="flex flex-col gap-10">
              <div>
                <label htmlFor="act_name">
                  Process Name
                  <span className="asterisk-imp">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Activity Name"
                  className='bg-[#2B2C47] px-6 mt-3 w-full outline-none'
                  {...register("processName")}
                />
              </div>

              <div>
                <label htmlFor="des">
                  Description
                </label>
                <input
                  type="text"
                  placeholder="Enter Description"
                  className='bg-[#2B2C47] px-6 mt-3 w-full outline-none'
                  {...register("description")}
                />

              </div>

              <div className="flex items-center gap-10">
                <button
                  type="submit"
                  className="px-16 py-2 text-white bg-tertiary rounded-xl cursor-pointer"
                  value="Add"
                >Add</button>
                <span
                  className="text-cyan cursor-pointer"
                  onClick={() => {
                    setaddActModal(false)
                  }}
                >
                  Cancel
                </span>
              </div>
            </div>
          </form>
        }
      />
    </main >
  )
}

export default activity