import Cookies from "js-cookie";

export const storeUser = (user, expires) => {
  Cookies.set("loggedUser", JSON.stringify(user), { expires });
};

export const storeSubDomain = (subDomain) => {  
  Cookies.set("subDomain", JSON.stringify(subDomain), {});
};

export const retrieveSubDomain = () => {
 
  const storedJSON = Cookies.get("subDomain");
  const subDomain = storedJSON ? JSON.parse(storedJSON) : null;
  
  return subDomain;
};

export const retrieveUser = () => {
  const storedJSON = Cookies.get("loggedUser");
  const loggedUser = storedJSON ? JSON.parse(storedJSON) : null;

  return loggedUser;
};

export const retrieveToken = () => {
  const storedJSON = Cookies.get("loggedUser");
  const loggedUser = storedJSON ? JSON.parse(storedJSON) : null;

  if (loggedUser) return loggedUser.token;
  else return null;
};

export const clearUser = () => {
  Cookies.remove("loggedUser");
  Cookies.remove("subDomain");
};
