import React, { useEffect, useState } from "react";
import { GetEmployeeTemp } from "../../../../services/hrms";
import customToast from "../../../../components/CustomToast";
import { useSelector } from "react-redux";

const ReportSalaryDetails = (props) => {
  const { emp } = props;
  const [userTemp, setUserTemp] = useState(null);
  // const theme = useSelector((state) => state.theme.theme);
  useEffect(() => {
    {
      emp &&
        GetEmployeeTemp({ id: emp?._id })
          .then(({ data }) => {
            if (data) {
              setUserTemp(data);
            } else {
              setUserTemp(null);
            }
          })
          .catch((err) => {
            customToast.error(
              "Salary details not uploaded for selected employee!",
            );
          });
    }
  }, [emp]);
  return (
    <div>
      {userTemp && userTemp?.employeeId === emp?._id ? (
        <div className="w-full ">
          <table className="w-full table-fixed text-center border-separate">
            <tr>
              <th className="px-6 py-2 w-1/5 border-b-2 bg-primaryLight2 dark:bg-primaryDark1 border-blue-400">
                Employee Name
              </th>
              <th className="px-6 py-2 w-1/5 border-b-2 bg-primaryLight2 dark:bg-primaryDark1 border-blue-400">
                Employee ID
              </th>
              <th className="px-6 py-2 w-1/5 border-b-2 bg-primaryLight2 dark:bg-primaryDark1 border-blue-400">
                Total monthly
              </th>
              <th className="px-6 py-2 w-1/5 border-b-2 bg-primaryLight2 dark:bg-primaryDark1 border-blue-400">
                Total Annually
              </th>
            </tr>
            <tr>
              <td className="px-6 py-2 w-1/5 bg-primaryLight2 dark:bg-primaryDark1">
                {props.emp?.label}
              </td>
              <td className="px-6 py-2 w-1/5 bg-primaryLight2 dark:bg-primaryDark1">
                {props.emp?.employeeId}
              </td>
              <td className="px-6 py-2 w-1/5 bg-primaryLight2 dark:bg-primaryDark1">
                {userTemp?.salaryTempelate?.monthlyTotal}
              </td>
              <td className="px-6 py-2 w-1/5 bg-primaryLight2 dark:bg-primaryDark1">
                {userTemp?.salaryTempelate?.total}
              </td>
            </tr>
          </table>
          {userTemp?.salaryTempelate?.earnings?.length > 0 && (
            <>
              <div className="text-2x2 uppercase ml-1 my-8">SALARY DETAILS</div>
              <table className="w-full table-fixed  border-separate text-center">
                <tr>
                  <th className="px-6 py-2 w-1/5 border-b-2 bg-primaryLight2 dark:bg-primaryDark1 border-blue-400">
                    Salary Components
                  </th>
                  <th className="px-6 py-2 w-1/5 border-b-2 bg-primaryLight2 dark:bg-primaryDark1 border-blue-400">
                    Calculation
                  </th>
                  <th className="px-6 py-2 w-1/5 border-b-2 bg-primaryLight2 dark:bg-primaryDark1 border-blue-400">
                    Total monthly
                  </th>
                  <th className="px-6 py-2 w-1/5 border-b-2 bg-primaryLight2 dark:bg-primaryDark1 border-blue-400">
                    Total Annually
                  </th>
                </tr>
                {userTemp?.salaryTempelate?.earnings?.map((el, idx) => {
                  return (
                    <tr key={idx} className=" text-center">
                      <td className=" bg-primaryLight2 dark:bg-primaryDark1 px-6 py-2 w-1/5">
                        {el.earningType?.name}
                      </td>
                      <td className="bg-primaryLight2 dark:bg-primaryDark1  px-6 py-2 w-1/5">
                        {/* {el.amount > 0 ? (
                          <span>
                            {el.amount > 0 && el.earningType.name == "HRA"
                              ? `${el.amount}${"%"}${"Basic"}`
                              : `${el.amount}${"%"}${"CTC"}`}
                          </span>
                        ) : (
                          <span>Fixed amount</span>
                        )} */}
                        {`${
                          el?.paytype[0].toUpperCase() +
                          el?.paytype.substring(1)
                        } , ${el?.amount}${
                          el?.calcType?.type === "percentage"
                            ? `% of ${el?.calcType?.value.toUpperCase()}`
                            : ""
                        }`}
                      </td>
                      <td className="bg-primaryLight2 dark:bg-primaryDark1  px-16 py-2 w-1/5">
                        <span>{el?.monthly}</span>
                      </td>
                      <td className="bg-primaryLight2 dark:bg-primaryDark1 px-16 py-2 w-1/5">
                        <span>{el?.yearly}</span>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </>
          )}
          {userTemp?.salaryTempelate?.deductions?.length > 0 && (
            <div>
              <div className="text-2x2 uppercase ml-1 my-8">Deductions</div>
              {/* <div className="max-h-[400px] mt-8 overflow-y-scroll "> */}
              <table className="w-full table-fixed  border-separate">
                {userTemp?.salaryTempelate?.deductions?.map((el, idx) => {
                  return (
                    <tr key={idx} className=" text-center">
                      <td className=" bg-primaryLight2 dark:bg-primaryDark1 px-6 py-2 w-1/5">
                        {el?.name}
                      </td>
                      <td className="bg-primaryLight2 dark:bg-primaryDark1  px-6 py-2 w-1/5">
                        <span>
                          {el.employeeContributionRate?.label
                            ? `${el.employeeContributionRate?.label}`
                            : `${el.deductionFrequency}`}
                        </span>
                      </td>
                      <td className="bg-primaryLight2 dark:bg-primaryDark1 px-16 py-2 w-1/5">
                        <span>{el?.monthly}</span>
                      </td>
                      <td className="bg-primaryLight2 dark:bg-primaryDark1 px-16 py-2 w-1/5">
                        <span>{el?.yearly}</span>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          )}
          {/* </div> */}
          {userTemp?.salaryTempelate?.reimbursements?.length > 0 && (
            <div className="text-2x2 uppercase ml-1 my-8">Reimbursement</div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default ReportSalaryDetails;
