import { createSlice } from "@reduxjs/toolkit";
import {
  getAllAdvances,
  assignSalaryAdvance
} from "../services/advanceSalary";
import customToast from "../components/CustomToast";

const advSalarySlice = createSlice({
  name: "advSalary",
  initialState: {
    data: [],
  },
  reducers: {
    setadvSalaryDetails(state, action) {
      state.data = action.payload;
    },
  },
});

export const { setadvSalaryDetails } = advSalarySlice.actions;

export const fetchAllAdvSalaries = () => async (dispatch) => {
  try {
    const response = await getAllAdvances();
    dispatch(setadvSalaryDetails(response.data));
  } catch (error) {
    customToast.error("Something went wrong !")
  }
};

export default advSalarySlice.reducer;
