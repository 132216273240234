import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useMemo } from "react";
import customToast from "../../../../components/CustomToast";
import PageTitle from "../../../../components/PageTitle";
import  { addIndex2 } from "../../../../utils/serialiseResponse";
import { SlOptionsVertical } from "react-icons/sl";
import Dropdown from "../../../../components/DropDown/dropdown";
import Table from "../../../../components/Table";
import DeletePopUp from "../reimbursement/DeletePopup";
import { DeleteEarning, fetchEarnings } from "../../../../reducers/earningsReducer";

const Earnings = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const earnings = useSelector(({earnings}) => earnings?.data);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    dispatch(fetchEarnings());
  },[]);

  const tableData = useMemo(() => addIndex2(earnings), [earnings]);
  const theme = useSelector((state) => state.theme.theme);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: (row) => row.eName || "--",
        disableFilters: false,
        disableSortBy: true,
      },
      {
        Header: "Earning Type",
        accessor: "earningType.name",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Calculation Type",
        accessor: (row) => row.calcType || "--",
        disableFilters: true,
        disableSortBy: true,
        Cell:(props) =>{
          let el = props?.row?.original;
          return (
            <div>
             {`${el?.paytype[0].toUpperCase() + el?.paytype.substring(1)} , ${el?.amount}${el?.calcType?.type === "percentage" ? `% of ${el?.calcType?.value.toUpperCase()}` : ""}`}

            </div>
          )
        }
      },
      {
        Header: "EPF",
        accessor: "isEpf",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          const el = props.row.original;
          return (
            <>
              <div
                className={`${el?.isEpf === true ? 'text-green-600' : 'text-red-600'} px-6 py-6`}>
                {el?.isEpf ? "Yes" : "No"}
              </div>
            </>
          );
        },
      },
      {
        Header: "ESI",
        accessor: "esi",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          const el = props.row.original;

          return (
            <>
              <div
                className={`${el?.isEsi === true ? 'text-green-600' : 'text-red-600'} px-6 py-6`}>
                {el?.isEsi ? "Yes" : "No"}
              </div>
            </>
          );
        },
      },
      {
        Header: "Status",
        accessor: "active",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          const el = props.row.original;

          return (
            <>
              <div
                className={`${el?.active === true ? 'text-green-600' : 'text-red-600'} px-6 py-6`}>
                {el?.active ? "Active" : "Inactive"}
              </div>
            </>
          );
        },
      },
      {
        Header: "Action",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return (
            <>
              <Dropdown
                icon={
                  <SlOptionsVertical className="icon-color-tertiary" />
                }
                options={[
                  {
                    text: 'Edit',
                    onClick: () => {
                      navigate("../earnings/edit",{state:props?.row?.original})
                    }
                  },
                  {
                    text: 'Delete',
                    onClick: () => {
                      setDeleteId(props?.row?.original?._id);
                      setShowEditPopup(true);
                    }
                  },
                ]}
              />
            </>
          );
        },
      },
    ],
    [],
  );

  const handleCancel = () => {
    setShowEditPopup(false);
  };

  const handleDelete = async(e) => {
    e.preventDefault();

    try {
      let payload = {
        id : deleteId
      };

      dispatch(DeleteEarning(payload));
      setDeleteId(null);
      setShowEditPopup(false);
      customToast.success("earning deleted successfully !");
    } catch (error) {
      console.log(error);
      setShowEditPopup(false);
      customToast.error("Error occurred while deleting !");
    }
  };

  return (
    <div
      className={"main-content " + `${theme == "light" ? "light_bg_main" : ""}`}
      style={{ width: "100%" }}
    >
      <div
        className={
          "creation-content " + `${theme == "light" ? "light_bg" : ""}`
        }
      >
        <PageTitle title="Earnings" route={'../..'} />

        <section className="admin-content mx-8 py-8">
          <Link to='./add'>
            <button
              type="button"
              className="rounded-full font-medium text-2xl px-10 py-5 dark:bg-tertiary cursor-pointer"
            >ADD NEW EARNING</button>
          </Link>

          <section>
            {tableData.length > 0 && (
              <h2
                className={
                  "show-entries" +
                  `${theme === "light" ? "light_bg_logo" : ""}`
                }
              >
                Showing ({tableData.length}) Entries
              </h2>
            )}
            <div
              className={
                "filter-table " + `${theme === "light" ? "table_light" : ""}`
              }
            >
              {tableData.length > 0 ? (
                <Table columns={columns} data={tableData} />
              ) : (
                <h3 className="no-results-message">Nothing To Show !!</h3>
              )}
            </div>
            {showEditPopup ? 
            (
            <DeletePopUp
            label="Are you sure you want to delete ? "
            setShowEdit={setShowEditPopup}
            handleCancel={handleCancel}
            handleDelete={handleDelete}/>
          )
          :
          null
          }
          </section>
        </section>
      </div>
    </div>
  );
};

export default Earnings;
