import { createSlice } from "@reduxjs/toolkit";
import { AddDeductionTypes, GetDeductionTypes } from "../services/deduction";

const deductionTypesReducer = createSlice({
    name: "deductionTypes",
    initialState:{
        data : [],
    },
    reducers:{
        setDeductionType : (state, action) => {
            state.data = action.payload
        },
    },
});

export const { setDeductionType } = deductionTypesReducer.actions;

export const fetchDeductionTypes = () => async (dispatch) => {
    try {
        const { data } = await GetDeductionTypes();
        dispatch(setDeductionType(data));
    } catch (error) {
        console.log(error);
    }
};

export const postDeductionTypes = (payload) => async (dispatch) => {
    try {
        const res = await AddDeductionTypes(payload);
        dispatch(fetchDeductionTypes());
    } catch (error) {
        console.log(error);
    }
};

export default deductionTypesReducer.reducer;