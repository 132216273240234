import { createSlice } from "@reduxjs/toolkit";
import { viewLocations, newLocation, deleteLocation, editLocation } from "../services/location.js";

const initialState = {
  data: [],
};

const citySlice = createSlice({
  name: "cities",
  initialState,
  reducers: {
    setCities(state, action) {
      state.data = action.payload;
    },
    createCity(state, action) {
      state.data.push(action.payload.data);
    },
    editCity(state, action) {
      const index = state.data.findIndex((city) => city.id === action.payload.id);
      if (index !== -1) {
        state.data[index] = action.payload.updatedCity;
      }
    },
    deleteCity(state, action) {
      state.data = state.data.filter((city) => city.id !== action.payload.id);
    },
    clearCities(state) {
      state.data = [];
    },
  },
});

export const { setCities, createCity, editCity, deleteCity, clearCities } = citySlice.actions;

export const fetchCities = (headquarterId, type) => async (dispatch) => {
  try {
    const cities = await viewLocations("city", headquarterId, type);
    dispatch(setCities(cities.data));
  } catch (error) {
  }
};

export const fetchAllCities = () => async (dispatch) => {
  try {
    const { data } = await viewLocations("city");
    dispatch(setCities(data));
  } catch (error) {
  }
};

export const addCityAsync = (data) => async (dispatch) => {
  try {
    const addedCity = await newLocation("city", data);
    dispatch(createCity(addedCity));
  } catch (error) {
    customToast.error("Failed to add city");
  }
};

export const editCityAsync = (id, data, areaType) => async (dispatch) => {
  try {
    const updatedCity = await editLocation("city", id, data);
    dispatch(editCity({ id, updatedCity }));
    dispatch(fetchCities("", areaType));
  } catch (error) {
    customToast.error("Failed to edit city");
  }
};

export const deleteCityAsync = (id, areaType) => async (dispatch) => {
  try {
    await deleteLocation("city", id);
    dispatch(deleteCity({ id }));
    dispatch(fetchCities("", areaType));
  } catch (error) {
    customToast.error("Failed to delete city");
  }
};

export default citySlice.reducer;
