import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Header from "./Layout/Header";
import NavLeft from "./Layout/NavPane";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompanySetUpStatusAndUpdateHrmsSetUpStatus,
  setHrmsSetUpStatus,
} from "../../reducers/hrmsSetUpStatusReducer";
import { fetchBasicInfo } from "../../reducers/Dashboard/companyBasicInfoReducer";
import { Modal, Spinner } from "react-bootstrap";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Body style={{ padding: "3rem 2rem 3rem 2rem" }}>
        <h2 className="font-semibold text-blue-500 mb-4 text-3xl">
          Welcome to HRMS
        </h2>
        <p className="text-gray-800 font-medium mb-4">
          Your HRMS is not yet setup. Redirecting to setup page...
        </p>
        <div className="flex justify-center items-center">
          <Spinner
            animation="border"
            variant="primary"
            style={{ width: "3rem", height: "3rem" }}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const hrmsSubStatus = useSelector((state) => state.hrmsSubscription.status);

  const basicInfo = useSelector((state) => state.basicInfo.data);

  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    if (hrmsSubStatus && hrmsSubStatus !== "-1") {
      dispatch(getCompanySetUpStatusAndUpdateHrmsSetUpStatus());
    }
  }, [hrmsSubStatus]);

  useEffect(() => {
    dispatch(setHrmsSetUpStatus());
    dispatch(fetchBasicInfo());
    if (hrmsSubStatus && hrmsSubStatus === "-1") {
      setModalShow(true);
      setTimeout(() => {
        setModalShow(false);
        navigate("/setup/company");
      }, 1000);
    }
  }, [hrmsSubStatus]);
  return (
    <div className="web-app">
      <Header companyName={basicInfo?.companyName} />
      <div className="flex bg-transparent !bg-primaryLight2 dark:!bg-primaryDark1">
        <NavLeft />
        <Outlet />
        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
        <ToastContainer />
      </div>
    </div>
  );
};

export default Home;
