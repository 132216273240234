import { createSlice } from "@reduxjs/toolkit";

const lopSlice = createSlice({
    name: "lop",
    initialState:{
      data:[]
    },
    reducers: {
        setLop: (state , action) => {
          state.data = action.payload
        }
    }
})
export const {setLop} = lopSlice.actions;

export const fetchLopData = (data) => async(dispatch) => {
    try {
     dispatch(setLop(data));
    } catch (error) {
     console.log(error)
    }
    
}

export default lopSlice.reducer;