export const title = "Manage Loan";

export const links = [
  {
    name: "Create Loan",
    route: "create-loan",
    accessor: "create-loan",
  },
  {
    name: "Assign Loan",
    route: "assign-loan",
    accessor: "assign-loan",
  },
  {
    name: "Loans",
    route: "loans",
    accessor: "loans",
  },
];
