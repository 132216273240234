import moment from "moment-timezone";
export const dayOptions = [
  {
    value: 0,
    label: "Sun",
  },
  {
    value: 1,
    label: "Mon",
  },
  {
    value: 2,
    label: "Tue",
  },
  {
    value: 3,
    label: "Wed",
  },
  {
    value: 4,
    label: "Thu",
  },
  {
    value: 5,
    label: "Fri",
  },
  {
    value: 6,
    label: "Sat",
  },
];
export const returnMonthFromIndex = (index) => {
  if (index === 0) return "January";
  else if (index === 1) return "February";
  else if (index === 2) return "March";
  else if (index === 3) return "April";
  else if (index === 4) return "May";
  else if (index === 5) return "June";
  else if (index === 6) return "July";
  else if (index === 7) return "August";
  else if (index === 8) return "September";
  else if (index === 9) return "October";
  else if (index === 10) return "November";
  else if (index === 11) return "December";
};

export const monthOptions = [
  {
    value: 0,
    label: "January",
  },
  {
    value: 1,
    label: "February",
  },
  {
    value: 2,
    label: "March",
  },
  {
    value: 3,
    label: "April",
  },
  {
    value: 4,
    label: "May",
  },
  {
    value: 5,
    label: "June",
  },
  {
    value: 6,
    label: "July",
  },
  {
    value: 7,
    label: "August",
  },
  {
    value: 8,
    label: "September",
  },
  {
    value: 9,
    label: "October",
  },
  {
    value: 10,
    label: "November",
  },
  {
    value: 11,
    label: "December",
  },
];

export const yearOptions = [
  { value: 1, label: "2022" },
  { value: 2, label: "2023" },
  { value: 3, label: "2024" },
  { value: 4, label: "2025" },
];
export const orgTypes = [
  { value: 0, label: "Software" },

  { value: 1, label: "Pharmaceutical" },
  { value: 2, label: "Banking" },
  { value: 3, label: "Medical" },
];
export const periodOptions = [
  { value: 1, label: "2022-2023" },
  { value: 2, label: "2023-2024" },
  { value: 3, label: "2024-2025" },
];

export const generateMonthYearList = () => {
  const currentDate = new Date();
  let currentMonth = currentDate.getMonth();
  let currentYear = currentDate.getFullYear();
  const monthsAndYears = [];

  for (let i = 0; i < 12; i++) {
    const monthName = new Date(0, currentMonth).toLocaleString("en-US", {
      month: "long",
    });
    monthsAndYears.push({
      label: monthName + " " + currentYear,
      value: { month: currentMonth, year: currentYear },
    });
    currentMonth++;
    if (currentMonth > 11) {
      currentMonth = 0;
      currentYear++;
    }
  }

  // setmonthsList(monthsAndYears);
  return monthsAndYears;
};

export const formatDate = (date) => {
  return new Date(date).toLocaleString("en-IN", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};

export const formatDate2 = (date) => {
  return new Date(date).toLocaleString("en-IN", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
};

export const getPercentageSalary = (ctc, percent) => {
  const num = Number(ctc);
  const dis = Number(percent);
  const ans = Number((num * dis) / 100);
  return Number(ans.toFixed(2));
};

export const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const base64String = reader.result.split(",")[1];
      const imageObject = {
        name: file.name,
        data: base64String,
      };
      resolve(imageObject);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};

export const fileDownloader = (files) => {
  if (!files) return;
  const extractFileName = (url) => {
    // Extract the file name from the URL
    const urlParts = url.split("/");
    return urlParts[urlParts.length - 1];
  };
  files.map((fileUrl, idx) => {
    // Create a link element
    const link = document.createElement("a");
    link.href = fileUrl;
    const fileName = extractFileName(fileUrl);
    link.download = fileName || "downloaded-file";

    // Append the link to the document
    document.body.appendChild(link);

    // Trigger a click on the link to start the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  });
};

export const convertNumberToWords = (number) => {
  const units = [
    "",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
  ];
  const tens = [
    "",
    "Ten",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];
  const teens = [
    "Ten",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];
  const crore = "Crore";
  const lakh = "Lakh";
  const arab = "Arab";
  const currency = "Rupees";
  const paisa = "Paisa";

  if (number === 0) {
    return "Zero " + currency;
  }

  let words = "";

  if (number >= 1000000000) {
    words +=
      convertNumberToWords(Math.floor(number / 1000000000)) + " " + arab + " ";
    number %= 1000000000;
  }
  if (number >= 10000000) {
    words +=
      convertNumberToWords(Math.floor(number / 10000000)) + " " + crore + " ";
    number %= 10000000;
  }

  if (number >= 100000) {
    words +=
      convertNumberToWords(Math.floor(number / 100000)) + " " + lakh + " ";
    number %= 100000;
  }

  if (number >= 1000) {
    words += convertNumberToWords(Math.floor(number / 1000)) + " Thousand ";
    number %= 1000;
  }

  if (number >= 100) {
    words += convertNumberToWords(Math.floor(number / 100)) + " Hundred ";
    number %= 100;
  }

  if (number >= 20) {
    words += tens[Math.floor(number / 10)] + " ";
    number %= 10;
  } else if (number >= 10) {
    console.log(number);
    words += teens[number - 10] + " ";
    number = 0;
  }

  if (number > 0) {
    words += units[number] + " ";
  }
  const decimalPart = Math.round((number % 1) * 100);
  if (decimalPart > 0) {
    words += "and" + decimalPart + " " + paisa + "only ";
  }

  return words.trim() + " ";
};

export const deepObjCompare = function deepEqual(obj1, obj2) {
  if (obj1 === obj2) {
    return true;
  }

  if (
    obj1 == null ||
    typeof obj1 !== "object" ||
    obj2 == null ||
    typeof obj2 !== "object"
  ) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
};

export const returnDaysInMonth = (index , year) => {
   return new Date(year , index+1 , 0).getDate();
}

export const generateWeekDays =  (index)=>{
  const days = ["Sunday", "Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
  return days[index]
} 

export const attendanceStatusOptions = [{label:"Present",value:"present"},{label:"Absent",value:"absent"},{label:"Holiday",value:"holiday"},{label:"Leave",value:"leave"}]

export const excelDateToJSDate = (excelDate)=> {
    const excelEpoch = new Date(Date.UTC(1899, 11, 30));
    excelEpoch.setDate(excelEpoch.getDate() + excelDate);    
    return moment
            .tz(excelEpoch, "Asia/Kolkata")
            .format("YYYY-MM-DD");
}

export const bloodOptions = [
  { value: 0, label: "AB" },
  { value: 1, label: "O+" },
  { value: 2, label: "O-" },
  { value: 3, label: "A+" },
  { value: 4, label: "B+" },
  { value: 5, label: "A-" },
  { value: 6, label: "B-" },
];