import React from "react";
import PageTitle from "../../../components/PageTitle";
import { useSelector } from "react-redux";

const ImportOted = () => {
  const theme = useSelector((state) => state.theme.theme);

  return (
    <main
      className={
        "main-content " + `${theme == "light" ? " light_bg_main" : ""}`
      }
      style={{ width: "100%" }}
    >
      <div className="creation-content ">
        <PageTitle title="All Onetime Earrings and Deductions in your file are ready to be imported" />
      </div>

      <div className="m-10 flex flex-col gap-10 ">
        <p className="text-3xl">
          Onetime Earrings and Deductions that are ready for the import (1)
        </p>
        <p className="text-3xl">Number of Records skipped (0)</p>
        <p className="text-3xl">Unmapped Fields</p>
      </div>

      <div className="mr-10 flex items-center gap-20">
        <button className="button-blue-gradient">Import</button>
        <button className="button-red-gradient">Cancel</button>
      </div>
    </main>
  );
};

export default ImportOted;
