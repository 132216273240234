import { combineSlices, createSlice } from "@reduxjs/toolkit";
import { GetAllEmployees, GetEmployees, GetEmployeesWithTemp, GetSingleEmployee } from "../services/hrms";

const initialState = {
  data: [],
  singleEmp : [],
};

const usersSlice = createSlice({
  name: "employees",
  initialState,
  reducers: {
    setEmployees(state, action) {
      state.data = action.payload;
    },
    setSingleEmployee(state, action) {
      state.singleEmp = action.payload;
    },
    setEmployeesWithTemp(state , action){
      state.data = action.payload;
    },
    clearEmployees(state) {
      state.data = [];
    },
  },
});

export const fetchEmployees = (payload) => async (dispatch) => {
  try {
    const employees = await GetAllEmployees(payload);
    dispatch(setEmployees(employees));
  } catch (error) {
  }
};


export const fetchSingleEmployee = (payload) => async (dispatch) => {
  try {
    const employee = await GetSingleEmployee(payload);
    dispatch(setSingleEmployee(employee.data));
  } catch (error) {
    console.log("Error fetching single employee", error);
  }
};

export const fetchEmployeesWithTemp = (payload) => async (dispatch) => {
  try {
    const employees = await GetEmployeesWithTemp(payload);
    dispatch(setEmployees(employees.data));
  } catch (error) {
    console.log(error)
    console.log("Error fetching Employee with Salary Template")
  }
};

export const { setEmployees, clearEmployees ,setSingleEmployee } = usersSlice.actions;
export default usersSlice.reducer;
