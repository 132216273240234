import React, { useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../../../components/PageTitle/index";
import Table from "../../../../components/Table/index";
import { FaSync } from "react-icons/fa";
import { monthOptions, yearOptions } from "../../../../utils/helper";
import Select from "react-select";
import { useSelector } from "react-redux";
import {
  adminStyles,
  adminStylesLight,
  selectStyles,
} from "../../../../utils/selectStyles";
import {
  syncAttendance,
  saveSyncAttendance,
  getMonthlyAttendance,
} from "../../../../services/attendance";

const SyncRegularAttendance = () => {
  const theme = useSelector((state) => state.theme.theme);
  const [attendanceFilter, setAttendanceFilter] = useState({
    month: "",
    year: "",
  });
  const [showSyncBtn, setShowSyncBtn] = useState(false);
  const [attendance, setAttendance] = useState([]);
  const [isFetched, setIsFetcthed] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setShowSyncBtn(false);
    if (!attendanceFilter.month || !attendanceFilter.year) return;
    const date = new Date();
    const currentMonth = date.getMonth();
    const currentYear = date.getFullYear();
    if (
      currentYear == attendanceFilter?.year?.label &&
      currentMonth == attendanceFilter?.month?.value
    )
      setShowSyncBtn(true);

    setIsFetcthed(false);
    getMonthlyAttendance(attendanceFilter).then((data) => {
      setAttendance(data.data);
      setIsFetcthed(true);
    });
  }, [attendanceFilter]);

  const columns = useMemo(() => {
    let initial = [
      {
        Header: "S No.",
        accessor: "sno",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          // console.log(props.row.original);
          return <span>{props?.row?.index + 1 || "_"}</span>;
        },
      },
      {
        Header: "Employee Name",
        accessor: "employeeName",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return <span>{`${props?.row?.original?.name}` || "_"}</span>;
        },
      },
      {
        Header: "Working Days",
        accessor: "workingDays",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return <span>{props?.row?.original?.totalWorkingDays || "_"}</span>;
        },
      },
      {
        Header: "Day Work",
        accessor: "dayWorked",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return <span>{props?.row?.original?.present || "_"}</span>;
        },
      },
      {
        Header: "LOP",
        accessor: "leaves",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return <span>{props?.row?.original?.absent || "_"}</span>;
        },
      },
      {
        Header: "Actions",
        accessor: "actions",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return (
            <span
              onClick={() =>
                navigate(
                  `/attendance/sync-attendance/view/${props?.row?.original?.uid}`,
                  {
                    state: {
                      data: props?.row?.original,
                      period: attendanceFilter,
                    },
                  },
                )
              }
              className="text-[#21c38b] flex justify-center h-full"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5M12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5s5 2.24 5 5s-2.24 5-5 5m0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3s3-1.34 3-3s-1.34-3-3-3"
                />
              </svg>
            </span>
          );
        },
      },
    ];

    return initial;
  }, [attendanceFilter]);

  const handleSyncAttendance = async () => {
    if (!attendanceFilter.month || !attendanceFilter.year) return;
    try {
      setLoading(true);
      const res = await syncAttendance(attendanceFilter);
      if (res?.data?.length) {
        await saveSyncAttendance({
          syncedAttendance: res?.data,
          month: attendanceFilter.month.value,
          year: attendanceFilter.year.label,
        });
        const { data } = await getMonthlyAttendance(attendanceFilter);
        setAttendance(data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <main className="main-content">
      {loading && (
        <div className="coming-soon fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center">
          <img src="/sync.gif" alt="sync" />
        </div>
      )}
      <div className="area-creation-content">
        <PageTitle title="Sync Regular Attendance" route={"../.."} />
      </div>
      <section className="admin-content">
        {/* <div className="flex ">
              <div className="flex gap-5 items-center w-full">
                <CascadingMenu items={[]} title={"SELECT DURATION"}/>
                <button type="button" className="button-blue-gradient !px-6 !py-3  flex items-center gap-2">
                    Sync <FaSync />
                  </button>
              </div>
          </div> */}
        <div className="row">
          <div>
            <label className="uppercase form-label-div">Select Month</label>
            <Select
              styles={theme == "light" ? adminStylesLight : adminStyles}
              options={monthOptions}
              // value={selectOptions[selectedMapping[index]]}
              onChange={(e) =>
                setAttendanceFilter((pre) => ({ ...pre, month: e }))
              }
            />
          </div>
          <div>
            <label className="uppercase form-label-div">Select yEAR</label>
            <Select
              styles={theme == "light" ? adminStylesLight : adminStyles}
              options={yearOptions}
              onChange={(e) =>
                setAttendanceFilter((pre) => ({ ...pre, year: e }))
              }
            />
          </div>
          <div></div>
          {/* isFetched && showSyncBtn && USE THSII FOR HIDE SYNC BUTTON FOR OTHER THAN CURRENT MONTH */}
          {isFetched && (
            <div className="flex flex-col justify-between items-center">
              <label className="uppercase form-label-div">
                Sync from medorn
              </label>
              <button
                onClick={handleSyncAttendance}
                type="button"
                className="button-blue-gradient !px-6 !py-3  flex items-center gap-2"
              >
                Sync <FaSync />
              </button>
            </div>
          )}
        </div>
        <div>
          <section className={"cardlink-row pb-6"}>
            <label className="show-entries">Show Entries (0)</label>
            <div className="filter-table">
              <Table columns={columns} data={attendance} />
            </div>
          </section>
        </div>
      </section>
    </main>
  );
};

export default SyncRegularAttendance;
