import {RiCloseCircleLine} from "react-icons/ri" ;
import {MdOutlineEdit, MdDeleteOutline, MdOutlineCancel} from "react-icons/md" ;
import "../../../../components/EditPopup/edit.css";

const DeletePopUp = ({ handleDelete, handleCancel, setShowEdit, label}) => {

  return (
    <section className="edit-popup text-se">
      <div className="edit-content">
        <div className="edit-popup__heading">
          <h2 className="web-app__heading">Delete</h2>
          <RiCloseCircleLine
            className="edit-popup__close"
            onClick={() => setShowEdit(false)}
          />
        </div>

        <form onSubmit={handleCancel} className="w-full">
          <div className="edit-form">
            <div className="mb-8">
              <label htmlFor="division">
                {label}<span className="asterisk-imp">*</span>
              </label>
              <br />
            </div>
          </div>

          <div className="edit-form-button-container ">
            <button type="submit" className="button-submit-green bg-success mt-4">
              <span className="button-submit-green__icon-container">
                <MdOutlineCancel className="button-submit-green__icon" size={25}/>
              </span>
              <span className="button-submit-green__text">Cancel</span>
            </button>

            <button
              type="submit"
              className="button-delete mt-4 bg-error"
              onClick={(e) => handleDelete(e)}
            >
              <span className="button-delete__icon-container">
                <MdDeleteOutline className="button-delete__icon" size={25}/>
              </span>
              <span className="button-delete__text">Delete</span>
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default DeletePopUp ;