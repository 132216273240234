import { customAxios } from "../utils/axiosinstance"

export const createDivision = async(payload) => {
    const {data} = await customAxios.post(`/hrms/onboarding/create-division` , payload)
    return data;
}

export const getAllDivisions = async() => {
    const {data} = await customAxios.get(`/hrms/onboarding/get-all-divisions`)
    return data;
}

export const addTaskToDivision = async(payload) => {
    const {data} = await customAxios.post(`/hrms/onboarding/add-task-to-division` , payload)
    return data;
}

export const createProcess = async(payload) => {
    const {data} = await customAxios.post(`/hrms/onboarding/create-process` , payload)
    return data;
}

export const getAllProcesses = async() => {
    const {data} = await customAxios.get(`/hrms/onboarding/get-all-processes`)
    return data;
}

export const AddEmployeesToProcess = async(payload) => {
    const {data} = await customAxios.post(`/hrms/onboarding/initiate-employees-to-process` , payload)
    return data;
}

export const getAllOnboardingEmployeeTasks = async() => {
    const {data} = await customAxios.get(`/hrms/onboarding/get-all-onboarding-employee-tasks`)
    return data;
}

export const getEmployeeTasks = async(payload) => {
    const {data} = await customAxios.post(`/hrms/onboarding/get-employee-tasks` , payload)
    return data;
}

export const updateStatusOfEmpTasks = async(payload) => {
    const {data} = await customAxios.post(`/hrms/onboarding/update-status-of-onboarding-employee-tasks`, payload)
    return data;
}

export const assignActivityToProcess = async(payload) => {
    const {data} = await customAxios.post(`/hrms/onboarding/assign-division-to-process` , payload)
    return data;
}

export const updateStatusOfEmpSingleTask = async(payload) => {
    const {data} = await customAxios.post(`/hrms/onboarding/update-status-of-employee-single-task`, payload)
    return data;
}

export const editTaskDetails = async(payload) => {
    const {data} = await customAxios.post(`/hrms/onboarding/edit-task`, payload)
    return data;
}

export const deleteTask = async(payload) => {
    const {data} = await customAxios.post(`/hrms/onboarding/delete-task`, payload)
    return data;
}

export const deleteProcess = async(payload) => {
    const {data} = await customAxios.post(`/hrms/onboarding/delete-process`, payload)
    return data;
}