import React, { useMemo, useEffect, useState } from "react";
import { CiEdit } from "react-icons/ci";
import { useSelector } from "react-redux";
import PageTitle from "../../../../components/PageTitle";
import Table from "../../../../components/Table";
import {
  ChangeRegimeType,
  GetAllEmployees,
} from "../../../../services/employee";

const Regime = () => {
  const theme = useSelector((state) => state.theme.theme);
  const [data, setData] = useState([]);
  const columns = useMemo(() => {
    let initial = [
      {
        Header: "Employee",
        accessor: "Employee",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          // console.log(props?.row.original);
          const { firstName, lastName } = props?.row?.original;
          return (
            // <>g</>
            <span>{`${firstName} ${lastName}` || "_"}</span>
          );
        },
      },
      {
        Header: "Tax-Regime",
        accessor: "Tax-Regime",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          const { regime, _id } = props?.row?.original;

          return <SelectForTable id={_id} initial={regime} />;
        },
      },
    ];
    return initial;
  }, []);

  useEffect(() => {
    GetAllEmployees().then((data) => {
      setData(data);
    });
  }, []);
  return (
    <main className="main-content bg-primaryLight2 dark:bg-primaryDark1">
      <div
        className={
          "area-creation-content" + `${theme == "light" ? "light_bg" : ""}`
        }
      >
        <PageTitle title="Tax Regime" route={".."} />
        <section className="admin-content">
          <div>
            <section className={"cardlink-row pb-6"}>
              <label className="show-entries">
                Show Entries ({data?.length})
              </label>

              <div className="filter-table">
                <Table columns={columns} data={data} />
              </div>
            </section>
          </div>
        </section>
      </div>
    </main>
  );
};

export default Regime;

export const SelectForTable = ({ initial, id }) => {
  const [regimeTypes, setRegimeTypes] = useState(initial);
  const [isEditMode, setIsEditMode] = useState(false);
  const handleRegimeChange = async (e) => {
    const { id, value } = e.target;
    const { data } = await ChangeRegimeType({ id, regimeType: value });

    setRegimeTypes(value);
    setIsEditMode(false);
  };

  const handleEditMode = () => {
    setIsEditMode(true);
  };
  return (
    <>
      {isEditMode ? (
        <select
          id={id}
          className={`!p-0 !py-1 !border !w-fit rounded-lg`}
          value={regimeTypes}
          onChange={handleRegimeChange}
        >
          <option value={"old-regime"}>old-regime</option>
          <option value={"new-regime"}>new-regime</option>
        </select>
      ) : (
        <div className="flex justify-between !text-green-500 font-semibold">
          <span></span>{" "}
          <span className="capitalize">
            {regimeTypes?.split("-").join(" ")}
          </span>{" "}
          <CiEdit color="#c7c4e9" fontSize={"30px"} onClick={handleEditMode} />
        </div>
      )}
    </>
  );
};
