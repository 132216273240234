import { useEffect, useState, createContext } from "react";

export const ThemeContext = createContext("");

const ThemeWrapper = (props) => {

  const [theme, setTheme] = useState("dark");

  function changeTheme(theme) {
    localStorage.setItem("theme", theme);
    setTheme(theme);
  }
  useEffect(()=>{
    const savedTheme = localStorage.getItem("theme");
    setTheme(savedTheme || "dark");
  },[]);

  useEffect(() => {
    switch (theme) {
      case "light":
        document.body.classList.remove("dark");
        document.body.classList.add("light");
        break;
      case "dark":
        document.body.classList.remove("light");
        document.body.classList.add("dark");
        break; 
      default:
        document.body.classList.remove("light");
        document.body.classList.add("dark");
        break;
    }
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme: theme, setTheme: changeTheme }}>
      {props.children}
    </ThemeContext.Provider>
  );
};

export default ThemeWrapper;
