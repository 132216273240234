import React, { useEffect, useState } from "react";
import "../index.css";
import { ToastContainer } from "react-toastify";
import Dots from "../../../assets/img/Vector(2).png";
import { useSelector } from "react-redux";
import { FaArrowRight } from "react-icons/fa6";
import { Outlet, useNavigate } from "react-router-dom";
// setCurrentStep

const SetUpHome = () => {
  const navigate = useNavigate();
  const currentStep = useSelector((state) => state.currentStep.value);

  const loggedIn = useSelector(({ user }) => user);

  useEffect(() => {
    if (!loggedIn.user) navigate("/login");
  }, [loggedIn.user, navigate]);

  return (
    <div className="set_up_home">
      <div className="box_container">
        <div className="left">
          <h1>MEDORN</h1>
          <p className="capitalize">Welcome {loggedIn?.user?.subdomain}</p>
          <div className="set_up_stages">
            <h2
              className={
                currentStep == 1
                  ? "cursor-pointer active_step"
                  : "cursor-pointer"
              }
              onClick={() => navigate("./company")}
            >
              {currentStep == 1 && <FaArrowRight />}Set Up Company
            </h2>
            <h2
              className={
                currentStep == 2
                  ? "cursor-pointer active_step"
                  : "cursor-pointer"
              }
              onClick={() => navigate("./tax-info")}
            >
              {currentStep == 2 && <FaArrowRight />}Tax Information
            </h2>
            <h2
              className={
                currentStep == 3
                  ? "cursor-pointer active_step"
                  : "cursor-pointer"
              }
              onClick={() => navigate("./schedule-payment")}
            >
              {currentStep == 3 && <FaArrowRight />}Schedule Payment
            </h2>
            <h2
              className={
                currentStep == 4
                  ? "cursor-pointer active_step"
                  : "cursor-pointer"
              }
              onClick={() => navigate("./statutory-components")}
            >
              {currentStep == 4 && <FaArrowRight />}Statutory Components
            </h2>
            <h2
              className={
                currentStep == 5
                  ? "cursor-pointer active_step"
                  : "cursor-pointer"
              }
              onClick={() => navigate("./salary-components")}
            >
              {currentStep == 5 && <FaArrowRight />}Salary Components
            </h2>
            <h2
              className={
                currentStep == 6
                  ? "cursor-pointer active_step"
                  : "cursor-pointer"
              }
              onClick={() => navigate("./department")}
            >
              {currentStep == 6 && <FaArrowRight />}Departments
            </h2>
            <h2
              className={
                currentStep == 7
                  ? "cursor-pointer active_step"
                  : "cursor-pointer"
              }
              onClick={() => navigate("./division")}
            >
              {currentStep == 7 && <FaArrowRight />}Divisions
            </h2>
          </div>
        </div>
        <div className="right">
          <Outlet />
        </div>
        <div className="dot_container">
          <img src={Dots} alt="" />
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default SetUpHome;
