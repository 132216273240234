import { createSlice } from "@reduxjs/toolkit";
import { GetEarningTypes, PostEarningTypes } from "../services/earning";

const earningTypesReducer = createSlice({
    name: "earningTypes",
    initialState:{
        data:[],
    },
    reducers:{
        setEarningTypes :(state , action) => {
            state.data = action.payload
        },
    },
});

export const { setEarningTypes } = earningTypesReducer.actions;

export const fetchEarningTypes = () => async (dispatch) => {
    try {
        const {data} = await GetEarningTypes();
        dispatch(setEarningTypes(data));
    } catch (error) {
        console.log(error);
    }
};

export const postEarningType = (payload) => async (dispatch) => {
    try {
        const res = await PostEarningTypes(payload);
        dispatch(fetchEarningTypes());
    } catch (error) {
        console.log(error);
    }
}

export default earningTypesReducer.reducer;