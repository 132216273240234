import { useEffect, useState, useMemo } from "react";
import PageTitle from "../../../../components/PageTitle/index.jsx";
import {
  adminStyles,
  adminStylesLight,
} from "../../../../utils/selectStyles.js";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  clearEmployees,
  fetchEmployees,
} from "../../../../reducers/employeesReducer.js";
import { generateSelectData } from "../../../../utils/serialiseResponse.js";
import { monthOptions } from "../../../../utils/helper.js";
import { fetchAllAdvSalaries } from "../../../../reducers/advSalaryReducer.js";
import { assignSalaryAdvance } from "../../../../services/advanceSalary.js";
import customToast from "../../../../components/CustomToast/index.js";

const AssignAdvSalary = () => {
  const theme = useSelector((state) => state.theme.theme);
  const dispatch = useDispatch();
  const employees = useSelector(({ employees }) => employees);
  const [data, setData] = useState({
    employeeId: "",
    nameOnPayslip: "",
    advanceAmount: "",
    deductionType: "",
    deductionMonth: "",
    sanctionDate: "",
    installmentAmount: "",
  });

  const emplSelect = useMemo(
    () => generateSelectData(employees.data, "fullName"),
    [employees.data],
  );

  useEffect(() => {
    dispatch(fetchEmployees({}));
    return () => dispatch(clearEmployees());
  }, [dispatch]);

  const submitHandler = async (e) => {
    e.preventDefault();

    if (
      !data.employeeId ||
      !data.advanceAmount ||
      !data.deductionMonth ||
      !data.sanctionDate ||
      !data.installmentAmount
    ) {
      return customToast.error("Please fill all required fields");
    }

    try {
      const res = await assignSalaryAdvance({
        sanctionDate: data.sanctionDate,
        nameOnPayslip: data.nameOnPayslip,
        employeeId: data.employeeId._id,
        deductionType: data.deductionType.value,
        advanceAmount: data.advanceAmount,
        deductionMonth: data.deductionMonth.value,
        installmentAmount: data.installmentAmount,
      });
      if (res) {
        dispatch(fetchAllAdvSalaries());
        setData({
          employeeId: "",
          nameOnPayslip: "",
          advanceAmount: "",
          deductionType: "",
          deductionMonth: "",
          noOfMonths: "",
          sanctionDate: "",
          installmentAmount: "",
        });
        return customToast.success(
          "Salary advance has been assigned successfully",
        );
      }
    } catch (err) {
      return customToast.error("Something went wrong! ");
    }
  };

  return (
    <main
      className={
        "main-content " + `${theme == "light" ? "light_bg_main" : ""} w-full`
      }
    >
      <div
        className={
          "area-creation-content " + `${theme == "light" ? "light_bg" : ""}`
        }
      >
        <PageTitle title="Assign Salary Advance" route={"../.."} />

        <form onSubmit={submitHandler}>
          <section className="admin-content w-full pr-8">
            <div className="row ">
              <div className="">
                <label htmlFor="emp_name">
                  EMPLOYEE NAME <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  id="emp_name"
                  options={emplSelect}
                  value={data.employeeId}
                  onChange={(e) => setData({ ...data, employeeId: e })}
                  styles={theme == "light" ? adminStylesLight : adminStyles}
                  placeholder="Select Name"
                  className="w-full rounded-none"
                />
              </div>

              <div className="">
                <label htmlFor="nameOnPayslip">NAME ON PAYSLIP</label>
                <br />
                <input
                  id="nameOnPayslip"
                  type="text"
                  placeholder="Salary Advance"
                  value={data.nameOnPayslip}
                  onChange={(e) =>
                    setData({ ...data, nameOnPayslip: e.target.value })
                  }
                />
              </div>

              <div className="">
                <label htmlFor="adv_amt">
                  ADVANCE AMOUNT <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="number"
                  placeholder="Enter Amount"
                  value={data.advanceAmount}
                  onChange={(e) =>
                    setData({ ...data, advanceAmount: e.target.value })
                  }
                />
              </div>

              <div className="">
                <label htmlFor="ins_amt">
                  INSTALLMENT AMOUNT <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  id="ins_amt"
                  type="number"
                  placeholder="Enter Amount"
                  value={data.installmentAmount}
                  onChange={(e) =>
                    setData({ ...data, installmentAmount: e.target.value })
                  }
                />
              </div>
            </div>

            <div className="row ">
              <div className="">
                <label htmlFor="ded_type">DEDUCTION TYPE</label>
                <br />
                <Select
                  id="ded_type"
                  options={[
                    { label: "Monthly", value: "monthly" },
                    // { label: "Yearly", value: "yearly" },
                    // { label: "Quarterly", value: "quarterly" },
                    // { label: "Half-yearly", value: "half-yearly" },
                  ]}
                  value={data.deductionType}
                  onChange={(e) => setData({ ...data, deductionType: e })}
                  placeholder="Select Type"
                  styles={theme == "light" ? adminStylesLight : adminStyles}
                />
              </div>

              <div className="">
                <label htmlFor="ded_month">
                  DEDUCTION MONTH <span className="asterisk-imp">*</span>
                </label>
                <Select
                  id="ded_month"
                  options={monthOptions}
                  value={data.deductionMonth}
                  onChange={(e) => setData({ ...data, deductionMonth: e })}
                  styles={theme == "light" ? adminStylesLight : adminStyles}
                  placeholder="Select Month"
                />
                <br />
              </div>

              <div className="">
                <label htmlFor="sanction_date">
                  ADVANCE SANCTION DATE <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  id="sanction_date"
                  type="date"
                  style={{
                    boxShadow: "none",
                  }}
                  placeholder="Select Date"
                  value={data.sanctionDate}
                  onChange={(e) =>
                    setData({ ...data, sanctionDate: e.target.value })
                  }
                />
              </div>
            </div>

            <div className="row pb-8">
              <div className=" area-creation-content__form-submit ">
                <button type="submit" className="button-blue-gradient">
                  Assign Advance
                </button>
              </div>
            </div>
          </section>
        </form>
      </div>
    </main>
  );
};

export default AssignAdvSalary;
