import React, { useMemo } from 'react'
import { useEffect, useState } from 'react'
import PageTitle from '../../../components/PageTitle'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select';
import { clearEmployees, fetchEmployees} from '../../../reducers/employeesReducer';
import serialise, { addIndex, generateSelectData } from '../../../utils/serialiseResponse';
import { adminStyles, adminStylesLight } from '../../../utils/selectStyles';
import { monthOptions } from '../../../utils/helper';
import Table from '../../../components/Table';
import { FaPen } from "react-icons/fa";
import { useNavigate, Link, redirect } from 'react-router-dom';

const ApproveSalaryRevision = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useSelector(({theme}) => theme);
    const employees = useSelector(({employees}) => employees);
    const [month, setMonth] = useState(null);
    const [selectEmpl, setSelectEmpl] = useState(null); 

    useEffect(()=>{
        dispatch(fetchEmployees({}));

        return () => dispatch(clearEmployees());
    },[dispatch]);


    const emplSelect = useMemo(
        ()=> generateSelectData(employees.data, "fullName"),
        [employees.data]
    );

    const reDirectDetails = (payload) => {
        navigate("../salary-revision-details",{state: payload});
    }

    const tableData = useMemo(()=> serialise(employees.data),[employees.data]);    

    const columns = useMemo(
        () => [
            {
                Header:"Employee Name",
                accessor:"",
                Cell:(props) => {
                    console.log("props ----->",props.row.original);
                    return  <span>
                    {`${props?.row?.original?.firstName} ${props?.row?.original?.middleName} ${props?.row?.original?.lastName}`}
                    </span>
                }    
            },
            {
                Header:"Effective Month",
                accessor:""   ,
                Cell:(props) =>{
                    let month = new Date(props?.row?.original?.joiningDate).getMonth();
                    {console.log("month -->", month)}
                    return <span>
                        {month}
                    </span>
                } 
            },
            {
                Header:"Current CTC",
                accessor:"salaryTempelate[0].ctc"  
            },
            {
                Header:"Status",
                // accessor:""    
            },
            {
                Header:"Actions",
                accessor:"",
                Cell:(props) =>{
                    return <button className="grid justify-items-center">
                        <FaPen className='icon-color-green' size={15}
                        onClick={() => reDirectDetails(props?.row?.original)}/>
                    </button>
                }    
            },
        ],
        [tableData]
    );


  return (
    <div className="main-content bg-primaryLight2 dark:bg-primaryDark1">
            <div className="area-creation-content ">
                <PageTitle title="Salary Revision"/>

            <div className="flex flex-row gap-x-40">
             <div className=' mt-5 m-4 flex flex-col'>
                <div>
                <h3>
                Select Employee
                </h3>
                </div>
                <div>
                <Select
                className="w-80"
                styles={theme == "light" ? adminStylesLight : adminStyles}
                options={emplSelect}
                onChange={(el) => setSelectEmpl(el)}/>
                </div>
             </div>

             <div className=' mt-5 m-4 flex flex-col'>
                <div>
                <h3>
                Select Month
                </h3>
                </div>
                <div>
                <Select
                className="w-80"
                styles={theme == "light" ? adminStylesLight : adminStyles}
                options={monthOptions}
                onChange={(el) => setMonth(el?.value)}/>
                </div>
             </div>
            </div>

            <div className='inside-containt'>
                <section className={"area-creation-content__info py-0 p-0"}>
                    <h2 className="mt-5 pt-5 ms-8 web-app__heading">
                    Showing ({tableData.length}) Entries
                    </h2>

                    <div className="filter-table m-8 ">
                    <Table columns={columns} data={tableData} />
                    </div>
                </section>
            </div>    

        </div>
    </div>
  )
}

export default ApproveSalaryRevision;