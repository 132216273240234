import React from "react";
import { title, links } from "./statutoryNavdata";
import { Outlet } from "react-router-dom";
import SideBar from "../../../components/SideBar/SideBar";
import { useSelector } from "react-redux";

const Statutory = () => {
  const theme = useSelector((state) => state.theme.theme);
  return (
    <div
      className={"flex w-full " + `${theme == "light" ? "light_bg_main" : ""}`}
    >
      <SideBar title={title} links={links} />
      <Outlet />
    </div>
  );
};

export default Statutory;
