import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import PageTitle from '../../../../components/PageTitle';
import  Select  from 'react-select';
import { adminStyles, adminStylesLight } from '../../../../utils/selectStyles';
import { clearStates, fetchStates } from '../../../../reducers/stateReducer';
import { clearCities, fetchCities } from '../../../../reducers/cityReducer';
import { generateSelectData } from '../../../../utils/serialiseResponse';
import { addCompanyWorkingAddress } from '../../../../services/hrms';
import customToast from '../../../../components/CustomToast';
import { useNavigate } from 'react-router-dom';

const AddWorkingAddress = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [state, setState] = useState(null);
    const [city, setCity] = useState("");
    const [officeName , setOfficeName] = useState("");
    const [addressLine1 , setAddressLine1] = useState("");
    const [addressLine2 , setAddressLine2] = useState("");
    const [zipCode , setZipCode] = useState(null);
    const states = useSelector((state) => state.states.data);
    const theme = useSelector((state) => state.theme.theme);
    const cities = useSelector(({ city }) => city.data);
   
    useEffect(() => {
      dispatch(fetchStates("state"));
      dispatch(fetchCities("",   "city" ));
      return () => {
      dispatch(clearStates());
      dispatch(clearCities());
     };
    },[dispatch])

    const stateSelect = useMemo(
        () => generateSelectData(states, "name"),
        [states]
    );

    const citySelect = useMemo(
        () => generateSelectData(cities, "name"),
        [cities]
    );  

    const handleSubmit = async(e) => {
        e.preventDefault();

        const payload = {
            officeName,
            state,
            city,
            addressLine1,
            addressLine2,
            zipCode,
        }
      
     const response = await addCompanyWorkingAddress(payload);
     if(response){
       navigate(-1)
       return customToast.success("Sucessfully Added a New Address")
     }
     else{
      return customToast.error("Server Error")
     }
    }

    return (
        <main className="w-full">
            <div
                className={
                    "creation-content " + `${theme == "light" ? "light_bg" : ""}`
                }
            >
                <PageTitle title="Add New Working Address" route='/add/working-address' />
            </div>
                <section className="admin-content">
                <form onSubmit={(e) => handleSubmit(e)}>
                        <div className="">
                            <div className='row'>
                               <div >
                                    <label htmlFor="mName">OFFICE NAME</label>
                                    <input
                                        type="text"
                                        id="mName"
                                        required
                                        placeholder="Enter OFFICE NAME"
                                        onChange={(e) => setOfficeName(e.target.value)}
                                    />
                                </div>

                                <div>
                                    <label>
                                        SELECT STATE<span className="asterisk-imp">*</span>
                                    </label>
                                    <Select
                                        isSearchable={true}
                                        options={stateSelect}
                                        required
                                        styles={theme == "light" ? adminStylesLight : adminStyles}
                                        placeholder="Select State"
                                        onChange={(e) => setState({ value: e.value, label: e.label })}
                                    />
                                </div>


                                <div>
                                    <label>
                                        SELECT CITY <span className="asterisk-imp">*</span>
                                    </label>
                                    <Select
                                        placeholder="Select City"
                                        required
                                        styles={theme == "light" ? adminStylesLight : adminStyles}
                                        options={citySelect}
                                        onChange={(e) => setCity({ value: e.value, label: e.label })}      
                                    />
                                </div>

                                <div >
                                    <label htmlFor="mName">ADDRESS LINE 1</label>
                                    <input
                                        type="text"
                                        id="mName"
                                        required
                                        placeholder="Enter Address"
                                        onChange={(e) => setAddressLine1(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className='row'>
                                <div>
                                    <label htmlFor="lName">
                                        ADDRESS LINE 2
                                    </label>
                                    <input
                                        type="text"
                                        id="lName"
                                        placeholder="Enter Address"
                                        onChange={(e) => setAddressLine2(e.target.value)}
                                    />
                                </div>

                                <div >
                                    <label htmlFor="mName">ZIP CODE</label>
                                    <input
                                        type="number"
                                        id="mName"
                                        required
                                        placeholder="Enter Address"
                                        onChange={(e) => setZipCode(e.target.value)}
                                    />
                                </div>
                            </div>
                      </div>

                        <div className='cardlink-row pb-8'>
                            <button type="submit" className="button-blue-gradient">
                                Save
                            </button>
                        </div>
                    </form>
                </section>
        </main>
    )
}

export default AddWorkingAddress