import React from "react";
import { useDropzone } from "react-dropzone";
import { RiUpload2Fill } from "react-icons/ri";
import { useSelector } from "react-redux";

const Dropzone = ({ uploadedFiles, setUploadedFiles }) => {
  const theme = useSelector((state) => state.theme.theme);
  const { getRootProps, getInputProps } = useDropzone({
    accept: [".csv", ".tsv", ".xls"],
    onDrop: (acceptedFiles) => {
      setUploadedFiles(acceptedFiles);
    },
  });
  return (
    <div
      className={
        "mr-10 mb-20 flex items-center flex-col gap-10 bg-primaryDark2 justify-center border-b-4 border-tertiary h-96 cursor-pointer " +
        `${theme == "light" && " bg-white "}`
      }
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <p className="w-full text-center text-3xl   text-wheat  ">
        Drop files here or click here to upload <br /> Maximum File Size: 5 MB |
        File Format: CSV or XLS
      </p>
      <RiUpload2Fill className="text-5xl text-tertiary" />
      <ul>
        {uploadedFiles.map((file) => (
          <li key={file.name}>{file.name}</li>
        ))}
      </ul>
    </div>
  );
};
export default Dropzone;
