import React from "react";
import { Outlet } from "react-router-dom";
import ManagePayrunNav from "../ManagePayrunNav/ManagePayrunNav";
import { title, links } from "../ManagePayRunData.js";
import SideBar from "../../../components/SideBar/SideBar.jsx";

const ManagePayrun = () => {
  return (
    <>
      <SideBar title={title} links={links} />
      <Outlet />
    </>
  );
};

export default ManagePayrun;
