import React from 'react';
import { Select } from 'antd';
import '../../antd.css'
const SearchDropDown = ({options,placeholder,onChange}) => (
  <Select 
  dropdownClassName='custom-select-dropdown'
  className='custom-ant-select w-full p-0 !text-secondary !bg-primary !placeholder-slate-50'  
  showSearch
    onChange={onChange}
    placeholder={placeholder}
    filterOption={(input, option) =>
      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
    }
    options={options||[]}
  />
);
export default SearchDropDown;