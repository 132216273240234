import axios from "axios";
import { retrieveSubDomain, retrieveToken } from "./authStorage";

// const apiURL = "http://192.168.1.11:8001";
// const apiURL = "http://localhost:8001";
// const apiURL = "https://development.medornventures.in"; // avdesh don't this url
const apiURL = "https://hrms.shippien.com";

export const customAxios = axios.create({
  baseURL: apiURL,
  headers: {
    "Content-Type": "application/json",
    Accept: "*/*",
  },
  timeout: 10000,
});

customAxios.interceptors.request.use(
  async (config) => {
    const subdomain = retrieveSubDomain();
    const token = retrieveToken();
    const bearerAuth = token ? token : null;
    // if (subdomain) config.headers.Referer = `http://${subdomain}.medorn.com`;
    config.headers.subdomain = subdomain;
    if (bearerAuth) config.headers.Authorization = `bearer ${bearerAuth}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// const apiURL2 = "http://192.168.1.7:8002/client";
// export const apiURL2 = "http://localhost:8002/medorn/client";
const apiURL2 = "https://development.medornventures.in/medorn/client";
// const apiURL2 = "https://backend.medorn.com/client";

export const customAxios2 = axios.create({
  baseURL: apiURL2,
  headers: {
    "Content-Type": "application/json",
    Accept: "*/*",
  },
  timeout: 10000,
});

customAxios2.interceptors.request.use(
  async (config) => {
    const subdomain = retrieveSubDomain();
    const token = retrieveToken();
    console.log(subdomain, token, "auth");
    const bearerAuth = token ? token : null;
    // if (subdomain) config.headers.Referer = `http://${subdomain}.medorn.com`;
    config.headers.subdomain = subdomain;
    config.headers.API_KEY = "123rhdutrsotpuostosrlthDTU";
    if (bearerAuth) config.headers.Authorization = `bearer ${bearerAuth}`;
    console.log(config, "config");
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
