import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../../../components/PageTitle";
import { adminStyles, adminStylesLight } from "../../../../utils/selectStyles";
import Select, { components } from "react-select";
import {
  fetchReimbursementTypes,
  postReimbursementTypes,
} from "../../../../reducers/reimbursementTypesReducer";
import { generateSelectData } from "../../../../utils/serialiseResponse";
import customToast from "../../../../components/CustomToast";
import {
  CreateReimbursement,
  PostReimbursementTypes,
} from "../../../../services/reimbursement";
import { useNavigate } from "react-router-dom";
import { IoAddOutline } from "react-icons/io5";
import Modal from "../../../../components/Modal";

const AddReimbursement = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const theme = useSelector((state) => state.theme.theme);
  const reimbursementTypes = useSelector(
    (state) => state.reimbursementTypes.types,
  );

  const [reimbursementType, setReimbursementType] = useState("");
  const [nameOnPaySlip, setNameOnPaySlip] = useState("");
  const [limitPerMonth, setLimitPerMonth] = useState(0);

  const [addFlexibleBenefit, setAddFlexibleBenefit] = useState(false);
  const [allowEmployeeOverriding, setAllowEmployeeOverriding] = useState(false);
  const [active, setActive] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [newReimbType, setNewReimbType] = useState(null);

  useEffect(() => {
    dispatch(fetchReimbursementTypes());
  }, []);

  // let reimbursementTypesSelect = [{ label: "Add Other", value: "Add Other", icon: <IoAddOutline /> }]

  const reimbursementTypesSelect = useMemo(() => {
    let options = generateSelectData(reimbursementTypes, "name");
    options.push({
      label: "Add Other",
      value: "Add Other",
      icon: <IoAddOutline />,
    });
    return options;
  }, [reimbursementTypes]);

  const clearStates = () => {
    setReimbursementType("");
    setNameOnPaySlip("");
    setLimitPerMonth("");
    setAddFlexibleBenefit(false);
    setAllowEmployeeOverriding(false);
    setActive(false);
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    try {
      if (!reimbursementType || !nameOnPaySlip || !limitPerMonth) {
        customToast.error("Please fill all the required fields");
        return;
      }

      const payload = {
        nameOnPaySlip,
        limitPerMonth: Number(limitPerMonth),
        // addFlexibleBenefit,
        // allowEmployeeOverriding,
        active,
        reimbursementType: reimbursementType?.name,
      };
      await CreateReimbursement([payload]);
      navigate("..");
      customToast.success("Reimbursement type created successfully");
      clearStates();
    } catch (error) {
      customToast.error("Failed to create reimbursement type");
    }
  };

  const handleReimbursementType = async (e) => {
    e.preventDefault();
    try {
      let payload = {
        reimbursementType: newReimbType,
      };
      dispatch(postReimbursementTypes([payload]));
      setShowModal(false);
      setNewReimbType(null);
      customToast.success("New reimbursement type created successfully !");
    } catch (error) {
      console.log(error);
      customToast.error("Error in adding new type !");
    }
  };

  return (
    <div
      className={"main-content " + `${theme == "light" ? "light_bg_main" : ""}`}
      style={{ width: "100%" }}
    >
      <div
        className={
          "creation-content " + `${theme == "light" ? "light_bg" : ""}`
        }
      >
        <PageTitle title="ADD REIMBURSEMENT" route={".."} />

        <section className="admin-content">
          <form onSubmit={(e) => handleCreate(e)}>
            <div className="row">
              <div>
                <label className="dark:text-primaryDark1 uppercase">
                  REIMBURSEMENT TYPE<span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  styles={adminStyles}
                  placeholder="Select Type"
                  className="mt-4"
                  options={reimbursementTypesSelect}
                  value={reimbursementType}
                  onChange={(e) => {
                    e?.label === "Add Other"
                      ? setShowModal(true)
                      : setReimbursementType(e);
                  }}
                  components={{
                    Option: ({ data, ...props }) => (
                      <components.Option {...props}>
                        <div className="flex gap-2 items-center">
                          <span>{data.label}</span>
                          <div>{data.icon}</div>
                        </div>
                      </components.Option>
                    ),
                  }}
                />
              </div>

              <div>
                <label>
                  NAME ON PAYSLIP<span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                  className="mt-4"
                  placeholder="Enter Name"
                  value={nameOnPaySlip}
                  onChange={({ target }) => {
                    setNameOnPaySlip(target?.value);
                  }}
                />
              </div>

              <div>
                <label>
                  ENTER LIMIT PER MONTH <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                  className="mt-4"
                  placeholder="Enter Limit Amount"
                  value={limitPerMonth}
                  onChange={({ target }) => setLimitPerMonth(target?.value)}
                />
              </div>
            </div>

            <div className="flex gap-3 mx-8">
              <input
                type="checkbox"
                id="chk"
                // onChange={(e) => active ? setActive(false) : setActive(true)}
                onClick={(e) => {
                  setActive(!active);
                }}
                checked={active}
                className="check-box-active blue_b w-6 h-6"
              />
              <label htmlFor="chk" className="text-2xl cursor-pointer">
                Mark this as Active
              </label>
            </div>

            <div className="mx-8 mt-6 pb-10">
              {/* <button type="submit" className="button-blue-gradient">
                Create
              </button> */}
              <button
                // onClick={handleCreate}
                className="button-blue-gradient"
              >
                Create
              </button>
            </div>
          </form>
        </section>

        <Modal
          showModal={showModal}
          closeModal={() => setShowModal(false)}
          title="Add Reimbursement Type"
          width="w-[300px]"
          content={
            <div>
              <form>
                <div className="flex flex-col gap-10">
                  <div className="flex flex-col gap-2">
                    <label htmlFor="loan_name">Enter Reimbursement Type</label>
                    <input
                      id="loan_name"
                      type="text"
                      placeholder="Enter Type"
                      className="bg-[#2B2C47] px-6 py-4 outline-none"
                      onChange={(e) => setNewReimbType(e?.target?.value)}
                    />
                  </div>

                  <div className="flex items-center gap-10">
                    <button
                      type="submit"
                      className="px-8 py-2 w-max text-white bg-tertiary rounded-xl cursor-pointer"
                      value="Save"
                      onClick={(e) => handleReimbursementType(e)}
                    >
                      Submit
                    </button>
                    <span
                      className="text-cyan cursor-pointer"
                      onClick={() => setShowModal(false)}
                    >
                      Cancel
                    </span>
                  </div>
                </div>
              </form>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default AddReimbursement;
