import React, { useState } from 'react'
import Form from 'react-bootstrap/Form';
import './listform.css'
import {list1  , list2 , list3 ,list4 ,list5 , list6 , list7} from './listData';
import { RiErrorWarningFill } from "react-icons/ri";

const ListForm = () => {
  const [lossConsidered , setLossConsidered] = useState(null);
  const [lossDeducted , setLossDeducted] = useState(null);
  const [paySlip , setPaySlip] = useState(null);
  const [manualWorkday , setManualWorkday] = useState(null);
  const [mailApproval , setMailApproval] = useState(null);
  
  return (
          <>
            <div className="row ml-3">
              <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                <label className='formslabels' >
                  Loss of pay will be considered for<span><RiErrorWarningFill/></span>
                </label>
              </div>
                <br />
              <div className="d-flex flex-wrap mt-3 gap-36 ml-2  radio-color">
              {list1.titles.map((title) =>
              <Form>
               {['radio'].map((type) => (
                        <Form.Check
                         inline
                         label={title.label}
                         name={title.name}
                         type={type}
                         id={title.id}
                         className="calc-radio d-flex "
                         onChange={(e) => setLossConsidered(e.target.value)}
                         value={title.id}
                         checked={lossConsidered === title.id}
                    />
                    
                ))} 
               </Form>)}
              </div>
            </div>

            <div className="row ml-3">
              <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                <label className='formslabels' >
                  Loss of pay amount deducted as per<span><RiErrorWarningFill/></span> 
                </label>
              </div>
                <br />
              <div className="d-flex flex-wrap mt-3 gap-14 ml-2  radio-color">
              {list2.titles.map((title) =>
              <Form>
               {['radio'].map((type) => (
                        <Form.Check
                         inline
                         label={title.label}
                         name={title.name}
                         type={type}
                         id={title.id}
                         className="calc-radio d-flex "
                         onChange={(e) => setLossDeducted(e.target.value)}
                         value={title.id}
                         checked={lossDeducted === title.id}
                    />
                    
                ))} 
               </Form>)}
              </div>
            </div>

            <div className="row ml-3">
              <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                <label className='formslabels' >
                  Display payslip in Employee Portal<span><RiErrorWarningFill/></span>
                </label>
              </div>
                <br />
              <div className="d-flex flex-wrap mt-3 gap-36 ml-2  radio-color">
              {list3.titles.map((title) =>
              <Form>
               {['radio'].map((type) => (
                        <Form.Check
                         inline
                         label={title.label}
                         name={title.name}
                         type={type}
                         id={title.id}
                         className="calc-radio d-flex "
                         onChange={(e) => setPaySlip(e.target.value)}
                         value={title.id}
                         checked={paySlip === title.id}
                    />
                    
                ))} 
               </Form>)}
              </div>
            </div>

            <div className="row ml-3">
              <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                <label className='formslabels' >
                  Manual Workday in Payrun<span><RiErrorWarningFill/></span>
                </label>
              </div>
                <br />
              <div className="d-flex flex-wrap mt-3 gap-36 ml-2  radio-color">
              {list4.titles.map((title) =>
              <Form>
               {['radio'].map((type) => (
                        <Form.Check
                         inline
                         label={title.label}
                         name={title.name}
                         type={type}
                         id={title.id}
                         className="calc-radio d-flex "
                         onChange={(e) => setManualWorkday(e.target.value)}
                         value={title.id}
                         checked={manualWorkday === title.id}
                    />
                    
                ))} 
               </Form>)}
              </div>
            </div>

            <div className="row ml-3">
              <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                <label className='formslabels' >
                  Employee Salary Edit Mail Approval Needed<span><RiErrorWarningFill/></span>
                </label>
              </div>
                <br />
              <div className="d-flex flex-wrap mt-3 gap-52 ml-2  radio-color">
              {list5.titles.map((title) =>
              <Form>
               {['radio'].map((type) => (
                        <Form.Check
                         inline
                         label={title.label}
                         name={title.name}
                         type={type}
                         id={title.id}
                         className="calc-radio d-flex "
                         onChange={(e) => setMailApproval(e.target.value)}
                         value={title.id}
                         checked={mailApproval === title.id} 
                    />
                    
                ))} 
               </Form>)}
              </div>
            </div>

            <div className="row ml-3">
              <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                <label className='formslabels' >
                  Payslip Entries with zero values should be removed<span><RiErrorWarningFill/></span>
                </label>
              </div>
                <br />
              <div className="d-flex flex-wrap mt-3 gap-52 ml-2">
              {list6.titles.map((title) =>
              <Form>
              <div className="mt-3 other-config  radio-color">
               {['checkbox'].map((type) => (
                        <Form.Check
                         inline
                         label={title.label}
                         name={title.name}
                         type={type}
                         id={title.id}
                         className="calc-radio d-flex "
                         onChange={(e) => setMailApproval(e.target.value)}
                         value={title.id}
                         checked={mailApproval === title.id} 
                    />
                    
                ))} 
               </div>
               </Form>)}
              </div>
            </div>

            <div className="row ml-3">
              <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                <label className='formslabels' >
                 Deducting Professional Tax from Employee Income as per “PT Slab”<span><RiErrorWarningFill/></span>
                </label>
              </div>
                <br />
              <div className="d-flex flex-wrap mt-3 gap-52 ml-2">
              {list7.titles.map((title) =>
              <Form>
              <div className="mt-3 other-config radio-color">
               {['checkbox'].map((type) => (
                        <Form.Check
                         inline
                         label={title.label}
                         name={title.name}
                         type={type}
                         id={title.id}
                         className="calc-radio d-flex "
                         onChange={(e) => setMailApproval(e.target.value)}
                         value={title.id}
                         checked={mailApproval === title.id} 
                    />
                    
                ))} 
               </div>
               </Form>)}
              </div>
            </div>
            </>

  )
}

export default ListForm;