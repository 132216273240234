import React from "react";
import CardLink from "../../components/CardLink";
import { useSelector } from "react-redux";

const Settings = () => {
  const theme = useSelector((state) => state.theme.theme);
  return (
    <div
      className="main-content bg-primaryLight2 dark:bg-primaryDark1"
    >
      <div className="admin-panel_content">
        <div className="cardlink-row">
          <div className="admin-panel__box-container flex flex-row flex-wrap gap-8">
            <CardLink
              className={"admin-panel__cards"}
              title="Master Data"
              type="masterdata"
              link="master-data/department"
            />
            <CardLink
              className={"admin-panel__cards"}
              title="Salary Components"
              type="money"
              link="salary/earnings"
            />
            <CardLink
              className={"admin-panel__cards"}
              title="Statuatory Components"
              type="statuatory"
              link="statutory/esi"
            />
            <CardLink
              className={"admin-panel__cards"}
              title="TAXES"
              type="tax"
              link="taxes"
            />
            <CardLink
              className={"admin-panel__cards"}
              title="Schedule Payment"
              type="schedule-payment"
              link="schedule-payment"
            />
            <CardLink
              className={"admin-panel__cards"}
              title="TEMPELATES"
              type="tempelate"
              link="templates/salary"
            />
            {/* <CardLink
              className={"admin-panel__cards"}
              title="USER"
              type="profile"
              link="user/profile"
            /> */}

          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
