import React from 'react';
import { PDFViewer, Document, Page, Text, View, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
import PageTitle from '../../../../components/PageTitle';

// Define styles for PDF
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 20,
  },
  section: {
    marginBottom: 10,
    textAlign:"center",
    gap:"6rem",
  },
  heading: {
    fontSize: 18,
    textAlign:"center",
    fontWeight: 'heavy',
    marginBottom: 10,
    color:"green"
  },
  content: {
    fontSize: 12,
    marginBottom: 5,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  row: { 
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderRightWidth: 1,
  },
  cellHeader: {
    width: '25%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
  },
  cell: {
    width: '25%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
  },
});

const PDFDocument = () => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.heading}>Payslip</Text>
        <Text>Zoonodle Inc</Text>
        <Text>201324 Pearson point Road Gateway Avenue</Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.heading}>Employee Details</Text>
        <Text>Date Of Joining : 20th September, 2024</Text>
        <Text>Employee Name : Anshika Yadav</Text>
        <Text>Pay Period : 20th August, 2024</Text>
        <Text>Designation : Marketing Executive</Text>
        <Text>Worked Days : 120</Text>
        <Text>Department : Marketing</Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.heading}>Earnings and Deductions</Text>
        <View style={styles.table}>
          <View style={styles.row}>
            <View style={styles.cellHeader}><Text>Earnings</Text></View>
            <View style={styles.cellHeader}><Text>Amount</Text></View>
            <View style={styles.cellHeader}><Text>Deductions</Text></View>
            <View style={styles.cellHeader}><Text>Amount</Text></View>
          </View>
          <View style={styles.row}>
            <View style={styles.cell}><Text>Basic</Text></View>
            <View style={styles.cell}><Text>1000</Text></View>
            <View style={styles.cell}><Text>Provident Fund</Text></View>
            <View style={styles.cell}><Text>1200</Text></View>
          </View>
          <View style={styles.row}>
            <View style={styles.cell}><Text>Basic</Text></View>
            <View style={styles.cell}><Text>1000</Text></View>
            <View style={styles.cell}><Text>Provident Fund</Text></View>
            <View style={styles.cell}><Text>1200</Text></View>
          </View>
          <View style={styles.row}>
            <View style={styles.cell}><Text>Basic</Text></View>
            <View style={styles.cell}><Text>1000</Text></View>
            <View style={styles.cell}><Text>Provident Fund</Text></View>
            <View style={styles.cell}><Text>1200</Text></View>
          </View>
        </View>
      </View>
    </Page>
  </Document>
);

const App = () => {
  return (
    <div className='w-full my-2'>
      <PageTitle title="Download Salary Slip" route={"../.."}/>
    <div >
      <PDFViewer width="100%" height="100%" style={{width:"100%", height:"70rem", marginBottom:"3rem"}}>
        <PDFDocument />
      </PDFViewer>
      <PDFDownloadLink document={<PDFDocument />} fileName="payslip.pdf" className='my-20 bg-red-500 text-white'>
        {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download PDF')}
      </PDFDownloadLink>
    </div>
    </div>
  );
};

export default App;

// import React from 'react'
// import { useSelector } from 'react-redux';

// const SalaryComparison = () => {
//   const theme =  useSelector((state) => state.theme.theme);

//   return (
//     <main className={"main-content " + `${theme == "light" ? "light_bg_main" : ""}`}>

//     </main>
//   )
// }

// export default SalaryComparison;