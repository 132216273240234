import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NotificationBox from "../../components/NotificationBox/NotificationBox";
import { fetchAllNotifications } from "../../reducers/notificationsReducer";
import { getNotifications } from '../../services/notifications'

const Notification = () => {
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notification.data)

  // const notifications = [
  //   {
  //     id: 1,
  //     title: "New Message",
  //     time: "2024-05-22T14:33:00Z",
  //     message: "You have received a new message from John.",
  //     type: "message",
  //   },
  //   {
  //     id: 2,
  //     title: "System Update",
  //     time: "2024-05-21T09:15:00Z",
  //     message: "The system will be updated at midnight tonight.",
  //     type: "update",
  //   },
  //   {
  //     id: 3,
  //     title: "Event Reminder",
  //     time: "2024-05-20T16:45:00Z",
  //     message: "Don't forget the team meeting tomorrow at 10 AM.",
  //     type: "reminder",
  //   },
  //   {
  //     id: 4,
  //     title: "New Comment",
  //     time: "2024-05-19T11:22:00Z",
  //     message: "Alice commented on your post.",
  //     type: "comment",
  //   },
  //   {
  //     id: 5,
  //     title: "Subscription Renewal",
  //     time: "2024-05-18T08:00:00Z",
  //     message: "Your subscription will renew automatically on June 1st.",
  //     type: "subscription",
  //   },
  // ];



  return (
    <div className="main-content">
      <div className="notification-content">
        <h2 className="web-app__heading pb-6">Notifications</h2>

        <div className="container flex flex-col w-full ">
          {notifications?.length === 0 && <label className="mx-4 mt-12 notification__title">No notifications yet</label>}
          {notifications?.map((data) => (
            <NotificationBox
              title={data.title}
              // time={data.time.slice(0, 10)}
              time={data.date}
              description={data.description}
              type={data.type}
              key={data.id}
              link={data.link}
            />
          ))
          }
        </div>
      </div>
    </div>
  );
};

export default Notification;
