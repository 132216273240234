import React, { useMemo } from 'react'
import './subdivision.css'
import { Link } from 'react-router-dom'
import Table from '../../../components/Table'
import { FaPen } from "react-icons/fa";
import { useSelector } from 'react-redux'
import Select from 'react-select'
import { adminStyles, adminStylesLight } from '../../../utils/selectStyles'


const SubDivision = () => {

   const theme = useSelector((state) => state.theme.theme);

  const tableData = [
    {SubDivision: 'Development' , SubDivisionCode: '401' }
  ];

  
     
const columns = useMemo(
  () => [
    {
      Header: "Sub Division",
      accessor: "SubDivision",
    },
    {
      Header: "Sub Division Code",
      accessor: "SubDivisionCode",
    },
    {
      Header: "Action",
      accessor: "Action",
      disableFilters: true,
      disableSortBy: true,
      Cell: (props) => (
        <span
          className="react-table-view-link"
          onClick={() => redirectToDetails(props.row.original)}
        >
          <FaPen className="icon-color-tertiary" />
        </span>
      ),
    },
  ],
  [],
);


  return (
     <main className={"main-content " + `${theme == "light" ? "light_bg_main" : ""}`} style={{width:'100%'} }>
        <div className='department-div'>
       <div className={theme === 'light' ? 'inner-department-div' : "" }>
       <div className={'mt-2 ' + `${theme == "light" ? "light_bg_logo" : ""}`}>
         <h3 className={`${theme == "light" ? "light_bg_logo" : ""}`}>DIVISION</h3>
       </div>

       <div className='select-div'>
       <Select
          styles={theme == "light" ? adminStylesLight : adminStyles}
          placeholder="All"
          className='select-all'      
         />

   
       <div className='button-div'>
          <Link to={'/master-card/add-subdivision'}>
           <button className='pre'>ADD SUB DIVISION</button>
          </Link>
      </div>
      </div>
      </div>
      </div>

      <div className='inside-containt'>
        <section className="area-creation-content__info py-0 p-1">
          <div className={"filter-table " + `${theme === 'light' ? "table_light" : ""}`}>
            {tableData.length > 0 ? (
              <Table columns={columns} data={tableData} />
            ) : (
              <h3 className="no-results-message">Nothing To Show !!</h3>
            )}
          </div>
        </section>
      </div>
     </main>
  )
}

export default SubDivision;