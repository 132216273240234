import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { FaEye } from 'react-icons/fa'
import { MdOutlineEdit } from 'react-icons/md';
import './leave.css'
import Select from 'react-select'
import { useSelector } from 'react-redux'
import { adminStyles, adminStylesLight } from '../../../utils/selectStyles';
import Table from '../../../components/Table';
import { getAllLeaves } from '../../../services/leave';
import customToast from '../../../components/CustomToast';
import { addIndex } from '../../../utils/serialiseResponse';
import { generateSelectData } from '../../../utils/serialiseResponse'
import PageTitle from '../../../components/PageTitle';
const LeaveType = () => {
  const theme = useSelector((state) => state.theme.theme);
  const [tableData, setTableData] = useState([]);
  const [lType, setLtype] = useState([]);
  const [leaves, setLeaves] = useState([]);
  useEffect(() => {
    getAllLeaves().then((res) => setTableData(res?.data))
      .catch(() => customToast.error("Error Occurred"));

  }, []);

  const leaveTypes = useMemo(
    () => generateSelectData(leaves, "leave_Name"),
    [leaves]
  );

  const tableData2 = useMemo(() => addIndex(tableData), [tableData]);
  // let tData=[];
  //  useEffect(()=>{
  //   leaves.forEach((el)=>{
  //     if(el.leave_Name===lType.label){
  //       tData.push(el)
  //     }
  //     setTableData(tData);
  //   })

  //  },[lType]);


  const leaveNames = [
    { value: 1, label: "Paid" },
    { value: 2, label: "Unpaid" }
  ]

  const columns = useMemo(
    () => [
      {
        Header: "Sr No.",
        accessor: "sr",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Leave Type",
        accessor: "leave_Name",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Leave Unit",
        accessor: "leave_unit",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return props?.row?.original?.leave_type?.label || props?.row?.original?.leave_type
        }
      },
      {
        Header: "Leave Code",
        accessor: "leave_code",
        disableFilters: true,
        disableSortBy: true,
      },
      
      {
        Header: "Actions",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          // console.log(props?.row?.original)
          return <Link to="/attendance/manage-leave/add" state={props?.row?.original}><div className='flex  justify-center '><MdOutlineEdit className='items-center icon-color-green' /></div></Link>
        }
      },

    ], []
  );



  return (
    <main className="main-content  bg-primaryLight2 dark:bg-primaryDark1">
      
      <div className=" bg-whiteLight dark:!bg-primaryDark2 creation-content">
        <PageTitle title="Leave TYpe" route='../../'/>
      


      {/* <div className='button-div'>
        <Link to={'/attendance/manage-leave/add'}>
          <button className='pre'>ADD LEAVE TYPE</button>
        </Link>
      </div> */}


      {/* <div className='inside-containt'> */}
        <section className={"cardlink-row pb-6"}>
          <div className='flex justify-between items-center'>
          <h2 className={"mt-5 pt-5 ms-2 web-app__heading " + `${theme === 'light' ? "light_bg_logo" : ""}`}>
            Showing ({tableData2.length}) Entries
          </h2>
          <div className='button-div'>
        <Link to={'/attendance/manage-leave/add'}>
          <button className='pre'>ADD LEAVE TYPE</button>
        </Link>
      </div>

          </div>
          <div className={"filter-table "}>
            <Table columns={columns} data={tableData2} />
          </div>
        </section>

      {/* </div> */}
      </div>
    </main>
  )
}

export default LeaveType;