import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setCurrentStep } from "../../../reducers/setUpCurrentStepReducer";
import { useNavigate } from "react-router-dom";
import Esi from "./Esi";
import Epf from "./Epf";
import ProfessionalTax from "./ProfessionalTax";
import Lwf from "./Lwf";
import customToast from "../../../components/CustomToast";
import { addStatutoryComponents } from "../../../services/hrms";
import { ToastContainer } from "react-toastify";
import { PostEarningTypes } from "../../../services/earning";
import { PostReimbursementTypes } from "../../../services/reimbursement";
import { PostDeductionTypes } from "../../../services/deduction";

const StatutoryComponents = () => {
  const CURRENT_STEP = 4;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setCurrentStep(CURRENT_STEP));
  }, []);

  const [data, setData] = useState([
    { earningType: "Basic" },
    {
      earningType: "HRA",
    },
    {
      earningType: "Conveyance Allowance",
    },
    {
      earningType: "Transport Allowance",
    },
    {
      earningType: "Travelling Allowance",
    },
    {
      earningType: "Bonus",
    },
    {
      earningType: "Commission",
    },
    {
      earningType: "Fixed Allowance",
    },
    {
      earningType: "Gratuity",
    },
    {
      earningType: "Notice Pay",
    },
    {
      earningType: "Leave Encashment",
    },
  ]);

  const [deductionData, setDeductionData] = useState([
    {
      deductionType: "Voluntary Provident Fund",
      categorie: "Pre-Tax Deduction",
    },
    {
      deductionType: "Notice Pay Deduction",
      categorie: "Post-Tax Deduction",
    },
  ]);

  const [reimbdata, setReimbData] = useState([
    {
      reimbursementType: "Fuel",
    },
    {
      reimbursementType: "Driver",
    },
    {
      reimbursementType: "Phone",
    },
    {
      reimbursementType: "Leave Travel",
    },
  ]);

  useEffect(() => {
    const add = async () => {
      await PostEarningTypes(data);
      await PostReimbursementTypes(reimbdata);
      await PostDeductionTypes(deductionData);
    };
    add();
  }, []);

  const [esi, setEsi] = useState({
    docName: "esiInfo",
    enableEsi: false,
    esiNumber: "",
    deductionType: null,
    employerContributionRate: null,
    employeeContributionRate: null,
    includeEmployersContribution: false,
    overridePFContributionRate: false,
  });
  const [epf, setEpf] = useState({
    docName: "epfInfo",
    enableEpf: false,
    epfNumber: "",
    deductionType: null,
    employerContributionRate: null,
    employeeContributionRate: null,
    calculatOnProDataBasis: false,
    allSalaryComponentsConsideration: false,
    includeEmployersContribution: false,
    addPfAdminChargesInCtc: false,
    addEmployersEdliContributionInCtc: false,
    componentOnPayslip: false,
  });
  const [professionalTax, setProfessionalTax] = useState({
    docName: "professionalTaxInfo",
    enableProfessionalTax: false,
    PtNumber: "",
    deductionType: null,
    state: null,
    includeEmployersContribution: false,
  });

  const [lwf, setLwf] = useState({
    docName: "lwfInfo",
    enableLwf: false,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        esi,
        epf,
        professionalTax,
        lwf,
      };
      await addStatutoryComponents(payload);
      customToast.success("Statutory components saved successfully");
      navigate("/setup/salary-components");
    } catch (error) {
      customToast.error("Something went wrong, please try again later");
    }
  };

  const handleSkip = (e) => {
    navigate("/setup/salary-components");
  };

  return (
    <>
      <p>
        Set up ESI, EPF and Professional Tax and <br /> Labour Welfare Fund
      </p>
      <Esi esi={esi} setEsi={setEsi} />
      <Epf epf={epf} setEpf={setEpf} />
      <ProfessionalTax
        professionalTax={professionalTax}
        setProfessionalTax={setProfessionalTax}
      />
      <Lwf lwf={lwf} setLwf={setLwf} />

      <div className="mt-4">
        <button className="submit_button" onClick={handleSubmit}>
          Save & Continue
        </button>
        <span className="skip" onClick={handleSkip}>
          Skip For Now
        </span>
      </div>
      <ToastContainer />
    </>
  );
};

export default StatutoryComponents;
