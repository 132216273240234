import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: 3,
};

const setUpCurrentStepReducer = createSlice({
  name: "setUpCurrentStepReducer",
  initialState,
  reducers: {
    setCurrentStep: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setCurrentStep } = setUpCurrentStepReducer.actions;
export default setUpCurrentStepReducer.reducer;
