import { customAxios } from "../utils/axiosinstance"

export const CreateDeduction = async(payload) => {
    const {data} = await customAxios.post(`/hrms/deductions/` , payload )
    return data;
}
export const postTax = async(payload) => {
    const {data} = await customAxios.post(`/hrms/taxes/` , payload )
    return data;
}
export const get80Cschemes = async() =>{
    const {data} = await customAxios.get('/hrms/taxes/80C');
    return data;
}
export const get80Dschemes = async() =>{
    const {data} = await customAxios.get('/hrms/taxes/80D');
    return data;
}
export const getOtherInvestments = async() =>{
    const {data} = await customAxios.get('/hrms/taxes/other-investments');
    return data;
}
export const addPOI = async(payload) => {
    const {data} = await customAxios.post(`/hrms/taxes/add-POI` , payload )
    return data;
}
export const AddDeduction = async(payload) => {
    const {data} = await customAxios.post(`/hrms/deductions/add` , payload )
    return data;
}

export const GetAllDeduction = async() => {
    const {data} = await customAxios.get(`/hrms/deductions/getAllDeduction` )
    return data;
}

export const GetAllActiveDeduction = async() => {
    const {data} = await customAxios.get(`/hrms/deductions/get-all-active` )
    return data;
}

export const GetDeductionTypes = async(payload) => {
    const {data} = await customAxios.get(`/hrms/deductions/types` , payload )
    return data;
}

export const AddDeductionTypes = async(payload) => {
    const {data} = await customAxios.post(`/hrms/deductions/types` , payload )
    return data;
}

export const GetPreDeduction = async() => {
    const {data} = await customAxios.get(`/hrms/deductions/getPreDeduction` )
    return data;
}

export const GetPostDeduction = async() => {
    const {data} = await customAxios.get(`/hrms/deductions/getPostDeduction` )
    return data;
}

export const UpdateDeduction = async(id, payload) => {
    const { data } = await customAxios.post(`/hrms/deductions/update/${id}`, payload);
    return data;
}

export const DeleteDeduction = async(payload) => {
    const { data } = await customAxios.post(`/hrms/deductions/delete`, payload);
    return data;
};

export const PostDeductionTypes = async(payload) => {
    const {data} = await customAxios.post(`/hrms/deductions/alltypes` , payload )
    return data;
}