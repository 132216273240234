import React, { useEffect, useMemo, useState } from 'react';
import PageTitle from '../../../../../components/PageTitle';
import { adminStyles, adminStylesLight } from '../../../../../utils/selectStyles';
import Select from "react-select";
import { useDispatch, useSelector } from 'react-redux';
import {GetDeductionTypes, UpdateDeduction} from '../../../../../services/deduction';
import customToast from "../../../../../components/CustomToast";
import { generateSelectData } from '../../../../../utils/serialiseResponse';
import { useLocation, useNavigate } from 'react-router-dom';
import { updateDeduction } from '../../../../../reducers/deductionReducer';

const deductionFrequencyOptions = [
  { value: 'recurring', label: 'Recurring' },
  { value: 'one-time', label: 'One-time' }
];

const EditDeduction = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [config, setConfig] = useState([]);
  const [deductionType, setDeductionType] = useState("");
  const [deductionFrequency, setDeductionFrequency] = useState("");
  const [paySlipName, setPaySlipName] = useState("");
  const [active, setActive] = useState(false);
  const [deductionData, setDeductionData] = useState([]);
  const [employerContribution , setEmployerContribution] = useState(false);
  const [proRata , setProRata] = useState(false);
  const theme = useSelector((state) => state.theme.theme);

  const deductionSelect = useMemo(() => generateSelectData(deductionData, "name"), [deductionData]);
  
  useEffect(() => {
    let defType = deductionSelect.find((el) => el?.name.toLowerCase() === location?.state?.deductionType.toLowerCase())
    let defFrequency = deductionFrequencyOptions.find((el) => el?.label.toLowerCase() === location?.state?.deductionFrequency.toLowerCase())
    setDeductionFrequency(defFrequency || location?.state?.deductionFrequency);
    setDeductionType(defType || location?.state?.deductionType);
    setActive(location?.state?.isActive);
    setPaySlipName(location?.state?.nameOnPaySlip);
  },[location, deductionSelect]);


  useEffect(() => {
    async function fetchDeductionTypes() {
      try {
        const { data } = await GetDeductionTypes();
        setDeductionData(data);
      } catch (error) {
        customToast.error("Sorry Something Went Wrong!");
      }
    }
    fetchDeductionTypes();
  }, []);

  const clearStates = () =>{
    setDeductionFrequency(null);
    setDeductionType(null);
    setPaySlipName(null);
    setActive(false);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      name: deductionType?.label,
      deductionType: deductionType?._id,
      nameOnPaySlip: paySlipName,
      deductionFrequency: deductionFrequency?.label,
      isActive:active,
    };
    let id = location?.state?._id;

    try {
      await UpdateDeduction(id, payload);
      // dispatch(updateDeduction(id, payload));
      clearStates();
      navigate("..");
      customToast.success("Deduction updated successfully !");
    } catch (error) {
      console.log(error);
      customToast.error("error while updating deduction !");
    }

  };

  const handleActive = () => {
    setActive(active === "active" ? "In-active" : "active");
  };

  return (
   
    <div
      className={"main-content " + `${theme == "light" ? "light_bg_main" : ""}`}
      style={{ width: "100%" }}
    >
      <div
        className={
          "creation-content " + `${theme == "light" ? "light_bg" : ""}`
        }
      >
        <PageTitle title="Edit Deduction" route={'..'} />

        <section className="admin-content">
          <form onSubmit={(e) => handleFormSubmit(e)}>
            <div className="row">
              <div>
                <label className='dark:text-primaryDark1 uppercase'>
                  Deduction Type<span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  options={deductionSelect}
                  styles={theme === "light" ? adminStylesLight : adminStyles}
                  placeholder="Select Type"
                  onChange={(selectedOption) => setDeductionType(selectedOption)}
                  value={deductionType}
                  // className='mt-4 min-w-[27rem] max-w-[27rem]'
                  className='mt-4'
                />
              </div>

              <div>
                <label htmlFor="mName" className='dark:text-primaryDark1 uppercase'>
                  Deduction Frequency
                </label>
                <br />
                <Select
                  options={deductionFrequencyOptions}
                  styles={theme === "light" ? adminStylesLight : adminStyles}
                  placeholder="Select Frequency"
                  onChange={(selectedOption) => setDeductionFrequency(selectedOption)}
                  value={deductionFrequency}
                  className='mt-4'
                />
              </div>

              <div>
                <label className='dark:text-primaryDark1 uppercase'>
                  Name on Payslip <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                  className='mt-4'
                  placeholder="Enter Name"
                  onChange={({ target }) => setPaySlipName(target.value)}
                  value={paySlipName}
                />
              </div>
            </div>

            <div className='flex gap-3 mx-8'>
              <input
                type='checkbox'
                checked={active}
                onChange={(e) => active ? setActive(false) : setActive(true)}
                className='check-box-active blue_b w-6 h-6'
              />
              <label className='text-2xl'>Mark this as Active</label>
            </div>

            <div className='mx-8 mt-6 pb-10'>
              <button type="submit" className="button-blue-gradient">
                Update
              </button>
            </div>
          </form>
        </section>
      </div>
    </div>
  )
}

export default EditDeduction;