import { useState, useEffect, useMemo } from "react";
import PageTitle from "../../../../components/PageTitle";
import { adminStyles, adminStylesLight, multiSelectStyles } from "../../../../utils/selectStyles";
import Select from "react-select";
import { useLocation } from "react-router-dom";
import './addleave.css';
import { useSelector, useDispatch } from "react-redux";
import { MdOutlineCheckBoxOutlineBlank, MdOutlineCheckBox } from "react-icons/md"
import { monthOptions, yearOptions } from "../../../../utils/helper";
import { generateSelectData, addIndex, generateMultiSelectData } from "../../../../utils/serialiseResponse";
import { fetchEmployees, clearEmployees } from "../../../../reducers/employeesReducer";
import { setDesignations, clearDesignations } from "../../../../reducers/designationReducer";
import { setDepartments } from "../../../../reducers/departmentReducer";
import { addLeave, getAllLeaves, editLeave } from "../../../../services/leave";
import customToast from "../../../../components/CustomToast";
import { MdAddBox } from "react-icons/md";
import { FaSquareMinus } from "react-icons/fa6";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { setHeadquarters } from "../../../../reducers/locationReducer";

const divisionOptions = [
  { value: 0, label: "Bhopal" },
  { value: 1, label: "Guna" },
  { value: 2, label: "other" },
];

const loanOptions = [
  { value: 0, label: "Home loan" },
  { value: 1, label: "Personal loan" },
  { value: 2, label: "other" },
]


const marketingOptions = [
  { value: 0, label: "Home loan" },
  { value: 1, label: "Personal loan" },
  { value: 2, label: "other" },
]


const departmentOptions = [
  { value: 0, label: "Home loan" },
  { value: 1, label: "Personal loan" },
  { value: 2, label: "other" },
]

const appliedOnDayOptions = [
  { value: 0, label: "Restricted Holiday" },
  { value: 1, label: "Date of joining" },
  { value: 2, label: "Birth Date" },
]

const leaveTypes = [
  { value: 1, label: "Paid" },
  { value: 2, label: "Unpaid" },
]

const leaveUnits = [
  { value: 1, label: "Hours" },
  { value: 2, label: "Days" },
]

const accrual = [
  {
    id: 1,
    label: 'accrual',
  },
]

const Reset = [
  {
    id: 1,
    label: 'Reset',
  },
]

const monthsOptions = [
  { value: 2, label: "2 Months" },
  { value: 6, label: "6 Months" },
  { value: 12, label: "12 Months" },
]

const JoiningFrom = [
  { value: 1, label: "Date of Joining" },
  { value: 2, label: "Date of Confirmation" }
]

const accrualMonthOptions = [
  { value: 1, label: "Monthly" },
  { value: 2, label: "Quarterly" },
  { value: 3, label: "Half Yearly" },
  { value: 4, label: "Yearly" },
];

const CarryFwdOptions = [
  { value: 0, label: "Carry Forward" },
  { value: 1, label: "Carry Forward With Expiry" },
  { value: 2, label: "Carry Forward With Overall Limit" },
];

const unitOptions = [
  { value: 0, label: "Units" },
  { value: 1, label: "Percentage" }
];

const allowLimitOptions = [
  { value: 1, label: "Without Limit" },
  { value: 2, label: "Until year end limit" },
  { value: 3, label: "Without limit and Mark as LOP" },
];

const durationOptions = [
  { value: 1, label: "Full Day" },
  { value: 2, label: "Half Day" },
  { value: 3, label: "Quarter Day" },
  { value: 4, label: "Hourly" },
];

const genders = [
  {
    value: 1,
    label: 'Male',
  },
  {
    value: 2,
    label: 'Female',
  },
  {
    value: 3,
    label: 'Others',
  },
]


const maritalstatus = [
  {
    value: 1,
    label: 'Unmarried',
  },
  {
    value: 2,
    label: 'Married',
  },
];

const accrualOptions = [
  {
    id: 1,
    label: 'Yes',
  },
  {
    id: 2,
    label: 'No',
  },
]

const month_yr_Option = [
  { value: 0, label: "Day(s)" },
  { value: 1, label: "Month(s)" },
  { value: 2, label: "Year(s)" }
]

const prorateOptions = [
  { value: 1, label: "Start of Policy" },
  { value: 2, label: "Start and End of Policy" },
  { value: 3, label: "Do not Prorate" },
]

const accOptions = [
  { value: 1, label: "Current Accrual" },
  { value: 2, label: "Next Accrual" }
]

const carryFrwdExpireOption = [
  { value: 0, label: "Day(s)" },
  { value: 1, label: "Month(s)" },
]

const AddLeave = () => {
  const locations = useLocation();
  const dispatch = useDispatch();

  // console.log("first--->",locations.state?);
  const theme = useSelector((state) => state.theme.theme);
  // const dispatch=useDispatch();
  const [leaves, setLeaves] = useState([]);

  const [lName, setLname] = useState("");
  const [lCode, setLcode] = useState("");
  const [fDate, setFDate] = useState(null);
  const [tDate, setTdate] = useState("");
  const [lType, setLType] = useState(null);
  const [lUnit, setLUnit] = useState(null);
  const [duration, setDuration] = useState(null);
  const [description, setDescription] = useState("");
  const [box, setBox] = useState('Applicablility');
  const [totaldays, setTotaldays] = useState(0);
  const [isaccrual, setIsaccrual] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [effOption, seteffOption] = useState(month_yr_Option[2]);
  const [effectAfter, setEffAfter] = useState(JoiningFrom[0]);
  const [effectAfterDays, setEffAfterDays] = useState(0);
  const [encashPerc, setencashPerc] = useState(0);
  const [encashLimit, setEncashLimit] = useState(0);
  const [encashUnit, setencashUnit] = useState(unitOptions[1]);
  const [caryyFrwdPer, setcaryyFrwdPer] = useState(0);
  const [carryFrwdExpireUnit, setcarryFrwdExpireUnit] = useState(carryFrwdExpireOption[1])
  const [carryFrwdExpireVal, setcarryFrwdExpireVal] = useState(0);
  const [accNoOfDay, setaccNoOfDay] = useState(0);
  const [effYearly, seteffYearly] = useState(0);

  const [accrualOption, setAccrualOption] = useState(accrualMonthOptions[3]);
  const [resetOption, setResetOption] = useState(accrualMonthOptions[3]);
  const [monthAccrual, setmonthAccrual] = useState(null);
  const [accrualType, setAccrualType] = useState(accOptions[0]);
  const [accrualDate, setAccrualDate] = useState(null);
  const [resetDate, setResetDate] = useState(null);
  const [monthReset, setMonthReset] = useState(null);
  const [accrualDays, setAccrualDays] = useState(0);
  const [carryFrwdUnit, setcarryFrwdUnit] = useState(unitOptions[1]);
  const [carryFrwd, setCarryFrwd] = useState(CarryFwdOptions[0]);
  const [carryFrwdLimit, setcarryFrwdLimit] = useState(0);
  const [carryFrwdOverallLimit, setcarryFrwdOverallLimit] = useState(0);
  const [togetheWith, setTogetheWith] = useState([]);
  const [aplliedOn, setaplliedOn] = useState(null);
  const [durationAllowed, setDurationAllowed] = useState(null);
  const [config, setConfig] = useState({});
  const [wleave, setWLeave] = useState(null);
  const [hleave, setHLeave] = useState(null);
  const [wCountLimit, setWCountLimit] = useState(0);
  const [holCount, setHolCount] = useState(0);
  const [restrictCnt, setRestrictCnt] = useState(0);
  const [restrict, setRestrict] = useState(false);
  const [leaveBal, setLeaveBal] = useState("");
  const [allowLimit, setAllowLimit] = useState({});
  const [durationLimit, setDurationLimit] = useState({});
  const [pastDays, setPastDays] = useState(false);
  const [futureDays, setFutureDays] = useState(false);
  const [minLeaveToggle, setMinLeaveToggle] = useState(false);
  const [minLeaveDays, setMinLeaveDays] = useState(0);
  const [maxLeaveToggle, setMaxLeaveToggle] = useState(false);
  const [maxLeaveDays, setMaxLeaveDays] = useState(0);
  const [maxConseToggle, setMaxConseToggle] = useState(false);
  const [fileUploadToggle, setFileUploadToggle] = useState(false);
  const [maxConsDays, setMaxConsDays] = useState(0);
  const [fileUploadDays, setfileUploadDays] = useState(0);
  const [minGapToggle, setMinGapToggle] = useState(false);
  const [minGapDays, setMinGapDays] = useState(0);
  const [maxLeavePerYr, setMaxLeavePerYr] = useState(0);
  const [designation, setDesignation] = useState({ value: "all", label: "All" });
  const [department, setDepartment] = useState({ value: "all", label: "All" });
  const [job, setJob] = useState(null);
  const [city, setCity] = useState(null);
  // const [state, setState] = useState(null);
  const [subDivision, setSubDivision] = useState(null);
  const [employee, setEmployee] = useState({ label: "All", value: "all" });
  const [selectedGender, setSelectedGender] = useState({ value: "all", label: "All" });
  const [selectedMaritalStatus, setSelectedMaritalStatus] = useState({ value: "all", label: "All" });
  const [hq, setHq] = useState({ value: "all", label: "All" });
  const [addProrate, setAddProrate] = useState(false);
  const [prorateLabel, setProrateLabel] = useState(null);
  const [entitleDrpDwn, setEntitleDrpDwn] = useState(false);
  const [applicableDrpDwn, setApplicableDrpDwn] = useState(false);
  const [restrictDrpDwn, setRestrictDrpDwn] = useState(false);
  const [maxLeavePerYrOption, setmaxLeavePerYrOption] = useState(month_yr_Option[2])
  // const [locations.state?Id,setPrevDataId]=useState(null);
  const designations = useSelector(({ designations }) => designations);
  const location = useSelector(({ location }) => location);
  const departments = useSelector(({ department }) => department);
  const employees = useSelector(({ employees }) => employees);
  
  const resetForm = async () => {
    setLname("");
    setLcode("");
    setLUnit(null);
    setLType(null);
    setFDate("");
    setTdate("");
    setDescription("");
    setEffAfterDays(0);
    setAccrualOption(accrualMonthOptions[3]);
    setResetOption(accrualMonthOptions[3]);
    setTotaldays(0);
    setIsaccrual(false);
    setIsReset(false);
    setEffAfter(JoiningFrom[0]);
    seteffOption(month_yr_Option[2]);
    setaccNoOfDay(0);
    setAccrualType(accOptions[0]);
    setcarryFrwdUnit(unitOptions[1]);
    setcarryFrwdExpireUnit(carryFrwdExpireOption[1]);
    setcarryFrwdExpireVal(0);
    setcaryyFrwdPer(0);
    setMonthReset(null);
    // setAccrualMonth(accrualMonthOptions[0]);
    // setResetMonth({});
    setmonthAccrual(null);
    setAccrualOption(null);
    setAccrualDate(null);
    setResetDate(null);
    setCarryFrwd(CarryFwdOptions[0]);
    setEncashLimit(0);
    setencashUnit(unitOptions[1]);
    setcarryFrwdLimit(0);
    setcarryFrwdOverallLimit(0);
    setaplliedOn(null);
    setTogetheWith(null);
    setWLeave(null);
    setHLeave(null);
    setWCountLimit(0);
    setHolCount(0);
    setRestrictCnt(0);
    setRestrict(false);
    setLeaveBal("");
    setAllowLimit({});
    setfileUploadDays(0);
    setDurationLimit({});
    setPastDays(false);
    setFutureDays(false);
    setMinLeaveDays(0);
    setMinLeaveToggle(false);
    setMaxLeaveDays(0);
    setMaxLeaveToggle(false);
    setMaxConsDays(0);
    setMaxConseToggle(false);
    setMinGapDays(0);
    setMinGapToggle(false);
    setMaxLeavePerYr(0);
    setmaxLeavePerYrOption(month_yr_Option[2])
    setEmployee({ label: "All", value: "all" });
    setSelectedGender({ label: "All", value: "all" });
    setSelectedMaritalStatus({ label: "All", value: "all" });
    setHq({ value: "all", label: "All" });
    setAddProrate(false);
    setProrateLabel(null);
    setEntitleDrpDwn(false);
    setApplicableDrpDwn(false);
    setRestrictDrpDwn(false);
  }
  // const [locations.state?, setlocations.state?] = useState(null);
  useEffect(() => {

    prevDataSet();

  }, [])
  // console.log(locations.state)
  const prevDataSet = async () => {

    if (locations.state) {
      // console.log("first--->")
      setLname(locations.state?.leave_Name);
      setLcode(locations.state?.leave_code);
      setLType(locations.state?.leave_type);
      setLUnit(locations.state.leave_unit);
      // setPrevDataId(locations.state?._id);
      setFDate("");
      setTdate("");
      setDescription(locations.state?.description);
      setEffAfterDays(locations.state.entitlement.effectAfterDays);
      setAccrualOption(locations.state.entitlement.accOn);
      setResetOption(locations.state.entitlement.resetOn);
      setTotaldays(0);
      setIsaccrual(locations.state.entitlement.isaccrual);
      setIsReset(locations.state.entitlement.reset);
      setEffAfter(locations.state.entitlement.effectiveAfter);
      seteffOption(locations.state.entitlement.effectiveOption);
      setaccNoOfDay(locations.state.entitlement.accrualDays);
      setAccrualType(locations.state.entitlement.accrualType);
      setcarryFrwdUnit(locations.state.entitlement.carryFrwd_unit);
      setcarryFrwdExpireUnit(locations.state.entitlement.carryFrwd_expire_unit);
      setcarryFrwdExpireVal(locations.state.entitlement.carryFrwd_expire_val);
      setcaryyFrwdPer(locations.state.entitlement.carryFrwd_per);
      setMonthReset(locations.state.entitlement.resetMonth);
      // setAccrualMonth(accrualMonthOptions[0]);
      // setResetMonth({});
      setmonthAccrual(locations.state.entitlement.monthAccrual);
      setmaxLeavePerYrOption(locations.state.restriction.max_appl_yr_option)
      setAccrualDate(locations.state.entitlement.accrualDate);
      setResetDate(locations.state.entitlement.resetDate);
      setCarryFrwd(locations.state?.entitlement.carry_forwd);
      setEncashLimit(locations.state?.entitlement.encash_limit);
      setencashUnit(locations.state?.entitlement.encashment_unit);
      setcarryFrwdLimit(locations.state?.entitlement.carryFrwd_val);
      setcarryFrwdOverallLimit(locations.state?.entitlement.carryFrwd_overall_limit);
      setaplliedOn(locations.state?.restriction.applied_only_on);
      setTogetheWith(locations.state?.restriction.not_together_with);
      setWLeave(locations.state?.restriction.weekend_leave);
      setHLeave(locations.state?.restriction.holiday_leave);
      setWCountLimit(locations.state?.restriction.cnt_weekend_leave);
      setHolCount(locations.state?.restriction.cnt_holiday_leave);
      setRestrictCnt(locations.state?.restriction.restrict_days);
      setRestrict(locations.state?.restriction.isRestrict);
      setLeaveBal(locations.state?.restriction.exceed_leave_bal);
      setAllowLimit(locations.state?.restriction.allow_limit);
      setfileUploadDays(locations.state?.restriction.file_upload_dayslimit);
      setDurationLimit(locations.state?.restriction.duration_limit);
      setPastDays(locations.state?.restriction.past_Days);
      setFutureDays(locations.state?.restriction.future_Days);
      setMinLeaveDays(locations.state?.restriction.min_leave_days);
      setMinLeaveToggle(locations.state?.restriction.min_leave_appl);
      setMaxLeaveDays(locations.state?.restriction.max_leave_days);
      setMaxLeaveToggle(locations.state?.restriction.max_leave_appl);
      setMaxConsDays(locations.state?.restriction.max_cons_leave_days);
      setMaxConseToggle(locations.state?.restriction.max_cons_leave_appl);
      setMinGapDays(locations.state?.restriction.min_gap_days);
      setMinGapToggle(locations.state?.restriction.min_gap);
      setMaxLeavePerYr(locations.state?.restriction.max_appl_yr);
      setEmployee({ label: "All", value: "all" });
      setSelectedGender(locations.state?.applicability.gender);
      setSelectedMaritalStatus(locations.state?.applicability.marital_Status);
      setHq(locations.state?.applicability?.headquarter);
      setDepartment(locations.state?.applicability?.department);
      setDesignation(locations.state?.applicability?.designation)
      setAddProrate(false);
      setProrateLabel(null);
      setEntitleDrpDwn(false);
      setApplicableDrpDwn(false);
      setRestrictDrpDwn(false);
      // console.log("prevState-->",locations.state?._id)
    }
    else {
      // console.log("prev-->",locations.state?)
      resetForm();
    }
  }



  useEffect(() => {
    dispatch(fetchEmployees());
    dispatch(setDesignations());
    dispatch(setDepartments());
    dispatch(setHeadquarters());
    return () => {
      dispatch(clearDesignations());
      dispatch(clearEmployees());
    };
  }, [dispatch]);

  const handleGenderChange = (selectedOption) => {
    setSelectedGender(selectedOption);
  };

  const handleMaritalStatusChange = (selectedOption) => {
    setSelectedMaritalStatus(selectedOption);
  };

  const designationSelect = useMemo(
    () => {
      let options = [{ value: "all", label: "All" }];
      if (Array.isArray(designations.data)) {
        let data = generateSelectData(designations.data, "name");
        options.push(...data);
      }
      return options;
    },
    [designations.data],
  );

  function getDateList() {
    var dateList = [];
    var suffixes = ["st", "nd", "rd"];

    for (var i = 1; i <= 31; i++) {
      var suffix = "th";
      if (i % 10 === 1 && i !== 11) {
        suffix = "st";
      } else if (i % 10 === 2 && i !== 12) {
        suffix = "nd";
      } else if (i % 10 === 3 && i !== 13) {
        suffix = "rd";
      }
      dateList.push({ value: i, label: i + suffix });

    }

    return dateList;
  }

  var dates = getDateList();




  const hqSelect = useMemo(
    () => {
      let options = [{ value: "all", label: "All" }];
      if (Array.isArray(location.hq)) {
        let data = generateSelectData(location.hq, "name");
        options.push(...data);
      }
      return options;
    },
    [location.hq]
  );
  
  const departmentSelect = useMemo(
    () => {
      const options = [{ value: "all", label: "All" }];
      if (Array.isArray(departments.data)) {
        let depSelect = generateSelectData(departments.data, "name");
        options.push(...depSelect);
      }
      return options;
    },
    [departments.data]
  );
  useEffect(() => {

    getAllLeaves().then((res) => setLeaves(res.data))
      .catch(() => customToast.error("Something Went Wrong !"))
  }, []);

 

  const leaveNames = useMemo(
    () => {
      let options = [];
      if (Array.isArray(leaves)) {
        const leaveNames = generateMultiSelectData(leaves, "leave_Name");
        options.push(...leaveNames);
      }
      return options;
    },
    [leaves]
  );
  const emplSelect = useMemo(
    () => {
      const options = [{ value: "all", label: "All" }];
      if (Array.isArray(employees.data)) {
        const emplSelect = generateSelectData(employees.data, "fullName");
        options.push(...emplSelect);
      }
      return options;
    },
    [employees.data]
  );


  const handleRestrictBtn = (el) => {
    setRestrict(!restrict);
  }

  const handleWeekendChange = (selectedOption) => {
    if (selectedOption.label === 'Yes') {
      setWLeave({ label: selectedOption.label, count: wCountLimit });
    } else {
      setWLeave({ label: selectedOption.label, count: null });
    }
  };

  const handleHolidayChange = (selectedOption) => {
    if (selectedOption.label === 'Yes') {
      setHLeave({ label: selectedOption.label, count: holCount });
      restrict === true && setRestrict(!restrict);

    } else {
      setHLeave({ label: selectedOption.label, count: null });
      restrict === true && setRestrict(!restrict)
    }
  };

  const handleLeaveBal = (el) => {
    setLeaveBal(el?.label);
  }

  const handleCheckboxChange = (titleId) => {
    // console.log('Checkbox changed for titleId:', titleId);
    setConfig((prevConfig) => {
      if (prevConfig.includes(titleId)) {
        return prevConfig.filter((id) => id !== titleId);
      } else {
        return [...prevConfig, titleId];
      }
    });
  };

  const getOption = (array, obj) => {
    if(obj) return array.find((val) => obj?.value == val.value)
    else return null
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("first")
    // let data = {};
    if (lName === "" || lCode === "" || lType === null || lUnit === null) { customToast.error("Fill all the required fields") }
    else {
      let data = {

        leave_Name: lName,
        leave_code: lCode,
        leave_type: lType,


        description: description,
        leave_unit: lUnit,

        entitlement: {
          effectAfterDays: effectAfterDays,
          effectiveAfter: effectAfter,
          effectiveOption: effOption,
          // effectiveFrom: String(fromDate?.label),
          isaccrual: isaccrual,
          // accrualSet: accrualMonth?.label,
          accOn: accrualOption,
          accrualType: accrualType,
          accrualDate: accrualDate,
          monthAccrual: monthAccrual,
          // accrualYear: Number(accrualYear),
          accrualDays: Number(accNoOfDay),
          reset: isReset,
          resetOn: resetOption,
          carryFrwd_expire_unit: carryFrwdExpireUnit,
          carryFrwd_expire_val: carryFrwdExpireVal,
          resetMonth: monthReset,
          carryFrwd_per: Number(caryyFrwdPer),
          resetDate: resetDate,
          carry_forwd: carryFrwd,
          carryFrwd_unit: carryFrwdUnit,
          carryFrwd_val: Number(carryFrwdLimit),
          encashment_unit: encashUnit,
          carryFrwd_overall_limit: carryFrwdOverallLimit,
          encash_limit: Number(encashLimit),
          prorate: addProrate,
          prorateLabel: prorateLabel?.label

        },

        applicability: {
          gender: selectedGender,
          marital_Status: selectedMaritalStatus,
          designation: designation,
          department: department,
          headquarter: hq,

        },

        restriction: {
          weekend_leave: wleave?.label,
          cnt_weekend_leave: Number(wCountLimit),
          holiday_leave: hleave?.label,
          cnt_holiday_leave: Number(holCount),
          isRestrict: restrict,
          restrict_days: Number(restrictCnt),
          exceed_leave_bal: leaveBal,
          allow_limit: allowLimit,
          file_upload_dayslimit: fileUploadDays,
          duration_limit: durationLimit,
          past_Days: pastDays,
          future_Days: futureDays,
          min_leave_appl: minLeaveToggle,
          min_leave_days: Number(minLeaveDays),
          max_leave_appl: maxLeaveToggle,
          max_leave_days: Number(maxLeaveDays),
          max_cons_leave_appl: maxConseToggle,
          max_cons_leave_days: Number(maxConsDays),
          min_gap: minGapToggle,
          min_gap_days: Number(minGapDays),
          max_appl_yr: maxLeavePerYr,
          max_appl_yr_option: maxLeavePerYrOption,
          applied_only_on: aplliedOn,
          not_together_with: togetheWith
        }
      };
      addLeave(data).then(() => {
        resetForm();
        customToast.success("Leave Type Added !");
      }).catch(() => customToast.error("Something went wrong !"));
    }
    // console.log("data--->",data)
  };

  const handleEdit = (e) => {
    e.preventDefault();
    // console.log("edit",locations.state?._id)

    if (lName === "" || lCode === "" || lType === null || lUnit === null) { customToast.error("Fill all the required fields") }
    else {
      let data = {

        leave_Name: lName,
        leave_code: lCode,
        leave_type: lType,


        description: description,
        leave_unit: lUnit,

        entitlement: {
          effectAfterDays: effectAfterDays,
          effectiveAfter: effectAfter,
          effectiveOption: effOption,
          // effectiveFrom: String(fromDate?.label),
          isaccrual: isaccrual,
          // accrualSet: accrualMonth?.label,
          accOn: accrualOption,
          accrualType: accrualType,
          accrualDate: accrualDate,
          monthAccrual: monthAccrual,
          // accrualYear: Number(accrualYear),
          accrualDays: Number(accNoOfDay),
          reset: isReset,
          resetOn: resetOption,
          carryFrwd_expire_unit: carryFrwdExpireUnit,
          carryFrwd_expire_val: carryFrwdExpireVal,
          resetMonth: monthReset,
          carryFrwd_per: Number(caryyFrwdPer),
          resetDate: resetDate,
          carry_forwd: carryFrwd,
          carryFrwd_unit: carryFrwdUnit,
          carryFrwd_val: Number(carryFrwdLimit),
          encashment_unit: encashUnit,
          carryFrwd_overall_limit: carryFrwdOverallLimit,
          encash_limit: Number(encashLimit),
          prorate: addProrate,
          prorateLabel: prorateLabel?.label

        },

        applicability: {
          gender: selectedGender,
          marital_Status: selectedMaritalStatus,
          designation: designation,
          department: department,
          headquarter: hq,

        },

        restriction: {
          weekend_leave: wleave?.label,
          cnt_weekend_leave: Number(wCountLimit),
          holiday_leave: hleave?.label,
          cnt_holiday_leave: Number(holCount),
          isRestrict: restrict,
          restrict_days: Number(restrictCnt),
          exceed_leave_bal: leaveBal,
          allow_limit: allowLimit,
          file_upload_dayslimit: fileUploadDays,
          duration_limit: durationLimit,
          past_Days: pastDays,
          future_Days: futureDays,
          min_leave_appl: minLeaveToggle,
          min_leave_days: Number(minLeaveDays),
          max_leave_appl: maxLeaveToggle,
          max_leave_days: Number(maxLeaveDays),
          max_cons_leave_appl: maxConseToggle,
          max_cons_leave_days: Number(maxConsDays),
          min_gap: minGapToggle,
          min_gap_days: Number(minGapDays),
          max_appl_yr: maxLeavePerYr,
          max_appl_yr_option: maxLeavePerYrOption,
          applied_only_on: aplliedOn,
          not_together_with: togetheWith
        }
      };
      editLeave(data, locations.state._id).then(() => {

        customToast.success("Leave Type Updated !");
      }).catch(() => customToast.error("Something went wrong !"));
    }
    // console.log("data--->",data)
  };
  // console.log("prevdata--->",locations.state)
  const handleForm = (e) => {
    if (locations.state) {
      handleEdit(e)
      // console.log(description)
    }
    else {
      handleSubmit(e);
    }
  }

  return (
    <main className="main-content bg-primaryLight2 dark:bg-primaryDark1 " >
      <div className="area-creation-content" style={{ color: "white" }}>
        <PageTitle title="Add Leave Type" route='../../' />
      
      
        <section className='admin-content'>
          <form className={`${theme == "light" ? "lightbg" : ""}`}>
            <div className="row">
              <div >
                <label htmlFor="mName">
                  Leave Name <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                  className=" mt-4 h-[4rem]"
                  id="mName"
                  placeholder="Leave Name"
                  value={lName}
                  required
                  onChange={(e) => setLname(e.target.value)}
                />
              </div>
              <div >
                <label htmlFor="mName">
                  Leave Code <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"

                  className={` mt-4 h-[4rem]`}
                  id="mName"
                  placeholder="Leave code"
                  value={lCode}
                  required
                  onChange={(e) => setLcode(e.target.value)}
                />
              </div>
              <div >
                <label htmlFor="mName">
                  Leave Type <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  options={leaveTypes}
                  styles={theme == "light" ? adminStylesLight : adminStyles}
                  placeholder="Leave Type"
                  className="mt-0"
                  value={getOption(leaveTypes, lType)}
                  id="mName"
                  onChange={(el) => setLType({ value: el?.id, label: el?.label })}
                />
              </div>
              <div className="ml-6" >
                <label htmlFor="mName">
                  Leave Unit <span className="asterisk-imp">*</span>
                </label>
                <br />
                <Select
                  options={leaveUnits}
                  styles={theme == "light" ? adminStylesLight : adminStyles}
                  placeholder="Leave Units"

                  id="mName"
                  value={getOption(leaveUnits, lUnit)}
                  onChange={(el) => setLUnit({ value: el?.id, label: el?.label })}
                />
              </div>
              <div  className="col-span-2">
                <label className="mb-4">
                  Description
                </label>
                <br />
                
                <textarea rows="4" cols="50"
                  placeholder="Enter description..."
                  className={`outline-none rounded-md mt-6  p-4 ${theme === 'light' ?
                    "text-area-bg-light " : "text-area-bg "}`}
                  value={description}
                  id="mName"
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
                
              </div>
            </div>

          </form>
        </section>
      

      {/* <div className={`creation-content ${theme == "light" ? "light_bg" : ""}`} > */}
      {/* <section > */}
      {/* <div className="subheading flex p-6 bg-white dark:bg-primaryDark2">
            <h1 className="font-normal p-6 text-3xl">
              Entitlement
            </h1>
            <div onClick={() => setEntitleDrpDwn(!entitleDrpDwn)}>
              {entitleDrpDwn === true ?
                <FaChevronUp size={25} />
                :
                <FaChevronDown size={25} />}
            </div>
          </div> */}
      {/* { */}
      {/* entitleDrpDwn === true ? */}
      {/* <form className="p-6"> */}

      {/* <div className='flex flex-row gap-x-6 p-6'>
                <div className='mt-2'>
                  <label>
                    Effective After
                  </label>
                  <br />
                  {/* <div className="flex flex-row gap-x-4"> */}
      {/* <div className="w-60 mt-5">
                      <input
                        type='number'
                        onChange={(e) => setEffAfterDays(e?.target?.value)}
                        styles={theme == "light" ? adminStylesLight : adminStyles}
                      />
                    </div> */}
      {/* <div className="flex justify-center rounded-md h-[4rem] items-center bg-black mt-4 bg-primaryLight2 dark:bg-primaryDark1">
                    <div className="w-[15rem] " styles={theme == "light" ? adminStylesLight : adminStyles}>
                      <input type="text" value={effectAfterDays} style={{ background: "transparent" }} onChange={(e) => setEffAfterDays(e?.target?.value)} placeholder="0" />
                    </div>

                    <Select
                      options={month_yr_Option}
                      styles={theme == "light" ? adminStylesLight : adminStyles}
                      placeholder={month_yr_Option[2].label}
                      value={getOption(month_yr_Option,effOption)}
                      className="mb-4"
                      onChange={(el) => seteffOption({ value: el?.value, label: el?.label })}
                    />

                  </div> */}
      {/* </div> */}
      {/* </div> */}
      {/* <div className="mt-2">
                  <label htmlFor="mName">
                    From
                  </label>
                  <br />
                  <Select
                    options={JoiningFrom}
                    styles={theme == "light" ? adminStylesLight : adminStyles}
                    placeholder={JoiningFrom[0].label}
                    value={getOption(JoiningFrom,effectAfter)}
                    className="text-nowrap"
                    onChange={(el) => setEffAfter({ value: el?.value, label: el?.label })}
                  />
                </div> */}
      {/* </div> */}


      {/* <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4'>
                <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                 <div className='flex flex-row gap-x-6'>
                     {accrual.map((el) => (
                     <div className="flex gap-x-4" key={el?.label}>
                         <div className='flex' onClick={() => setIsaccrual(!isaccrual)}>
                           {isaccrual === true ?
                         <MdOutlineCheckBox className="w-20 icon-color-green" />
                             :
                             <MdOutlineCheckBoxOutlineBlank className="w-20 icon-color-green" />}
                           <div>
                            <label>{el?.label}</label>
                         </div>
                        </div>
                      </div>
                    ))}
                   </div>
                 </div>
               </div> */}
      {/* {isaccrual ?
                <div className="flex px-6 h-[3rem] w-full items-center gap-4">

                  <Select
                     options={accrualMonthOptions}
                     styles={theme == "light" ? adminStylesLight : adminStyles}
                     placeholder={accrualMonthOptions[3]?.label}
                     value={getOption(accrualMonthOptions,accrualOption)}
                     onChange={(el) => setAccrualOption({ value: el?.value, label: el?.label })}
                   />
                   <h3>
                     On
                   </h3>
                   <Select
                     options={dates}
                     styles={theme == "light" ? adminStylesLight : adminStyles}
                     onChange={(el) => setAccrualDate({ value: el?.value, label: el?.label })}
                     value={getOption(dates,accrualDate)}
                   />
                {accrualOption?.label !== "Monthly" ? <Select
                    options={monthOptions}
                    placeholder="Month"
                    styles={theme == "light" ? adminStylesLight : adminStyles}
                    onChange={(el) => setmonthAccrual({ value: el?.value, label: el?.label })}
                    value={getOption(monthOptions,monthAccrual)}
                  /> : null}

                   <h3>
                     No.&nbsp;of&nbsp;Days
                   </h3>

               <div className="w-[7rem] h-[4rem] mt-2 ">
                <input
                      type='number'
                      onChange={(e) => setaccNoOfDay(e?.target?.value)}
                      value={accNoOfDay}
                      placeholder="0"
                      styles={theme == "light" ? adminStylesLight : adminStyles}

                    />
                  </div>

                   <h3>
                     In
                </h3>


                   <Select
                    options={accOptions}
                    styles={theme == "light" ? adminStylesLight : adminStyles}
                    className="text-nowrap"
                    placeholder={accOptions[0]?.label}
                    value={getOption(accOptions,accrualType)}
                    onChange={(el) => setAccrualType({ value: el?.value, label: el?.label })}
                 />

                </div>
                :
                 null} */}

      {/* <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4 mt-7'>
                 <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
                  <div className='flex flex-row gap-x-6'>
                    {Reset.map((el) => (
                      <div className="flex gap-x-4" key={el?.label}>
                       <div className='flex' onClick={() => setIsReset(!isReset)}>
                          {isReset === true ?
                            <MdOutlineCheckBox className="w-20 icon-color-green" />
                            :
                            <MdOutlineCheckBoxOutlineBlank className="w-20 icon-color-green" />}
                           <div>
                            <label>{el?.label}</label>
                          </div>
                        </div>
                     </div>
                  ))}
                </div>
                 </div>
               </div> */}
      {/* {isReset ?
        <div>
          <div className="flex  px-6 h-[3rem] w-full items-center gap-4">

            <Select
              options={accrualMonthOptions}
              styles={theme == "light" ? adminStylesLight : adminStyles}
              placeholder={accrualMonthOptions[3]?.label}
              value={getOption(accrualMonthOptions, resetOption)}
              onChange={(el) => setResetOption({ value: el?.value, label: el?.label })}
            />


            <h3>
              On
            </h3>
            <Select
              options={dates}
              styles={theme == "light" ? adminStylesLight : adminStyles}
              placeholder="Select..."
              value={getOption(dates, resetDate)}
              onChange={(el) => setResetDate({ value: el?.value, label: el?.label })}
            />
            {resetOption?.label !== "Monthly" ? <Select
              options={monthOptions}
              styles={theme == "light" ? adminStylesLight : adminStyles}


              value={getOption(monthOptions, monthReset)}
              onChange={(el) => setMonthReset({ value: el?.value, label: el?.label })}
            /> : null}

            <div className="mt-1">
                    <input
                       type="date"
                     className="m-3 p-4"
                      placeholder="From"
                      value={resetDate}
                      onChange={(e) => setResetDate(e.target.value)} />
                  </div>
          </div>


          <div className="flex pt-4 pl-4 mt-5 items-center gap-4 w-[93rem]">
            <div className="w-80">
              <Select
                options={CarryFwdOptions}
                styles={theme == "light" ? adminStylesLight : adminStyles}
                placeholder={CarryFwdOptions[0]?.label}
                value={getOption(CarryFwdOptions, carryFrwd)}
                onChange={(el) => setCarryFrwd({ value: el?.value, label: el?.label })}
              />
            </div>
            <div className="flex justify-center h-[4rem] rounded-md items-center bg-black mt-4 bg-primaryLight2 dark:bg-primaryDark1">
              <div className="w-[13rem] " styles={theme == "light" ? adminStylesLight : adminStyles}>
                <input type="text" placeholder="0" value={caryyFrwdPer} onChange={(e) => { setcaryyFrwdPer(e.target.value) }} style={{ background: "transparent" }} />
              </div>

              <Select
                options={unitOptions}
                styles={theme == "light" ? adminStylesLight : adminStyles}
                placeholder={unitOptions[1]?.label}
                className="mb-4 text-xl"
                value={getOption(unitOptions, carryFrwdUnit)}
                onChange={(el) => setcarryFrwdUnit({ value: el?.value, label: el?.label })}
              />

            </div>

            {carryFrwd?.value == 1 ? <h3>Expires&nbsp;in</h3> : null}

            {carryFrwd?.value == 1 ?
              <div className="flex justify-center items-center gap-2">
                <h3>Expires&nbsp;in</h3>
                <div className="flex justify-center h-[4rem] rounded-md items-center bg-black mt-4 bg-primaryLight2 dark:bg-primaryDark1">
                  <div className="w-[12rem] " styles={theme == "light" ? adminStylesLight : adminStyles}>
                    <input type="text" placeholder="0" value={carryFrwdExpireVal} onChange={(e) => { setcarryFrwdExpireVal(e.target.value) }} style={{ background: "transparent" }} />
                  </div>

                  <Select
                    options={carryFrwdExpireOption}
                    styles={theme == "light" ? adminStylesLight : adminStyles}
                    placeholder={carryFrwdExpireOption[1]?.label}
                    className="mb-4 text-xl"
                    value={getOption(carryFrwdExpireOption, carryFrwdExpireUnit)}
                    onChange={(el) => setcarryFrwdExpireUnit({ value: el?.value, label: el?.label })}
                  />


                </div>
              </div> : null
            }


            {carryFrwd?.value == 2 ?

              <div className="flex justify-center h-[4rem] items-center bg-black mt-4 bg-primaryLight2 dark:bg-primaryDark1">
                <div className=" flex justify-center items-center gap-2 mt-5">
                  <h3>Overall&nbsp;Limit</h3>
                  <input
                    type='number'
                    styles={theme == "light" ? adminStylesLight : adminStyles}
                    placeholder='0'
                    className="w-80"
                    value={carryFrwdOverallLimit}
                    onChange={(e) => setcarryFrwdOverallLimit(e.target.value)} />
                </div>

                <Select
                  options={carryFrwdExpireOption}
                  styles={theme == "light" ? adminStylesLight : adminStyles}
                  placeholder={carryFrwdExpireOption[1]?.label}
                  className="mb-4 text-xl"
                  onChange={(el) => setcarryFrwdUnit({ value: el?.value, label: el?.label })}
                />


              </div>
              :
              <div className=" flex justify-center items-center gap-2 mt-5">
                <h3>Max&nbsp;Limit</h3>
                <input
                  type='number'
                  styles={theme == "light" ? adminStylesLight : adminStyles}
                  placeholder='0'
                  className="w-80"
                  value={carryFrwdLimit}
                  onChange={(e) => setcarryFrwdLimit(e.target.value)} />
              </div>
            }
          </div>

          <div className="flex p-4 mt-5 items-center gap-4">
            <div className="ml-36 mt-7">
              <label>
                Encashment
              </label>
            </div>
            <div className="flex justify-center h-[4rem]  rounded-md items-center bg-black mt-4 bg-primaryLight2 dark:bg-primaryDark1">
              <div className="w-[13rem] " styles={theme == "light" ? adminStylesLight : adminStyles}>
                <input type="text" placeholder="0" value={encashPerc} onChange={(e) => setencashPerc(e.target.value)} style={{ background: "transparent" }} />
              </div>

              <Select
                options={unitOptions}
                styles={theme == "light" ? adminStylesLight : adminStyles}
                placeholder={unitOptions[1]?.label}
                className="mb-4 text-xl"
                value={getOption(unitOptions, encashUnit)}
                onChange={(el) => setencashUnit({ value: el?.value, label: el?.label })}
              />

            </div>
            <h3>Max&nbsp;Limit</h3>
            <div className="w-80 mt-5" style={{ width: "20rem", marginTop: "1.2rem" }}>
              <input
                type='number'
                styles={theme == "light" ? adminStylesLight : adminStyles}
                value={encashLimit}
                placeholder='0'
                onChange={(e) => setEncashLimit(e.target.value)} />
            </div>

          </div>
        </div>
        :
        null
      } */}
      {/* <div className="flex  p-6  w-full items-center justify-between ">
              //   <button className="button-blue-gradient" onClick={(e) => handleSubmit(e)} >
              //     Save
              //   </button>
              //   <button className="button-green-gradient" >
              //     Add&nbsp;More
              //   </button>
      </div> */}
      {/* <div className="flex gap-x-4">
        <div className='flex' onClick={() => setAddProrate(!addProrate)}>
          {addProrate === true ?
            <FaSquareMinus className="w-20 icon-color-green" />
            :
            <MdAddBox className="w-20 icon-color-green" />}
          <div>
            <label>Prorate</label>
          </div>
        </div>
      </div> */}
      {/* <div className="p-4">
        {addProrate === true ?
          <Select
            className="w-80"
            options={prorateOptions}
            styles={theme == "light" ? adminStylesLight : adminStyles}
            onChange={(el) => setProrateLabel({ value: el?.value, label: el?.label })} />
          :
          null}
      </div> */}

    
        
      
    {/* </section > */}
       {/* </div > */}

{/* <div className={`creation-content ${theme == "light" ? "light_bg" : ""}`}>
        <section className="creation-content_form">
          <div className="subheading flex p-6 bg-white dark:bg-primaryDark2">
            <h1 className="font-normal p-6 text-3xl">
              Restrictions
            </h1>
            <div 
            // onClick={() => setRestrictDrpDwn(!restrictDrpDwn)}
            >
              {restrictDrpDwn === true ?
                <FaChevronUp size={25} />
                :
                <FaChevronDown size={25} />}
            </div>
          </div>

          {
          restrictDrpDwn == true ?

            <form className="p-8">
              <div className="p-6 text-blue-400 text-3xl">
                <h4>
                  Weekends Between Leave Period
                </h4>
              </div>
              <div className="flex flex-row  w-[50rem] justify-between">
                <div>
                  <div className="pl-6 mb-4">
                    <label>
                      Count As Leave
                    </label>
                  </div>
                  <div className="flex flex-row gap-14 mb-4">
                    {accrualOptions.map((el) => (
                      <div className="flex gap-4" key={el.label}>
                        <div onClick={() => handleWeekendChange({ label: el.label })}>
                          {(wleave && wleave.label === el.label) ?
                            <MdOutlineCheckBox className="w-20 icon-color-green" /> :
                            <MdOutlineCheckBoxOutlineBlank className="w-20 icon-color-green" />
                          }
                        </div>
                        <label>{el.label}</label>
                      </div>
                    ))}
                  </div>
                </div>
                {(wleave?.label === 'Yes') ?
                  <div className="flex flex-col gap-x-4 ml-8 pl-6">
                    <div className=" mb-4">
                      <label>
                        Count after
                      </label>
                    </div>
                    <div className="mb-8 w-[15rem] flex gap-4">
                      <input
                        className=""
                        type='number'
                        
                        onChange={(e) => setWCountLimit(e.target.value)}
                      />
                      <div className=" mb-4">
                        <label>
                          Days
                        </label>
                      </div>
                    </div>
                  </div>
                  :
                  null
                }
              </div>
              <div className="p-6 text-3xl text-blue-400">
                <h4>
                  Holidays Between Leave Period
                </h4>
              </div>
              <div className="flex flex-row w-[50rem] justify-between">
                <div>
                  <div className="pl-6 mb-4">
                    <label>
                      Count As Leave
                    </label>
                  </div>
                  <div className="flex flex-row gap-12 ">
                    {accrualOptions.map((el) => (
                      <div className="flex gap-4" key={el.label}>
                        <div onClick={() => handleHolidayChange({ label: el.label })}>
                          {(hleave && hleave.label === el.label) ?
                            <MdOutlineCheckBox className="w-20 icon-color-green" /> :
                            <MdOutlineCheckBoxOutlineBlank className="w-20 icon-color-green" />
                          }
                        </div>
                        <label>{el.label}</label>
                      </div>
                    ))}
                  </div>
                </div>
                {hleave?.label === "Yes" ?
                  <div className="flex flex-col gap-x-4 ml-8 pl-6">
                    <div className=" mb-4">
                      <label>
                        Count after
                      </label>
                    </div>
                    <div className="mb-8 w-[15rem] flex gap-4">
                      <input
                        className=""
                        type='number'
                        value={holCount}
                        onChange={(e) => setHolCount(e.target.value)}
                      />
                      <div className="mb-4">
                        <label>
                          Days
                        </label>
                      </div>
                    </div>
                  </div>
                  :
                  null
                }

              </div>
              <div className="flex  w-[50rem] justify-between ">
                <div className='flex gap-4 pl-6' onClick={() => handleRestrictBtn()}>
                  {restrict === false ? <MdOutlineCheckBoxOutlineBlank className="w-20 icon-color-green" /> :
                    <MdOutlineCheckBox className="w-20 icon-color-green " />}
                  <label>Restrict</label>
                </div>
                {restrict === true ?
                  <div className="flex flex-col gap-x-4 pl-6 mb-4">
                    <div className="w-60 mb-4">
                      <label>
                        Count after
                      </label>
                    </div>
                    <div className="mb-8 w-[15rem] flex gap-4">
                      <input
                        className=""
                        type='number'
                        value={restrictCnt}
                        onChange={(e) => setRestrictCnt(e.target.value)}
                      />
                      <div className="mb-4">
                        <label>
                          Days
                        </label>
                      </div>
                    </div>
                  </div>
                  :
                  null}

              </div>
              <div className="p-6 text-blue-400 text-3xl">
                <h4>
                  While Applying Leave, Exceed Leave Balance
                </h4>
              </div>
              <div className="flex flex-row gap-x-4 pl-6">
                {accrualOptions.map((el) => (
                  <div className="flex gap-x-4" key={el.label}>
                    <div onClick={() => handleLeaveBal(el)}>
                      {(leaveBal === el?.label) ?
                        <MdOutlineCheckBox className="w-fit icon-color-green" /> :
                        <MdOutlineCheckBoxOutlineBlank className="w-fit icon-color-green" />
                      }
                    </div>
                    <label>{el.label}</label>
                  </div>
                ))}
              </div>
              {leaveBal === "Yes" ?
                <div className='flex flex-row'>
                  <div className="p-6">
                    <label>
                      Allowable Limit
                    </label>
                  </div>
                  <div className="w-80 pl-4">
                    <Select
                      options={allowLimitOptions}
                      styles={theme == "light" ? adminStylesLight : adminStyles}
                      placeholder="Without Limit"
                      value={getOption(allowLimitOptions,allowLimit)}
                      onChange={(el) => setAllowLimit({ value: el?.value, label: el?.label })}
                    />
                  </div>
                  <div className="p-6">
                    <label>
                      Duration Allowed
                    </label>
                  </div>
                  <div className=" flex flex-row">
                    <div className="w-80 pl-4">
                      <Select
                        options={durationOptions}
                        styles={theme == "light" ? adminStylesLight : adminStyles}
                        placeholder="Full Day"
                        value={getOption(durationOptions,durationLimit)}
                        onChange={(el) => setDurationLimit({ value: el?.value, label: el?.label })}
                      />
                    </div>
                    <div className="m-6">
                      <label>
                        Days
                      </label>
                    </div>
                  </div>
                </div>
                :
                null
              }

              <div className="p-6 mt-6 text-blue-400 text-3xl">
                <h4>
                  Allow requests for
                </h4>
              </div>
              <div className="flex flex-row p-4 items-center justify-between">
                <div onClick={() => setPastDays(!pastDays)}>
                  {pastDays === true ?
                    <MdOutlineCheckBox className="icon-color-green" />
                    :
                    <MdOutlineCheckBoxOutlineBlank className='icon-color-green' />
                  }
                </div>
                <div className="pl-4 mr-auto">
                  <label>
                    Past days
                  </label>
                </div>
              </div>
              <div className="flex flex-row p-4 items-center justify-between">
                <div onClick={() => setFutureDays(!futureDays)}>
                  {futureDays === true ?
                    <MdOutlineCheckBox className="icon-color-green" />
                    :
                    <MdOutlineCheckBoxOutlineBlank className='icon-color-green' />}
                </div>
                <div className="pl-4 mr-auto">
                  <label>
                    Future days
                  </label>
                </div>
              </div>
              <div className="flex flex-row p-4 items-center justify-between">
                <div onClick={() => setMinLeaveToggle(!minLeaveToggle)}>
                  {minLeaveToggle === true ?
                    <MdOutlineCheckBox className="icon-color-green" />
                    :
                    <MdOutlineCheckBoxOutlineBlank className='icon-color-green' />}
                </div>
                <div className="pl-4 mr-auto">
                  <label>
                    Minimum leave that can be availed per application
                  </label>
                </div>
                {minLeaveToggle === true ?
                  <div className='contents'>
                    <div className="w-80">
                      <input
                        style={theme == "light" ? adminStylesLight : adminStyles}
                        placeholder="0"
                        type='number'
                        onChange={(e) => setMinLeaveDays(e.target.value)} />
                    </div>
                    <div>
                      <label>
                        Days
                      </label>
                    </div>
                  </div>
                  :
                  null
                }
              </div>
              <div className="flex flex-row p-4 items-center justify-between">
                <div onClick={() => setMaxLeaveToggle(!maxLeaveToggle)}>
                  {maxLeaveToggle === true ?
                    <MdOutlineCheckBox className="icon-color-green" />
                    :
                    <MdOutlineCheckBoxOutlineBlank className='icon-color-green' />}
                </div>
                <div className="pl-4 mr-auto">
                  <label>
                    Maximum leave that can be availed per application
                  </label>
                </div>
                {maxLeaveToggle === true ?
                  <div className='contents'>
                    <div className="w-80">
                      <input
                        style={theme == "light" ? adminStylesLight : adminStyles}
                        placeholder="0"
                        type='number'
                        onChange={(e) => setMaxLeaveDays(e.target.value)} />
                    </div>
                    <div>
                      <label>
                        Days
                      </label>
                    </div>
                  </div>
                  :
                  null
                }
              </div>
              <div className="flex flex-row p-4 items-center justify-between">
                <div onClick={() => setMaxConseToggle(!maxConseToggle)}>
                  {maxConseToggle === true ?
                    <MdOutlineCheckBox className="icon-color-green" />
                    :
                    <MdOutlineCheckBoxOutlineBlank className='icon-color-green' />}
                </div>
                <div className="pl-4 mr-auto">
                  <label>
                    Maximum number of consecutive days of Leave allowed per application
                  </label>
                </div>
                {maxConseToggle === true ?
                  <div className='contents'>
                    <div className="w-80">
                      <input
                        style={theme == "light" ? adminStylesLight : adminStyles}
                        placeholder="0"
                        type='number'
                        onChange={(e) => setMaxConsDays(e.target.value)} />
                    </div>
                    <div>
                      <label>
                        Days
                      </label>
                    </div>
                  </div>
                  :
                  null
                }
              </div>
              <div className="flex flex-row p-4 items-center justify-between">
                <div onClick={() => setMinGapToggle(!minGapToggle)}>
                  {minGapToggle === true ?
                    <MdOutlineCheckBox className="icon-color-green" />
                    :
                    <MdOutlineCheckBoxOutlineBlank className='icon-color-green' />}
                </div>
                <div className="pl-4 mr-auto">
                  <label>
                    Minimum gap (in days) between two applications
                  </label>
                </div>
                {minGapToggle === true ?
                  <div className='contents'>
                    <div className="w-80">
                      <input
                        style={theme == "light" ? adminStylesLight : adminStyles}
                        placeholder="0"
                        type='number'
                        onChange={(e) => setMinGapDays(e.target.value)} />
                    </div>
                    <div>
                      <label>
                        Days
                      </label>
                    </div>
                  </div>
                  :
                  null
                }
              </div>
              <div className="flex flex-row p-4 items-center justify-between">
                <div onClick={() => setFileUploadToggle(!fileUploadToggle)}>
                  {fileUploadToggle === true ?
                    <MdOutlineCheckBox className="icon-color-green" />
                    :
                    <MdOutlineCheckBoxOutlineBlank className='icon-color-green' />}
                </div>
                <div className="pl-4 mr-auto">
                  <label>
                  Enable file upload option if the applied leave period exceeds per application
                  </label>
                </div>
                {fileUploadToggle === true ?
                  <div className='contents'>
                    <div className="w-80">
                      <input
                        style={theme == "light" ? adminStylesLight : adminStyles}
                        placeholder="0"
                        type='number'
                        onChange={(e) => setfileUploadDays(e.target.value)} />
                    </div>
                    <div>
                      <label>
                        Days
                      </label>
                    </div>
                  </div>
                  :
                  null
                }
              </div>
              <div className="p-6">
                <label>
                  Maximum number of applications allowed within a year
                  
                </label>
              </div>
              <div className="flex justify-center w-[25rem] rounded-md ml-6 h-[4rem] items-center bg-black mt-4 bg-primaryLight2 dark:bg-primaryDark1">
                <div className="w-[13rem] " styles={theme == "light" ? adminStylesLight : adminStyles}>
                  <input type="text" style={{ background: "transparent" }} onChange={(e)=>setMaxLeavePerYr(e.target.value)}/>
                </div>

                <Select
                  options={month_yr_Option}
                  styles={theme == "light" ? adminStylesLight : adminStyles}
                  placeholder={month_yr_Option[2].label}
                  className="mb-4"
                  value={getOption(month_yr_Option,maxLeavePerYrOption)}
                  onChange={(el) => setmaxLeavePerYrOption({ value: el?.value, label: el?.label })}
                />

              </div>
              <div className="p-6">
                <label>
                  This leave can be applied only on
                </label>
              </div>
              <div className="w-[26rem] pl-4">
                <Select
                  options={appliedOnDayOptions}
                  styles={theme == "light" ? adminStylesLight : adminStyles}
                  placeholder="Select..."
                  className='allowable-select'
                  value={getOption(appliedOnDayOptions,aplliedOn)}
                  onChange={(el) => setaplliedOn({ value:el?.value, label:el?.label })}
                />
              </div>
              <div className="p-6">
                <label>
                  This leave cannot be taken together with
                </label>
              </div>
              <div className="w-[26rem] pl-4">
                <Select
                  options={leaveNames}
                  styles={theme == "light" ? adminStylesLight : adminStyles}
                  placeholder="Select..."
                  className='allowable-select'
                  isMulti
                  value={leaveNames.filter(obj1 => togetheWith?.some(obj2 => obj1.label == obj2.label))}
                  onChange={(el) => {setTogetheWith(el)}}
                />
              </div>
            </form>
            :
            null
          }


        </section>
      </div> */}

  < div className = {` ${theme == "light" ? "light_bg" : ""}`}>
    <section className="admin-content">
      <div className="subheading flex pl-6 bg-white dark:bg-primaryDark2 cursor-pointer" onClick={() => setApplicableDrpDwn(!applicableDrpDwn)}>
        <h1 className="font-normal  text-3xl ">
          Applicability
        </h1>
        <div >
          {applicableDrpDwn === true ?
            <FaChevronUp size={25} />
            :
            <FaChevronDown size={25} />}
        </div>
      </div>
      {applicableDrpDwn === true ?
        <form className="pl-6 pt-2 form-label-admin  bg-white dark:bg-primaryDark2 creation-content">
          <div className="pl-6">
            <label>
              Applicable to
            </label>
          </div>
          <div className="flex flex-col gap-10 justify-between p-8">
            <div className="flex flex-col">
              <div className="mt-4 ">
                <label>
                  Gender
                </label>
              </div>
              <div className="flex gap-72 mt-4">
                {genders.map((el) => (
                  <div className="flex gap-6" key={el?.label}>
                    <div onClick={() => handleGenderChange({ value: el?.value, label: el?.label })}>
                      {(selectedGender && selectedGender?.value == el?.value) ?
                        <MdOutlineCheckBox className="w-10 icon-color-green" /> :
                        <MdOutlineCheckBoxOutlineBlank className="w-10 icon-color-green" />
                      }
                    </div>
                    <label>{el?.label}</label>
                  </div>
                ))}
              </div>
            </div>

            <div className="flex flex-col">
              <div className="w-80 mt-2">
                <label>
                  Marital Status
                </label>
              </div>
              <div className="flex flex-row gap-52 mt-4">
                {maritalstatus.map((el) => (
                  <div className="flex gap-6">
                    <div onClick={() => handleMaritalStatusChange({ value: el?.value, label: el?.label })}>
                      {(selectedMaritalStatus && selectedMaritalStatus?.value === el?.value) ?
                        <MdOutlineCheckBox className="w-10 icon-color-green" />
                        :
                        <MdOutlineCheckBoxOutlineBlank className="w-10 icon-color-green" />
                      }
                    </div>
                    <label>{el?.label}</label>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="flex justify-between">
            <div className="flex flex-col p-6 justify-between">
              <div className="w-80">
                <label>
                  Designation
                </label>
              </div>
              <div className="mr-auto w-96">
                <Select
                  options={designationSelect}
                  styles={theme == "light" ? adminStylesLight : adminStyles}
                  placeholder="Select Designation"
                  value={getOption(designationSelect, designation)}
                  onChange={({ value, label }) => setDesignation({ value, label })}
                />
              </div>
            </div>

            <div className="flex flex-col p-6 justify-between">
              <div className="w-80 ">
                <label>
                  Department
                </label>
              </div>
              <div className="mr-auto w-96">
                <Select
                  options={departmentSelect}
                  styles={theme == "light" ? adminStylesLight : adminStyles}
                  placeholder="Select Department"
                  value={getOption(departmentSelect, department)}
                  onChange={({ value, label }) => setDepartment({ value, label })}
                />
              </div>
            </div>

            <div className="flex flex-col p-6 justify-between">
              <div className="w-80 ">
                <label>
                  Headquarters
                </label>
              </div>
              <div className="mr-auto w-96">
                <Select
                  options={hqSelect}
                  styles={theme == "light" ? adminStylesLight : adminStyles}
                  placeholder="Select Headquarter"
                  value={getOption(hqSelect, hq)}
                  onChange={({ value, label }) => setHq({ value, label })}
                />
              </div>
            </div>
          </div>


        </form>
        :
        null
      }
    </section>
      </div >
      </div>
  <div className="my-4 mx-2">


    <button className="button-blue-gradient" onClick={(e) => handleForm(e)}>
      Save
    </button>





  </div>
  
    </main >
  );
};

export default AddLeave;
