import { createSlice } from "@reduxjs/toolkit";
import { deleteLwf, getEpfDetails, getLwf, updateLwf } from "../services/hrms";

const lwfDetailsSlice = createSlice({
  name: "lwfDetails",
  initialState: {
    data: [],
  },
  reducers: {
    setLwfDetails(state, action) {
      state.data = action.payload;
    },
  },
});

export const { setLwfDetails } = lwfDetailsSlice.actions;

export const fetchLwfDetails = () => async (dispatch) => {
  try {
    const response = await getLwf();
    dispatch(setLwfDetails(response));
  } catch (error) {
    console.log("Error fetching epf details: ", error);
  }
};

export const deleteLwfDetails = (id) => async (dispatch) => {
    try {
        await deleteLwf(id);
        await dispatch(fetchLwfDetails()); 
    } catch (error) {
      console.log("Error fetching epf details: ", error);  
    }
}


export const updateLwfStatus = (id , payload) => async (dispatch) => {
    try {
        await updateLwf(id , payload);
        await dispatch(fetchLwfDetails()); 
    } catch (error) {
      console.log("Error fetching epf details: ", error);  
    }
}

export default lwfDetailsSlice.reducer;
