import { createSlice } from "@reduxjs/toolkit";
import { GetActiveEarnings, GetEarnings, deleteEarning } from "../services/earning";

const earningsSlice = createSlice({
    name: "earnings",
    initialState: {
        data: [],
    },
    reducers: {
        setEarnings: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const { setEarnings } = earningsSlice.actions;

export const fetchEarnings = () => async (dispatch) => {
    try {
        const response = await GetEarnings();
        dispatch(setEarnings(response?.data));
    } catch (error) {
        console.log(error);
    }
};

export const DeleteEarning = (payload) => async(dispatch) => {
    try {
        const response = await deleteEarning(payload);
        dispatch(fetchEarnings());
    } catch (error) {
        console.log(error);
    }
};

// export const ActiveEarning = (payload) => async(dispatch) => {
//     try {
//         const response = await GetActiveEarnings();
//         dispatch(fetchEarnings());
//     } catch (error) {
//         console.log(error);
//     }
// }

export default earningsSlice.reducer;