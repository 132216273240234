import { Link, useLocation } from "react-router-dom";
import {
  MdOutlineDashboard,
  MdOutlineManageAccounts,
  MdPeople,
  MdOutlinePayments,
} from "react-icons/md";
import { GiReceiveMoney } from "react-icons/gi";
import { FaUserAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import { IoMdSettings } from "react-icons/io";
import { BsGraphUp } from "react-icons/bs";
import { AiTwotoneCreditCard } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import { TbCoinRupeeFilled } from "react-icons/tb";
import { BsBuildingFillGear } from "react-icons/bs";

const NavLeft = () => {
  const { pathname } = useLocation();
  const { sidenavVisible } = useSelector(({ sidenav }) => sidenav);
  const theme = useSelector((state) => state.theme.theme);

  return (
    <nav
      className={"sidebar " + `${theme == "light" ? "light_bg" : ""}`}
      style={{ display: sidenavVisible ? "flex" : "none" }}
    >
      <ul className="side-nav">
        <li
          className={`side-nav__item side-nav__item${
            pathname.startsWith("/dashboard") && "--active"
          }`}
        >
          <Link to="/dashboard" className="side-nav__link">
            <MdOutlineDashboard
              className={
                "web-app__left-nav-icon side-nav__icon " +
                `${theme == "light" ? "light_text" : ""}`
              }
            />
            <span className={theme == "light" ? "light_text" : ""}>
              Dashboard
            </span>
          </Link>
        </li>
        <li
          className={`side-nav__item side-nav__item${
            pathname.startsWith("/employee") && "--active"
          }`}
        >
          <Link to="/employee" className="side-nav__link">
            <CgProfile
              className={
                "web-app__left-nav-icon side-nav__icon " +
                `${theme == "light" ? "light_text" : ""}`
              }
            />
            <span className={theme == "light" ? "light_text" : ""}>
              Employee Panel
            </span>
          </Link>
        </li>
        <li
          className={`side-nav__item side-nav__item${
            pathname.startsWith("/payrun") && "--active"
          }`}
        >
          <Link to="/payrun" className="side-nav__link">
            <MdOutlinePayments
              className={
                "web-app__left-nav-icon side-nav__icon " +
                `${theme == "light" ? "light_text" : ""}`
              }
            />
            <span className={theme == "light" ? "light_text" : ""}>PayRun</span>
          </Link>
        </li>
        <li
          className={`side-nav__item side-nav__item${
            pathname.startsWith("/attendance") && "--active"
          }`}
        >
          <Link to="/attendance" className="side-nav__link">
            <MdPeople
              className={
                "web-app__left-nav-icon side-nav__icon " +
                `${theme == "light" ? "light_text" : ""}`
              }
            />
            <span className={theme == "light" ? "light_text" : ""}>
              Attendance
            </span>
          </Link>
        </li>
        
        <li
          className={`side-nav__item side-nav__item${
            pathname.startsWith("/finance") && "--active"
          }`}
        >
          <Link to="/finance" className="side-nav__link">
            <TbCoinRupeeFilled
              className={
                "web-app__left-nav-icon side-nav__icon " +
                `${theme == "light" ? "light_text" : ""}`
              }
            />
            <span className={theme == "light" ? "light_text" : ""}>
              Finance
            </span>
          </Link>
        </li>
        <li
          className={`side-nav__item side-nav__item${
            pathname.startsWith("/loan") && "--active"
          }`}
        >
          <Link to="/loan" className="side-nav__link">
            <GiReceiveMoney
              className={
                "web-app__left-nav-icon side-nav__icon " +
                `${theme == "light" ? "light_text" : ""}`
              }
            />
            <span className={theme == "light" ? "light_text" : ""}>Loan</span>
          </Link>
        </li>
        <li
          className={`side-nav__item side-nav__item${
            pathname.startsWith("/reports") && "--active"
          }`}
        >
          <Link to="/reports" className="side-nav__link">
            <BsGraphUp 
             className={
                "web-app__left-nav-icon side-nav__icon " +
                `${theme == "light" ? "light_text" : ""}`
              }
            />
            <span className={theme == "light" ? "light_text" : ""}>Reports</span>
          </Link>
        </li>
        <li
          className={`side-nav__item side-nav__item${
            pathname.startsWith("/settings") && "--active"
          }`}
        >
          <Link to="/settings" className="side-nav__link">
            <IoMdSettings
              className={
                "web-app__left-nav-icon side-nav__icon " +
                `${theme == "light" ? "light_text" : ""}`
              }
            />
            <span className={theme == "light" ? "light_text" : ""}>
              Settings
            </span>
          </Link>
        </li>
      </ul>

      <div className={"profile " + `${theme == "light" ? "light_bg" : ""}`}>
        {/* <div className="profile-pic">
          <img
            src={
              // loggedIn?.user?.gender?.toLowerCase() === "male"
              //   ? MaleAvatar
              //   : FemaleAvatar
            }
            alt="user"
          />
        </div> */}

        {/* <div className="profile-info">
          <h3>{`${loggedIn.user.firstName} ${loggedIn.user.lastName}`}</h3>
          <p className="mt-2">{loggedIn.user.email}</p>
        </div> */}

        <div className={"profile-settings "}>

          <Link to="/add/create">
          <span href='/'><BsBuildingFillGear /></span>

          </Link>
        </div>
      </div>
    </nav>
  );
};

export default NavLeft;
