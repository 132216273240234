import React, { useEffect, useRef, useState  } from 'react'
import { useSelector } from 'react-redux';
import { PDFViewer, Document, Page, Text, View, StyleSheet, PDFDownloadLink, Svg, Image, Font, usePDF, BlobProvider } from '@react-pdf/renderer';
const BandAdvice = () => {
    const theme = useSelector((state) => state.theme.theme);
    // const dispatch = useDispatch();
    // const employees = useSelector(({ employees }) => employees);
    // useEffect(() => {
    //     dispatch(fetchEmployees());
    //     setarray(employees?.data)
    //     return () => dispatch(clearEmployees());
    // }, [dispatch]);
    // const [array, setarray] = useState(employees?.data)
    Font.register
        ({
            family: 'Tinos',
             src: "http://fonts.gstatic.com/s/tinos/v9/R0GUby9C7Xd7F2g6Wjdydw.ttf",
             
        })
    const PDFDocument = () => (
        <Document>
            <Page size="A3" wrap style={{padding:"20px"}}>
                <View style={{ backgroundColor: 'white', width: '100%', padding: '30pt',fontFamily:'Tinos', fontWeight: 'heavy'}}>
                    <View  style={{ borderBottom: "1px solid black", padding: "15px 10px",marginBottom:"20px" }}>
                        <Text style={{ color: "#3399FF", fontSize: "17px", marginBottom: "10px" }}>MEDORN VENTURES PRIVATE LIMITED</Text>
                        <Text style={{ color: "#3399FF", fontSize: "15px" }}>Flat No. 123 , Ayodhya Bypass , Bhopal</Text>
                    </View>
                    <Text style={{ textAlign: "center", fontWeight: 'heavy', padding: "15px" }} >Payment Advice</Text>
                    <View style={{ display: "flex", flexDirection: "row", padding: "15px", justifyContent: "space-between", fontSize: "15px" }}>
                        <View style={{ display: "flex", gap: "5px" }}>
                            <Text>To,</Text>
                            <Text>Bank Manager</Text>
                            <Text>Bank Name</Text>
                            <Text>____________________________</Text>
                            <Text>Bank Address</Text>
                            <Text>____________________________</Text>
                        </View>
                        <View style={{ fontSize: "15px", marginRight: "45px", display: "flex", gap: "5px" }}>
                            <Text>Page:(1)</Text>
                            <Text>Date:</Text>
                        </View>
                    </View>
                    <Text style={{ fontSize: "15px", padding: "15px" }}>Payment Advice from Medorn A/C # _________________________ for period 01 April 2024 to 31 April 2024 .</Text>
                    <Text style={{ fontSize: "15px", padding: "15px" }}>Please make the payroll transfer from above account number to the below
                        mentioned account numbers towards employee salaries:</Text>
                    <View style={{ display: "flex", flexDirection: "row", fontSize: "15px", padding: "0 15px", justifyContent: "center" }}>
                        <Text style={{ border: "1px black solid",width:"12vw", padding: "10px 20px" }}>Sr. No</Text>
                        <Text style={{ border: "1px black solid",width:"14vw", padding: "10px 20px" }}>Emp. No</Text>
                        <Text style={{ border: "1px black solid",width:"21vw", padding: "10px 20px" }}>Employee Name</Text>
                        <Text style={{ border: "1px black solid",width:"19vw", padding: "10px 20px" }}>Account No.</Text>
                        <Text style={{ border: "1px black solid",width:"19vw", padding: "10px 20px" }}>Bank Name</Text>
                        <Text style={{ border: "1px black solid",width:"19vw", padding: "10px 20px" }}>IFSC Code</Text>
                    </View>
                    {/* {array?.map((e,index) => ( */}
                        <View wrap={false} style={{ display: "flex", flexDirection: "row", fontSize: "15px", padding: "0 15px", justifyContent: "center" }}>
                            <Text style={{ border: "1px black solid", width:"12vw", padding: "10px 20px"  }}>1</Text>
                            <Text style={{ border: "1px black solid",width:"14vw", padding: "10px 20px" }}>E12</Text>
                            <Text style={{ border: "1px black solid",width:"21vw", padding: "10px 20px" }}>Kuhu</Text>
                            <Text style={{ border: "1px black solid",width:"19vw", padding: "10px 20px" }}>9876654</Text>
                            <Text style={{ border: "1px black solid",width:"19vw", padding: "10px 20px" }}>SBI</Text>
                            <Text style={{ border: "1px black solid",width:"19vw", padding: "10px 20px" }}>Abc876543</Text>
                        </View>
                    {/* ) */}
                    {/* )} */}
                    <Text style={{ fontSize: "15px", padding: "5px 15px", marginTop: "30px" }} fixed>Yours Sincerely,</Text>
                    <Text style={{ fontSize: "15px", padding: "5px 15px" }} fixed>Medorn Ventures Private Limited</Text>
                    <Text style={{ fontSize: "15px", marginLeft: "15px", marginTop: "45px" }} fixed>Authorised Signature</Text>
                </View>
            </Page>
        </Document>
    )

    return (
        <div className='w-full'>
            <PDFViewer width="100%" height="100%" style={{ width: "100%", height: "70rem", marginBottom: "3rem" }}>
                <PDFDocument />
            </PDFViewer>
        </div>
    )
}

export default BandAdvice;