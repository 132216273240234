import React, { lazy } from "react";
import "../../antd.css";
const Popover = lazy(() => import("antd/es/popover"));
// const content = (
//   <div>
//     <p>Content</p>
//     <p>Content</p>
//   </div>
// );
const PopoverAntd = ({ children, title, content }) => (
  <Popover content={content} title={title} overlayClassName="custom-popover">
    {children}
  </Popover>
);
export default PopoverAntd;
