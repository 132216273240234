import React, { useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { ReactComponent as SearchIcon } from "../../../../assets/SVG/magnifying-glass.svg";
import Logo from "../../../../assets/img/logo.png";
import { MdOutlineNotificationsActive, MdLogout } from "react-icons/md";
import MaleAvatar from "../../../../assets/img/male-avatar.png";
import FemaleAvatar from "../../../../assets/img/female-avatar.png";
import "../../home.css";
import { logoutUser } from "../../../../reducers/userReducer";
import { useDispatch, useSelector } from "react-redux";
import Toggle from "../../../../components/Toggle/Toggle";
import { fetchAllNotifications } from "../../../../reducers/notificationsReducer";

const Header = ({companyName}) => {
  const theme = useSelector((state) => state.theme.theme);
  const loggedIn = useSelector(({user}) => user);
  const notifications = useSelector((state) => state.notification.data)

  // + `${theme === "light" ? "light_bg" : ""}`
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchAllNotifications());
  }, [dispatch]);

  const handleLogOut = () => {
    dispatch(logoutUser());
  };
 
  const [firstName , ...remainingWords] = companyName?.split(' ') || [];
  const remainingName = remainingWords?.join(' ');
  console.log

  return (
    <header
      className={
        "h-28 flex justify-between text-2xl items-center main-header line " +
        `${theme === "light" ? "light_bg" : ""}`
      }
    >
      <div
        className="flex justify-between items-center cursor-pointer"
        onClick={() => navigate("/dashboard", { replace: true })}
      >
        <img src={Logo} alt="Medorn Logo" className="logo" />
        <div>
        <h1
          className={
            "brand__heading " +
            `${theme === "light" ? "light_bg_logo" : "dark_bg_logo"}`
          }
          style={{minWidth:'300px', maxWidth: '300px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          {firstName}
        </h1>
        <p style={{minWidth:'300px', maxWidth: '300px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginLeft: "1.2rem", fontSize: "1.5rem", fontWeight: "600", color: "rgba(46, 204, 113, 0.733)"}}>
          {remainingName}
        </p>
        </div>
      </div>
        
      <form action="#" className="search">
        <input
          type="search"
          className={
            "search__input " + `${theme === "light" ? "light_input_bg" : ""}`
          }
          placeholder="Search Doctors, Tour Program....."
        />
        <button
          className={
            "search__button " + `${theme === "light" ? "light_input_bg" : ""}`
          }
        >
          <SearchIcon
            className={
              "search__icon " + `${theme === "light" ? "light_bg_fill" : ""}`
            }
          />
        </button>
      </form>

      <nav className="user-nav">
        <div>
          {/* <Toggle /> */}
        </div>
        <div className="user-nav__icon-box">
          <Link to="/notifications">
            <MdOutlineNotificationsActive className="user-nav__icon text-primaryLight dark:text-secondary" />
            <span className="user-nav__notification">{notifications?.length}</span>
          </Link>
        </div>

        <div onClick={() => handleLogOut()} className="user-nav__icon-box">
          <MdLogout
            className={
              "user-nav__icon " + `${theme === "light" ? "light_bg_logo" : ""}`
            }
          />
        </div>

        <Link to="/profile">
          <div className="user-nav__user flex flex-row">
            <img
              src={
                loggedIn?.user?.gender === "male" ? MaleAvatar : FemaleAvatar
              }
              alt="avatar"
              className="user-nav__user-photo"
            />
            <span
              className={
                "user-nav__user-name mt-4" +
                `${theme === "light" ? "light_bg_logo" : ""}`
              }
            >
              {`${loggedIn?.user?.firstName}`}
            </span>
          </div>
        </Link>
      </nav>
    </header>
  );
};

export default Header;
