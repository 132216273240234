// import { useEffect, useState, useMemo, useReducer } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { adminStyles, adminStylesLight } from "../../../../utils/selectStyles";
// import PageTitle from "../../../../components/PageTitle";
// import Select from "react-select";
// import { generateSelectData } from "../../../../utils/serialiseResponse";
// import {
//   fetchEmployees,
//   setEmployees,
// } from "../../../../reducers/employeesReducer";
// import { GetAllEmployees, UploadDocument } from "../../../../services/hrms";
// import customToast from "../../../../components/CustomToast";
// import { fileToBase64 } from "../../../../utils/helper";
// import { IoIosAddCircleOutline, IoIosRemoveCircleOutline } from "react-icons/io";


// const selectStyle = {
//   control: (provided, state) => ({
//     ...provided,
//     padding: "0.1rem 1rem",
//     backgroundColor: "#2B2C47",
//     color: "#C7C4E9",
//     border: "5px",
//     borderRadius: "5px",
//     boxShadow: "none",
//     minHeight: "3.5rem",
//     maxHeight: "3.5rem",
//     width: "100%",
//     marginTop: "1rem",
//   }),
//   option: (provided, state) => ({
//     ...provided,
//     backgroundColor: "#2B2C47",
//     color: "#9E9AD1",

//     "&:hover": {
//       backgroundColor: "#393b5f",
//     },
//   }),
//   singleValue: (provided, state) => ({
//     ...provided,
//     color: "#9e9ad0",
//   }),
//   menu: (provided, state) => ({
//     ...provided,
//     backgroundColor: "#2B2C47",
//     width: "80%",
//   }),
//   input: (provided, state) => ({
//     ...provided,
//     color: "#fff",
//     border: "none",
//     width: "100%",
//   }),
//   dropdownIndicator: (provided, state) => ({
//     ...provided,
//     color: "#C7C4E9",

//     "&:hover": {
//       color: "#9E9AD1",
//     },
//   }),
//   placeholder: (provided, state) => ({
//     ...provided,
//     color: "#9E9AD1",
//   }),
//   indicatorSeparator: () => null,
// };

// const Passport = () => {
//   const dispatch = useDispatch();

//   useEffect(() => {
//     dispatch(fetchEmployees({}));
//   }, []);

//   const employeeData = useSelector((state) => state.employees.data);

//   const employeeSelectData = useMemo(
//     () => generateSelectData(employeeData, "fullName"),
//     [employeeData],
//   );

//   const [selectedEmployee, setSelectedEmployee] = useState();
//   const [passportNumber, setPassportNumber] = useState("");
//   const [passportIssueDate, setPassportIssueDate] = useState("");
//   const [passportExpiryDate, setPassportExpiryDate] = useState("");
//   const [placeOfIssue, setPlaceOfIssue] = useState("");
//   const [uploadData, setUploadData] = useState([]);
//   const [docs, setdocs] = useState([]);
//   const [extraInput, setextraInput] = useState(false)

//   let changeHandler = (e) => {
//     if (e.target.files[0].size > 5242880) {
//       e.target.value = '';
//       customToast.error("Maximum file size should be 5 MB");
//       return;
//     }
//     else {
//       const validFiles = Array.from(e.target.files);
//       const updatedDocs = [...docs, ...validFiles];
//       setdocs(updatedDocs);
//       const promises = updatedDocs.map(fileToBase64);
//       setUploadData([]);
//       Promise.all(promises)
//         .then((base64Images) => {
//           setUploadData(base64Images);
//         })
//         .catch((error) => {
//           customToast.error("Error adding images");
//         });
//     }
//   };

//   const resetStates = () => {
//     setSelectedEmployee("");
//     setUploadData([]);
//     setPassportNumber("");
//     setPassportIssueDate("");
//     setPassportExpiryDate("");
//     setPlaceOfIssue("");
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (!selectedEmployee) {
//       customToast.error("Please Select Employee !!");
//       return;
//     }
//     if (
//       !passportNumber ||
//       !passportIssueDate ||
//       !passportExpiryDate ||
//       !placeOfIssue
//     ) {
//       customToast.error("Please Fill all The Required Details !!");
//       return;
//     }
//     try {
//       const payload = {
//         docType: "passport",
//         employeeId: selectedEmployee?.value,
//         number: passportNumber,
//         issueDate: passportIssueDate,
//         expiryDate: passportExpiryDate,
//         placeOfIssue,
//         uploadData,
//       };
//       const response = await UploadDocument(payload);
//       if (response?.ok) {
//         resetStates();
//         customToast.success("Passport Details Uploaded Successfully !!");
//       }
//     } catch (error) {
//       customToast.error("Error Uploading Passport Details !!");
//     }
//   };

//   const theme = useSelector((state) => state.theme.theme);
//   const [inputCount, setInputCount] = useState(0);

//   const renderFileInputs = () => {
//     const inputs = [];
//     for (let i = 0; i < inputCount; i++) {
//       inputs.push(
//         <div key={i}>
//           <input type="file" onChange={changeHandler} className="dark:bg-primaryDark2 px-5 py-2" />
//         </div>
//       );
//     }
//     return inputs;
//   };

//   return (
//     <main
//       className="main-content bg-primaryLight2 dark:bg-primaryDark1"
//     >
//       <div
//         className={
//           "creation-content " + `${theme == "light" ? "light_bg" : ""}`
//         }
//       >
//         <PageTitle title="PASSPORT DETAILS" route={'/employee/manage/create'} />

//         <section className="creation-content_form">
//           <form className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols- gap-4 p-6">
//             <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
//               <label>Select Employee <span className="asterisk-imp">*</span>
//               </label>
//               <br />
//               <Select
//                 name="emp_select"
//                 value={selectedEmployee}
//                 className="mt-5 w-[350px]"
//                 styles={theme == "light" ? adminStylesLight : selectStyle}
//                 options={employeeSelectData}
//                 onChange={(e) => setSelectedEmployee({ ...e })}
//                 placeholder="Select Employee"
//               />
//             </div>

//             <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
//               <label>
//                 Passport Number <span className="asterisk-imp">*</span>
//               </label>
//               <br />
//               <input
//                 type="text"
//                 className="me-5 mt-5 w-[250px]"
//                 placeholder="Enter Passport Number"
//                 value={passportNumber}
//                 onChange={(e) => setPassportNumber(e.target.value)}
//               />
//             </div>

//             <div className="col-sm-12 col-md-4 col-lg-3 mt-5 ">
//               <label>
//                 Date of issue <span className="asterisk-imp">*</span>
//               </label>
//               <br />
//               <input
//                 type="date"
//                 className="me-5 mt-5 w-[250px]"
//                 placeholder="Select Date"
//                 value={passportIssueDate}
//                 onChange={(e) => setPassportIssueDate(e.target.value)}
//                 style={{
//                   boxShadow: "none"
//                 }}
//               />
//             </div>

//             <div className="col-sm-12 col-md-4 col-lg-3 mt-5 ">
//               <label>
//                 Expiry date <span className="asterisk-imp">*</span>
//               </label>
//               <br />
//               <input
//                 type="date"
//                 className="me-5 mt-5 w-[250px]"
//                 placeholder="Enter Date"
//                 value={passportExpiryDate}
//                 onChange={(e) => setPassportExpiryDate(e.target.value)}
//                 style={{
//                   boxShadow: "none"
//                 }}
//               />
//             </div>

//             <div className="col-sm-12 col-md-4 col-lg-3 mt-5 ">
//               <label>
//                 Place of issue <span className="asterisk-imp">*</span>
//               </label>
//               <br />
//               <input
//                 type="text"
//                 className="me-5 mt-5 w-[250px]"
//                 placeholder="Enter Place Of Issue"
//                 value={placeOfIssue}
//                 onChange={(e) => setPlaceOfIssue(e.target.value)}
//               />
//             </div>
//           </form>
//         </section>
//       </div>

//       <div className="col-sm-12 col-md-4 col-lg-3 mt-5 w-fit">
//         <label>Upload file</label> <span className="asterisk-imp">*</span>
//       </div>

//       <input type="file" onChange={changeHandler} className="dark:bg-primaryDark2 px-5 py-2" />

//       <div className="col-sm-2 col-md-4 col-lg-2 mt-5">
//         <button type="submit" className="button-blue-gradient" onClick={(e) => handleSubmit(e)}>
//           Upload
//         </button>
//       </div>
//     </main>
//   );
// };

// export default Passport;

// <main
//   className="main-content bg-primaryLight2 dark:bg-primaryDark1"
// >
//   <div
//     className={
//       "creation-content " + `${theme == "light" ? "light_bg" : ""}`
//     }
//   >
//     <PageTitle title="PASSPORT DETAILS" route={'/employee/manage/create'} />

//     <section className="creation-content_form">
//       <form className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols- gap-4 p-6">
//         <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
//           <label>Select Employee <span className="asterisk-imp">*</span>
//           </label>
//           <br />
//           <Select
//             name="emp_select"
//             value={selectedEmployee}
//             className="mt-5 w-[250px]"
//             styles={theme == "light" ? adminStylesLight : selectStyle}
//             options={employeeSelectData}
//             onChange={(e) => setSelectedEmployee({ ...e })}
//             placeholder="Select Employee"
//           />
//         </div>

//         <div className="col-sm-12 col-md-4 col-lg-3 mt-5">
//           <label>
//             Passport Number <span className="asterisk-imp">*</span>
//           </label>
//           <br />
//           <input
//             type="text"
//             className="me-5 mt-5 w-[250px]"
//             placeholder="Enter Passport Number"
//             value={passportNumber}
//             onChange={(e) => setPassportNumber(e.target.value)}
//           />
//         </div>

//         <div className="col-sm-12 col-md-4 col-lg-3 mt-5 ">
//           <label>
//             Date of issue <span className="asterisk-imp">*</span>
//           </label>
//           <br />
//           <input
//             type="date"
//             className="me-5 mt-5 w-[250px]"
//             placeholder="Select Date"
//             value={passportIssueDate}
//             onChange={(e) => setPassportIssueDate(e.target.value)}
//             style={{
//               boxShadow: "none"
//             }}
//           />
//         </div>

//         <div className="col-sm-12 col-md-4 col-lg-3 mt-5 ">
//           <label>
//             Expiry date <span className="asterisk-imp">*</span>
//           </label>
//           <br />
//           <input
//             type="date"
//             className="me-5 mt-5 w-[250px]"
//             placeholder="Enter Date"
//             value={passportExpiryDate}
//             onChange={(e) => setPassportExpiryDate(e.target.value)}
//             style={{
//               boxShadow: "none"
//             }}
//           />
//         </div>

//         <div className="col-sm-12 col-md-4 col-lg-3 mt-5 ">
//           <label>
//             Place of issue <span className="asterisk-imp">*</span>
//           </label>
//           <br />
//           <input
//             type="text"
//             className="me-5 mt-5 w-[250px]"
//             placeholder="Enter Place Of Issue"
//             value={placeOfIssue}
//             onChange={(e) => setPlaceOfIssue(e.target.value)}
//           />
//         </div>
//       </form>
//     </section>
//   </div>

//   <div className="col-sm-12 col-md-4 col-lg-3 mt-5 w-fit">
//     <label>Upload file</label> <span className="asterisk-imp">*</span>
//   </div>
//   <input type="file" onChange={changeHandler} className="dark:bg-primaryDark2 px-5 py-2" />
//   <div className="col-sm-2 col-md-4 col-lg-2 mt-5">
//     <button type="submit" className="button-blue-gradient" onClick={(e) => handleSubmit(e)}>
//       Upload
//     </button>
//   </div>
// </main>

import { useEffect, useState, useMemo, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminStyles, adminStylesLight } from "../../../../utils/selectStyles";
import PageTitle from "../../../../components/PageTitle";
import Select from "react-select";
import { generateSelectData } from "../../../../utils/serialiseResponse";
import {
  fetchEmployees,
  setEmployees,
} from "../../../../reducers/employeesReducer";
import { GetAllEmployees, UploadDocument } from "../../../../services/hrms";
import customToast from "../../../../components/CustomToast";
import { fileToBase64 } from "../../../../utils/helper";
import { IoIosAddCircleOutline, IoIosRemoveCircleOutline } from "react-icons/io";


const selectStyle = {
  control: (provided, state) => ({
    ...provided,
    padding: "0.1rem 1rem",
    backgroundColor: "#2B2C47",
    color: "#C7C4E9",
    border: "5px",
    borderRadius: "5px",
    boxShadow: "none",
    minHeight: "3.5rem",
    maxHeight: "3.5rem",
    width: "100%",
    marginTop: "1rem",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    color: "#9E9AD1",

    "&:hover": {
      backgroundColor: "#393b5f",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#9e9ad0",
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#2B2C47",
    width: "80%",
  }),
  input: (provided, state) => ({
    ...provided,
    color: "#fff",
    border: "none",
    width: "100%",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#C7C4E9",

    "&:hover": {
      color: "#9E9AD1",
    },
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#9E9AD1",
  }),
  indicatorSeparator: () => null,
};

const Passport = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchEmployees({}));
  }, []);

  const employeeData = useSelector((state) => state.employees.data);

  const employeeSelectData = useMemo(
    () => generateSelectData(employeeData, "fullName"),
    [employeeData],
  );

  const [selectedEmployee, setSelectedEmployee] = useState();
  const [passportNumber, setPassportNumber] = useState("");
  const [passportIssueDate, setPassportIssueDate] = useState("");
  const [passportExpiryDate, setPassportExpiryDate] = useState("");
  const [placeOfIssue, setPlaceOfIssue] = useState("");
  const [uploadData, setUploadData] = useState([]);
  const [docs, setdocs] = useState([]);

  // let changeHandler = (e) => {
  //   const validFiles = Array.from(e.target.files);
  //   setUploadData([]);
  //   Promise.all(validFiles.map(fileToBase64))
  //     .then((base64Images) => {
  //       setUploadData(base64Images);
  //     })
  //     .catch((error) => {
  //       customToast.error("Error adding images");
  //     });
  // };

  let changeHandler = (e) => {
    if (e.target.files[0].size > 5242880) {
      e.target.value = '';
      customToast.error("Maximum file size should be 5 MB");
      return;
    }
    else {
      const validFiles = Array.from(e.target.files);
      const updatedDocs = [...docs, ...validFiles];
      setdocs(updatedDocs);
      const promises = updatedDocs.map(fileToBase64);
      setUploadData([]);
      Promise.all(promises)
        .then((base64Images) => {
          setUploadData(base64Images);
        })
        .catch((error) => {
          customToast.error("Error adding images");
        });
    }
  };

  const resetStates = () => {
    setSelectedEmployee("");
    setUploadData([]);
    setPassportNumber("");
    setPassportIssueDate("");
    setPassportExpiryDate("");
    setPlaceOfIssue("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedEmployee) {
      customToast.error("Please Select Employee !!");
      return;
    }
    if (
      !passportNumber ||
      !passportIssueDate ||
      !passportExpiryDate ||
      !placeOfIssue
    ) {
      customToast.error("Please Fill all The Required Details !!");
      return;
    }
    try {
      const payload = {
        docType: "passport",
        employeeId: selectedEmployee?.value,
        number: passportNumber,
        issueDate: passportIssueDate,
        expiryDate: passportExpiryDate,
        placeOfIssue,
        uploadData,
      };
      const response = await UploadDocument(payload);
      if (response?.ok) {
        dispatch(fetchEmployees());
        resetStates();
        customToast.success("Passport Details Uploaded Successfully !!");
      }
    } catch (error) {
      customToast.error("Error Uploading Passport Details !!");
    }
  };

  const theme = useSelector((state) => state.theme.theme);
  const [extraInput, setextraInput] = useState(false)

  return (
    <main
      className="main-content bg-primaryLight2 dark:bg-primaryDark1"
    >
      <div
        className={
          "area-creation-content " + `${theme == "light" ? "light_bg" : ""}`
        }
      >
        <PageTitle title="PASSPORT DETAILS" route={'/employee/manage/create'} />
        <form>
          <section className="admin-content">
            <form className="row">
              <div>
                <label>Select Employee <span className="asterisk-imp">*</span></label>
                <br />
                <Select
                  name="emp_select"
                  value={selectedEmployee}
                  styles={theme == "light" ? adminStylesLight : adminStyles}
                  options={employeeSelectData}
                  onChange={(e) => setSelectedEmployee({ ...e })}
                  placeholder="Select Employee"
                />
              </div>

              <div>
                <label>
                  Passport Number <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                  placeholder="Enter Passport Number"
                  value={passportNumber}
                  onChange={(e) => setPassportNumber(e.target.value)}
                />
              </div>

              <div>
                <label>
                  Date of issue <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="date"
                  placeholder="Select Date"
                  value={passportIssueDate}
                  onChange={(e) => setPassportIssueDate(e.target.value)}
                  style={{
                    boxShadow: "none"
                  }}
                />
              </div>

              <div>
                <label>
                  Expiry date <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="date"
                  placeholder="Enter Date"
                  value={passportExpiryDate}
                  onChange={(e) => setPassportExpiryDate(e.target.value)}
                  style={{
                    boxShadow: "none"
                  }}
                />
              </div>

              <div>
                <label>
                  Place of issue <span className="asterisk-imp">*</span>
                </label>
                <br />
                <input
                  type="text"
                  placeholder="Enter Place Of Issue"
                  value={placeOfIssue}
                  onChange={(e) => setPlaceOfIssue(e.target.value)}
                />
              </div>
            </form>

            <div className="mx-[2rem]">
              <div className="w-max">
                <label>Upload Document <span className="asterisk-imp">*</span>
                </label>
                <br />
                <div className="flex flex-col">
                  <div className="flex h-full gap-4">
                    <input type="file" onChange={changeHandler} className="dark:bg-primaryDark2 px-5 py-2 " />
                    {
                      extraInput &&
                      <input type="file" onChange={changeHandler} className="dark:bg-primaryDark2 px-5 py-2 " />
                    }
                    <div className="flex justify-center items-center mt-3">
                      {extraInput ?
                        <IoIosRemoveCircleOutline
                          className="text-4xl cursor-pointer text-[#36abf9]"
                          onClick={() => setextraInput(false)}
                        />
                        :
                        <IoIosAddCircleOutline
                          className="text-4xl cursor-pointer text-[#36abf9]"
                          onClick={() => setextraInput(true)}
                        />
                      }
                      <br />
                    </div>
                  </div>
                  <span className="text-lg text-gray-500 mt-2">*Please upload only JPG, JPEG or PDF format, no larger than 5 MB.</span>
                </div>
              </div>
            </div>

            <div className="row pb-10">
              <button type="submit" className="button-blue-gradient" onClick={(e) => handleSubmit(e)}>
                Upload
              </button>
            </div>
          </section>
        </form>
      </div>
    </main>
  );
};

export default Passport;
