import React, { useEffect, useState } from "react";
import Select from "react-select";
import { setUpSelectStyles } from "../../../utils/selectStyles";
import ToggleButton from "../../../components/Toggle/ToggleButton/ToggleButton";
import customToast from "../../../components/CustomToast";

const deductionTypes = [
  { value: "monthly", label: "Monthly" },
  { value: "annual", label: "Annual" },
];

const employerContributionRate = [
  { value: 3.25, label: "3.25% of Gross Salary" },
];

const employeeContributionRate = [
  { value: 0.75, label: "0.75% of Gross Salary" },
];

const Esi = ({ esi, setEsi }) => {
  const [toggle, setToggle] = useState(false);

  return (
    <div className="comp cursor-pointer">
      <div className="heading_container" onClick={() => setToggle(!toggle)}>
        <h1>
          Employee’s State Insurance (ESI)
          <ToggleButton
            value={esi?.enableEsi}
            onChange={() => setToggle(!toggle)}
          />
        </h1>

        <p>
          Any Company with more than 20 Employees Any Company with more than 20
          Employees Any Company with more than 20 Employees
        </p>
      </div>
      <form className={"set-up " + `${toggle ? "show" : "hide"}`}>
        <div className="two_in_one">
          <div>
            <label htmlFor="">ESI Number</label>
            <br />
            <input
              className="text_input"
              type="text"
              placeholder="0000000000"
              value={esi.esiNumber}
              onChange={(e) =>
                setEsi({
                  ...esi,
                  esiNumber: e.target.value,
                })
              }
            />
          </div>
          <div>
            <label htmlFor="">Deduction Type </label>
            <br />
            <Select
              isSearchable={false}
              styles={setUpSelectStyles}
              options={deductionTypes}
              value={esi.deductionType}
              onChange={(e) => setEsi({ ...esi, deductionType: e })}
            />
          </div>
        </div>
        <div>
          <label htmlFor="">Employer Contribution Rate</label>
          <br />
          <Select
            isSearchable={false}
            styles={setUpSelectStyles}
            options={employerContributionRate}
            value={esi.employerContributionRate}
            onChange={(e) => setEsi({ ...esi, employerContributionRate: e })}
          />
        </div>
        <div>
          <label htmlFor="">Employee Contribution Rate</label>
          <br />
          <Select
            isSearchable={false}
            styles={setUpSelectStyles}
            options={employeeContributionRate}
            value={esi.employeeContributionRate}
            onChange={(e) => setEsi({ ...esi, employeeContributionRate: e })}
          />
        </div>
        <div className="terms">
          <span className="text-green-500 my-2">View Sample Calculation</span>
          <div>
            <input
              className="checkbox_input"
              type="checkbox"
              id="esi-op-1"
              onClick={(e) => {
                setEsi({
                  ...esi,
                  calculatOnProDataBasis: e.target.checked,
                });
              }}
            />
            <label
              className="!m-0 !font-normal !text-lg cursor-pointer"
              htmlFor="esi-op-1"
            >
              Calculate on Pro-rata Basis
            </label>
          </div>
          {/* <div>
            <input
              className="checkbox_input"
              type="checkbox"
              onClick={(e) => {
                setEsi({
                  ...esi,
                  allSalaryComponentsConsideration: e.target.checked,
                });
              }}
            />
            <span>
              Consider all salary components applicable if the pay is less than
              15000 after LOP calculation
            </span>
          </div> */}
          <div>
            <input
              className="checkbox_input"
              type="checkbox"
              id="esi-op-2"
              onClick={(e) => {
                if (e.target.checked) {
                  setEsi({
                    ...esi,
                    includeEmployersContribution: e.target.checked,
                  });
                } else {
                  setEsi({
                    ...esi,
                    includeEmployersContribution: e.target.checked,
                    addPfAdminChargesInCtc: false,
                    addEmployersEdliContributionInCtc: false,
                  });
                }
              }}
            />
            <label
              className="!m-0 !font-normal !text-lg cursor-pointer"
              htmlFor="esi-op-2"
            >
              Include Employers Contribution in the CTC
            </label>
          </div>

          {/* we have to toggle between them */}
          {/* {esi.includeEmployersContribution && (
            <div className="flex flex-col  !items-start pl-12">
              <div>
                <input
                  className="checkbox_input"
                  type="checkbox"
                  checked={esi.addPfAdminChargesInCtc}
                  onClick={(e) => {
                    if (e.target.checked) {
                      setEsi({
                        ...esi,
                        addPfAdminChargesInCtc: e.target.checked,
                        addEmployersEdliContributionInCtc: false,
                      });
                    } else {
                      setEsi({
                        ...esi,
                        addPfAdminChargesInCtc: e.target.checked,
                      });
                    }
                  }}
                />
                <span>Add PF Admin charges in CTC</span>
              </div>
              <div>
                <input
                  className="checkbox_input"
                  type="checkbox"
                  checked={esi.addEmployersEdliContributionInCtc}
                  onClick={(e) => {
                    if (e.target.checked) {
                      setEsi({
                        ...esi,
                        addEmployersEdliContributionInCtc: e.target.checked,
                        addPfAdminChargesInCtc: false,
                      });
                    } else {
                      setEsi({
                        ...esi,
                        addEmployersEdliContributionInCtc: e.target.checked,
                      });
                    }
                  }}
                />
                <span>Add Employer’s EDLI Contribution in CTC</span>
              </div>
            </div>
          )} */}

          <div>
            <input
              className="checkbox_input"
              type="checkbox"
              id="esi-op-3"
              onClick={(e) => {
                setEsi({
                  ...esi,
                  componentOnPayslip: e.target.checked,
                });
              }}
            />
            <label
              className="!m-0 !font-normal !text-lg cursor-pointer"
              htmlFor="esi-op-3"
            >
              Show this Component on Payslip
            </label>
          </div>
        </div>
        <div className="mt-4">
          <button
            className="submit_button"
            onClick={(e) => {
              e.preventDefault();
              if (
                esi.esiNumber &&
                esi.deductionType &&
                esi.employerContributionRate &&
                esi.employeeContributionRate
              ) {
                setEsi({
                  ...esi,
                  enableEsi: !esi.enableEsi,
                });
                setToggle(!toggle);
              } else customToast.error("Please fill all the fields");
            }}
          >
            {esi.enableEsi === true ? "Enabled" : "Enable"}
          </button>
          <span
            className="skip"
            onClick={(e) => {
              e.preventDefault();
              setEsi({
                ...esi,
                enableEsi: false,
              });
              setToggle(false);
            }}
          >
            cancel
          </span>
        </div>
      </form>
    </div>
  );
};

export default Esi;
