export const title = "Manage Payroll Reports"

export const links = [
    {
        name:"PAYROLL-SUMMARY",
        route:'payroll-summary'
    },
    {
        name:"SALARY-COMPARISON",
        route:'salary-comparison'
    },
]