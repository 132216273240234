import { customAxios } from "../utils/axiosinstance";


export const ConfigurePayRunApproveStatus = async(payload) => {
    const {data} = await customAxios.post(`/hrms/payrun-approve/` , payload)
    return data;
}

export const updatePayrunApproveStatus = async(payload) => {
    const {data} = await customAxios.put(`/hrms/payrun-approve/` , payload)
    return data;
}

export const getPayrunApproveStatus =  async(status) => {
    const {data} = await customAxios.get(`/hrms/payrun-approve/`)
    return data;
}

export const updatePayrunMonth =  async(index) => {
    const {data} = await customAxios.post(`/hrms/payrun-approve/updateMonth` , index)
    return data;
}

export const createPayrun =  async(payload) => {
    const {data} = await customAxios.post(`/hrms/payrun-approve/create` , payload)
    return data;
}

export const getPayrun =  async(payload) => {
    const {data} = await customAxios.get(`/hrms/payrun-approve/getAll` , payload)
    return data;
}

export const getPayRunInfo = async(payload) => {
    const {data} = await customAxios.get(`hrms/payrun-approve/get-info`,{
        params: payload
    });
    return data;
}

export const calculatePayrunData = async(payload) => {
    const {data} = await customAxios.put(`/hrms/payrun-approve/calc` , payload)
    return data;
}


export const calculatePayrunDataMonthly = async(payload) => {
    const {data} = await customAxios.put(`/hrms/payrun-approve/calc-monthly` , payload)
    return data;
}

export const uploadPayslip = async(payload) => {
    const {data} = await customAxios.post('hrms/payrun-approve/upload-payslip', payload);
    return data;
}

export const getPayrunWithMonthAndYear = async(payload) => {
    const {data} = await customAxios.get(`/hrms/payrun-approve/getPayrunWithMonthAndYear` , {
        params: payload
    })
    return data;
}

export const getProcessedPayruns = async(payload) => {
    const {data} = await customAxios.get(`/hrms/payrun-approve/getProcessedPayruns` , {
        params: payload
    })
    return data;
}
export const getProcessedPayrunsMonths = async(payload) => {
    const {data} = await customAxios.get(`/hrms/payrun-approve/getProcessedPayruns-months` , {
        params: payload
    })
    return data;
}