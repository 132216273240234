import React from "react";
import { title, links } from "./docNavData";
import { Outlet } from "react-router-dom";
import SideBar from "../../../components/SideBar/SideBar";
import "../employee.css";
import { useSelector } from "react-redux";

const DocumentHome = () => {
  const theme = useSelector((state) => state.theme.theme);
  return (
    <div className="flex w-full bg-primaryLight2 dark:bg-primaryDark1" >
      <SideBar title={title} links={links} />

      <Outlet />
    </div>
  );
};

export default DocumentHome;
