import React, { useMemo, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { FaEye } from 'react-icons/fa'
import Select from 'react-select'
import { useSelector, useDispatch } from 'react-redux'
import { adminStyles, adminStylesLight, dcrStyles } from '../../../utils/selectStyles'
import Table from "../../../components/Table";
import { getAllLeaves, customizeBalance, getUserLeaveType } from '../../../services/leave'
import customToast from '../../../components/CustomToast';
import { addIndex } from '../../../utils/serialiseResponse';
import { clearEmployees, fetchEmployees } from "../../../reducers/employeesReducer";
import { generateSelectData } from "../../../utils/serialiseResponse";
import PageTitle from '../../../components/PageTitle'
const CustomiseBalance = () => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.theme)
  const today = new Date().toISOString().split('T')[0];
  const employees = useSelector(({ employees }) => employees);
  const [empl, setEmpl] = useState(null);


  const [selectDate, setselectDate] = useState("")

  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    dispatch(fetchEmployees());

    return () => dispatch(clearEmployees());
  }, [dispatch]);
  const emplSelect = useMemo(
    () => generateSelectData(employees.data, "fullName"),
    [employees.data]
  );

  useEffect(() => {

    if (empl) {
      getUserLeaveType(empl?._id).then((res) => setTableData(res.data))
      .catch(() => customToast.error("Error occurred "))
      setTableData(null)
    }
    else {
      getAllLeaves().then((res) => setTableData(res.data))
        .catch(() => customToast.error("Error occurred "))
      setTableData(null)
    }
  }, [empl]);
  const updateData = (rowIndex, columnId, value) => {
    setTableData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            [columnId]: Number(value),
          };
        }
        return row;
      })
    );
  };

  const handleUpdate = () => {
    if(empl){
      let payload=[] ;
      tableData?.map((l)=>{
        payload.push({
          leave_type:l._id,
          balance:l.balance
        })
      });
    const emp = { employee: empl?._id };
    customizeBalance({ payload, emp }).then((res) => customToast.success(res.data))
      .catch(() => customToast.error('Something went wrong!'));
    }
    else{
      customToast.error('Employee Not Selected !');
    }
  }

  const tableData2 = useMemo(() => addIndex(tableData), [tableData]);
  const columns = useMemo(
    () => [
      {
        Header: "Leave Type",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        Cell :(props)=>{
          {
             return (props?.row?.original?.leave_type?.leave_Name ?
              props?.row?.original?.leave_type.leave_Name
              :
              props?.row?.original?.leave_Name
            )
             
          }
        }
      },
      {
        Header: "Leave Code",
        accessor: "",
        disableFilters: true,
        disableSortBy: true,
        Cell :(props)=>{
          {
             return (props?.row?.original?.leave_type?.leave_code ?
              props?.row?.original?.leave_type.leave_code
              :
              props?.row?.original?.leave_code
            )
             
          }
        }
        // Cell: (props) => {

        //   return (
        //     <div className='section-form_content'>
        //       <form >
        //         <input
        //           type="date"

        //           className='outline-none border-none '
        //           value={selectDate || today} // Set default value to today's date
        //           onChange={(e) => setselectDate(e.target.value)}
        //         />
        //       </form>
        //     </div>
        //   );
        // }

      },
      {
        Header: "Existing Leave",
        accessor: "balance",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "New Leave",
        accessor: "status",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return <input type="number" style={{ backgroundColor: "#353456",borderRadius:"1.5rem" }} className='text-wheat h-[3rem] w-[8rem] p-4 text-center outline-none '
            onChange={(e) => updateData(props.row.index, 'balance', e.target.value)}
          />
        }
      },
      {
        Header: "Reason",
        accessor: "reason",
        disableFilters: true,
        disableSortBy: true
      },
    ],
    [],
  );


  return (
    <div className="main-content bg-primaryLight2 dark:bg-primaryDark1" >
      {/* <div className=' mt-5 m-0'> */}
      <div className={'area-creation-content' + `${theme === 'light' ? "light_bg" : ""}`}>

        {/* <div className=" bg-whiteLight dark:!bg-primaryDark2"> */}
        <PageTitle title="Customize Balance" route='../../' />
        {/* </div> */}

        <div className='flex  justify-between items-center '>
          <div className="w-96 mt-4 cardlink-row">
            <Select
              className="mb-3"
              name="select employee"
              id="select_emp"
              options={emplSelect}
              onChange={(el) => setEmpl(el)}
              styles={theme == "light" ? adminStylesLight : adminStyles}
            /></div>

          <div className='button-div cardlink-row mt-4 align-content-center'>
            <Link to={'/attendance/manage-leave/upload-leave'}>
              <button className='pre'>UPLOAD LEAVE</button>
            </Link>
          </div>
        </div>
      

      {/* </div> */}

  
        <section
          className='cardlink-row pb-6'
        >
          {tableData?.length > 0 && (
            <h2 className={"  web-app__heading " }>
              Showing ({tableData?.length}) Entries
            </h2>
          )}

          {tableData2?.length > 0 ? (
            <div className={"filter-table"}>
              <Table columns={columns} data={tableData2} />
            </div>
          ) : (
            ""
          )}

          <button className="button-blue-gradient" onClick={() => handleUpdate()}>Update</button>
        </section>

    </div>
    </div>
  )
}


export default CustomiseBalance;