import React from 'react'
import { links, title } from './payrollNavData'
import SideBar from '../../../components/SideBar/SideBar'
import { Outlet } from 'react-router-dom'

const PayrollNav = () => {
  return (
    <>
      <SideBar title={title} links={links} />

      <Outlet />
    </>
  )
}

export default PayrollNav