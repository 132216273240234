import React from "react";
import ThemeWrapper from "./theme/ThemeWrapper";
import store from "./store";
import { Provider } from "react-redux";
import App from "./components/App.jsx";
import { createRoot } from "react-dom/client";
import "./index.css";
import { BrowserRouter as Router } from 'react-router-dom';

const root = createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <Router>
      <ThemeWrapper>
        <App tab="home" />
      </ThemeWrapper>
    </Router>
  </Provider>,
);
